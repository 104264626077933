import { Select } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { fromSelectOption } from 'utils/selectOption'

import { HAZMAT_CLASSES_OPTIONS } from './HazmatInformation.constants'
import type { useHazmatItemFormFieldProps } from './HazmatItemForm'
import { useHazmatItemFormField } from './HazmatItemForm'
import { getHazmatClassOption } from './HazmatItemForm.utils'

export const HazmatItemClass = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: useHazmatItemFormFieldProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    name,
    dispatch,
  } = useHazmatItemFormField({ name: 'hazmat_class', hint })

  return (
    <Field required={required} style={style} {...fieldProps}>
      <Field.Label {...labelProps}>Hazmat class</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={Boolean(controlProps.disabled)}
        placeholder="Select hazmat class"
        id={`field-${index}-${name}`}
        name={name}
        options={HAZMAT_CLASSES_OPTIONS}
        value={getHazmatClassOption(get(item, name))}
        onChange={(event) => {
          const {
            target: { value: option },
          } = event
          const value = fromSelectOption(option as SelectOption)

          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: value,
              },
            },
          })
        }}
        hideClear={required}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
