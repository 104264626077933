import { Layout, Tag } from '@loadsmart/loadsmart-ui'
import { Divider, LabeledValue } from '@loadsmart/miranda-react'
import { useQueries } from 'react-query'

import { getOrderDetails } from 'orders/order-service'
import type { Order } from 'orders/types'
import { getTransientErrorsCount } from 'utils/transient'

import { FormSection } from './FormSection'
import { FulfillmentField } from './FulfillmentField'
import { useFulfillmentFormContext } from './FulfillmentFormContext'

export function FulfillmentInfo() {
  const { fulfillment } = useFulfillmentFormContext()
  const errors = getTransientErrorsCount(fulfillment)

  const results = useQueries(mapOrdersQueries(fulfillment.order_uuids))
  const orderTags = results
    .map(({ data, status }) => {
      return !data ? null : (
        <OrderTags key={data.uuid} order={data} status={status} />
      )
    })
    .filter(Boolean)
  const hasOrders = orderTags.length > 0

  return (
    <FormSection
      id="fulfillment-freight-info"
      title="Freight info"
      errors={errors}
    >
      <Layout.Stack>
        {fulfillment.owner === 'customer' ? (
          <LabeledValue
            label="Customer"
            value={fulfillment.customer_name}
            labelVariant="body-sm"
            valueVariant="body-md"
          />
        ) : (
          <LabeledValue
            label="Supplier"
            value={fulfillment.supplier_name}
            labelVariant="body-sm"
            valueVariant="body-md"
          />
        )}
        {hasOrders && (
          <LabeledValue
            label="Orders"
            value={orderTags}
            labelVariant="body-sm"
            valueVariant="body-md"
          />
        )}
        <Divider />
        <FulfillmentField name="pickup_number" label="Pickup Number" />
      </Layout.Stack>
    </FormSection>
  )
}

export function mapOrdersQueries(orderUuids: string[] | null) {
  if (!orderUuids) {
    return []
  }

  return orderUuids.map((uuid) => ({
    queryKey: ['getOrderDetails', uuid],
    queryFn: () => getOrderDetails(uuid),
  }))
}

export function getOrderIdentifier(order?: Order) {
  if (!order) {
    return undefined
  }

  if (order.so_number) {
    return order.so_number
  }

  return order.po_number
}

export function OrderTags({
  order,
  status,
}: {
  readonly order?: Order
  readonly status: string
}) {
  if (status === 'loading' || !order) {
    return null
  }

  const identifier = getOrderIdentifier(order)
  return identifier ? (
    <Tag key={order?.uuid} variant="default" size="small">
      {identifier}
    </Tag>
  ) : null
}
