import { Header } from '@loadsmart/miranda-react'

import { NewFulfillmentButton } from 'fulfillments/components/NewFulfillmentButton'
import { useIsFulfillmentsFlowEnabled } from 'fulfillments/hooks/useIsFulfillmentsFlowEnabled'

import { NewShipmentFromOrdersButton } from '../../screens/Orders/ManualConsolidation'

export default function ListOrdersPageHeader() {
  const isFulfillmentsFlowEnabled = useIsFulfillmentsFlowEnabled()

  return (
    <Header>
      <Header.Content>
        <Header.Title data-testid="title">Orders</Header.Title>
      </Header.Content>
      <Header.Actions>
        {isFulfillmentsFlowEnabled ? (
          <NewFulfillmentButton />
        ) : (
          <NewShipmentFromOrdersButton />
        )}
      </Header.Actions>
    </Header>
  )
}
