import { Field, Select } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { OCEAN_LINES } from 'screens/Shipper/Checkout/DrayageCheckout/DrayageCheckout.constants'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const OCEAN_LINE_NAME = 'ocean_line_scac'
const OCEAN_LINE_LABEL = 'Ocean line'

type FreightInformationOceanLineProps = {
  readonly disabled?: boolean
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

const getOceanLineOptions = () => {
  return OCEAN_LINES.map((line) => {
    return {
      label: line.name,
      value: line.scac_code,
    }
  })
}

type FreightInformationOceanLineSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name' | 'value'
>

export function FreightInformationOceanLineSummaryField(
  props: Readonly<FreightInformationOceanLineSummaryFieldProps>
) {
  const { shipment } = useFreightInformationSummaryContext()

  return (
    <FreightInformationSummaryField
      label={OCEAN_LINE_LABEL}
      name={OCEAN_LINE_NAME}
      value={
        getOceanLineOptions().find(
          (option) => option.value === shipment[OCEAN_LINE_NAME]
        )?.label
      }
      {...props}
    />
  )
}

type SingleSelectOption = string | undefined

export function FreightInformationOceanLineField({
  disabled = false,
  required,
  hint,
  error,
  style,
}: FreightInformationOceanLineProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: OCEAN_LINE_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${OCEAN_LINE_NAME}-field`}
    >
      <Field.Label id={`label-${OCEAN_LINE_NAME}`}>
        {OCEAN_LINE_LABEL}
      </Field.Label>
      <Select
        aria-labelledby={`label-${OCEAN_LINE_NAME}`}
        disabled={disabled}
        name={OCEAN_LINE_NAME}
        placeholder="Select"
        options={getOceanLineOptions()}
        value={shipment[OCEAN_LINE_NAME]}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [OCEAN_LINE_NAME]: get(
                event,
                'target.value',
                undefined
              ) as SingleSelectOption,
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
