import { defaultTo } from 'lodash-es'
import { useCallback } from 'react'
import { useQuery } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'

import type {
  FulfillmentCreator,
  FulfillmentSource,
  FulfillmentStatus,
  ListFulfillment,
} from 'fulfillments/domain/Fulfillment'
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  getFulfillmentsAsCustomer,
  getFulfillmentsAsSupplier,
} from 'fulfillments/fulfillment-service'
import { useOffsetPagination } from 'hooks/usePagination'
import { SEARCH_TERM_CHANGE_DEBOUNCE_MS } from 'orders/constants'
import {
  convertNumberFromSearchParam,
  convertNumberToSearchParam,
} from 'suppliers/common/usePaginatedSuppliersList.pagination'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import type { Filters } from 'utils/filters'
import { generateUseSearchParamsPlugin } from 'utils/filters'

import { usePersistedFilters } from './usePersistedFilters'

export type GetFulfillmentsPagination = {
  page_size?: number
  page?: number
}

export type GetFulfillmentsFilters = {
  status?: string[] | null
  search?: string | null
  source?: FulfillmentSource
  created_by?: FulfillmentCreator
}

export type FilterValues = Filters<GetFulfillmentsFilters>

export type GetFulfillmentsQueryParams = GetFulfillmentsPagination &
  GetFulfillmentsFilters

export function getDefaultFulfillmentsPaginationParams(
  overrides?: Partial<GetFulfillmentsPagination>
): GetFulfillmentsPagination {
  return {
    page_size: defaultTo(overrides?.page_size, DEFAULT_PAGE_SIZE),
    page: defaultTo(overrides?.page, DEFAULT_PAGE),
  }
}

export function getDefaultFulfillmentsFiltersParams(
  overrides: Partial<GetFulfillmentsFilters> = {}
): GetFulfillmentsFilters {
  return {
    status: defaultTo(overrides.status, null),
    search: defaultTo(overrides.search, ''),
    source: defaultTo(overrides.source, undefined),
    created_by: defaultTo(overrides.created_by, undefined),
  }
}

export function getDefaultFulfillmentsQueryParams(
  overrides?: Partial<GetFulfillmentsQueryParams>
): GetFulfillmentsQueryParams {
  return {
    ...getDefaultFulfillmentsPaginationParams(overrides),
    ...getDefaultFulfillmentsFiltersParams(overrides),
  }
}

export function mapPageSizeParam(pagination: GetFulfillmentsPagination = {}) {
  return {
    page_size: Number(defaultTo(pagination.page_size, DEFAULT_PAGE_SIZE)),
  }
}

export function mapPageParam(pagination: GetFulfillmentsQueryParams = {}) {
  return { page: Number(defaultTo(pagination.page, DEFAULT_PAGE)) }
}

export function mapFilterParams(filters: GetFulfillmentsFilters = {}) {
  return {
    status: defaultTo(filters.status, null) as FulfillmentStatus[] | null,
    search_term: defaultTo(filters.search, ''),
    source: defaultTo(filters.source, undefined),
    created_by: defaultTo(filters.created_by, undefined),
  }
}

export function mapParams(params?: GetFulfillmentsQueryParams) {
  return {
    ...mapPageSizeParam(params),
    ...mapPageParam(params),
    ...mapFilterParams(params),
  }
}

export const useFulfillmentsListPaginationQueryParamsPlugin =
  generateUseSearchParamsPlugin<GetFulfillmentsPagination>({
    page: {
      type: 'primitive',
      fromSearchParam: convertNumberFromSearchParam,
      toSearchParam: convertNumberToSearchParam,
    },
    page_size: {
      type: 'primitive',
      fromSearchParam: convertNumberFromSearchParam,
      toSearchParam: convertNumberToSearchParam,
    },
  })

export const useFulfillmentListFiltersQueryParamsPlugin =
  generateUseSearchParamsPlugin<GetFulfillmentsFilters>({
    status: { type: 'primitive-collection' },
    search: { type: 'primitive' },
    source: { type: 'primitive' },
    created_by: { type: 'primitive' },
  })

export function useFulfillmentList(view: FulfillmentCreator = 'customer') {
  const { clearFilters, filters, restoreFilters, setFilter, setFilters } =
    usePersistedFilters<GetFulfillmentsQueryParams>(
      'fulfillments-as-customer-list',
      getDefaultFulfillmentsQueryParams(),
      useFulfillmentsListPaginationQueryParamsPlugin,
      useFulfillmentListFiltersQueryParamsPlugin
    )

  const query = useQuery<PaginatedResult<ListFulfillment>>({
    queryKey: ['getFulfillmentsAsCustomer', view, filters],
    queryFn: ({ signal }) => {
      if (view === 'supplier') {
        return getFulfillmentsAsSupplier({ ...mapParams(filters) }, { signal })
      }

      return getFulfillmentsAsCustomer({ ...mapParams(filters) }, { signal })
    },
  })

  const { ...pagination } = useOffsetPagination({
    resultsCount: defaultTo(query.data?.count, 0),
    pageSize: defaultTo(filters.page_size, DEFAULT_PAGE_SIZE),
    currentOffset:
      (defaultTo(filters.page, DEFAULT_PAGE) - 1) * DEFAULT_PAGE_SIZE,
  })

  const onPageChange = useCallback(
    (event: { page: number; pageSize: number }) => {
      const { page, pageSize } = event

      // Miranda's Pagination component uses a 0-index,
      // so we need to +1 the page
      // https://miranda.loadsmart.com/?path=/docs/components-pagination--docs
      setFilters({
        page_size: pageSize,
        page: page + 1,
      })
    },
    [setFilters]
  )

  const onSearchChange = useDebouncedCallback((search: string) => {
    setFilters({ search })
    analytics.track('Action: Apply Filters', AnalyticsEventTrigger.click, {
      search,
    })
  }, SEARCH_TERM_CHANGE_DEBOUNCE_MS)

  const { data, isLoading, refetch } = query
  return {
    ...pagination,
    data,
    isLoading,
    clearFilters,
    filters,
    restoreFilters,
    onPageChange,
    onSearchChange,
    refetch,
    setFilter,
    setFilters,
  }
}
