import { SideNavigation } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { useFeatureFlag } from '_shared_/feature-flag'
import { useSettings } from '_shared_/settings/useSettings'
import { userUtils } from '_shared_/user'
import { appRoutes } from 'router/app-routes'
import { USER_TYPE } from 'utils/constants'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const ManageMenu = () => {
  const history = useHistory()
  const {
    values: [facilityManagementEnabled],
  } = useSettings(['flags.ENABLE_FACILITY_MANAGEMENT'])
  const enableSuppliers = useFeatureFlag('ENABLE_SUPPLIERS')

  if (userUtils.isUserType([USER_TYPE.SG, USER_TYPE.FBM])) {
    return (
      <SideNavigation.Menu label="Manage">
        {userUtils.isUserType([USER_TYPE.SG]) && (
          <>
            <SideNavigation.Menu.Item
              active={isActivePath(appRoutes.carriers)}
              label="Carriers"
              url={appRoutes.carriers}
              onClick={(event) => {
                event.preventDefault()
                navbarLinkClicked('Carriers', appRoutes.carriers, history)
              }}
            />
            <SideNavigation.Menu.Item
              active={isActivePath(appRoutes.lanes)}
              label="Lanes"
              url={appRoutes.lanes}
              onClick={(event) => {
                event.preventDefault()
                navbarLinkClicked('Lanes', appRoutes.lanes, history)
              }}
            />
          </>
        )}
        {facilityManagementEnabled && (
          <SideNavigation.Menu.Item
            active={isActivePath(appRoutes.facilityManagement)}
            label="Facilities"
            url={appRoutes.facilityManagement}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked(
                'Facilities',
                appRoutes.facilityManagement,
                history
              )
            }}
          />
        )}
        {enableSuppliers && (
          <SideNavigation.Menu.Item
            active={isActivePath(appRoutes.suppliersList)}
            label="Suppliers"
            url={appRoutes.suppliersList}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked('Suppliers', appRoutes.suppliersList, history)
            }}
          />
        )}
      </SideNavigation.Menu>
    )
  }

  return null
}
