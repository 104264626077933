import type { GroupProps, TextProps } from '@loadsmart/miranda-react'
import { Layout, Text } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash-es'
import { Fragment } from 'react'
import type { ReactNode } from 'react'

export type TextListProps = Readonly<{
  groupProps?: GroupProps
  separator?: ReactNode
  separatorProps?: TextProps
  valueProps?: TextProps
  values: (string | null | undefined)[]
}>

export function TextList({
  groupProps = {},
  separator = <>&bull;</>,
  separatorProps = {},
  values,
  valueProps = {},
}: TextListProps) {
  const nonEmptyValues = values.filter((value) => !isEmpty(value))

  return (
    <Layout.Group align="center" gap="spacing-1" {...groupProps}>
      {nonEmptyValues.map((value, index) => (
        <Fragment key={value}>
          {index > 0 && <Text {...separatorProps}>{separator}</Text>}
          <Text {...valueProps}>{value}</Text>
        </Fragment>
      ))}
    </Layout.Group>
  )
}
