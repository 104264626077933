import { Layout } from '@loadsmart/miranda-react'
import styled from 'styled-components'

export const StickyFormFooter = styled(Layout.Box).attrs({
  borderWidth: 'border-thin',
  borderRadius: 'border-radius-none',
  borderColor: 'color-neutral-20',
  backgroundColor: 'color-background-primary',
  paddingX: 'none',
})`
  bottom: 0;
  position: sticky;
`
