import { Button, Dialog } from '@loadsmart/miranda-react'
import { useCallback } from 'react'

import { useSettings } from '_shared_/settings/useSettings'

import { useRedirectToNewShipment } from './hooks'

export interface InvalidOrderSelectionForManualConsolidationDialogProps {
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly onConfirm: () => void
  readonly showConfirm?: boolean
}

export function InvalidOrderSelectionForManualConsolidationDialog({
  isOpen,
  onClose,
  onConfirm,
  showConfirm,
}: InvalidOrderSelectionForManualConsolidationDialogProps) {
  if (!isOpen) {
    return null
  }

  return (
    <Dialog
      open
      size="small"
      data-testid="manual-consolidation-dialog-invalid-order-selection"
    >
      <Dialog.Header>
        Planned orders selected <Dialog.Close onClose={onClose} />
      </Dialog.Header>
      <Dialog.Body>
        Planned orders were selected and cannot be used to create a new
        shipment.
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionTertiary onClick={onClose}>
          Back to orders
        </Dialog.ActionTertiary>
        {showConfirm && (
          <Dialog.ActionPrimary onClick={onConfirm}>
            Use open orders only
          </Dialog.ActionPrimary>
        )}
      </Dialog.Actions>
    </Dialog>
  )
}

export function NewShipmentFromOrdersButton() {
  const {
    values: [isManualConsolidationEnabled, isPlanPageEnabled],
  } = useSettings([
    'flags.ENABLE_ORDERS_MANUAL_CONSOLIDATION',
    'flags.ENABLE_PLAN_ORDERS_PAGE',
  ])

  const redirectToNewShipment = useRedirectToNewShipment()

  const onNewShipmentClick = useCallback(() => {
    if (isManualConsolidationEnabled) {
      redirectToNewShipment()
    }
  }, [isManualConsolidationEnabled, redirectToNewShipment])

  if (!isManualConsolidationEnabled || !isPlanPageEnabled) {
    return null
  }

  return (
    <Button
      variant="primary"
      data-testid="button-create-new-shipment"
      onClick={onNewShipmentClick}
    >
      Plan shipment
    </Button>
  )
}
