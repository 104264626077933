import { isEmpty } from 'lodash-es'

import { formatFacilityContactPhone } from 'components/FacilityDetails'
import type { FacilityContactV2, FacilityV2 } from 'services/facilities'

interface FacilitiesV2AdapterProps {
  facilities: Array<FacilityV2>
}

interface AdaptedFacilityV2 extends FacilityV2 {
  label: string
  value: string
  className: string
}

interface SelectableContact extends Contact {
  label: string
  value: string
}

export function facilitiesV2Adapter({
  facilities,
}: FacilitiesV2AdapterProps): Array<AdaptedFacilityV2> {
  if (!facilities || !Array.isArray(facilities)) {
    return []
  }

  const adaptedFacilities = facilities.map((facility) => ({
    ...facility,
    location: { lat: '', lng: '' },
    isFixture: true,
    isFacility: true,
    label: facility.name || facility.address || '',
    value: facility.uuid,
    className: 'facility__item',
  }))

  return adaptedFacilities
}

interface FacilityContactsAdapterProps {
  contacts: Array<Contact>
}

export function facilityContactsAdapter({
  contacts,
}: FacilityContactsAdapterProps): Array<SelectableContact> {
  if (!contacts || !Array.isArray(contacts)) {
    return []
  }

  const adaptedContacts = contacts.map((contact: Contact) => ({
    label: contact?.name || contact.phone_number,
    value: contact.uuid,
    name: contact?.name,
    phone_number: contact.phone_number,
    email: contact.email,
    uuid: contact.uuid,
  }))

  return adaptedContacts
}

// Temporary "V2" version of the adapter until other pages using the old one are migrated
interface SelectableContactV2 extends FacilityContactV2 {
  label: string
  value: string
}

export function facilityContactsAdapterV2({
  contacts,
}: {
  contacts: Array<FacilityContactV2>
}): Array<SelectableContactV2> {
  if (isEmpty(contacts)) {
    return []
  }

  const adaptedContacts = contacts.map((contact) => ({
    label: contact?.name || formatFacilityContactPhone(contact),
    value: contact.uuid,
    ...contact,
  }))

  return adaptedContacts
}
