import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const CONTAINER_NUMBER_NAME = 'container_number'
const CONTAINER_NUMBER_LABEL = 'Container number'

type FreightInformationContainerNumberSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name'
>

export function FreightInformationContainerNumberSummaryField(
  props: Readonly<FreightInformationContainerNumberSummaryFieldProps>
) {
  return (
    <FreightInformationSummaryField
      label={CONTAINER_NUMBER_LABEL}
      name={CONTAINER_NUMBER_NAME}
      {...props}
    />
  )
}

type FreightInformationContainerNumberFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
  readonly disabled?: boolean
}

export function FreightInformationContainerNumber({
  required,
  hint,
  error,
  style,
  disabled,
}: FreightInformationContainerNumberFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: CONTAINER_NUMBER_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${CONTAINER_NUMBER_NAME}-field`}
    >
      <Field.Label id={`label-${CONTAINER_NUMBER_NAME}`}>
        {CONTAINER_NUMBER_LABEL}
      </Field.Label>
      <TextField
        aria-labelledby={`label-${CONTAINER_NUMBER_NAME}`}
        value={get(shipment, CONTAINER_NUMBER_NAME, '')}
        disabled={disabled}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: { [CONTAINER_NUMBER_NAME]: get(event, 'target.value') },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
