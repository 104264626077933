import {
  add,
  addBusinessDays as addBusinessDaysFns,
  addDays as addDaysFns,
  addHours as addHoursFns,
  addMinutes as addMinutesFns,
  differenceInDays as differenceInDaysFns,
  differenceInHours as differenceInHoursFns,
  differenceInMinutes as differenceInMinutesFns,
  differenceInMonths as differenceInMonthsFns,
  differenceInSeconds as differenceInSecondsFns,
  differenceInYears as differenceInYearsFns,
  format,
  formatDistance as formatDistanceFns,
  formatDistanceStrict as formatDistanceStrictFns,
  formatDuration as formatDurationFns,
  intervalToDuration as intervalToDurationFns,
  isAfter as isAfterFns,
  isFuture as isFutureFns,
  isPast as isPastFns,
  isToday as isTodayFns,
  isWeekend as isWeekendFns,
  parse,
  parseISO as parseISOFns,
  set,
  setHours as setHoursFns,
  sub,
  subBusinessDays as subBusinessDaysFns,
  subDays,
  subHours,
} from 'date-fns'
import type { DateArg } from 'date-fns'

export type { DateArg, DifferenceInHoursOptions, Duration } from 'date-fns'

export type DateArgument = DateArg<Date>
export const addDate = add
export const addBusinessDays = addBusinessDaysFns
export const addDays = addDaysFns
export const addHours = addHoursFns
export const addMinutes = addMinutesFns
export const differenceInDays = differenceInDaysFns
export const differenceInHours = differenceInHoursFns
export const differenceInMinutes = differenceInMinutesFns
export const differenceInMonths = differenceInMonthsFns
export const differenceInSeconds = differenceInSecondsFns
export const differenceInYears = differenceInYearsFns

export const formatDate = format
export const formatDistance = formatDistanceFns
export const formatDistanceStrict = formatDistanceStrictFns
export const formatDuration = formatDurationFns

export const intervalToDuration = intervalToDurationFns

export const isAfter = isAfterFns
export const isFuture = isFutureFns
export const isPast = isPastFns
export const isToday = isTodayFns
export const isWeekend = isWeekendFns

export const parseDate = parse
export const parseISO = parseISOFns

export const setDate = set

export const setHours = setHoursFns

export const subtractDate = sub

export const subtractBusinessDays = subBusinessDaysFns

export const subtractDays = subDays
export const substractHours = subHours

export const setEndOfDay = (date: DateArgument): Date => {
  return setDate(date, { hours: 23, minutes: 59, seconds: 59 })
}

export const setStartOfDay = (date: DateArgument): Date => {
  return setDate(date, { hours: 0, minutes: 0, seconds: 0 })
}

/**
 * Adjusts the given date by removing the timezone offset.
 *
 * This function creates a new Date object based on the provided date,
 * then adjusts the minutes by adding the timezone offset to it.
 * This effectively removes the timezone offset from the date.
 *
 * The removeTimezoneOffset function is useful when you need to work with dates in a consistent manner, regardless of the user's local timezone.
 * By removing the timezone offset, you can ensure that the date and time are treated as if they are in the UTC timezone.
 * This can be particularly helpful in scenarios where you need to store or compare dates in a standardized format.
 *
 * @param date - The original date object to be adjusted.
 * @returns A new Date object with the timezone offset removed.
 */
export const removeTimezoneOffset = (date: Date): Date => {
  const newDate = new Date(date.getTime())
  newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset())

  return newDate
}

/**
 * Creates a Date object from a given date string, matching Moment.js behavior.
 * Assumes the input date is in local time at midnight.
 * @param dateString - The date string (e.g., "2025-03-21").
 * @param formatString - The format of the date string (default: "yyyy-MM-dd").
 * @returns A Date object representing midnight in local time. (e.g., "2025-03-21T00:00:00.000")
 */
export const parseLocalDateString = (
  dateString: string,
  formatString: string = 'yyyy-MM-dd'
): Date => {
  return parse(dateString, formatString, new Date())
}
