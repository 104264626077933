import { DateFormatHelper, DateHelper } from '@loadsmart/loadsmart-ui'

import { formatTimezoneAbbreviation, getCurrentTimezone } from 'utils/dateUtils'

/**
 * Returns the timezone abbreviation for the current timezone.
 *
 * @returns The timezone abbreviation.
 */
export const formatCurrentTimezoneAbbreviation = () => {
  return formatTimezoneAbbreviation(getCurrentTimezone())
}

export const formatLogDate = (date: string) => {
  return `${DateFormatHelper('MM/DD/YYYY HH:mm:ss').format(
    DateHelper(date, { normalize: false })
  )} (${formatCurrentTimezoneAbbreviation()})`
}
