import type {
  PusherError,
  PusherUnavailableError,
  ConfigurationError,
  PusherNotSupportedError,
  PusherSubscriptionError,
  PusherConnectionError,
  WorkerError,
} from './pusher'

export function extractPusherErrorMessage(error?: PusherError): string | null {
  if (!error) {
    return null
  }

  if (typeof error === 'string') {
    return error
  }

  if ('error' in error) {
    if (typeof (error as PusherSubscriptionError).error === 'string') {
      return (error as PusherSubscriptionError).error
    }
    if (
      typeof (error as PusherConnectionError).error.data?.message === 'string'
    ) {
      return (error as PusherConnectionError).error.data.message
    }
    if ((error as WorkerError).error instanceof Error) {
      return (error as WorkerError).error.message
    }
  }
  if (
    [
      'ConfigurationError',
      'PusherUnavailableError',
      'PusherNotSupportedError',
    ].includes(error.type)
  ) {
    return (
      (
        error as
          | ConfigurationError
          | PusherUnavailableError
          | PusherNotSupportedError
      ).message ?? null
    )
  }

  return null
}
