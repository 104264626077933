import { isAxiosError } from 'axios'
import type { AxiosError, AxiosResponse } from 'axios'

import { assertHasDotAtEnd } from 'utils/strings'

export type FormErrorResponse = {
  [key: string]: string[]
}

function nestedError(value: unknown): string {
  const error = value as string | string[]
  if (Array.isArray(error)) {
    return error
      .map((msg: string | { message: string }) => {
        const errorMsg =
          typeof msg === 'string'
            ? msg
            : Array.isArray(msg)
              ? msg[0]
              : msg.message
        return assertHasDotAtEnd(errorMsg)
      })
      .join(' ')
  }

  if (typeof error === 'object') {
    // used to nested object
    return parseError({ response: { data: error } })
  }

  return assertHasDotAtEnd(error)
}

export function parseError(error?: any) {
  if (!error?.response?.data) {
    return 'Please check your network connection.'
  }

  return assertHasDotAtEnd(
    Object.values(error.response.data).map(nestedError).join(' ')
  )
}

export function isSerializedAxiosError(error: unknown) {
  return error && Object.prototype.hasOwnProperty.call(error, 'statusCode')
}

export const getAxiosErrorResponseData = <T = unknown, D = unknown>(
  error: unknown
): AxiosResponse<T, D>['data'] | null => {
  if (isAxiosError<T, D>(error)) {
    return error.response?.data ?? null
  }

  return null
}

export function serializeAxiosError(error: AxiosError) {
  const method = error.config?.method
  const errorResponseData =
    typeof error.response?.data === 'object' && error.response?.data !== null
      ? error.response.data
      : {}

  const responseData = {
    ...errorResponseData,
    ...error.config?.data,
  }

  if (responseData?.password) {
    responseData.password = '******'
  }

  let statusCode = error.response?.status ?? 0

  if (error.message?.toLowerCase().includes('network error')) {
    statusCode = 0
  }

  return {
    statusCode,
    responseData,
    method,
  }
}

export function isHttpStatus(error: any, ...statuses: number[]) {
  const statusCode = error?.response?.status
  return statuses.includes(statusCode)
}

export function getUploadErrors(errors: any) {
  const errorData = serializeAxiosError(errors)
  if (errorData.responseData.error.errors) {
    return errorData.responseData.error.errors[0]
  }
  const parsedErrors = [] as string[]
  const errorContent = Object.values(errorData.responseData.error)
  errorContent.forEach((e: any) => {
    const keys = Object.keys(e)
    keys.forEach((key) => {
      parsedErrors.push(`"${key}": ${e[key]}`)
    })
  })
  return parsedErrors[0]
}
