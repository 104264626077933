import { useMemo } from 'react'

import type { ListOrder } from 'orders/types'
import { useOrdersList } from 'shipments/hooks/useOrdersList'

export const useLoadOrderData = (selectedOrderUUIDs: string[]) => {
  const { data, isLoading, isSuccess, isError } = useOrdersList(
    {
      limit: 1000,
      offset: 0,
      search_term: null,
      filters: {
        status: null,
        pickup_location: null,
        delivery_location: null,
        pickup_date_after: null,
        pickup_date_before: null,
        delivery_date_after: null,
        delivery_date_before: null,
        uuid: selectedOrderUUIDs,
      },
    },
    selectedOrderUUIDs.length > 0
  )

  const orders: ListOrder[] = useMemo(
    () =>
      data?.results.filter((order) =>
        selectedOrderUUIDs.includes(order.uuid)
      ) ?? [],
    [data, selectedOrderUUIDs]
  )

  return { orders, isLoading, isSuccess, isError }
}
