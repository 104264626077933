import { isEmpty, uniq } from 'lodash-es'

import type {
  TransientCommodity,
  TransientHandlingUnit,
} from 'components/HandlingUnitsManager/HandlingUnits.types'
import type { CommodityPackageType } from 'components/ShippingItemsManager'
import { COMMODITY_PACKAGE_TYPES_MAP } from 'components/ShippingItemsManager'
import type { TransientStop } from 'components/StopsManager'
import {
  createTransientContact,
  createTransientStop,
} from 'components/StopsManager'
import type {
  FulfillmentDetails,
  FulfillmentDetailsHandlingUnit,
} from 'fulfillments/details/ViewFulfillmentPage.data'
import type { FulfillmentHandlingUnitOrderItem } from 'fulfillments/domain/Fulfillment'
import type { ListOrder } from 'orders/types'
import type { PackageType } from 'quotes/Quote'
import { getOrderItemsLowestTemperature } from 'screens/Orders/common/utils'
import type { TransientShipment } from 'screens/Shipper/Shipments/components/Shipment'
import { createTransientShipment } from 'screens/Shipper/Shipments/components/Shipment'
import type { FacilityDetailsV2 } from 'services/facilities'
import type { MODES } from 'utils/constants'
import type { TransientMetadata } from 'utils/transient'
import {
  createTransient,
  getTransientError,
  getTransientErrorsCount,
} from 'utils/transient'
import { resolveTransportationMode } from 'utils/transportationMode'

function getFormattedErrors<T>(
  errors: TransientMetadata<T>['errors'],
  prefix = ''
) {
  return Object.keys(errors).map(
    (field) =>
      `${prefix}${field.split('_').join(' ')}: ${errors[field as keyof T]}`
  )
}

export function getTransientShipmentFromFulfillmentValidationErrors(
  shipment: TransientShipment
) {
  const errors: string[] = []

  errors.push(...getFormattedErrors(getTransientError(shipment)))

  errors.push(
    ...getFormattedErrors(getTransientError(shipment.stops[0])),
    'Pickup facility '
  )

  if (getTransientErrorsCount(shipment.stops[0].contact)) {
    errors.push('Pickup facility contact: required')
  }

  errors.push(
    ...getFormattedErrors(getTransientError(shipment.stops[1])),
    'Delivery facility '
  )

  if (getTransientErrorsCount(shipment.stops[1].contact)) {
    errors.push('Delivery facility contact: required')
  }

  shipment.items.forEach((item, index) => {
    errors.push(
      ...getFormattedErrors(
        getTransientError(item),
        `Shipment item #${index + 1} `
      )
    )

    item.commodities.forEach((commodity) => {
      errors.push(
        ...getFormattedErrors(
          getTransientError(commodity),
          `Commodity ${commodity.description} `
        )
      )
    })
  })

  return errors
}

export function getConvertedPackageType(
  orderItemPackageType: PackageType | null
): CommodityPackageType | null {
  if (
    orderItemPackageType &&
    COMMODITY_PACKAGE_TYPES_MAP.has(
      orderItemPackageType as CommodityPackageType
    )
  ) {
    return orderItemPackageType as CommodityPackageType
  }

  return null
}

function mapTransientCommoditiesFromFulfillmentHandlingUnitOrderItem(
  orderItem: FulfillmentHandlingUnitOrderItem
): TransientCommodity {
  return createTransient({
    description: orderItem.commodity,
    freight_class: orderItem.shipped_freight_class,
    nmfc_code: orderItem.shipped_nmfc_code ?? '',
    package_count: orderItem.package_count,
    package_type: getConvertedPackageType(orderItem.package_type),
    weight: Number(orderItem.total_weight),
    hazmat: false,
    orderItem,
  })
}

function mapTransientShipmentItemsFromFulfillmentHandlingUnit(
  handlingUnit: FulfillmentDetailsHandlingUnit
): TransientHandlingUnit {
  return createTransient({
    id: handlingUnit.uuid,
    commodities: handlingUnit.order_items.map(
      mapTransientCommoditiesFromFulfillmentHandlingUnitOrderItem
    ),
    delivery_stop_index: 1,
    fulfillment_handling_unit: handlingUnit,
    height: handlingUnit.package_height,
    length: handlingUnit.package_length,
    package_count: handlingUnit.package_count,
    package_type: handlingUnit.package_type,
    pickup_stop_index: 0,
    stackable: handlingUnit.stackable,
    turnable: handlingUnit.turnable,
    width: handlingUnit.package_width,
    weight_type: 'all_packages',
  })
}

function mapTransientStopsFromFulfillment(
  fulfillment: FulfillmentDetails,
  pickupFacility: FacilityDetailsV2,
  deliveryFacility: FacilityDetailsV2
): TransientStop[] {
  return [
    createTransientStop({
      facility: pickupFacility,
      contact: createTransientContact(
        fulfillment.pickup_contact_uuid
          ? {
              uuid: fulfillment.pickup_contact_uuid,
            }
          : {}
      ),
      date: fulfillment.pickup_ready_date,
      stop_type: 'pickup',
      stop_index: 0,
      notes: pickupFacility.notes[0]?.note
        ? `${pickupFacility.notes[0]?.note}Fulfillment ${fulfillment.ref_number} stop instructions:\n${fulfillment.pickup_instructions}\n`
        : fulfillment.pickup_instructions,
    }),
    createTransientStop({
      facility: deliveryFacility,
      contact: createTransientContact(
        fulfillment.delivery_contact_uuid
          ? {
              uuid: fulfillment.delivery_contact_uuid,
            }
          : {}
      ),
      date: fulfillment.delivery_date,
      stop_type: 'delivery',
      stop_index: 1,
      notes: deliveryFacility.notes[0]?.note
        ? `${deliveryFacility.notes[0]?.note}Fulfillment ${fulfillment.ref_number} stop instructions:\n${fulfillment.delivery_instructions}\n`
        : fulfillment.delivery_instructions,
    }),
  ]
}

export function createTransientShipmentFromFulfillment(
  fulfillment: FulfillmentDetails,
  orders: ListOrder[],
  pickupFacility: FacilityDetailsV2,
  deliveryFacility: FacilityDetailsV2,
  mode: MODES
) {
  const orderItems = orders.map((order) => order.items).flat()
  const soNumbers = uniq(
    orders
      .map((order) => order.so_number)
      .filter((soNumber) => !isEmpty(soNumber))
  )
  const poNumbers = uniq(
    orders
      .map((order) => order.po_number)
      .filter((poNumber) => !isEmpty(poNumber))
  )
  let equipmentReqs = undefined
  const tempReqs = getOrderItemsLowestTemperature(orderItems)

  if (tempReqs.max_temperature) {
    equipmentReqs = {
      temperature: tempReqs.max_temperature,
      unit: tempReqs.max_temperature_uom,
    }
  }

  let equipment_type = 'DRV'

  if (fulfillment.equipment_type) {
    // give priority to the fulfillment equipment type
    equipment_type = fulfillment.equipment_type
  } else {
    // change the equipment type to the first value different than the default DRV
    const equipmentTypesOtherThanDRV = uniq(
      orders?.map((order) => order.equipment_type)
    ).filter(
      (equipmentType) => Boolean(equipmentType) && equipmentType !== 'DRV'
    )

    if (equipmentTypesOtherThanDRV.length > 0) {
      equipment_type = equipmentTypesOtherThanDRV[0]
    }
  }

  // uses the linked orders' primary ref
  let shipper_ref_number = fulfillment.ref_number
  const ordersPrimaryRefs = orders
    ?.map((order) => order.primary_ref)
    .filter(Boolean)
  if (ordersPrimaryRefs?.length) {
    shipper_ref_number = ordersPrimaryRefs.join(',')
  }

  return createTransientShipment({
    bol_number: '',
    equipment_length: '',
    equipment_type,
    equipment_requirements: equipmentReqs,
    items: fulfillment.handling_units.map(
      mapTransientShipmentItemsFromFulfillmentHandlingUnit
    ),
    mode: resolveTransportationMode(mode) as string,
    po_numbers: poNumbers,
    so_number: soNumbers.join(','),
    so_numbers: soNumbers,
    shipper_ref_number,
    stops: mapTransientStopsFromFulfillment(
      fulfillment,
      pickupFacility,
      deliveryFacility
    ),
  })
}
