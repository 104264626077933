import {
  formatDimensionNumber,
  formatWeight,
} from 'components/ShippingItemsManager'
import type { FulfillmentDetailsHandlingUnit } from 'fulfillments/details/ViewFulfillmentPage.data'
import type { FulfillmentHandlingUnitOrderItem } from 'fulfillments/domain/Fulfillment'
import { numberFormatter } from 'utils/numbers'
import { defaultEmptyString } from 'utils/strings'

export function formatFulfillmentHandlingUnitDimensions(
  handlingUnit: Pick<
    FulfillmentDetailsHandlingUnit,
    'package_height' | 'package_length' | 'package_width'
  >
) {
  return `${numberFormatter(handlingUnit.package_length, 1)}″ L x ${numberFormatter(
    handlingUnit.package_width,
    1
  )}″ W x ${numberFormatter(handlingUnit.package_height, 1)}″ H`
}

export function formatFulfillmentHandlingUnitFreightClass(
  handlingUnit: FulfillmentDetailsHandlingUnit
) {
  let highestClass: string | null = null
  const orderItems = handlingUnit.order_items

  if (orderItems.length) {
    const classesPerItem = orderItems
      .map(
        (orderItem) =>
          orderItem.shipped_freight_class ?? orderItem.freight_class
      )
      .filter((freightClass) => freightClass != null)
      .map(Number)
    if (classesPerItem.length) {
      highestClass = classesPerItem
        .toSorted((a, b) => a - b)
        [classesPerItem.length - 1].toString()
    }
  }

  return defaultEmptyString(highestClass, '-')
}

export function formatFulfillmentHandlingUnitOrderItemShippedWeightComparison(
  orderItem: FulfillmentHandlingUnitOrderItem
) {
  if (
    !orderItem.shipped_package_weight ||
    !orderItem.total_weight ||
    Number.isNaN(Number(orderItem.shipped_package_weight))
  ) {
    return null
  }

  return `${formatDimensionNumber(Number(orderItem.shipped_package_weight))} of ${formatWeight(orderItem.total_weight)}`
}
