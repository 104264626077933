import { get, isEmpty, isUndefined } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useMultipleFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import { createStop, createTransientStop } from 'components/StopsManager'
import type { Stop, TransientStop } from 'components/StopsManager'
import type { FacilityContactV2, FacilityDetailsV2 } from 'services/facilities'

const PRESET_STOP_PARAM_REGEX = /stop_(facility|facility_name|type)_(\d+)/

interface StopData {
  stop_facility: FacilityDetails['uuid']
  stop_type: Stop['stop_type']
  stop_index: Stop['stop_index']
}

export function presetStopToFormTransientStop(stop: Stop): TransientStop {
  const contacts = get(stop, 'facility.contacts', [])

  return {
    ...createTransientStop(stop),
    facility: {
      ...stop.facility,
      _type: 'facility',
    } as FacilityDetailsV2,
    contact:
      contacts.length > 0
        ? ({ ...contacts[0], _type: 'contact' } as FacilityContactV2)
        : null,
  }
}

function urlStopsDataIsValid(stopsData: StopData[]) {
  if (stopsData.length < 2) {
    return false
  }
  const isMissingData =
    stopsData.filter((data) => {
      return isUndefined(data.stop_facility) || isUndefined(data.stop_type)
    }).length > 0

  if (isMissingData) {
    return false
  }
  const sortedStops = [...stopsData].sort(
    (a, b) => a.stop_index! - b.stop_index!
  )
  const hasDifferentIndex = sortedStops.some(
    (data, index) => data.stop_index != index
  )
  if (hasDifferentIndex) {
    return false
  }
  const hasInvalidStopType =
    sortedStops[0].stop_type != 'pickup' ||
    sortedStops[sortedStops.length - 1].stop_type != 'delivery'
  if (hasInvalidStopType) {
    return false
  }
  return true
}

export const usePreSetStops = ({ enabled }: { enabled: boolean }) => {
  const location = useLocation()

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const stopsData: StopData[] = useMemo(() => {
    const params = Array.from(searchParams.keys())
    const readStopsData = params.reduce((acc: any, key) => {
      const match = key.match(PRESET_STOP_PARAM_REGEX)
      if (match) {
        const index = match[2]
        const type = match[1]
        if (!acc[index]) {
          acc[index] = { stop_index: Number(index) }
        }
        acc[index][`stop_${type}`] = searchParams.get(key)
      }
      return acc
    }, {})

    return Object.values(readStopsData)
  }, [searchParams])

  const errorReadingFacilities = useMemo(() => {
    return !isEmpty(stopsData) && !urlStopsDataIsValid(stopsData)
  }, [stopsData])

  const facilities = useMultipleFacilityDetailsV2(
    stopsData.map((data) => data.stop_facility),
    { enabled: !errorReadingFacilities }
  )

  useEffect(() => {
    if (errorReadingFacilities || facilities.isError) {
      toast.error('There was an error pre setting facilities')
    }
  }, [errorReadingFacilities, facilities.isError])

  const stops: Stop[] | null = useMemo(() => {
    if (
      !facilities.isLoading &&
      !facilities.isError &&
      !errorReadingFacilities &&
      enabled
    ) {
      return stopsData.map((data, index) => {
        return createStop({
          stop_index: data.stop_index,
          stop_type: data.stop_type,
          facility: facilities.facilities?.[index] || null,
        })
      })
    }
    return null
  }, [stopsData, facilities, errorReadingFacilities, enabled])

  return {
    stops,
    loadingFacilities: facilities.isLoading,
    errorLoadingFacilities: errorReadingFacilities || facilities.isError,
  }
}
