import {
  Banner,
  Dialog,
  Icon,
  Layout,
  ProgressBar,
  Table,
  Text,
  Tooltip,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { generatePath, NavLink } from 'react-router-dom'

import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { useCreateShipmentLink } from 'fulfillments/hooks/useCreateShipmentLink'
import { appRoutes } from 'router/app-routes'
import { numberFormatter } from 'utils/numbers'
import { plural } from 'utils/strings'

import type { MutationContext } from './OneClickPlanFulfillment.types'

type OneClickPlanResultLoadingHeaderProps = Readonly<{
  mutationsCompleted: number
  shipmentCount: number
}>

export function OneClickPlanResultLoadingHeader({
  mutationsCompleted,
  shipmentCount,
}: OneClickPlanResultLoadingHeaderProps) {
  return (
    <Layout.Stack gap="spacing-1">
      <Layout.Group align="center" justify="space-between">
        <Text variant="body-md-bold">
          Creating {plural('shipment', shipmentCount)}...
        </Text>

        <Text>{numberFormatter(mutationsCompleted * 100, 0)}%</Text>
      </Layout.Group>
      <ProgressBar
        variant="default"
        max={100}
        value={mutationsCompleted * 100}
      />
      <Text color="color-text-tertiary" variant="body-sm">
        This might take a couple of minutes, thanks for waiting
      </Text>
    </Layout.Stack>
  )
}

export function OneClickPlanResultFailedHeader({
  failedMutations,
}: Readonly<{
  failedMutations: number
}>) {
  return (
    <Layout.Stack>
      <Banner variant="danger">
        <Banner.Title>
          Unable to create {failedMutations}{' '}
          {plural('shipment', failedMutations)} due to incomplete data
        </Banner.Title>
        <Banner.Description />
      </Banner>
    </Layout.Stack>
  )
}

export function OneClickPlanResultSuccessHeader({
  shipmentsCreated,
}: Readonly<{
  shipmentsCreated: number
}>) {
  return (
    <Layout.Stack>
      <Banner variant="success">
        <Banner.Title>
          {shipmentsCreated} {plural('shipment', shipmentsCreated)} created
        </Banner.Title>
        <Banner.Description />
      </Banner>
    </Layout.Stack>
  )
}

export type OneClickPlanFulfillmentResultContentHeaderProps = Readonly<{
  mutations: Record<string, MutationContext>
  mutationsCompleted: number
  fulfillments: ListFulfillment[]
}>

function OneClickPlanFulfillmentResultContentHeader({
  mutations,
  mutationsCompleted,
  fulfillments,
}: OneClickPlanFulfillmentResultContentHeaderProps) {
  if (mutationsCompleted < 1) {
    return (
      <OneClickPlanResultLoadingHeader
        mutationsCompleted={mutationsCompleted}
        shipmentCount={fulfillments.length}
      />
    )
  }

  const keys = Object.keys(mutations)
  const failedMutations = keys.filter((key) => mutations[key].isError).length

  if (failedMutations) {
    return <OneClickPlanResultFailedHeader failedMutations={failedMutations} />
  }

  return <OneClickPlanResultSuccessHeader shipmentsCreated={keys.length} />
}

export type StatusCellProps = Readonly<{
  errors?: string[]
  isError?: boolean
  isLoading?: boolean
  isSuccess?: boolean
  validationErrors?: string[]
}>

export function StatusCell({
  errors,
  isError,
  isLoading,
  isSuccess,
}: StatusCellProps) {
  if (isError) {
    return (
      <Layout.Group align="center" gap="spacing-1">
        <Text color="color-text-error" variant="body-sm">
          Failed to create shipment
        </Text>
        {errors?.length ? (
          <Tooltip
            message={
              <Layout.Stack gap="spacing-2">
                {errors.map((error, index) => (
                  <Text key={`${index}-${error}`} color="color-text-inverted">
                    {error}
                  </Text>
                ))}
              </Layout.Stack>
            }
            placement="top"
          >
            <Icon color="color-text-error" name="info-circle" size="12px" />
          </Tooltip>
        ) : null}
      </Layout.Group>
    )
  }

  if (isSuccess) {
    return (
      <Layout.Group align="center" gap="spacing-1">
        <Icon
          name="check"
          size="12px"
          style={{ color: toCSSValue('color-success-100') }}
        />
        <Text color="color-success-100" variant="body-sm">
          Shipment created
        </Text>
      </Layout.Group>
    )
  }

  if (isLoading) {
    return (
      <Layout.Group align="center" gap="spacing-1">
        <Icon
          name="clock"
          size="12px"
          style={{ color: toCSSValue('color-text-highlight') }}
        />
        <Text color="color-text-highlight" variant="body-sm">
          Creating shipment...
        </Text>
      </Layout.Group>
    )
  }

  return null
}

export type ShipmentLinkCellProps = Readonly<{
  newPage?: boolean
  shipmentUUID?: string
}>

export function ShipmentLinkCell({
  newPage = true,
  shipmentUUID,
}: ShipmentLinkCellProps) {
  if (!shipmentUUID) {
    return null
  }

  return (
    <NavLink
      target={newPage && process.env.NODE_ENV != 'test' ? '_blank' : undefined}
      to={{
        pathname: generatePath(appRoutes.shipmentDetails, {
          id: shipmentUUID,
        }),
      }}
      style={{ textDecoration: 'none' }}
    >
      <Layout.Group align="center" gap="spacing-1" justify="flex-end">
        <Icon
          name="arrow-corner-up"
          size="12px"
          style={{ color: toCSSValue('color-text-link') }}
        />
        <Text color="color-text-link" variant="body-sm">
          Go to shipment
        </Text>
      </Layout.Group>
    </NavLink>
  )
}

export type ManualPlanLinkCellProps = Readonly<{
  fulfillment: ListFulfillment
}>

export function ManualPlanLinkCell({ fulfillment }: ManualPlanLinkCellProps) {
  const { link } = useCreateShipmentLink(fulfillment)

  return (
    <NavLink
      target={process.env.NODE_ENV != 'test' ? '_blank' : undefined}
      to={link}
      style={{ textDecoration: 'none' }}
    >
      <Layout.Group align="center" gap="spacing-1" justify="flex-end">
        <Icon
          name="export"
          size="12px"
          style={{ color: toCSSValue('color-text-link') }}
        />
        <Text color="color-text-link" variant="body-sm">
          Create manually
        </Text>
      </Layout.Group>
    </NavLink>
  )
}

export type OneClickPlanFulfillmentResultContentTableRowProps = Readonly<{
  mutationContext?: MutationContext
  fulfillment: ListFulfillment
}>

export function OneClickPlanFulfillmentResultContentTableRow({
  mutationContext,
  fulfillment,
}: OneClickPlanFulfillmentResultContentTableRowProps) {
  return (
    <Table.Row>
      <Table.Cell
        style={{
          paddingBottom: toCSSValue('spacing-4'),
          paddingTop: toCSSValue('spacing-4'),
        }}
      >
        {fulfillment.ref_number}
      </Table.Cell>

      <Table.Cell
        style={{
          paddingBottom: toCSSValue('spacing-4'),
          paddingTop: toCSSValue('spacing-4'),
        }}
      >
        <StatusCell
          errors={mutationContext?.errors}
          isError={mutationContext?.isError}
          isLoading={mutationContext?.isLoading}
          isSuccess={mutationContext?.isSuccess}
        />
      </Table.Cell>

      <Table.Cell
        style={{
          paddingBottom: toCSSValue('spacing-4'),
          paddingTop: toCSSValue('spacing-4'),
        }}
      >
        {mutationContext?.isSuccess ? (
          <ShipmentLinkCell shipmentUUID={mutationContext?.response?.uuid} />
        ) : null}
        {mutationContext?.isError ? (
          <ManualPlanLinkCell fulfillment={fulfillment} />
        ) : null}
      </Table.Cell>
    </Table.Row>
  )
}

type OneClickPlanFulfillmentResultContentProps = Readonly<{
  fulfillments: ListFulfillment[]
  mutations: Record<string, MutationContext>
  mutationsCompleted: number
  onClose: () => void
}>

export default function OneClickPlanFulfillmentResultContent({
  fulfillments,
  mutations,
  mutationsCompleted,
  onClose,
}: OneClickPlanFulfillmentResultContentProps) {
  return (
    <>
      <Dialog.Body>
        <Layout.Stack gap="spacing-6">
          <OneClickPlanFulfillmentResultContentHeader
            mutations={mutations}
            mutationsCompleted={mutationsCompleted}
            fulfillments={fulfillments}
          />

          <Table size="small">
            <Table.Head>
              <Table.Row>
                <Table.HeadCell style={{ width: '150px' }}>
                  Fulfillment
                </Table.HeadCell>
                <Table.HeadCell>Status</Table.HeadCell>
                <Table.HeadCell />
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {fulfillments.map((fulfillment) => (
                <OneClickPlanFulfillmentResultContentTableRow
                  key={fulfillment.uuid}
                  mutationContext={mutations[fulfillment.uuid]}
                  fulfillment={fulfillment}
                />
              ))}
            </Table.Body>
          </Table>
        </Layout.Stack>
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionPrimary
          disabled={mutationsCompleted < 1}
          onClick={onClose}
        >
          Close
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </>
  )
}
