import type {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
} from 'axios'
import axios from 'axios'

import { userUtils } from '_shared_/user'
import { appRoutes } from 'router/app-routes'
import { refreshToken } from 'services/auth'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import {
  BASE_URL,
  TIMEOUT,
  SENTRY_IGNORED_ERROR_MESSAGES,
} from 'utils/constants'
import logger from 'utils/logger'
import { urlCleaner } from 'utils/url'

import { serializeAxiosError } from './errors'
import {
  getShipperUUIDFromLocalStorage,
  shouldNotAppendLocationUUIDToThisPath,
} from './shipperLocationManagement'

interface AxiosRequestConfigWithMeta extends AxiosRequestConfig {
  meta?: {
    requestStartedAt?: number
    retries?: number
  }
}

export const STATUS = {
  HTTP_200_OK: 200,
  HTTP_204_NO_CONTENT: 204,
  HTTP_401_UNAUTHORIZED: 401,
  HTTP_404_NOT_FOUND: 404,
  HTTP_500_INTERNAL_SERVER_ERROR: 500,
  HTTP_502_BAD_GATEWAY: 502,
  HTTP_504_GATEWAY_TIMEOUT: 504,
}

function logoutIfNeeded() {
  if (globalThis.location.pathname !== userUtils.loginURL) {
    analytics.track(
      'Redirect User to Login',
      AnalyticsEventTrigger.redirect,
      {}
    )

    globalThis.location.replace(appRoutes.logout)
  }
}

function logError(error: AxiosError) {
  // @ts-expect-error -- need to be refactored to use the correct type
  const url = error.config.url

  logger.error(`Axios error interceptor at ${url}`, serializeAxiosError(error))
}

function logErrorRetry(error: AxiosError) {
  const { url, meta } = error.config as AxiosRequestConfigWithMeta

  logger.error(
    `Axios retrying request at ${url} [retries: ${meta?.retries ?? 0}]`,
    serializeAxiosError(error)
  )
}

function logDuration(config: AxiosRequestConfigWithMeta) {
  if (config.meta?.requestStartedAt) {
    const duration = new Date().getTime() - config.meta.requestStartedAt
    logger.info('finished request', {
      duration,
      url: config.url,
      method: config.method?.toUpperCase(),
      cleanUrl: urlCleaner(config.url ?? ''),
    })
  }
}

function appendShipperUUIDToQueryParams(config: AxiosRequestConfigWithMeta) {
  if (!userUtils.user?.is_supervisor) {
    return
  }

  if (shouldNotAppendLocationUUIDToThisPath(config.url, config.method)) {
    return
  }

  const shipperUUID = getShipperUUIDFromLocalStorage()

  if (!shipperUUID) {
    return
  }

  if (config.params instanceof URLSearchParams) {
    config.params.append('shipper_uuid', shipperUUID)
  } else {
    config.params = {
      ...config.params,
      ...{ shipper_uuid: shipperUUID },
    }
  }
}

export const createHttpClient = (
  { baseURL } = { baseURL: BASE_URL }
): AxiosInstance => {
  const headers: Record<string, string> = { 'Content-Type': 'application/json' }
  const defaultTransformRequests = Array.isArray(
    axios.defaults.transformRequest
  )
    ? axios.defaults.transformRequest
    : axios.defaults.transformRequest
      ? [axios.defaults.transformRequest]
      : []

  const client = axios.create({
    headers,
    withCredentials: true,
    baseURL: baseURL || BASE_URL,
    // the max timeout for aws apigateway is 29s, we add some fat
    timeout: TIMEOUT,
    transformRequest: [
      // eslint-disable-next-line @typescript-eslint/no-shadow
      function (data: any, headers: any) {
        const token = userUtils.accessToken
        headers['Authorization'] = token ? `Bearer ${token}` : undefined
        return data
      },
      ...defaultTransformRequests,
    ],
  })

  const isUnauthorized = (response: AxiosResponse | undefined): boolean =>
    response?.status === STATUS.HTTP_401_UNAUTHORIZED

  const isRefreshTokenURL = (url: string | undefined): boolean =>
    !!(url && url.search('token/refresh') >= 0)

  const isWrongCredential = (error: AxiosError): boolean =>
    error.response?.status === STATUS.HTTP_401_UNAUTHORIZED &&
    // @ts-expect-error -- need to be refactored to use the correct type
    !!((error.config.url?.search('auth/token') ?? -1) >= 0)

  const isCloudFrontError = (error: AxiosError): boolean => {
    const status = error.response?.status ?? 0
    return (
      status === STATUS.HTTP_502_BAD_GATEWAY ||
      status === STATUS.HTTP_504_GATEWAY_TIMEOUT ||
      error.response?.headers?.['x-amzn-ErrorType'] ===
        'InternalServerErrorException'
    )
  }

  const isInternalServerError = (error: AxiosError): boolean => {
    const status = error.response?.status ?? 0
    return status >= STATUS.HTTP_500_INTERNAL_SERVER_ERROR
  }

  const shouldRejectRequest = (error: AxiosError): boolean => {
    return (
      isWrongCredential(error) ||
      isInternalServerError(error) ||
      !isUnauthorized(error.response)
    )
  }

  const shouldRedirectToLogin = (
    config: AxiosRequestConfigWithMeta
  ): boolean => {
    return isRefreshTokenURL(config.url) || !userUtils.refreshToken
  }

  const shouldRetryCloudfront = (
    error: AxiosError,
    maxRetries = 2
  ): boolean => {
    const { meta } = error.config as AxiosRequestConfigWithMeta
    return (
      isCloudFrontError(error) && !!meta && (meta.retries ?? 0) < maxRetries
    )
  }

  let refreshingTokenRequest: Promise<void> | null = null

  // @ts-expect-error -- need to be refactored to use the correct type
  client.interceptors.request.use((config: AxiosRequestConfigWithMeta) => {
    config.meta = config.meta || {}
    config.meta.requestStartedAt = new Date().getTime()

    appendShipperUUIDToQueryParams(config)

    return config
  })

  client.interceptors.response.use(
    (response: AxiosResponse) => {
      const { config } = response

      logDuration(config)

      return response
    },
    async (error: AxiosError) => {
      logError(error)
      // @ts-expect-error -- need to be refactored
      logDuration(error.config)

      if (
        // @ts-expect-error -- need to be refactored
        SENTRY_IGNORED_ERROR_MESSAGES.includes(error.response?.data?.detail)
      ) {
        // @ts-expect-error -- need to be refactored
        error.message = error.response?.data?.detail
      }

      const config = error.config as AxiosRequestConfigWithMeta

      // Cloudfront can throw a 502/504 when the backend is too slow.
      if (shouldRetryCloudfront(error)) {
        if (config.meta) {
          config.meta.retries = (config.meta.retries ?? 0) + 1
        }
        logErrorRetry(error)
        return axios.request(config)
      }

      if (shouldRejectRequest(error)) {
        return Promise.reject(error)
      }

      if (shouldRedirectToLogin(config)) {
        logoutIfNeeded()
      }

      try {
        /**
         * This implementation aims to avoid multiple requests to the
         * refresh token endpoint at the same time.
         * If any request reach this point and a refresh token
         * request is already happening it'll await this current
         * request in order to avoid multiple calls to the refresh
         * token endpoint.
         * Due the single threaded nature of JS inside browsers
         * we do not need to use a mutex in order to protect the
         * refreshingTokenRequest variable
         */
        if (!refreshingTokenRequest) {
          refreshingTokenRequest = refreshToken()
        }
        await refreshingTokenRequest
        refreshingTokenRequest = null
        const token = userUtils.accessToken

        if (config) {
          if (config?.headers) {
            config.headers.Authorization = `Bearer ${token}`
          } else {
            config.headers = {
              Authorization: `Bearer ${token}`,
            } as AxiosRequestHeaders
          }
          return axios.request(config)
        }
      } catch (refreshTokenError) {
        logoutIfNeeded()

        //Just to avoid "TypeError: "x" has no properties" in any pending request
        return Promise.reject(
          new Error('Error on refreshing token', { cause: refreshTokenError })
        )
      }
    }
  )

  return client
}

export default createHttpClient()
