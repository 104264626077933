import { isEmpty } from 'lodash-es'

export const cleanEmptyValues = <T extends object>(obj: T): Partial<T> => {
  return (Object.keys(obj) as (keyof T)[]).reduce((acc, curr) => {
    if (typeof obj[curr] !== 'boolean' && isEmpty(obj[curr])) {
      return acc
    }

    return {
      ...acc,
      [curr]: obj[curr],
    }
  }, {}) as Partial<T>
}
