import { IconClose, IconInfoCircle } from '@loadsmart/icons'
import {
  Card,
  Label,
  Layout,
  Link,
  Select,
  Switch,
  Text,
  Tooltip,
  TooltipPosition,
} from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { Button } from '@loadsmart/miranda-react'
import { isEqual } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'
import type { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'

import { CloseSidebarWrapper, TitleContainer } from 'components/SideBar/styles'
import { usePreventPageScrollOver } from 'hooks/usePreventPageScrollOver'
import {
  Container,
  InnerContainerScroll,
} from 'rfp/rfp-details/components/routing-guide/styles'
import { theme } from 'styles/theme'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

const Footer = styled.div`
  background: ${({ theme: loadsmartTheme }) =>
    loadsmartTheme.colors.backgroundWhiteNeutral};
  bottom: 0;
  box-shadow: ${({ theme: loadsmartTheme }) => loadsmartTheme.shadows.shadow01};
  padding: ${getToken('space-l')};
  width: 600px;
`

const Title = styled(Card.Title)`
  margin: 0;
  padding: 0;
`

const SwitchWrapper = styled.div`
  margin-right: ${getToken('space-m')};
`

type Filters = {
  carriers: string | null
  types: string | null
  backup: string | null
  splitCarriers?: string | null
}

type FilterOption = {
  value: string
  label: string
  _type?: string
}

type SelectFilters = {
  carriers: FilterOption[]
  types: FilterOption[]
  backup: FilterOption[]
  splitCarriers: FilterOption[]
}

type SelectedFilters = {
  carrierFilter: string | null
  typesFilter: string | null
  backupFilter: string | null
  splitCarriersFilter: string | null
}

type AwardScenariosFiltersProps = {
  readonly onClose: () => void
  readonly applyFilters: (filters: Filters) => void
  readonly selectedFilters: SelectedFilters
  readonly clear: () => void
  readonly backupFilterActive: boolean
  readonly setBackupFilterActive: Dispatch<SetStateAction<boolean>>
  readonly splitCarriersFilterActive: boolean
  readonly setSplitCarriersFilterActive: Dispatch<SetStateAction<boolean>>
}

const CARRIERS_OPTIONS: FilterOption[] = [
  { label: 'Incumbent', value: 'incumbent' },
  { label: 'Challengers', value: 'challenger' },
]

const TYPES_OPTIONS: FilterOption[] = [
  { label: 'Asset', value: 'asset' },
  { label: 'Broker', value: 'broker' },
]

const BACKUP_OPTIONS: FilterOption[] = Array.from(Array(10).keys()).map(
  (value) => ({
    label: `${value + 1}`,
    value: `${value + 1}`,
  })
)

const SPLIT_CARRIERS_OPTIONS: FilterOption[] = Array.from(Array(5).keys()).map(
  (value) => ({
    label: `${value + 1}`,
    value: `${value + 1}`,
  })
)

const TOTAL_CARRIERS_AND_TYPES_OPTIONS = 2

const setFilterValue = (options: FilterOption[], value: string | null) => {
  if (value === 'any') {
    return options
  }

  return options.filter((item) => item.value === value)
}

const getFilterValue = (
  filter: FilterOption[] | FilterOption
): string | null => {
  // For single select, we need a different logic because the filter it's not an array
  if (!Array.isArray(filter)) {
    return filter.value
  }

  if (filter.length === 0) {
    return null
  }

  if (filter.length === TOTAL_CARRIERS_AND_TYPES_OPTIONS) {
    return 'any'
  }

  return filter.map((item) => item.value)[0]
}

const SplitVolumeSelectWrapper = styled.div`
  width: 140px;
`

const AwardScenariosFilters = ({
  onClose,
  applyFilters,
  clear,
  selectedFilters,
  backupFilterActive,
  setBackupFilterActive,
  setSplitCarriersFilterActive,
  splitCarriersFilterActive,
}: AwardScenariosFiltersProps) => {
  const scrollerRef = useRef<HTMLDivElement>(null)
  usePreventPageScrollOver(scrollerRef)
  const [filters, setFilters] = useState<SelectFilters>({
    carriers: [],
    types: [],
    backup: [],
    splitCarriers: [],
  })
  const queryClient = useQueryClient()

  const [backupActive, setBackupActive] = useState<boolean>(backupFilterActive)
  const [splitCarriersActive, setSplitCarriersActive] = useState<boolean>(
    splitCarriersFilterActive
  )

  useEffect(() => setBackupActive(backupFilterActive), [backupFilterActive])

  useEffect(
    () => setSplitCarriersActive(splitCarriersFilterActive),
    [splitCarriersFilterActive]
  )

  useEffect(() => {
    setFilters({
      carriers: setFilterValue(CARRIERS_OPTIONS, selectedFilters.carrierFilter),
      types: setFilterValue(TYPES_OPTIONS, selectedFilters.typesFilter),
      backup: setFilterValue(BACKUP_OPTIONS, selectedFilters.backupFilter),
      splitCarriers: setFilterValue(
        SPLIT_CARRIERS_OPTIONS,
        selectedFilters.splitCarriersFilter
      ),
    })
  }, [selectedFilters])

  const onChangeFilters = (type: string, selectedOption: FilterOption[]) => {
    setFilters({ ...filters, [type]: selectedOption })
  }

  const onSave = () => {
    const filtersToCompare = {
      backupFilter: getFilterValue(filters.backup),
      carrierFilter: getFilterValue(filters.carriers),
      splitCarriersFilter: getFilterValue(filters.splitCarriers),
      typesFilter: getFilterValue(filters.types),
    }

    if (isEqual(filtersToCompare, selectedFilters)) {
      queryClient.refetchQueries({ queryKey: ['awardScenarios'], exact: false })
    } else {
      applyFilters({
        carriers: getFilterValue(filters.carriers),
        types: getFilterValue(filters.types),
        backup: getFilterValue(filters.backup),
        splitCarriers: getFilterValue(filters.splitCarriers),
      })
    }
    onClose()
    setBackupFilterActive(backupActive)
    setSplitCarriersFilterActive(splitCarriersActive)
    analytics.track('Award Scenarios / Filter', AnalyticsEventTrigger.click, {
      carrier: filters.carriers.length > 0,
      types: filters.types.length > 0,
      backup: filters.backup.length > 0,
      split_carriers: filters.splitCarriers.length > 0,
    })

    analytics.track(
      'Award Scenarios / Backup Carriers Filter Active',
      AnalyticsEventTrigger.click,
      { active: backupActive }
    )

    analytics.track(
      'Award Scenarios / Split Carriers Filter Active',
      AnalyticsEventTrigger.click,
      { active: splitCarriersActive }
    )
  }

  return (
    <Container>
      <TitleContainer>
        <Title>Add Rules</Title>
        <CloseSidebarWrapper onClick={onClose}>
          <IconClose width={16} height={16} />
        </CloseSidebarWrapper>
      </TitleContainer>
      <InnerContainerScroll ref={scrollerRef}>
        <Layout.Stack>
          <Layout.Group align="center" style={{ marginBottom: '8px' }}>
            <Text variant="caption-bold" color="color-neutral">
              SCENARIO
            </Text>
            <Layout.Group align="center" space="xs">
              <Text variant="heading-sm-bold" color="color-neutral-darker">
                Lowest Bids
              </Text>
              <Tooltip
                style={{ maxHeight: '16px' }}
                position={TooltipPosition.Bottom}
                message={
                  <>
                    The underlying carrier ranking is based on the Lowest Bid
                    carrier once the filtered scenarios have been selected.
                  </>
                }
              >
                <IconInfoCircle
                  height={16}
                  width={16}
                  fill={theme.colors.neutral}
                  title={null}
                />
              </Tooltip>
            </Layout.Group>
            <Link
              href="https://community.loadsmart.com/hc/en-us/articles/17102472332947-How-to-use-Award-Scenarios"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'underline',
                fontWeight: '800',
                fontSize: 14,
                marginLeft: 'auto',
              }}
            >
              How to customize your awarding process.
            </Link>
          </Layout.Group>
          <Card>
            <Card.Title>Carriers</Card.Title>
            <Card.Subtitle>Create scenarios based on incumbency</Card.Subtitle>
            <Card.Separator />
            <Card.Body>
              <Layout.Stack space="xs">
                <Label htmlFor="incumbency">Carriers Incumbency</Label>
                <Select
                  multiple
                  placeholder="Select"
                  id="incumbency"
                  name="incumbency"
                  options={CARRIERS_OPTIONS}
                  value={filters.carriers}
                  onChange={(event) =>
                    onChangeFilters(
                      'carriers',
                      (event.target.value as FilterOption[]) ?? []
                    )
                  }
                />
              </Layout.Stack>
            </Card.Body>
          </Card>
          <Card>
            <Card.Title>Type</Card.Title>
            <Card.Subtitle>
              Create scenarios based on carriers type
            </Card.Subtitle>
            <Card.Separator />
            <Card.Body>
              <Layout.Stack space="xs">
                <Label htmlFor="type">Carrier Type</Label>
                <Select
                  multiple
                  placeholder="Select"
                  id="type"
                  name="type"
                  options={TYPES_OPTIONS}
                  value={filters.types}
                  onChange={(event) =>
                    onChangeFilters(
                      'types',
                      (event.target.value as FilterOption[]) ?? []
                    )
                  }
                />
              </Layout.Stack>
            </Card.Body>
          </Card>
          <Card>
            <Layout.Group align="center" justify="space-between">
              <Layout.Stack>
                <Card.Title>Split Volume</Card.Title>
                <Card.Subtitle>
                  Split primary to cover 100% the volume
                </Card.Subtitle>
              </Layout.Stack>
              <SwitchWrapper>
                <Switch
                  active={splitCarriersActive}
                  onToggle={(event: ChangeEvent<HTMLInputElement>) => {
                    setSplitCarriersActive(event.target.checked)
                  }}
                />
              </SwitchWrapper>
            </Layout.Group>
            <Card.Separator />
            <Card.Body>
              <Layout.Group space="s" align="center">
                <Text variant="body">Split between at most</Text>
                <SplitVolumeSelectWrapper>
                  <Select
                    placeholder="carriers #"
                    id="split-carriers"
                    name="split-carriers"
                    options={SPLIT_CARRIERS_OPTIONS}
                    value={filters.splitCarriers}
                    onChange={(event) => {
                      onChangeFilters(
                        'splitCarriers',
                        (event.target.value as FilterOption[]) ?? []
                      )

                      if (!splitCarriersActive) {
                        setSplitCarriersActive(true)
                      }
                    }}
                  />
                </SplitVolumeSelectWrapper>
                <Text variant="body">carriers</Text>
              </Layout.Group>
            </Card.Body>
          </Card>
          <Card>
            <Layout.Group align="center" justify="space-between">
              <Layout.Stack>
                <Card.Title>Backup Carriers</Card.Title>
                <Card.Subtitle>
                  Set a number of backup carriers for each lane
                </Card.Subtitle>
              </Layout.Stack>
              <SwitchWrapper>
                <Switch
                  active={backupActive}
                  onToggle={(event: ChangeEvent<HTMLInputElement>) => {
                    setBackupActive(event.target.checked)
                  }}
                />
              </SwitchWrapper>
            </Layout.Group>
            <Card.Separator />
            <Card.Body>
              <Layout.Stack space="xs">
                <Label htmlFor="backup">Backup Carriers</Label>
                <Select
                  placeholder="Select"
                  id="backup"
                  name="backup"
                  options={BACKUP_OPTIONS}
                  value={filters.backup}
                  onChange={(event) => {
                    onChangeFilters(
                      'backup',
                      (event.target.value as FilterOption[]) ?? []
                    )

                    if (!backupActive) {
                      setBackupActive(true)
                    }
                  }}
                />
              </Layout.Stack>
            </Card.Body>
          </Card>
        </Layout.Stack>
      </InnerContainerScroll>
      <Footer>
        <Layout.Group space="s" justify="flex-start">
          <Button variant="primary" onClick={() => onSave()}>
            Save and close
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              clear()
              onClose()
            }}
          >
            Reset
          </Button>
        </Layout.Group>
      </Footer>
    </Container>
  )
}

export default AwardScenariosFilters
