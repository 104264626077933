import { Layout, Tooltip, TooltipPosition } from '@loadsmart/loadsmart-ui'
import type { Dispatch, MouseEvent, SetStateAction } from 'react'
import type { UseTableRowProps } from 'react-table'

import StringLimiter from '_shared_/components/StringLimiter'
import LaneAddress from 'components/LaneAddress'
import { BidsCell } from 'components/LaneManagement/LanesTableCells'
import {
  DestinationWrapper,
  StopBadge,
} from 'components/LaneManagement/LanesTableV2/styles'
import type { TableData } from 'components/LaneManagement/LanesTableV2/types'
import { getLaneStatus } from 'components/LaneManagement/LanesTableV2/utils'
import {
  ActionButton,
  PriceChangeIndicator,
} from 'components/LaneManagement/LanesTableV3/styles'
import LinkButton from 'components/LinkButton'
import IncumbentTooltip from 'rfp/rfp-details/award-scenarios/IncumbentTooltip'
import { AllocationCell } from 'rfp/rfp-details/award-scenarios/lanes-allocated-table/Cells'
import { TableHeaderDropdown } from 'rfp/rfp-details/award-scenarios/TableHeaderDropdown'
import LaneStatusIndicator from 'rfp/rfp-details/components/lane-status-indicator'
import MarketBenchmarkTooltip from 'rfp/rfp-details/components/live-market-rate/MarketBenchmarkTooltip'
import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { Currency, RequestForProposal } from 'rfp/rfp.types'
import { formatAddress, formatStop, getAdditionalStops } from 'utils/address'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { BID_TYPE, RFP_STATE } from 'utils/constants'
import { isValidLane } from 'utils/lanes'
import { numberFormatter } from 'utils/numbers'

import {
  getDistanceTypeLabel,
  getLaneMileage,
  getPerDistanceRate,
  getPerDistanceRateLabel,
  isKilometers,
} from '../distanceType'
import ModeAndEquipmentTag from '../mode-and-equipment-tag'
import RankedProposalBadge from '../RankedProposalBadge'

type TableRow = UseTableRowProps<TableData>

type CustomLanePreference = Pick<
  LanePreference,
  'mode' | 'equipment_type' | 'extra_fields'
>

export const getLaneStatusColumn = (rfp: RequestForProposal) => ({
  Header: '',
  id: 'state',
  className: 'text-center small',
  accessor: (lane: TableData) => getLaneStatus(lane, rfp.state),
  Cell: function CellLaneState({ row }: { readonly row: TableRow }) {
    const lane = row.original as Lane

    return (
      <LaneStatusIndicator
        lane={lane}
        rfpState={rfp.state as RfpState}
        isSpotMode={rfp.capacity_time_period === 'spot'}
      />
    )
  },
})

export const getLaneIdColumn = (laneIdMaxLength: number) => ({
  Header: 'LANE ID',
  id: 'lane_id',
  accessor: 'lane_id',
  className: 'text-left limit-size',
  limitSize: true,
  Cell: function CellLaneId({
    value,
  }: {
    readonly value: TableData['lane_id']
  }) {
    return <StringLimiter limiter={laneIdMaxLength} value={value} />
  },
})

export const getOriginOrPickupColumn = () => ({
  Header: 'ORIGIN/PICKUP',
  id: 'pickup_city',
  className: 'text-left',
  accessor: (lane: TableData) => {
    return formatStop(lane.stops[0])
  },
  Cell: function CellOrigin({ value }: { readonly value: Address }) {
    return <LaneAddress location={value} />
  },
})

export const getDestOrDeliveryColumn = () => ({
  Header: 'DESTINATION/DELIVERY',
  id: 'delivery_city',
  className: 'text-left',
  Cell: function CellDestination({ row }: { readonly row: TableRow }) {
    const { stops } = row.original as Lane

    const additionalStops = getAdditionalStops(stops)

    const destination = formatStop(stops.slice(-1)[0])

    return (
      <DestinationWrapper>
        <LaneAddress location={destination} />
        {additionalStops.length > 0 && (
          <Tooltip
            message={additionalStops
              .map((stop: any) =>
                formatAddress(stop.city, stop.state, stop.zipcode, stop.country)
              )
              .toString()}
            position={TooltipPosition.Bottom}
          >
            <StopBadge className="is-disabled" size="small" variant="main">
              <b>+{additionalStops.length}</b>
            </StopBadge>
          </Tooltip>
        )}
      </DestinationWrapper>
    )
  },
})

export const getMileageColumn = (
  rfpDistanceType: RequestForProposal['distance_type']
) => ({
  Header: getDistanceTypeLabel(rfpDistanceType).toLocaleUpperCase(),
  id: 'mileage',
  accessor: (lane: Lane) =>
    numberFormatter(getLaneMileage(rfpDistanceType, lane)),
  className: 'text-left',
})

const buildPreferencesFromPrimaryCarriers = (
  primaryCarriers: CustomLanePreference[]
) => {
  return primaryCarriers.map((carrier) => ({
    mode: carrier.mode,
    equipment_type: carrier.equipment_type,
    extra_fields: {},
  }))
}

export const getAwardScenariosModeAndEquipmentColumn = () => ({
  Header: 'MODE & EQUIPMENT',
  id: 'preferences__equipment_type',
  accessor: 'primary_carriers',
  className: 'text-left',
  Cell: function CellEquipmentType({
    value,
  }: {
    readonly value: CustomLanePreference[]
  }) {
    if (!value) {
      return undefined
    }

    return (
      <ModeAndEquipmentTag
        preferences={buildPreferencesFromPrimaryCarriers(value)}
      />
    )
  },
})

export const getRankAutomationModeAndEquipmentColumn = () => ({
  Header: 'MODE & EQUIPMENT',
  id: 'preferences__equipment_type',
  accessor: 'ranked_proposals',
  className: 'text-left',
  Cell: function CellEquipmentType({
    value,
  }: {
    readonly value?: { preference: LanePreference }[]
  }) {
    if (!value) {
      return undefined
    }

    const preferences = value.map((proposal) => proposal.preference)

    return <ModeAndEquipmentTag preferences={preferences} />
  },
})

export const getModeAndEquipmentColumn = () => ({
  Header: 'MODE & EQUIPMENT',
  id: 'preferences__equipment_type',
  accessor: 'equipment_type',
  className: 'text-left',
  Cell: function CellEquipmentType({ row }: { readonly row: TableRow }) {
    const { preferences } = row.original as Lane

    if (!preferences) {
      return undefined
    }

    return <ModeAndEquipmentTag preferences={preferences} />
  },
})

export const getVolumeColumn = () => ({
  Header: 'VOLUME',
  id: 'volume',
  className: 'text-left',
  accessor: ({ volume }: { volume: string | number | undefined }) =>
    numberFormatter(volume),
})

/* Award Scenarios columns */

const LaneIndicator = ({
  row,
  isRankedAward,
  rfpState,
  isSpotMode,
}: {
  readonly row: TableRow
  readonly isRankedAward: boolean
  readonly rfpState: string
  readonly isSpotMode: boolean
}) => {
  const lane = row.original as Lane
  if (isRankedAward && isValidLane(lane)) {
    return (
      <RankedProposalBadge
        isRanked={
          !!lane?.awarded_proposals && lane.awarded_proposals.length > 0
        }
      />
    )
  }
  return (
    <LaneStatusIndicator
      lane={lane}
      rfpState={rfpState as RfpState}
      isSpotMode={isSpotMode}
    />
  )
}

export const getLaneIndicatorColumn = (
  rfpState: string,
  isRankedAward: boolean,
  isSpotMode: boolean
) => ({
  Header: '',
  id: 'state',
  className: 'text-center small',
  accessor: (lane: TableData) => getLaneStatus(lane, rfpState),
  Cell: function CellLaneState({ row }: { readonly row: TableRow }) {
    return (
      <LaneIndicator
        row={row}
        isRankedAward={isRankedAward}
        rfpState={rfpState}
        isSpotMode={isSpotMode}
      />
    )
  },
})

export const getActionColumn = (rfpState: string) => ({
  Header: '',
  id: 'actions',
  className: 'flex flex-right',
  style: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  Cell: function CellActions() {
    const publishedActionText = rfpState === 'published' ? 'Review' : 'Results'

    const actionText = rfpState === 'draft' ? 'Edit Lane' : publishedActionText

    return <ActionButton>{actionText}</ActionButton>
  },
})

export const getActionOrFindCarrierColumn = (
  rfpState: string,
  openFindCarriersSidebar: (lane: Lane) => void,
  bidType?: BID_TYPE
) => ({
  Header: '',
  id: 'actions',
  className: 'flex flex-right',
  style: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  Cell: function CellActions({ row }: { readonly row: any }) {
    const publishedActionText =
      rfpState === RFP_STATE.PUBLISHED ? 'Review' : 'Results'

    const actionText =
      rfpState === RFP_STATE.DRAFT ? 'Edit Lane' : publishedActionText

    const showRecommendedCarriers =
      rfpState === RFP_STATE.DRAFT || rfpState === RFP_STATE.PUBLISHED

    const onFindCarrierButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      openFindCarriersSidebar(row.original)
      analytics.track(
        'Lane / Actions Dropdown / Find Carriers / Click',
        AnalyticsEventTrigger.click,
        {
          bid_type: bidType || BID_TYPE.AWARD,
          rfp_state: rfpState,
        }
      )
    }

    return showRecommendedCarriers ? (
      <LinkButton onClick={onFindCarrierButtonClick}>Find carrier</LinkButton>
    ) : (
      <ActionButton>{actionText}</ActionButton>
    )
  },
})

export const getIncumbentCarrierColumn = () => ({
  Header: 'INCUMBENT',
  id: 'incumbent',
  accessor: '',
  className: 'text-left medium',
  Cell: function CellIncumbent({ row }: { readonly row: any }) {
    const { incumbent_carriers_awards, incumbent_carriers_names } = row.original
    if (
      !incumbent_carriers_awards ||
      !incumbent_carriers_names ||
      incumbent_carriers_awards?.length === 0 ||
      incumbent_carriers_names?.length === 0
    ) {
      return (
        <Layout.Group
          justify="flex-start"
          align="center"
          space="s"
          style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
        >
          No Carriers
        </Layout.Group>
      )
    }

    if (incumbent_carriers_names.length === 1) {
      return (
        <Layout.Group
          justify="flex-start"
          align="center"
          space="s"
          style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
        >
          {incumbent_carriers_names[0]}
        </Layout.Group>
      )
    }

    const incumbents = incumbent_carriers_names.map(
      (name: string, index: number) => ({
        name,
        award: incumbent_carriers_awards[index],
      })
    )

    return (
      <Layout.Group
        justify="flex-start"
        align="center"
        space="s"
        style={{ flexWrap: 'nowrap', whiteSpace: 'nowrap' }}
      >
        Split Primary
        <IncumbentTooltip carriers={incumbents} />
      </Layout.Group>
    )
  },
})

export const getRateOptionColumn = (
  optionsDict: Record<string, string>,
  rateOption: string,
  availableRateOptions: {
    value: string
    label: string
  }[],
  setRadioOptionTwo: Dispatch<SetStateAction<string>>,
  displayMultiCurrency: boolean,
  rfpCurrency: Currency
) => ({
  Header: (
    <Layout.Group space="xs" style={{ flexWrap: 'nowrap' }}>
      {optionsDict[rateOption]}
      {rateOption === 'market_rate' && <MarketBenchmarkTooltip />}
      <TableHeaderDropdown
        options={availableRateOptions}
        defaultValue={rateOption}
        setValue={setRadioOptionTwo}
        disabled={availableRateOptions.length <= 1}
      />
    </Layout.Group>
  ),
  id: rateOption,
  accessor: rateOption,
  className: 'text-left medium',
  Cell: function CellFlatRate({ row }: { readonly row: any }) {
    const valueField = displayMultiCurrency
      ? `converted_${rateOption}`
      : rateOption

    return (
      <MultiCurrencyValue
        value={row.original[valueField]}
        rfpCurrency={rfpCurrency}
        nonConvertedValue={row.original[rateOption]}
      />
    )
  },
})

export const getBidOptionColumn = (
  optionsDict: Record<string, string>,
  bidOption: string,
  BidOptions: {
    value: string
    label: string
  }[],
  setRadioOptionOne: Dispatch<SetStateAction<string>>,
  displayMultiCurrency: boolean,
  rfpCurrency: Currency
) => ({
  Header: (
    <Layout.Group space="xs" style={{ flexWrap: 'nowrap' }}>
      {optionsDict[bidOption]}
      <TableHeaderDropdown
        options={BidOptions}
        defaultValue={bidOption}
        setValue={setRadioOptionOne}
      />
    </Layout.Group>
  ),
  id: bidOption,
  accessor: bidOption,
  className: 'text-left',
  Cell: function CellLowestBids({ row }: { readonly row: any }) {
    const valueField = displayMultiCurrency
      ? `converted_${bidOption}`
      : bidOption

    return (
      <MultiCurrencyValue
        value={row.original[valueField]}
        rfpCurrency={rfpCurrency}
        nonConvertedValue={row.original[bidOption]}
      />
    )
  },
})

export const getVSColumn = (bidOption: string, rateOption: string) => ({
  Header: 'VS →',
  id: 'vs',
  className: 'text-left text-nowrap',
  accessor: '',
  Cell: function CellVersus({ row }: { readonly row: any }) {
    const optionOne = row.original[bidOption]
    const optionTwo = row.original[rateOption]

    if (optionOne === null || optionTwo === null) {
      return '- %'
    }

    const diff = optionOne - optionTwo

    if (diff === 0) {
      return '0%'
    }

    const direction = diff < 0 ? 'positive' : 'negative'
    const [smaller, bigger] =
      diff > 0 ? [optionTwo, optionOne] : [optionOne, optionTwo]
    const percentage = 100 - (smaller * 100) / bigger
    return (
      <>
        <PriceChangeIndicator direction={direction} />
        {percentage.toFixed(1)}%
      </>
    )
  },
})

export const getProposalCountColumn = () => ({
  Header: 'BIDS',
  id: 'proposals_count',
  className: 'text-left',
  accessor: ({
    proposals_count,
  }: {
    proposals_count: string | number | undefined
  }) => numberFormatter(proposals_count),
  Cell: function CellBid({
    value,
  }: {
    readonly value: TableData['proposals_count']
  }) {
    return <BidsCell proposalsCount={value} />
  },
})

export const getAllocationPercentageColumn = () => ({
  Header: 'ALLOCATION',
  id: 'allocation_percentage',
  className: 'text-left',
  accessor: 'allocation_percentage',
  Cell: function CellAllocation({
    value,
  }: {
    readonly value: TableData['allocation_percentage']
  }) {
    return <AllocationCell value={value} />
  },
})

export const getRatePerDistanceColumn = (
  displayMultiCurrency: boolean,
  rfpCurrency: Currency,
  rfpDistanceType: RequestForProposal['distance_type'],
  enableMultiModeRFPs: boolean
) => {
  if (enableMultiModeRFPs) {
    return []
  }

  return [
    {
      Header: getPerDistanceRateLabel(rfpDistanceType),
      id: 'per_mile_rate',
      accessor: 'per_mile_rate',
      className: 'text-left',
      Cell: function render({ row }: { row: any }) {
        const value = getPerDistanceRate(
          row.original,
          rfpDistanceType,
          displayMultiCurrency
        )

        const nonConvertedValue = isKilometers(rfpDistanceType)
          ? row.original['per_kilometer_rate']
          : row.original['per_mile_rate']

        return (
          <MultiCurrencyValue
            value={value}
            rfpCurrency={rfpCurrency}
            nonConvertedValue={nonConvertedValue}
            boldStyle={false}
          />
        )
      },
    },
  ]
}
