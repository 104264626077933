import { Select } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { fromSelectOption } from 'utils/selectOption'

import { FREIGHT_CLASSES_OPTIONS } from './ShippingItems.constants'
import { useShippingItemFormField } from './ShippingItemsForm'
import type { UseShippingItemProps } from './ShippingItemsForm'
import { getFreightClassOption } from './ShippingItemsForm.utils'

export const ShippingItemFreightClass = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: UseShippingItemProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    name,
    dispatch,
  } = useShippingItemFormField({ name: 'freight_class', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Class</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={Boolean(controlProps.disabled)}
        placeholder="Select"
        name={name}
        options={FREIGHT_CLASSES_OPTIONS}
        value={getFreightClassOption(get(item, name))}
        onChange={(event) => {
          const {
            target: { value: option },
          } = event

          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: fromSelectOption(option as SelectOption),
              },
            },
          })
        }}
        hideClear={required}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
