import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const VOYAGE_NUMBER_NAME = 'voyage_number'
const VOYAGE_NUMBER_LABEL = 'Voyage'

type FreightInformationVoyageNumberSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name'
>

export function FreightInformationVoyageNumberSummaryField(
  props: Readonly<FreightInformationVoyageNumberSummaryFieldProps>
) {
  return (
    <FreightInformationSummaryField
      label={VOYAGE_NUMBER_LABEL}
      name={VOYAGE_NUMBER_NAME}
      {...props}
    />
  )
}

type FreightInformationVoyageNumberFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
  readonly disabled?: boolean
}

export function FreightInformationVoyageNumber({
  required,
  hint,
  error,
  style,
  disabled,
}: FreightInformationVoyageNumberFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: VOYAGE_NUMBER_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${VOYAGE_NUMBER_NAME}-field`}
    >
      <Field.Label id={`label-${VOYAGE_NUMBER_NAME}`}>
        {VOYAGE_NUMBER_LABEL}
      </Field.Label>
      <TextField
        aria-labelledby={`label-${VOYAGE_NUMBER_NAME}`}
        value={get(shipment, VOYAGE_NUMBER_NAME, '')}
        disabled={disabled}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: { [VOYAGE_NUMBER_NAME]: get(event, 'target.value') },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
