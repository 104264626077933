import type { ToggleGroupProps } from '@loadsmart/miranda-react'
import {
  Card,
  Field,
  Icon,
  LabeledValue,
  Layout,
  ToggleGroup,
  Tooltip,
} from '@loadsmart/miranda-react'
import type { ToggleGroupOption } from '@loadsmart/miranda-react/dist/components/ToggleGroup/ToggleGroup'
import { useEffect } from 'react'

import type { UserData } from '_shared_/user'
import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { useFulfillmentCreationFeatureFlags } from 'fulfillments/create/hooks/useFulfillmentCreationFeatureFlags'
import type { TransientFulfillment } from 'fulfillments/domain/Fulfillment'
import { defaultEmptyString } from 'utils/strings'

import { FulfillmentCustomer } from './FulfillmentCustomer'
import { FulfillmentField } from './FulfillmentField'
import { useFulfillmentFormContext } from './FulfillmentFormContext'
import { FulfillmentOrders } from './FulfillmentOrders'
import { FulfillmentSupplier } from './FulfillmentSupplier'

export const isSupplier = (shipper?: UserData['shipper']) => {
  if (!shipper) {
    return false
  }
  return shipper.is_supplier
}

const inputMethodOptions: Array<
  ToggleGroupOption<TransientFulfillment['order_identifier_type']>
> = [
  { label: 'Select orders', value: 'orders' },
  { label: 'Enter POs', value: 'po_number' },
  { label: 'Enter SOs', value: 'so_number' },
]

// const ownershipOptions: Array<
//   ToggleGroupOption<TransientFulfillment['owner']>
// > = [
//   { label: 'Owned by us', value: 'us' },
//   { label: 'Owned by customer', value: 'customer' },
// ]

export function FulfillmentFreightInfo() {
  const { defaultOrderInputMode, enableFulfillmentsWithoutOrders } =
    useFulfillmentCreationFeatureFlags()
  const { fulfillment, firstSelectedOrder, setPartialFulfillment } =
    useFulfillmentFormContext()
  const { user } = useCurrentUser()

  // const handleFreightOwnership: NonNullable<
  //   ToggleGroupProps<'single-strict', TransientFulfillment['owner']>['onChange']
  // > = (event) => {
  //   setPartialFulfillment({
  //     owner: event.target.value,
  //   })
  // }

  useEffect(() => {
    setPartialFulfillment({
      order_identifier_type: defaultOrderInputMode,
    })
  }, [defaultOrderInputMode, setPartialFulfillment])

  const handleInputMethod: NonNullable<
    ToggleGroupProps<
      'single-strict',
      TransientFulfillment['order_identifier_type']
    >['onChange']
  > = (event) => {
    setPartialFulfillment({
      order_identifier_type: event.target.value,
    })
  }

  useEffect(() => {
    // if the user is a supplier, we should set the fulfillment owner to customer
    if (fulfillment.owner === 'us' && isSupplier(user?.shipper)) {
      setPartialFulfillment({
        owner: 'customer',
      })
    }
  }, [fulfillment.owner, setPartialFulfillment, user])

  return (
    <Card id="fulfillment-freight-info">
      <Card.Title>Freight info</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Container>
          {enableFulfillmentsWithoutOrders && (
            <Layout.Row>
              {/* Hiding the toggle for now, but we will enable this feature in the future */}
              {/* <Layout.Column>
                <Field>
                  <Field.Label>
                    Freight ownership{' '}
                    <Tooltip
                      message={`Select how you'll input orders for this fulfillment: by selecting from existing orders, entering purchase orders (POs), or entering sales orders (SOs)`}
                      trigger="hover"
                      placement="top"
                    >
                      <Icon name="question" color="color-text-tertiary" />
                    </Tooltip>
                  </Field.Label>
                  <ToggleGroup
                    name="owner"
                    type="single-strict"
                    options={ownershipOptions}
                    value={fulfillment.owner}
                    onChange={handleFreightOwnership}
                  />
                </Field>
              </Layout.Column>
            */}
              <Layout.Column>
                <Field>
                  <Field.Label>
                    Order input method{' '}
                    <Tooltip
                      message="Select how you'll input orders for this fulfillment: by entering purchase orders (POs), sales orders (SOs), or by selecting from existing orders."
                      trigger="hover"
                      placement="top"
                    >
                      <Icon name="question" color="color-text-tertiary" />
                    </Tooltip>
                  </Field.Label>
                  <ToggleGroup
                    name="order_identifier_type"
                    type="single-strict"
                    options={inputMethodOptions}
                    value={fulfillment.order_identifier_type}
                    onChange={handleInputMethod}
                  />
                </Field>
              </Layout.Column>
            </Layout.Row>
          )}
          {fulfillment.owner === 'customer' && (
            <Layout.Row>
              <Layout.Column>
                <FulfillmentCustomer />
              </Layout.Column>
            </Layout.Row>
          )}
          <Layout.Row>
            {fulfillment.order_identifier_type === 'orders' && (
              <Layout.Column>
                <FulfillmentOrders required />
              </Layout.Column>
            )}
            {fulfillment.order_identifier_type === 'po_number' && (
              <Layout.Column>
                <FulfillmentField
                  name="po_numbers"
                  label="PO number(s)"
                  placeholder="Enter PO number(s)"
                  hint="Separate values with commas"
                  required
                />
              </Layout.Column>
            )}
            {fulfillment.order_identifier_type === 'so_number' && (
              <Layout.Column>
                <FulfillmentField
                  name="so_numbers"
                  label="SO number(s)"
                  placeholder="Enter SO number(s)"
                  hint="Separate values with commas"
                  required
                />
              </Layout.Column>
            )}
          </Layout.Row>
          {fulfillment.owner === 'us' && (
            <Layout.Row>
              <Layout.Column>
                {fulfillment.order_identifier_type === 'orders' ? (
                  <LabeledValue
                    label="Supplier"
                    value={defaultEmptyString(
                      firstSelectedOrder?.supplier_name
                    )}
                  />
                ) : (
                  <FulfillmentSupplier />
                )}
              </Layout.Column>
            </Layout.Row>
          )}
          <Layout.Row>
            <Layout.Column md="6">
              <FulfillmentField
                name="pickup_number"
                label="Pickup number"
                placeholder="Enter pickup number"
              />
            </Layout.Column>
          </Layout.Row>
        </Layout.Container>
      </Card.Body>
    </Card>
  )
}
