import type { RouteComponentProps } from 'react-router-dom'

import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

export const isActivePath = (path: string) => {
  return globalThis.location.pathname.toLowerCase().startsWith(path)
}

export function navbarLinkClicked(
  address: string,
  url?: string,
  history?: RouteComponentProps['history']
) {
  analytics.track('Navbar / Navigation Link', AnalyticsEventTrigger.click, {
    address,
  })

  if (url) {
    history?.push(url)
  }
}
