import { useEffect } from 'react'
import { toast } from 'react-toastify'

import { useSettings } from '_shared_/settings/useSettings'
import { useLoadOrderData } from 'screens/Orders/common/useLoadOrderData'
import { useSelectedOrdersQueryParams } from 'screens/Orders/ManualConsolidation/useSelectedOrders'

export const useSelectedOrders = ({ enable }: { enable?: boolean }) => {
  const {
    values: [enableManualConsolidation],
  } = useSettings(['flags.ENABLE_ORDERS_MANUAL_CONSOLIDATION'])

  const { selectedOrderUUIDs } = useSelectedOrdersQueryParams()
  const { orders, isLoading, isSuccess, isError } = useLoadOrderData(
    enableManualConsolidation && enable ? selectedOrderUUIDs : []
  )

  useEffect(() => {
    if (isSuccess && orders.length < selectedOrderUUIDs.length) {
      toast.error('Unable to load some selected orders.')
    }
  }, [isSuccess, orders, selectedOrderUUIDs])

  useEffect(() => {
    if (isError) {
      toast.error('Error loading orders')
    }
  }, [isError])

  return {
    selectedOrders: orders,
    isLoading,
    isSuccess,
    isError,
  }
}
