import type {
  FreightClass as StrangerFreightClass,
  SearchDrawerProps as StrangerSearchDrawerProps,
  NmfcResponse as StrangerNmfcResponse,
} from '@loadsmart/strangercodes'
import { NmfcSearchDrawer as StrangerNmfcSearchDrawer } from '@loadsmart/strangercodes'

import '@loadsmart/strangercodes/style.css'

export type FreightClass = StrangerFreightClass

export type SearchDrawerProps = StrangerSearchDrawerProps
export type NmfcResponse = StrangerNmfcResponse

export const NmfcSearchDrawer = StrangerNmfcSearchDrawer
