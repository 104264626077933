import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useSettings } from '_shared_/settings/useSettings'
import { appRoutes } from 'router/app-routes'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

import { ORDER_QUERY_PARAM_KEY } from './constants'

export function useRedirectToNewShipment() {
  const {
    values: [isPlanOrdersPageEnabled],
  } = useSettings(['flags.ENABLE_PLAN_ORDERS_PAGE'])
  const history = useHistory()

  // To be removed once the new consolidation flow replaces the current one
  const newShipmentRoute = isPlanOrdersPageEnabled
    ? appRoutes.planOrders
    : appRoutes.newShipment
  const newShipmentQueryParam = isPlanOrdersPageEnabled
    ? ORDER_QUERY_PARAM_KEY
    : 'selected'

  const redirect = useCallback(
    (orderUUIDs?: string[]) => {
      if (orderUUIDs) {
        const ordersParams = new URLSearchParams()
        orderUUIDs.forEach((uuid) => {
          ordersParams.append(newShipmentQueryParam, uuid)
        })
        analytics.track(
          'Orders List / New shipment selection',
          AnalyticsEventTrigger.click,
          { orders: orderUUIDs.length }
        )
        history.push(`${newShipmentRoute}?${ordersParams.toString()}`)
      } else {
        analytics.track(
          'Orders List / New shipment selection',
          AnalyticsEventTrigger.click
        )
        history.push(`${newShipmentRoute}`)
      }
    },
    [history, newShipmentRoute, newShipmentQueryParam]
  )

  return redirect
}
