import { Text } from '@loadsmart/miranda-react'

import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { formatPackageTypeLabel } from 'components/HandlingUnitsManager/HandlingUnitsUtils'

type HandlingUnitTitleProps = {
  readonly handlingUnit: TransientHandlingUnit
}

export const HandlingUnitTitle = ({ handlingUnit }: HandlingUnitTitleProps) => {
  return (
    <Text variant="heading-sm-bold" id={`title-${handlingUnit._metadata.id}`}>
      {formatPackageTypeLabel(
        handlingUnit.package_type,
        Number(handlingUnit.package_count),
        'items',
        handlingUnit.commodities[0]?.description || 'pending description'
      )}
    </Text>
  )
}
