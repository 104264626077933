import { DateFormatHelper, DateHelper } from '@loadsmart/loadsmart-ui'
import * as Sentry from '@sentry/browser'
import { defaultTo, isEmpty, some } from 'lodash-es'

import { formatDate } from '_shared_/utils/date'
import type { PackageType } from 'components/ShippingItemsManager'
import {
  formatDimensionNumber,
  formatVolume,
  formatWeight,
} from 'components/ShippingItemsManager'
import LocationHelper from 'utils/locations'
import { numberFormatter } from 'utils/numbers'

import { defaultEmptyString, plural } from '../utils/strings'
import type { TruckMileageStop } from './order-service'
import type { ListOrder, ListOrderItem, Order, OrderItem } from './types'

/**
 * @param order Order
 * @param stop Pickup or delivery
 * @param includeZipCode Include zipcode after state?
 * @returns <city>, <state> [<zipcode>]
 */
export function formatOrderStop(
  order: Order | ListOrder,
  stop: 'pickup' | 'delivery',
  includeZipCode = true
) {
  let formattedLocation = '-'
  const location: Record<string, string | null> = {
    state: order[`${stop}_state`],
    city: order[`${stop}_city`],
  }
  let format = `${LocationHelper.CITY}, ${LocationHelper.STATE}`

  if (includeZipCode) {
    format = `${LocationHelper.CITY}, ${LocationHelper.STATE} ${LocationHelper.ZIPCODE}`
    location.zipcode = order[`${stop}_zipcode`]
  }

  if (!Object.values(location).every(isEmpty)) {
    formattedLocation = LocationHelper(location).format(format)
  }

  return formattedLocation
}

/**
 * @param order Order
 * @param stop Pickup or delivery
 * @returns Formatted dates as window or single date, depending on the values
 */
export function formatOrderDates(
  order: Order | ListOrder,
  stop: 'pickup' | 'delivery'
) {
  const start = order[`${stop}_window_start`] ?? ''
  const end = order[`${stop}_window_end`] ?? ''
  const simpleDateFormat = 'LLL dd'
  const fullDateFormat = 'LLL dd, yyyy'

  if (!start && !end) {
    return '-'
  }

  let formattedStart = ''
  let formattedEnd = ''

  try {
    formattedStart = formatDate(start, fullDateFormat)
  } catch (e) {
    Sentry.captureException(e)
  }

  try {
    formattedEnd = formatDate(end, fullDateFormat)
  } catch (e) {
    Sentry.captureException(e)
  }

  if (!formattedStart) {
    return formattedEnd
  }

  if (!formattedEnd) {
    return formattedStart
  }

  if (formattedStart === formattedEnd) {
    return formattedStart
  }

  return `${formatDate(start, simpleDateFormat)} - ${formattedEnd}`
}

/**
 * @param order Order
 * @returns Text describing how and when the order was created
 */
export function formatOrderSource(order: Order) {
  let formattedDate = ''
  let createdAtText = ''

  try {
    formattedDate = formatDate(order.created_at, 'LL/dd/yyyy h:mm a')
  } catch (e) {
    Sentry.captureException(e)
  }

  if (!isEmpty(order.created_at)) {
    createdAtText = ` at ${formattedDate}`
  }

  if (!isEmpty(order.integration_source)) {
    return `Created by ${order.source} (${order.integration_source})${createdAtText}`
  }

  if (!isEmpty(order.source)) {
    return `Created by ${order.source}${createdAtText}`
  }

  return `Created${createdAtText}`
}

export function getOrderItemsWeight(order?: Order | ListOrder) {
  const total = order?.items?.reduce((sum, item) => {
    return sum + Number(item.total_weight)
  }, 0)

  if (!total || Number.isNaN(total)) {
    return null
  }

  return total
}

/**
 * @param object Order, ListOrder
 * @returns Formatted total weight. Returns - if empty or NaN
 */
export function formatOrderWeight(order?: Order | ListOrder): string {
  const formattedString = formatWeight(defaultTo(order?.total_weight, ''))

  if (isEmpty(order?.total_weight) || isEmpty(formattedString)) {
    const itemsWeight = getOrderItemsWeight(order)

    if (itemsWeight == null) {
      return '-'
    }

    return formatWeight(itemsWeight)
  }

  return formattedString
}

/**
 * @param object OrderItem, ListOrderItem
 * @returns Formatted total weight. Returns - if empty or NaN
 */
export function formatOrderItemWeight({
  total_weight,
}: OrderItem | ListOrderItem): string {
  const formattedString = formatWeight(defaultTo(total_weight, ''))

  if (isEmpty(total_weight) || isEmpty(formattedString)) {
    return '-'
  }

  return formattedString
}

/**
 * @param order Order or ListOrder
 * @returns Formatted total volume. Returns - if empty or NaN
 */
export function formatOrderVolume(order: Order | ListOrder): string {
  const formattedString = formatVolume(defaultTo(order.total_volume, ''))

  if (isEmpty(order.total_volume) || isEmpty(formattedString)) {
    return '-'
  }

  return formattedString
}

/**
 * @param order Order or ListOrder
 * @returns Formatted density. Returns - if empty or NaN
 */
export function formatOrderDensity(order: Order | ListOrder): string {
  if (
    isEmpty(order.total_density) ||
    Number.isNaN(Number(order.total_density))
  ) {
    return '-'
  }

  return `${formatDimensionNumber(Number(order.total_density))} PCF`
}

const SHORT_PACKAGE_TYPE_LABELS = new Map<PackageType, string>([
  ['std_pallets', 'Pallet'] as const,
  ['pallets_non_std', 'Pallet Non Standard'] as const,
  ['bags', 'Bag'] as const,
  ['bales', 'Bale'] as const,
  ['boxes', 'Box'] as const,
  ['bunches', 'Bunch'] as const,
  ['carpets', 'Carpet'] as const,
  ['coils', 'Coil'] as const,
  ['crates', 'Crate'] as const,
  ['cylinders', 'Cylinder'] as const,
  ['drums', 'Drum'] as const,
  ['pails', 'Pail'] as const,
  ['reels', 'Reel'] as const,
  ['rolls', 'Roll'] as const,
  ['tubes_pipes', 'Tube and Pipe'] as const,
  ['loose', 'Loose'] as const,
])
const SHORT_PACKAGE_TYPE_LABELS_PLURAL = new Map<PackageType, string>([
  ['std_pallets', 'Pallets'] as const,
  ['pallets_non_std', 'Pallets Non Standard'] as const,
  ['bags', 'Bags'] as const,
  ['bales', 'Bales'] as const,
  ['boxes', 'Boxes'] as const,
  ['bunches', 'Bunches'] as const,
  ['carpets', 'Carpets'] as const,
  ['coils', 'Coils'] as const,
  ['crates', 'Crates'] as const,
  ['cylinders', 'Cylinders'] as const,
  ['drums', 'Drums'] as const,
  ['pails', 'Pails'] as const,
  ['reels', 'Reels'] as const,
  ['rolls', 'Rolls'] as const,
  ['tubes_pipes', 'Tubes and Pipes'] as const,
  ['loose', 'Loose'] as const,
])

export function getFormattedPackageType(
  packageType: OrderItem['package_type'],
  customPackageType: OrderItem['custom_package_type'] | undefined,
  packageCount: OrderItem['package_count']
) {
  if (customPackageType) {
    return plural(customPackageType, +packageCount)
  }

  if (packageType) {
    const shortPackageType = SHORT_PACKAGE_TYPE_LABELS.get(packageType)

    if (shortPackageType) {
      return plural(
        shortPackageType,
        packageCount,
        SHORT_PACKAGE_TYPE_LABELS_PLURAL.get(packageType)
      )
    }
  }

  return plural('item', packageCount)
}

export function formatCommodityAndPackaging(
  commodity: OrderItem['commodity'],
  packageCount: OrderItem['package_count'],
  packageType: OrderItem['package_type'],
  customPackageType: OrderItem['custom_package_type'] | undefined
) {
  const formattedPackageType = getFormattedPackageType(
    packageType,
    customPackageType,
    packageCount
  )

  if (isEmpty(commodity)) {
    return `${packageCount} ${formattedPackageType.toLocaleLowerCase()}`
  }

  return `${packageCount} ${formattedPackageType.toLocaleLowerCase()} of ${commodity}`
}

/**
 * @param orderItem OrderItem or ListOrderItem
 * @returns Returns the formatted description for an Order Item
 */
export const formatOrderItemDescription = (
  orderItem:
    | OrderItem
    | ListOrderItem
    | Pick<
        OrderItem,
        'commodity' | 'package_count' | 'package_type' | 'custom_package_type'
      >
) => {
  return formatCommodityAndPackaging(
    orderItem.commodity,
    orderItem.package_count,
    orderItem.package_type,
    orderItem.custom_package_type
  )
}

/**
 * @param orderItem OrderItem or ListOrderItem
 * @returns Returns the formatted description for the stackable flag
 */
export function formatOrderItemStackable(orderItem: OrderItem | ListOrderItem) {
  return orderItem.stackable ? 'Stackable' : 'Do not stack'
}

/**
 * @param orderItem OrderItem or ListOrderItem
 * @returns Returns the formatted description for the turnable flag
 */
export function formatOrderItemTurnable(orderItem: OrderItem | ListOrderItem) {
  return orderItem.turnable ? 'Turnable' : 'Do not turn'
}

/**
 * Formats units dimension for display purposes. Returns empty string if one of the values is null.
 * @param values [L, W, H]: Array of 3 numbers as strings (ordered as Length/Width/Height)
 * @param options showUnits (defaults to true)
 * @returns 16″ L x 27″ W x 8″ H
 */
export function formatUnitDimensions(
  values: [L: string | null, W: string | null, H: string | null],
  { showUnits = true } = {}
) {
  const units = ['L', 'W', 'H']

  if (values.some(isEmpty)) {
    return ''
  }

  const dimensions = values.map((value, index) => {
    const unit = showUnits ? ` ${units[index]}` : ''
    return `${numberFormatter(value, 1)}″${unit}`
  })

  return dimensions.join(' x ')
}

/**
 * @param item OrderItem, ListOrderItem, or equivalent object with package_unit_* fields
 * @param [showUnits=false] Add L, W, and H to the text
 * @returns Formatted dimensions text
 */
export function formatOrderItemDimensions(
  item:
    | OrderItem
    | ListOrderItem
    | Pick<
        OrderItem,
        'package_unit_length' | 'package_unit_width' | 'package_unit_height'
      >,
  showUnits = false
) {
  return defaultEmptyString(
    formatUnitDimensions(
      [
        item.package_unit_length,
        item.package_unit_width,
        item.package_unit_height,
      ],
      { showUnits }
    ),
    '-'
  )
}

/**
 * @param item OrderItem, ListOrderItem, or equivalent object with max_temperature and max_temperature_uom
 * @returns If there is non-empty value for the max_temperature field, it returns the formatted
 * temperature with the UOM. Otherwise, it returns an empty string
 */
export function formatOrderItemMaxTemperature(
  item:
    | ListOrderItem
    | OrderItem
    | Pick<ListOrderItem, 'max_temperature' | 'max_temperature_uom'>
) {
  if (isEmpty(item.max_temperature)) {
    return ''
  }

  return `${numberFormatter(item.max_temperature)}${
    item.max_temperature_uom === 'celsius' ? ' °C' : ' °F'
  }`
}

/**
 * @param order Order or ListOrder
 * @returns An array with the PU and DEL defined as either lat/long coordinates or zipcodes
 *  that can be used for truck mileage calculation
 */
export function mapOrderStopsToTruckMileageStops(
  order?: Order | ListOrder
): TruckMileageStop[] {
  if (
    !some(
      [
        order?.pickup_latitude,
        order?.pickup_longitude,
        order?.delivery_latitude,
        order?.delivery_longitude,
      ],
      isEmpty
    )
  ) {
    return [
      {
        latitude: Number(order?.pickup_latitude),
        longitude: Number(order?.pickup_longitude),
      },
      {
        latitude: Number(order?.delivery_latitude),
        longitude: Number(order?.delivery_longitude),
      },
    ]
  }

  if (!some([order?.pickup_zipcode, order?.delivery_zipcode], isEmpty)) {
    return [
      {
        postal_code: order?.pickup_zipcode,
      },
      {
        postal_code: order?.delivery_zipcode,
      },
    ]
  }

  return []
}

export const formatOrderDateFilters = (
  date: string | null,
  format = 'YYYY-MM-DD'
) => {
  return date ? DateFormatHelper(format).format(DateHelper(date)) : null
}

export function formatOrderItemLabel(
  item: OrderItem | ListOrderItem,
  order: Order | ListOrder
) {
  let label = `Order ${order.primary_ref} - Item #${item.line_number}`

  if (!isEmpty(item.item_description)) {
    label += ` ${item.item_description}`
  }

  if (!isEmpty(item.item_number)) {
    label += ` (item number ${item.item_number})`
  }

  return label
}
