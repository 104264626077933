import { Layout, PageContent } from '@loadsmart/miranda-react'

import { WindowTitle } from '_shared_/components/WindowTitle'

import { PlanFulfillmentsMap } from './components/PlanFulfillmentsMap'
import { PlanFulfillmentsPageHeader } from './components/PlanFulfillmentsPageHeader'
import { PlanFulfillmentsPlanPreview } from './components/PlanFulfillmentsPlanPreview'
import { PlanFulfillmentsContextProvider } from './PlanFulfillmentsContext'

export function PlanFulfillmentsPage() {
  return (
    <PlanFulfillmentsContextProvider>
      <WindowTitle title="Fulfillments" />
      <PageContent>
        <PlanFulfillmentsPageHeader />

        <PageContent.Body padding="none" style={{ minHeight: 0 }}>
          <Layout.Group
            style={{
              display: 'flex',
              flex: 1,
              minHeight: 0,
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <Layout.Box
              style={{
                display: 'flex',
                height: '100%',
                minWidth: '750px',
                maxWidth: '850px',
                overflowY: 'auto',
              }}
            >
              <PlanFulfillmentsPlanPreview />
            </Layout.Box>

            <Layout.Box
              style={{
                display: 'flex',
                flex: 1,
                height: '100%',
                paddingRight: '0',
                paddingTop: '0',
              }}
            >
              <PlanFulfillmentsMap />
            </Layout.Box>
          </Layout.Group>
        </PageContent.Body>
      </PageContent>
    </PlanFulfillmentsContextProvider>
  )
}
