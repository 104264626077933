import {
  Banner,
  Button,
  Divider,
  Icon,
  Layout,
  Text,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { defaultTo, isEmpty, uniq } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { FitBounds, MapV2 } from 'components/Map'
import { SHIPMENT_MAP_GOOGLE_MAP_ID } from 'utils/constants'

import { usePlanFulfillmentsContext } from '../PlanFulfillmentsContext'
import { PlanFulfillmentsList } from './PlanFulfillmentsList'
import type {
  PinMarkerProps,
  PinVariant,
} from './PlanFulfillmentsMap.components'
import { Line, PinMarker } from './PlanFulfillmentsMap.components'
import type { StopMapData } from './PlanFulfillmentsMap.utils'
import {
  getBounds,
  getLanesMap,
  getStopsMap,
  mapLaneMapDataToLineConfig,
  mapStopMapDataToPinConfig,
} from './PlanFulfillmentsMap.utils'

const Container = styled.div`
  background: ${toCSSValue('color-background-primary')};
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`

const MapContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`

export function getPinVariant(stop: StopMapData): PinVariant {
  if (!stop.plannedFulfillments.length) {
    return 'default'
  }
  if (stop.pendingFulfillments.length > 0) {
    return 'accent'
  }

  return 'success'
}

export function PlanFulfillmentsMap() {
  const {
    addSelectedFulfillments,
    pendingFulfillments,
    plan,
    removeSelectedFulfillments,
    selectedFulfillmentsUUIDs,
  } = usePlanFulfillmentsContext()

  const [selectedStopFacilityUUID, setSelectedStopFacilityUUID] = useState<
    string | null
  >(null)
  const [isListDrawerOpen, setIsListDrawerOpen] = useState(false)
  const openListDrawer = useCallback(
    (facilityUUID: string | null) => {
      setSelectedStopFacilityUUID(facilityUUID)
      setIsListDrawerOpen(true)
    },
    [setIsListDrawerOpen, setSelectedStopFacilityUUID]
  )
  const closeListDrawer = useCallback(() => {
    setIsListDrawerOpen(false)
    setSelectedStopFacilityUUID(null)
  }, [setIsListDrawerOpen])

  // List all stops from all available fulfillments
  const stopsMap = useMemo(
    () => getStopsMap(pendingFulfillments, selectedFulfillmentsUUIDs, plan),
    [pendingFulfillments, plan, selectedFulfillmentsUUIDs]
  )
  const pins = useMemo(
    () => Object.values(stopsMap).map(mapStopMapDataToPinConfig),
    [stopsMap]
  )
  const pinsProps: PinMarkerProps[] = useMemo(
    () =>
      pins.map((pin) => {
        const stop = stopsMap[pin.id]

        const onPinClick: PinMarkerProps['onPinClick'] = () => {
          openListDrawer(stop.facilityUUID)
        }

        const variant = getPinVariant(stop)

        return {
          ...pin,
          onPinClick,
          variant,
        }
      }),
    [openListDrawer, pins, stopsMap]
  )

  // List all lanes from all available fulfillments and current planned stops
  const lanesMap = useMemo(
    () =>
      getLanesMap(
        pendingFulfillments,
        stopsMap,
        plan?.stops,
        selectedFulfillmentsUUIDs
      ),
    [pendingFulfillments, plan?.stops, selectedFulfillmentsUUIDs, stopsMap]
  )
  const lines = useMemo(
    () => Object.values(lanesMap).map(mapLaneMapDataToLineConfig),
    [lanesMap]
  )

  const bounds = useMemo(() => getBounds(lines), [lines])
  const notPlottableFacilities = useMemo(
    () => pins.filter((pin) => isEmpty(pin.position)),
    [pins]
  )

  const pendingFulfillmentsUUIDs = defaultTo(
    pendingFulfillments?.map((fulfillment) => fulfillment.uuid),
    []
  )

  const allFulfillmentsUUIDs = uniq([
    ...pendingFulfillmentsUUIDs,
    ...selectedFulfillmentsUUIDs,
  ])

  return (
    <>
      <Container>
        {notPlottableFacilities.length > 0 && (
          <Banner
            variant="warning"
            style={{ marginBottom: toCSSValue('spacing-4') }}
          >
            <Banner.Title>
              {notPlottableFacilities.length} facilities without latitude and
              longitude
            </Banner.Title>
            <Banner.Description>
              These stops were not plotted on the map
            </Banner.Description>
          </Banner>
        )}
        <Layout.Group
          align="center"
          justify="space-between"
          padding="spacing-4"
        >
          <Text color="color-text-tertiary" variant="body-md">
            Showing {pendingFulfillments?.length} pending fulfillments
          </Text>

          <Button
            leading={<Icon name="list" size="12px" />}
            onClick={() => {
              openListDrawer(null)
            }}
            variant="tertiary"
          >
            View in a list
          </Button>
        </Layout.Group>

        <Divider />

        <MapContainer>
          <MapV2
            page="Plan fulfillments"
            fullscreenControl
            mapId={SHIPMENT_MAP_GOOGLE_MAP_ID}
            zoomControl
          >
            {lines.map(({ id, ...lane }) => (
              <Line key={id} {...lane} />
            ))}

            {pinsProps.map((pinProps) => (
              <PinMarker key={pinProps.id} {...pinProps} />
            ))}

            {bounds && <FitBounds path={bounds} control={false} />}
          </MapV2>
        </MapContainer>
      </Container>
      <PlanFulfillmentsList
        addSelectedFulfillments={addSelectedFulfillments}
        allFulfillmentsUUIDs={allFulfillmentsUUIDs}
        closeListDrawer={closeListDrawer}
        isListDrawerOpen={isListDrawerOpen}
        removeSelectedFulfillments={removeSelectedFulfillments}
        selectedFulfillmentsUUIDs={selectedFulfillmentsUUIDs}
        selectedStopFacilityUUID={selectedStopFacilityUUID}
        stops={stopsMap}
      />
    </>
  )
}
