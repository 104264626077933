import { MODES } from 'utils/constants'
import { getTransportationMode } from 'utils/transportationMode'
import { validateTransientWithSchema } from 'utils/yup'

import type { TransientShipment } from '../components/Shipment/Shipment.utils'
import { shipmentModeToPageConfigMap } from './create.common'

export function validate(
  shipment: TransientShipment
): [TransientShipment, boolean] {
  const mode = getTransportationMode(shipment.mode!)

  const validationSchema =
    shipmentModeToPageConfigMap[mode?.abbr ?? MODES.FTL].validationSchema

  return validateTransientWithSchema(validationSchema, shipment) as [
    TransientShipment,
    boolean,
  ]
}
