import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const CARRIER_REF_NUMBERS = 'carrier_ref_numbers'
const CARRIER_PRO_NUMBER_NAME = 'carrier_pro'
const PRO_NUMBER_LABEL = 'PRO number'

type FreightInformationPRONumberSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name'
>

export function FreightInformationPRONumberSummaryField(
  props: FreightInformationPRONumberSummaryFieldProps
) {
  return (
    <FreightInformationSummaryField
      label="PRO number"
      name={`${CARRIER_REF_NUMBERS}.${CARRIER_PRO_NUMBER_NAME}`}
      {...props}
    />
  )
}

type FreightInformationPRONumberFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

export function FreightInformationPRONumberFormField({
  required,
  hint,
  error,
  style,
}: FreightInformationPRONumberFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: CARRIER_REF_NUMBERS,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${CARRIER_PRO_NUMBER_NAME}-field`}
    >
      <Field.Label id={`label-${CARRIER_PRO_NUMBER_NAME}`}>
        {PRO_NUMBER_LABEL}
      </Field.Label>
      <TextField
        aria-labelledby={`label-${CARRIER_PRO_NUMBER_NAME}`}
        placeholder="Enter PRO number"
        value={get(
          shipment,
          `${CARRIER_REF_NUMBERS}.${CARRIER_PRO_NUMBER_NAME}`,
          ''
        )}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [CARRIER_REF_NUMBERS]: {
                ...shipment[CARRIER_REF_NUMBERS],
                [CARRIER_PRO_NUMBER_NAME]: get(event, 'target.value'),
              },
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
