import { Header } from '@loadsmart/miranda-react'

export function PayablesListPageHeader() {
  return (
    <Header>
      <Header.Content>
        <Header.Title>Payables</Header.Title>
      </Header.Content>
    </Header>
  )
}
