import { Tag } from '@loadsmart/loadsmart-ui'
import { Layout } from '@loadsmart/miranda-react'
import { useCallback, useMemo } from 'react'

import AppliedFilters from 'components/AppliedFilters'
import {
  getAppliedFilterTags,
  getHasAppliedFilters,
} from 'fulfillments/components/Filters.utils'
import type { GetFulfillmentsFilters } from 'fulfillments/hooks/useFulfillmentList'
import type { Filters } from 'utils/filters'

export interface AppliedFiltersBarProps {
  readonly clearFilters: () => void
  readonly setFilters: (newFilters: Partial<GetFulfillmentsFilters>) => void
  readonly filters: Filters<GetFulfillmentsFilters>
}

export function AppliedFiltersBar({
  clearFilters,
  setFilters,
  filters,
}: AppliedFiltersBarProps) {
  const hasAppliedFilters = getHasAppliedFilters(filters)
  const appliedFiltersTags = useMemo(
    () => getAppliedFilterTags(filters),
    [filters]
  )

  const onRemoveFilter = useCallback(
    (removedFilter: keyof GetFulfillmentsFilters) => {
      setFilters({ ...filters, [removedFilter]: null })
    },
    [filters, setFilters]
  )

  if (!hasAppliedFilters) {
    return null
  }

  return (
    <Layout.Group align="center" gap="spacing-2">
      <AppliedFilters
        appliedFilters={appliedFiltersTags}
        handleRemove={onRemoveFilter}
        onClearFilters={clearFilters}
      />
    </Layout.Group>
  )
}

export type RemovableTagsProps<T> = {
  readonly items: T[] | null
  readonly getItemKey: (item: T) => string
  readonly onRemoveItem: (index: number) => void
  readonly getLabel: (item: T) => string
}

export function SelectedItemsTags<T>(props: RemovableTagsProps<T>) {
  if (!Array.isArray(props.items)) {
    return null
  }

  return (
    <Layout.Group gap="spacing-2" data-testid="selected-item-tags">
      {props.items.map((item, index) => (
        <Tag
          key={props.getItemKey(item)}
          data-testid={`selected-item-tag-${props.getItemKey(item)}`}
          variant="default"
          removable
          onRemove={() => props.onRemoveItem(index)}
          getRemoveButtonProps={() => ({
            'aria-label': `Remove ${props.getLabel(item)}`,
          })}
        >
          {props.getLabel(item)}
        </Tag>
      ))}
    </Layout.Group>
  )
}
