import { IconClose, IconTrash } from '@loadsmart/icons'
import { Dialog, LoadingDots } from '@loadsmart/loadsmart-ui'
import {
  Button,
  Layout,
  Tag,
  Text,
  Icon,
  Tooltip,
} from '@loadsmart/miranda-react'
import { useCallback, useMemo, useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

import { getInitialValues } from 'components/CarrierForm/utils'
import InvalidCurrencyBanner from 'components/InvalidCurrencyBanner'
import { NotSet } from 'components/NotSet'
import { CloseSidebarWrapper, TitleContainer } from 'components/SideBar/styles'
import { useModal } from 'hooks/useModal'
import { useModeOptions } from 'hooks/useModeOptions'
import { usePreventPageScrollOver } from 'hooks/usePreventPageScrollOver'
import { useCarriersOperationRegions } from 'hooks/useQuery'
import { useRfpEquipmentTypes } from 'rfp/hooks/rfp'
import {
  Container,
  InnerContainerScroll,
} from 'rfp/rfp-details/components/routing-guide/styles'
import type { Currency } from 'rfp/rfp.types'
import { deleteCarrierDetails } from 'services/carriers'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { validateCurrencyForCarrier } from 'utils/currency'
import formatCurrency from 'utils/formatCurrency'

import { RelationshipStatusSummary } from './RelationshipStatusSummary'
import { ActionLink, ButtonWrapper, FormActionsWrapper, Title } from './styles'

const hasTags = (tags?: string[]) => tags != null && tags?.length > 0

export type CarrierDetailsProps = {
  readonly onClose: () => void
  readonly carrier?: Carrier | null
  readonly isRecommendedCarriers?: boolean
  readonly onClickEdit: any
  readonly rfpCurrency?: Currency
  readonly loadsmartDOT?: string
}

const isAlternativeEmailsEmpty = (
  alternativeEmails: { email: string; active: boolean }[]
) => {
  if (alternativeEmails.length === 1) {
    return alternativeEmails[0].email === ''
  }

  return false
}

export const AdditionalEmails = ({
  alternativeEmails,
  labelVariant = 'body-sm',
}: {
  readonly alternativeEmails: { email: string; active: boolean }[]
  readonly labelVariant?: 'body-sm' | 'body-sm-bold'
}) => {
  return (
    <Layout.Stack gap="spacing-1">
      <Text variant={labelVariant} color="color-text-tertiary">
        Additional emails
      </Text>
      {alternativeEmails && !isAlternativeEmailsEmpty(alternativeEmails) ? (
        alternativeEmails
          .filter((value) => value.email !== '')
          .map((value) => (
            <Layout.Group gap="spacing-1" key={value.email} align="center">
              {value.active ? (
                <Tooltip
                  placement="bottom-start"
                  trigger="hover"
                  message={
                    <>
                      This account is configured
                      <br /> to receive all notifications
                      <br /> from ShipperGuide.
                    </>
                  }
                >
                  <Icon name="bell" size="16px" color="color-text-primary" />
                </Tooltip>
              ) : (
                <Tooltip
                  placement="bottom-start"
                  trigger="hover"
                  message={
                    <>
                      This email is currently not
                      <br /> configured to receive notifications
                      <br /> from ShipperGuide.
                    </>
                  }
                >
                  <Icon
                    name="bell-cancel"
                    size="16px"
                    color="color-text-disabled"
                  />
                </Tooltip>
              )}
              <Text variant="body-md" color="color-text-primary">
                {value.email}
              </Text>
            </Layout.Group>
          ))
      ) : (
        <NotSet />
      )}
    </Layout.Stack>
  )
}

const CarrierDetails = ({
  onClose,
  carrier,
  isRecommendedCarriers,
  onClickEdit,
  rfpCurrency,
  loadsmartDOT,
}: CarrierDetailsProps) => {
  const { modalState, closeModal, openModal } = useModal()
  const queryClient = useQueryClient()
  const scrollerRef = useRef<HTMLDivElement>(null)
  usePreventPageScrollOver(scrollerRef)

  const { mutate, isLoading } = useMutation({
    mutationFn: deleteCarrierDetails,
    onSuccess: async () => {
      onClose()
      closeModal()
      toast.success('Carrier successfully deleted')
      await queryClient.refetchQueries({ queryKey: ['retrieveCarriers'] })
    },
    onError() {
      onClose()
      closeModal()
      toast.error('Could not delete the carrier, please contact an admin')
    },
  })

  const { modeOptions } = useModeOptions()
  const { data: equipmentTypeOptions, isLoading: isLoadingEquipmentTypes } =
    useRfpEquipmentTypes('')

  const {
    data: operationRegionsOptions,
    isLoading: isLoadingOperationRegions,
  } = useCarriersOperationRegions(false)

  const memoizedEquipmentTypes = useMemo(() => {
    if (isLoadingEquipmentTypes || !equipmentTypeOptions?.length) {
      return []
    }
    return equipmentTypeOptions.map(
      (item: { value: string; label: string }) => ({
        value: item.value,
        label: item.value,
      })
    )
  }, [isLoadingEquipmentTypes, equipmentTypeOptions])

  const memoizedOperationRegions = useMemo(() => {
    if (isLoadingOperationRegions || !operationRegionsOptions?.length) {
      return []
    }
    return operationRegionsOptions.map(
      (item: { value: string; label: string }) => ({
        value: item.value,
        label: item.label,
      })
    )
  }, [isLoadingOperationRegions, operationRegionsOptions])

  const {
    name,
    dot,
    mc,
    scac,
    email,
    phone,
    hq_address,
    req_safety_passed,
    cargo_on_file,
    bipd_on_file,
    operation_regions,
    modes,
    equipment_types,
    tags,
    notes,
    entity,
    alternative_emails: alternativeEmails,
    relationship_status,
  } = getInitialValues(carrier, {
    modeOptions,
    equipmentTypeOptions: memoizedEquipmentTypes,
    operationRegionOptions: memoizedOperationRegions,
  })

  const handleCarrierDeletion = useCallback(() => {
    if (carrier) {
      mutate(carrier.id)
    }
  }, [carrier, mutate])

  const isLoadsmartCarrier = dot === loadsmartDOT
  const isCurrencyAccepted = validateCurrencyForCarrier(
    carrier,
    rfpCurrency,
    loadsmartDOT
  )

  const hasTagsToShow = hasTags(tags)

  const recommendedCarriersButtons = (
    <ButtonWrapper>
      <Button
        variant="primary"
        onClick={() =>
          analytics.track(
            'RFP / Carriers / Recommended / View / Click on Add & Invite to Bid'
          )
        }
        disabled={!isCurrencyAccepted}
      >
        Add &amp; invite to bid
      </Button>
      <ActionLink
        onClick={() =>
          analytics.track(
            'RFP / Carriers / Recommended / View / Click on Add to Carriers'
          )
        }
      >
        Add to carriers
      </ActionLink>
      <Button style={{ marginRight: 0 }} onClick={onClose}>
        Close
      </Button>
    </ButtonWrapper>
  )

  const editButtons = (
    <Layout.Group justify="flex-end" style={{ width: '100%' }}>
      {carrier && !isLoadsmartCarrier && (
        <Button
          leading={<IconTrash height={12} width={12} title={null} />}
          variant="danger"
          aria-label="Delete carrier"
          onClick={openModal}
        >
          Delete carrier
        </Button>
      )}
      <Button
        variant="secondary"
        onClick={() => {
          onClickEdit()
          analytics.track(
            'Carriers / Carrier Details / Edit Carrier',
            AnalyticsEventTrigger.click,
            {
              ...carrier,
            }
          )
        }}
      >
        Edit carrier details
      </Button>
    </Layout.Group>
  )

  const regions = operation_regions.map((item) => item.label)
  const equipments = equipment_types.map((item) => item.label)

  return (
    <Container data-testid="proposalsContainer">
      <TitleContainer>
        <Title>Carrier details</Title>
        <CloseSidebarWrapper
          onClick={onClose}
          role="button"
          aria-label="close carrier details"
        >
          <IconClose width={16} height={16} />
        </CloseSidebarWrapper>
      </TitleContainer>
      <InnerContainerScroll ref={scrollerRef}>
        <Layout.Stack>
          <Layout.Box
            borderColor="color-text-secondary-inverted"
            borderWidth="border-thin"
            borderRadius="border-radius-s"
          >
            <Layout.Stack gap="spacing-6">
              <Text variant="heading-sm-bold" color="color-text-primary">
                {name}
              </Text>

              <Layout.Stack gap="spacing-4">
                <Layout.Grid gap="spacing-6" minColumnWidth="100px">
                  <RelationshipStatusSummary
                    relationshipStatus={relationship_status?.value}
                  />

                  <Layout.Stack gap="spacing-1">
                    <Text variant="body-sm" color="color-text-tertiary">
                      DOT
                    </Text>
                    <Text variant="body-md" color="color-text-primary">
                      {dot}
                    </Text>
                  </Layout.Stack>

                  <Layout.Stack gap="spacing-1">
                    <Text variant="body-sm" color="color-text-tertiary">
                      MC number
                    </Text>
                    <Text variant="body-md" color="color-text-primary">
                      {mc}
                    </Text>
                  </Layout.Stack>

                  <Layout.Stack gap="spacing-1">
                    <Text variant="body-sm" color="color-text-tertiary">
                      SCAC
                    </Text>
                    <Text variant="body-md" color="color-text-primary">
                      {scac}
                    </Text>
                  </Layout.Stack>

                  <Layout.Stack gap="spacing-1">
                    <Text variant="body-sm" color="color-text-tertiary">
                      Type
                    </Text>
                    <Text variant="body-md" color="color-text-primary">
                      {entity?.label}
                    </Text>
                  </Layout.Stack>
                </Layout.Grid>

                <Layout.Grid gap="spacing-6" minColumnWidth="200px">
                  <Layout.Stack>
                    <Layout.Stack
                      gap="spacing-1"
                      style={{ marginRight: 'auto', flexBasis: 2 }}
                    >
                      <Text variant="body-sm" color="color-text-tertiary">
                        Primary email
                      </Text>
                      <Layout.Group gap="spacing-1" align="center">
                        <Tooltip
                          placement="bottom-start"
                          trigger="hover"
                          message={
                            <>
                              This account is configured
                              <br /> to receive all notifications
                              <br /> from ShipperGuide.
                            </>
                          }
                        >
                          <Icon
                            name="bell"
                            size="16px"
                            color="color-text-primary"
                          />
                        </Tooltip>
                        <Text variant="body-md" color="color-text-primary">
                          {email}
                        </Text>
                      </Layout.Group>
                    </Layout.Stack>

                    <AdditionalEmails alternativeEmails={alternativeEmails} />
                  </Layout.Stack>

                  <Layout.Stack gap="spacing-1">
                    <Text variant="body-sm" color="color-text-tertiary">
                      Phone
                    </Text>
                    <Text variant="body-md" color="color-text-primary">
                      {phone}
                    </Text>
                  </Layout.Stack>
                </Layout.Grid>
              </Layout.Stack>
            </Layout.Stack>
          </Layout.Box>

          <Layout.Box
            borderColor="color-text-secondary-inverted"
            borderWidth="border-thin"
            borderRadius="border-radius-s"
          >
            <Layout.Stack gap="spacing-6">
              <Text variant="heading-sm" color="color-text-primary">
                More info
              </Text>

              <Layout.Stack gap="spacing-4">
                <Layout.Stack gap="spacing-1">
                  <Text variant="body-sm" color="color-text-tertiary">
                    HQ address
                  </Text>
                  <Text variant="body-md" color="color-text-primary">
                    {hq_address}
                  </Text>
                </Layout.Stack>

                <Layout.Stack gap="spacing-1">
                  <Text variant="body-sm" color="color-text-tertiary">
                    Region of operation
                  </Text>

                  <Layout.Group gap="spacing-2">
                    {regions.map((region) => (
                      <Tag size="small" key={region}>
                        {region.toUpperCase()}
                      </Tag>
                    ))}
                  </Layout.Group>
                </Layout.Stack>

                <Layout.Grid gap="spacing-6" minColumnWidth="200px">
                  <Layout.Stack gap="spacing-1">
                    <Text variant="body-sm" color="color-text-tertiary">
                      Equipment
                    </Text>

                    <Layout.Group gap="spacing-2">
                      {equipments.map((equipment) => (
                        <Tag size="small" key={equipment}>
                          {equipment.toUpperCase()}
                        </Tag>
                      ))}
                    </Layout.Group>
                  </Layout.Stack>

                  <Layout.Stack gap="spacing-1">
                    <Text variant="body-sm" color="color-text-tertiary">
                      Mode
                    </Text>

                    <Layout.Group gap="spacing-2">
                      {modes.map((mode) => (
                        <Tag size="small" key={mode.label}>
                          {mode.label.toUpperCase()}
                        </Tag>
                      ))}
                    </Layout.Group>
                  </Layout.Stack>
                </Layout.Grid>

                <Layout.Grid gap="spacing-6" minColumnWidth="200px">
                  <Layout.Stack gap="spacing-1">
                    <Text variant="body-sm" color="color-text-tertiary">
                      Cargo insurance
                    </Text>
                    <Text variant="body-md" color="color-text-primary">
                      {formatCurrency(cargo_on_file)}
                    </Text>
                  </Layout.Stack>

                  <Layout.Stack gap="spacing-1">
                    <Text variant="body-sm" color="color-text-tertiary">
                      BIPD insurance
                    </Text>
                    <Text variant="body-md" color="color-text-primary">
                      {formatCurrency(bipd_on_file)}
                    </Text>
                  </Layout.Stack>
                </Layout.Grid>

                <Layout.Stack gap="spacing-1">
                  <Text variant="body-sm" color="color-text-tertiary">
                    Safety requirements
                  </Text>
                  <Text variant="body-md" color="color-text-primary">
                    {req_safety_passed}
                  </Text>
                </Layout.Stack>

                <Layout.Stack gap="spacing-1">
                  <Text variant="body-sm" color="color-text-tertiary">
                    Notes
                  </Text>
                  <Text variant="body-md" color="color-text-primary">
                    {notes}
                  </Text>
                </Layout.Stack>
              </Layout.Stack>
            </Layout.Stack>
          </Layout.Box>

          {hasTagsToShow && (
            <Layout.Box
              borderColor="color-text-secondary-inverted"
              borderWidth="border-thin"
              borderRadius="border-radius-s"
            >
              <Layout.Stack gap="spacing-6">
                <Text variant="heading-sm" color="color-text-primary">
                  Tags
                </Text>

                <Layout.Stack gap="spacing-1">
                  <Text variant="body-sm" color="color-text-tertiary">
                    Use this to group your carriers and help you invite the
                    right carriers to bid.
                  </Text>
                  <Layout.Group gap="spacing-2">
                    {tags?.map((tag) => (
                      <Tag size="small" key={tag}>
                        {tag.toUpperCase()}
                      </Tag>
                    ))}
                  </Layout.Group>
                </Layout.Stack>
              </Layout.Stack>
            </Layout.Box>
          )}
        </Layout.Stack>
        {!isCurrencyAccepted && (
          <InvalidCurrencyBanner currency={rfpCurrency} />
        )}
      </InnerContainerScroll>
      <FormActionsWrapper width="600px">
        {isRecommendedCarriers ? recommendedCarriersButtons : editButtons}
      </FormActionsWrapper>
      <Dialog open={modalState} scale="small" onOverlayClick={closeModal}>
        <Dialog.Header>
          Are you sure you want to delete this carrier?
        </Dialog.Header>
        <Dialog.Body>This action cannot be undone.</Dialog.Body>
        <Dialog.ActionConfirm onConfirm={handleCarrierDeletion}>
          {isLoading ? (
            <div className="flex alig-center justify-center">
              <LoadingDots variant="light" />
            </div>
          ) : (
            'Delete carrier'
          )}
        </Dialog.ActionConfirm>
        <Dialog.ActionCancel onCancel={closeModal} />
      </Dialog>
    </Container>
  )
}

export default CarrierDetails
