import { useDialog } from '@loadsmart/loadsmart-ui'
import { noop } from 'lodash-es'
import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useSettings } from '_shared_/settings/useSettings'
import type {
  CreateShipmentFromSingleOrderRequest,
  CreateShipmentFromSingleOrderResponse,
} from 'orders/order-service'
import { mapListOrderToCreateShipmentFromSingleOrderRequest } from 'orders/order-service'
import type { ListOrder } from 'orders/types'
import type { MODES } from 'utils/constants'

import { isOrderPlannable } from '../common/utils'
import { AutoCreateShipmentsDialog } from './components'
import { useCreateShipmentFromSingleOrderMutation } from './hooks'
import type { OneClickAvailableModes, OrderMapPerMode } from './types'
import { ONE_CLICK_AVAILABLE_MODES } from './types'
import { isOrderAutoPlannable } from './utils'

export interface OneClickShipmentCreationContextValue {
  canProceed: boolean
  closeDialog: () => void
  hasConfirmed: boolean
  isBulkOneClickShipmentCreationEnabled: boolean
  isFTLEnabled: boolean
  isLTLEnabled: boolean
  isOneClickShipmentCreationEnabled: boolean
  isOpen?: boolean
  mode: OneClickAvailableModes
  mutations: Record<string, MutationContext>
  mutationsCompleted: number
  onConfirm: () => void
  openDialog: (orders: ListOrder[]) => void
  ordersPerMode: OrderMapPerMode
  selectedOrders: ListOrder[]
  setHasConfirmed: (flag: boolean) => void
  setMode: (mode: OneClickAvailableModes) => void
  validOrdersCount: number
}

export const DEFAULT_ONE_CLICK_CONTEXT: OneClickShipmentCreationContextValue = {
  canProceed: false,
  closeDialog: noop,
  hasConfirmed: false,
  isBulkOneClickShipmentCreationEnabled: false,
  isFTLEnabled: true,
  isLTLEnabled: true,
  isOneClickShipmentCreationEnabled: false,
  isOpen: false,
  mode: 'FTL',
  mutations: {},
  mutationsCompleted: 0,
  onConfirm: noop,
  openDialog: noop,
  ordersPerMode: {
    FTL: {
      suitable: [],
      unsuitable: [],
    },
    LTL: {
      suitable: [],
      unsuitable: [],
    },
    alreadyPlanned: [],
    incompatibleModes: [],
  },
  selectedOrders: [],
  setHasConfirmed: noop,
  setMode: noop,
  validOrdersCount: 0,
}

export const OneClickShipmentCreationContext =
  createContext<OneClickShipmentCreationContextValue>(DEFAULT_ONE_CLICK_CONTEXT)

export function useOneClickShipmentCreationContext() {
  return useContext(OneClickShipmentCreationContext)
}

export interface MutationContext {
  isError?: boolean
  isLoading?: boolean
  isSuccess?: boolean
  request: CreateShipmentFromSingleOrderRequest | null
  response?: CreateShipmentFromSingleOrderResponse
}

export function getOrdersPerModeMap(
  selectedOrders: ListOrder[] = []
): OrderMapPerMode {
  const initialMap = ONE_CLICK_AVAILABLE_MODES.reduce(
    (map, currentMode) => {
      return {
        ...map,
        [currentMode]: {
          suitable: [] as ListOrder[],
          unsuitable: [] as ListOrder[],
        },
      }
    },
    {
      alreadyPlanned: [] as ListOrder[],
      incompatibleModes: [] as ListOrder[],
    } as OrderMapPerMode
  )

  return selectedOrders.reduce((map, order) => {
    if (!isOrderPlannable(order)) {
      map.alreadyPlanned.push(order)
    } else if (!isOrderAutoPlannable(order)) {
      map.incompatibleModes.push(order)
    } else {
      ONE_CLICK_AVAILABLE_MODES.forEach((currentMode) => {
        if (order.suitable_modes?.includes(currentMode)) {
          map[currentMode].suitable.push(order)
        } else {
          map[currentMode].unsuitable.push(order)
        }
      })
    }

    return map
  }, initialMap)
}

export const DEFAULT_ONE_CLIKC_MODE = 'FTL'

function canProceedWithShipmentCreation({
  isBulkOneClickShipmentCreationEnabled,
  isFTLEnabled,
  isLTLEnabled,
  mode,
  selectedOrders,
}: {
  isBulkOneClickShipmentCreationEnabled: boolean
  isFTLEnabled: boolean
  isLTLEnabled: boolean
  mode: OneClickAvailableModes
  selectedOrders: ListOrder[]
}) {
  const isSelectedCountValid = isBulkOneClickShipmentCreationEnabled
    ? selectedOrders.length >= 1 && selectedOrders.length <= 10
    : selectedOrders.length === 1

  const isSelectedModeValid =
    (mode === 'FTL' && isFTLEnabled) || (mode === 'LTL' && isLTLEnabled)

  return isSelectedCountValid && isSelectedModeValid
}

export function useOneClickShipmentCreationContextState(
  onSuccessCallback?: () => void
) {
  const {
    values: [
      isOneClickShipmentCreationEnabled,
      isBulkOneClickShipmentCreationEnabled,
    ],
  } = useSettings([
    'flags.ENABLE_1_CLICK_SHIPMENT_CREATION',
    'flags.ENABLE_1_CLICK_SHIPMENT_CREATION_BULK',
  ])
  const { hide, open: isOpen, show } = useDialog({ open: true })
  const [mode, setMode] = useState<OneClickAvailableModes>(
    DEFAULT_ONE_CLIKC_MODE
  )
  const [selectedOrders, setSelectedOrders] = useState<ListOrder[]>([])
  const [hasConfirmed, setHasConfirmed] = useState<boolean>(false)
  const { mutateAsync } = useCreateShipmentFromSingleOrderMutation()
  const mutations = useRef<Record<string, MutationContext>>({})

  const completedMutations = Object.keys(mutations.current).filter(
    (key) =>
      mutations.current[key]?.isSuccess || mutations.current[key]?.isError
  ).length
  const mutationsCompleted = hasConfirmed
    ? completedMutations / selectedOrders.length
    : 0

  const openDialog = useCallback(
    (orders: ListOrder[]) => {
      setSelectedOrders(orders)
      show()
    },
    [setSelectedOrders, show]
  )

  const onError = useCallback(
    (_: unknown, request: CreateShipmentFromSingleOrderRequest) => {
      const key = request.orders[0].id
      mutations.current[key] = {
        isError: true,
        isLoading: false,
        isSuccess: false,
        request,
        response: undefined,
      }
    },
    [mutations]
  )

  const onSuccess = useCallback(
    (
      response: CreateShipmentFromSingleOrderResponse,
      request: CreateShipmentFromSingleOrderRequest
    ) => {
      const key = request.orders[0].id
      mutations.current[key] = {
        isError: false,
        isLoading: false,
        isSuccess: true,
        request,
        response,
      }
    },
    [mutations]
  )

  const closeDialog = useCallback(() => {
    setSelectedOrders([])
    setHasConfirmed(false)
    setMode(DEFAULT_ONE_CLIKC_MODE)
    mutations.current = {}
    hide()
  }, [hide, mutations, setHasConfirmed, setSelectedOrders])

  const onConfirm = useCallback(async () => {
    selectedOrders.forEach((order) => {
      const request = mapListOrderToCreateShipmentFromSingleOrderRequest(
        mode as MODES,
        order
      )
      mutations.current[order.uuid] = {
        isError: !request,
        isLoading: !!request,
        request,
      }
    })

    setHasConfirmed(true)

    for (const order of selectedOrders) {
      const request = mutations.current[order.uuid].request
      if (request) {
        try {
          await mutateAsync(request, {
            onError,
            onSuccess,
          })
        } catch {
          mutations.current[order.uuid].isError = true
        }
      }
    }

    onSuccessCallback?.()
  }, [
    mode,
    mutateAsync,
    mutations,
    onError,
    onSuccess,
    onSuccessCallback,
    selectedOrders,
    setHasConfirmed,
  ])

  const ordersPerMode = getOrdersPerModeMap(selectedOrders)

  // is enabled if there's at least 1 order that can be auto-planned to the mode
  const isFTLEnabled = ordersPerMode.FTL.suitable.length > 0
  const isLTLEnabled = ordersPerMode.LTL.suitable.length > 0

  const validOrdersCount = ordersPerMode[mode].suitable.length

  const canProceed = canProceedWithShipmentCreation({
    isBulkOneClickShipmentCreationEnabled,
    isFTLEnabled,
    isLTLEnabled,
    mode,
    selectedOrders,
  })

  const context = useMemo(
    () => ({
      canProceed,
      closeDialog,
      hasConfirmed,
      isBulkOneClickShipmentCreationEnabled,
      isFTLEnabled,
      isLTLEnabled,
      isOneClickShipmentCreationEnabled,
      isOpen,
      mode,
      mutations: mutations.current,
      mutationsCompleted,
      onConfirm,
      ordersPerMode,
      openDialog,
      selectedOrders,
      setHasConfirmed,
      setMode,
      validOrdersCount,
    }),
    [
      canProceed,
      closeDialog,
      hasConfirmed,
      isBulkOneClickShipmentCreationEnabled,
      isFTLEnabled,
      isLTLEnabled,
      isOneClickShipmentCreationEnabled,
      isOpen,
      mode,
      mutations,
      mutationsCompleted,
      onConfirm,
      openDialog,
      ordersPerMode,
      selectedOrders,
      setHasConfirmed,
      setMode,
      validOrdersCount,
    ]
  )

  return context
}

export interface OneClickConsolidationProviderProps extends PropsWithChildren {
  readonly onSuccess: () => void
}

export function OneClickConsolidationProvider({
  children,
  onSuccess,
}: OneClickConsolidationProviderProps) {
  const oneClickContextValue =
    useOneClickShipmentCreationContextState(onSuccess)

  return (
    <OneClickShipmentCreationContext.Provider value={oneClickContextValue}>
      {children}
      <AutoCreateShipmentsDialog />
    </OneClickShipmentCreationContext.Provider>
  )
}
