import type { AxiosRequestConfig } from 'axios'

import type { CarrierEmployee } from '_shared_/types/carrier-employee.type'
import httpClient from 'utils/httpClient'

export const CARRIER_ENDPOINT_BASE_URL = '/carriers'

export const list = async <T extends Carrier[] | PaginatedResult<Carrier>>(
  params: Record<string, any> = {},
  config?: Omit<AxiosRequestConfig<object[]>, 'params'>
): Promise<T> => {
  const { data } = await httpClient.get<T>('/carriers', { ...config, params })

  return data
}

export const listPaginated = async (
  query: string,
  limit: string,
  offset: string,
  sort: { column: string; direction: string },
  filter: string,
  rfpId: string | number | null,
  config?: AxiosRequestConfig
) => {
  const urlParams = new URLSearchParams({ limit, offset })
  if (query) {
    urlParams.append('query', query)
  }
  const searchParams = urlParams ? `&${urlParams.toString()}` : ''
  const idParam = rfpId ? `&rfp_id=${rfpId}` : ''
  let sortParam = sort.column
  if (sort.direction === 'desc') {
    sortParam = `-${sort.column}`
  }
  const { data } = await httpClient.get(
    `/carriers?sort=${sortParam}&filter=${filter}${idParam}${searchParams}`,
    config
  )
  return data
}
/**
 * @param carrier - the carrier data to be created
 * @param allowArchived - allows the backend to set an archived carrier as accessible
 * @returns the newly created carrier
 */
export const create = async (
  carrier: Partial<Carrier>,
  allowArchived = false
) => {
  let url = CARRIER_ENDPOINT_BASE_URL

  if (allowArchived) {
    url = url.concat('?allow_archived=true')
  }

  const { data } = await httpClient.post<Carrier>(url, carrier)
  return data
}

export const search = async (query: string) => {
  const params = query ? { q: query } : null
  const { data } = await httpClient.get('/carriers/search', {
    params,
  })

  return data
}

export const aliceCarrierDetails = async (
  carrierUUID: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `/carriers/search/${carrierUUID}`,
    config
  )
  return data.data
}

export const carrierDetails = async (
  id: string | number,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get<Carrier>(
    `/carriers/${id}?allow_archived=true`,
    config
  )
  return data
}

export const carrierAccountDetails = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get(`/carriers/me`, config)
  return data
}

export const partialUpdateCarrierDetails = async ({
  id,
  carrier,
  allowArchived = false,
}: {
  id: string | number
  carrier: Partial<Carrier>
  allowArchived?: boolean
}) => {
  let url = `${CARRIER_ENDPOINT_BASE_URL}/${id}`

  if (allowArchived) {
    url = url.concat('?allow_archived=true')
  }

  const { data } = await httpClient.patch(url, carrier)
  return data
}

export const deleteCarrierDetails = async (id: string | number) => {
  const { data } = await httpClient.delete(`${CARRIER_ENDPOINT_BASE_URL}/${id}`)
  return data
}

export const bulkDeleteCarrierDetails = async (
  carriers: Array<string | number>
) => {
  const { data } = await httpClient.delete(`/carriers/bulk_delete`, {
    data: { carriers },
  })
  return data
}

export const upload = async ({
  file,
  rfpId,
}: {
  file: File
  rfpId?: string | number
}) => {
  const formData = new FormData()
  formData.append('file', file)
  if (rfpId) {
    formData.append('rfp', `${rfpId}`)
  }
  const { data } = await httpClient.post(`/carriers/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

export const downloadSample = async (
  config?: Omit<AxiosRequestConfig, 'responseType'>
) => {
  const { data, headers } = await httpClient.get(`/carriers/sample`, {
    ...config,
    responseType: 'arraybuffer',
  })
  return { data, type: headers['content-type'] }
}

export const awardConfirmation = async ({
  token,
  action,
}: {
  token: string
  action: string
}) => {
  const { data } = await httpClient.post(
    `/proposals/award-confirmation/${token}/${action}`
  )
  return data
}

export const awardFeedback = async ({
  token,
  award_feedback,
}: {
  token: string
  award_feedback: string
}) => {
  const { data } = await httpClient.post(
    `/proposals/award-confirmation/${token}/feedback`,
    {
      award_feedback,
    }
  )
  return data
}

export const listCarriers = async (
  rfpId: string | number | null,
  sort: any,
  query: string,
  config?: AxiosRequestConfig
) => {
  const searchParams = new URLSearchParams({ query })
  const params = query ? `&${searchParams.toString()}` : ''
  let sortParam = sort.column
  if (sort.direction === 'desc') {
    sortParam = `-${sort.column}`
  }
  const { data } = await httpClient.get(
    `/carriers?rfp_id=${rfpId}&sort=${sortParam}${params}`,
    config
  )
  return data
}

export const resubscribeCarrier = async ({
  linkUUID,
}: {
  linkUUID: string
}) => {
  return await httpClient.post(`/proposals/${linkUUID}/subscribe`)
}

export const fetchCarriersEntities = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get(
    `carriers/entities?allow_not_set=true&allow_counts=true`,
    config
  )
  return data
}

export const CARRIER_OPERATION_REGIONS_ENDPOINT = 'carriers/operation_regions'
export const fetchCarriersOperationRegions = async (
  allowNotSet: boolean,
  allowCounts: boolean,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `${CARRIER_OPERATION_REGIONS_ENDPOINT}?allow_not_set=${allowNotSet}&allow_counts=${allowCounts}`,
    config
  )
  return data
}

export const fetchCarriersTags = async (config?: AxiosRequestConfig) => {
  const { data } = await httpClient.get(
    `carriers/tags?allow_not_set=true&allow_counts=true`,
    config
  )
  return data
}

type FetchCarriersFilteredOptions = {
  tagFiltersQuery: string
  query: string
  limit: string
  offset: string
  sort: { column: string; direction: string }
  filter: string
  rfpId: string | number | null
}
export const fetchCarriersFiltered = async (
  {
    tagFiltersQuery,
    query,
    limit,
    offset,
    sort,
    filter,
    rfpId,
  }: FetchCarriersFilteredOptions,
  config?: AxiosRequestConfig
) => {
  const urlParams = new URLSearchParams({ limit, offset })
  if (query) {
    urlParams.append('query', query)
  }
  const searchParams = urlParams ? `&${urlParams.toString()}` : ''
  const idParam = rfpId ? `&rfp_id=${rfpId}` : ''
  const tagFilterParam = tagFiltersQuery !== '' ? `&${tagFiltersQuery}` : ''
  let sortParam = sort.column
  if (sort.direction === 'desc') {
    sortParam = `-${sort.column}`
  }
  const { data } = await httpClient.get(
    `/carriers?sort=${sortParam}&filter=${filter}${idParam}${searchParams}${tagFilterParam}`,
    config
  )
  return data
}

export const fetchCarrierEmployees = async (
  carrierUUID: string,
  config?: AxiosRequestConfig
): Promise<Array<CarrierEmployee>> => {
  const { data } = await httpClient.get(
    `carriers/${carrierUUID}/employees`,
    config
  )
  return data
}

export const createCarrierEmployee = async ({
  carrierUUID,
  employeeData,
}: {
  carrierUUID: string
  employeeData: Partial<{
    name: string
    phone_number: string
    email: string
    roles: string[]
  }>
}) => {
  const { data } = await httpClient.post(
    `carriers/${carrierUUID}/employees`,
    employeeData
  )
  return data
}

export const deleteCarrierEmployee = async ({
  carrierUUID,
  employeeUUID,
}: {
  carrierUUID: string
  employeeUUID: string
}) => {
  const { data } = await httpClient.delete(
    `/carriers/${carrierUUID}/employees/${employeeUUID}`
  )

  return data
}

export const inviteAll = async ({
  rfpId,
  parsedTagFilter,
}: {
  rfpId: string | number
  parsedTagFilter: string
}) => {
  const filtersParam = parsedTagFilter !== '' ? `&${parsedTagFilter}` : ''

  const { data } = await httpClient.post(
    `/carriers/invite_all?rfp_id=${rfpId}${filtersParam}`
  )
  return data
}

export const listAttachments = async (
  carrierUUID: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `carriers/${carrierUUID}/attachments`,
    config
  )

  return data
}

export const deleteAttachment = async ({
  carrierUUID,
  attachmentUUID,
}: {
  carrierUUID: string
  attachmentUUID: string
}) => {
  const { data } = await httpClient.delete(
    `carriers/${carrierUUID}/attachments/${attachmentUUID}`
  )
  return data
}

export const uploadAttachments = async ({
  carrierUUID,
  files,
}: {
  carrierUUID: string
  files: File[]
}) => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('attachments', file)
  })

  const { data } = await httpClient.post(
    `carriers/${carrierUUID}/attachments`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return data
}

export const getAttachmentDownloadURL = async (
  {
    carrierUUID,
    attachmentId,
  }: {
    carrierUUID: string
    attachmentId: string | undefined
  },
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.get(
    `carriers/${carrierUUID}/attachments/${attachmentId}`,
    config
  )
  return data
}
