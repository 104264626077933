import { Select } from '@loadsmart/loadsmart-ui'
import type { SelectDatasource } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type { FieldProps } from '@loadsmart/miranda-react'
import { Field, Text } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties, ReactNode } from 'react'
import { useMemo } from 'react'

import type { FacilityDetailsV2, FacilityV2 } from 'services/facilities'
import type { Transient } from 'utils/transient'

import type { FacilityOption } from './StopFacility.helpers'
import {
  buildFacilitiesDatasource,
  CustomFacilityOption,
  FACILITY_ADAPTER,
  facilityToFacilityDetails,
} from './StopFacility.helpers'
import { usePinnedFacilities } from './StopFacility.hooks'
import type { TransientStop } from './Stops.types'
import type { UseStopFacilityParams } from './useStopFacility'
import { useStopFacility } from './useStopFacility'
import { useStopFormField } from './useStopFormField'
import type { UseStopFormFieldProps } from './useStopFormField'

const CUSTOM_COMPONENTS = {
  CreatableOption: function CreateFacilityOption() {
    return (
      <Select.CreatableOption>
        <Text variant="body-md-bold" color="color-primary-60">
          Add new facility
        </Text>
      </Select.CreatableOption>
    )
  },
  Option: CustomFacilityOption,
}

type StopFacilityProps = Readonly<{
  label?: ReactNode
  required?: FieldProps['required']
  hint?: UseStopFormFieldProps['hint']
  error?: UseStopFormFieldProps['error']
  style?: CSSProperties
  disabled?: boolean
  stopType?: StopType
  onCreateFacility?: () => void
  addable?: boolean
  datasource?: () => SelectDatasource<FacilityV2>
  customerUUID?: string
}> &
  Pick<
    UseStopFacilityParams,
    'initialFacilityValues' | 'onFacilitySaved' | 'selectFirstContactByDefault'
  >

export const StopFacility = (props: StopFacilityProps) => {
  const {
    label,
    required,
    hint,
    error,
    style,
    disabled,
    onCreateFacility,
    onFacilitySaved,
    initialFacilityValues,
    selectFirstContactByDefault,
    stopType,
    addable = true, // defaulting to true in order to keep retrocompatibility
    datasource,
    customerUUID,
  } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    labelProps,
    name,
    stop,
    dispatch,
  } = useStopFormField({ name: 'facility', hint, error })

  const { openCreateFacilityDrawer } = useStopFacility({
    onFacilitySaved,
    initialFacilityValues,
    selectFirstContactByDefault,
    customerUUID,
  })

  const { data: pinnedFacilities } = usePinnedFacilities({
    enabled: Boolean(stopType),
    stopType,
  })

  const datasources = useMemo(
    () => [datasource ?? buildFacilitiesDatasource(stopType, pinnedFacilities)],
    [stopType, datasource, pinnedFacilities]
  )
  function getValue() {
    const facility = get<
      Transient<TransientStop>,
      string,
      FacilityDetailsV2 | null
    >(stop, name, null)

    if (facility) {
      return {
        label: FACILITY_ADAPTER.getLabel(facility),
        value: FACILITY_ADAPTER.getKey(facility),
      } as Selectable
    }
    return null
  }

  const handleCreate = () => {
    onCreateFacility?.()
    openCreateFacilityDrawer()
  }

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>{label ?? `Stop #${index}`}</Field.Label>
      <Select
        isValidNewOption
        disabled={disabled}
        getInputProps={() => controlProps}
        placeholder="Select a facility"
        createOptionPosition="first"
        name={name}
        datasources={datasources}
        components={CUSTOM_COMPONENTS}
        value={getValue()}
        onCreate={addable ? handleCreate : undefined}
        onChange={(event) => {
          const selectedFacility = get(
            event,
            'target.value',
            null
          ) as FacilityOption | null

          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: selectedFacility
                  ? {
                      ...facilityToFacilityDetails(selectedFacility),
                      _type: 'facility',
                    }
                  : null,
                notes: null,
                contact: null,
              },
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
