import { Card, Icon, Layout } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { get } from 'lodash-es'

import { FacilitySummary } from 'components/FacilityDetails'
import { FacilityEditButton } from 'components/FacilityDetails/FacilityEditButton'
import type { TransientStop } from 'components/StopsManager'

import { StopFacilityContactSummary } from './StopFacilityContactSummary'

export type StopFacilitySummaryProps = {
  readonly stop: TransientStop
  readonly style?: React.CSSProperties
}

export function StopFacilitySummary({ stop, style }: StopFacilitySummaryProps) {
  return (
    <FacilitySummary facility={stop.facility ?? stop.terminal}>
      <Layout.Box
        style={{
          marginLeft: `calc(${toCSSValue('spacing-6')} * -1)`,
          marginRight: `calc(${toCSSValue('spacing-6')} * -1)`,
          marginBottom: `calc(${toCSSValue('spacing-6')} * -1)`,
          marginTop: toCSSValue('spacing-2'),
          borderTopColor: toCSSValue('color-divider'),
          borderTopWidth: toCSSValue('border-thin'),
          ...style,
        }}
        padding="spacing-6"
        backgroundColor="color-background-secondary"
      >
        <Layout.Stack align="stretch">
          <Layout.Group
            align="flex-start"
            justify="space-between"
            style={{ flexFlow: 'nowrap' }}
          >
            <Layout.Stack gap="none">
              <Layout.Group gap="spacing-2">
                <Icon
                  name={stop.facility ? 'warehouse' : 'terminal'}
                  size="32px"
                />
                <FacilitySummary.Name />
              </Layout.Group>
              <FacilitySummary.Address inline />
              <Layout.Group gap="spacing-2">
                <FacilitySummary.Location />
                <FacilitySummary.Country />
              </Layout.Group>
            </Layout.Stack>
            <FacilitySummary.OpendockConnected />
          </Layout.Group>
          {stop.facility && (
            <>
              <StopFacilityContactSummary contact={stop.contact} />
              <FacilitySummary.HoursOfOperation />
            </>
          )}
        </Layout.Stack>
      </Layout.Box>
    </FacilitySummary>
  )
}

type StopFacilitySummaryFooterProps = { readonly stop: TransientStop }

export function StopFacilitySummaryFooter({
  stop,
}: StopFacilitySummaryFooterProps) {
  const stopFacility = get(stop, 'facility')

  if (!stopFacility) {
    return null
  }

  return (
    <Card.Footer>
      <Layout.Group align="center" justify="flex-start">
        <FacilityEditButton uuid={stopFacility.uuid} />
      </Layout.Group>
    </Card.Footer>
  )
}
