import {
  Card,
  Divider,
  Icon,
  Layout,
  Tag,
  Text,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { defaultTo, get } from 'lodash-es'
import { useEffect, useMemo } from 'react'

import { FacilitySummary } from 'components/FacilityDetails'
import { useFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import type { TransientStop } from 'components/StopsManager'
import {
  createTransientStop,
  StopForm,
  StopsForm,
} from 'components/StopsManager'
import { buildCustomerFacilitiesDatasource } from 'components/StopsManager/StopFacility.helpers'
import { StopFacilitySummary } from 'components/StopsManager/StopFacilitySummary'
import { useFulfillmentFormContext } from 'fulfillments/components/FulfillmentFormContext'
import type { TransientFulfillment } from 'fulfillments/domain/Fulfillment'
import { AT_LEAST_ONE_HU_REQUIRED_MESSAGE } from 'fulfillments/domain/Fulfillment.validation'
import { useIsCurrentShipperASupplier } from 'suppliers/common/useIsCurrentShipperASupplier'
import { getTransientError, getTransientErrorsCount } from 'utils/transient'

import { FulfillmentHandlingUnit } from './FulfillmentHandlingUnit'
import { FulfillmentStop } from './FulfillmentStop'

function ErrorTag({ errorsCount }: Readonly<{ errorsCount: number }>) {
  if (!errorsCount) {
    return null
  }

  return (
    <Tag variant="danger" size="small">
      {errorsCount} {errorsCount > 1 ? 'Errors' : 'Error'}
    </Tag>
  )
}

function DeniedAccessMessage({
  accessDenied,
  pickup,
}: Readonly<{ accessDenied?: boolean; pickup?: boolean }>) {
  if (!accessDenied) {
    return null
  }

  return (
    <Layout.Group align="center" gap="spacing-1">
      <Icon color="color-danger-60" name="attention" size="10px" />
      <Text color="color-danger-60" variant="body-sm">
        Facility not shared with you.{' '}
        {pickup ? 'Choose another or request access from customer.' : ''}
      </Text>
    </Layout.Group>
  )
}

function useFacilityDetails(
  fulfillment: TransientFulfillment,
  isSupplier?: boolean
) {
  const pickupUuid = fulfillment.pickup_facility_uuid
  const {
    data: pickupFacility,
    error: errorLoadingPU,
    isError: isErrorLoadingPU,
  } = useFacilityDetailsV2(pickupUuid, {
    enabled: Boolean(pickupUuid),
  })

  const accessDeniedToPickupFacility =
    isSupplier &&
    isErrorLoadingPU &&
    errorLoadingPU?.message === 'Request failed with status code 403'

  const deliveryUuid = fulfillment.delivery_facility_uuid
  const {
    data: deliveryFacility,
    error: errorLoadingDEL,
    isError: isErrorLoadingDEL,
  } = useFacilityDetailsV2(deliveryUuid, {
    enabled: Boolean(deliveryUuid),
  })

  const accessDeniedToDeliveryFacility =
    isSupplier &&
    isErrorLoadingDEL &&
    errorLoadingDEL?.message === 'Request failed with status code 403'

  return {
    accessDeniedToDeliveryFacility,
    accessDeniedToPickupFacility,
    deliveryFacility,
    pickupFacility,
  }
}

export function shouldDisableFields({
  fulfillment,
  isSupplier,
}: {
  fulfillment: TransientFulfillment
  isSupplier?: boolean
}) {
  const disableFacilityFields =
    fulfillment.owner === 'customer' && !fulfillment.customer_uuid
  const disableDelivery =
    isSupplier && fulfillment.order_identifier_type === 'orders'

  return { disableFacilityFields, disableDelivery }
}

type FulfillmentLaneProps = Readonly<{
  userType: 'shipper' | 'supplier'
}>

export function FulfillmentLane({ userType }: FulfillmentLaneProps) {
  const { fulfillment, setPartialFulfillment, setFulfillment } =
    useFulfillmentFormContext()
  const isSupplier = useIsCurrentShipperASupplier()

  const errorsCount = getTransientErrorsCount(fulfillment.stops)

  const {
    accessDeniedToDeliveryFacility,
    accessDeniedToPickupFacility,
    deliveryFacility,
    pickupFacility,
  } = useFacilityDetails(fulfillment, isSupplier)

  function handleStopChange(stops: TransientStop[]) {
    setPartialFulfillment({ stops })
  }

  const handleHandlingUnitChange = (
    newHandlingUnits: TransientHandlingUnit[]
  ) => {
    setPartialFulfillment({
      handling_units: newHandlingUnits,
    })
  }

  const [pickup, delivery] = get(fulfillment, 'stops', [])

  const oneItemRequiredError =
    getTransientError(fulfillment, 'handling_units') ==
    AT_LEAST_ONE_HU_REQUIRED_MESSAGE

  const facilitiesDatasource = useMemo(() => {
    if (fulfillment.owner === 'us' || !fulfillment.customer_uuid) {
      return undefined
    }
    return buildCustomerFacilitiesDatasource(fulfillment.customer_uuid)
  }, [fulfillment.customer_uuid, fulfillment.owner])

  const { disableFacilityFields, disableDelivery } = shouldDisableFields({
    fulfillment,
    isSupplier,
  })

  // update the transient fulfillment with facilities data
  useEffect(() => {
    if (pickupFacility || deliveryFacility) {
      setFulfillment((current) => {
        return {
          ...current,
          stops: [
            {
              ...current.stops[0],
              facility: pickupFacility,
            },
            {
              ...current.stops[1],
              facility: deliveryFacility,
            },
          ],
        }
      })
    }
  }, [pickupFacility, deliveryFacility, setFulfillment])

  return (
    <StopsForm
      stops={fulfillment.stops}
      createStop={createTransientStop}
      onChange={handleStopChange}
    >
      <Card id="fulfillment-lane">
        <Card.Title>
          <Layout.Group align="center" padding="none" gap="spacing-1">
            Lane
            <ErrorTag errorsCount={errorsCount} />
          </Layout.Group>
        </Card.Title>
        <Card.Divider />
        <Card.Body>
          <Layout.Stack>
            <StopForm index={0}>
              <Layout.Group data-testid="pickup-stop">
                <StopForm.Facility
                  label="Pickup"
                  datasource={facilitiesDatasource}
                  style={{ flex: 1 }}
                  disabled={disableFacilityFields}
                  required
                  customerUUID={defaultTo(fulfillment.customer_uuid, '')}
                />

                <StopForm.Date label="Pickup-ready date" required />
              </Layout.Group>
              <DeniedAccessMessage
                accessDenied={accessDeniedToPickupFacility}
                pickup
              />
            </StopForm>
            <StopForm index={fulfillment.stops.length - 1}>
              <Layout.Group data-testid="delivery-stop">
                <StopForm.Facility
                  datasource={facilitiesDatasource}
                  disabled={disableFacilityFields || disableDelivery}
                  label="Delivery"
                  required={!accessDeniedToDeliveryFacility}
                  style={{ flex: 1 }}
                  customerUUID={defaultTo(fulfillment.customer_uuid, '')}
                />

                <StopForm.Date
                  disabled={disableDelivery}
                  label="Delivery date"
                  required={!disableDelivery}
                />
              </Layout.Group>
              <DeniedAccessMessage
                accessDenied={accessDeniedToDeliveryFacility}
              />
            </StopForm>
          </Layout.Stack>
        </Card.Body>
      </Card>
      {Boolean(pickup.facility) && (
        <FulfillmentStop
          key={`${pickup._metadata.id}-${0}`}
          id="pickup"
          stop={pickup}
          index={0}
          totalStops={2}
          initialCollapsedState={false}
        >
          <StopFacilitySummary
            stop={pickup}
            style={{
              margin: 0,
              borderTopWidth: 0,
              borderBottomColor: toCSSValue('color-divider'),
              borderBottomWidth: toCSSValue('border-thin'),
            }}
          />
          <StopForm key={pickup._metadata.id} index={0}>
            <Layout.Stack
              data-testid="pickup-details"
              gap="spacing-4"
              padding="spacing-6"
            >
              <StopForm.Contact addable={userType === 'shipper'} />
              <StopForm.Notes />
            </Layout.Stack>
          </StopForm>
          <FulfillmentHandlingUnit
            hasOneItemRequiredError={oneItemRequiredError}
            handlingUnits={fulfillment.handling_units || []}
            onHandlingUnitChange={handleHandlingUnitChange}
            stops={[pickup, delivery]}
          />
          {userType === 'shipper' && (
            <>
              <Divider />
              <Layout.Group padding="spacing-6">
                <FacilitySummary facility={pickup.facility}>
                  <FacilitySummary.EditButton />
                </FacilitySummary>
              </Layout.Group>
            </>
          )}
        </FulfillmentStop>
      )}
      {Boolean(delivery.facility) && (
        <FulfillmentStop
          key={`${delivery._metadata.id}-${1}`}
          id="delivery"
          stop={delivery}
          index={1}
          totalStops={2}
          initialCollapsedState={false}
        >
          <StopFacilitySummary
            stop={delivery}
            style={{
              margin: 0,
              borderTopWidth: 0,
              borderBottomColor: toCSSValue('color-divider'),
              borderBottomWidth: toCSSValue('border-thin'),
            }}
          />
          <StopForm key={delivery._metadata.id} index={1}>
            <Layout.Stack
              data-testid="delivery-details"
              gap="spacing-4"
              padding="spacing-6"
            >
              <StopForm.Contact addable={userType === 'shipper'} />
              <StopForm.Notes />
            </Layout.Stack>
          </StopForm>
          {userType === 'shipper' && (
            <>
              <Divider />
              <Layout.Group padding="spacing-6">
                <FacilitySummary facility={delivery.facility}>
                  <FacilitySummary.EditButton />
                </FacilitySummary>
              </Layout.Group>
            </>
          )}
        </FulfillmentStop>
      )}
    </StopsForm>
  )
}
