import { Layout } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash-es'

import { useSettings } from '_shared_/settings/useSettings'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { HandlingUnitForm } from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsForm'
import { HandlingUnitsSummary } from 'components/HandlingUnitsManager/HandlingUnitsSummary'
import { OrderItemForm } from 'components/HandlingUnitsManager/OrderItemForm/OrderItemForm'
import { ORDER_GROUPING_SINGLE_OPTION } from 'screens/Orders/ManualConsolidation/useSelectedOrders'

import { useConsolidationContext } from '../consolidation.context'

export function HandlingUnitConsolidationSection({
  handlingUnit,
}: {
  readonly handlingUnit: TransientHandlingUnit
}) {
  const { consolidationType } = useConsolidationContext()

  if (consolidationType === 'fulfillment') {
    return <FulfillmentSection handlingUnit={handlingUnit} />
  }

  return <OrderSection handlingUnit={handlingUnit} />
}

function FulfillmentSection({
  handlingUnit,
}: {
  readonly handlingUnit: TransientHandlingUnit
}) {
  return (
    <Layout.Box paddingB="spacing-4" paddingT="none" paddingL="none">
      <HandlingUnitsSummary value={handlingUnit}>
        <HandlingUnitsSummary.FulfillmentRefNumber
          labelVariant="body-md"
          inline={false}
        />
      </HandlingUnitsSummary>
    </Layout.Box>
  )
}

function OrderSection({
  handlingUnit,
}: {
  readonly handlingUnit: TransientHandlingUnit
}) {
  const { selectedOrders, groupingMode } = useConsolidationContext()
  const {
    values: [enableShippedPackageCount],
  } = useSettings(['flags.ENABLE_ORDER_SHIPPED_COUNT'])

  if (isEmpty(selectedOrders)) {
    return null
  }

  if (groupingMode === ORDER_GROUPING_SINGLE_OPTION) {
    return (
      <Layout.Box paddingY="spacing-4" paddingL="none">
        <HandlingUnitsSummary value={handlingUnit}>
          <HandlingUnitsSummary.OrderItemsRefs variant="large" label="Order" />
        </HandlingUnitsSummary>
        {enableShippedPackageCount &&
          handlingUnit.order_items?.map((orderItem, index) => (
            <OrderItemForm key={orderItem._metadata.id} index={index}>
              <OrderItemForm.ShippedPackageCount />
            </OrderItemForm>
          ))}
      </Layout.Box>
    )
  }

  return (
    <Layout.Stack gap="spacing-4">
      <HandlingUnitForm.OrderItems selectableOrders={selectedOrders} />
      {enableShippedPackageCount &&
        handlingUnit.order_items?.map((orderItem, index) => (
          <OrderItemForm key={orderItem._metadata.id} index={index}>
            <OrderItemForm.ShippedPackageCount />
          </OrderItemForm>
        ))}
    </Layout.Stack>
  )
}
