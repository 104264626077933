import type { Stop as ShipmentStop } from 'screens/Shipper/Shipments/Details/types'
import type { FacilityContactV2, FacilityDetailsV2 } from 'services/facilities'
import type { NewShipmentStopPayload } from 'shipments/shipments.services'
import type { AttributeMapper } from 'utils/AdapterFactory'
import AdapterFactory from 'utils/AdapterFactory'
import {
  mapFacilityContactV1toV2,
  mapFacilityDetailsV1ToV2,
} from 'utils/adapters/facilityV1toV2'
import { formatISODate } from 'utils/dateUtils'
import toArray from 'utils/toArray'
import { createTransient, isTransient } from 'utils/transient'

import type { Stop, TransientStop } from './Stops.types'

export function createContact(
  overrides?: Partial<FacilityContactV2>
): FacilityContactV2 {
  return {
    uuid: '',
    name: '',
    email: '',
    phone: '',
    extension: '',
    ...overrides,
  }
}

/**
 * Creates a Contact instance with additional _metadata to be used only in forms.
 */
export function createTransientContact(stop?: Partial<FacilityContactV2>) {
  return createTransient(createContact(stop))
}

export function createFacility(
  overrides?: Partial<FacilityDetailsV2>
): FacilityDetailsV2 {
  return {
    uuid: '',
    name: '',
    address: '',
    address_details: '',
    zipcode: '',
    city: '',
    state: '',
    country: '',
    warehouse_uuid: null,
    contacts: [],
    hours_of_operation: [],
    notes: [],
    accessorials: [],
    archived: false,
    ...overrides,
  }
}

/**
 * Creates a Facility instance with additional _metadata to be used only in forms.
 */
export function createTransientFacility(stop?: Partial<FacilityDetailsV2>) {
  return createTransient(createFacility(stop))
}

export function createStop(overrides?: Partial<Stop>): Stop {
  return {
    uuid: '',
    stop_type: 'delivery',
    facility: null,
    id: null,
    ...overrides,
  }
}

/**
 * Creates a Stop instance with additional _metadata to be used only in forms.
 */
export function createTransientStop(stop?: Partial<Stop>) {
  return createTransient(createStop(stop))
}

export function toTransientStops(stops: Partial<Stop> | Partial<Stop>[]) {
  return toArray(stops).map((stop) => {
    if (isTransient(stop)) {
      return stop
    }

    return createTransientStop(stop)
  })
}

const STOP_TO_PAYLOAD_MAPPER: AttributeMapper[] = [
  {
    from: 'stop_type',
    to: 'stop_type',
    transform(stop_type: Stop['stop_type']) {
      return stop_type || null
    },
  },
  {
    from: 'facility',
    to: 'facility_uuid',
    transform(facility: Stop['facility']) {
      return facility?.uuid ?? null
    },
  },
  {
    from: 'date',
    to: 'date',
    transform(date: Stop['date']) {
      return date ? formatISODate(date) : null
    },
  },
  {
    from: 'notes',
    to: 'notes',
    transform(notes: Stop['notes']) {
      return notes || null
    },
  },
  {
    from: 'update_facility_notes',
    to: 'update_facility_notes',
    transform(update_facility_notes: Stop['update_facility_notes']) {
      return Boolean(update_facility_notes)
    },
  },
  {
    from: 'contact',
    to: 'default_contact_uuid',
    transform(contact: Stop['contact']) {
      return contact?.uuid ?? null
    },
  },
]

export const adaptStopToNewShipmentPayload = AdapterFactory<
  Omit<NewShipmentStopPayload, 'index'>,
  Stop
>(STOP_TO_PAYLOAD_MAPPER)

export function mapShipmentStopsToTransientStops(
  shipmentStops: Array<ShipmentStop>
): Array<TransientStop> {
  return shipmentStops.map((shipmentStop) => {
    return createTransientStop({
      ...shipmentStop,
      id: shipmentStop.id as number,
      facility: shipmentStop.facility
        ? mapFacilityDetailsV1ToV2(shipmentStop.facility)
        : null,
      contact: shipmentStop.contact
        ? mapFacilityContactV1toV2(shipmentStop.contact as Contact)
        : null,
    })
  })
}

export function getStopFacilityLabel(index: number, totalStops: number) {
  if (index === 0) {
    return 'Pickup'
  }

  if (index === totalStops - 1) {
    return 'Delivery'
  }

  return `Stop #${index}`
}

export function getStopDateFacilityLabel(index: number, totalStops: number) {
  if (index === 0) {
    return 'Pickup date'
  }

  if (index === totalStops - 1) {
    return 'Delivery date'
  }

  return `Stop #${index} date`
}

export function isIntermediaryStop(index: number, totalStops: number) {
  return index > 0 && index < totalStops - 1
}

export function getStopSectionTitle(stopIndex: number, totalStops: number) {
  if (stopIndex === 0) {
    return 'Pickup details'
  }

  if (stopIndex === totalStops - 1) {
    return 'Delivery details'
  }

  return `Stop #${stopIndex} details`
}

export function isMultiStop(totalStops: number) {
  return totalStops > 2
}

export function getIsDateRequired(stopIndex: number, totalStops: number) {
  const isFirstStop = stopIndex === 0
  const isLastStop = stopIndex === totalStops - 1

  return isFirstStop || (isLastStop && isMultiStop(totalStops))
}
