import { Banner, Button, Layout } from '@loadsmart/miranda-react'
import { useHistory } from 'react-router-dom'

import { useCurrentUser } from '_shared_/user/useCurrentUser'
import PageIndex from 'components/PageIndex'
import { StickyFormFooter } from 'components/StickyFormFooter'
import { FulfillmentInfo } from 'fulfillments/components/FulfillmentInfo'
import { FulfillmentLane } from 'fulfillments/components/FulfillmentLane'
import { FulfillmentNotes } from 'fulfillments/components/FulfillmentNotes'
import { getUserType } from 'fulfillments/create/CreateFulfillmentForm'
import { getFulfillmentFormIndex } from 'fulfillments/getFulfillmentFormIndex'
import { hasTransientError } from 'utils/transient'

import { useEditFulfillmentForm } from './useEditFulfillmentForm'

export function EditFulfillmentForm() {
  const history = useHistory()
  const { user } = useCurrentUser()
  const { fulfillment, saveStatus, handleSave } = useEditFulfillmentForm()

  const formIndex = getFulfillmentFormIndex(fulfillment)
  const userType = getUserType(user?.shipper)
  const hasError = hasTransientError(fulfillment)

  return (
    <form>
      <Layout.Container>
        <Layout.Row columnGap="none">
          <Layout.Column offsetLg="3" sm="12" md="8" lg="6">
            <Layout.Stack gap="spacing-6" paddingB="spacing-8">
              {hasError && (
                <Banner variant="danger">
                  <Banner.Title>
                    Please fix the errors below to proceed
                  </Banner.Title>
                </Banner>
              )}
              <FulfillmentInfo />
              <FulfillmentLane userType={userType} />
              <FulfillmentNotes />
            </Layout.Stack>
          </Layout.Column>
          <Layout.Column sm="12" md="4" lg="3">
            <Layout.Box>
              <PageIndex values={formIndex} />
            </Layout.Box>
          </Layout.Column>
        </Layout.Row>
      </Layout.Container>
      <StickyFormFooter>
        <Layout.Container>
          <Layout.Row columnGap="none">
            <Layout.Column offsetLg="3" sm="12" md="8" lg="6">
              <Layout.Group justify="flex-end">
                <Layout.Group justify="space-between">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    onClick={handleSave}
                    loading={saveStatus === 'loading'}
                    disabled={hasError}
                  >
                    Save fulfillment
                  </Button>
                </Layout.Group>
              </Layout.Group>
            </Layout.Column>
          </Layout.Row>
        </Layout.Container>
      </StickyFormFooter>
    </form>
  )
}
