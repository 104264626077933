import { Layout, Text } from '@loadsmart/miranda-react'

import type { StopAppointment } from 'screens/Shipper/Shipments/Details/types'

import { formatDateDayString, formatDateMonthString } from './utils'

export type AppointmentDateProps = {
  readonly appointment: StopAppointment | null
  readonly stopDate?: string | null
}

export default function AppointmentDateCard({
  appointment,
  stopDate,
}: AppointmentDateProps) {
  const date = appointment?.scheduled_date || appointment?.suggested_date
  const dateStart = date?.start
  const dateEnd = date?.end

  if (!date && !stopDate) {
    return null
  }

  return (
    <Layout.Box
      borderRadius="border-radius-s"
      borderColor="color-border"
      borderWidth="border-thin"
      paddingY="spacing-1"
      padding="spacing-4"
    >
      <Layout.Stack gap="none" paddingY="none" align="center">
        <Text variant="body-lg-bold">
          {formatDateDayString(dateStart, dateEnd, stopDate)}
        </Text>
        <Text variant="body-sm" color="color-text-tertiary">
          {formatDateMonthString(dateStart, dateEnd, stopDate)}
        </Text>
      </Layout.Stack>
    </Layout.Box>
  )
}
