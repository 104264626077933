import { createContext, useContext } from 'react'

import type { ConsolidationContextValue } from './consolidation.types'

export const ConsolidationContext = createContext<
  ConsolidationContextValue | undefined
>(undefined)

export function useConsolidationContext() {
  const context = useContext(ConsolidationContext)

  if (context === undefined) {
    throw new Error(
      'useConsolidationContext must be used within a ConsolidationContext.Provider'
    )
  }

  return context
}
