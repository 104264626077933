import { Card, Divider, Layout, Text } from '@loadsmart/miranda-react'
import { useMemo } from 'react'

import { Column, GridV2 as Grid, GridV2 } from 'components/Grid'
import {
  CommoditiesForm,
  CommodityForm,
} from 'components/HandlingUnitsManager/CommoditiesForm/CommoditiesForm'
import type {
  TransientCommodity,
  TransientHandlingUnit,
  TransientHandlingUnitOrderItem,
} from 'components/HandlingUnitsManager/HandlingUnits.types'
import {
  HandlingUnitForm,
  HandlingUnitsForm,
} from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsForm'
import { HandlingUnitsFormAddHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsFormAddHandlingUnit'
import {
  createTransientCommodity,
  createTransientHandlingUnit,
  createTransientHandlingUnitOrderItem,
  getHandlingUnitsPickedUpAtStop,
} from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import { OrderItemsForm } from 'components/HandlingUnitsManager/OrderItemForm/OrderItemForm'
import { HandlingUnitHeader } from 'components/LTLQuoteFormHandlingUnit/HandlingUnitHeader'
import type { TransientStop } from 'components/StopsManager'
import { HandlingUnitsFormHeader } from 'screens/Shipper/Shipments/components/HandlingUnit/HandlingUnitsFormHeader'
import { hasMoreThanOneItem, replaceArrayItem } from 'utils/arrays'
import { hasTransientError } from 'utils/transient'

import { HandlingUnitConsolidationSection } from '../../consolidation/components/HandlingUnitConsolidationSection'
import { useConsolidationContext } from '../../consolidation/consolidation.context'
import { useDeliveryStopSelectOptions } from '../../create.hooks'

type EXPFormHandlingUnitProps = {
  readonly handlingUnits: TransientHandlingUnit[]
  readonly stopIndex: number
  readonly stops: TransientStop[]
  readonly hasOneItemRequiredError?: boolean
  readonly onHandlingUnitChange: (value: TransientHandlingUnit[]) => void
}

function FormCommodity({
  commodityIndex,
  handlingUnit,
}: {
  readonly commodityIndex: number
  readonly handlingUnit: TransientHandlingUnit
}) {
  const { canBeRemoved } = useConsolidationContext()
  return (
    <CommodityForm index={commodityIndex}>
      <Layout.Stack aria-label={`Commodity #${commodityIndex + 1}`}>
        <Layout.Stack gap="spacing-1">
          <Layout.Group align="center" justify="space-between">
            <Text variant="body-md-bold" aria-hidden="true">
              {`Commodity #${commodityIndex + 1}`}
            </Text>

            {canBeRemoved(handlingUnit) &&
              hasMoreThanOneItem(handlingUnit.commodities) && (
                <CommoditiesForm.RemoveCommodity />
              )}
          </Layout.Group>
          <Divider />
        </Layout.Stack>

        <Layout.Stack>
          <Layout.Stack gap="none">
            <CommodityForm.Description required hint={<>&nbsp;</>} />

            <GridV2 columns={4} align="flex-end">
              <CommodityForm.Weight required hint={<>&nbsp;</>} />
              <CommodityForm.PackageCount hint={<>&nbsp;</>} />
              <CommodityForm.PackageType hint={<>&nbsp;</>} />
            </GridV2>

            <GridV2 columns={4} align="flex-end">
              <CommodityForm.Nmfc hint={<>&nbsp;</>} />
              <CommodityForm.FreightClass hint={<>&nbsp;</>} />
              <Column columns={2}>
                <CommodityForm.NmfcSearch hint={<>&nbsp;</>} />
              </Column>
            </GridV2>
          </Layout.Stack>
        </Layout.Stack>
      </Layout.Stack>
    </CommodityForm>
  )
}

export function EXPFormHandlingUnit({
  hasOneItemRequiredError,
  handlingUnits,
  stopIndex,
  stops,
  onHandlingUnitChange,
}: EXPFormHandlingUnitProps) {
  const { canBeRemoved } = useConsolidationContext()
  const deliveryStops = useDeliveryStopSelectOptions(stops, stopIndex)

  const filtered = useMemo(
    () => getHandlingUnitsPickedUpAtStop(handlingUnits, stopIndex),
    [handlingUnits, stopIndex]
  )

  const handleCommodityChange = (index: number) => {
    return (newTransientCommodities: TransientCommodity[]) => {
      onHandlingUnitChange(
        replaceArrayItem(handlingUnits, index, {
          ...handlingUnits[index],
          commodities: newTransientCommodities,
        })
      )
    }
  }

  const handleOrderItemChange = (index: number) => {
    return (newTransientOrderItems: TransientHandlingUnitOrderItem[]) => {
      onHandlingUnitChange(
        replaceArrayItem(handlingUnits, index, {
          ...handlingUnits[index],
          order_items: newTransientOrderItems,
        })
      )
    }
  }

  const handleCreateItem = () => {
    return createTransientHandlingUnit({
      order_items: [],
      pickup_stop_index: stopIndex,
      delivery_stop_index: null,
    })
  }

  return (
    <HandlingUnitsForm
      items={handlingUnits}
      createItem={handleCreateItem}
      onChange={onHandlingUnitChange}
    >
      <HandlingUnitsFormHeader
        handlingUnits={filtered}
        hasOneItemRequiredError={hasOneItemRequiredError}
        actions={<HandlingUnitsFormAddHandlingUnit />}
      />
      {handlingUnits.map((handlingUnit, index) => {
        // We need to return null to maintain the index of the handling units,
        // that's why we are not filtering the handling units
        const isHandlingUnitFromThisStop =
          handlingUnit.pickup_stop_index === stopIndex

        if (!isHandlingUnitFromThisStop) {
          return null
        }

        return (
          <HandlingUnitForm key={handlingUnit._metadata?.id} index={index}>
            <CommoditiesForm
              items={handlingUnit.commodities}
              createItem={createTransientCommodity}
              onChange={handleCommodityChange(index)}
            >
              <OrderItemsForm
                items={handlingUnit.order_items || []}
                createItem={createTransientHandlingUnitOrderItem}
                onChange={handleOrderItemChange(index)}
              >
                <Card
                  aria-labelledby={`title-${handlingUnit._metadata?.id}`}
                  data-testid={`handling-unit-${index}`}
                  onToggle={(event) => event.stopPropagation()}
                  style={{
                    '--m-card-border-left': 'none',
                    '--m-card-border-right': 'none',
                    '--m-card-border-bottom': 'none',
                  }}
                  collapsible
                >
                  <HandlingUnitHeader
                    handlingUnit={handlingUnit}
                    actions={
                      canBeRemoved(handlingUnit) &&
                      hasMoreThanOneItem(filtered) && (
                        <Layout.Group align="center" justify="flex-end">
                          <HandlingUnitsForm.RemoveHandlingUnit />
                        </Layout.Group>
                      )
                    }
                  />
                  <Card.Divider />
                  <Card.Body>
                    <Layout.Stack
                      gap={
                        hasTransientError(handlingUnit) ? 'spacing-4' : 'none'
                      }
                    >
                      <HandlingUnitConsolidationSection
                        handlingUnit={handlingUnit}
                      />
                      <Layout.Group align="flex-start">
                        <HandlingUnitForm.PackageType
                          required
                          hint={<>&nbsp;</>}
                        />
                        <HandlingUnitForm.PackageCount
                          required
                          hint={<>&nbsp;</>}
                        />
                        <HandlingUnitForm.Stackable hint={<>&nbsp;</>} />
                        <HandlingUnitForm.Turnable hint={<>&nbsp;</>} />
                      </Layout.Group>

                      <Grid columns={3} rowGap="none">
                        <HandlingUnitForm.Dimension
                          required
                          dimension="length"
                          hint={<>&nbsp;</>}
                        />
                        <HandlingUnitForm.Dimension
                          required
                          dimension="width"
                          hint={<>&nbsp;</>}
                        />
                        <HandlingUnitForm.Dimension
                          required
                          dimension="height"
                          hint={<>&nbsp;</>}
                        />
                        <HandlingUnitForm.WeightType
                          required
                          hint={<>&nbsp;</>}
                        />
                        <HandlingUnitForm.DeliverAt
                          options={deliveryStops}
                          hint={<>&nbsp;</>}
                          required
                        />
                      </Grid>

                      {handlingUnit.commodities.map(
                        (
                          commodity: TransientCommodity,
                          commodityIndex: number
                        ) => (
                          <FormCommodity
                            key={commodity._metadata?.id}
                            commodityIndex={commodityIndex}
                            handlingUnit={handlingUnit}
                          />
                        )
                      )}
                    </Layout.Stack>
                  </Card.Body>
                  {canBeRemoved(handlingUnit) && (
                    <Card.Footer>
                      <CommoditiesForm.AddCommodity />
                    </Card.Footer>
                  )}
                </Card>
              </OrderItemsForm>
            </CommoditiesForm>
          </HandlingUnitForm>
        )
      })}
    </HandlingUnitsForm>
  )
}
