import type { ReactNode } from 'react'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { DeleteFulfillmentDialog } from './DeleteFulfillment.dialog'
import { useDeleteFulfillment } from './useDeleteFulfillment'

export interface DeleteFulfillmentContextValue {
  closeDialog: () => void
  confirm: () => void
  isDeleting?: boolean
  openDialog: (id?: string) => void
  selectedUUID?: string
}

export const DeleteFulfillmentContext =
  createContext<DeleteFulfillmentContextValue>({
    closeDialog: () => {},
    confirm: () => {},
    isDeleting: false,
    openDialog: () => {},
    selectedUUID: undefined,
  })

export function DeleteFulfillmentProvider({
  callback,
  children,
}: Readonly<{ children: ReactNode; callback?: () => void }>) {
  const [selectedUUID, setSelectedUUID] = useState<string | undefined>()
  const {
    isError,
    isLoading: isDeleting,
    isSuccess,
    mutate,
  } = useDeleteFulfillment()

  const closeDialog = useCallback(() => {
    setSelectedUUID(undefined)
  }, [setSelectedUUID])

  const openDialog = useCallback(
    (UUID?: string) => {
      setSelectedUUID(UUID)
    },
    [setSelectedUUID]
  )

  const confirm = useCallback(() => {
    if (!selectedUUID) {
      return
    }

    mutate(selectedUUID)
  }, [mutate, selectedUUID])

  const context = useMemo(
    () => ({ closeDialog, confirm, isDeleting, openDialog, setSelectedUUID }),
    [closeDialog, confirm, isDeleting, openDialog, setSelectedUUID]
  )

  useEffect(() => {
    if (isError) {
      toast.error('Failed to delete the fulfillment')
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      toast.success('Fulfillment deleted')
      callback?.()
      setSelectedUUID(undefined)
    }
  }, [callback, isSuccess, setSelectedUUID])

  return (
    <DeleteFulfillmentContext.Provider value={context}>
      {children}
      {selectedUUID ? (
        <DeleteFulfillmentDialog
          closeDialog={closeDialog}
          confirm={confirm}
          isDeleting={isDeleting}
        />
      ) : null}
    </DeleteFulfillmentContext.Provider>
  )
}
