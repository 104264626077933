import { useQueries } from 'react-query'

import type { FulfillmentDetails } from 'fulfillments/details/ViewFulfillmentPage.data'
import { enrichFulfillment } from 'fulfillments/details/ViewFulfillmentPage.data'
import { getFulfillmentDetails } from 'fulfillments/fulfillment-service'

function mapQueries(UUIDs: string[] | null) {
  if (!UUIDs?.length) {
    return []
  }

  return UUIDs.map((uuid) => ({
    queryKey: ['getFulfillmentDetails', uuid],
    queryFn: () => getFulfillmentDetails(uuid),
    staleTime: 60000, // 1 min, avoids repeating calls as user adds to the selection
  }))
}

export function useFulfillmentsDetails(UUIDs: string[] = []) {
  const results = useQueries(mapQueries(UUIDs))

  return results.reduce<{
    isError?: boolean
    isLoading?: boolean
    fulfillments: FulfillmentDetails[]
  }>(
    (summary, result) => {
      const { data, isError, isLoading } = result

      const fulfillment = enrichFulfillment(data)
      return {
        isError: summary.isError || isError,
        isLoading: summary.isLoading || isLoading,
        fulfillments: fulfillment
          ? [...summary.fulfillments, fulfillment]
          : summary.fulfillments,
      }
    },
    { fulfillments: [] }
  )
}
