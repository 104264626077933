import { isAxiosError } from 'axios'
import { get, isEmpty, set } from 'lodash-es'
import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import type { TransientStop } from 'components/StopsManager'
import {
  createTransientStop,
  getStopFacilityLabel,
} from 'components/StopsManager'
import { useShipmentsShipperSettings } from 'hooks/useQuery'
import { appRoutes } from 'router/app-routes'
import type { NewShipmentPayload } from 'shipments/shipments.services'
import { createNewShipment, handleAction } from 'shipments/shipments.services'
import { handleToastError } from 'shipments/shipments.utils'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { scrollToTop } from 'utils/scroll'
import { hasTransientError } from 'utils/transient'
import {
  getTransportationMode,
  resolveTransportationMode,
} from 'utils/transportationMode'

import type { TransientShipment } from '../components/Shipment'
import { createTransientShipment } from '../components/Shipment'
import { useConsolidation } from './consolidation/hooks/useConsolidation'
import { useShipmentModePageConfig } from './create.common'
import type { ShipmentContextValue } from './create.types'
import {
  mapStopsToOptionsByStopType,
  updateItemsStopIndexesOnStopAddition,
  updateItemsStopIndexesOnStopRemoval,
  updateItemStopIndexesIfStopTypeChanged,
} from './create.utils'
import { validate } from './create.validation'

type ActionType = 'create-shipment' | 'create-and-get-quote'

const analyticsEventByActionType = {
  'create-shipment': 'Created: Shipment' as const,
  'create-and-get-quote': 'Clicked: Create & Get Quote' as const,
} as const

function handleCreateShipmentError(error: any) {
  let errorMessage: string | string[] =
    'Unable to create the shipment. Please try again'

  if (isAxiosError(error) && error.response?.data) {
    errorMessage = error.response.data
  }

  handleToastError(errorMessage)
}

export function useCreateNewShipment() {
  const history = useHistory()
  const [shipment, setShipment] = useState<TransientShipment>(() =>
    createTransientShipment({
      mode: resolveTransportationMode('ftl') as string,
      stops: [
        createTransientStop({ stop_type: 'pickup', stop_index: 0 }),
        createTransientStop({ stop_type: 'delivery', stop_index: 1 }),
      ],
    })
  )
  useCustomFields({ setShipment })

  const setPartialShipment = useCallback(
    (changes: Partial<TransientShipment>) => {
      setShipment((currentShipment) => {
        const updatedShipment = Object.keys(changes).reduce(
          (newShipment, path) => {
            return set(newShipment, path, get(changes, path))
          },
          { ...currentShipment }
        )

        if (hasTransientError(updatedShipment)) {
          const [validatedShipment] = validate(updatedShipment)

          return validatedShipment
        }

        return updatedShipment
      })
    },
    [setShipment]
  )

  const consolidationContext = useConsolidation({
    setPartialShipment,
    setShipment,
  })

  const onSetShipment = useCallback(
    (action: SetStateAction<TransientShipment>) => {
      setShipment((currentShipment) => {
        const updatedShipment =
          typeof action === 'function' ? action(currentShipment) : action

        if (hasTransientError(updatedShipment)) {
          const [validatedShipment] = validate(updatedShipment)

          return validatedShipment
        }

        return updatedShipment
      })
    },
    [setShipment]
  )

  const shipmentContextValue: ShipmentContextValue = useMemo(
    () => [shipment, setPartialShipment, onSetShipment],
    [shipment, setPartialShipment, onSetShipment]
  )

  const mode = getTransportationMode(shipment.mode!)

  const adaptShipmentToNewShipmentPayload = useShipmentModePageConfig(
    mode?.abbr
  ).adapter

  const [{ isSubmitting, requestedAction }, setSubmitState] = useState<{
    isSubmitting: boolean
    requestedAction: ActionType | undefined
  }>({ isSubmitting: false, requestedAction: undefined })

  const trackMixpanelAction = ({
    payload,
    actionType,
  }: {
    payload: NewShipmentPayload & { favorite_facility_selected: boolean }
    actionType: ActionType
  }) => {
    const {
      mode: trackedMode,
      equipment_type,
      accessorials,
      requested_accessorials,
      stops,
      items,
      hazmat,
      favorite_facility_selected,
    } = payload

    analytics.track(
      analyticsEventByActionType[actionType],
      AnalyticsEventTrigger.click,
      {
        mode: trackedMode,
        equipmentType: equipment_type,
        hasAccessorials: Boolean(
          accessorials?.length || requested_accessorials?.length
        ),
        numberOfItems: items.length,
        numberOfStops: stops.length,
        hazmat: Boolean(hazmat),
        nmfc: false,
        freightClass: false,
        favoriteFacilitySelected: favorite_facility_selected,
        number_of_orders: consolidationContext.ordersCount,
      }
    )
  }

  const onCreateShipment = async (payload: NewShipmentPayload) => {
    if (payload.equipment_requirements) {
      payload.equipment_requirements.unit =
        payload.equipment_requirements.unit || 'fahrenheit'
    }
    const [validatedShipment, isValid] = validate(shipment)

    if (!isValid) {
      setShipment(validatedShipment)
      scrollToTop()
      return
    }

    setSubmitState({ isSubmitting: true, requestedAction: 'create-shipment' })
    trackMixpanelAction({
      payload: {
        ...payload,
        favorite_facility_selected: shipment.stops.some((stop) =>
          Boolean(stop.facility?.pinned)
        ),
      },
      actionType: 'create-shipment',
    })

    try {
      const createdShipment = await createNewShipment(payload)

      if (createdShipment?.uuid) {
        history.push(
          generatePath(appRoutes.shipmentDetails, {
            id: createdShipment.uuid,
          })
        )
      } else {
        toast.error("Couldn't redirect to the shipment page")
      }
    } catch (error: any) {
      handleCreateShipmentError(error)
    }

    setSubmitState({ isSubmitting: false, requestedAction: undefined })
  }

  const getQuoteFromShipment = async (createdShipmentUUID: string) => {
    toast.info('Getting Quote...')

    try {
      const createdQuote = await handleAction({
        method: 'POST',
        endpoint: `/shipments/${createdShipmentUUID}/spot-quote`,
      })

      if (createdQuote?.spot_quote_uuid) {
        history.push(
          generatePath(appRoutes.quoteDetails, {
            quoteUUID: createdQuote.spot_quote_uuid,
          })
        )
      } else {
        toast.error("Couldn't redirect to new Spot Quote")
      }
    } catch {
      toast.error("Couldn't create new Spot Quote")
    }
  }

  const onCreateAndGetQuote = async (payload: NewShipmentPayload) => {
    if (payload.equipment_requirements) {
      payload.equipment_requirements.unit =
        payload.equipment_requirements.unit || 'fahrenheit'
    }

    const [validatedShipment, isValid] = validate(shipment)

    if (!isValid) {
      setShipment(validatedShipment)
      scrollToTop()
      return
    }

    setSubmitState({
      isSubmitting: true,
      requestedAction: 'create-and-get-quote',
    })
    trackMixpanelAction({
      payload: {
        ...payload,
        favorite_facility_selected: shipment.stops.some((stop) =>
          Boolean(stop.facility?.pinned)
        ),
      },
      actionType: 'create-and-get-quote',
    })

    try {
      const createdShipment = await createNewShipment(payload)

      if (createdShipment?.uuid) {
        getQuoteFromShipment(createdShipment.uuid)
      } else {
        toast.error("Couldn't create new Spot Quote")
      }
    } catch (error: any) {
      handleCreateShipmentError(error)
    }

    setSubmitState({ isSubmitting: false, requestedAction: undefined })
  }

  return {
    shipment,
    onCreateShipment,
    onCreateAndGetQuote,
    requestedAction,
    isSubmitting,
    shipmentContextValue,
    consolidationContextValue: consolidationContext,
    adaptShipmentToNewShipmentPayload,
  }
}

export function useMultiStopWithHandlingUnitsShipmentFormHandlers({
  setShipment,
}: {
  setShipment: Dispatch<SetStateAction<TransientShipment>>
}) {
  const handleStopsChange = useCallback(
    (newTransientStops: TransientStop[]) => {
      setShipment((previousShpiment) => {
        const parsetItems = updateItemStopIndexesIfStopTypeChanged(
          previousShpiment,
          newTransientStops
        )
        return {
          ...previousShpiment,
          stops: newTransientStops.map((stop, index) => ({
            ...stop,
            stop_index: index,
          })),
          items: parsetItems,
        }
      })
    },
    [setShipment]
  )

  const handleStopAdded = useCallback(
    (addedAt: number) => {
      setShipment((previousShipment) => {
        const parsedItems = updateItemsStopIndexesOnStopAddition(
          previousShipment.items,
          addedAt
        )

        return { ...previousShipment, items: parsedItems }
      })
    },
    [setShipment]
  )

  const handleRemoveStop = useCallback(
    (removedIndex: number) => {
      setShipment((previousShipment) => {
        const newItems = previousShipment.items.filter(
          (item) => item.pickup_stop_index !== removedIndex
        )
        const parsedItems = updateItemsStopIndexesOnStopRemoval(
          newItems,
          removedIndex
        )
        return { ...previousShipment, items: parsedItems }
      })
    },
    [setShipment]
  )
  return {
    handleStopsChange,
    handleStopAdded,
    handleRemoveStop,
  }
}

export function usePickupStopSelectOptions(stops: TransientStop[]) {
  const deliveryStopsSelectOptions = useMemo(
    () => mapStopsToOptionsByStopType(stops, 'pickup'),
    [stops]
  )

  return deliveryStopsSelectOptions
}

export function useDeliveryStopSelectOptions(
  stops: TransientStop[],
  stopIndex: number
) {
  const deliveryStopsSelectOptions = useMemo(() => {
    return stops
      .map((stop, index) => ({
        index,
        type: stop.stop_type,
        value: String(index),
        label: getStopFacilityLabel(index, stops.length),
      }))
      .filter(({ type, index }) => type === 'delivery' && index > stopIndex)
  }, [stopIndex, stops])

  return deliveryStopsSelectOptions
}

export function useDeliveryStopSelectOptionsV2(stops: TransientStop[]) {
  const deliveryStopsSelectOptions = useMemo(
    () => mapStopsToOptionsByStopType(stops, 'delivery'),
    [stops]
  )

  return deliveryStopsSelectOptions
}

function useCustomFields({
  setShipment,
}: {
  setShipment: Dispatch<SetStateAction<TransientShipment>>
}) {
  const { data: settings } = useShipmentsShipperSettings({
    refetchOnWindowFocus: false,
  })

  const defaultCustomFields = useMemo(() => {
    const customFieldsList = isEmpty(settings?.shipment_extra_fields)
      ? []
      : settings?.shipment_extra_fields
    return customFieldsList?.reduce((o, key) => ({ ...o, [key]: null }), {})
  }, [settings])

  useEffect(() => {
    if (!isEmpty(defaultCustomFields)) {
      setShipment((previous) => ({
        ...previous,
        shipper_custom_fields: defaultCustomFields,
      }))
    }
  }, [defaultCustomFields, setShipment])

  return { defaultCustomFields }
}
