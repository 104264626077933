import type { TransientFulfillment } from 'fulfillments/domain/Fulfillment'
import { useIsCurrentShipperASupplier } from 'suppliers/common/useIsCurrentShipperASupplier'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { scrollToTop } from 'utils/scroll'
import { hasTransientError } from 'utils/transient'

import { useFulfillmentFormContext } from '../../components/FulfillmentFormContext'
import { validate } from '../../domain/Fulfillment.validation'
import {
  createFulfillmentAdapter,
  createFulfillmentWithoutOrdersAdapter,
} from '../createFulfillment.adapter'
import { useCreateFulfillment } from './useCreateFulfillment'

function mapPayloadToAnalyticsMetadata(fulfillment: TransientFulfillment) {
  return {
    creator: fulfillment.owner === 'us' ? 'shipper' : 'supplier',
    customer: fulfillment.customer_name,
    supplier: fulfillment.supplier_name,
    order_input: fulfillment.order_identifier_type,
    notes: fulfillment.notes,
  }
}

export function useCreateFulfillmentForm() {
  const isSupplier = useIsCurrentShipperASupplier()
  const { fulfillment, selectedOrders, setFulfillment, setPartialFulfillment } =
    useFulfillmentFormContext()

  const onCreationSuccess = () => {
    analytics.track(
      'Created: Fulfillment',
      AnalyticsEventTrigger.success,
      mapPayloadToAnalyticsMetadata(fulfillment)
    )
  }
  const {
    mutate: createFulfillment,
    data,
    status,
  } = useCreateFulfillment({ onSuccess: onCreationSuccess })
  const hasErrors = hasTransientError(fulfillment)

  const submitForm = () => {
    const [validatedFulfillment] = validate(fulfillment, isSupplier)

    if (hasTransientError(validatedFulfillment)) {
      setFulfillment(validatedFulfillment)
      scrollToTop()
      return
    }

    const withOrders = fulfillment.order_identifier_type === 'orders'

    if (withOrders) {
      createFulfillment({
        withOrders: true,
        payload: createFulfillmentAdapter(fulfillment),
      })
    } else {
      createFulfillment({
        withOrders: false,
        payload: createFulfillmentWithoutOrdersAdapter(fulfillment),
      })
    }
  }

  return {
    uuid: data?.data.uuid,
    fulfillment,
    selectedOrders,
    hasErrors,
    status,
    submitForm,
    setFulfillment,
    setPartialFulfillment,
  }
}
