import ReactLocationSelect from '@loadsmart/react-location-select'
import type { LocationSelectProps as ReactLocationSelectProps } from '@loadsmart/react-location-select'

import { useSettings } from '_shared_/settings/useSettings'
import { COMPASS_API_KEY, LOADSMART_API_URL } from 'utils/constants'
import logger from 'utils/logger'

export type LocationSelectProps = {
  readonly googleMapsAPIKey?: ReactLocationSelectProps['googleMapsAPIKey']
} & Omit<ReactLocationSelectProps, 'googleMapsAPIKey'>

export const LocationSelect = (props: LocationSelectProps) => {
  const {
    values: [
      GOOGLE_PLACES_API_KEY,
      LOCATION_SELECT_SHOULD_INFER_AMBIGUOUS_LOCALITY,
    ],
  } = useSettings([
    'settings.GOOGLE_PLACES_API_KEY',
    'flags.LOCATION_SELECT_SHOULD_INFER_AMBIGUOUS_LOCALITY',
  ])

  return (
    <ReactLocationSelect
      googleMapsAPIKey={GOOGLE_PLACES_API_KEY}
      compassConfig={{
        APIKey: COMPASS_API_KEY,
        baseURL: LOADSMART_API_URL,
      }}
      shouldInferAmbiguousLocality={
        LOCATION_SELECT_SHOULD_INFER_AMBIGUOUS_LOCALITY
      }
      onInferLocality={(inferredLocality) => {
        logger.info(
          'Locality inferred by react-location-select',
          inferredLocality
        )
      }}
      {...props}
    />
  )
}
