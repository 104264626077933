import { defaultTo } from 'lodash-es'
import { useCallback, useMemo } from 'react'
import type { Column } from 'react-table'

import { Table } from 'components/TableV4'
import type { TableSelectedRowsIDs } from 'components/TableV4/Table.utils'
import { NewFulfillmentFromOrdersButton } from 'fulfillments/hooks/navigation'
import { useIsFulfillmentsFlowEnabled } from 'fulfillments/hooks/useIsFulfillmentsFlowEnabled'
import { OrderHistoryProvider } from 'orders/components/OrderHistory'
import { useGoToOrderDetails } from 'orders/hooks/navigation'
import type { ListOrder, Order } from 'orders/types'
import { useSuppliersSettings } from 'suppliers/common/useSuppliersSettings'

import { DeleteOrderProvider } from '../../screens/Orders/common/DeleteOrder'
import {
  ManualOrderConsolidationProvider,
  NewShipmentFromOrderSelectionButton,
} from '../../screens/Orders/ManualConsolidation'
import {
  BulkAutoPlanButton,
  OneClickConsolidationProvider,
} from '../../screens/Orders/OneClickConsolidation'
import { StartSimpleConsolidation } from '../../screens/Orders/SimpleConsolidation/SimpleConsolidation.components'
import { SimpleConsolidationProvider } from '../../screens/Orders/SimpleConsolidation/SimpleConsolidation.context'
import {
  OrdersFiltersProvider,
  useOrdersFiltersContext,
} from '../components/Filters'
import { AppliedFiltersBar } from '../components/Filters.components'
import { useOrderSelection } from '../hooks/useOrderSelection'
import { usePaginatedOrders } from '../hooks/usePaginatedOrders'
import {
  ActionsCell,
  CommodityCell,
  DeliveryLocationCell,
  FulfillmentsCell,
  IdentifierCell,
  IdentifierHeaderCell,
  OrdersTableEmptyState,
  PickupLocationCell,
  StatusCell,
  SupplierCell,
} from './ListOrders.components'
import { OrdersListProvider } from './ListOrders.context'

export function ListOrdersAsCustomerFulfillmentFlowBulkActions({
  isLoading,
  selectedOrders,
}: Readonly<{
  isLoading?: boolean
  selectedOrders: (ListOrder | Order | undefined)[]
}>) {
  return (
    <NewFulfillmentFromOrdersButton
      isLoading={isLoading}
      orders={selectedOrders}
    />
  )
}

export function ListOrdersAsCustomerBulkActions({
  selectedOrders,
}: Readonly<{ selectedOrders: ListOrder[] }>) {
  return (
    <>
      <StartSimpleConsolidation selectedOrders={selectedOrders} />
      <NewShipmentFromOrderSelectionButton />
      <BulkAutoPlanButton selectedOrders={selectedOrders} />
    </>
  )
}

const COLUMNS_WITH_SUPPLIER: ReadonlyArray<Column<ListOrder>> = [
  {
    id: 'ref_number',
    Header: IdentifierHeaderCell,
    Cell: IdentifierCell,
    width: '200px',
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Fulfillments',
    id: 'fulfillments',
    Cell: FulfillmentsCell,
  },
  {
    Header: 'Origin',
    id: 'pickup_address',
    Cell: PickupLocationCell,
  },
  {
    Header: 'Destination',
    id: 'delivery_address',
    Cell: DeliveryLocationCell,
  },
  {
    Header: 'Commodity',
    id: 'commodity',
    Cell: CommodityCell,
  },
  {
    Header: 'Supplier',
    id: 'supplier_name',
    Cell: SupplierCell,
  },
  {
    Header: '',
    id: 'row_actions',
    Cell: ActionsCell,
    width: '35px',
  },
]

const COLUMNS_WITHOUT_SUPPLIER: ReadonlyArray<Column<ListOrder>> = [
  {
    id: 'ref_number',
    Header: IdentifierHeaderCell,
    Cell: IdentifierCell,
    width: '200px',
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Origin',
    id: 'pickup_address',
    Cell: PickupLocationCell,
  },
  {
    Header: 'Destination',
    id: 'delivery_address',
    Cell: DeliveryLocationCell,
  },
  {
    Header: 'Commodity',
    id: 'commodity',
    Cell: CommodityCell,
  },
  {
    Header: '',
    id: 'row_actions',
    Cell: ActionsCell,
    width: '35px',
  },
]

type OrdersListTableProps = ReturnType<typeof usePaginatedOrders> &
  Readonly<{
    isLoadingSelectedOrdersData?: boolean
    selectedOrderUUIDs: string[]
    selectedOrders: (Order | undefined)[]
    setSelectedOrders: (newSelected: string[]) => void
  }>

function OrdersListTable({
  clearFilters,
  currentPage,
  data,
  filters,
  isLoading,
  isLoadingSelectedOrdersData,
  onPageChange,
  onSearchChange,
  pageSize,
  resultsCount,
  selectedOrders,
  selectedOrderUUIDs,
  setFilters,
  setSelectedOrders,
}: OrdersListTableProps) {
  const isFulfillmentsFlowEnabled = useIsFulfillmentsFlowEnabled()
  const { openFilters } = useOrdersFiltersContext()
  const goToDetails = useGoToOrderDetails()
  const { isSuppliersEnabled } = useSuppliersSettings()
  const columns = isSuppliersEnabled
    ? COLUMNS_WITH_SUPPLIER
    : COLUMNS_WITHOUT_SUPPLIER

  const onRowClick = useCallback(
    (order: ListOrder) => {
      goToDetails(order.uuid)
    },
    [goToDetails]
  )

  const onSelectionChange = useCallback(
    (selection: TableSelectedRowsIDs) => {
      if (selection !== 'all') {
        setSelectedOrders(selection)
      }
    },
    [setSelectedOrders]
  )

  const selectedListOrders = useMemo(() => {
    if (data?.results && selectedOrderUUIDs?.length) {
      return data?.results?.filter((order) =>
        selectedOrderUUIDs.includes(order.uuid)
      )
    }

    return []
  }, [data, selectedOrderUUIDs])

  return (
    <OrdersListProvider>
      <Table<ListOrder>
        tableStyle={{ height: '100%' }}
        appliedFiltersBar={
          <AppliedFiltersBar
            clearFilters={clearFilters}
            filters={filters}
            setFilters={setFilters}
          />
        }
        bulkActions={
          isFulfillmentsFlowEnabled ? (
            <ListOrdersAsCustomerFulfillmentFlowBulkActions
              isLoading={isLoadingSelectedOrdersData}
              selectedOrders={selectedOrders}
            />
          ) : (
            <ListOrdersAsCustomerBulkActions
              selectedOrders={selectedListOrders}
            />
          )
        }
        columns={columns}
        count={data?.results?.length}
        data={defaultTo(data?.results, [])}
        emptyState={<OrdersTableEmptyState />}
        isLoading={isLoading}
        onOpenFilters={openFilters}
        onPageChange={onPageChange}
        onRowClick={onRowClick}
        onSearchInputChange={onSearchChange}
        onSelectionChange={onSelectionChange}
        page={currentPage}
        pageSize={pageSize}
        searchFilter={defaultTo(filters.search, '')}
        selectable
        selectedRows={selectedOrderUUIDs}
        showResultsCount={false}
        totalCount={resultsCount}
        uniqueIDfield="uuid"
      />
    </OrdersListProvider>
  )
}

export function ListOrdersAsCustomer() {
  const paginatedOrdersProps = usePaginatedOrders()
  const { selectedOrderUUIDs, setSelectedOrders, selectedOrdersData } =
    useOrderSelection({
      loadDetails: true,
    })

  const { clearFilters, filters, refetch, setFilters } = paginatedOrdersProps

  return (
    <ManualOrderConsolidationProvider selectedOrderUUIDs={selectedOrderUUIDs}>
      <OneClickConsolidationProvider onSuccess={refetch}>
        <SimpleConsolidationProvider>
          <DeleteOrderProvider callback={refetch}>
            <OrderHistoryProvider>
              <OrdersFiltersProvider
                clearFilters={clearFilters}
                filters={filters}
                setFilters={setFilters}
              >
                <OrdersListTable
                  {...paginatedOrdersProps}
                  isLoadingSelectedOrdersData={selectedOrdersData.isLoading}
                  selectedOrders={selectedOrdersData.orders}
                  selectedOrderUUIDs={selectedOrderUUIDs}
                  setSelectedOrders={setSelectedOrders}
                />
              </OrdersFiltersProvider>
            </OrderHistoryProvider>
          </DeleteOrderProvider>
        </SimpleConsolidationProvider>
      </OneClickConsolidationProvider>
    </ManualOrderConsolidationProvider>
  )
}
