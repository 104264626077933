import {
  Banner,
  Card,
  Drawer,
  Layout,
  LogoLoader,
  Text,
} from '@loadsmart/miranda-react'
import { get, set } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { createTransientFacilityContact } from 'components/FacilityContactsForm/FacilityContactsForm.helpers'
import type { TransientFacilityContact } from 'components/FacilityContactsForm/FacilityContactsForm.types'
import { FacilityContactsFormCard } from 'components/FacilityContactsForm/FacilityContactsFormCard'
import { FacilitySummaryCard } from 'components/FacilityDetails/FacilitySummaryCard'
import { useFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import type { TransientFacilityV2 } from 'components/FacilityDrawer'
import type { FacilityContactV2 } from 'services/facilities'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { scrollToTop } from 'utils/scroll'
import { createTransient, hasTransientError } from 'utils/transient'

import {
  DEFAULT_ERROR_BANNER_MESSAGE,
  UNKNOWN_SERVER_ERROR_BANNER_MESSAGE,
  validate,
} from './ContactsEditForm.utils'
import { useSaveFacilityContacts } from './useSaveFacilityContacts'

type ErrorState = {
  readonly errorType: 'validation' | 'server'
  readonly errorMessage: string
  readonly serverErrors?: string[]
}

function ContactsEditErrorBanner({
  errorType,
  errorMessage,
  serverErrors,
  hasTransientErrors,
}: ErrorState & {
  readonly hasTransientErrors: boolean
}) {
  if (errorType === 'server') {
    return (
      <Banner variant="danger">
        <Banner.Title>{errorMessage}</Banner.Title>
        {serverErrors && serverErrors.length > 0 && (
          <Banner.Description>
            <Layout.Stack gap="none">
              {serverErrors.map((error) => (
                <Text key={error} color="color-text-secondary">
                  {error}
                </Text>
              ))}
            </Layout.Stack>
          </Banner.Description>
        )}
      </Banner>
    )
  }

  if (hasTransientErrors) {
    return (
      <Banner variant="danger">
        <Banner.Title>{errorMessage}</Banner.Title>
      </Banner>
    )
  }

  return null
}

type ContactsEditFormBodyProps = {
  readonly facilityUUID: string
  readonly contacts: TransientFacilityContact[]
  readonly onContactsChange: (
    changedContacts: TransientFacilityContact[]
  ) => void
}

function ContactsEditFormBody({
  facilityUUID,
  contacts,
  onContactsChange,
}: ContactsEditFormBodyProps) {
  const {
    data: facility,
    isLoading,
    isError,
    refetch,
  } = useFacilityDetailsV2(facilityUUID)

  useEffect(() => {
    if (facility?.contacts) {
      onContactsChange(facility.contacts.map(createTransientFacilityContact))
    }
    // We just need to run this when the facility.contacts are retrieved
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility?.contacts])

  if (isLoading) {
    return (
      <>
        <Card>
          <Card.Body>
            <Layout.Group justify="center">
              <LogoLoader size="32px" />
            </Layout.Group>
          </Card.Body>
        </Card>

        <Card>
          <Card.Title>Contacts</Card.Title>
          <Card.Divider />
          <Card.Body>
            <Layout.Group justify="center">
              <LogoLoader aria-label="Loading facility contacts" size="32px" />
            </Layout.Group>
          </Card.Body>
        </Card>
      </>
    )
  }

  if (isError || !facility) {
    return (
      <Banner variant="danger">
        <Banner.Title>Failed to load the facility contacts</Banner.Title>
        <Banner.Actions>
          <Banner.ActionPrimary onClick={() => refetch()}>
            Try again
          </Banner.ActionPrimary>
        </Banner.Actions>
      </Banner>
    )
  }

  return (
    <>
      <FacilitySummaryCard facility={facility} />

      <FacilityContactsFormCard
        contacts={contacts}
        onChange={onContactsChange}
      />
    </>
  )
}

type ContactsEditFormProps = {
  readonly facilityUUID: string
  readonly onClose: () => void
  readonly onContactsSaved: (contacts: FacilityContactV2[]) => void
}

export function ContactsEditForm({
  facilityUUID,
  onClose,
  onContactsSaved,
}: ContactsEditFormProps) {
  const [facility, setFacility] = useState<
    Pick<TransientFacilityV2, 'contacts'>
  >(createTransient({ contacts: [createTransientFacilityContact()] }))
  const [errorState, setErrorState] = useState<ErrorState>()

  useEffect(() => {
    analytics.track('Edit contacts drawer', AnalyticsEventTrigger.view)
  }, [facilityUUID])

  const { saveContacts, isSaving } = useSaveFacilityContacts({
    facilityUUID,
    onSuccess: (updatedContacts) => {
      onContactsSaved(updatedContacts)
      onClose()
      scrollToTop()
      toast.success('Contacts updated')
    },
    onError: (errors) => {
      setErrorState({
        errorType: 'server',
        errorMessage: errors.length
          ? DEFAULT_ERROR_BANNER_MESSAGE
          : UNKNOWN_SERVER_ERROR_BANNER_MESSAGE,
        serverErrors: errors,
      })
      scrollToTop('.edit-contacts-drawer-body')
    },
  })

  const onSetFacility = useCallback(
    (changes: Partial<TransientFacilityV2>) => {
      setFacility((currentFacility) => {
        const updatedFacility = Object.keys(changes).reduce(
          (newFacility, path) => {
            return set(newFacility, path, get(changes, path))
          },
          structuredClone(currentFacility)
        )

        // If there were validation errors previously found, run validations when values change
        if (errorState?.errorType === 'validation') {
          const [validatedFacility] = validate(updatedFacility)

          return validatedFacility
        }

        return updatedFacility
      })
    },
    [errorState?.errorType]
  )

  const onSaveClick = useCallback(() => {
    const [validatedFacility, isValid] = validate(facility)

    setFacility(validatedFacility)

    if (isValid) {
      setErrorState(undefined)
      saveContacts(validatedFacility.contacts)
    } else {
      setErrorState({
        errorType: 'validation',
        errorMessage: DEFAULT_ERROR_BANNER_MESSAGE,
      })
      scrollToTop('.edit-contacts-drawer-body')
    }
  }, [facility, saveContacts])

  return (
    <>
      <Drawer.Header role="heading" data-testid="contacts-form-header">
        Contacts
        <Drawer.Close aria-label="Close contacts drawer" disabled={isSaving} />
      </Drawer.Header>

      <Drawer.Body className="edit-contacts-drawer-body">
        <Layout.Stack gap="spacing-6">
          {errorState && (
            <ContactsEditErrorBanner
              {...errorState}
              hasTransientErrors={hasTransientError(facility)}
            />
          )}

          <ContactsEditFormBody
            facilityUUID={facilityUUID}
            contacts={facility.contacts}
            onContactsChange={(newContacts) => {
              onSetFacility({ contacts: newContacts })
            }}
          />
        </Layout.Stack>
      </Drawer.Body>

      <Drawer.Actions>
        <Drawer.ActionTertiary onClick={onClose} disabled={isSaving}>
          Cancel
        </Drawer.ActionTertiary>
        <Drawer.ActionPrimary
          onClick={onSaveClick}
          disabled={isSaving}
          loading={isSaving}
        >
          Save contacts
        </Drawer.ActionPrimary>
      </Drawer.Actions>
    </>
  )
}
