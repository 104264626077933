import { Layout, Table as MTable } from '@loadsmart/miranda-react'
import { isEqual } from 'lodash-es'
import type { CSSProperties, ReactElement, ReactNode } from 'react'
import { useCallback } from 'react'
import type { Row, Column } from 'react-table'
import { useMountedLayoutEffect, useRowSelect, useTable } from 'react-table'

import type {
  TableDefaultBulkActionsProps,
  TableDefaultPaginationProps,
  TableDefaultToolbarProps,
} from './Table.components'
import {
  SelectionCell,
  SelectionHeader,
  TableDefaultBulkActions,
  TableDefaultLoading,
  TableDefaultPagination,
  TableDefaultToolbar,
  TableRow,
} from './Table.components'
import type { CustomTableState } from './Table.types'
import { ACTION_TYPES } from './Table.types'
import type { TableSelectedRowsIDs } from './Table.utils'
import {
  convertToSelectedRowIds,
  getCellProps,
  stateReducer,
} from './Table.utils'

export interface TableProps<RowObject extends object>
  extends TableDefaultToolbarProps,
    Omit<
      TableDefaultBulkActionsProps,
      'handleClearSelection' | 'handleSelectAll' | 'isAllRowsSelected'
    >,
    TableDefaultPaginationProps {
  readonly appliedFiltersBar?: ReactNode
  readonly columns: ReadonlyArray<Column<RowObject>>
  readonly count?: number
  readonly data: RowObject[]
  readonly emptyState?: ReactElement
  readonly hideToolbar?: boolean
  readonly isLoading: boolean
  readonly onRowClick?: (rowObejct: RowObject) => void
  readonly onSelectionChange?: (value: TableSelectedRowsIDs) => void
  readonly selectable?: boolean
  readonly selectedRows?: TableSelectedRowsIDs
  readonly totalCount?: number
  readonly uniqueIDfield: keyof RowObject
  readonly tableStyle?: CSSProperties
}

export function Table<RowObject extends object>({
  appliedFiltersBar,
  bulkActions,
  columns,
  count = 0,
  data,
  emptyState,
  hideToolbar,
  isLoading,
  onOpenFilters,
  onPageChange,
  onRowClick,
  onSearchInputChange,
  onSelectionChange,
  page,
  pageSize,
  searchFilter,
  selectable,
  selectAllEnabled,
  selectedRows,
  showResultsCount,
  totalCount,
  uniqueIDfield,
  tableStyle,
}: TableProps<RowObject>) {
  const {
    visibleColumns,
    rows,
    prepareRow,
    state,
    isAllRowsSelected,
    dispatch,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        selectedRowIds: convertToSelectedRowIds(selectedRows),
      },
      getRowId: (row: RowObject) => String(row[uniqueIDfield]),
      stateReducer,
    },
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((_columns) => [
          {
            id: 'selection',
            Header: SelectionHeader,
            Cell: SelectionCell,
            width: '58px',
          },
          ..._columns,
        ])
      }
    }
  )

  const { selectedRowIds, areAllItemsSelected } =
    state as unknown as CustomTableState

  useMountedLayoutEffect(() => {
    if (!onSelectionChange) {
      return
    }

    if (areAllItemsSelected) {
      onSelectionChange('all')
      return
    }

    if (!isEqual(selectedRows, Object.keys(selectedRowIds))) {
      onSelectionChange(Object.keys(selectedRowIds))
    }
  }, [selectedRowIds, areAllItemsSelected])

  const handleClearSelection = useCallback(() => {
    dispatch({ type: ACTION_TYPES.clearSelection })
  }, [dispatch])

  const handleSelectAll = useCallback(() => {
    dispatch({ type: ACTION_TYPES.selectAllItems })
  }, [dispatch])

  return (
    <MTable size="large" aria-label="orders table" style={tableStyle}>
      {!hideToolbar && (
        <MTable.Toolbar>
          <Layout.Stack>
            <TableDefaultToolbar
              onOpenFilters={onOpenFilters}
              onSearchInputChange={onSearchInputChange}
              searchFilter={searchFilter}
              totalCount={totalCount}
              showResultsCount={showResultsCount}
            />
            {appliedFiltersBar}
          </Layout.Stack>
        </MTable.Toolbar>
      )}
      <MTable.Head>
        <MTable.Row>
          {count > 0 &&
            visibleColumns.map((header) => {
              return (
                <MTable.Cell
                  key={header.id}
                  style={{
                    width: header.width,
                  }}
                >
                  {header.render('Header')}
                </MTable.Cell>
              )
            })}
        </MTable.Row>
      </MTable.Head>

      <MTable.Body>
        {!isLoading &&
          rows.map((row: Row<RowObject>) => {
            prepareRow(row)
            return (
              <TableRow
                key={row.id}
                $selected={selectedRowIds[row.id]}
                onClick={() => onRowClick?.(row.original)}
              >
                {row.cells.map((cell) => {
                  return (
                    <MTable.Cell key={cell.column.id} {...getCellProps(cell)}>
                      {cell.render('Cell')}
                    </MTable.Cell>
                  )
                })}
              </TableRow>
            )
          })}
      </MTable.Body>

      <MTable.Actions sticky>
        <TableDefaultBulkActions
          bulkActions={bulkActions}
          count={count}
          handleClearSelection={handleClearSelection}
          handleSelectAll={handleSelectAll}
          isAllRowsSelected={isAllRowsSelected}
          pageSize={pageSize}
          selectAllEnabled={selectAllEnabled}
          selectedRows={selectedRows}
        />
        <TableDefaultPagination
          isLoading={isLoading}
          onPageChange={onPageChange}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
        />
      </MTable.Actions>

      {(isLoading || !count) && (
        <MTable.Feedback>
          <TableDefaultLoading isLoading={isLoading} />

          {!isLoading && emptyState}
        </MTable.Feedback>
      )}
    </MTable>
  )
}
