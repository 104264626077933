import * as Yup from 'yup'

import {
  INVALID_NUMBER_MESSAGE,
  MAX_LENGTH_MESSAGE,
  POSITIVE_NUMBER_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from 'constants/errors'
import { validateTransientWithSchema } from 'utils/yup'

import type { TransientFulfillment } from './Fulfillment'

type TestContextExtended = {
  from: {
    value: any
  }[]
}

const stringRequired = Yup.string().nullable().required(REQUIRED_FIELD_MESSAGE)
const numberAsString = Yup.string()
  .nullable()
  .required(REQUIRED_FIELD_MESSAGE)
  .matches(/^[0-9.]+$/, INVALID_NUMBER_MESSAGE)
const numberRequired = Yup.number()
  .nullable()
  .required(REQUIRED_FIELD_MESSAGE)
  .min(1, POSITIVE_NUMBER_MESSAGE)

const FulfillmentCommodity = {
  order_item_uuid: Yup.string().nullable().concat(stringRequired),
  package_count: Yup.number().nullable().concat(numberRequired),
  weight: Yup.string().nullable().concat(numberAsString),
}

const FulfillmentWithoutOrdersCommodity = {
  order_identifier: Yup.string().nullable().concat(stringRequired),
  description: Yup.string()
    .nullable()
    .max(255, MAX_LENGTH_MESSAGE`255`),
  package_type: Yup.string().nullable(),
  package_count: Yup.number().nullable(),
  weight: Yup.string().nullable().concat(numberAsString),
  hazmat: Yup.boolean().nullable(),
  nmfc_code: Yup.string()
    .nullable()
    .max(255, MAX_LENGTH_MESSAGE`255`),
  freight_class: Yup.string().nullable(),
}

const FulfillmentItem = {
  package_type: Yup.string().nullable().concat(stringRequired),
  package_count: Yup.number().nullable().concat(numberRequired),
  length: Yup.string().nullable().concat(numberAsString),
  width: Yup.string().nullable().concat(numberAsString),
  height: Yup.string().nullable().concat(numberAsString),
  weight_type: Yup.string().nullable().concat(stringRequired),
  delivery_stop_index: Yup.number().when(['uuid'], {
    is: Boolean,
    then: Yup.number().nullable(),
    otherwise: Yup.number()
      .nullable()
      .required(REQUIRED_FIELD_MESSAGE)
      .test(
        'deliveryStopIndex',
        'A delivery address is required',
        function test(value, context) {
          if (value == null) {
            return false
          }

          const { from } = context as Yup.TestContext & TestContextExtended
          const stop = from.at(-1)?.value.stops[value]
          return stop.stop_type === 'delivery'
        }
      ),
  }),
}

export const AT_LEAST_ONE_HU_REQUIRED_MESSAGE =
  'At least one handling unit is required'
export const HandlingUnitsSchema = Yup.array()
  .of(
    Yup.object().shape({
      ...FulfillmentItem,
      commodities: Yup.array().of(
        Yup.object().when('$withOrders', {
          is: (val: any) => {
            return val
          },
          then: (schema) => schema.shape(FulfillmentCommodity),
          otherwise: (schema) =>
            schema.shape(FulfillmentWithoutOrdersCommodity),
        })
      ),
    })
  )
  .min(1, AT_LEAST_ONE_HU_REQUIRED_MESSAGE)

const FulfillmentFormSchema = Yup.object().shape({
  order_identifier_type: Yup.string().required('Input method is required'),
  order_uuids: Yup.array()
    .of(Yup.string())
    .nullable()
    .when('order_identifier_type', {
      is: 'orders',
      then: Yup.array().of(Yup.string()).min(1).required('Order is required'),
    }),
  po_numbers: Yup.string().when('order_identifier_type', {
    is: 'po_number',
    then: Yup.string().trim().required('PO number(s) is required'),
  }),
  so_numbers: Yup.string().when('order_identifier_type', {
    is: 'so_number',
    then: Yup.string().trim().required('SO number(s) is required'),
  }),
  pickup_number: Yup.string(),
  customer_uuid: Yup.string().when('owner', {
    is: 'customer',
    then: Yup.string().trim().required('Customer is required'),
  }),
  supplier_uuid: Yup.string(),
  stops: Yup.array().of(
    Yup.object().shape({
      date: Yup.date().required('Date is required'),
      facility: Yup.object()
        .shape({
          uuid: Yup.string().required('Facility is required'),
        })
        .nullable()
        .required('Facility is required')
        .when(
          ['stop_type', '$fulfillment', '$isSupplier'],
          (
            stop_type: string,
            fulfillment: TransientFulfillment,
            isSupplier: boolean,
            schema: Yup.ObjectSchema<any>
          ) => {
            if (stop_type === 'pickup') {
              return schema
            }

            const hasDeliveryUUIDFromOrder =
              fulfillment.delivery_facility_uuid != null

            if (isSupplier && hasDeliveryUUIDFromOrder) {
              return schema.shape({
                uuid: Yup.string().nullable(),
              })
            }

            return schema
          }
        ),
      contact: Yup.object().nullable().shape({
        uuid: Yup.string().nullable(),
      }),
    })
  ),
  handling_units: HandlingUnitsSchema,
})

export function validate(
  fulfillment: TransientFulfillment,
  isSupplier = false
): [TransientFulfillment, boolean] {
  const withOrders = fulfillment.order_identifier_type === 'orders'

  return validateTransientWithSchema<TransientFulfillment>(
    FulfillmentFormSchema,
    fulfillment,
    { withOrders, fulfillment, isSupplier }
  )
}
