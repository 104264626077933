import {
  Button,
  Divider,
  Icon,
  LabeledValue,
  Layout,
  Text,
} from '@loadsmart/miranda-react'
import { Fragment } from 'react/jsx-runtime'

import { FulfillmentFacilityAddress } from 'fulfillments/components/FulfillmentFacilityAddress'
import type { FulfillmentDetails } from 'fulfillments/details/ViewFulfillmentPage.data'
import {
  formatFulfillmentDeliveryDate,
  formatFulfillmentPickupReadyDate,
} from 'fulfillments/fulfillment-utils'
import { plural } from 'utils/strings'

import { usePlanFulfillmentsContext } from '../PlanFulfillmentsContext'
import { PlanFulfillmentsPlanPreviewStopCardHUs } from './PlanFulfillmentsPlanPreviewStopCardHUs'

function FulfillmentSummary({
  fulfillment,
  removeFulfillment,
}: Readonly<{
  fulfillment: FulfillmentDetails
  removeFulfillment: (UUID: string) => void
}>) {
  return (
    <Layout.Stack
      gap="spacing-8"
      padding="none"
      data-testid={`stop-card-pickup-item-fulfillment-${fulfillment.ref_number}`}
    >
      <Layout.Stack padding="none">
        <Layout.Group justify="space-between" padding="none">
          <Text variant="body-md-bold">
            Fulfillment {fulfillment.ref_number}
          </Text>

          <Button
            onClick={() => {
              removeFulfillment(fulfillment.uuid)
            }}
            size="small"
            variant="icon"
            data-testid="button-remove-fulfillment"
          >
            <Icon name="close" size="10px" />
          </Button>
        </Layout.Group>

        <Layout.Grid>
          <Layout.Stack padding="none">
            <LabeledValue
              label="Pickup-ready date"
              value={formatFulfillmentPickupReadyDate(fulfillment)}
            />

            <LabeledValue
              label="Delivery date"
              value={formatFulfillmentDeliveryDate(fulfillment)}
            />
          </Layout.Stack>

          <Layout.Stack padding="none">
            {fulfillment.supplier?.name && (
              <LabeledValue
                label="Supplier"
                value={fulfillment.supplier?.name}
              />
            )}

            <LabeledValue
              label="Delivery facility"
              value={
                <FulfillmentFacilityAddress
                  fulfillment={fulfillment}
                  stop="delivery"
                />
              }
            />
          </Layout.Stack>
        </Layout.Grid>
      </Layout.Stack>

      <Layout.Stack gap="spacing-4" padding="none">
        <Text color="color-text-tertiary" variant="body-sm">
          {`${fulfillment.handling_units.length} ${plural('item', fulfillment.handling_units.length)} being picked up`}
        </Text>

        <Layout.Grid>
          <PlanFulfillmentsPlanPreviewStopCardHUs fulfillment={fulfillment} />
        </Layout.Grid>
      </Layout.Stack>
    </Layout.Stack>
  )
}

export function PlanFulfillmentsPlanPreviewStopCardPickup({
  stopFulfillments,
}: Readonly<{
  stopFulfillments: FulfillmentDetails[]
}>) {
  const { removeSelectedFulfillments } = usePlanFulfillmentsContext()

  return (
    <Layout.Stack gap="spacing-8" padding="none">
      <FulfillmentFacilityAddress
        addressLineProps={{ variant: 'body-md' }}
        fulfillment={stopFulfillments[0]}
        nameLineProps={{ variant: 'heading-sm-bold' }}
        stop="pickup"
      />

      {stopFulfillments.map((order) => (
        <Fragment key={order.uuid}>
          <Divider />
          <FulfillmentSummary
            key={order.uuid}
            fulfillment={order}
            removeFulfillment={removeSelectedFulfillments}
          />
        </Fragment>
      ))}
    </Layout.Stack>
  )
}
