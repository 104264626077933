import mixpanel from 'mixpanel-browser'

import { formatUserForAnalytics } from '_shared_/user'
import type { UserData } from '_shared_/user'
import { MIXPANEL_TOKEN } from 'utils/constants'

export const setupMixpanel = () => {
  mixpanel.init(MIXPANEL_TOKEN)
}

export const setMixPanelUser = (userData: UserData): void => {
  const formattedUserData = formatUserForAnalytics(userData)
  const {
    email,
    name,
    company,
    type,
    shipper_test,
    user_category,
    business_type,
  } = formattedUserData

  mixpanel.identify(email)
  mixpanel.register(formattedUserData)
  mixpanel.people.set({
    id: email,
    $email: email,
    $name: name,
    company,
    roles: userData.user_role,
    type,
    shipper_test,
    user_category,
    business_type,
  })
}

export const mixpanelReset = () => {
  mixpanel.reset()
}
