import { PageContent } from '@loadsmart/miranda-react'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { WindowTitle } from '_shared_/components/WindowTitle'
import { OrderHistoryProvider } from 'orders/components/OrderHistory'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

import { ViewOrderPageHeader } from './components/ViewOrderPageHeader'
import {
  useViewOrderContextData,
  ViewOrderContextProvider,
} from './ViewOrderContext'
import { ViewOrderDetails } from './ViewOrderDetails'

export default function ViewOrderPage() {
  const { id } = useParams<{ id: string }>()
  const data = useViewOrderContextData(id)

  useEffect(() => {
    analytics.track('Viewed: Order', AnalyticsEventTrigger.view)
  }, [])

  return (
    <>
      <WindowTitle title="Order Details" />
      <ViewOrderContextProvider {...data}>
        <OrderHistoryProvider>
          <PageContent>
            <ViewOrderPageHeader />
            <PageContent.Body>
              <ViewOrderDetails />
            </PageContent.Body>
          </PageContent>
        </OrderHistoryProvider>
      </ViewOrderContextProvider>
    </>
  )
}
