import { createIndexProvider } from 'contexts/indexer'

import { HandlingUnitDeliverAt } from './HandlingUnitDeliverAt'
import { HandlingUnitDimension } from './HandlingUnitDimension'
import {
  HandlingUnitOrderItems,
  HandlingUnitOrders,
} from './HandlingUnitOrders'
import { HandlingUnitPackageCount } from './HandlingUnitPackageCount'
import { HandlingUnitPackageType } from './HandlingUnitPackageType'
import { HandlingUnitPickupAt } from './HandlingUnitPickupAt'
import { HandlingUnitsTransientCollectionForm } from './HandlingUnitsForm.hooks'
import { HandlingUnitsFormAddHandlingUnit } from './HandlingUnitsFormAddHandlingUnit'
import { HandlingUnitsFormRemoveHandlingUnit } from './HandlingUnitsFormRemoveHandlingUnit'
import { HandlingUnitStackable } from './HandlingUnitStackable'
import { HandlingUnitTurnable } from './HandlingUnitTurnable'
import { HandlingUnitWeightType } from './HandlingUnitWeightType'

export const HandlingUnitsForm = Object.assign(
  HandlingUnitsTransientCollectionForm,
  {
    AddHandlingUnit: HandlingUnitsFormAddHandlingUnit,
    RemoveHandlingUnit: HandlingUnitsFormRemoveHandlingUnit,
  }
)

export const HandlingUnitForm = Object.assign(createIndexProvider(), {
  Dimension: HandlingUnitDimension,
  WeightType: HandlingUnitWeightType,
  PackageCount: HandlingUnitPackageCount,
  PackageType: HandlingUnitPackageType,
  Stackable: HandlingUnitStackable,
  Turnable: HandlingUnitTurnable,
  DeliverAt: HandlingUnitDeliverAt,
  PickupAt: HandlingUnitPickupAt,
  Orders: HandlingUnitOrders,
  OrderItems: HandlingUnitOrderItems,
})
