import { Button, Card, Icon, Layout, Tag } from '@loadsmart/miranda-react'

import { TextList } from '_shared_/components/TextList'
import { formatVolume, formatWeight } from 'components/ShippingItemsManager'
import {
  formatFulfillmentsDateWindows,
  formatFulfillmentsLinkedOrders,
  formatFulfillmentsLowestMaxTemperature,
  formatFulfillmentsTotalPieceCount,
  getFulfillmentsVolume,
  getFulfillmentsWeight,
} from 'fulfillments/fulfillment-utils'

import { usePlanFulfillmentsContext } from '../PlanFulfillmentsContext'
import { formatStop } from '../PlanFulfillmentsPage.utils'
import PlanFulfillmentsPlanPreviewStopCardDelivery from './PlanFulfillmentsPlanPreviewStopCardDelivery'
import { PlanFulfillmentsPlanPreviewStopCardPickup } from './PlanFulfillmentsPlanPreviewStopCardPickup'

export type PlanFulfillmentsPlanPreviewStopCardProps = Readonly<{
  collapsed?: boolean
  facilityUUID: string
  setCollapsed: (facilityUUID: string) => void
  stopIndex: number
}>

export default function PlanFulfillmentsPlanPreviewStopCard({
  collapsed,
  facilityUUID,
  setCollapsed,
  stopIndex,
}: PlanFulfillmentsPlanPreviewStopCardProps) {
  const {
    plan,
    reorderFacility: reorderStop,
    selectedFulfillments,
  } = usePlanFulfillmentsContext()

  if (!plan) {
    return null
  }

  const stop = plan.stops.find((item) => item.facilityUUID === facilityUUID)

  if (!stop) {
    return null
  }

  const onMoveUpClick = () => {
    reorderStop(stop.facilityUUID, 'up')
  }

  const onMoveDownClick = () => {
    reorderStop(stop.facilityUUID, 'down')
  }

  const stopFulfillments = selectedFulfillments.filter(
    (fulfillment) =>
      stop.pickupFulfillmentsUUIDs.includes(fulfillment.uuid) ||
      stop.deliveryFulfillmentsUUIDs.includes(fulfillment.uuid)
  )

  const [pickupWindow] = formatFulfillmentsDateWindows(stopFulfillments)
  const totalWeight = getFulfillmentsWeight(stopFulfillments)
  const totalVolume = getFulfillmentsVolume(stopFulfillments)
  const maxTemperature =
    formatFulfillmentsLowestMaxTemperature(stopFulfillments)

  return (
    <Card
      collapsed={collapsed}
      collapsible
      onToggle={() => {
        setCollapsed(facilityUUID)
      }}
      data-testid={`stop-card-${facilityUUID}`}
    >
      <Card.Title>
        <Layout.Group
          align="center"
          justify="space-between"
          style={{ width: '100%' }}
        >
          <Layout.Stack gap="spacing-2">
            <Layout.Group align="center" gap="spacing-2" padding="none">
              <Button size="small" variant="icon" data-testid="stop-card-index">
                {stopIndex}
              </Button>
              <span data-testid="stop-card-title">{formatStop(stop)}</span>
              <Tag size="small" data-testid="stop-card-title-tag">
                {stop.stopLabel}
              </Tag>
            </Layout.Group>

            <TextList
              separator={<>&bull;</>}
              separatorProps={{
                color: 'color-text-secondary-inverted',
                variant: 'body-md',
              }}
              values={[
                pickupWindow,
                `Order ${formatFulfillmentsLinkedOrders(stopFulfillments)}`,
              ]}
              valueProps={{ color: 'color-text-tertiary', variant: 'body-md' }}
            />

            <TextList
              separator={<>&bull;</>}
              separatorProps={{
                color: 'color-text-secondary-inverted',
                variant: 'body-md',
              }}
              values={[
                formatFulfillmentsTotalPieceCount(stopFulfillments),
                totalWeight ? formatWeight(totalWeight) : null,
                totalVolume ? formatVolume(totalVolume) : null,
                maxTemperature,
              ]}
              valueProps={{ color: 'color-text-tertiary', variant: 'body-md' }}
            />
          </Layout.Stack>

          <Layout.Group gap="spacing-2" padding="none">
            {stop.canMoveUp && (
              <Button
                aria-label="Move stop up"
                onClick={onMoveUpClick}
                size="small"
                variant="icon"
              >
                <Icon name="arrow-sort-up" size="12px" />
              </Button>
            )}
            {stop.canMoveDown && (
              <Button
                aria-label="Move stop down"
                onClick={onMoveDownClick}
                size="small"
                variant="icon"
              >
                <Icon name="arrow-sort-down" size="12px" />
              </Button>
            )}
          </Layout.Group>
        </Layout.Group>
      </Card.Title>

      <Card.Divider />

      <Card.Body>
        {stop.stopType === 'pickup' ? (
          <PlanFulfillmentsPlanPreviewStopCardPickup
            stopFulfillments={stopFulfillments}
          />
        ) : (
          <PlanFulfillmentsPlanPreviewStopCardDelivery
            stopFulfillments={stopFulfillments}
          />
        )}
      </Card.Body>
    </Card>
  )
}
