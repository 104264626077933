import { IconPlus } from '@loadsmart/icons'
import { Button, Layout, Text } from '@loadsmart/loadsmart-ui'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { useMemo, useState } from 'react'
import type { Dispatch, ReactNode, SetStateAction } from 'react'
import ReactPaginate from 'react-paginate'
import { useMutation } from 'react-query'
import type { UseQueryResult } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import EmptyStateImage from 'assets/imgs/tracking-empty-state.svg'
import {
  Container,
  EmptyButtonsRow,
  EmptyLanesContainer,
  EmptyLanesImage,
  StyledText,
} from 'components/LaneManagement/LanesTableV3/styles'
import { useModal } from 'hooks/useModal'
import usePagination from 'hooks/usePagination'
import { defaultLaneTableParams, LIMIT } from 'rfp/components/table/laneParams'
import type { QueryParams } from 'rfp/components/table/laneParams'
import {
  getTablePageOffset,
  getTableSortDirection,
  useLaneIdMaxLength,
} from 'rfp/components/table/tableUtils'
import BaseTable from 'rfp/rfp-details/award-scenarios/BaseTable'
import { ImportLanes } from 'rfp/rfp-details/award-scenarios/ImportLanes'
import DeleteLanesDialog from 'rfp/rfp-details/award-scenarios/lanes-deallocated-table/DeleteLanesDialog'
import LaneSearchField from 'rfp/rfp-details/award-scenarios/LaneSearchField'
import type { UploadLanesSuccessResponse } from 'rfp/rfp-details/types'
import type { RequestForProposal } from 'rfp/rfp.types'
import { bulkDeleteLanes } from 'rfp/services/rfp'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'

import makeAddLanesColumns from './makeAddLanesColumns'

const StyledGroup = styled(Layout.Group)`
  margin-bottom: ${getToken('space-m')};
`

enum RadioOptions {
  LOWEST = 'lowest_bid',
  AVERAGE = 'average_bid',
  HIGHEST = 'highest_bid',
  LAST_AWARD = 'last_award',
  TARGET_RATE = 'target_rate',
  SPOT_MARKET = 'market_rate',
  SHORT_CONTRACT = 'contract_rate',
}

const RateOptions = [
  { value: 'market_rate', label: 'Market Benchmark' },
  { value: 'target_rate', label: 'Target Rate' },
  { value: 'last_award', label: 'Last Award' },
]

const MultiCurrencyDraftRateOptions = RateOptions.filter((option) =>
  [RadioOptions.TARGET_RATE, RadioOptions.LAST_AWARD].includes(
    option.value as RadioOptions
  )
)

const MultiCurrencyPublishedRateOptions = RateOptions.filter(
  (option) => option.value === RadioOptions.LAST_AWARD
)

interface AddLanesProps {
  readonly rfp: RequestForProposal
  readonly query: UseQueryResult<PaginatedResult, unknown>
  readonly unrankedLanesSearchQuery: string
  readonly setLaneSearchQuery: (query: string) => void
  readonly laneCsvTemplateButtonProps: {
    onClick: () => void
    disabled: boolean
    isDownloading: boolean
  }
  readonly addLanesButtonProps: {
    onClick: () => void
    disabled: boolean
  }
  readonly showLaneDetailsOrRoutingGuide: (lane: Lane) => void
  readonly showRecommendedCarriersSidebar: (lane: Lane) => void
  readonly params: QueryParams
  readonly setParams: Dispatch<SetStateAction<QueryParams>>
  readonly addLanesButton: ReactNode
  readonly importLanesButton: ReactNode
  readonly onSuccessUploadLane: (lanes: UploadLanesSuccessResponse) => void
  readonly onErrorUploadLane: (start?: number, errorType?: string) => void
}

const AddLanes = ({
  query,
  unrankedLanesSearchQuery,
  setLaneSearchQuery,
  laneCsvTemplateButtonProps,
  addLanesButtonProps,
  rfp,
  params,
  setParams,
  showLaneDetailsOrRoutingGuide,
  showRecommendedCarriersSidebar,
  addLanesButton,
  importLanesButton,
  onErrorUploadLane,
  onSuccessUploadLane,
}: AddLanesProps) => {
  const displayMultiCurrency = isMultiCurrency(rfp.currency)
  const [rateOption, setRadioOptionTwo] = useState<string>(
    displayMultiCurrency || rfp.state === RFP_STATE.DRAFT
      ? RadioOptions.LAST_AWARD
      : RadioOptions.SPOT_MARKET
  )
  const [selectedLanes, setSelectedLanes] = useState([])

  const getAvailableRateOptions = () => {
    const multiCurrencyOptions =
      rfp.state === RFP_STATE.DRAFT
        ? MultiCurrencyDraftRateOptions
        : MultiCurrencyPublishedRateOptions

    return displayMultiCurrency ? multiCurrencyOptions : RateOptions
  }

  const availableRateOptions = getAvailableRateOptions()

  const { pageCount, page } = usePagination(
    LIMIT,
    query.data?.count,
    params.page
  )

  const handlePageChange = (selectedItem: { selected: number }) => {
    setParams({
      ...params,
      page: selectedItem.selected,
      offset: getTablePageOffset(
        selectedItem.selected,
        defaultLaneTableParams.offset
      ),
    })
  }

  const changeSorting = (column: string) => {
    setParams({
      ...params,
      sort: {
        column,
        direction: getTableSortDirection(params.sort.direction),
      },
    })
  }

  const laneIdMaxLength = useLaneIdMaxLength()

  const columns = useMemo(() => {
    return () =>
      makeAddLanesColumns({
        laneIdMaxLength,
        rateOption,
        availableRateOptions,
        displayMultiCurrency,
        rfp,
        showRecommendedCarriersSidebar,
        setRadioOptionTwo,
      })
  }, [
    laneIdMaxLength,
    rateOption,
    availableRateOptions,
    displayMultiCurrency,
    rfp,
    showRecommendedCarriersSidebar,
  ])

  const isQueryLoadingOrHasResults = () =>
    query.isLoading || (query.data?.results?.length ?? 0) > 0

  const showTable = isQueryLoadingOrHasResults()

  const deleteLanesDialog = useModal()

  const { mutate: deleteLanes, isLoading: isRemovingLanes } = useMutation({
    mutationFn: bulkDeleteLanes,
    onSuccess: () => {
      analytics.track(
        'Lanes / Delete Selected',
        AnalyticsEventTrigger.success,
        {
          deletedLanesCount: selectedLanes.length,
          totalLanesCount: query.data?.count,
        }
      )
      deleteLanesDialog.closeModal()
      toast.success('Lanes successfully deleted')
      setParams({
        ...defaultLaneTableParams,
        filterByRankedLanes: undefined,
        page: 0,
      })
      query.refetch()
      setSelectedLanes([])
    },
    onError: () => {
      analytics.track('Lanes / Delete Selected', AnalyticsEventTrigger.error)
      deleteLanesDialog.closeModal()
      toast.error('Could not delete lanes, please contact an admin')
    },
  })

  return (
    <>
      <StyledGroup align="center" justify="space-between">
        <Text variant="heading-md-bold" color="color-neutral-darker">
          {`${query.data?.count ?? 0} lanes`}
        </Text>
        <Layout.Group space="s">
          <LaneSearchField
            laneSearchQuery={unrankedLanesSearchQuery}
            setLaneSearchQuery={setLaneSearchQuery}
          />
          <ImportLanes
            onClickDownloadTemplate={laneCsvTemplateButtonProps.onClick}
            onErrorUploadLane={onErrorUploadLane}
            onSuccessUploadLane={onSuccessUploadLane}
            rfpId={rfp.id}
          />
          <Button
            data-testid="button-create-lane"
            variant="secondary"
            scale="default"
            onClick={addLanesButtonProps.onClick}
            leading={<IconPlus width={12} />}
            disabled={addLanesButtonProps.disabled}
          >
            ADD LANE
          </Button>
          <Button
            variant="secondary"
            scale="default"
            onClick={() => deleteLanesDialog.openModal()}
            disabled={selectedLanes.length === 0}
          >
            DELETE
          </Button>
        </Layout.Group>
      </StyledGroup>
      {!showTable && (
        <EmptyLanesContainer border="v2">
          <EmptyLanesImage src={EmptyStateImage} />
          <Text variant="body-bold">Add Your Lanes</Text>
          <StyledText size="small">
            Add single lanes or import lanes in batch for this RFP.
          </StyledText>
          <EmptyButtonsRow>
            {addLanesButton}
            {importLanesButton}
          </EmptyButtonsRow>
        </EmptyLanesContainer>
      )}
      <Container>
        <Layout.Stack>
          {showTable && (
            <BaseTable
              isLoading={query.isLoading}
              columns={columns}
              entries={query.data?.results ?? []}
              onRowClick={(_event, row) =>
                showLaneDetailsOrRoutingGuide(row.original)
              }
              sortable
              selectable={rfp.state === RFP_STATE.DRAFT}
              updateSelection={setSelectedLanes}
              changeSorting={changeSorting}
              sort={params.sort}
            />
          )}
        </Layout.Stack>
        {pageCount > 1 && (
          <ReactPaginate
            data-testid="pagination"
            previousLabel="Previous"
            nextLabel="Next"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            forcePage={page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
          />
        )}
        <DeleteLanesDialog
          deleteLanes={() =>
            deleteLanes({ rfpId: rfp.id, laneIds: selectedLanes })
          }
          isDeleting={isRemovingLanes}
          isOpen={deleteLanesDialog.modalState}
          lanesCount={selectedLanes.length}
          onClose={deleteLanesDialog.closeModal}
        />
      </Container>
    </>
  )
}

export default AddLanes
