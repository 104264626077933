import { Layout } from '@loadsmart/loadsmart-ui'

import { numberFormatter } from 'utils/numbers'

import { TotalizerItem } from './index'

const RankedTotalizers = ({
  lanesCount,
  totalizers,
  totalSpentHeader,
  totalSpentFooter,
}: {
  readonly lanesCount: number | undefined
  readonly totalizers: RFPTotalizers | undefined
  readonly totalSpentHeader: string
  readonly totalSpentFooter: string | undefined
}) => {
  return (
    <Layout.Group space="s">
      <TotalizerItem
        title="Total lanes"
        value={`${lanesCount}`}
        footer={`${
          numberFormatter(totalizers?.lane_count_ranked, 1) ?? 0
        }% allocated`}
      />
      <TotalizerItem
        title="Carriers ranked"
        value={`${totalizers?.total_carriers_ranked || 0}`}
      />
      <TotalizerItem
        title={<Layout.Group space="xs">Total spend</Layout.Group>}
        value={totalSpentHeader}
        footer={totalSpentFooter}
      />
    </Layout.Group>
  )
}

export default RankedTotalizers
