import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useFulfillmentFormContext } from 'fulfillments/components/FulfillmentFormContext'
import { useFulfillmentField } from 'fulfillments/create/hooks/useFulfillmentField'
import type { FulfillmentFormTextFields } from 'fulfillments/domain/Fulfillment'

type FulfillmentFieldProps = Readonly<{
  name: keyof FulfillmentFormTextFields
  label?: string
  required?: FieldProps['required']
  placeholder?: string
  hint?: string
  error?: string
  style?: CSSProperties
}>

export function FulfillmentField({
  name,
  label,
  required,
  placeholder = '',
  hint = '',
  error,
  style,
}: FulfillmentFieldProps) {
  const { fulfillment, setPartialFulfillment } = useFulfillmentFormContext()

  const { fieldProps, hintProps } = useFulfillmentField({
    name,
    hint,
    error,
    meta: fulfillment._metadata,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${name}-field`}
    >
      <Field.Label id={`label-${name}`}>{label}</Field.Label>
      <TextField
        aria-labelledby={`label-${name}`}
        placeholder={placeholder}
        value={get(fulfillment, name)}
        onChange={(event) => {
          setPartialFulfillment({ [name]: get(event, 'target.value') })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
