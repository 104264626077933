import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import App from 'app/App'
import store from 'app/store'
import analytics from 'utils/analytics'

import * as serviceWorker from './serviceWorker'

import 'react-app-polyfill/stable'
/**
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/structuredClone|structuredClone global function}
 * @see {@link https://github.com/zloirock/core-js?tab=readme-ov-file#structuredclone}
 */
import 'core-js/modules/web.structured-clone'

analytics.init()

const container = document.getElementById('root')!

const root = createRoot(container)

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
)

serviceWorker.unregister()
