import { pick } from 'lodash-es'
import { useContext } from 'react'

import { CommoditiesFormContext } from 'components/HandlingUnitsManager/CommoditiesForm/CommoditiesForm.hooks'
import { ShippingItemsFormContext } from 'components/ShippingItemsManager'
import { useIndexerContext } from 'contexts/indexer'
import type { useCustomFormFieldProps } from 'hooks/useCustomFormField'
import { useCustomFormField } from 'hooks/useCustomFormField'
import { getTransientError, getMetadata, METADATA_KEY } from 'utils/transient'

import type { HazmatItem } from './HazmatInformation.types'

/**
 * A hazmat item form does not exist by itself.
 * For now, we only use it in the context of shipping items when creating a new shipment.
 */

const useHazmatItemFormContext = () => {
  // Not sure why, but both contexts will always have a value.
  // They share the same api ([items, dispatch]) with set items action.
  const shippingItemsContext = useContext(ShippingItemsFormContext)
  const commodityContext = useContext(CommoditiesFormContext)

  // So we have to check if one of the context have items to return the correct one.
  const commoditiesHasItems = commodityContext?.[0].length > 0
  if (commoditiesHasItems) {
    return [
      commodityContext[0].map((item) =>
        pick(item, [
          'hazmat_un_number',
          'hazmat_class',
          'hazmat_packing_group',
          'hazmat_proper_shipping_name',
          METADATA_KEY,
        ])
      ),
      commodityContext[1],
    ] as const
  }

  return [
    shippingItemsContext[0].map((item) =>
      pick(item, [
        'hazmat_un_number',
        'hazmat_class',
        'hazmat_packing_group',
        'hazmat_proper_shipping_name',
        METADATA_KEY,
      ])
    ),
    shippingItemsContext[1],
  ] as const
}

export type useHazmatItemFormFieldProps = {
  name: keyof HazmatItem
  hint?: useCustomFormFieldProps['hint']
}

export function useHazmatItemFormField(props: useHazmatItemFormFieldProps) {
  const { name, hint } = props

  /**
   * up until now we do not have the need to have a separated context for managing
   * the hazmat items, as they are currently part of the Shipping Item form in the new shipment
   * lifecycle. We should revisit this decision in case we need to use the hazmat item fields in a
   * different feature flow.
   */
  const [items, dispatch] = useHazmatItemFormContext()
  const index = useIndexerContext()
  const item = items[index]
  const error = getTransientError(item, name)

  const controlProps = getMetadata(item, ['controls', name])
  const fieldProps = getMetadata(item, ['fields', name])

  const formFieldProps = useCustomFormField({
    index,
    name,
    hint,
    error,
    controlProps,
    fieldProps,
  })

  return {
    index,
    item,
    dispatch,
    ...formFieldProps,
  }
}
