import { isEmpty } from 'lodash-es'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useMemo } from 'react'

import { useSettings } from '_shared_/settings/useSettings'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import type {
  ORDER_GROUPING_MULTIPLE_OPTION,
  ORDER_GROUPING_SINGLE_OPTION,
} from 'screens/Orders/ManualConsolidation/useSelectedOrders'
import type { TransientShipment } from 'screens/Shipper/Shipments/components/Shipment'
import { resolveTransportationMode } from 'utils/transportationMode'

import type { ConsolidationContextValue } from '../consolidation.types'
import { useFulfillmentConsolidation } from './useFulfillmentConsolidation'
import { useOrderConsolidation } from './useOrderConsolidation'

export function useConsolidation({
  setPartialShipment,
  setShipment,
}: {
  setPartialShipment: (shipment: Partial<TransientShipment>) => void
  setShipment: Dispatch<SetStateAction<TransientShipment>>
}) {
  const {
    fulfillmentsInConsolidation,
    inConsolidation: fulfillmentConsolidation,
  } = useFulfillmentConsolidation({
    setShipment,
  })
  const { ordersInConsolidation, consolidationMode } = useOrderConsolidation({
    enable: !fulfillmentConsolidation,
    setShipment,
  })

  let ordersCount = ordersInConsolidation.length

  if (fulfillmentsInConsolidation.length) {
    const ordersUUIDs = fulfillmentsInConsolidation.map(
      (fulfillment) => fulfillment.order_uuids
    )
    ordersCount = new Set(ordersUUIDs).size
  }

  const {
    values: [lockConsolidationToLTL],
  } = useSettings(['flags.ENABLE_CONSOLIDATION_MODE_LOCK'])

  const inConsolidation =
    !isEmpty(ordersInConsolidation) || !isEmpty(fulfillmentsInConsolidation)

  useEffect(() => {
    if (lockConsolidationToLTL && inConsolidation) {
      setPartialShipment({ mode: resolveTransportationMode('ltl') as string })
    }
  }, [lockConsolidationToLTL, inConsolidation, setPartialShipment])

  const consolidationType = useMemo(() => {
    if (fulfillmentConsolidation) {
      return 'fulfillment'
    }
    if (inConsolidation) {
      return 'order'
    }
    return null
  }, [inConsolidation, fulfillmentConsolidation])

  const canBeRemoved = (handlingUnit: TransientHandlingUnit) => {
    return isEmpty(handlingUnit.fulfillment_handling_unit)
  }

  const ConsolidationContextValue: ConsolidationContextValue = useMemo(
    () => ({
      consolidationType,
      selectedOrders: ordersInConsolidation,
      selectedFulfillments: fulfillmentsInConsolidation,
      groupingMode: consolidationMode as
        | typeof ORDER_GROUPING_SINGLE_OPTION
        | typeof ORDER_GROUPING_MULTIPLE_OPTION,
      modeLocked: lockConsolidationToLTL,
      canBeRemoved,
      ordersCount,
    }),
    [
      ordersInConsolidation,
      fulfillmentsInConsolidation,
      consolidationMode,
      lockConsolidationToLTL,
      consolidationType,
      ordersCount,
    ]
  )
  return ConsolidationContextValue
}
