import type { AttributeMapper } from 'utils/AdapterFactory'

import type {
  DrayageCheckoutFormValues,
  OceanLine,
} from './DrayageCheckout.types'

export const CONTACT_DETAILS_STATE_TO_PAYLOAD_MAPPER: AttributeMapper[] = [
  {
    from: 'terminalNotes',
    to: 'terminal',
    transform(terminalNotes: DrayageCheckoutFormValues['terminalNotes']) {
      return {
        contact_uuid: null,
        email: null,
        contact_name: null,
        phone: null,
        stop_index: 0,
        instructions: terminalNotes,
      }
    },
  },
  {
    from: 'facilityContact',
    to: 'facility',
    transform(facilityContact: DrayageCheckoutFormValues['facilityContact']) {
      return {
        contact_uuid: facilityContact?.uuid,
        email: facilityContact?.email,
        contact_name: facilityContact?.name,
        phone: facilityContact?.phone,
        stop_index: 1,
      }
    },
  },
  {
    from: 'facilityNotes',
    to: 'facility.instructions',
  },
]

export const OCEAN_LINES: OceanLine[] = [
  {
    id: 1,
    name: 'ACL/Grimaldi Group',
    scac_code: 'ACLU',
  },
  {
    id: 20,
    name: 'American President Lines (APLU)',
    scac_code: 'APLU',
  },
  {
    id: 2,
    name: 'CMA CGM / APL',
    scac_code: 'CMDU',
  },
  {
    id: 24,
    name: 'China United Lines',
    scac_code: 'CULU',
  },
  {
    id: 3,
    name: 'Cosco Shipping',
    scac_code: 'COSU',
  },
  {
    id: 25,
    name: 'Crowley Puerto Rico Services',
    scac_code: 'CPRC',
  },
  {
    id: 4,
    name: 'Evergreen Line',
    scac_code: 'EGLV',
  },
  {
    id: 22,
    name: 'Global Sea Containers Ltd (GSCL)',
    scac_code: 'GSCL',
  },
  {
    id: 19,
    name: 'HAMBURG SUD',
    scac_code: 'SUDU',
  },
  {
    id: 5,
    name: 'Hapag-Lloyd',
    scac_code: 'HLCU',
  },
  {
    id: 6,
    name: 'Hyundai Merchant Marine (HMM)',
    scac_code: 'HDMU',
  },
  {
    id: 27,
    name: 'Lihua Logistics',
    scac_code: 'LLHP',
  },
  {
    id: 7,
    name: 'Maersk Line',
    scac_code: 'MAEU',
  },
  {
    id: 8,
    name: 'Matson',
    scac_code: 'MATS',
  },
  {
    id: 9,
    name: 'Mediterranean Shipping Company (MSC)',
    scac_code: 'MSCU',
  },
  {
    id: 30,
    name: 'New Way (H.K.) International Limited',
    scac_code: 'NWRU',
  },
  {
    id: 10,
    name: 'Ocean Network Express (ONE)',
    scac_code: 'ONEY',
  },
  {
    id: 11,
    name: 'Orient Overseas Container Line (OOCL)',
    scac_code: 'OOLU',
  },
  {
    id: 12,
    name: 'Pacific International Lines (PIL)',
    scac_code: 'PCIU',
  },
  {
    id: 15,
    name: 'SM Line',
    scac_code: '',
  },
  {
    id: 13,
    name: 'Safmarine',
    scac_code: 'SAFM',
  },
  {
    id: 14,
    name: 'Seaboard Marine',
    scac_code: 'SMLU',
  },
  {
    id: 28,
    name: 'Sealand',
    scac_code: 'SEAU',
  },
  {
    id: 21,
    name: 'Swire Shipping',
    scac_code: 'CHVW',
  },
  {
    id: 29,
    name: 'T.S. Lines',
    scac_code: 'THZS',
  },
  {
    id: 23,
    name: 'Turkon Line',
    scac_code: 'TRKU',
  },
  {
    id: 16,
    name: 'Wan Hai Lines',
    scac_code: '22AA',
  },
  {
    id: 17,
    name: 'Yang Ming Line',
    scac_code: 'YMLU',
  },
  {
    id: 18,
    name: 'Zim Integrated Shipping',
    scac_code: 'ZIMU',
  },
]
