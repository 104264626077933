import { useQuery } from 'react-query'

import { getSuppliers } from 'fulfillments/fulfillment-service'
import type { Supplier } from 'suppliers/types'

const suppliersToOptions = (suppliers: Supplier[]) => {
  return suppliers.map((supplier) => ({
    label: supplier.name,
    value: supplier.uuid,
  }))
}

export function useSuppliersOptions() {
  return useQuery({
    queryKey: ['get-suppliers'],
    queryFn: getSuppliers,
    cacheTime: 60000,
    select: suppliersToOptions,
  })
}
