import { defaultTo } from 'lodash-es'
import { useCallback } from 'react'
import { useQueries } from 'react-query'

import { getOrderDetails } from 'orders/order-service'
import type { Order } from 'orders/types'
import { generateUseSearchParamsPlugin, useFilters } from 'utils/filters'

type OrderSelectionParams = {
  orders: string[]
}

function getDefaultOrderSelectionQueryParams(
  overrides?: Partial<OrderSelectionParams>
): OrderSelectionParams {
  return {
    orders: [],
    ...overrides,
  }
}

const useOrderSelectionQueryParamsPlugin =
  generateUseSearchParamsPlugin<OrderSelectionParams>({
    orders: {
      type: 'primitive-collection',
    },
  })

function mapOrdersQueries(orderUUIDs: string[] | null, enabled: boolean) {
  if (!orderUUIDs?.length) {
    return []
  }

  return orderUUIDs.map((uuid) => ({
    queryKey: ['getOrderDetails', uuid],
    queryFn: () => getOrderDetails(uuid),
    enabled,
    staleTime: 60000, // 1 min, avoids repeating calls as user adds to the selection
  }))
}

export function useOrderSelection({
  loadDetails = false,
}: {
  loadDetails?: boolean
} = {}) {
  const { setFilter, values } = useFilters(
    { initialValues: getDefaultOrderSelectionQueryParams() },
    useOrderSelectionQueryParamsPlugin
  )
  const { orders } = values
  const selectedOrderUUIDs = defaultTo(orders, [])
  const results = useQueries(mapOrdersQueries(selectedOrderUUIDs, loadDetails))

  const selectedOrdersData = results.reduce<{
    isError?: boolean
    isLoading?: boolean
    orders: (Order | undefined)[]
  }>(
    (summary, result) => {
      const { data, isError, isLoading } = result

      return {
        isError: summary.isError || isError,
        isLoading: summary.isLoading || isLoading,
        orders: [...summary.orders, data],
      }
    },
    { orders: [] }
  )

  const setSelectedOrders = useCallback(
    (newSelected: string[]) => {
      setFilter('orders', newSelected)
    },
    [setFilter]
  )

  const addSelectedOrders = useCallback(
    (toBeAdded: string | string[]) => {
      const array = Array.isArray(toBeAdded) ? toBeAdded : [toBeAdded]
      const newOrdersArray = [...selectedOrderUUIDs, ...array]
      setFilter('orders', newOrdersArray)
    },
    [selectedOrderUUIDs, setFilter]
  )

  const removeSelectedOrders = useCallback(
    (toBeRemoved: string | string[]) => {
      const newOrdersArray = selectedOrderUUIDs.filter((uuid) => {
        if (Array.isArray(toBeRemoved)) {
          return !toBeRemoved.includes(uuid)
        }
        return toBeRemoved !== uuid
      })

      setFilter('orders', newOrdersArray)
    },
    [selectedOrderUUIDs, setFilter]
  )

  const clearSelectedOrders = useCallback(() => {
    setFilter('orders', [])
  }, [setFilter])

  return {
    addSelectedOrders,
    clearSelectedOrders,
    removeSelectedOrders,
    selectedOrdersData,
    selectedOrderUUIDs,
    setSelectedOrders,
  }
}
