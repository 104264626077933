import { IconDownload, IconEmailFilled } from '@loadsmart/icons'
import { Banner, Dialog, Layout, LoadingDots } from '@loadsmart/loadsmart-ui'
import { useCallback, useMemo, useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useCurrentUser } from '_shared_/user/useCurrentUser'
import Button from 'components/Button'
import type { RequestForProposalDetail } from 'rfp/rfp.types'
import {
  downloadResource,
  downloadSample,
  exportResource,
  sendAwardsByEmail,
} from 'rfp/services/rfp'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { BID_TYPE, RFP_STATE } from 'utils/constants'
import { downloadData } from 'utils/download'
import { STATUS } from 'utils/httpClient'

import { fileExtensions } from '../utils'

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const DownloadSection = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`
const DownloadSectionTitle = styled.h2`
  display: flex;
`
const DownloadSectionInfo = styled.p`
  display: block;
  text-align: left;
`
const DownloadSectionActions = styled.div`
  display: flex;
  & > button {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    margin-right: 20px;
    padding: 4px 10px;
    width: fit-content;

    & > svg {
      margin-right: 5px;
    }
  }
`

const trackDownloadAllBids = (
  fileType: 'csv' | 'csv_rows' | 'excel',
  rfpState: string
) => {
  analytics.track('RFP / Download / All bids', AnalyticsEventTrigger.click, {
    file_type: fileType,
    status: rfpState,
  })
}

const trackDownloadAwardedCarriers = (
  fileType: 'csv' | 'excel',
  rfpState: string
) => {
  analytics.track(
    'RFP / Download / Awarded carriers',
    AnalyticsEventTrigger.click,
    {
      file_type: fileType,
      status: rfpState,
    }
  )
}

function DownloadsActionButtons({
  showAsyncReportMessage,
  showAsyncDownload,
  onClose,
  handleBannerClose,
}: {
  readonly showAsyncReportMessage: boolean
  readonly showAsyncDownload: boolean
  readonly onClose: () => void
  readonly handleBannerClose: () => void
}) {
  if (showAsyncReportMessage) {
    return (
      <Layout.Stack>
        <Banner
          scale="default"
          title="The report is being generated. You will receive an email with the download link once it's ready."
          variant="warning"
          onClose={handleBannerClose}
        />
        <Dialog.ActionConfirm onConfirm={onClose}>Ok</Dialog.ActionConfirm>
      </Layout.Stack>
    )
  }

  if (!showAsyncDownload) {
    return <Dialog.ActionCancel onCancel={onClose} />
  }

  return null
}

const getDialogText = (rfpName: string | undefined) =>
  rfpName ? 'Your report is ready' : 'Preparing your report...'

const getDisabledStatus = (isExporting: boolean, rfpName: string | undefined) =>
  isExporting || !rfpName

export default function DownloadDialog({
  rfp,
  isOpen,
  onClose,
  asyncReportData,
}: {
  readonly rfp: RequestForProposalDetail
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly asyncReportData: { type: string; format: string }
}) {
  const { user } = useCurrentUser()
  const [clickedButtonIdentifier, setClickedButtonIdentifier] = useState('')
  const [error, setError] = useState<boolean>(false)
  const [isExporting, setIsExporting] = useState<boolean>(false)
  const [showAsyncReportMessage, setShowAsyncReportMessage] =
    useState<boolean>(false)

  const isRankedAward = rfp.bid_type === BID_TYPE.RANK

  const { mutateAsync: downloadMutateAsync } = useMutation({
    mutationFn: exportResource,
  })

  const onError = () => {
    setError(true)
    setIsExporting(false)
  }

  const { mutate: asyncReportDownloadMutate } = useMutation({
    mutationFn: downloadResource,
    onMutate() {
      setError(false)
      setIsExporting(true)
    },
    onSuccess(values) {
      const { data, reportName, type } = values
      downloadData(data, reportName, type)
      setIsExporting(false)
    },
    onError,
  })

  const { mutate: downloadLanesSampleMutate } = useMutation({
    mutationFn: downloadSample,
    onMutate() {
      setError(false)
      setIsExporting(true)
    },
    onSuccess(values) {
      const { data, type } = values
      downloadData(data, `RFP Lanes Template.csv`, type)
      setIsExporting(false)
    },
    onError,
  })
  const downloadAwardedCarriersByEmailMutation = useMutation({
    mutationFn: sendAwardsByEmail,
    onMutate() {
      setError(false)
      setIsExporting(true)
    },
    onSuccess() {
      toast.success('The PDFs will be sent to your email once ready.')
      onClose()
      setIsExporting(false)
    },
    onError,
  })

  const handleDownload = useCallback(
    async (
      resource: 'bids' | 'awards',
      format: 'csv' | 'csv_rows' | 'excel'
    ) => {
      setError(false)
      setIsExporting(true)
      try {
        const { data, type, status } = await downloadMutateAsync({
          id: rfp.id,
          resource,
          format,
        })
        if (status === STATUS.HTTP_204_NO_CONTENT) {
          setShowAsyncReportMessage(true)
          return
        }
        downloadData(
          data,
          `${rfp.name} ${resource}.${fileExtensions[format]}`,
          type
        )
      } catch {
        setError(true)
        setIsExporting(false)
      } finally {
        setIsExporting(false)
      }
    },
    [downloadMutateAsync, rfp.id, rfp.name]
  )

  useEffect(() => {
    if (!isExporting) {
      setClickedButtonIdentifier('')
    }
  }, [isExporting])

  const onClickDownloadLanesSample = useCallback(() => {
    return () => {
      setClickedButtonIdentifier('CSVlanesSample')
      downloadLanesSampleMutate(rfp.id)
    }
  }, [downloadLanesSampleMutate, rfp])

  const downloadAsyncReport = useCallback(() => {
    asyncReportDownloadMutate({
      ...asyncReportData,
      rfpId: rfp.id,
      rfpName: rfp.name,
    })
  }, [asyncReportData, asyncReportDownloadMutate, rfp.id, rfp.name])

  const downloadOptions = useMemo(() => {
    const options = []
    if (rfp.state !== RFP_STATE.DRAFT) {
      options.push({
        title: 'All Bids',
        actions: [
          {
            callback: () => {
              handleDownload('bids', 'csv')
              trackDownloadAllBids('csv', rfp.state)
            },
            label: 'CSV',
            Icon: IconDownload,
            identifier: 'allBidsCSV',
          },
          {
            callback: () => {
              handleDownload('bids', 'csv_rows')
              trackDownloadAllBids('csv_rows', rfp.state)
            },
            label: 'CSV - ROWS',
            Icon: IconDownload,
            identifier: 'allBidsCSVRows',
          },
          {
            callback: () => {
              handleDownload('bids', 'excel')
              trackDownloadAllBids('excel', rfp.state)
            },
            label: 'EXCEL',
            Icon: IconDownload,
            identifier: 'allBidsCSVExcel',
          },
        ],
      })
    }
    if (
      rfp.was_finalized ||
      rfp.state === RFP_STATE.PUBLISHED ||
      rfp.state === RFP_STATE.CLOSED
    ) {
      options.push({
        title: isRankedAward ? 'Ranked Carriers' : 'Awarded Carriers',
        actions: [
          {
            callback: () => {
              handleDownload('awards', 'csv')
              trackDownloadAwardedCarriers('csv', rfp.state)
            },
            label: 'CSV',
            Icon: IconDownload,
            identifier: 'awardedCarriersCSV',
          },
          {
            callback: () => {
              handleDownload('awards', 'excel')
              trackDownloadAwardedCarriers('excel', rfp.state)
            },
            label: 'EXCEL',
            Icon: IconDownload,
            identifier: 'awardedCarriersExcel',
          },
        ],
      })
    }
    return options
  }, [handleDownload, rfp.state, rfp.was_finalized, isRankedAward])

  const handleCloseDownloadsModal = () => {
    setShowAsyncReportMessage(false)
    onClose()
  }

  const showAsyncDownload = !!asyncReportData.type && !!asyncReportData.format

  return (
    <Dialog
      open={isOpen}
      scale="default"
      onOverlayClick={handleCloseDownloadsModal}
    >
      <Dialog.Header>Downloads</Dialog.Header>
      {error && (
        <Banner
          scale="default"
          title="An error occurred, please try again."
          variant="danger"
        />
      )}
      <DownloadWrapper data-testid="downloadDialog">
        {showAsyncDownload ? (
          <Layout.Stack>
            <DownloadSectionTitle>
              {getDialogText(rfp.name)}
            </DownloadSectionTitle>
            <Dialog.ActionConfirm
              disabled={getDisabledStatus(isExporting, rfp.name)}
              onConfirm={downloadAsyncReport}
            >
              <IconDownload width={12} height={12} />
              Download your report
            </Dialog.ActionConfirm>
          </Layout.Stack>
        ) : (
          <div>
            {Object.entries(downloadOptions).map(
              ([key, { title, actions }]) => {
                return (
                  <DownloadSection key={key}>
                    <DownloadSectionTitle>{title}</DownloadSectionTitle>
                    <DownloadSectionActions>
                      {Object.values(actions).map(
                        ({ callback, Icon, label, identifier }, index) => {
                          return (
                            <Button
                              style={{
                                height: '26px',
                                lineHeight: '0',
                              }}
                              key={index}
                              variant="secondary"
                              disabled={
                                isExporting &&
                                identifier === clickedButtonIdentifier
                              }
                              onClick={() => {
                                setClickedButtonIdentifier(identifier)
                                callback()
                              }}
                              data-testid={`${title}-${label}`}
                            >
                              {Icon && <Icon width={12} height={12} />}
                              {isExporting &&
                              identifier === clickedButtonIdentifier ? (
                                <LoadingDots />
                              ) : (
                                label
                              )}
                            </Button>
                          )
                        }
                      )}
                    </DownloadSectionActions>
                  </DownloadSection>
                )
              }
            )}
          </div>
        )}
        {rfp.was_finalized && !isRankedAward && (
          <DownloadSection>
            <DownloadSectionTitle>Award Letters</DownloadSectionTitle>
            <DownloadSectionActions style={{ flexDirection: 'column' }}>
              <DownloadSectionInfo>
                Send a package (.zip) with all award letters to your email.
                <br />
                It will be sent to <b>{user?.user_email}</b>
              </DownloadSectionInfo>
              <Button
                variant="secondary"
                onClick={() => {
                  downloadAwardedCarriersByEmailMutation.mutate(rfp.id)
                }}
                data-testid="Award Letters-EMAIL"
              >
                <IconEmailFilled width={12} height={12} />
                Send to email
              </Button>
            </DownloadSectionActions>
          </DownloadSection>
        )}
        {rfp.state === RFP_STATE.DRAFT && (
          <DownloadSection>
            <DownloadSectionTitle>Import Lanes Template</DownloadSectionTitle>
            <DownloadSectionActions style={{ flexDirection: 'column' }}>
              <DownloadSectionInfo>
                Use the lanes template spreadsheet to add your lane information
                and upload it to RFP Guide
              </DownloadSectionInfo>
              <Button
                variant="secondary"
                onClick={onClickDownloadLanesSample()}
                data-testid="Download-Lanes-Sample"
                disabled={
                  isExporting && clickedButtonIdentifier === 'CSVlanesSample'
                }
                style={{
                  height: '26px',
                  lineHeight: '0',
                }}
              >
                <IconDownload width={12} height={12} />

                {isExporting && clickedButtonIdentifier === 'CSVlanesSample' ? (
                  <LoadingDots />
                ) : (
                  <>CSV - Lanes Template</>
                )}
              </Button>
            </DownloadSectionActions>
          </DownloadSection>
        )}
      </DownloadWrapper>
      <DownloadsActionButtons
        showAsyncReportMessage={showAsyncReportMessage}
        showAsyncDownload={showAsyncDownload}
        onClose={handleCloseDownloadsModal}
        handleBannerClose={() => setShowAsyncReportMessage(false)}
      />
    </Dialog>
  )
}
