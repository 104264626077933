import { defaultTo, intersection } from 'lodash-es'

import type { FulfillmentDetails } from 'fulfillments/details/ViewFulfillmentPage.data'

import type {
  FulfillmentsPlan,
  FulfillmentsPlanStop,
} from '../PlanFulfillmentsPage.types'

export function canMoveStopDown(
  index: number,
  stops: FulfillmentsPlanStop[]
): boolean | undefined {
  // invalid range
  if (index < 0 || index >= stops.length) {
    return false
  }

  // not last stop
  if (index === stops.length - 1) {
    return false
  }

  const stop = stops[index]

  // deliveries can always go later
  // if there are no pickups
  if (
    stop.stopType === 'delivery' &&
    stop.pickupFulfillmentsUUIDs.length === 0
  ) {
    return true
  }

  // current stop is a pickup

  const nextStop = stops[index + 1]

  // pickups can always be swapped (if there are no deliveries in the next stop)
  if (
    nextStop.stopType === 'pickup' &&
    nextStop.deliveryFulfillmentsUUIDs.length === 0
  ) {
    return true
  }

  // current stop is pickup and next stop is delivery
  // cannot move down if any of the picked up fulfillments are being delivered up at the next stop
  const pickedUpFulfillmentsAtCurrentStop = stop.pickupFulfillmentsUUIDs
  const deliveredFulfillmentsAtNextStop = nextStop.deliveryFulfillmentsUUIDs

  if (
    intersection(
      pickedUpFulfillmentsAtCurrentStop,
      deliveredFulfillmentsAtNextStop
    ).length > 0
  ) {
    return false
  }

  return true
}

export function canMoveStopUp(
  index: number,
  stops: FulfillmentsPlanStop[]
): boolean | undefined {
  // invalid range
  if (index < 0 || index >= stops.length) {
    return false
  }

  // not first stop
  if (index === 0) {
    return false
  }

  const stop = stops[index]

  // pickups can always go earlier
  // if there are no deliveries
  if (
    stop.stopType === 'pickup' &&
    stop.deliveryFulfillmentsUUIDs.length == 0
  ) {
    return true
  }

  const previousStop = stops[index - 1]

  // can change one delivery for another
  if (
    previousStop.stopType === 'delivery' &&
    previousStop.pickupFulfillmentsUUIDs.length === 0
  ) {
    return true
  }

  // current stop is delivery and previous stop is pickup
  // cannot move up if any of the delivered fulfillments are being picked up at the previous stop
  const deliveredFulfillmentsAtCurrentStop = stop.deliveryFulfillmentsUUIDs
  const pickedUpFulfillmentsAtPreviousStop =
    previousStop.pickupFulfillmentsUUIDs

  if (
    intersection(
      deliveredFulfillmentsAtCurrentStop,
      pickedUpFulfillmentsAtPreviousStop
    ).length > 0
  ) {
    return false
  }

  return true
}

export const getFormattedStopNumber = (
  stop: FulfillmentsPlanStop | undefined = undefined,
  delivery_count = 1
) => {
  if (!stop) {
    return ''
  }

  if (stop.stopType === 'pickup' && stop.pickupIndex) {
    return `Stop #${stop.pickupIndex}`
  }

  if (
    stop.stopType === 'delivery' &&
    stop.deliveryIndex != delivery_count - 1
  ) {
    return `Stop #${defaultTo(stop.deliveryIndex, 0) + 1}`
  }

  return ''
}

export const getStops = (
  fulfillments: FulfillmentDetails[],
  orderedFacilities: string[] = []
) => {
  let stopsFacilityUUIDs = new Set<string>()
  const stopsMap: Record<string, FulfillmentsPlanStop> = {}
  const warnings: Pick<
    FulfillmentsPlan['warnings'],
    'facilityWithPickupAndDelivery'
  > = {}

  // adding first all pickups
  fulfillments.forEach((fulfillment) => {
    if (!stopsMap[fulfillment.pickup_facility.uuid]) {
      stopsFacilityUUIDs.add(fulfillment.pickup_facility.uuid)
      stopsMap[fulfillment.pickup_facility.uuid] = {
        city: defaultTo(fulfillment.pickup_facility.city, ''),
        state: defaultTo(fulfillment.pickup_facility.state, ''),
        country: defaultTo(fulfillment.pickup_facility.country, ''),
        address: defaultTo(fulfillment.pickup_facility.address, ''),
        zipcode: defaultTo(fulfillment.pickup_facility.zipcode, ''),
        latitude: defaultTo(fulfillment.pickup_facility.latitude, ''),
        longitude: defaultTo(fulfillment.pickup_facility.longitude, ''),
        facilityName: fulfillment.pickup_facility.name,
        facilityUUID: fulfillment.pickup_facility.uuid,
        stopType: 'pickup',
        stopLabel: 'Pickup',
        pickupFulfillmentsUUIDs: [fulfillment.uuid],
        deliveryFulfillmentsUUIDs: [],
      }
    } else {
      stopsMap[fulfillment.pickup_facility.uuid].pickupFulfillmentsUUIDs.push(
        fulfillment.uuid
      )
    }
  })

  // then adding all deliveries
  fulfillments.forEach((fulfillment) => {
    if (!stopsMap[fulfillment.delivery_facility.uuid]) {
      stopsFacilityUUIDs.add(fulfillment.delivery_facility.uuid)
      stopsMap[fulfillment.delivery_facility.uuid] = {
        city: defaultTo(fulfillment.delivery_facility.city, ''),
        state: defaultTo(fulfillment.delivery_facility.state, ''),
        country: defaultTo(fulfillment.delivery_facility.country, ''),
        address: defaultTo(fulfillment.delivery_facility.address, ''),
        zipcode: defaultTo(fulfillment.delivery_facility.zipcode, ''),
        latitude: defaultTo(fulfillment.delivery_facility.latitude, ''),
        longitude: defaultTo(fulfillment.delivery_facility.longitude, ''),
        facilityName: fulfillment.delivery_facility.name,
        facilityUUID: fulfillment.delivery_facility.uuid,
        stopType: 'delivery',
        stopLabel: 'Delivery',
        pickupFulfillmentsUUIDs: [],
        deliveryFulfillmentsUUIDs: [fulfillment.uuid],
      }
    } else {
      stopsMap[
        fulfillment.delivery_facility.uuid
      ].deliveryFulfillmentsUUIDs.push(fulfillment.uuid)

      if (stopsMap[fulfillment.delivery_facility.uuid].stopType === 'pickup') {
        warnings.facilityWithPickupAndDelivery = true
      }
    }
  })

  // removing facilities from the ordered array that are not present anymore
  const orderedFacilitiesToKeep = orderedFacilities.filter((facility) =>
    stopsFacilityUUIDs.has(facility)
  )

  // using the fact that Set keep the order of addition
  // to keep the previous ordering, and adding any new additional
  // facilities at the end of the list
  stopsFacilityUUIDs = new Set([
    ...orderedFacilitiesToKeep,
    ...stopsFacilityUUIDs,
  ])

  let puCount = 0
  let delCount = 0
  const stops = Array.from(stopsFacilityUUIDs)
    .map((facilityUUID) => stopsMap[facilityUUID])
    .map((stop) => {
      if (stop.stopType === 'pickup') {
        stop.pickupIndex = puCount
        puCount += 1
      } else {
        stop.deliveryIndex = delCount
        delCount += 1
      }
      return stop
    })
    .map((stop) => ({
      ...stop,
      stopLabel:
        `${stop.stopLabel} ${getFormattedStopNumber(stop, delCount)}`.trim(),
    }))

  stops.forEach((stop, index) => {
    stop.canMoveDown = canMoveStopDown(index, stops)
    stop.canMoveUp = canMoveStopUp(index, stops)
  })

  // update map
  stops.forEach((stop) => {
    stopsMap[stop.facilityUUID] = stop
  })

  return {
    stops,
    stopsMap,
    warnings,
  }
}
