import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import type { Config, DriveStep } from 'driver.js'
import { driver } from 'driver.js'
import { useCallback, useMemo } from 'react'
import { createGlobalStyle } from 'styled-components'

import useLocalStorageState from 'hooks/useLocalStorageState'

export const GuidedTourStylesheet = createGlobalStyle`
  .guided-tour {

    .driver-popover-title {
      max-width: 250px;
    }

    .driver-popover {
      background-color:  ${toCSSValue('color-neutral-0')};
      color: ${toCSSValue('color-neutral-100')};
      border: 1px solid  ${toCSSValue('color-neutral-100')};
      border-radius: 0;
      box-shadow: ${toCSSValue('elevation-4')};
    }

    .driver-popover-prev-btn {
      font-size: ${toCSSValue('font-size-3')};
      font-weight: ${toCSSValue('font-weight-bold')};
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      box-sizing: border-box;
      text-decoration: none;
      gap: ${toCSSValue('spacing-2')};
      padding: ${toCSSValue('spacing-2')} ${toCSSValue('spacing-4')};
      background-color: transparent;
      color: ${toCSSValue('color-primary-100')};
      text-shadow: none;
      border: 1px solid ${toCSSValue('color-primary-100')};
      border-radius: ${toCSSValue('border-radius-s')};

      &:hover {
        background-color: ${toCSSValue('color-background-tertiary')};
      }
    }

    .driver-popover-next-btn {
      font-size: ${toCSSValue('font-size-3')};
      font-weight: ${toCSSValue('font-weight-bold')};
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      box-sizing: border-box;
      text-decoration: none;
      gap: ${toCSSValue('spacing-2')};
      padding: ${toCSSValue('spacing-2')} ${toCSSValue('spacing-4')};
      background-color: ${toCSSValue('color-primary-60')} !important;
      color: ${toCSSValue('color-text-inverted')} !important;
      text-shadow: none;
      border: 1px solid ${toCSSValue('color-primary-60')};
      border-radius: ${toCSSValue('border-radius-s')};

      &:hover {
        background-color: ${toCSSValue('color-primary-100')};
      }
    }

    #driver-popover-skip-btn {
      font-size: ${toCSSValue('font-size-3')};
      font-weight: ${toCSSValue('font-weight-bold')};
      display: flex;
      text-decoration: none;
      padding: ${toCSSValue('spacing-2')} 0;
      background-color: transparent !important;
      color: ${toCSSValue('color-primary-60')} !important;
      text-shadow: none;
      border: none;
      margin-right: auto;

      &:hover {
        background-color: ${toCSSValue('color-primary-100')};
      }
    }
  }

  .driver-popover-close-btn {
    font-size: ${toCSSValue('font-size-6')};
    padding: ${toCSSValue('spacing-4')} ${toCSSValue('spacing-5')};
    color: ${toCSSValue('color-neutral-100')} ;
    height: 6px;
    width: 6px;
  }
`

export type DriverInstance = ReturnType<typeof driver>

export type GuidedTour = {
  name: string
  steps: DriveStep[]
}

export type TourStatus = 'initial' | 'started' | 'finished'

export type GuidedTourStepChange = {
  stepIndex: number
  completedPercentage: number
}

export type UseGuidedTourProps = Readonly<{
  name: string
  showSkipStep?: boolean
  onStepChange?: (stepChange: GuidedTourStepChange) => void
}> &
  Config

function getCompletedTourPercentage(driverInstance: DriverInstance): number {
  const steps = driverInstance.getConfig().steps ?? []
  const stepCount = steps.length
  const currentStep = (driverInstance.getActiveIndex() ?? 0) + 1

  return Math.ceil((currentStep / stepCount) * 100)
}

export function useGuidedTour({
  name,
  showSkipStep,
  onStepChange,
}: UseGuidedTourProps) {
  const storageKey = useMemo(() => `guided-tour-${name}-status`, [name])
  const [localStorageState, setLocalStorageState] =
    useLocalStorageState<TourStatus>(storageKey, { initialValue: 'started' })

  const driverInstance = useMemo(
    () =>
      driver({
        popoverClass: 'guided-tour',
        onNextClick: () => {
          if (driverInstance.hasNextStep()) {
            driverInstance.moveNext()
            setLocalStorageState('started')
          } else {
            setLocalStorageState('finished')
          }
        },
        onCloseClick: () => {
          setLocalStorageState('finished')
        },
        onPopoverRender: (popover) => {
          onStepChange?.({
            stepIndex: driverInstance.getActiveIndex() ?? 0,
            completedPercentage: getCompletedTourPercentage(driverInstance),
          })

          if (!showSkipStep || driverInstance.isLastStep()) {
            return
          }

          const skipButton = document.createElement('button')
          skipButton.id = 'driver-popover-skip-btn'
          skipButton.type = 'button'
          const text = document.createTextNode('Skip')
          skipButton.appendChild(text)

          popover.footerButtons.insertBefore(
            skipButton,
            popover.footerButtons.firstChild
          )

          skipButton.addEventListener('click', () => {
            setLocalStorageState('finished')
            driverInstance.destroy()
          })
        },
      }),
    [onStepChange, setLocalStorageState, showSkipStep]
  )

  const getStatus = useCallback(() => {
    return localStorageState
  }, [localStorageState])

  const setSteps = useCallback(
    (steps: DriveStep[]) => {
      driverInstance.setSteps(steps)
    },
    [driverInstance]
  )

  const start = useCallback(
    (stepIndex: number = 0) => {
      const isFinished = getStatus() === 'finished'
      if (!driverInstance.isActive() && !isFinished) {
        driverInstance.drive(stepIndex)
      }
    },
    [driverInstance, getStatus]
  )

  const stop = useCallback(() => {
    driverInstance.destroy()
  }, [driverInstance])

  const isActive = useCallback(() => {
    return driverInstance.isActive()
  }, [driverInstance])

  return { getStatus, isActive, setSteps, start, stop }
}
