import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties, ReactNode } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationFormField } from './useFreightInformationFormField'

export const TARP_SIZE_NAME = 'tarp_size'
const TARP_SIZE_LABEL = 'Tarp size'

type FreightInformationTarpSizeFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly disabled?: boolean
  readonly hint?: ReactNode
  readonly error?: string
  readonly style?: CSSProperties
  readonly label?: string
}

export function FreightInformationTarpSizeFormField({
  required,
  disabled,
  hint,
  error,
  style,
  label = TARP_SIZE_LABEL,
}: FreightInformationTarpSizeFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()
  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: TARP_SIZE_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${TARP_SIZE_NAME}-field`}
    >
      <Field.Label id={`label-${TARP_SIZE_NAME}`}>{label}</Field.Label>
      <TextField
        aria-labelledby={`label-${TARP_SIZE_NAME}`}
        name={TARP_SIZE_NAME}
        id={TARP_SIZE_NAME}
        placeholder="In ft"
        inputMode="numeric"
        type="number"
        min="0"
        disabled={disabled}
        value={get(shipment, TARP_SIZE_NAME, '')}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [TARP_SIZE_NAME]: Number(get(event, 'target.value')),
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
