import { isWeekend, isAfter, setDate } from '_shared_/utils/date'
import type { DateArgument } from '_shared_/utils/date'

export const END_HOUR_OF_BUSINESS_DAY = 17

export function isBusinessDayOver(date: DateArgument): boolean {
  const endOfBusinessDay = setDate(date, {
    hours: END_HOUR_OF_BUSINESS_DAY,
    minutes: 0,
  })
  return isWeekend(date) || isAfter(date, endOfBusinessDay)
}

export function getDateWithUTCHours(
  date: Date,
  {
    hours,
    minutes,
    seconds,
  }: { hours: number; minutes: number; seconds: number }
): Date {
  const customDate = new Date(date)

  customDate.setUTCHours(hours)
  customDate.setUTCMinutes(minutes)
  customDate.setUTCSeconds(seconds)

  return customDate
}

export function getCustomDateInUTC(
  date: Date,
  {
    hours,
    minutes,
    seconds,
  }: { hours: number; minutes: number; seconds: number }
): Date {
  const customDate = new Date(date)

  customDate.setHours(hours)
  customDate.setMinutes(minutes)
  customDate.setSeconds(seconds)

  return customDate
}
