import type { FieldProps } from '@loadsmart/miranda-react'
import { Field, Toggle, ToggleGroup } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const HAZMAT_NAME = 'hazmat'
const HAZMAT_LABEL = 'Hazmat'
const YES_VALUE = 'yes'
const NO_VALUE = 'no'

type FreightInformationHazmatFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly disabled?: boolean
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

export function FreightInformationHazmatFormField({
  required,
  disabled,
  hint,
  error,
  style,
}: FreightInformationHazmatFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: HAZMAT_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${HAZMAT_NAME}-field`}
    >
      <Field.Label id={`label-${HAZMAT_NAME}`}>{HAZMAT_LABEL}</Field.Label>
      <ToggleGroup
        aria-labelledby={`label-${HAZMAT_NAME}`}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [HAZMAT_NAME]: String(get(event, 'target.value')) === YES_VALUE,
            },
          })
        }}
        value={shipment[HAZMAT_NAME] ? YES_VALUE : NO_VALUE}
        type="single-strict"
        disabled={disabled}
      >
        <Toggle value={NO_VALUE}>No</Toggle>
        <Toggle value={YES_VALUE}>Yes</Toggle>
      </ToggleGroup>
      <Field.Hint {...hintProps} />
    </Field>
  )
}
