import { Field, ToggleGroup } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { useMemo } from 'react'
import type { CSSProperties, ReactNode } from 'react'

import type { TransientShippingItem } from './ShippingItems.types'
import { useShippingItemFormField } from './ShippingItemsForm'
import type { UseShippingItemProps } from './ShippingItemsForm'

const usePropertiesValue = (item: TransientShippingItem) => {
  return useMemo(() => {
    return [
      item.stackable ? 'stackable' : undefined,
      item.hazmat ? 'hazmat' : undefined,
      item.turnable ? 'turnable' : undefined,
    ].filter((value) => value !== undefined)
  }, [item.stackable, item.hazmat, item.turnable])
}

export const ShippingItemProperties = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: UseShippingItemProps['hint']
  readonly style?: CSSProperties
  readonly children: ReactNode
}) => {
  const { required, hint, style, children } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    dispatch,
  } = useShippingItemFormField({ name: 'properties', hint })
  const value = usePropertiesValue(item)

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Properties</Field.Label>

      <ToggleGroup
        type="multiple"
        {...controlProps}
        value={value}
        onChange={(event) => {
          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: ((event.target.value ?? []) as string[]).reduce(
                (properties, currentValue) => {
                  return {
                    ...properties,
                    [currentValue]: true,
                  }
                },
                { stackable: false, hazmat: false, turnable: false }
              ),
            },
          })
        }}
      >
        {children}
      </ToggleGroup>
      <Field.Hint {...hintProps} />
    </Field>
  )
}
