export const loadScript = ({
  src,
  id,
  referrerPolicy,
  onLoad,
}: {
  src: HTMLScriptElement['src']
  id?: HTMLScriptElement['id']
  referrerPolicy?: HTMLScriptElement['referrerPolicy']
  onLoad?: GlobalEventHandlers['onload']
}): HTMLScriptElement => {
  const newScript = document.createElement('script')
  newScript.async = true
  newScript.src = src

  if (id) {
    newScript.id = id
  }

  if (referrerPolicy) {
    newScript.referrerPolicy = referrerPolicy
  }

  if (onLoad) {
    newScript.onload = onLoad
  }

  document.body.appendChild(newScript)

  return newScript
}
