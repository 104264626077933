import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { SummaryLabelValue } from 'components/Summary'
import type { HazmatClass, HazmatPackingGroup } from 'utils/types'

import {
  useShippingItemSummaryContext,
  hasHazmat,
} from '../ShippingItemsManager'
import {
  HAZMAT_CLASSES_MAP,
  HAZMAT_PACKING_GROUP_MAP,
} from './HazmatInformation.constants'

export function HazmatItemSummaryClass(props: {
  readonly style?: CSSProperties
  readonly hazmatClass?: HazmatClass | null
}) {
  const { hazmatClass, ...rest } = props

  return (
    <SummaryLabelValue
      label="Hazmat class"
      data-testid="hazmat_class-summary"
      {...rest}
      value={hazmatClass ? (HAZMAT_CLASSES_MAP.get(hazmatClass) ?? '-') : '-'}
    />
  )
}

const ContextBasedHazmatItemSummaryClass = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useShippingItemSummaryContext()

  if (!hasHazmat(item)) {
    return null
  }

  const hazmatClass = get(item, 'hazmat_class')

  return <HazmatItemSummaryClass hazmatClass={hazmatClass} {...props} />
}

export function HazmatItemSummaryPackingGroup(props: {
  readonly style?: CSSProperties
  readonly packingGroup?: HazmatPackingGroup | null
}) {
  const { packingGroup, ...rest } = props

  return (
    <SummaryLabelValue
      label="Packing group"
      data-testid="hazmat_packing_group-summary"
      {...rest}
      value={
        packingGroup ? (HAZMAT_PACKING_GROUP_MAP.get(packingGroup) ?? '-') : '-'
      }
    />
  )
}

const ContextBasedHazmatItemSummaryPackingGroup = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useShippingItemSummaryContext()

  if (!hasHazmat(item)) {
    return null
  }

  const packingGroup = get(item, 'hazmat_packing_group')

  return (
    <HazmatItemSummaryPackingGroup packingGroup={packingGroup} {...props} />
  )
}

export function HazmatItemSummaryShippingName(props: {
  readonly style?: CSSProperties
  readonly properShippingName?: string | null
}) {
  const { properShippingName, ...rest } = props

  return (
    <SummaryLabelValue
      label="Proper shipping name"
      data-testid="hazmat_proper_shipping_name-summary"
      {...rest}
      value={properShippingName || '-'}
    />
  )
}

const ContextBasedHazmatItemSummaryShippingName = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useShippingItemSummaryContext()

  if (!hasHazmat(item)) {
    return null
  }

  const properShippingName = get(item, 'hazmat_proper_shipping_name')

  return (
    <HazmatItemSummaryShippingName
      properShippingName={properShippingName}
      {...props}
    />
  )
}

export function HazmatItemSummaryUNNumber(props: {
  readonly style?: CSSProperties
  readonly unNumber?: string | null
}) {
  const { unNumber, ...rest } = props

  return (
    <SummaryLabelValue
      label="UN number"
      data-testid="hazmat_un_number-summary"
      {...rest}
      value={unNumber || '-'}
    />
  )
}

const ContextBasedHazmatItemSummaryUNNumber = (props: {
  readonly style?: CSSProperties
}) => {
  const item = useShippingItemSummaryContext()

  if (!hasHazmat(item)) {
    return null
  }

  const unNumber = get(item, 'hazmat_un_number') ?? get(item, 'un_number')

  return <HazmatItemSummaryUNNumber unNumber={unNumber} {...props} />
}

export const HazmatItemSummary = {
  Class: ContextBasedHazmatItemSummaryClass,
  PackingGroup: ContextBasedHazmatItemSummaryPackingGroup,
  ShippingName: ContextBasedHazmatItemSummaryShippingName,
  UNNumber: ContextBasedHazmatItemSummaryUNNumber,
}
