import type { ListOrder, Order } from 'orders/types'

const FULFILLABLE_STATUSES: Order['status'][] = ['open', 'planning']
const CLOSABLE_STATUSES: Order['status'][] = ['planning']
const UNCLOSABLE_STATUSES: Order['status'][] = ['closed']

export function canCreateFulfillmentFromOrder(order: Order | ListOrder) {
  return FULFILLABLE_STATUSES.includes(order.status)
}

export function canCreateFulfillmentFromOrders(
  orders: (Order | ListOrder | undefined)[] = []
) {
  return (
    orders.length > 0 &&
    !orders
      .map((order) => (order ? canCreateFulfillmentFromOrder(order) : false))
      .includes(false)
  )
}

export function canCloseOrder(order: Order | ListOrder) {
  return CLOSABLE_STATUSES.includes(order.status)
}

export function canUndoCloseOrder(order: Order | ListOrder) {
  return UNCLOSABLE_STATUSES.includes(order.status)
}
