import { defaultTo } from 'lodash-es'
import { useCallback } from 'react'
import type { Column } from 'react-table'

import { Table } from 'components/TableV4'
import {
  FulfillmentsFiltersProvider,
  useFulfillmentsFiltersContext,
} from 'fulfillments/components/Filters'
import { AppliedFiltersBar } from 'fulfillments/components/Filters.components'
import { getHasAppliedFilters } from 'fulfillments/components/Filters.utils'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { useGoToFulfillmentDetails } from 'fulfillments/hooks/navigation'
import { useFulfillmentList } from 'fulfillments/hooks/useFulfillmentList'

import { COMMON_COLUMNS } from './components/ListFulfillmentsColumns'
import {
  CustomerCell,
  NotesCell,
  SupplierActionsCell,
} from './components/ListFulfillmentsTableCells'
import {
  ListFulfillmentsTableEmptyState,
  ListFulfillmentsTableEmptyStateWithFilters,
} from './components/ListFulfillmentsTableEmptyState'

const SUPPLIER_COLUMNS: ReadonlyArray<Column<ListFulfillment>> = [
  { id: 'customer', Header: 'Customer', Cell: CustomerCell },
  ...COMMON_COLUMNS,
  { Header: 'Notes', id: 'notes', Cell: NotesCell },
  { Header: '', id: 'row_actions', Cell: SupplierActionsCell, width: '50px' },
]

type FulfillmentsTableProps = ReturnType<typeof useFulfillmentList> & {
  columns: ReadonlyArray<Column<ListFulfillment>>
}

function FulfillmentsTable({
  columns,
  currentPage,
  data,
  filters,
  setFilters,
  clearFilters,
  isLoading,
  onPageChange,
  onSearchChange,
  pageSize,
}: Readonly<FulfillmentsTableProps>) {
  const goToFulfillmentDetails = useGoToFulfillmentDetails()
  const { openFilters } = useFulfillmentsFiltersContext()
  const onRowClick = useCallback(
    (fulfillment: ListFulfillment) => {
      goToFulfillmentDetails(fulfillment.uuid)
    },
    [goToFulfillmentDetails]
  )
  const hasAppliedFilters = getHasAppliedFilters(filters)

  if (!isLoading && !hasAppliedFilters && !data?.results?.length) {
    return <ListFulfillmentsTableEmptyState />
  }

  return (
    <Table<ListFulfillment>
      tableStyle={{ height: '100%' }}
      appliedFiltersBar={
        <AppliedFiltersBar
          clearFilters={clearFilters}
          setFilters={setFilters}
          filters={filters}
        />
      }
      columns={columns}
      count={data?.results?.length}
      data={defaultTo(data?.results, [])}
      emptyState={<ListFulfillmentsTableEmptyStateWithFilters />}
      isLoading={!!isLoading}
      onOpenFilters={openFilters}
      onPageChange={onPageChange}
      onRowClick={onRowClick}
      page={currentPage}
      pageSize={pageSize}
      onSearchInputChange={onSearchChange}
      searchFilter={defaultTo(filters.search, '')}
      showResultsCount={false}
      totalCount={data?.count}
      uniqueIDfield="uuid"
    />
  )
}

export function ListFulfillmentsAsSupplier() {
  const asSupplierList = useFulfillmentList('supplier')
  const { filters, clearFilters, setFilters } = asSupplierList

  return (
    <FulfillmentsFiltersProvider
      view="supplier"
      clearFilters={clearFilters}
      filters={filters}
      setFilters={setFilters}
    >
      <FulfillmentsTable {...asSupplierList} columns={SUPPLIER_COLUMNS} />
    </FulfillmentsFiltersProvider>
  )
}
