import * as Sentry from '@sentry/browser'
import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { closeOrder, undoCloseOrder } from 'orders/order-service'

export function useCloseOrder(
  orderId: string,
  callback?: (orderId: string) => void
) {
  const { isLoading: isClosing, mutate } = useMutation({
    mutationFn: closeOrder,
    onSuccess() {
      toast.success('Order successfully closed.')
      callback?.(orderId)
    },
    onError(error) {
      Sentry.captureException(error)
      toast.error('Order could not be closed.')
    },
  })

  const confirm = useCallback(() => {
    mutate(orderId)
  }, [mutate, orderId])

  return {
    confirm,
    isClosing,
  }
}

export function useUndoCloseOrder(
  orderId: string,
  callback?: (orderId: string) => void
) {
  const { isLoading: isUnclosing, mutate } = useMutation({
    mutationFn: undoCloseOrder,
    onSuccess() {
      toast.success('Order close undone.')
      callback?.(orderId)
    },
    onError(error) {
      Sentry.captureException(error)
      toast.error('Order close could not be undone.')
    },
  })

  const confirm = useCallback(() => {
    mutate(orderId)
  }, [mutate, orderId])

  return {
    confirm,
    isUnclosing,
  }
}
