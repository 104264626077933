import { createElement, isValidElement } from 'react'
import type { ComponentType, PropsWithChildren } from 'react'
import type { RouteProps } from 'react-router-dom'
import { Redirect, Route } from 'react-router-dom'

import { userUtils } from '_shared_/user'
import { appRoutes } from 'router/app-routes'
import * as ErrorScreens from 'screens/ErrorScreens'
import { SHIPPER_HOME } from 'utils/constants'

export interface PrivateRouteProps extends RouteProps {
  readonly component?: ComponentType<PropsWithChildren<unknown>>
  readonly enabled?: boolean
}

const Login = ({ path }: { readonly path: string }) => {
  return <Redirect to={`${appRoutes.login}?next=${path || SHIPPER_HOME}`} />
}

export default function PrivateRoute({
  component,
  enabled,
  render,
  children,
  ...rest
}: PrivateRouteProps) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!userUtils.isLoggedIn) {
          return <Login path={props.location.pathname} />
        }

        if (enabled === false) {
          return <ErrorScreens.NotFound />
        }

        if (component) {
          return createElement(component)
        }

        if (render) {
          return render(props)
        }

        if (isValidElement(children)) {
          return children
        }
        return <ErrorScreens.NotFound />
      }}
    />
  )
}
