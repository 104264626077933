import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { defaultTo } from 'lodash-es'

import type { GetOrdersFilters } from 'orders/hooks/useOrdersFiltersQueryParamPlugin'
import { formatOrderDateFilters } from 'orders/orders.utils'
import type { Filters } from 'utils/filters'

export function removeItem(
  filters: Filters<GetOrdersFilters>,
  field: keyof GetOrdersFilters,
  itemIndex: number
) {
  if (!Array.isArray(filters[field])) {
    return
  }

  return filters[field].filter((_, valIndex) => valIndex !== itemIndex)
}

export type SelectStatusEvent = GenericOption | GenericOption[] | null
export function updateStatus(statuses: SelectStatusEvent) {
  if (!Array.isArray(statuses) || !statuses) {
    return null
  }

  return statuses.map((item) => String(item.value))
}

export type DateRangeValue = [string | null, string | null]
export function updateDateRange(
  name: string,
  dateRange: DateRangeValue | null
) {
  if (!Array.isArray(dateRange)) {
    return
  }

  const [date_after, date_before] = dateRange

  if (name === 'pickup_date_range') {
    return {
      pickup_date_after: formatOrderDateFilters(defaultTo(date_after, null)),
      pickup_date_before: formatOrderDateFilters(defaultTo(date_before, null)),
    }
  }

  return {
    delivery_date_after: formatOrderDateFilters(defaultTo(date_after, null)),
    delivery_date_before: formatOrderDateFilters(defaultTo(date_before, null)),
  }
}
