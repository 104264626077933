import { isEmpty, isEqual } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useSettings } from '_shared_/settings/useSettings'
import type { FulfillmentDetails } from 'fulfillments/details/ViewFulfillmentPage.data'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import {
  getFulfillmentsVolume,
  getFulfillmentsWeight,
} from 'fulfillments/fulfillment-utils'
import { useFulfillmentsDetails } from 'fulfillments/hooks/useFulfillmentsDetails'
import { useTruckMileage } from 'orders/hooks/useTruckMileage'
import type { TruckMileageStop } from 'orders/order-service'
import { appRoutes } from 'router/app-routes'
import {
  ORDER_GROUPING_MULTIPLE_OPTION,
  ORDER_GROUPING_SINGLE_OPTION,
} from 'screens/Orders/ManualConsolidation/useSelectedOrders'

import type {
  FulfillmentsPlan,
  FulfillmentsPlanStop,
} from '../PlanFulfillmentsPage.types'
import { getStops } from './useFulfillmentsPlan.utils'
import { usePlanFulfillmentsQPState } from './usePlanFulfillmentsQPState'

export function mapPlanToNewShipmentConsolidationParamsString(
  fulfillments: FulfillmentDetails[],
  plan: FulfillmentsPlan,
  isMultipleAsDefaultEnabled: boolean
): string {
  const fulfillmentsUUIDs = fulfillments.map((order) => order.uuid)
  const stopParams = plan.stops.reduce((acc, curr, index) => {
    return {
      ...acc,
      [`stop_facility_${index}`]: curr.facilityUUID,
      [`stop_type_${index}`]: curr.stopType,
    }
  }, {})
  const params = {
    ...stopParams,
    consolidation_mode: isMultipleAsDefaultEnabled
      ? ORDER_GROUPING_MULTIPLE_OPTION
      : ORDER_GROUPING_SINGLE_OPTION,
  }
  const searchParams = new URLSearchParams(params)
  fulfillmentsUUIDs.forEach((uuid) => searchParams.append('fulfillments', uuid))
  return searchParams.toString()
}

export function mapPlanPreviewStopToTruckMileageStop(
  stop?: FulfillmentsPlanStop
): TruckMileageStop {
  if (!isEmpty(stop?.latitude) && !isEmpty(stop?.longitude)) {
    return {
      latitude: Number(stop?.latitude),
      longitude: Number(stop?.longitude),
    }
  }

  return {
    postal_code: !isEmpty(stop?.zipcode) ? stop?.zipcode : null,
  }
}

export function getFulfillmentsPlan(
  fulfillments: FulfillmentDetails[],
  orderedFacilities: string[]
): FulfillmentsPlan {
  const totalWeight = getFulfillmentsWeight(fulfillments)
  const totalVolume = getFulfillmentsVolume(fulfillments)

  const { stops, stopsMap, warnings } = getStops(
    fulfillments,
    orderedFacilities
  )

  return {
    stops,
    stopsMap,
    totalVolume,
    totalWeight,
    warnings,
  }
}

export function useFulfillmentsPlan(
  initialPendingFulfillments: ListFulfillment[] | undefined
) {
  const history = useHistory()
  const {
    addSelectedFulfillments,
    clearQPState,
    orderedFacilitiesUUIDs,
    removeSelectedFulfillments,
    reorderFacility,
    setFacilities,
    setSelectedFulfillments,
    selectedFulfillmentsUUIDs,
  } = usePlanFulfillmentsQPState()

  // re-loading selected fulfillments details in case the QPs point to fulfillments not loaded
  // by the list endpoint, due to the page size limit
  const {
    isLoading: isLoadingSelectedFulfillments,
    isError,
    fulfillments: selectedFulfillments,
  } = useFulfillmentsDetails(selectedFulfillmentsUUIDs)

  useEffect(() => {
    if (isError) {
      toast.error('Failed to load selected fulfillments details')
    }
  }, [isError])

  // Remove selected fulfillments from options
  const selectableFulfillments = useMemo(
    () =>
      initialPendingFulfillments
        ? initialPendingFulfillments.filter(
            (fulfillment) =>
              !selectedFulfillmentsUUIDs.includes(fulfillment.uuid)
          )
        : [],
    [initialPendingFulfillments, selectedFulfillmentsUUIDs]
  )

  const canStartShipment = selectedFulfillments.length > 0

  // Generate plan
  const plan = useMemo(() => {
    return getFulfillmentsPlan(selectedFulfillments, orderedFacilitiesUUIDs)
  }, [orderedFacilitiesUUIDs, selectedFulfillments])

  const {
    values: [isMultipleAsDefaultEnabled],
  } = useSettings(['flags.ORDER_CONSOLIDATION_MULTIPLE_GROUPING_DEFAULT'])
  const onStartShipmentClick = useCallback(() => {
    const planSearchParams = mapPlanToNewShipmentConsolidationParamsString(
      selectedFulfillments,
      plan,
      isMultipleAsDefaultEnabled
    )

    history.push(`${appRoutes.newShipment}?${planSearchParams}`)
  }, [history, isMultipleAsDefaultEnabled, plan, selectedFulfillments])

  // Update QP
  // If there was no initial facilities QP (first order being added)
  useEffect(() => {
    const newStops = plan.stops.map((stop) => stop.facilityUUID)
    if (!isEqual(orderedFacilitiesUUIDs, newStops)) {
      setFacilities(newStops)
    }
  }, [orderedFacilitiesUUIDs, setFacilities, plan.stops])

  const {
    data: truckMileage,
    isLoading: isLoadingTruckMileage,
    refetch: getTruckMileage,
  } = useTruckMileage(plan.stops.map(mapPlanPreviewStopToTruckMileageStop))

  useEffect(() => {
    if (plan.stops.length >= 2) {
      getTruckMileage()
    }
  }, [getTruckMileage, plan.stops])

  return {
    addSelectedFulfillments,
    canStartShipment,
    clearQPState,
    isLoadingSelectedFulfillments,
    isLoadingTruckMileage,
    onStartShipmentClick,
    orderedFacilitiesUUIDs,
    plan,
    removeSelectedFulfillments,
    reorderFacility,
    setFacilities,
    setSelectedFulfillments,
    selectableFulfillments,
    selectedFulfillmentsUUIDs,
    selectedFulfillments,
    truckMileage,
  }
}
