import { Select } from '@loadsmart/loadsmart-ui'
import { Text } from '@loadsmart/miranda-react'
import type {
  LocationSelectProps,
  FetchLocationOptions,
} from '@loadsmart/react-location-select'

import { LocationSelect } from 'components/LocationSelect'
import analytics from 'utils/analytics'

function CreateFacilityOption() {
  return (
    <Select.CreatableOption>
      <Text variant="body-md-bold" color="color-text-link">
        Address not found, add manually
      </Text>
    </Select.CreatableOption>
  )
}

const LOCATION_SELECT_CONFIG: FetchLocationOptions = {
  types: ['address', 'geocode', 'establishment'],
  restrictions: { country: ['us', 'ca', 'mx'] },
}

const CUSTOM_COMPONENTS = {
  CreatableOption: CreateFacilityOption,
}

export function AddressSearch(props: Partial<LocationSelectProps>) {
  const { onCreate, ...rest } = props

  return (
    <LocationSelect
      name="addressSearch"
      placeholder="Insert facility address"
      isValidNewOption
      createOptionPosition="first"
      components={CUSTOM_COMPONENTS}
      config={LOCATION_SELECT_CONFIG}
      {...rest}
      onCreate={(query) => {
        analytics.track('Facility Management / Address not found')
        onCreate?.(query)
      }}
    />
  )
}
