import type { ReactNode } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { useDebounce } from 'use-debounce'

import { useModal } from 'hooks/useModal'
import { useUnrankedLanes } from 'hooks/useQuery'
import {
  defaultLaneTableAwardedParams,
  defaultLaneTableParams,
} from 'rfp/components/table/laneParams'
import type { UploadLanesSuccessResponse } from 'rfp/rfp-details/types'
import type { RequestForProposal } from 'rfp/rfp.types'
import { recommendedCarriers } from 'services/recommendedCarriers'
import { RFP_STATE } from 'utils/constants'

import SideBar from './components/Sidebar'
import RankArchived from './rfp-state/RankArchived'
import RankDraft from './rfp-state/RankDraft'
import RankFinalized from './rfp-state/RankFinalized'
import RankPublished from './rfp-state/RankPublished'

interface AwardScenariosProps {
  readonly rfp: RequestForProposal
  readonly remainingRfpQuota: number
  readonly carrierInvitesMaxQuota: number
  readonly addLanesButton: ReactNode
  readonly importLanesButton: ReactNode
  readonly showLaneProposals: (lane: Lane) => void
  readonly addLanesButtonProps: {
    onClick: () => void
    disabled: boolean
  }
  readonly laneCsvTemplateButtonProps: {
    onClick: () => void
    disabled: boolean
    isDownloading: boolean
  }
  readonly invites: NewCarrierInvite[]
  readonly refetchCarrierInvites: () => Promise<unknown>
  readonly isLoadingCarrierInvites: boolean
  readonly carrierInviteStatus: string
  readonly onSuccessUploadLane: (lanes: UploadLanesSuccessResponse) => void
  readonly onErrorUploadLane: (start?: number, errorType?: string) => void
}

const Rank = ({
  rfp,
  remainingRfpQuota,
  carrierInvitesMaxQuota,
  addLanesButton,
  importLanesButton,
  showLaneProposals,
  addLanesButtonProps,
  laneCsvTemplateButtonProps,
  invites = [],
  carrierInviteStatus,
  refetchCarrierInvites,
  isLoadingCarrierInvites,
  onErrorUploadLane,
  onSuccessUploadLane,
}: AwardScenariosProps) => {
  const routingGuideSidebar = useModal()
  const findCarriersSidebar = useModal()
  const [selectedLane, setSelectedLane] = useState<Lane>()
  const [selectedLaneId, setSelectedLaneId] = useState<string | number>()
  const [recommendedCarriersData, setRecommendedCarriersData] =
    useState<RecommendedCarrier[]>()
  const [carriersRecommendationsError, setCarriersRecommendationsError] =
    useState<boolean>(false)
  const {
    mutate: recommendedCarriersMutation,
    isLoading: isLoadingRecommendedCarriers,
  } = useMutation({
    mutationFn: recommendedCarriers,
    onSuccess(data) {
      setCarriersRecommendationsError(false)
      setRecommendedCarriersData(data)
    },
    onError() {
      setCarriersRecommendationsError(true)
    },
  })

  const [unrankedLanesParams, setUnrankedLanesParams] = useState(
    defaultLaneTableParams
  )
  const [unrankedLanesSearchQuery, setUnrankedLanesSearchQuery] = useState('')
  const [debouncedUnrankedLanesSearchQuery] = useDebounce(
    unrankedLanesSearchQuery,
    450
  )

  const [rankedLanesParams, setRankedLanesParams] = useState(
    defaultLaneTableAwardedParams
  )
  const [rankedLanesSearchQuery, setRankedLanesSearchQuery] = useState('')

  const [rankAutomationParams, setRankAutomationParams] = useState(
    defaultLaneTableParams
  )

  const unrankedLanesQuery = useUnrankedLanes(
    rfp.id,
    unrankedLanesParams.limit,
    unrankedLanesParams.offset,
    unrankedLanesParams.sort,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError() {
        toast.error('Could not retrieve the lanes, please contact an admin')
      },
    },
    debouncedUnrankedLanesSearchQuery,
    unrankedLanesParams.filters
  )

  const setLaneSearchQuery = useCallback(
    (query: string) => {
      setUnrankedLanesParams({
        ...unrankedLanesParams,
        page: defaultLaneTableParams.page,
        offset: defaultLaneTableParams.offset,
      })
      setUnrankedLanesSearchQuery(query)
    },
    [unrankedLanesParams]
  )

  const showLaneDetailsOrRoutingGuide = (lane: Lane) => {
    if (rfp.state === RFP_STATE.DRAFT) {
      showLaneProposals(lane)
      return
    }

    setSelectedLane(lane)

    // this setSelectedLaneId is a escape hatch to update the selectedLane in useEffect above
    // without triggering an inifite loop. We only need this for Routing Guide,
    // this is why we don't use it before showLaneProposals
    setSelectedLaneId(lane.id)

    unrankedLanesQuery.refetch()

    routingGuideSidebar.openModal()
  }

  const showRecommendedCarriersSidebar = (lane: Lane) => {
    setSelectedLane(lane)
    setSelectedLaneId(lane.id)

    recommendedCarriersMutation(lane.id)
    findCarriersSidebar.openModal()
  }

  const resetParamsAndRefetchQueries = () => {
    setUnrankedLanesParams(defaultLaneTableParams)
    setUnrankedLanesSearchQuery('')
    setRankedLanesParams(defaultLaneTableAwardedParams)
    setRankedLanesSearchQuery('')
    setRankAutomationParams(defaultLaneTableParams)
  }
  const queryClient = useQueryClient()

  useEffect(() => {
    // Since we also update data using WebSocket, we also need to update the selected lane
    // in order to reflect the new data in RoutingGuide
    if (selectedLaneId) {
      let updatedLane = undefined

      const lanesDataSource = [
        unrankedLanesQuery.data?.results,
        queryClient.getQueryData<PaginatedResult>(['primaryCarriersLanes'], {
          exact: false,
        })?.results,
      ]

      for (const dataSource of lanesDataSource) {
        updatedLane = dataSource?.find(
          (lane: Lane) => lane.id === selectedLaneId
        )

        if (updatedLane) {
          break
        }
      }

      // since this effect can happen between various loading states
      //  we want to only update the selectLane if we have new data ready
      if (updatedLane) {
        setSelectedLane({ ...updatedLane })
      }
    }
  }, [unrankedLanesQuery.data, selectedLaneId, queryClient])

  if (rfp.state === RFP_STATE.DRAFT) {
    return (
      <>
        <SideBar
          carriersRecommendationsError={carriersRecommendationsError}
          findCarriersSidebar={findCarriersSidebar}
          invites={invites}
          isLoadingCarrierInvites={isLoadingCarrierInvites}
          isLoadingRecommendedCarriers={isLoadingRecommendedCarriers}
          recommendedCarriersData={recommendedCarriersData}
          refetchCarrierInvites={refetchCarrierInvites}
          resetParamsAndRefetchQueries={resetParamsAndRefetchQueries}
          rfp={rfp}
          routingGuideSidebar={routingGuideSidebar}
          selectedLane={selectedLane}
        />
        <RankDraft
          rfp={rfp}
          invites={invites}
          carrierInviteStatus={carrierInviteStatus}
          refetchCarrierInvites={refetchCarrierInvites}
          isLoadingCarrierInvites={isLoadingCarrierInvites}
          query={unrankedLanesQuery}
          setLaneSearchQuery={setLaneSearchQuery}
          unrankedLanesSearchQuery={unrankedLanesSearchQuery}
          addLanesButtonProps={addLanesButtonProps}
          laneCsvTemplateButtonProps={laneCsvTemplateButtonProps}
          showLaneDetailsOrRoutingGuide={showLaneDetailsOrRoutingGuide}
          showRecommendedCarriersSidebar={showRecommendedCarriersSidebar}
          params={unrankedLanesParams}
          setParams={setUnrankedLanesParams}
          carrierInvitesMaxQuota={carrierInvitesMaxQuota}
          remainingRfpQuota={remainingRfpQuota}
          addLanesButton={addLanesButton}
          importLanesButton={importLanesButton}
          onErrorUploadLane={onErrorUploadLane}
          onSuccessUploadLane={onSuccessUploadLane}
        />
      </>
    )
  }

  if (rfp.state === RFP_STATE.PUBLISHED || rfp.state === RFP_STATE.CLOSED) {
    return (
      <>
        <SideBar
          carriersRecommendationsError={carriersRecommendationsError}
          findCarriersSidebar={findCarriersSidebar}
          invites={invites}
          isLoadingCarrierInvites={isLoadingCarrierInvites}
          isLoadingRecommendedCarriers={isLoadingRecommendedCarriers}
          recommendedCarriersData={recommendedCarriersData}
          refetchCarrierInvites={refetchCarrierInvites}
          resetParamsAndRefetchQueries={resetParamsAndRefetchQueries}
          rfp={rfp}
          routingGuideSidebar={routingGuideSidebar}
          selectedLane={selectedLane}
        />
        <RankPublished
          params={unrankedLanesParams}
          rfp={rfp}
          setParams={setUnrankedLanesParams}
          showLaneDetailsOrRoutingGuide={showLaneDetailsOrRoutingGuide}
          showRecommendedCarriersSidebar={showRecommendedCarriersSidebar}
          unrankedLanesQuery={unrankedLanesQuery}
          carrierInviteStatus={carrierInviteStatus}
          invites={invites}
          isLoadingCarrierInvites={isLoadingCarrierInvites}
          refetchCarrierInvites={refetchCarrierInvites}
          carrierInvitesMaxQuota={carrierInvitesMaxQuota}
          remainingRfpQuota={remainingRfpQuota}
          unrankedLanesSearchQuery={unrankedLanesSearchQuery}
          setUnrankedLanesSearchQuery={setUnrankedLanesSearchQuery}
          rankedLanesParams={rankedLanesParams}
          rankedLanesSearchQuery={rankedLanesSearchQuery}
          setRankedLanesSearchQuery={setRankedLanesSearchQuery}
          rankAutomationParams={rankAutomationParams}
          setRankAutomationParams={setRankAutomationParams}
          setRankedLanesParams={setRankedLanesParams}
          resetParamsAndRefetchQueries={resetParamsAndRefetchQueries}
        />
      </>
    )
  }

  if (rfp.state === RFP_STATE.FINALIZED) {
    return (
      <>
        <SideBar
          carriersRecommendationsError={carriersRecommendationsError}
          findCarriersSidebar={findCarriersSidebar}
          invites={invites}
          isLoadingCarrierInvites={isLoadingCarrierInvites}
          isLoadingRecommendedCarriers={isLoadingRecommendedCarriers}
          recommendedCarriersData={recommendedCarriersData}
          refetchCarrierInvites={refetchCarrierInvites}
          resetParamsAndRefetchQueries={resetParamsAndRefetchQueries}
          rfp={rfp}
          routingGuideSidebar={routingGuideSidebar}
          selectedLane={selectedLane}
        />
        <RankFinalized
          carrierInviteStatus={carrierInviteStatus}
          invites={invites}
          isLoadingCarrierInvites={isLoadingCarrierInvites}
          refetchCarrierInvites={refetchCarrierInvites}
          rfp={rfp}
          showLaneDetailsOrRoutingGuide={showLaneDetailsOrRoutingGuide}
          showRecommendedCarriersSidebar={showRecommendedCarriersSidebar}
          carrierInvitesMaxQuota={carrierInvitesMaxQuota}
          remainingRfpQuota={remainingRfpQuota}
        />
      </>
    )
  }

  return (
    <>
      <SideBar
        carriersRecommendationsError={carriersRecommendationsError}
        findCarriersSidebar={findCarriersSidebar}
        invites={invites}
        isLoadingCarrierInvites={isLoadingCarrierInvites}
        isLoadingRecommendedCarriers={isLoadingRecommendedCarriers}
        recommendedCarriersData={recommendedCarriersData}
        refetchCarrierInvites={refetchCarrierInvites}
        resetParamsAndRefetchQueries={resetParamsAndRefetchQueries}
        rfp={rfp}
        routingGuideSidebar={routingGuideSidebar}
        selectedLane={selectedLane}
      />
      <RankArchived
        carrierInviteStatus={carrierInviteStatus}
        invites={invites}
        isLoadingCarrierInvites={isLoadingCarrierInvites}
        refetchCarrierInvites={refetchCarrierInvites}
        rfp={rfp}
        showLaneDetailsOrRoutingGuide={showLaneDetailsOrRoutingGuide}
        showRecommendedCarriersSidebar={showRecommendedCarriersSidebar}
        carrierInvitesMaxQuota={carrierInvitesMaxQuota}
        remainingRfpQuota={remainingRfpQuota}
      />
    </>
  )
}

export default Rank
