import { isEmpty } from 'lodash-es'

import type { StopAppointment } from 'screens/Shipper/Shipments/Details/types'

import { AppointmentOutOfTime } from './AppointmentOutOfTime'
import { getMTCAppointmentStatus } from './utils'

type MTCAppointmentStatusProps = {
  readonly appointment: StopAppointment
}

export default function MTCAppointmentOutOfTime({
  appointment,
}: MTCAppointmentStatusProps) {
  const status = getMTCAppointmentStatus(appointment)

  if (isEmpty(status)) {
    return null
  }

  return <AppointmentOutOfTime appointment={appointment} />
}
