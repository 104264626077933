import { Field, TextField } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash-es'

import type { Transient } from 'utils/transient'

import type {
  CustomFormFieldProps,
  TransientHandlingUnitOrderItem,
} from '../HandlingUnits.types'
import { useOrderItemFormField } from './OrderItemForm.hooks'

export function formatLabel(item: Transient<TransientHandlingUnitOrderItem>) {
  let label = `Shipped count for ${item.order.primary_ref} item #${item.line_number}`

  if (!isEmpty(item.item_description)) {
    label += ` ${item.item_description}`
  }

  if (!isEmpty(item.item_number)) {
    label += ` (item number ${item.item_number})`
  }

  return label
}

export const OrderItemShippedPackageCount = (props: CustomFormFieldProps) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    labelProps,
    value,
    setValue,
    item,
  } = useOrderItemFormField({ name: 'shipped_package_count', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>{formatLabel(item)}</Field.Label>
      <TextField
        {...controlProps}
        placeholder="e.g. 2"
        type="number"
        min="1"
        inputMode="numeric"
        value={value || ''}
        onChange={(event) => {
          const {
            target: { value: newValue },
          } = event

          setValue(Number(newValue))
        }}
        style={{ width: '75px' }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
