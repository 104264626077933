import { isEmpty, noop } from 'lodash-es'
import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { AuditLogsDrawer } from 'components/AuditLogsDrawer'

export interface FulfillmentHistoryContextValue {
  closeDrawer: () => void
  openDrawer: (id?: string) => void
  uuid?: string
}

export const FulfillmentHistoryContext =
  createContext<FulfillmentHistoryContextValue>({
    closeDrawer: noop,
    uuid: undefined,
    openDrawer: noop,
  })

export function useFulfillmentHistoryContext() {
  return useContext(FulfillmentHistoryContext)
}

function FulfillmentHistoryDrawer() {
  const { closeDrawer, uuid } = useFulfillmentHistoryContext()

  if (!uuid || isEmpty(uuid)) {
    return null
  }

  return (
    <AuditLogsDrawer
      isOpen
      kind="fulfillment"
      onClose={closeDrawer}
      queryOptions={{ enabled: true }}
      title="Fulfillment History"
      uuid={uuid}
    />
  )
}

export function FulfillmentHistoryProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const [uuid, setUUID] = useState<string | undefined>()

  const closeDrawer = useCallback(() => {
    setUUID(undefined)
  }, [setUUID])
  const openDrawer = useCallback(
    (id?: string) => {
      setUUID(id)
    },
    [setUUID]
  )

  const context = useMemo(
    () => ({ closeDrawer, openDrawer, uuid }),
    [closeDrawer, openDrawer, uuid]
  )

  return (
    <FulfillmentHistoryContext.Provider value={context}>
      {children}
      <FulfillmentHistoryDrawer />
    </FulfillmentHistoryContext.Provider>
  )
}
