import { IconClose, IconInfoCircle } from '@loadsmart/icons'
import { Label, Layout, Select, Tag, Text } from '@loadsmart/loadsmart-ui'
import { Button } from '@loadsmart/miranda-react'
import { useEffect, useRef, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

import { Title } from 'components/CarrierDetails/styles'
import { CloseSidebarWrapper, TitleContainer } from 'components/SideBar/styles'
import { usePreventPageScrollOver } from 'hooks/usePreventPageScrollOver'
import {
  useCarriersEntities,
  useCarriersOperationRegions,
  useCarriersTags,
} from 'hooks/useQuery'
import {
  useProposalCarrierInviteStates,
  useProposalsEquipmentTypes,
} from 'rfp/hooks/proposals'
import {
  Container,
  InnerContainerScroll,
} from 'rfp/rfp-details/components/routing-guide/styles'
import { theme } from 'styles/theme'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import * as storage from 'utils/localStorage'

const DONT_SHOW_CARRIER_FILTER_AGAIN_KEY = 'dont-show-carrier-filter-again'

interface CarrierFiltersProps {
  readonly onClose: () => void
  readonly apply: Dispatch<SetStateAction<Filters>>
  readonly clear: () => void
  readonly tableFilters: Filters
  readonly rfpId: string | number
}

const Footer = styled.div`
  background: ${({ theme: loadsmartTheme }) =>
    loadsmartTheme.colors.backgroundWhiteNeutral};
  bottom: 0;
  box-shadow: ${({ theme: loadsmartTheme }) => loadsmartTheme.shadows.shadow01};
  padding: 24px;
  width: 600px;
`

const BoldLink = styled(Text)`
  cursor: pointer;
  font-weight: bold;
  padding-left: 28px;
  text-decoration: underline;
`

interface FilterOption {
  value: string
  label: string
  _type?: string
}

export interface Filters {
  entity: FilterOption[]
  equipment: FilterOption[]
  tags: FilterOption[]
  region: FilterOption[]
  responseRate: FilterOption | null
  carrierInviteStates: FilterOption[]
}

const defaulFilters: Filters = {
  entity: [],
  equipment: [],
  tags: [],
  region: [],
  responseRate: null,
  carrierInviteStates: [],
}

const FilterTags = ({
  values,
  onRemove,
}: {
  readonly values: FilterOption | FilterOption[]
  readonly onRemove: (value: FilterOption) => void
}) => {
  if (!Array.isArray(values)) {
    return (
      <Tag
        leading={null}
        onRemove={() => onRemove(values)}
        removable
        size="default"
        variant="default"
        key={values.label}
      >
        {values.label}
      </Tag>
    )
  }

  return (
    <Layout.Group space="s">
      {values.map((value: any) => {
        return (
          <Tag
            leading={null}
            onRemove={() => onRemove(value)}
            removable
            size="default"
            variant="default"
            key={value.label}
          >
            {value.label}
          </Tag>
        )
      })}
    </Layout.Group>
  )
}

const GenericFilterTags = ({
  filters,
  filterName,
  onRemove,
}: {
  readonly filters: Filters
  readonly filterName: keyof Filters
  readonly onRemove: (value: FilterOption) => void
}) => {
  const filterValues = filters[filterName]
  return (
    filterValues && <FilterTags values={filterValues} onRemove={onRemove} />
  )
}

const CarrierFilters = ({
  onClose,
  apply,
  clear,
  tableFilters,
  rfpId,
}: CarrierFiltersProps) => {
  const [filters, setFilters] = useState<Filters>(defaulFilters)
  const [dontShowHint, setDontShowHint] = useState(
    storage.get(DONT_SHOW_CARRIER_FILTER_AGAIN_KEY) ?? false
  )
  const scrollerRef = useRef<HTMLDivElement>(null)
  usePreventPageScrollOver(scrollerRef)

  useEffect(() => setFilters(tableFilters), [tableFilters])

  const { data: entity } = useCarriersEntities()
  const { data: regions } = useCarriersOperationRegions()
  const { tags } = useCarriersTags()
  const { data: equipmentTypes } = useProposalsEquipmentTypes()
  const { data: carrierInviteStates } = useProposalCarrierInviteStates(rfpId)

  const onChangeFilters = (type: string, value: FilterOption) => {
    setFilters({ ...filters, [type]: value })
  }

  const removeFilter = (type: keyof Filters, value: FilterOption) => {
    if (type !== 'responseRate') {
      setFilters({
        ...filters,
        [type]:
          filters[type]?.filter((item) => item.value !== value.value) ?? [],
      })
      return
    }

    setFilters({ ...filters, responseRate: null })
  }

  return (
    <Container>
      <TitleContainer>
        <Title>Apply Filters</Title>
        <CloseSidebarWrapper onClick={onClose}>
          <IconClose width={16} height={16} />
        </CloseSidebarWrapper>
      </TitleContainer>
      <InnerContainerScroll ref={scrollerRef}>
        <Layout.Stack space="s">
          {!dontShowHint && (
            <Layout.Box
              padding="l"
              background="neutral-white"
              borderColor="neutral-lighter"
              borderWidth="thin"
            >
              <Layout.Stack>
                <Layout.Group space="s">
                  <IconInfoCircle
                    width={20}
                    height={20}
                    fill={theme.colors.neutral}
                  />
                  <Text variant="heading-sm-bold" color="color-neutral-darker">
                    How do filters work?
                  </Text>
                </Layout.Group>
                <Text
                  variant="body"
                  color="color-neutral-darker"
                  style={{ paddingLeft: '28px' }}
                >
                  Filters help you select the right carriers for your bid by
                  only displaying carriers based on your selection. For example,
                  if you select type Asset, all Brokers will be cleared from the
                  list.
                </Text>
                <BoldLink
                  color="color-neutral-darkest"
                  variant="link"
                  onClick={() => {
                    storage.set(DONT_SHOW_CARRIER_FILTER_AGAIN_KEY, true)
                    setDontShowHint(true)
                  }}
                >
                  Don&apos;t show this again
                </BoldLink>
              </Layout.Stack>
            </Layout.Box>
          )}
          <Layout.Box
            padding="l"
            background="neutral-white"
            borderColor="neutral-lighter"
            borderWidth="thin"
          >
            <Layout.Stack space="l">
              <Layout.Stack space="xs">
                <Label htmlFor="filter-entity">Type</Label>
                <Layout.Stack space="s">
                  <Select
                    multiple
                    placeholder="Select Type"
                    id="filter-entity"
                    name="filter-entity"
                    options={
                      entity?.map((item: any) => {
                        return {
                          value: item.value,
                          label: `${item.label} (${item.count})`,
                        }
                      }) ?? []
                    }
                    value={filters.entity}
                    onChange={(event) => {
                      onChangeFilters(
                        'entity',
                        (event.target.value as FilterOption) ?? []
                      )
                    }}
                  />
                  <GenericFilterTags
                    filters={filters}
                    filterName="entity"
                    onRemove={(value: FilterOption) =>
                      removeFilter('entity', value)
                    }
                  />
                </Layout.Stack>
              </Layout.Stack>

              <Layout.Stack space="xs">
                <Label htmlFor="filter-equipment">Equipment</Label>
                <Layout.Stack space="s">
                  <Select
                    multiple
                    placeholder="Select Equipment"
                    id="filter-equipment"
                    name="filter-equipment"
                    options={
                      equipmentTypes?.map((equipment: any) => {
                        if (equipment.value !== 'None') {
                          return {
                            value: equipment.value,
                            label: `${equipment.value} (${equipment.count})`,
                          }
                        }

                        return {
                          value: equipment.value,
                          label: `${equipment.label} (${equipment.count})`,
                        }
                      }) ?? []
                    }
                    value={filters.equipment}
                    onChange={(event) =>
                      onChangeFilters(
                        'equipment',
                        (event.target.value as FilterOption) ?? []
                      )
                    }
                  />
                  <GenericFilterTags
                    filters={filters}
                    filterName="equipment"
                    onRemove={(value: FilterOption) =>
                      removeFilter('equipment', value)
                    }
                  />
                </Layout.Stack>
              </Layout.Stack>

              <Layout.Stack space="xs">
                <Label htmlFor="filter-tags">Tags</Label>
                <Layout.Stack space="s">
                  <Select
                    multiple
                    placeholder="Select Tags"
                    id="filter-tags"
                    name="filter-tags"
                    options={
                      tags?.map((tag) => {
                        return {
                          value: tag.value,
                          label: `${tag.label} (${tag.count})`,
                        }
                      }) ?? []
                    }
                    onChange={(event) =>
                      onChangeFilters(
                        'tags',
                        (event.target.value as FilterOption) ?? []
                      )
                    }
                    value={filters.tags}
                  />
                  <GenericFilterTags
                    filters={filters}
                    filterName="tags"
                    onRemove={(value: FilterOption) =>
                      removeFilter('tags', value)
                    }
                  />
                </Layout.Stack>
              </Layout.Stack>

              <Layout.Stack space="xs">
                <Label htmlFor="filter-region">Region</Label>
                <Layout.Stack space="s">
                  <Select
                    multiple
                    placeholder="Select Region"
                    id="filter-region"
                    name="filter-region"
                    options={
                      regions?.map((region: any) => {
                        return {
                          value: region.value,
                          label: `${region.label} (${region.count})`,
                        }
                      }) ?? []
                    }
                    onChange={(event) =>
                      onChangeFilters(
                        'region',
                        (event.target.value as FilterOption) ?? []
                      )
                    }
                    value={filters.region}
                  />
                  <GenericFilterTags
                    filters={filters}
                    filterName="region"
                    onRemove={(value: FilterOption) =>
                      removeFilter('region', value)
                    }
                  />
                </Layout.Stack>
              </Layout.Stack>

              <Layout.Stack space="xs">
                <Label htmlFor="filter-carrier-invite-states">
                  Carrier Invite Status
                </Label>
                <Layout.Stack space="s">
                  <Select
                    multiple
                    placeholder="Select Type"
                    id="filter-carrier-invite-states"
                    name="filter-carrier-invite-states"
                    options={
                      carrierInviteStates?.map((inviteStates: any) => {
                        return {
                          value: inviteStates.value,
                          label: `${inviteStates.label} (${inviteStates.count})`,
                        }
                      }) ?? []
                    }
                    value={filters.carrierInviteStates}
                    onChange={(event) => {
                      onChangeFilters(
                        'carrierInviteStates',
                        (event.target.value as FilterOption) ?? []
                      )
                    }}
                  />
                  <GenericFilterTags
                    filters={filters}
                    filterName="carrierInviteStates"
                    onRemove={(value: FilterOption) =>
                      removeFilter('carrierInviteStates', value)
                    }
                  />
                </Layout.Stack>
              </Layout.Stack>
            </Layout.Stack>
          </Layout.Box>
        </Layout.Stack>
      </InnerContainerScroll>
      <Footer>
        <Layout.Group space="s" justify="flex-start">
          <Button
            variant="primary"
            onClick={() => {
              analytics.track(
                'Award Scenarios / Carrier Filter',
                AnalyticsEventTrigger.click,
                {
                  entity: filters.entity.length > 0,
                  equipment: filters.equipment.length > 0,
                  tags: filters.tags.length > 0,
                  region: filters.region.length > 0,
                  responseRate: Boolean(filters.responseRate?.value),
                  carrierInviteStates: filters.region.length > 0,
                }
              )
              apply(filters)
            }}
          >
            Apply filters
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button
            onClick={clear}
            style={{ marginLeft: 'auto' }}
            variant="tertiary"
          >
            Clear filters
          </Button>
        </Layout.Group>
      </Footer>
    </Container>
  )
}

export default CarrierFilters
