import { useEffect, useState } from 'react'

import { loadScript } from '_shared_/utils/load-script'
import { noop } from 'utils/noop'

interface ScriptLoaderProps {
  readonly src: string
  readonly onLoad?: () => void
}

const ScriptLoader = ({ src, onLoad }: ScriptLoaderProps) => {
  const [script, setScript] = useState<HTMLScriptElement | null>(null)

  useEffect(() => {
    const newScript = loadScript({ src })

    setScript(newScript)

    return () => {
      document.body.removeChild(newScript)
    }
  }, [src])

  useEffect(() => {
    if (!script || !onLoad) {
      return noop
    }

    script.addEventListener('load', onLoad)

    return () => {
      script.removeEventListener('load', onLoad)
    }
  }, [script, onLoad])

  return null
}

export default ScriptLoader
