import { Layout, Text } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash-es'
import styled from 'styled-components'

import { FulfillmentHandlingUnitOrdersList } from 'fulfillments/components/FulfillmentHandlingUnitOrdersList'
import { FulfillmentLink } from 'fulfillments/components/FulfillmentLink'
import type { FulfillmentDetails } from 'fulfillments/details/ViewFulfillmentPage.data'
import {
  formatFulfillmentHandlingUnitMaxTemperature,
  formatFulfillmentHandlingUnitTitle,
  formatHandlingUnitPONumbers,
  formatHandlingUnitSONumbers,
  formatHandlingUnitTotalShippedWeight,
} from 'fulfillments/fulfillment-utils'

import type { FulfillmentsPlanStop } from '../PlanFulfillmentsPage.types'

const HandlingUnitCardWrapper = styled(Layout.Box).attrs({
  backgroundColor: 'color-background-secondary',
  borderColor: 'color-border-input',
  borderWidth: 'border-thin',
})`
  max-width: 300px;
`

export function PlanFulfillmentsPlanPreviewStopCardHUs({
  fulfillment,
  pickupStop,
  showFulfillment = false,
}: Readonly<{
  fulfillment: FulfillmentDetails
  pickupStop?: FulfillmentsPlanStop
  showFulfillment?: boolean
}>) {
  return fulfillment.handling_units.map((handlingUnit) => (
    <HandlingUnitCardWrapper
      key={handlingUnit.uuid}
      data-testid={`stop-card-delivery-item-fulfillment-${fulfillment.ref_number}-${handlingUnit.uuid}`}
    >
      <Layout.Stack gap="spacing-1" padding="none">
        {pickupStop && (
          <Text color="color-text-tertiary" variant="body-sm">
            {`From ${pickupStop.stopLabel.toLowerCase()}`}
          </Text>
        )}

        <Text variant="body-md-bold">
          {formatFulfillmentHandlingUnitTitle(handlingUnit)}
        </Text>

        <Layout.Group align="center" gap="spacing-0-5">
          <Text color="color-text-tertiary" variant="body-sm">
            Order
          </Text>
          <Text color="color-primary-100" variant="body-sm-bold">
            <FulfillmentHandlingUnitOrdersList handlingUnit={handlingUnit} />
          </Text>
        </Layout.Group>

        <Layout.Group align="center" gap="spacing-0-5">
          <Text color="color-text-tertiary" variant="body-sm">
            PO
          </Text>
          <Text variant="body-sm">
            {formatHandlingUnitPONumbers(handlingUnit)}
          </Text>
        </Layout.Group>

        <Layout.Group align="center" gap="spacing-0-5">
          <Text color="color-text-tertiary" variant="body-sm">
            SO
          </Text>
          <Text variant="body-sm">
            {formatHandlingUnitSONumbers(handlingUnit)}
          </Text>
        </Layout.Group>

        {showFulfillment && (
          <Layout.Group align="center" gap="spacing-0-5">
            <Text color="color-text-tertiary" variant="body-sm">
              Fulfillment
            </Text>
            <Text variant="body-sm">
              <FulfillmentLink fulfillment={fulfillment} />
            </Text>
          </Layout.Group>
        )}

        <Layout.Group align="center" gap="spacing-0-5">
          <Text variant="body-sm">
            {formatHandlingUnitTotalShippedWeight(handlingUnit)}
          </Text>
          {!isEmpty(handlingUnit.temperatureReqs.max_temperature) && (
            <>
              <Text color="color-text-secondary-inverted">&bull;</Text>
              <Text variant="body-sm">
                {formatFulfillmentHandlingUnitMaxTemperature(handlingUnit)}
              </Text>
            </>
          )}
        </Layout.Group>
      </Layout.Stack>
    </HandlingUnitCardWrapper>
  ))
}
