import { Select } from '@loadsmart/loadsmart-ui'
import type { SelectDatasource } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type { FieldProps } from '@loadsmart/miranda-react'
import { Field } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties, ReactNode } from 'react'

import { AsyncTerminalDatasource } from 'screens/NewQuote/DrayageQuote/DrayageQuote.datasources'
import type { FacilityDetailsV2, FacilityV2 } from 'services/facilities'
import type { Transient } from 'utils/transient'

import type { FacilityOption } from './StopFacility.helpers'
import {
  CustomTerminalOption,
  FACILITY_ADAPTER,
  facilityToFacilityDetails,
} from './StopFacility.helpers'
import type { TransientStop } from './Stops.types'
import type { UseStopFacilityParams } from './useStopFacility'
import { useStopFormField } from './useStopFormField'
import type { UseStopFormFieldProps } from './useStopFormField'

const CUSTOM_COMPONENTS = {
  Option: CustomTerminalOption,
}

type StopTerminalProps = Readonly<{
  label?: ReactNode
  required?: FieldProps['required']
  hint?: UseStopFormFieldProps['hint']
  error?: UseStopFormFieldProps['error']
  style?: CSSProperties
  disabled?: boolean
  stopType?: StopType
  onCreateFacility?: () => void
  addable?: boolean
  datasource?: () => SelectDatasource<FacilityV2>
  customerUUID?: string
}> &
  Pick<
    UseStopFacilityParams,
    'initialFacilityValues' | 'onFacilitySaved' | 'selectFirstContactByDefault'
  >

export const StopTerminal = (props: StopTerminalProps) => {
  const { label, required, hint, error, style, disabled } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    labelProps,
    name,
    stop,
    dispatch,
  } = useStopFormField({ name: 'terminal', hint, error })

  function getValue() {
    const facility = get<
      Transient<TransientStop>,
      string,
      FacilityDetailsV2 | null
    >(stop, name, null)

    if (facility) {
      return {
        label: FACILITY_ADAPTER.getLabel(facility),
        value: FACILITY_ADAPTER.getKey(facility),
      } as Selectable
    }
    return null
  }

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>{label ?? `Stop #${index}`}</Field.Label>
      <Select
        disabled={disabled}
        getInputProps={() => controlProps}
        placeholder="Enter port or rail yard"
        name={name}
        datasources={[AsyncTerminalDatasource]}
        components={CUSTOM_COMPONENTS}
        value={getValue()}
        onChange={(event) => {
          const selectedFacility = get(
            event,
            'target.value',
            null
          ) as FacilityOption | null

          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: selectedFacility
                  ? {
                      ...facilityToFacilityDetails(selectedFacility),
                      _type: 'facility',
                    }
                  : null,
                notes: null,
                contact: null,
              },
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
