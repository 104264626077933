import { Dialog, LoadingDots, Text } from '@loadsmart/loadsmart-ui'
import { useQueryClient } from 'react-query'
import type { QueryClient } from 'react-query'

import type { RequestForProposal } from 'rfp/rfp.types'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { RFP_STATE } from 'utils/constants'

import { SpinnerWrapper } from '../../styles'

interface Props {
  readonly rfp: RequestForProposal
  readonly isOpen: boolean
  readonly isPublishing: boolean
  readonly onClose: () => void
  readonly publishRFP: (variables: {
    id: string | number
    payload: Partial<RequestForProposal>
    queryClient: QueryClient
  }) => void
  readonly lanesCount: number
  readonly rfpLaneDetails: { mode: string; multimode: boolean }
}

export default function PublishDialogV2({
  rfp,
  isOpen,
  isPublishing,
  onClose,
  publishRFP,
  lanesCount = 0,
  rfpLaneDetails,
}: Props) {
  const queryClient = useQueryClient()

  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Header>Publish to Carriers</Dialog.Header>
      <Dialog.Body>
        <Text variant="body" color="color-neutral-dark">
          You are about to publish your RFP
          <br />
          with <b>{`${lanesCount} ${lanesCount === 1 ? 'lane' : 'lanes'}`}</b>.
        </Text>
        <br />
        <br />
        <Text variant="body" color="color-neutral-dark">
          Do you want to continue?
        </Text>
      </Dialog.Body>
      <Dialog.ActionConfirm
        onConfirm={() => {
          analytics.track(
            'RFP / Publish / Confirmation',
            AnalyticsEventTrigger.click,
            { ...rfp, ...rfpLaneDetails }
          )
          publishRFP({
            id: rfp.id,
            payload: { state: RFP_STATE.PUBLISHED },
            queryClient,
          })
        }}
        disabled={isPublishing}
      >
        <SpinnerWrapper>
          {isPublishing ? (
            <LoadingDots variant="light" />
          ) : (
            <>Publish to carriers</>
          )}
        </SpinnerWrapper>
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={onClose} />
    </Dialog>
  )
}
