import { Dialog } from '@loadsmart/miranda-react'

import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'

import OneClickPlanFulfillmentConfirmationContent from './OneClickPlanFulfillment.dialogConfirmation'
import OneClickPlanFulfillmentResultContent from './OneClickPlanFulfillment.dialogResult'
import { useAutoCreateShipmentsDialogState } from './OneClickPlanFulfillment.dialogState'

export type OneClickPlanFulfillmentDialogProps = Readonly<{
  fulfillments: ListFulfillment[]
  isOpen?: boolean
  onClose: () => void
}>

export default function OneClickPlanFulfillmentDialog({
  fulfillments,
  isOpen,
  onClose,
}: OneClickPlanFulfillmentDialogProps) {
  const {
    canProceed,
    hasConfirmed,
    mode,
    mutations,
    mutationsCompleted,
    onConfirm,
    setMode,
  } = useAutoCreateShipmentsDialogState(fulfillments)

  if (!isOpen || fulfillments.length === 0) {
    return null
  }

  const count = fulfillments.length

  return (
    <Dialog open={isOpen} size="medium" onClose={onClose}>
      <Dialog.Header>
        Auto create shipments <Dialog.Close />
      </Dialog.Header>
      {hasConfirmed ? (
        <OneClickPlanFulfillmentResultContent
          fulfillments={fulfillments}
          mutations={mutations.current}
          mutationsCompleted={mutationsCompleted}
          onClose={onClose}
        />
      ) : (
        <OneClickPlanFulfillmentConfirmationContent
          canProceed={canProceed}
          count={count}
          mode={mode}
          onClose={onClose}
          onConfirm={onConfirm}
          setMode={setMode}
        />
      )}
    </Dialog>
  )
}
