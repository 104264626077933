import { isEqual } from 'lodash-es'
import { useCallback, useEffect } from 'react'

import { useCurrentUser } from '_shared_/user/useCurrentUser'
import useLocalStorageState from 'hooks/useLocalStorageState'
import type { Filters, FiltersPluginHook } from 'utils/filters'
import { useFilters } from 'utils/filters'

export function usePersistedFilters<FiltersType>(
  storageKey: string,
  initialValue: FiltersType,
  ...plugins: FiltersPluginHook<FiltersType>[]
) {
  const { user } = useCurrentUser()

  const [persistedFilters, setPersistedFilters] = useLocalStorageState<
    Filters<FiltersType>
  >(`${storageKey}-${user?.user_uuid}`, {
    initialValue,
  })

  const {
    setFilters,
    setFilter,
    values: filters,
  } = useFilters<FiltersType>(
    {
      initialValues: {
        ...initialValue,
      },
    },
    ...plugins
  )

  const clearFilters = useCallback(() => {
    setFilters({
      ...initialValue,
    })
  }, [initialValue, setFilters])

  // back up the filters from the query params whenever they change
  useEffect(() => {
    if (!isEqual(persistedFilters, filters)) {
      setPersistedFilters(filters)
    }
  }, [filters, persistedFilters, setPersistedFilters])

  // sets the saved filters in localStorage as query params
  const restoreFilters = useCallback(() => {
    setFilters(persistedFilters)
  }, [persistedFilters, setFilters])

  return {
    clearFilters,
    filters,
    restoreFilters,
    setFilter,
    setFilters,
  }
}
