import { createTransientStop } from 'components/StopsManager'
import type { ListOrder } from 'orders/types'
import { formatDateUTC } from 'utils/dateUtils'

import type { TransientFulfillment } from './Fulfillment'

export function mapFulfillmentFromOrder(
  order?: ListOrder,
  asSupplier: boolean = false
): Partial<TransientFulfillment> | undefined {
  if (!order) {
    return undefined
  }

  const pickupDate = order.pickup_window_start
    ? formatDateUTC(order.pickup_window_start, 'MM/DD/YYYY')
    : undefined
  const deliveryDate = order.delivery_window_start
    ? formatDateUTC(order.delivery_window_start, 'MM/DD/YYYY')
    : undefined

  const owner = asSupplier
    ? { customer_uuid: order.customer?.uuid }
    : { supplier_uuid: order.supplier?.uuid }

  return {
    order_identifier_type: 'orders',
    pickup_facility_uuid: order.pickup_facility_uuid,
    delivery_facility_uuid: order.delivery_facility_uuid,
    ...owner,
    stops: [
      createTransientStop({
        date: pickupDate,
      }),
      createTransientStop({
        date: deliveryDate,
      }),
    ],
  }
}
