import { useDialog } from '@loadsmart/loadsmart-ui'
import { get, isEmpty, noop } from 'lodash-es'
import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useSettings } from '_shared_/settings/useSettings'
import type { OrderValidationResult } from 'orders/order-service'
import { useValidateOrders } from 'orders/orders.hooks'
import type { ListOrder } from 'orders/types'
import { appRoutes } from 'router/app-routes'

import { ORDER_GROUPING_MULTIPLE_OPTION } from '../ManualConsolidation/useSelectedOrders'
import { ValidationDialog } from './SimpleConsolidation.components'

export interface SimpleConsolidationContextValue {
  isEnabled: boolean
  selectedOrders: ListOrder[]
  isOpen: boolean
  removeOrder: (uuid: string | string[]) => void
  closeDialog: () => void
  openDialog: (orders: ListOrder[]) => void
  continueShipmentCreation: () => void
  validationResult: any
  isLoadingValidation: boolean
}

export const DEFAULT_SIMPLE_CONSOLIDATION_CONTEXT: SimpleConsolidationContextValue =
  {
    isEnabled: false,
    selectedOrders: [],
    isOpen: false,
    removeOrder: noop,
    closeDialog: noop,
    openDialog: noop,
    validationResult: {},
    isLoadingValidation: false,
    continueShipmentCreation: noop,
  }

export const SimpleConsolidationContext =
  createContext<SimpleConsolidationContextValue>(
    DEFAULT_SIMPLE_CONSOLIDATION_CONTEXT
  )

export function useSimpleOrderConsolidation() {
  const history = useHistory()
  const {
    values: [isSimpleConsolidationEnabled],
  } = useSettings(['flags.ENABLE_SIMPLE_CONSOLIDATION'])
  const { hide, open, show } = useDialog({ open: false })
  const [selectedOrders, setSelectedOrders] = useState<ListOrder[]>([])
  const {
    data: validationResult,
    isLoading: isLoadingValidation,
    isError,
  } = useValidateOrders(
    selectedOrders.map((order) => order.uuid),
    open
  )

  const closeDialog = useCallback(() => {
    hide()
  }, [hide])

  const openDialog = useCallback(
    (orders: ListOrder[]) => {
      setSelectedOrders(orders)
      show()
    },
    [setSelectedOrders, show]
  )

  const removeOrder = useCallback(
    (uuidToRemove: string | string[]) => {
      let filterUIIDs: string[] = []
      if (!Array.isArray(uuidToRemove)) {
        filterUIIDs = [uuidToRemove]
      }
      setSelectedOrders((prev) => {
        return prev.filter((order) => !filterUIIDs.includes(order.uuid))
      })
    },
    [setSelectedOrders]
  )

  const continueShipmentCreation = useCallback(() => {
    if (!isEmpty(validationResult) && !isEmpty(selectedOrders)) {
      const params = mapValidatedConsolidationToNewShipment(
        selectedOrders,
        validationResult
      )
      history.push(`${appRoutes.newShipment}?${params}`)
    }
  }, [validationResult])

  useEffect(() => {
    if (isError) {
      toast.error('Failed to validate consolidation plan.')
    }
  }, [isError])

  return {
    isEnabled: isSimpleConsolidationEnabled,
    selectedOrders,
    isOpen: open,
    removeOrder,
    closeDialog,
    openDialog,
    validationResult,
    isLoadingValidation,
    continueShipmentCreation,
  }
}

export function SimpleConsolidationProvider({
  children,
}: Readonly<PropsWithChildren>) {
  const contextValue = useSimpleOrderConsolidation()

  return (
    <SimpleConsolidationContext.Provider value={contextValue}>
      {children}
      <ValidationDialog />
    </SimpleConsolidationContext.Provider>
  )
}

export function useSimpleConsolidationContext() {
  return useContext(SimpleConsolidationContext)
}

function mapValidatedConsolidationToNewShipment(
  orders: ListOrder[],
  validationResult: OrderValidationResult
): string {
  const orderUUIDs = orders.map((order) => order.uuid)
  const stopParams = {
    stop_facility_0: get(validationResult, 'stops[0].facility_uuid', ''),
    stop_facility_1: get(validationResult, 'stops[1].facility_uuid', ''),
    stop_type_0: 'pickup',
    stop_type_1: 'delivery',
  }
  const params = {
    ...stopParams,
    consolidation_mode: ORDER_GROUPING_MULTIPLE_OPTION,
  }
  const searchParams = new URLSearchParams(params)
  orderUUIDs.forEach((uuid) => searchParams.append('selected', uuid))
  return searchParams.toString()
}
