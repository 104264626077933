import { Layout } from '@loadsmart/miranda-react'
import styled from 'styled-components'

import PageIndex from 'components/PageIndex'

const Side = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    content-visibility: hidden;
  }
`

export function FormLayout({
  indexValues,
  children,
}: {
  readonly indexValues: { id: string; label: string }[]
  readonly children: React.ReactNode
}) {
  return (
    <Layout.Group
      gap="spacing-12"
      justify="center"
      style={{ flexWrap: 'nowrap' }}
    >
      <Side />
      {children}
      <Side>
        <PageIndex values={indexValues} />
      </Side>
    </Layout.Group>
  )
}
