import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { useFulfillmentFormContext } from 'fulfillments/components/FulfillmentFormContext'
import { validate } from 'fulfillments/domain/Fulfillment.validation'
import { putFulfillment } from 'fulfillments/fulfillment-service'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { scrollToTop } from 'utils/scroll'
import { hasTransientError } from 'utils/transient'

import { adaptFulfillmentToApi } from './editFulfillment.adapter'

export function useEditFulfillmentForm() {
  const { fulfillment, setFulfillment } = useFulfillmentFormContext()
  const { mutate: saveFulfillment, status: saveStatus } = useMutation({
    mutationKey: ['updateFulfillment', fulfillment?.uuid],
    mutationFn: putFulfillment,
  })

  const handleSave = () => {
    if (!fulfillment) {
      return
    }

    const [validatedFulfillment] = validate(fulfillment)

    if (hasTransientError(validatedFulfillment)) {
      setFulfillment(validatedFulfillment)
      scrollToTop()
      return
    }

    const payload = adaptFulfillmentToApi(fulfillment)
    saveFulfillment({ uuid: fulfillment.uuid!, payload })
  }

  useEffect(() => {
    if (saveStatus === 'success') {
      toast.success('Fulfillment saved successfully')
      scrollToTop()
      analytics.track('Updated: Fulfillment', AnalyticsEventTrigger.success)
    }

    if (saveStatus === 'error') {
      toast.error('Failed to save fulfillment')
      scrollToTop()
    }
  }, [saveStatus])

  return { fulfillment, saveStatus, handleSave }
}
