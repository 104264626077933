import type { TableState } from 'react-table'

export interface CustomTableState extends TableState {
  areAllItemsSelected: boolean
}

export const ACTION_TYPES = {
  selectAllItems: 'selectAllItems',
  clearSelection: 'clearSelection',
}
