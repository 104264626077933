import {
  Dialog,
  Layout,
  Text,
  TextField,
  Button,
} from '@loadsmart/miranda-react'
import { indexOf } from 'lodash-es'

import type {
  NewCustomFieldModalProps,
  DeleteCustomFieldModalProps,
} from './types'

export const NewCustomFieldModal = ({
  isModalOpen,
  modeLabel,
  newCustomField,
  setNewCustomField,
  isSubmitting,
  handleCloseModal,
  addNewExtraField,
}: NewCustomFieldModalProps) => {
  const handleNewFieldUpdate = (event: any) => {
    setNewCustomField({
      mode: modeLabel,
      field: event.target.value,
    })
  }

  return (
    <Dialog open={isModalOpen}>
      <Dialog.Header>Add new {modeLabel} field</Dialog.Header>
      <Dialog.Body>
        <Layout.Stack gap="spacing-6">
          <Text style={{ textAlign: 'center' }}>
            Add up to 5 extra fields to complement your lane information to be
            displayed to invited carriers.
          </Text>
          <Text variant="body-md">
            Field Name <span style={{ color: 'red' }}>*</span>
          </Text>
          <TextField
            value={newCustomField}
            onInput={handleNewFieldUpdate}
            id="new-custom-field-input"
            data-testid={`new-custom-field-input-${modeLabel}`}
            placeholder="New field name"
          />
          <Button
            disabled={!newCustomField || isSubmitting}
            onClick={addNewExtraField}
            variant="primary"
          >
            Save New Field
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={handleCloseModal}
            variant="tertiary"
          >
            Cancel
          </Button>
        </Layout.Stack>
      </Dialog.Body>
    </Dialog>
  )
}

export const DeleteCustomFieldModal = ({
  isModalOpen,
  fieldToRemove,
  currentExtraFields,
  isSubmitting,
  mode,
  handleCloseModal,
  removeExtraField,
}: DeleteCustomFieldModalProps) => {
  const removeField = () => {
    const fieldIndex = indexOf(currentExtraFields[mode].headers, fieldToRemove)
    const modeFields = currentExtraFields[mode].headers
    const newHeaders = [
      ...modeFields.slice(0, fieldIndex),
      ...modeFields.slice(fieldIndex + 1),
    ]
    removeExtraField({ ...currentExtraFields, [mode]: { headers: newHeaders } })
  }

  return (
    <Dialog open={isModalOpen}>
      <Dialog.Header>Delete: {fieldToRemove}</Dialog.Header>
      <Dialog.Body>
        <Layout.Stack gap="spacing-6">
          <Text style={{ textAlign: 'center' }}>
            Deleting the field{' '}
            <Text variant="body-md-bold">({fieldToRemove})</Text> will erase
            this field on all lanes on this bid. Data associated with this field
            will also be erased. This action cannot be undone.
          </Text>
          <Button
            disabled={isSubmitting}
            onClick={removeField}
            variant="danger"
          >
            Delete field
          </Button>
          <Button
            disabled={isSubmitting}
            onClick={handleCloseModal}
            variant="tertiary"
          >
            Cancel
          </Button>
        </Layout.Stack>
      </Dialog.Body>
    </Dialog>
  )
}
