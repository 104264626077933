import { createIndexProvider } from 'contexts/indexer'

import { OrderItemsTransientCollectionForm } from './OrderItemForm.hooks'
import { OrderItemShippedPackageCount } from './OrderItemShippedPackageCount'

export const OrderItemsForm = OrderItemsTransientCollectionForm

export const OrderItemForm = Object.assign(createIndexProvider(), {
  ShippedPackageCount: OrderItemShippedPackageCount,
})
