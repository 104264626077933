import { Card, Divider, Icon, Layout, Tooltip } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-tokens'
import { get, isEmpty } from 'lodash-es'
import { useCallback, useEffect } from 'react'

import { useSettings } from '_shared_/settings/useSettings'
import { FacilitySummary } from 'components/FacilityDetails'
import type { TransientFreightInformation } from 'components/FreightInformation'
import { FreightInformationForm } from 'components/FreightInformation'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { hasHazmat } from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import {
  StopForm,
  StopsForm,
  createTransientStop,
  getIsDateRequired,
} from 'components/StopsManager'
import { StopFacilitySummary } from 'components/StopsManager/StopFacilitySummary'
import {
  EQUIPMENT_TYPES,
  areEquipmentTypesEqual,
  isEquipmentTypeOneOf,
} from 'utils/equipmentTypeV2'
import {
  resolveTransportationMode,
  TRANSPORTATION_MODES,
} from 'utils/transportationMode'
import type { Mode } from 'utils/types'

import { useEquipmentTypes } from '../../../../../shipments/hooks/useEquipmentTypes'
import { useTransportationModes } from '../../../../../shipments/hooks/useTransportationModes'
import { FormLayout } from '../../components/FormLayout'
import { HandlingUnitsFormRemoveAll } from '../../components/HandlingUnit/HandlingUnitsFormRemoveAll'
import { canDeleteShipmentItemFromStop } from '../../components/Shipment'
import { StopSection } from '../../components/StopSection/StopSection'
import { ConsolidationReviewCard } from '../consolidation/components/ConsolidationReviewCard'
import { useConsolidationContext } from '../consolidation/consolidation.context'
import { useShipmentFormContext } from '../create.context'
import { useMultiStopWithHandlingUnitsShipmentFormHandlers } from '../create.hooks'
import { FORM_MAX_WIDTH, FormWrapper } from '../create.styles'
import { getShipmentFormIndexes, hasHazmatCommodities } from '../create.utils'
import { TLFormHandlingUnit } from './handlingUnit/TLFormHandlingUnit'
import { HandlingUnitsToDeliver } from './HandlingUnitsToDelivery'

export default function TruckloadForm() {
  const {
    values: [enableFTLStopOffCharges],
  } = useSettings(['flags.ENABLE_FTL_STOP_OFF_CHARGES'])
  const [shipment, setPartialShipment, setShipment] = useShipmentFormContext()
  const { consolidationType, canBeRemoved } = useConsolidationContext()

  const { handleRemoveStop, handleStopAdded, handleStopsChange } =
    useMultiStopWithHandlingUnitsShipmentFormHandlers({ setShipment })

  const { modes } = useTransportationModes()
  const { equipments } = useEquipmentTypes({ mode: shipment.mode })

  const shipmentHasHazmatCommodity = hasHazmatCommodities(shipment.items)

  useEffect(() => {
    const equipmentOptions = equipments.map(
      (equipmentOption) => equipmentOption.value as string
    )
    if (
      shipment.equipment_type &&
      !isEquipmentTypeOneOf(shipment.equipment_type, equipmentOptions)
    ) {
      setPartialShipment({ equipment_type: EQUIPMENT_TYPES.drv.value })
    }
  }, [shipment.equipment_type, setPartialShipment, equipments])

  const handleFreightInformationChange = useCallback(
    (newFreightInformation: TransientFreightInformation) => {
      setPartialShipment(newFreightInformation)
    },
    [setPartialShipment]
  )

  const handleHandlingUnitChange = (
    newHandlingUnits: TransientHandlingUnit[]
  ) => {
    const hasHazmatCommodity = hasHazmat(
      newHandlingUnits.map((item) => item.commodities).flat()
    )

    setPartialShipment({
      items: newHandlingUnits,
      hazmat: hasHazmatCommodity,
    })
  }

  const handleRemoveHandlingUnits = (stopIndex: number) => {
    const newHandlingUnits = shipment.items.filter(
      (handlingUnit) => handlingUnit.pickup_stop_index !== stopIndex
    )
    handleHandlingUnitChange(newHandlingUnits)
  }

  const stops = get(shipment, 'stops', [])
  const pickup = stops.at(0)
  const delivery = stops.at(-1)
  const intermediaryStops = stops.slice(1, stops.length - 1)
  const isReefer = shipment.equipment_type === EQUIPMENT_TYPES.rfr.abbr
  const isFlatBed = areEquipmentTypesEqual(
    shipment.equipment_type ?? '',
    EQUIPMENT_TYPES.fbe.value
  )

  return (
    <FormLayout
      indexValues={getShipmentFormIndexes({
        transientShipment: shipment,
        consolidation: consolidationType,
        withIntermediaryStops: true,
      })}
    >
      <FormWrapper
        data-testid="truckload-form"
        style={{ maxWidth: FORM_MAX_WIDTH, width: '100%' }}
      >
        <FreightInformationForm
          shipment={shipment}
          onChange={handleFreightInformationChange}
        >
          {Boolean(consolidationType) && <ConsolidationReviewCard />}
          <Card id="mode-card">
            <Card.Title>Mode and Equipment</Card.Title>
            <Card.Divider />
            <Card.Body>
              <Layout.Switcher>
                <FreightInformationForm.Mode modes={modes} required />
                <FreightInformationForm.Equipment
                  equipments={equipments}
                  required
                />
                {isFlatBed && (
                  <>
                    <FreightInformationForm.SubEquipment required />
                    <FreightInformationForm.WithTarp />
                  </>
                )}
                {shipment.with_tarp && (
                  <Layout.Grid style={{ gridTemplateColumns: '3fr 1fr' }}>
                    <FreightInformationForm.TarpType required />
                    <FreightInformationForm.TarpSize required />
                  </Layout.Grid>
                )}
              </Layout.Switcher>
            </Card.Body>
          </Card>
          <StopsForm
            stops={shipment.stops}
            createStop={createTransientStop}
            onChange={handleStopsChange}
            onAddStop={handleStopAdded}
            onDeleteStop={handleRemoveStop}
          >
            <Card id="lane-card">
              <Card.Title>Lane</Card.Title>
              <Card.Divider />
              <Card.Body>
                <Layout.Stack>
                  <StopForm key={pickup!._metadata.id} index={0}>
                    <Layout.Group data-testid="pickup-stop">
                      <StopForm.FacilityOrAddress
                        label="Pickup"
                        required
                        style={{ flex: 1 }}
                        stopType="pickup"
                      />

                      <StopForm.Date label="Pickup date" required />
                    </Layout.Group>
                  </StopForm>

                  {intermediaryStops.map((stop, index: number) => (
                    <StopForm key={stop._metadata.id} index={index + 1}>
                      <Layout.Group
                        data-testid={`stop-${index + 1}`}
                        align="flex-end"
                      >
                        <StopForm.Facility
                          label={`Stop #${index + 1}`}
                          required
                          style={{ flex: 1 }}
                          stopType={stop.stop_type as StopType}
                        />
                        <StopForm.Type />
                        <Layout.Box padding="none" paddingY="spacing-2">
                          <StopsForm.RemoveStop />
                        </Layout.Box>
                        <StopForm.Date label={`Stop #${index + 1} date`} />
                      </Layout.Group>
                    </StopForm>
                  ))}

                  <StopForm
                    key={delivery!._metadata.id}
                    index={shipment.stops.length - 1}
                  >
                    <Layout.Group data-testid="delivery-stop">
                      <StopForm.Facility
                        label="Delivery"
                        required
                        style={{ flex: 1 }}
                        stopType="delivery"
                      />

                      <StopForm.Date
                        label="Delivery date"
                        required={getIsDateRequired(
                          shipment.stops.length - 1,
                          shipment.stops.length
                        )}
                      />
                    </Layout.Group>
                  </StopForm>
                </Layout.Stack>
              </Card.Body>
              <Card.Footer>
                <StopsForm.AddStop addAt={shipment.stops.length - 1} />
              </Card.Footer>
            </Card>
            {stops.map(
              (stop, index) =>
                (Boolean(stop.facility) || Boolean(stop.location)) && (
                  <StopSection
                    key={`${stop._metadata.id}`}
                    id={`stop-${index}`}
                    stop={stop}
                    handlingUnits={shipment.items}
                    index={index}
                    totalStops={shipment.stops.length}
                    initialCollapsedState={false}
                  >
                    <StopFacilitySummary
                      stop={stop}
                      style={{
                        margin: 0,
                        borderTopWidth: 0,
                        borderBottomColor: toCSSValue('color-divider'),
                        borderBottomWidth: toCSSValue('border-thin'),
                      }}
                    />
                    <StopForm key={stop._metadata.id} index={index}>
                      <Layout.Stack gap="spacing-4" padding="spacing-6">
                        <StopForm.Contact />
                        <StopForm.Notes />
                      </Layout.Stack>
                    </StopForm>

                    {stop.stop_type == 'pickup' && (
                      <TLFormHandlingUnit
                        handlingUnits={shipment.items}
                        onHandlingUnitChange={handleHandlingUnitChange}
                        stopIndex={stop.stop_index!}
                        stops={shipment.stops}
                      />
                    )}
                    <HandlingUnitsToDeliver
                      stopIndex={stop.stop_index}
                      allItems={shipment.items}
                    />

                    {canDeleteShipmentItemFromStop(
                      shipment,
                      stop,
                      canBeRemoved
                    ) && (
                      <>
                        <Divider />
                        <Layout.Group padding="spacing-6">
                          <HandlingUnitsFormRemoveAll
                            stopIndex={index}
                            onRemove={handleRemoveHandlingUnits}
                          />
                        </Layout.Group>
                      </>
                    )}

                    {stop.facility && (
                      <>
                        <Divider />
                        <Layout.Group padding="spacing-6">
                          <FacilitySummary facility={stop.facility}>
                            <FacilitySummary.EditButton />
                          </FacilitySummary>
                        </Layout.Group>
                      </>
                    )}
                  </StopSection>
                )
            )}
          </StopsForm>
          <Card id="more-details-card" collapsible>
            <Card.Title>More details</Card.Title>
            <Card.Divider />
            <Card.Body>
              <Layout.Stack>
                <Layout.Group>
                  <FreightInformationForm.PONumber />
                  <FreightInformationForm.SONumber />
                  <FreightInformationForm.BOLNumber />
                  {isReefer && <FreightInformationForm.Temperature />}
                </Layout.Group>
                <Layout.Group>
                  <FreightInformationForm.PowerOnly />
                  <FreightInformationForm.Hazmat
                    disabled={shipmentHasHazmatCommodity}
                  />
                  <FreightInformationForm.EquipmentLength
                    mode={shipment.mode as Mode}
                  />
                  {shipment.mode ===
                    TRANSPORTATION_MODES.full_truck_load.value &&
                    enableFTLStopOffCharges && (
                      <FreightInformationForm.StopOffCharges />
                    )}
                </Layout.Group>
                <FreightInformationForm.Accessorials
                  mode={resolveTransportationMode(shipment.mode!)!}
                  stops={shipment.stops}
                />
              </Layout.Stack>
            </Card.Body>
          </Card>
          {!isEmpty(shipment.shipper_custom_fields) && (
            <Card id="custom-fields-card" collapsible>
              <Card.Title>
                <Layout.Group gap="spacing-1">
                  Custom fields
                  <Tooltip message="Custom fields information is for your internal use only and are not shared with carriers.">
                    <Icon name="question-dot" color="color-text-tertiary" />
                  </Tooltip>
                </Layout.Group>
              </Card.Title>
              <Card.Divider />
              <Card.Body>
                <FreightInformationForm.CustomFields />
              </Card.Body>
            </Card>
          )}
        </FreightInformationForm>
      </FormWrapper>
    </FormLayout>
  )
}
