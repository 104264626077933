import { PageContent } from '@loadsmart/miranda-react'

import { WindowTitle } from '_shared_/components/WindowTitle'

import { ListOrders } from './ListOrders'
import { OrdersListProvider } from './ListOrders.context'
import ListOrdersPageHeader from './ListOrdersPageHeader'

export default function ListOrdersPage() {
  return (
    <>
      <WindowTitle title="Orders" />
      <PageContent>
        <ListOrdersPageHeader />
        <PageContent.Body>
          <OrdersListProvider>
            <ListOrders />
          </OrdersListProvider>
        </PageContent.Body>
      </PageContent>
    </>
  )
}
