import type { Column } from 'react-table'

import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'

import {
  DestinationCell,
  HandlingUnitsCell,
  OrdersCell,
  OriginCell,
  StatusCell,
} from './ListFulfillmentsTableCells'

export const COMMON_COLUMNS: ReadonlyArray<Column<ListFulfillment>> = [
  {
    id: 'ref_number',
    accessor: 'ref_number',
    Header: 'Ref number',
    width: '200px',
  },
  {
    id: 'pickup_number',
    accessor: 'pickup_number',
    Header: 'Pickup number',
    width: '200px',
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Orders',
    id: 'order_uuids',
    Cell: OrdersCell,
  },
  {
    Header: 'Origin',
    id: 'pickup_facility',
    Cell: OriginCell,
  },
  {
    Header: 'Destination',
    id: 'delivery_facility',
    Cell: DestinationCell,
  },
  {
    Header: 'Handling units',
    id: 'handling_units',
    Cell: HandlingUnitsCell,
  },
]
