import { Button, Header } from '@loadsmart/miranda-react'

import { NewFulfillmentButton } from 'fulfillments/components/NewFulfillmentButton'
import useLinkToFulfillmentsPlan from 'fulfillments/plan/hooks/useLinkToFulfillmentsPlan'
import { useIsCurrentShipperASupplier } from 'suppliers/common/useIsCurrentShipperASupplier'

function PlanShipmentButton() {
  const isSupplier = useIsCurrentShipperASupplier()
  const onClick = useLinkToFulfillmentsPlan()

  if (isSupplier) {
    return null
  }

  return (
    <Button
      onClick={onClick}
      variant="secondary"
      data-testid="button-create-new-shipment"
    >
      Plan shipment
    </Button>
  )
}

export function ListFulfillmentsPageHeader() {
  return (
    <Header>
      <Header.Content>
        <Header.Title>Fulfillments</Header.Title>
      </Header.Content>

      <Header.Actions>
        <PlanShipmentButton />
        <NewFulfillmentButton />
      </Header.Actions>
    </Header>
  )
}
