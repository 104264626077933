import { createContext, useContext } from 'react'

import type { ShipmentContextValue } from './create.types'

export const ShipmentFormContext = createContext<
  ShipmentContextValue | undefined
>(undefined)

export function useShipmentFormContext() {
  const context = useContext(ShipmentFormContext)

  if (context === undefined) {
    throw new Error(
      'useNewShipmentFormContext must be used within a ShipmentFormContext.Provider'
    )
  }

  return context
}
