import type { LabeledValueProps } from '@loadsmart/miranda-react'
import {
  Card,
  Divider,
  Icon,
  LabeledValue,
  Layout,
  Tag,
  Text,
  Tooltip,
} from '@loadsmart/miranda-react'
import { Fragment } from 'react/jsx-runtime'

import { HandlingUnitDimensionsSummary } from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitDimensionsSummary'
import type { FulfillmentHandlingUnitOrderItem } from 'fulfillments/domain/Fulfillment'
import { calculateTotalDensity } from 'fulfillments/domain/Fulfillment'
import {
  formatFulfillmentHandlingUnitTitle,
  getFulfillmentVolume,
  getFulfillmentWeight,
} from 'fulfillments/fulfillment-utils'
import {
  formatFulfillmentHandlingUnitDimensions,
  formatFulfillmentHandlingUnitFreightClass,
  formatFulfillmentHandlingUnitOrderItemShippedWeightComparison,
} from 'fulfillments/presenter/handlingUnits'
import { formatOrderItemDescription } from 'orders/orders.utils'
import { defaultEmptyString } from 'utils/strings'

import { useViewFulfillmentContext } from '../ViewFulfillmentContext'
import type { FulfillmentDetailsHandlingUnit } from '../ViewFulfillmentPage.data'

function LabelValueWrappedWithOriginalValueTooltip({
  original,
  value,
  ...labeledValueProps
}: Omit<LabeledValueProps, 'value'> &
  Readonly<{
    original: string | null
    value: string | null
  }>) {
  if (defaultEmptyString(original) === defaultEmptyString(value)) {
    return (
      <LabeledValue {...labeledValueProps} value={defaultEmptyString(value)} />
    )
  }

  return (
    <LabeledValue
      {...labeledValueProps}
      value={
        <Layout.Group align="center" gap="spacing-1">
          {defaultEmptyString(value)}
          <Tooltip message={`Original value: ${defaultEmptyString(original)}`}>
            <Icon color="color-text-tertiary" name="eye" size="12px" />
          </Tooltip>
        </Layout.Group>
      }
    />
  )
}

function OrderItemCard({
  orderItem,
}: Readonly<{ orderItem: FulfillmentHandlingUnitOrderItem }>) {
  return (
    <Layout.Stack
      key={`${orderItem.order.primary_ref}-${orderItem.line_number}`}
    >
      <Text color="color-text-secondary" variant="body-md-bold">
        {formatOrderItemDescription(orderItem)}
      </Text>

      <Layout.Grid>
        <LabeledValue
          label="Order primary ref"
          value={orderItem.order.primary_ref}
        />
        <LabeledValue label="Line number" value={orderItem.line_number} />
        <LabeledValue
          label="Weight"
          value={formatFulfillmentHandlingUnitOrderItemShippedWeightComparison(
            orderItem
          )}
        />
        <LabelValueWrappedWithOriginalValueTooltip
          original={orderItem.nmfc_code}
          label="NMFC"
          value={orderItem.shipped_nmfc_code}
        />
        <LabelValueWrappedWithOriginalValueTooltip
          original={orderItem.freight_class}
          label="Freight class"
          value={orderItem.shipped_freight_class}
        />
      </Layout.Grid>
    </Layout.Stack>
  )
}

function HandlingUnitCard({
  handlingUnit,
}: Readonly<{
  handlingUnit: FulfillmentDetailsHandlingUnit
}>) {
  const weight = handlingUnit.totalShippedWeight

  return (
    <Card
      collapsible
      style={{
        '--m-card-border-left': 'none',
        '--m-card-border-right': 'none',
        '--m-card-border-top': 'none',
      }}
    >
      <Card.Title>
        <Layout.Stack>
          <Layout.Group>
            <Tag size="small" variant="neutral">
              {handlingUnit.turnable ? 'Turnable' : 'Do not turn'}
            </Tag>

            <Tag size="small" variant="neutral">
              {handlingUnit.stackable ? 'Stackable' : 'Do not stack'}
            </Tag>
          </Layout.Group>
          <Text variant="heading-sm-bold">
            {formatFulfillmentHandlingUnitTitle(handlingUnit)}
          </Text>
          <Layout.Stack gap="spacing-2">
            <Text>{`Weighing ${weight} lbs in total`}</Text>

            <Layout.Group>
              <Text color="color-text-tertiary">Dimensions</Text>
              <Text>
                {formatFulfillmentHandlingUnitDimensions(handlingUnit)}
              </Text>
            </Layout.Group>

            <Layout.Group>
              <Text color="color-text-tertiary">Freight class</Text>
              <Text>
                {formatFulfillmentHandlingUnitFreightClass(handlingUnit)}
              </Text>
            </Layout.Group>
          </Layout.Stack>
        </Layout.Stack>
      </Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Stack>
          {handlingUnit.order_items.map((orderItem, index) => (
            <Fragment
              key={`${orderItem.order.primary_ref}-${orderItem.line_number}`}
            >
              {index > 0 ? <Divider /> : null}
              <OrderItemCard orderItem={orderItem} />
            </Fragment>
          ))}
        </Layout.Stack>
      </Card.Body>
    </Card>
  )
}

export function FulfillmentHandlingUnitsCard() {
  const { fulfillment } = useViewFulfillmentContext()

  if (!fulfillment) {
    return null
  }

  const handlingUnits = fulfillment.handling_units

  const volume = getFulfillmentVolume(fulfillment)
  const weight = getFulfillmentWeight(fulfillment) ?? 0
  const density = calculateTotalDensity(weight, volume)

  return (
    <Card
      data-testid="fulfillment-handling-units-card"
      style={{ '--m-card-body-padding': '0' }}
    >
      <Card.Title>Handling Units</Card.Title>
      <Card.Subtitle>
        <HandlingUnitDimensionsSummary
          cubicDensity={density}
          cubicVolume={volume}
          handlingUnitsCount={handlingUnits.length}
          linearFeet={0}
          totalWeight={weight}
        />
      </Card.Subtitle>
      <Card.Divider />
      <Card.Body>
        {handlingUnits.map((handlingUnit, index) => (
          <Fragment key={handlingUnit.uuid}>
            {index > 0 ? <Divider /> : null}
            <HandlingUnitCard handlingUnit={handlingUnit} />
          </Fragment>
        ))}
      </Card.Body>
    </Card>
  )
}
