import { Field, ToggleGroup } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useStopFormField } from './useStopFormField'
import type { UseStopFormFieldProps } from './useStopFormField'

const STOP_TYPE_OPTIONS = [
  { value: 'delivery', label: 'DEL' },
  { value: 'pickup', label: 'PU' },
]

export const StopType = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: UseStopFormFieldProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props

  const { controlProps, fieldProps, hintProps, index, name, stop, dispatch } =
    useStopFormField({ name: 'stop_type', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <ToggleGroup
        {...controlProps}
        type="single-strict"
        options={STOP_TYPE_OPTIONS}
        value={get(stop, name)}
        onChange={(event) => {
          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: get(event, 'target.value'),
              },
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
