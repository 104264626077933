import { isEmpty } from 'lodash-es'

import type {
  FacilityAccessorial,
  FacilityContactV2,
  FacilityDetailsV2,
} from 'services/facilities'
import { getStateNameByAbbreviation, resolveCountry } from 'utils/states'
import { joinValidStrings } from 'utils/strings'

import {
  SUPPORTED_COUNTRIES_METADATA,
  DEFAULT_HOURS_OF_OPERATION,
} from './FacilityDetails.constants'
import type { FacilityHoursOfOperationByDay } from './FacilityDetails.types'

export function getCountryMetadataByAbbreviation(
  countryAbbreviation: string | null | undefined
) {
  const standardizedCountryAbbreviation = resolveCountry(countryAbbreviation)

  if (!standardizedCountryAbbreviation) {
    return null
  }

  return SUPPORTED_COUNTRIES_METADATA[standardizedCountryAbbreviation]
}

export function formatFacilityLocation({
  city,
  state,
  zipcode,
}: Nullable<Pick<FacilityDetailsV2, 'city' | 'state' | 'zipcode'>>) {
  return joinValidStrings([city, joinValidStrings([state, zipcode])], ', ')
}

export function formatFacilityLocationWithStateName({
  city,
  state,
  zipcode,
  country,
}: Nullable<
  Pick<FacilityDetailsV2, 'city' | 'state' | 'zipcode' | 'country'>
>) {
  return joinValidStrings(
    [
      city,
      joinValidStrings([
        state && country
          ? (getStateNameByAbbreviation(state, country) ?? state)
          : state,
        zipcode,
      ]),
    ],
    ', '
  )
}

export function formatFacilityAddress({
  address,
  address_details,
}: Pick<FacilityDetailsV2, 'address' | 'address_details'>) {
  return joinValidStrings([address, address_details], ', ')
}

export function formatFacilityHoursOfOperationByDay({
  hours_of_operation,
}: Nullable<
  Pick<FacilityDetailsV2, 'hours_of_operation'>
>): FacilityHoursOfOperationByDay {
  const hoursOfOperationEntries =
    !hours_of_operation || isEmpty(hours_of_operation)
      ? DEFAULT_HOURS_OF_OPERATION
      : hours_of_operation
  const hoursOfOperationByDay: FacilityHoursOfOperationByDay = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  }

  hoursOfOperationEntries.forEach((entry, index) => {
    hoursOfOperationByDay[entry.day_of_week].push({
      // this key is only used for rendering purposes
      key:
        'uuid' in entry && typeof entry.uuid === 'string'
          ? entry.uuid
          : `${entry.day_of_week}-${index}`,
      opens: entry.opens,
      closes: entry.closes,
      appointment_mode: entry.appointment_mode,
    })
  })

  return hoursOfOperationByDay
}

export function formatFacilityContactPhone({
  country,
  phone,
  extension,
}: Nullable<Pick<FacilityContactV2, 'country' | 'phone' | 'extension'>>) {
  // We have some contact entries migrated from magazzino that include the phone prefix within the phone field,
  // we should ignore the country field for those cases
  const countryMetadata = !phone?.includes('+')
    ? getCountryMetadataByAbbreviation(country)
    : null

  return joinValidStrings(
    [countryMetadata?.phonePrefix, joinValidStrings([phone, extension], '')],
    ' '
  )
}

export function getAccessorialsByModeAndStop(
  accessorials: FacilityAccessorial[]
) {
  const accessorialsByModeAndStop: Record<
    FacilityAccessorial['mode'],
    { pickup: FacilityAccessorial[]; delivery: FacilityAccessorial[] }
  > = {
    FTL: { pickup: [], delivery: [] },
    LTL: { pickup: [], delivery: [] },
    PTL: { pickup: [], delivery: [] },
  }

  accessorials
    // Filtering out possible invalid data returned from the BE
    .filter(
      (accessorial) =>
        ['FTL', 'LTL', 'PTL'].includes(accessorial.mode) &&
        ['pickup', 'delivery'].includes(accessorial.stop)
    )
    .forEach((accessorial) => {
      accessorialsByModeAndStop[accessorial.mode][accessorial.stop].push(
        accessorial
      )
    })

  return accessorialsByModeAndStop
}

export function getAccessorialsValue(accessorials: FacilityAccessorial[]) {
  if (isEmpty(accessorials)) {
    return 'No accessorials'
  }
  return accessorials.map((accessorial) => accessorial.label).join(', ')
}
