import { useMemo } from 'react'

import { useFulfillmentFormContext } from './FulfillmentFormContext'
import { FulfillmentSelect } from './FulfillmentSelect'

export function FulfillmentOrders({
  required,
}: {
  readonly required?: boolean
}) {
  const { orders, isLoadingOrders } = useFulfillmentFormContext()

  const ordersOptions = useMemo(
    () =>
      orders.map((order) => ({
        label: order.primary_ref,
        value: order.uuid,
      })),
    [orders]
  )

  return (
    <FulfillmentSelect
      name="order_uuids"
      label="Orders"
      required={required}
      placeholder="Select orders"
      loading={isLoadingOrders}
      options={ordersOptions}
      multiple
    />
  )
}
