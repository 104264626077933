import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const VESSEL_NAME = 'vessel_name'
const VESSEL_NAME_LABEL = 'Vessel name'

type FreightInformationVesselNameSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name'
>

export function FreightInformationVesselNameSummaryField(
  props: Readonly<FreightInformationVesselNameSummaryFieldProps>
) {
  return (
    <FreightInformationSummaryField
      label={VESSEL_NAME_LABEL}
      name={VESSEL_NAME}
      {...props}
    />
  )
}

type FreightInformationVesselNameFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
  readonly disabled?: boolean
}

export function FreightInformationVesselName({
  required,
  hint,
  error,
  style,
  disabled,
}: FreightInformationVesselNameFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: VESSEL_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${VESSEL_NAME}-field`}
    >
      <Field.Label id={`label-${VESSEL_NAME}`}>{VESSEL_NAME_LABEL}</Field.Label>
      <TextField
        aria-labelledby={`label-${VESSEL_NAME}`}
        value={get(shipment, VESSEL_NAME, '')}
        disabled={disabled}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: { [VESSEL_NAME]: get(event, 'target.value') },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
