import { isUndefined } from 'lodash-es'

import { mapValueToRequestedAccessorialsPayload } from 'components/Accessorials'
import type { HandlingUnitOrderItem } from 'components/CommodityDetails/Commodity'
import type { TransientCommodity } from 'components/HandlingUnitsManager/HandlingUnits.types'
import {
  getHighestFreightClassCommodity,
  backwardsCompatibilityItemAdapter,
} from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import { hasHazmat } from 'components/ShippingItemsManager'
import type { TransientStop } from 'components/StopsManager'
import type {
  NewShipmentPayload,
  NewShipmentShippingItemPayload,
  NewShipmentStopPayload,
} from 'shipments/shipments.services'
import { formatISODate } from 'utils/dateUtils'
import {
  EQUIPMENT_TYPES,
  areEquipmentTypesEqual,
  getEquipmentType,
} from 'utils/equipmentTypeV2'
import { getTransportationMode } from 'utils/transportationMode'

import type { TransientShipment } from '../components/Shipment/Shipment.utils'

function adaptStop(stop: TransientStop, index: number): NewShipmentStopPayload {
  return {
    stop_type: stop.stop_type,
    stop_index: index,
    facility_uuid: stop.facility?.uuid || null,
    default_contact_uuid: stop.contact?.uuid || null,
    notes: stop.notes || null,
    update_facility_notes: !!stop.update_facility_notes,
    date: stop.date ? formatISODate(stop.date) : null,
  }
}

function adaptDrayStop(
  stop: TransientStop,
  index: number
): NewShipmentStopPayload {
  return {
    stop_type: stop.stop_type,
    stop_index: index,
    facility_uuid: stop.facility?.uuid ?? stop.terminal?.uuid ?? null,
    default_contact_uuid: stop.contact?.uuid ?? null,
    notes: stop.notes ?? null,
    update_facility_notes: Boolean(stop.update_facility_notes),
    date: stop.date ? formatISODate(stop.date) : null,
  }
}

function adaptCommodity(commodity: TransientCommodity) {
  let hazmatDetails = {}
  if (commodity.hazmat) {
    hazmatDetails = {
      hazmat_proper_shipping_name: commodity.hazmat_proper_shipping_name,
      un_number: commodity.hazmat_un_number,
      hazmat_class: commodity.hazmat_class,
      hazmat_packing_group: commodity.hazmat_packing_group,
      hazmat: commodity.hazmat,
    }
  }

  return {
    description: commodity.description,
    package_count: Number(commodity.package_count),
    package_type: commodity.package_type,
    weight: Number(commodity.weight),
    nmfc_code: commodity.nmfc_code,
    freight_class: commodity.freight_class,
    ...hazmatDetails,
  }
}

function adaptHandlingUnitOrderItem(orderItem: HandlingUnitOrderItem) {
  if (!isUndefined(orderItem.shipped_package_count)) {
    return {
      uuid: orderItem.uuid,
      shipped_package_count: orderItem.shipped_package_count,
    }
  }
  return {
    uuid: orderItem.uuid,
  }
}

function adaptEXPItems(
  items: TransientShipment['items']
): NewShipmentShippingItemPayload[] {
  return items.map((item) => ({
    package_type: item.package_type,
    package_count: Number(item.package_count),
    stackable: item.stackable,
    turnable: item.turnable,
    length: Number(item.length),
    width: Number(item.width),
    height: Number(item.height),
    weight_type: item.weight_type,
    commodities: item.commodities.map(adaptCommodity),
    order_items: item.order_items?.map(adaptHandlingUnitOrderItem) || [],
    fulfillment_handling_unit_uuid: item.fulfillment_handling_unit?.uuid,
    freight_class: getHighestFreightClassCommodity(item.commodities),
    pickup_stop_index: item.pickup_stop_index!,
    delivery_stop_index: item.delivery_stop_index!,
    ...backwardsCompatibilityItemAdapter(item),
  }))
}

function adaptTLItems(
  items: TransientShipment['items']
): NewShipmentShippingItemPayload[] {
  return items.map((item) => ({
    package_type: item.package_type,
    package_count: Number(item.package_count),
    stackable: item.stackable,
    turnable: item.turnable,
    length: Number(item.length),
    width: Number(item.width),
    height: Number(item.height),
    weight_type: item.weight_type,
    commodities: item.commodities.map(adaptCommodity),
    order_items: item.order_items?.map(adaptHandlingUnitOrderItem) || [],
    fulfillment_handling_unit_uuid: item.fulfillment_handling_unit?.uuid,
    freight_class: getHighestFreightClassCommodity(item.commodities),
    pickup_stop_index: item.pickup_stop_index!,
    delivery_stop_index: item.delivery_stop_index!,
    ...backwardsCompatibilityItemAdapter(item),
  }))
}

function adaptLTLItems(
  items: TransientShipment['items']
): NewShipmentShippingItemPayload[] {
  return items.map((item) => ({
    package_type: item.package_type,
    package_count: Number(item.package_count),
    stackable: item.stackable,
    turnable: item.turnable,
    length: Number(item.length),
    width: Number(item.width),
    height: Number(item.height),
    weight_type: item.weight_type,
    commodities: item.commodities.map(adaptCommodity),
    order_items: item.order_items?.map(adaptHandlingUnitOrderItem) || [],
    fulfillment_handling_unit_uuid: item.fulfillment_handling_unit?.uuid,
    freight_class: getHighestFreightClassCommodity(item.commodities),
    pickup_stop_index: 0,
    delivery_stop_index: 1,
    ...backwardsCompatibilityItemAdapter(item),
  }))
}

export const fromNumbersToArr = (numbers: string | undefined) => {
  if (!numbers) {
    return null
  }
  return numbers.split(',').map((number) => number.trim())
}

function adaptBaseFreightInformation(
  shipment: TransientShipment
): Omit<NewShipmentPayload, 'items' | 'stops'> {
  return {
    po_numbers: shipment.po_number
      ? fromNumbersToArr(shipment.po_number)
      : null,
    bol_number: shipment.bol_number || null,
    so_numbers: shipment.so_number
      ? fromNumbersToArr(shipment.so_number)
      : null,
    shipper_ref_number: shipment.shipper_ref_number || null,
    mode: getTransportationMode(shipment.mode || 'full_truck_load')!.abbr,
    equipment_type: getEquipmentType(shipment.equipment_type || 'drv')!.abbr,
    equipment_length: shipment.equipment_length || null,
    hazmat: shipment.hazmat || false,
    power_only: shipment.power_only || false,
    equipment_requirements: shipment.equipment_requirements,
    shipper_custom_fields: shipment.shipper_custom_fields,
  }
}

function addStopOffCharges(shipment: TransientShipment) {
  if (shipment.add_stop_off_charges) {
    return { add_stop_off_charges: shipment.add_stop_off_charges }
  }

  return {}
}

function adaptHazmatHeaderFields(
  shipment: TransientShipment
): Pick<
  NewShipmentPayload,
  'hazmat' | 'hazmat_contact_name' | 'hazmat_phone_number'
> {
  const shipmentHasHazmatCommodity = hasHazmat(
    shipment.items.map(({ commodities }) => commodities).flat()
  )

  if (shipment.hazmat || shipmentHasHazmatCommodity) {
    return {
      hazmat: true,
      hazmat_contact_name: shipment.hazmat_contact_name,
      hazmat_phone_number: shipment.hazmat_phone_number,
    }
  }

  return {
    hazmat: shipment.hazmat ?? false,
  }
}

function getFlatbedFreightInformation(shipment: TransientShipment) {
  if (
    !areEquipmentTypesEqual(shipment.equipment_type!, EQUIPMENT_TYPES.fbe.value)
  ) {
    return {}
  }
  if (!shipment.with_tarp) {
    return {
      equipment_subtypes: shipment.equipment_subtypes,
      with_tarp: shipment.with_tarp,
    }
  }
  return {
    equipment_subtypes: shipment.equipment_subtypes,
    with_tarp: shipment.with_tarp,
    tarp_size: shipment.tarp_size,
    tarp_type: shipment.tarp_type,
  }
}

function adaptFTLFreightInformation(
  shipment: TransientShipment
): Omit<NewShipmentPayload, 'items' | 'stops'> {
  return {
    ...adaptBaseFreightInformation(shipment),
    ...getFlatbedFreightInformation(shipment),
    ...addStopOffCharges(shipment),
  }
}

function adaptLTLStops(
  stops: TransientShipment['stops']
): Array<NewShipmentStopPayload> {
  if (stops.length < 2) {
    return []
  }

  return [
    { ...adaptStop(stops[0], 0) },
    {
      ...adaptStop(stops[stops.length - 1], 1),
      date: null,
    },
  ]
}

function adaptAccessorials(
  shipment: Pick<TransientShipment, 'accessorials' | 'requested_accessorials'>
): Pick<NewShipmentPayload, 'accessorials' | 'requested_accessorials'> {
  return {
    accessorials: shipment.accessorials,
    requested_accessorials: shipment.requested_accessorials
      ? mapValueToRequestedAccessorialsPayload(shipment.requested_accessorials)
      : undefined,
  }
}

/**
 * @param originString string in the format hhmm
 */
const splitHourString = (originString: string) => [
  originString.slice(0, 2),
  originString.slice(2),
]

function adaptContainerInfo(shipment: TransientShipment) {
  let vesselETA = undefined

  if (shipment.vessel_eta_date && shipment.vessel_eta_time) {
    const [hour, minutes] = splitHourString(shipment.vessel_eta_time)

    vesselETA = `${shipment.vessel_eta_date}T${hour}:${minutes}`
  }

  return {
    direction: shipment.direction!,
    container_size: shipment.container_size!,
    container_number: shipment.container_number!,
    master_bol_number: shipment.master_bol_number,
    ocean_line_scac: shipment.ocean_line_scac,
    seal_number: shipment.seal_number,
    vessel_eta: vesselETA,
    vessel_name: shipment.vessel_name,
    voyage_number: shipment.voyage_number,
    imo_code: shipment.imo_code,
  }
}

export function ftlAdapter(shipment: TransientShipment): NewShipmentPayload {
  return {
    ...adaptFTLFreightInformation(shipment),
    items: adaptTLItems(shipment.items),
    stops: shipment.stops.map(adaptStop),
    ...adaptAccessorials(shipment),
  }
}

export function imdlAdapter(shipment: TransientShipment): NewShipmentPayload {
  return {
    ...adaptBaseFreightInformation(shipment),
    items: adaptTLItems(shipment.items),
    stops: shipment.stops.map(adaptStop),
  }
}

export function ptlAdapter(shipment: TransientShipment): NewShipmentPayload {
  return {
    ...adaptBaseFreightInformation(shipment),
    items: adaptTLItems(shipment.items),
    stops: shipment.stops.map(adaptStop),
    ...adaptAccessorials(shipment),
  }
}

export function ltlAdapter(shipment: TransientShipment): NewShipmentPayload {
  return {
    ...adaptBaseFreightInformation(shipment),
    ...adaptHazmatHeaderFields(shipment),
    items: adaptLTLItems(shipment.items),
    stops: adaptLTLStops(shipment.stops),
    ...adaptAccessorials(shipment),
  }
}

export function expAdapter(shipment: TransientShipment): NewShipmentPayload {
  return {
    ...adaptBaseFreightInformation(shipment),
    items: adaptEXPItems(shipment.items),
    stops: shipment.stops.map(adaptStop),
    ...adaptAccessorials(shipment),
  }
}

export function drayAdapter(shipment: TransientShipment): NewShipmentPayload {
  return {
    ...adaptFTLFreightInformation(shipment),
    items: adaptTLItems(shipment.items),
    stops: shipment.stops.map(adaptDrayStop),
    container: adaptContainerInfo(shipment),
    ...adaptAccessorials(shipment),
  }
}
