import { DatePicker } from '@loadsmart/loadsmart-ui'
import { Field, Layout, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { getToken } from '@loadsmart/miranda-react/dist/tokens'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'
import styled from 'styled-components'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const VESSEL_ETA_NAME = 'vessel_eta_date'
const VESSEL_ETA_LABEL = 'Vessel ETA'
const VESSEL_ETA_TIME_LABEL = 'Time'
const VESSEL_ETA_TIME_NAME = 'vessel_eta_time'

type FreightInformationVesselETASummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name'
>

export function FreightInformationVesselETASummaryField(
  props: Readonly<FreightInformationVesselETASummaryFieldProps>
) {
  return (
    <FreightInformationSummaryField
      label={VESSEL_ETA_LABEL}
      name={VESSEL_ETA_NAME}
      {...props}
    />
  )
}

type FreightInformationVesselETAFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
  readonly disabled?: boolean
}

const DatePickerWrapper = styled.div`
  span {
    display: none;
  }

  [role='presentation'] span {
    display: inherit;
  }

  div[scale='default'] {
    border-color: rgba(${getToken('color-border-input')});
  }
`

export function FreightInformationVesselETA({
  required,
  hint,
  error,
  style,
}: FreightInformationVesselETAFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: VESSEL_ETA_NAME,
    hint,
    error,
  })

  const { fieldProps: timeFieldProps, hintProps: timeHintProps } =
    useFreightInformationFormField({
      name: VESSEL_ETA_TIME_NAME,
      hint,
      error,
    })

  return (
    <Layout.Grid minColumnWidth="40%" gap="spacing-1">
      <Field
        {...fieldProps}
        required={required}
        style={style}
        data-testid={`${VESSEL_ETA_NAME}-field`}
      >
        <Field.Label id={`label-${VESSEL_ETA_NAME}`}>
          {VESSEL_ETA_LABEL}
        </Field.Label>
        <DatePickerWrapper>
          <DatePicker
            name="vessel-eta-date"
            aria-labelledby={`label-${VESSEL_ETA_NAME}`}
            value={shipment[VESSEL_ETA_NAME]}
            onChange={(event) => {
              const value = get(event, 'target.value')

              if (value) {
                const datePart = value.split('T')[0]

                dispatch({
                  type: 'HANDLE_CHANGE',
                  payload: {
                    [VESSEL_ETA_NAME]: datePart,
                  },
                })
              } else {
                dispatch({
                  type: 'HANDLE_CHANGE',
                  payload: { [VESSEL_ETA_NAME]: undefined },
                })
              }
            }}
          />
        </DatePickerWrapper>
        <Field.Hint {...hintProps} />
      </Field>
      <Field {...timeFieldProps} data-test-id={`${VESSEL_ETA_TIME_NAME}-field`}>
        <Field.Label
          style={{ visibility: 'hidden' }}
          id={`label-${VESSEL_ETA_TIME_NAME}`}
        >
          {VESSEL_ETA_TIME_LABEL}
        </Field.Label>
        <TextField
          placeholder="0800"
          aria-labelledby={`label-${VESSEL_ETA_TIME_NAME}`}
          value={shipment[VESSEL_ETA_TIME_NAME]}
          onChange={(event) => {
            dispatch({
              type: 'HANDLE_CHANGE',
              payload: {
                [VESSEL_ETA_TIME_NAME]: get(event, 'target.value'),
              },
            })
          }}
        />
        <Field.Hint {...timeHintProps} />
      </Field>
    </Layout.Grid>
  )
}
