import { Button, Tooltip } from '@loadsmart/miranda-react'

import TableActions from '_shared_/components/TableActions'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { canCreateShipment } from 'fulfillments/domain/Fulfillment'
import { plural } from 'utils/strings'

import { useOneClickPlanFulfillmentContext } from './OneClickPlanFulfillment.context'

export function OneClickPlanAsShipmentAction({
  fulfillment,
}: {
  readonly fulfillment: ListFulfillment
}) {
  const { enabled, open } = useOneClickPlanFulfillmentContext()

  const canPlan = canCreateShipment(fulfillment)

  if (!enabled) {
    return null
  }

  const caption = canPlan
    ? 'Automatically create a shipment for this fulfillment'
    : "Planned fulfillments can't be used to create a new shipment"

  return (
    <TableActions.Item
      caption={caption}
      disabled={!canPlan}
      onClick={() => {
        open([fulfillment])
      }}
    >
      Auto plan as shipment...
    </TableActions.Item>
  )
}

export function OneClickPlanAsShipmentButton({
  selectedFulfillments,
}: Readonly<{ selectedFulfillments: ListFulfillment[] }>) {
  const disabled = !canCreateShipment(selectedFulfillments)
  const { enabled, open } = useOneClickPlanFulfillmentContext()

  if (!enabled || !selectedFulfillments.length) {
    return null
  }

  const button = (
    <Button
      disabled={disabled}
      onClick={() => {
        open(selectedFulfillments)
      }}
      variant="secondary"
      data-testid="button-create-new-shipment"
    >
      {`Auto plan as ${selectedFulfillments.length} ${plural('shipment', selectedFulfillments.length)}`}
    </Button>
  )

  if (disabled) {
    return (
      <Tooltip message="Planned fulfillments were selected and cannot be used to create a new shipment.">
        {button}
      </Tooltip>
    )
  }

  return button
}
