import { Button, Card, Divider, Icon, Layout } from '@loadsmart/miranda-react'
import { generatePath } from 'react-router-dom'

import {
  freightInformationFromAdaptedShipment,
  FreightInformationSummary,
} from 'components/FreightInformation'
import { appRoutes } from 'router/app-routes'
import { ShipmentSummary } from 'screens/Shipper/Shipments/components/Shipment'

import { useViewFulfillmentContext } from '../ViewFulfillmentContext'

export function FulfillmentShipmentCard() {
  const { isFulfillmentSupplier, shipment } = useViewFulfillmentContext()

  if (isFulfillmentSupplier || !shipment) {
    return null
  }

  return (
    <Card data-testid="fulfillment-shipment">
      <Card.Title>Shipment</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Stack>
          <ShipmentSummary shipment={shipment}>
            <Layout.Group justify="space-between" style={{ width: '100%' }}>
              <Layout.Group align="center">
                <Layout.Stack gap="spacing-1">
                  {/* Shipment route and status */}
                  <Layout.Group align="center" gap="spacing-6">
                    <ShipmentSummary.Route />
                    <ShipmentSummary.Status />
                  </Layout.Group>

                  {/* Shipment period, mileage, ID */}
                  <Layout.Group align="center" gap="spacing-2">
                    <ShipmentSummary.Period dateFormat="ddd, MMM D" />
                    {Boolean(shipment.mileage) && (
                      <>
                        <ShipmentSummary.Divider />
                        <ShipmentSummary.Mileage />
                      </>
                    )}
                    <ShipmentSummary.Divider />
                    <ShipmentSummary.ShipmentID heading />
                  </Layout.Group>
                </Layout.Stack>
              </Layout.Group>

              <Button
                href={generatePath(appRoutes.shipmentDetails, {
                  id: shipment.uuid,
                })}
                variant="tertiary"
              >
                Go to shipment &nbsp;
                <Icon name="arrow-corner-up" />
              </Button>
            </Layout.Group>

            <Divider />

            <FreightInformationSummary
              shipment={freightInformationFromAdaptedShipment(shipment)}
            >
              <Layout.Group>
                <Layout.Box style={{ minWidth: '250px' }}>
                  <FreightInformationSummary.Mode />
                </Layout.Box>
                <Layout.Box style={{ minWidth: '250px' }}>
                  <FreightInformationSummary.Equipment />
                </Layout.Box>
                <Layout.Box style={{ minWidth: '250px' }}>
                  <FreightInformationSummary.ReferenceNumber />
                </Layout.Box>
              </Layout.Group>
            </FreightInformationSummary>
          </ShipmentSummary>
        </Layout.Stack>
      </Card.Body>
    </Card>
  )
}
