import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

/* eslint-disable @typescript-eslint/no-empty-object-type */
import type { RFPGuideTheme } from 'styles/theme'

declare module 'styled-components' {
  export interface DefaultTheme extends RFPGuideTheme {}
}

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  body {
    margin: 0;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSize};
    font-weight: ${({ theme }) => theme.fontWeight};

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ReactModal__Overlay {
    z-index: 10;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left;
  }

  .table-checkbox {
    width: 48px
  }

  .capitalize {
    text-transform: capitalize;
  }

  .absolute-content {
    position: relative;
    padding-left: 16px;
  }

  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-nowrap {
    white-space: nowrap;
  }

  * {
    box-sizing: border-box;
  }

  .flex {
    display: flex;
  }

  .flex-right {
    justify-content: flex-end;
  }

  .td-flex-height {
    line-height: 28px;
  }

  .align-center {
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  .pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSize};
    font-weight: ${({ theme }) => theme.fontWeight};
    justify-content: center;
  }

  .pagination > li {
    cursor: pointer;
    display: inline-block;
    list-style-type: none;
  }

  .pagination > li > a {
    display: inline-flex;
    padding: 10px;
  }

  .pagination > li.active > a {
    background-color: ${({ theme }) => theme.colors.main};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
  }

  .select-dropdown__clear-indicator {
    cursor: pointer;
    padding: 6px;
  }

   /* This is a quick fix to center the only item of the Topnav menu */
  .miranda-topnav-subitem > button > :nth-child(2) {
    margin: 0 !important;
  }
  .miranda-topnav-subitem-button > :nth-child(2) {
    margin: 0 !important;
  }

  /*Seems like we need to reset some styles in order to the tab without leading icon to render nothing. */
  .miranda-tabs li {
    list-style: none;
  }

  .relative {
    position: relative;
  }

  .hidden {
    display: none;
  }

  .h-full {
    height: 100%;
  }

  .w-full {
    width: 100%;
  }

  .mr-auto {
    margin-right: auto;
  }

  .ml-auto {
    margin-left: auto;
  }

  .overflow-visible {
    overflow: visible
  }

  .cursor-pointer {
    cursor: pointer
  }

  /* Beamer notification indicator. We did that to mimick Miranda badge behavior */
  .beamerTrigger .beamer_icon.active {
    background-color: ${toCSSValue('color-danger-60')};
    color: ${toCSSValue('color-neutral-0')};
    font-size: ${toCSSValue('font-size-1')};
    left: calc(100% - 6px);
    animation: none;
    font-family: Manrope;
    font-weight: ${toCSSValue('font-weight-regular')};
    line-height: 12px;
    top: 0;
    inline-size: 12px;
    block-size: 12px;
    transition: none;
  }
`
