import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { SingleValue } from 'react-select'
import { useDebouncedCallback } from 'use-debounce'

import type { AppDispatch } from 'app/store'
import Autocomplete, { highlight } from 'components/Autocomplete'
import { Option, OptionHeader } from 'components/Autocomplete/styles'
import {
  searchAliceCarriers,
  updateAliceSearch,
} from 'features/carriers/actions'
import {
  aliceCarriersIsLoadingSelector,
  aliceCarriersQuerySelector,
  aliceCarriersSelector,
} from 'features/carriers/selectors'

/**
 * @deprecated use miranda-react instead
 */
export default function AliceCarriersAutocomplete({
  onSelect,
}: {
  readonly onSelect: (carrier: Carrier) => void
}) {
  const dispatch = useDispatch<AppDispatch>()

  const aliceCarriers = useSelector(aliceCarriersSelector)
  const aliceCarriersQuery = useSelector(aliceCarriersQuerySelector)
  const isLoadingAliceCarriers = useSelector(aliceCarriersIsLoadingSelector)

  const [isTyping, setTyping] = useState(false)

  const mappedAliceCarriers = useMemo<
    Array<Carrier & { label: string; value: string }>
  >(() => {
    const carriers = aliceCarriers.map((carrier) => {
      return {
        ...carrier,
        label: carrier.name,
        value: `${carrier.id}`,
      }
    })

    if (aliceCarriersQuery && carriers.length) {
      carriers.unshift({
        alternative_emails: [],
        dot: '',
        email: '',
        id: -1,
        label: '',
        name: '',
        value: '',
        relationship_status: 'in_network' as const,
      })
    }

    return carriers
  }, [aliceCarriers, aliceCarriersQuery])

  const debouncedSearchAliceCarriers = useDebouncedCallback(() => {
    setTyping(false)
    dispatch(searchAliceCarriers())
  }, 450)

  useEffect(() => {
    debouncedSearchAliceCarriers()
  }, [aliceCarriersQuery, debouncedSearchAliceCarriers])

  const updateSearch = useCallback(
    (value: string) => {
      setTyping(true)
      dispatch(updateAliceSearch(value))
    },
    [dispatch]
  )

  const renderNoOptionsMessage = useCallback(
    ({ inputValue }: { readonly inputValue: string }) =>
      inputValue && !isTyping && !isLoadingAliceCarriers
        ? `No carriers matching "${inputValue}" found.`
        : null,
    [isLoadingAliceCarriers, isTyping]
  )

  const select = useCallback(
    (
      carrier: SingleValue<Readonly<Carrier & { label: string; value: string }>>
    ) => {
      if (carrier === null) {
        return
      }
      updateSearch('')
      onSelect(carrier)
    },
    [onSelect, updateSearch]
  )

  return (
    <Autocomplete<any>
      blurInputOnSelect={true}
      controlShouldRenderValue={false}
      filterOption={() => true}
      inputValue={aliceCarriersQuery}
      isLoading={isLoadingAliceCarriers}
      noOptionsMessage={renderNoOptionsMessage}
      onChange={select}
      onInputChange={updateSearch}
      options={mappedAliceCarriers}
      placeholder="Search by carrier name, DOT or MC number"
      renderOption={({ label, data, innerProps }) =>
        data.id === -1 ? (
          <OptionHeader>
            <div className="text-left">Carrier</div>
            <div className="text-right">DOT</div>
            <div className="text-right">MC Number</div>
          </OptionHeader>
        ) : (
          <Option
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore -- We can safely ignore the ref type
            ref={innerProps.ref}
            {...innerProps}
          >
            <div
              className="text-left"
              dangerouslySetInnerHTML={{
                __html: highlight(label, aliceCarriersQuery),
              }}
            />
            <div
              className="text-right"
              dangerouslySetInnerHTML={{
                __html: highlight(data.dot ?? '', aliceCarriersQuery),
              }}
            />
            <div
              className="text-right"
              dangerouslySetInnerHTML={{
                __html: highlight(data.mc ?? '', aliceCarriersQuery),
              }}
            />
          </Option>
        )
      }
    />
  )
}
