import { IconQuestionCircle, IconTrash } from '@loadsmart/icons'
import {
  Card,
  Layout,
  Field,
  Text,
  TextField,
  Button,
} from '@loadsmart/miranda-react'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useDebouncedCallback } from 'use-debounce'

import StopSearchFieldV2 from '_shared_/components/form/StopSearchFieldV2'
import type { LocationOrFacilityV2 } from '_shared_/components/form/types'
import { getLaneMileage } from 'rfp/components/distanceType'
import { RequiredErrorMessage } from 'rfp/rfp-form/componentUtils'
import { getFieldStatus } from 'rfp/rfp-form/utils'
import { getMileage } from 'services/mileage'

import { LOCATION_SELECT_CONFIG } from '../constants'
import { FormTooltip } from './styles'
import type { AditionalStopFieldProps, LaneDetailsProps } from './types'
import { canCalculateMileage, getExtraStops } from './utils'

const AditionalStopField = ({
  aditionalStops,
  control,
  errors,
  setValue,
  handleStopChanged,
}: AditionalStopFieldProps) => {
  const aditionalStopCallback = (
    location: LocationOrFacilityV2 | null,
    index: number
  ) => {
    aditionalStops[index] = location || {}
    setValue('additional_stops', aditionalStops)
    handleStopChanged()
  }

  const removeAditionalStop = (index: number) => {
    const stops = aditionalStops
    stops.splice(index, 1)
    setValue('additional_stops', stops)
    handleStopChanged()
  }

  if (!aditionalStops) {
    return null
  }

  return aditionalStops.map((stop: LocationOrFacilityV2, index: number) => {
    return (
      <Field
        required
        status={getFieldStatus(errors.additional_stops)}
        key={`additional-stop-${stop.zipcode}`}
      >
        <Field.Label aria-label="newRfpMode" id="labelMode">
          Stop #{index + 1}
        </Field.Label>
        <Controller
          name="additional_stops"
          control={control}
          render={({ field }) => (
            <Layout.Grid style={{ gridTemplateColumns: '11fr 1fr' }}>
              <StopSearchFieldV2
                id={field.value[index]}
                config={LOCATION_SELECT_CONFIG}
                placeholder="City, state, zip code or facility"
                value={{
                  address: field.value[index]?.address,
                  city: field.value[index]?.city,
                  state: field.value[index]?.state,
                  zipcode: field.value[index]?.zipcode,
                  lat: field.value[index]?.lat,
                  lng: field.value[index]?.lng,
                  country: field.value[index]?.country,
                  company_name:
                    field.value[index]?.name ||
                    field.value[index]?.facility?.facility_name,
                }}
                callback={(location) => aditionalStopCallback(location, index)}
                withFacilities
              />
              <Button
                data-testid="delete-extra-stop"
                variant="tertiary"
                onClick={() => removeAditionalStop(index)}
              >
                <IconTrash
                  width={14}
                  height={14}
                  aria-label={`aditional-stop-${index}`}
                  title={null}
                />
              </Button>
            </Layout.Grid>
          )}
        />
        <RequiredErrorMessage error={errors.additional_stops} />
      </Field>
    )
  })
}

export default function LaneDetailsSection({
  control,
  setValue,
  getValue,
  errors,
  watchOrigin,
  watchDestination,
  watchMileage,
  watchMileageSource,
  watchExtraStops,
  rfpDistanceType,
}: LaneDetailsProps) {
  const [isCalculatingMileage, setIsCalculatingMileage] = useState(false)

  const calculateMileage = useDebouncedCallback(async () => {
    const canCalculate = canCalculateMileage(
      watchMileage,
      watchMileageSource,
      watchOrigin,
      watchDestination,
      errors
    )
    if (isCalculatingMileage || !canCalculate) {
      return
    }

    setIsCalculatingMileage(true)

    try {
      const lanePayload = {
        pickup_city: watchOrigin?.origin_city,
        pickup_state: watchOrigin?.origin_state,
        pickup_zip_code: watchOrigin?.origin_zip,
        delivery_city: watchDestination?.dest_city,
        delivery_state: watchDestination?.dest_state,
        delivery_zip_code: watchDestination?.dest_zip,
        extra_stops: getExtraStops(watchExtraStops),
      }
      const data: Lane = await getMileage(lanePayload)
      const calculatedMileage = getLaneMileage(rfpDistanceType, data)
      if (calculatedMileage === watchMileage) {
        return
      }
      setValue('mileage', Math.round(calculatedMileage ?? 0))
      setValue('mileage_source', data.mileage_source)
    } catch {
      setValue('mileage', undefined)
      setValue('mileage_source', undefined)
    } finally {
      setIsCalculatingMileage(false)
    }
  }, 300)

  const getFacilityData = (stop: LocationOrFacilityV2 | null) => {
    return stop?.isFacility
      ? {
          facility_uuid: stop?.uuid,
          facility_name: stop?.name,
          facility_address: stop?.address,
        }
      : {
          facility_uuid: null,
          facility_name: null,
          facility_address: null,
        }
  }

  const originCallback = (origin: LocationOrFacilityV2 | null = {}) => {
    const locationDetails = !origin?.isFacility
      ? {
          origin_lat: origin?.lat,
          origin_lng: origin?.lng,
        }
      : {
          origin_lat: null,
          origin_lng: null,
        }

    const originAddress = {
      origin_address: origin?.address,
      origin_city: origin?.city,
      origin_state: origin?.state,
      origin_country: origin?.country,
      origin_zip: origin?.zipcode,
      origin_facility: getFacilityData(origin),
      ...locationDetails,
    }
    setValue('origin', originAddress)
    calculateMileage()
  }

  const destinationCallback = (
    destination: LocationOrFacilityV2 | null = {}
  ) => {
    const locationDetails = !destination?.isFacility
      ? {
          dest_lat: destination?.lat,
          dest_lng: destination?.lng,
        }
      : {
          dest_lat: null,
          dest_lng: null,
        }

    const destinationAddress = {
      dest_address: destination?.address,
      dest_city: destination?.city,
      dest_state: destination?.state,
      dest_country: destination?.country,
      dest_zip: destination?.zipcode,
      dest_facility: getFacilityData(destination),
      ...locationDetails,
    }
    setValue('destination', destinationAddress)
    calculateMileage()
  }

  const handleAddStop = () => {
    const currentStops = getValue('additional_stops')
    currentStops.push({
      address: '',
      city: '',
      state: '',
      zipcode: '',
      lat: '',
      lng: '',
      country: '',
      name: undefined,
    })
    setValue('additional_stops', currentStops)
    calculateMileage()
  }

  const handleSelectAditionalStopLocation = () => {
    calculateMileage()
  }

  return (
    <Layout.Box paddingB="spacing-4" padding="none">
      <Card>
        <Card.Title>Lane Details</Card.Title>
        <Card.Divider />
        <Card.Body>
          <Layout.Stack gap="spacing-6">
            <Field required status={getFieldStatus(errors.origin)}>
              <Field.Label aria-label="newRfpMode" id="labelMode">
                Origin
              </Field.Label>
              <Controller
                name="origin"
                control={control}
                render={({ field }) => (
                  <StopSearchFieldV2
                    id="origin-stop-input"
                    config={LOCATION_SELECT_CONFIG}
                    placeholder="City, state, zip code or facility"
                    value={{
                      address: field.value.origin_address,
                      city: field.value.origin_city,
                      state: field.value.origin_state,
                      zipcode: field.value.origin_zip,
                      lat: field.value.origin_lat,
                      lng: field.value.origin_lng,
                      country: field.value.origin_country,
                      company_name:
                        field.value.name ||
                        field.value.origin_facility?.facility_name,
                    }}
                    callback={originCallback}
                    withFacilities
                  />
                )}
              />
              <RequiredErrorMessage error={errors.origin} />
            </Field>
            <AditionalStopField
              aditionalStops={watchExtraStops}
              control={control}
              errors={errors}
              setValue={setValue}
              handleStopChanged={handleSelectAditionalStopLocation}
            />
            <Field required status={getFieldStatus(errors.destination)}>
              <Field.Label aria-label="newRfpMode" id="labelMode">
                Destination
              </Field.Label>
              <Controller
                name="destination"
                control={control}
                render={({ field }) => (
                  <StopSearchFieldV2
                    id="destination-stop-input"
                    config={LOCATION_SELECT_CONFIG}
                    placeholder="City, state, zip code or facility"
                    value={{
                      address: field.value.dest_address,
                      city: field.value.dest_city,
                      state: field.value.dest_state,
                      zipcode: field.value.dest_zip,
                      lat: field.value.dest_lat,
                      lng: field.value.dest_lng,
                      country: field.value.dest_country,
                      company_name:
                        field.value.name ||
                        field.value.dest_facility?.facility_name,
                    }}
                    callback={destinationCallback}
                    withFacilities
                  />
                )}
              />
              <RequiredErrorMessage error={errors.destination} />
            </Field>
            <FormTooltip
              style={{ width: 'fit-content' }}
              trigger="hover"
              message={
                <>
                  Add any intermediate stops along
                  <div />
                  the route. These can be additional
                  <div />
                  pickup or delivery points.
                </>
              }
              placement="top-start"
            >
              <Button
                style={{ width: 'fit-content' }}
                variant="tertiary"
                onClick={() => {
                  handleAddStop()
                }}
              >
                Add stops
                <IconQuestionCircle
                  width={14}
                  height={14}
                  aria-label="extra-stops"
                  title={null}
                />
              </Button>
            </FormTooltip>
            <Layout.Grid>
              <Field required status={getFieldStatus(errors.mileage)}>
                <Field.Label aria-label="mileage" id="mileage">
                  <Text>Distance</Text>
                  <FormTooltip
                    className="field-label-tooltip"
                    trigger="hover"
                    message={
                      <>
                        Automatically calculated. You can
                        <div />
                        edit if needed. Auto calculation
                        <div />
                        can be turned off in settings.
                      </>
                    }
                    placement="top-start"
                  >
                    <IconQuestionCircle
                      width={14}
                      height={14}
                      aria-label="mileage"
                      title={null}
                    />
                  </FormTooltip>
                </Field.Label>
                <Controller
                  name="mileage"
                  control={control}
                  disabled={isCalculatingMileage}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onInput={field.onChange}
                      aria-labelledby="mileage"
                      placeholder={`Distance in ${rfpDistanceType}`}
                    />
                  )}
                />
                <RequiredErrorMessage error={errors.mileage} />
              </Field>
              <Field required status={getFieldStatus(errors.volume)}>
                <Field.Label aria-label="volume" id="volume">
                  <Text>Volume</Text>
                  <FormTooltip
                    className="field-label-tooltip"
                    trigger="hover"
                    message={
                      <>
                        Enter the volume of shipments as per the
                        <div />
                        volume frequency set at the RFP level.
                      </>
                    }
                    placement="top-start"
                  >
                    <IconQuestionCircle
                      width={14}
                      height={14}
                      aria-label="volume"
                      title={null}
                    />
                  </FormTooltip>
                </Field.Label>
                <Controller
                  name="volume"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onInput={field.onChange}
                      aria-labelledby="volume"
                      placeholder="Enter volume"
                    />
                  )}
                />
                <RequiredErrorMessage error={errors.volume} />
              </Field>
              <Field status={getFieldStatus(errors.lane_id)}>
                <Field.Label aria-label="lane_id" id="lane_id">
                  <Text>Lane ID</Text>
                  <FormTooltip
                    className="field-label-tooltip"
                    trigger="hover"
                    message={
                      <>
                        Enter a unique identifier for this
                        <div />
                        lane. This can be used for your
                        <div />
                        internal tracking purposes.
                      </>
                    }
                    placement="top-start"
                  >
                    <IconQuestionCircle
                      width={14}
                      height={14}
                      aria-label="lane-id"
                      title={null}
                    />
                  </FormTooltip>
                </Field.Label>
                <Controller
                  name="lane_id"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      onInput={field.onChange}
                      aria-labelledby="laneID"
                      placeholder="Enter lane ID"
                    />
                  )}
                />
                <RequiredErrorMessage error={errors.lane_id} />
              </Field>
            </Layout.Grid>
          </Layout.Stack>
        </Card.Body>
      </Card>
    </Layout.Box>
  )
}
