import {
  EmptyState as EmptyStateComponent,
  Icon,
  Layout,
  SpinnerWheel,
  Text,
} from '@loadsmart/miranda-react'
import { useCallback, useEffect, useState } from 'react'

import addFulfillmentsIllustration from 'assets/illustrations/add-orders.svg'
import type { TruckMileageResponse } from 'orders/order-service'

import { usePlanFulfillmentsContext } from '../PlanFulfillmentsContext'
import PlanFulfillmentsPlanPreviewHeader from './PlanFulfillmentsPlanPreviewHeader'
import PlanFulfillmentsPlanPreviewStopCard from './PlanFulfillmentsPlanPreviewStopCard'

function LoadingState() {
  return (
    <Layout.Group
      align="center"
      justify="center"
      style={{ height: '100%' }}
      data-testid="preview-loading-state"
    >
      <SpinnerWheel size="48px" title="Loading..." />
    </Layout.Group>
  )
}

function EmptyState() {
  return (
    <Layout.Stack
      align="center"
      justify="center"
      style={{ flex: 1 }}
      data-testid="preview-empty-state"
    >
      <EmptyStateComponent
        variant="panel"
        illustration={addFulfillmentsIllustration}
        header="Add a fulfillment to start planning your shipment"
        message="Add fulfillments from the list or explore the map to find them by location"
      />
    </Layout.Stack>
  )
}

export type MileageProps = Readonly<{
  isLoadingTruckMileage?: boolean
  stopIndex: number
  truckMileage?: TruckMileageResponse
}>

export function Mileage({
  isLoadingTruckMileage,
  stopIndex,
  truckMileage,
}: MileageProps) {
  // if this is the first stop,
  // there's no mileage to display
  // since mileage is displayed between stops
  if (stopIndex === 1) {
    return null
  }

  if (isLoadingTruckMileage) {
    return (
      <Layout.Group data-testid={`truck-mileage-stop-${stopIndex}-loading`}>
        <SpinnerWheel size="21px" />
      </Layout.Group>
    )
  }

  if (
    truckMileage?.stops_mileages?.length &&
    truckMileage.stops_mileages.length >= stopIndex
  ) {
    return (
      <Layout.Group
        data-testid={`truck-mileage-stop-${stopIndex}`}
        align="center"
        gap="spacing-1"
      >
        <Text color="color-text-tertiary">
          <Icon name="order" size="10px" />
        </Text>
        <Text color="color-text-tertiary" variant="body-md">
          {truckMileage.stops_mileages[stopIndex - 1]} miles
        </Text>
      </Layout.Group>
    )
  }

  return null
}

export function PlanFulfillmentsPlanPreview() {
  const {
    isLoadingPendingFulfillments,
    isLoadingSelectedFulfillments,
    isLoadingTruckMileage,
    orderedFacilitiesUUIDs,
    plan,
    selectedFulfillmentsUUIDs,
    truckMileage,
  } = usePlanFulfillmentsContext()
  const [expandedCardsMap, setExpandedCardsMap] = useState<
    Record<string, boolean>
  >({})

  useEffect(() => {
    setExpandedCardsMap((state) => {
      const newExpandedMap: Record<string, boolean> = {}

      orderedFacilitiesUUIDs.forEach((facilityUUID) => {
        newExpandedMap[facilityUUID] = state[facilityUUID] || false
      })

      return newExpandedMap
    })
  }, [setExpandedCardsMap, orderedFacilitiesUUIDs])

  const toggleExpandedCard = useCallback(
    (facilityUUID: string) => {
      setExpandedCardsMap((state) => ({
        ...state,
        [facilityUUID]: !state[facilityUUID],
      }))
    },
    [setExpandedCardsMap]
  )

  if (isLoadingPendingFulfillments || isLoadingSelectedFulfillments) {
    return (
      <Layout.Stack paddingR="spacing-4" style={{ width: '100%' }}>
        <LoadingState />
      </Layout.Stack>
    )
  }

  if (selectedFulfillmentsUUIDs.length === 0) {
    return (
      <Layout.Stack paddingR="spacing-4" style={{ width: '100%' }}>
        <EmptyState />
      </Layout.Stack>
    )
  }

  return (
    <Layout.Stack
      paddingL="spacing-2"
      paddingR="spacing-4"
      paddingT="spacing-2"
      style={{ width: '100%' }}
    >
      <PlanFulfillmentsPlanPreviewHeader />

      {plan?.stops.map((stop, index) => (
        <Layout.Stack key={stop.facilityUUID}>
          <Mileage
            isLoadingTruckMileage={isLoadingTruckMileage}
            stopIndex={index + 1}
            truckMileage={truckMileage}
          />
          <PlanFulfillmentsPlanPreviewStopCard
            collapsed={!expandedCardsMap[stop.facilityUUID]}
            facilityUUID={stop.facilityUUID}
            setCollapsed={toggleExpandedCard}
            stopIndex={index + 1}
          />
        </Layout.Stack>
      ))}
    </Layout.Stack>
  )
}
