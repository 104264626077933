import moment from 'moment'
import type { unitOfTime } from 'moment'
import momentTimezone from 'moment-timezone'

import {
  differenceInHours,
  parseDate,
  formatDate as format,
  intervalToDuration,
  parseISO,
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  differenceInMinutes,
  differenceInSeconds,
  formatDuration,
} from '_shared_/utils/date'
import type { DifferenceInHoursOptions, Duration } from '_shared_/utils/date'

/**
 * @deprecated
 */
export function formatDate(
  dateString: string,
  dateFormat = 'MM/DD/YY'
): string {
  const date = moment(dateString)
  if (!date.isValid()) {
    throw Error('Invalid date format.')
  }
  return date.format(dateFormat)
}

export function formatDateUTC(date: string, dateFormat = 'MM/DD/YY') {
  return moment.utc(date).format(dateFormat)
}

export function formatDateTimeWithTimezone(
  dateString: string,
  dateTimeFormat = 'MM/DD/YY, h:mm A',
  timezone = 'America/New_York'
): string {
  const date = momentTimezone.tz(dateString, timezone)
  if (!date.isValid()) {
    throw Error('Invalid date format.')
  }
  return date.format(dateTimeFormat)
}

export function getCurrentTimezone() {
  return momentTimezone.tz.guess()
}

/**
 * Returns the timezone abbreviation for the given timezone.
 * America/New_York => EDT
 *
 * @param timezone The timezone to format. If not provided the function
 * returns an empty string
 * @returns The timezone abbreviation.
 */
export function formatTimezoneAbbreviation(timezone?: string | null) {
  if (!timezone) {
    return ''
  }

  return momentTimezone.tz(timezone).zoneAbbr()
}

export function formatISODate(dateString: string): string {
  const date1 = parseDate(dateString, 'MM/dd/yyyy', new Date())
  if (!Number.isNaN(date1.getTime())) {
    return format(date1, 'yyyy-MM-dd')
  }

  const date2 = new Date(dateString)
  if (!Number.isNaN(date2.getTime())) {
    return format(date2, 'yyyy-MM-dd')
  }

  return 'Invalid date'
}

export function formatRFPDate(dateString: string): string {
  const date = moment(dateString)
  if (!date.isValid()) {
    throw Error('Invalid date format.')
  }
  return date.format('MMM D, YYYY')
}

export function formatRFPDateTime(dateString: string): string {
  const date = moment(dateString)
  if (!date.isValid()) {
    throw Error('Invalid date format.')
  }
  return date.format('MMM D, YYYY @ h:mm A')
}

export function humanizeDuration(
  from: Date | string,
  to: Date | string,
  depth = Infinity
) {
  const DURATION_FORMATS_DEPTH: Record<number, (keyof Duration)[] | undefined> =
    {
      1: ['years', 'months', 'days'],
      2: ['years', 'months'],
      [Infinity]: ['years', 'months', 'days', 'hours'],
    }

  return formatDuration(intervalToDuration({ start: from, end: to }), {
    format: DURATION_FORMATS_DEPTH[depth],
    delimiter: ' and ',
    zero: true,
  })
}

export function getTimeDiff(
  dateTime: string,
  options?: DifferenceInHoursOptions
) {
  return differenceInHours(new Date(dateTime), new Date(), options)
}

export function getDatesDiff(
  from: string | Date,
  to: string | Date,
  unit: unitOfTime.Diff = 'ms'
) {
  const momentFrom = momentTimezone(from)
  const momentTo = momentTimezone(to)

  return momentTo.diff(momentFrom, unit)
}

export function getPreciseDiff(
  from?: string | number | null,
  to = new Date().toISOString()
) {
  if (!from) {
    return null
  }

  const dateFrom = typeof from === 'string' ? parseISO(from) : new Date(from)
  const dateTo = parseISO(to)

  if (isNaN(dateFrom.getTime()) || isNaN(dateTo.getTime())) {
    return null
  }

  const years = differenceInYears(dateTo, dateFrom)
  const months = differenceInMonths(dateTo, dateFrom) % 12
  const days = differenceInDays(dateTo, dateFrom) % 30
  const hours = differenceInHours(dateTo, dateFrom) % 24
  const minutes = differenceInMinutes(dateTo, dateFrom) % 60
  const seconds = differenceInSeconds(dateTo, dateFrom) % 60

  return {
    years: Math.abs(years),
    months: Math.abs(months),
    days: Math.abs(days),
    hours: Math.abs(hours),
    minutes: Math.abs(minutes),
    seconds: Math.abs(seconds),
  }
}

export function formatTimeDifference(
  from: string,
  to = new Date().toISOString()
) {
  const dateDiff = getPreciseDiff(from, to)

  if (!dateDiff) {
    return ''
  }

  if (dateDiff.years) {
    return `${dateDiff.years.toString().padStart(2, '0')}yr`
  }

  if (dateDiff.months) {
    return `${dateDiff.months.toString().padStart(2, '0')}mo`
  }

  if (dateDiff.days) {
    return `${dateDiff.days.toString().padStart(2, '0')}d`
  }

  if (dateDiff.hours) {
    return `${dateDiff.hours.toString().padStart(2, '0')}h`
  }

  if (dateDiff.minutes) {
    return `${dateDiff.minutes.toString().padStart(2, '0')}m`
  }

  if (dateDiff.seconds) {
    return `${dateDiff.seconds.toString().padStart(2, '0')}s`
  }
  return ''
}
