import { Card } from '@loadsmart/miranda-react'

import { FulfillmentSelection } from 'screens/Shipper/Shipments/components/FulfillmentSelection/FulfillmentSelection'
import { OrderSelection } from 'screens/Shipper/Shipments/components/OrderSelection/OrderSelectionTable'

import { useConsolidationContext } from '../consolidation.context'

export function ConsolidationReviewCard() {
  const { selectedOrders, selectedFulfillments, consolidationType } =
    useConsolidationContext()

  if (consolidationType === 'fulfillment') {
    return (
      <Card
        id="fulfillment-selection-card"
        data-testid="fulfillment-selection-card"
      >
        <Card.Title>Fulfillments</Card.Title>
        <Card.Divider />
        <Card.Body>
          <FulfillmentSelection fulfillments={selectedFulfillments} />
        </Card.Body>
      </Card>
    )
  }

  return (
    <Card id="order-selection-card" data-testid="order-selection-card">
      <Card.Title>Orders</Card.Title>
      <Card.Divider />
      <Card.Body>
        <OrderSelection orders={selectedOrders} />
      </Card.Body>
    </Card>
  )
}
