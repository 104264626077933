import {
  Banner,
  Dialog,
  Layout,
  Radio,
  RadioGroup,
  Text,
} from '@loadsmart/miranda-react'
import type { Dispatch, SetStateAction } from 'react'

import { plural } from 'utils/strings'

import type { OneClickAvailableModes } from './OneClickPlanFulfillment.types'

type OneClickPlanFulfillmentConfirmationContentProps = Readonly<{
  canProceed: boolean
  count: number
  mode: OneClickAvailableModes
  onClose: () => void
  onConfirm: () => Promise<void>
  setMode: Dispatch<SetStateAction<OneClickAvailableModes>>
}>

export default function OneClickPlanFulfillmentConfirmationContent({
  canProceed,
  count,
  mode,
  onClose,
  onConfirm,
  setMode,
}: OneClickPlanFulfillmentConfirmationContentProps) {
  return (
    <>
      <Dialog.Body>
        <Layout.Stack>
          <Banner>
            <Banner.Title>
              {`${count} ${plural('fulfillment', count)} selected`}
            </Banner.Title>
            <Banner.Description>
              We will create a separate shipment for each selected fulfillment
            </Banner.Description>
          </Banner>

          <Layout.Stack>
            <Text color="color-text-tertiary">Select the shipment mode</Text>
            <RadioGroup
              onChange={(e) => {
                setMode(e.target.value as OneClickAvailableModes)
              }}
              value={mode}
            >
              <Radio aria-label="FTL" value="FTL">
                <Text variant="body-md-bold">FTL</Text> (Full Truckload)
              </Radio>

              <Radio aria-label="LTL" value="LTL">
                <Text variant="body-md-bold">LTL</Text> (Less Than Truckload)
              </Radio>
            </RadioGroup>
          </Layout.Stack>
        </Layout.Stack>
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionTertiary onClick={onClose}>Cancel</Dialog.ActionTertiary>
        <Dialog.ActionPrimary disabled={!canProceed} onClick={onConfirm}>
          Create {count} {plural('shipment', count)}
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </>
  )
}
