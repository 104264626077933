import { defaultTo, get, isEmpty, isNumber, omit } from 'lodash-es'

import type {
  TransientCommodity,
  TransientHandlingUnit,
} from 'components/HandlingUnitsManager/HandlingUnits.types'
import {
  calculateTotalVolume,
  calculateTotalWeight,
} from 'components/ShippingItemsManager'
import type {
  FulfillmentPayload,
  FulfillmentWithoutOrderPayload,
  TransientFulfillment,
} from 'fulfillments/domain/Fulfillment'
import { calculateTotalDensity } from 'fulfillments/domain/Fulfillment'
import { formatISODate } from 'utils/dateUtils'

export function adaptCommoditiesWithoutOrders(
  commodities?: TransientCommodity[]
) {
  if (!commodities) {
    return []
  }

  return commodities.map((commodity) => {
    const baseCommodity = omit(commodity, [
      '_metadata',
      'package_count',
      'weight',
    ])

    return {
      ...baseCommodity,
      package_type: isEmpty(baseCommodity.package_type)
        ? null
        : baseCommodity.package_type, // needs to be null because of enum values
      shipped_count: isNumber(commodity.package_count)
        ? commodity.package_count
        : null, // default to null if not explicitly set
      shipped_weight: commodity.weight,
    }
  })
}

export function adaptHandlingUnitsWithoutOrders(
  handlingUnits?: TransientHandlingUnit[]
) {
  if (!handlingUnits) {
    return []
  }

  return handlingUnits.map((handlingUnit) => {
    const { commodities, height, length, width, ...baseHandlingUnit } = omit(
      handlingUnit,
      ['_metadata', 'order_items', 'pickup_stop_index', 'weight_type'] as const
    )

    const totalWeight = calculateTotalWeight(commodities)
    const totalVolume = calculateTotalVolume(commodities)
    const totalDensity = calculateTotalDensity(totalWeight, totalVolume)

    const adaptedHandlingUnit = {
      ...baseHandlingUnit,
      commodities: adaptCommoditiesWithoutOrders(commodities),
      package_height: height,
      package_length: length,
      package_width: width,
      total_density: totalDensity,
      total_volume: totalVolume,
      total_weight: totalWeight,
    }

    return adaptedHandlingUnit
  })
}

export function adaptCommoditiesWithOrders(commodities?: TransientCommodity[]) {
  if (!commodities) {
    return []
  }

  return commodities.map((commodity) => {
    return {
      order_item_uuid: get(commodity, 'order_item_uuid', ''),
      shipped_weight: get(commodity, 'weight', ''),
      shipped_count: get(commodity, 'package_count', ''),
      shipped_nmfc_code: get(commodity, 'nmfc_code', ''),
      shipped_freight_class: get(commodity, 'freight_class', null),
    }
  })
}

export function adaptHandlingUnitsWithOrders(
  handlingUnits?: TransientHandlingUnit[]
): FulfillmentPayload['handling_units'] {
  if (!handlingUnits) {
    return []
  }

  return handlingUnits.map((handlingUnit) => {
    const { commodities, height, length, width, ...baseHandlingUnit } = omit(
      handlingUnit,
      ['_metadata', 'order_items', 'pickup_stop_index', 'weight_type'] as const
    )

    const totalWeight = calculateTotalWeight(commodities)
    const totalVolume = calculateTotalVolume(commodities)
    const totalDensity = calculateTotalDensity(totalWeight, totalVolume)

    const adaptedHandlingUnit = {
      ...baseHandlingUnit,
      commodities: adaptCommoditiesWithOrders(commodities),
      package_height: height,
      package_length: length,
      package_width: width,
      total_density: totalDensity,
      total_volume: totalVolume,
      total_weight: totalWeight,
    }

    return adaptedHandlingUnit
  })
}

export function createFulfillmentAdapter(
  fulfillment: TransientFulfillment
): FulfillmentPayload {
  const payload = omit(fulfillment, [
    '_metadata',
    'owner',
    'input_method',
    'po_numbers',
    'so_numbers',
    'stops',
    'handling_units',
  ] as const)

  const [pickup, delivery] = get(fulfillment, 'stops', [])

  return {
    ...payload,
    pickup_facility_uuid: defaultTo(pickup.facility?.uuid, ''),
    pickup_ready_date: pickup.date ? formatISODate(pickup.date) : '',
    pickup_contact_uuid: defaultTo(pickup.contact?.uuid, null),
    pickup_instructions: defaultTo(pickup.notes, ''),

    // when creating from orders,
    // the original PU/DEL facility UUIDs are stored in these root fields
    // in case the supplier was unable to select the delivery due to lack of access
    // we should still use the correct UUID, since the supplier has not write access to DEL
    delivery_facility_uuid: defaultTo(
      delivery.facility?.uuid ?? fulfillment.delivery_facility_uuid,
      ''
    ),
    delivery_date: delivery.date ? formatISODate(delivery.date) : '',
    delivery_contact_uuid: defaultTo(delivery.contact?.uuid, null),
    delivery_instructions: defaultTo(delivery.notes, ''),
    handling_units: adaptHandlingUnitsWithOrders(fulfillment.handling_units),
  }
}

export function createFulfillmentWithoutOrdersAdapter(
  fulfillment: TransientFulfillment
): FulfillmentWithoutOrderPayload {
  const payload = omit<TransientFulfillment>(fulfillment, [
    '_metadata',
    'owner',
    'input_method',
    'po_numbers',
    'so_numbers',
    'stops',
    'handling_units',
  ])

  const [pickup, delivery] = get(fulfillment, 'stops', [])

  return {
    ...payload,
    pickup_facility_uuid: defaultTo(pickup.facility?.uuid, ''),
    pickup_ready_date: pickup.date ? formatISODate(pickup.date) : '',
    pickup_contact_uuid: defaultTo(pickup.contact?.uuid, null),
    pickup_instructions: defaultTo(pickup.notes, ''),

    delivery_facility_uuid: defaultTo(delivery.facility?.uuid, ''),
    delivery_date: delivery.date ? formatISODate(delivery.date) : '',
    delivery_contact_uuid: defaultTo(delivery.contact?.uuid, null),
    delivery_instructions: defaultTo(delivery.notes, ''),
    handling_units: adaptHandlingUnitsWithoutOrders(fulfillment.handling_units),
  }
}
