import { useQuery } from 'react-query'

import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { getFulfillmentsAsCustomer } from 'fulfillments/fulfillment-service'

export function usePendingFulfillmentsAsCustomer() {
  return useQuery<PaginatedResult<ListFulfillment>>({
    queryKey: ['usePendingFulfillmentsAsCustomer'],
    queryFn: ({ signal }) => {
      return getFulfillmentsAsCustomer(
        { page: 1, page_size: 500, status: ['pending'] },
        { signal }
      )
    },
  })
}
