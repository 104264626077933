import { get } from 'lodash-es'
import { useCallback } from 'react'
import type { ReactNode } from 'react'

import { createTransientCollectionSetup } from 'components/TransientCollectionForm'
import { useIndexerContext } from 'contexts/indexer'
import { useCustomFormField } from 'hooks/useCustomFormField'
import { getMetadata, getTransientError } from 'utils/transient'

import type { TransientCommodity } from '../HandlingUnits.types'

const {
  TransientCollectionForm,
  useTransientCollectionFormContext,
  TransientCollectionFormContext,
} = createTransientCollectionSetup<TransientCommodity>()

export const CommoditiesTransientCollectionForm = TransientCollectionForm
export const useCommoditiesForm = useTransientCollectionFormContext
export const CommoditiesFormContext = TransientCollectionFormContext

type UseCommodityFormFieldProps = {
  name: string
  hint?: ReactNode
}

export function useCommodityFormField(props: UseCommodityFormFieldProps) {
  const { name, hint } = props

  const [items, dispatch] = useCommoditiesForm()
  const index = useIndexerContext()
  const item = items[index]
  const error = getTransientError(item, name)
  const controlProps = getMetadata(item, ['controls', name])
  const fieldProps = getMetadata(item, ['fields', name])

  const formFieldProps = useCustomFormField({
    index,
    // prevent duplicate ids
    name: `commodity-${name}`,
    hint,
    error,
    controlProps,
    fieldProps,
  })

  const setItem = useCallback(
    (changes: Partial<TransientCommodity>) => {
      dispatch({
        type: 'SET_ITEM',
        payload: { index, changes },
      })
    },
    [dispatch, index]
  )

  const value = get(item, name)

  const setValue = useCallback(
    (newValue: TransientCommodity[keyof TransientCommodity]) => {
      setItem({ [name]: newValue })
    },
    [setItem, name]
  )

  return {
    index,
    item,
    dispatch,
    setItem,
    value,
    setValue,
    items,
    ...formFieldProps,
  }
}
