import { isEmpty } from 'lodash-es'
import { useQuery } from 'react-query'

import { listOrders, MAX_LIMIT } from 'orders/order-service'
import { getListOrdersParams } from 'orders/order-service.utils'

export function useGetOrdersByUUID(UUIDs: string[], enabled: boolean = false) {
  const filterParams = getListOrdersParams({
    limit: MAX_LIMIT,
    offset: 0,
    uuid: !isEmpty(UUIDs) ? UUIDs.join(',') : null,
  })

  const query = useQuery({
    enabled,
    queryKey: ['getOrdersByUUID', filterParams],
    queryFn: ({ signal }) =>
      listOrders(
        {
          ...filterParams,
        },
        { signal }
      ),
    refetchOnWindowFocus: false,
  })

  return {
    ...query,
  }
}
