import { IconDotsHorizontal } from '@loadsmart/icons'
import {
  Button,
  Dropdown,
  DropdownContext,
  Layout,
  Popover,
  Text,
} from '@loadsmart/loadsmart-ui'
import type { DropdownMenuItemProps } from '@loadsmart/loadsmart-ui/dist/components/Dropdown'
import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { useContext } from 'react'
import type { HTMLAttributes, MouseEvent, ReactNode } from 'react'
import styled from 'styled-components'

import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

const DividerWrapper = styled.div`
  padding: ${getToken('space-s')} ${getToken('space-m')};
`

const DividerLine = styled.div`
  background-color: ${getToken('color-neutral-lighter')};
  height: 1px;
  width: 100%;
`

const Divider = () => (
  <DividerWrapper>
    <DividerLine />
  </DividerWrapper>
)

const IconDotsVertical = styled(IconDotsHorizontal)`
  transform: rotate(90deg);
`

function ActionTrigger({
  title,
  onClick,
  ...props
}: {
  readonly title?: string
  readonly onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}) {
  const { toggle, disabled } = useContext(DropdownContext)

  return (
    <Popover.Reference role="presentation">
      <Button
        style={{ paddingLeft: 0, paddingRight: 0, margin: 0 }}
        scale="small"
        variant="tertiary"
        disabled={disabled}
        onClick={(event) => {
          onClick?.(event)
          toggle()
        }}
        data-testid="dots-selector"
        title={title}
        aria-label={title}
        {...props}
      >
        <IconDotsVertical height={16} width={16} />
      </Button>
    </Popover.Reference>
  )
}

interface TableActionsProps extends HTMLAttributes<HTMLButtonElement> {
  readonly disabled?: boolean
  readonly align?: 'start' | 'end'
}

const TableActions = ({
  children,
  onClick,
  disabled = false,
  align = 'end',
  title = 'toggle row actions',
  ...props
}: TableActionsProps) => {
  return (
    <Dropdown disabled={disabled} align={align}>
      <ActionTrigger
        title={title}
        aria-label={title}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation()
          onClick?.(event)
          analytics.track(
            'Shipments Overview / Actions Dropdown / Click',
            AnalyticsEventTrigger.click
          )
        }}
        {...props}
      />
      <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
        {children}
      </Dropdown.Menu>
    </Dropdown>
  )
}

interface TableActionsItemProps extends DropdownMenuItemProps {
  readonly caption?: ReactNode
}

const TableActionsItem = ({
  children,
  onClick,
  caption = '',
  ...props
}: TableActionsItemProps) => (
  <Dropdown.Item
    onClick={(event) => {
      event.stopPropagation()
      onClick?.(event)
    }}
    {...props}
  >
    <Layout.Stack space="2xs">
      {children}
      {caption ? (
        <Text variant="caption" color="color-neutral-dark">
          {caption}
        </Text>
      ) : null}
    </Layout.Stack>
  </Dropdown.Item>
)

TableActions.Item = TableActionsItem
TableActions.Divider = Divider

export default TableActions
