import { Layout, Accordion, Tag } from '@loadsmart/miranda-react'
import { useState } from 'react'

import { useSettings } from '_shared_/settings/useSettings'
import useAccessorialsPermissions from 'hooks/useAccessorialsPermissions'

import { AccessorialsMultiChoice } from './AccessorialsMultiChoice'
import {
  AccessorialsSelect,
  AccessorialsSelectFooter,
} from './AccessorialsSelect'

type AccessorialsAccordionProps = {
  readonly mode: TransportationModeCode
  readonly usage: AccountingObjectType
  readonly values: {
    requested_accessorials: string[]
    accessorials: string[]
  }
  readonly onChange: (
    fieldName: 'requested_accessorials' | 'accessorials',
    value: string[]
  ) => void
  readonly hint?: string
}

export function AccessorialsAccordion({
  mode,
  usage,
  values,
  onChange: onChangeProp,
  hint,
}: AccessorialsAccordionProps) {
  const {
    values: [favoriteAccessorialsEnabled],
  } = useSettings(['flags.ENABLE_FAVORITE_ACCESSORIALS'])
  const { accessorialsIsEnabled, priceItemTypesEnabled } =
    useAccessorialsPermissions({ mode })

  const [accordionIsExpanded, setAccordionIsExpanded] = useState(false)

  if (!accessorialsIsEnabled) {
    return null
  }

  const fieldName = priceItemTypesEnabled
    ? 'requested_accessorials'
    : 'accessorials'

  const onChange = (value: string[]) => {
    onChangeProp(fieldName, value)
  }

  // AccessorialsSelect only works with favorite accessorials and price item types
  if (favoriteAccessorialsEnabled && priceItemTypesEnabled) {
    return (
      <Accordion
        expanded={accordionIsExpanded}
        onToggle={(event) => {
          setAccordionIsExpanded(event.detail.value)
        }}
      >
        <Accordion.Title>
          <Layout.Group gap="spacing-2" align="center">
            <span>Accessorials</span>
            {values.requested_accessorials.length > 0 && (
              <Tag size="small">{values.requested_accessorials.length}</Tag>
            )}
          </Layout.Group>
        </Accordion.Title>
        <Accordion.Content>
          <Layout.Stack>
            <AccessorialsSelect
              mode={mode}
              usage={usage}
              queryOptions={{ enabled: accordionIsExpanded }}
              value={values.requested_accessorials}
              onChange={onChange}
            />
            <AccessorialsSelectFooter />
          </Layout.Stack>
        </Accordion.Content>
      </Accordion>
    )
  }

  // AccessorialsMultiChoice can work with either accessorials or price item types
  return (
    <AccessorialsMultiChoice
      label="Accessorials"
      mode={mode}
      usage={usage}
      value={values[fieldName]}
      onChange={onChange}
      hint={hint}
    />
  )
}
