import { getToken } from '@loadsmart/loadsmart-ui/dist/theming'
import { Layout } from '@loadsmart/miranda-react'
import styled from 'styled-components'

export const FORM_MAX_WIDTH = '712px'
const FORM_HEADER_HEIGHT = '136px'
const FORM_HEADER_HEIGHT_SMALL = '120px'
const STICKY_FOOTER_HEIGHT = '106px'

export const FormWrapper = styled(Layout.Stack)`
  > * {
    max-width: 712px;
  }
  min-height: calc(
    100vh - ${getToken('top-navigation-height')} - ${FORM_HEADER_HEIGHT_SMALL} -
      ${STICKY_FOOTER_HEIGHT}
  );
  @media (min-width: 1026px) {
    min-height: calc(
      100vh - ${getToken('top-navigation-height')} - ${FORM_HEADER_HEIGHT} -
        ${STICKY_FOOTER_HEIGHT}
    );
  }
`

export const StickyFooter = styled(Layout.Box).attrs({
  borderWidth: 'border-thin',
  borderRadius: 'border-radius-none',
  borderColor: 'color-neutral-20',
  backgroundColor: 'color-background-primary',
})`
  bottom: 0;
  position: sticky;
`
