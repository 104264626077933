import { Divider, Field, Layout, Select, Text } from '@loadsmart/miranda-react'
import { defaultTo, flatten } from 'lodash-es'
import { useMemo } from 'react'

import { GridV2 } from 'components/Grid'
import {
  CommoditiesForm,
  CommodityForm,
} from 'components/HandlingUnitsManager/CommoditiesForm/CommoditiesForm'
import { useCommodityFormField } from 'components/HandlingUnitsManager/CommoditiesForm/CommoditiesForm.hooks'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import type { CommodityPackageType } from 'components/ShippingItemsManager'
import { useFulfillmentFormContext } from 'fulfillments/components/FulfillmentFormContext'
import { formatOrderItemLabel } from 'orders/orders.utils'
import type { ListOrderItem } from 'orders/types'
import { hasMoreThanOneItem } from 'utils/arrays'

export function mapOrderItemToHandlingUnitCommodity(
  handling_units: TransientHandlingUnit[] | undefined,
  handlingUnit: TransientHandlingUnit,
  commodityIndex: number,
  orderItem: ListOrderItem
) {
  return handling_units?.map((handlingUnitItem) => {
    if (handlingUnit._metadata?.id === handlingUnitItem._metadata?.id) {
      return {
        ...handlingUnitItem,
        commodities: handlingUnitItem.commodities.map(
          (commodityItem, index) => {
            if (index === commodityIndex) {
              return {
                ...commodityItem,
                order_item_uuid: orderItem.uuid,
                description: orderItem.commodity,
                weight: defaultTo(orderItem.total_weight, ''),
                package_count: orderItem.package_count,
                // TODO review OrderItem package_type typing
                package_type: orderItem.package_type as CommodityPackageType,
                nmfc_code: orderItem.nmfc_code,
                freight_class: orderItem.freight_class,
              }
            }
            return commodityItem
          }
        ),
      }
    }

    return handlingUnitItem
  })
}

function OrderItemSelect({
  commodityIndex,
  handlingUnit,
}: {
  readonly commodityIndex: number
  readonly handlingUnit: TransientHandlingUnit
}) {
  const { fulfillment, selectedOrders, setPartialFulfillment } =
    useFulfillmentFormContext()
  const { controlProps, fieldProps, hintProps, labelProps, value } =
    useCommodityFormField({ name: 'order_item_uuid' })

  const allOrderItems = useMemo(
    () => flatten(selectedOrders?.map((order) => order.items)),
    [selectedOrders]
  )

  if (!selectedOrders?.length) {
    return null
  }

  const options = selectedOrders.reduce<{ value: string; label: string }[]>(
    (accumulatedOptions, order) => {
      return [
        ...accumulatedOptions,
        ...order.items.map((item) => ({
          label: formatOrderItemLabel(item, order),
          value: item.uuid,
        })),
      ]
    },
    []
  )

  return (
    <Field {...fieldProps} required>
      <Field.Label {...labelProps}>Order item</Field.Label>
      <Select
        {...controlProps}
        placeholder="Select order item"
        id="order-item"
        name="order-item"
        options={options}
        disabled={!options.length}
        value={value}
        onChange={(event) => {
          const orderItemUUID = event.target.value?.toString()

          const selectedOrderItem = allOrderItems.find(
            (item) => item.uuid === orderItemUUID
          )

          if (selectedOrderItem) {
            setPartialFulfillment({
              handling_units: mapOrderItemToHandlingUnitCommodity(
                fulfillment.handling_units,
                handlingUnit,
                commodityIndex,
                selectedOrderItem
              ),
            })
          }
        }}
      />
      <Field.Hint {...hintProps}>Select a single Order Item</Field.Hint>
    </Field>
  )
}

function OrderIdentifierSelect() {
  const { fulfillment } = useFulfillmentFormContext()
  const { controlProps, fieldProps, hintProps, labelProps, value, setValue } =
    useCommodityFormField({ name: 'order_identifier' })

  const label =
    fulfillment.order_identifier_type === 'po_number'
      ? 'PO Number'
      : 'SO Number'
  const values =
    fulfillment.order_identifier_type === 'po_number'
      ? fulfillment.po_numbers
      : fulfillment.so_numbers
  const options = values
    .trim()
    .split(',')
    .map((o) => ({ label: o, value: o }))

  return (
    <Field {...fieldProps} required>
      <Field.Label {...labelProps}>{label}</Field.Label>
      <Select
        {...controlProps}
        placeholder={`Select ${label}`}
        id="order-identifier"
        name="order-identifier"
        options={options}
        disabled={!options.length}
        value={value}
        onChange={(event) => {
          setValue(event.target.value?.toString())
        }}
      />
      <Field.Hint {...hintProps}>Select a single PO Number</Field.Hint>
    </Field>
  )
}

export function FulfillmentCommodity({
  commodityIndex,
  handlingUnit,
}: {
  readonly commodityIndex: number
  readonly handlingUnit: TransientHandlingUnit
}) {
  const { fulfillment, action } = useFulfillmentFormContext()

  const isFulfillmentFromExistingOrder =
    fulfillment.order_identifier_type === 'orders'

  return (
    <CommodityForm index={commodityIndex}>
      <Layout.Stack aria-label={`Commodity #${commodityIndex + 1}`}>
        <Layout.Stack gap="spacing-1">
          <Layout.Group align="center" justify="space-between">
            <Text variant="body-md-bold" aria-hidden="true">
              {`Commodity #${commodityIndex + 1}`}
            </Text>

            {action === 'create' &&
              hasMoreThanOneItem(handlingUnit.commodities) && (
                <CommoditiesForm.RemoveCommodity />
              )}
          </Layout.Group>
          <Divider />
        </Layout.Stack>

        {isFulfillmentFromExistingOrder ? (
          <OrderItemSelect
            commodityIndex={commodityIndex}
            handlingUnit={handlingUnit}
          />
        ) : (
          <OrderIdentifierSelect />
        )}

        <Layout.Stack>
          <Layout.Stack gap="none">
            <CommodityForm.Description
              hint={<>&nbsp;</>}
              disabled={isFulfillmentFromExistingOrder}
            />

            <GridV2 columns={3} align="flex-end">
              <CommodityForm.Weight hint={<>&nbsp;</>} required />
              <CommodityForm.PackageCount
                hint={<>&nbsp;</>}
                required={isFulfillmentFromExistingOrder}
              />
              <CommodityForm.PackageType
                hint={<>&nbsp;</>}
                disabled={isFulfillmentFromExistingOrder}
              />
            </GridV2>

            <GridV2 columns={3} align="flex-end">
              <CommodityForm.Nmfc hint={<>&nbsp;</>} />
              <CommodityForm.FreightClass hint={<>&nbsp;</>} />
              <CommodityForm.NmfcSearch hint={<>&nbsp;</>} />
            </GridV2>
          </Layout.Stack>
        </Layout.Stack>
      </Layout.Stack>
    </CommodityForm>
  )
}
