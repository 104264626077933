import { Header, PageContent } from '@loadsmart/miranda-react'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { WindowTitle } from '_shared_/components/WindowTitle'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

import { FulfillmentFormProvider } from '../components/FulfillmentFormContext'
import { CreateFulfillmentForm } from './CreateFulfillmentForm'

export function CreateFulfillmentPage() {
  const location = useLocation<{ orders: string[] }>()
  const history = useHistory()

  const selectedOrdersUUIDs = location.state?.orders ?? []

  function handleBack() {
    history.goBack()
  }

  useEffect(() => {
    analytics.track('Viewed: New Fulfillment', AnalyticsEventTrigger.view)
  }, [])

  return (
    <PageContent>
      <WindowTitle title="New fulfillment | ShipperGuide" />
      <Header>
        <Header.BackButton onClick={handleBack} />
        <Header.Content>
          <Header.Title>New fulfillment</Header.Title>
        </Header.Content>
      </Header>
      <PageContent.Body paddingX="none" paddingB="none">
        <FulfillmentFormProvider action="create">
          <CreateFulfillmentForm selectedOrdersUUIDs={selectedOrdersUUIDs} />
        </FulfillmentFormProvider>
      </PageContent.Body>
    </PageContent>
  )
}
