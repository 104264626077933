import { defaultTo } from 'lodash-es'
import { useQuery } from 'react-query'

import { listOrders, MAX_LIMIT } from 'orders/order-service'
import { getListOrdersParams } from 'orders/order-service.utils'

export type UseFulfillableOrdersParams = {
  asSupplier?: boolean
  enabled?: boolean
}

export function useFulfillableOrders({
  asSupplier = false,
  enabled = true,
}: UseFulfillableOrdersParams = {}) {
  const params = getListOrdersParams({
    limit: MAX_LIMIT,
    offset: 0,
    search_term: null,
    status: ['open', 'planning'],
  })

  const { data, isError, isLoading } = useQuery({
    queryKey: ['getPlannableOrders', asSupplier, params],
    queryFn: () =>
      listOrders({
        ...params,
        view: asSupplier ? 'supplier' : 'customer',
      }),
    cacheTime: 60000,
    enabled,
  })

  const orders = defaultTo(data?.results, [])

  return {
    isError,
    isLoading,
    orders,
  }
}
