import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useOauth2 } from '_shared_/hooks/useOauth2'
import { userUtils } from '_shared_/user'
import analytics from 'utils/analytics'

export const useAuth = () => {
  const history = useHistory()
  const { logOut, oAuth2Flow } = useOauth2()

  const logout = useCallback(() => {
    if (oAuth2Flow.isDisabled) {
      userUtils.cleanUserStorage()
      analytics.reset()
      history.replace(userUtils.loginURL)
    } else {
      logOut()
    }
  }, [history, oAuth2Flow.isDisabled, logOut])

  return {
    logout,
  }
}
