import { Field, Select } from '@loadsmart/miranda-react'
import type { SelectOption } from '@loadsmart/miranda-react'

import type { CustomFormFieldProps, WeightType } from '../HandlingUnits.types'
import { useHandlingUnitFormField } from './HandlingUnitsForm.hooks'

export const WEIGHT_TYPE_MAP = new Map<WeightType, string>([
  ['all_packages', 'Total weight'] as const,
  ['per_package', 'Per H/U'] as const,
] as const)

export const WEIGHT_TYPE_OPTIONS: Array<SelectOption> = Array.from(
  WEIGHT_TYPE_MAP,
  ([value, label]) => ({ value, label })
)

export const HandlingUnitWeightType = (props: CustomFormFieldProps) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    labelProps,
    value,
    setValue,
    name,
  } = useHandlingUnitFormField({ name: 'weight_type', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Set the weight</Field.Label>
      <Select
        type="single-strict"
        disabled={controlProps.disabled}
        aria-labelledby={controlProps['aria-labelledby']}
        name={name}
        placeholder="Select"
        options={WEIGHT_TYPE_OPTIONS}
        value={value}
        onChange={(event) => {
          setValue(event.target.value as string)
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
