import { isEmpty } from 'lodash-es'

import { getLabel } from 'components/AppliedFilters'
import type { Fulfillment } from 'fulfillments/domain/Fulfillment'
import { FULFILLMENT_STATUS_LABEL } from 'fulfillments/domain/Fulfillment'
import type { GetFulfillmentsFilters } from 'fulfillments/hooks/useFulfillmentList'
import type { Filters } from 'utils/filters'

type FilterValues = Filters<GetFulfillmentsFilters>

const LABELS: Partial<Record<keyof FilterValues, string>> = {
  status: 'Status',
  search: '',
  created_by: 'Created by',
}

const VALUE_FORMATTER: Partial<
  Record<keyof FilterValues, (filters: FilterValues) => string>
> = {
  status: statusFormatter,
  search: searchFormatter,
  source: sourceFormatter,
  created_by: createdByFormatter,
}

export function searchFormatter(filters: FilterValues = {}) {
  return filters.search ?? ''
}

export function createdByFormatter(filters: FilterValues = {}) {
  return filters.created_by ?? ''
}

export function sourceFormatter(filters: FilterValues = {}) {
  switch (filters.source) {
    case 'manual':
      return 'Created manually'
    case 'auto-fulfilled':
      return 'Created automatically'
    default:
      return ''
  }
}

export function statusFormatter(filters: FilterValues = {}) {
  if (!filters.status) {
    return ''
  }

  return getLabel(
    filters.status,
    (item) => FULFILLMENT_STATUS_LABEL[item as Fulfillment['status']]
  )
}

export function getHasAppliedFilters(filters: FilterValues) {
  if (isEmpty(filters)) {
    return false
  }

  const keys = Object.keys(filters) as Array<keyof FilterValues>

  return keys.reduce(
    (hasValue, key) => hasValue || !isEmpty(filters[key]),
    false
  )
}

export function getTagLabel(
  filters: FilterValues,
  filterKey: keyof FilterValues
): string {
  let filterLabel = ''
  let itemsLabel: string | null = ''
  const formatter = VALUE_FORMATTER[filterKey]
  if (formatter && !isEmpty(filters[filterKey])) {
    filterLabel = LABELS[filterKey] ?? filterLabel
    itemsLabel = formatter(filters)
  }
  return `${filterLabel} ${itemsLabel}`.trim()
}

export function getAppliedFilterTags(filters: FilterValues) {
  const keys = Object.keys(filters) as Array<keyof FilterValues>

  const mapped = keys
    .map((filterKey) => {
      return {
        label: getTagLabel(filters, filterKey),
        value: filterKey,
      }
    })
    .filter(({ label }) => !isEmpty(label))
  return mapped
}
