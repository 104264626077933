import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { appRoutes } from 'router/app-routes'

export default function useLinkToFulfillmentsPlan(
  selectedUUIDs?: string[] | string
) {
  const history = useHistory()

  const onClick = useCallback(() => {
    if (selectedUUIDs == null) {
      history.push(appRoutes.fulfillmentsPlanShipment)
    } else {
      const params = new URLSearchParams()
      const UUIDs = Array.isArray(selectedUUIDs)
        ? selectedUUIDs
        : [selectedUUIDs]

      UUIDs.forEach((UUID) => {
        params.append('fulfillments', UUID)
      })

      history.push({
        pathname: appRoutes.fulfillmentsPlanShipment,
        search: `?${params.toString()}`,
      })
    }
  }, [history, selectedUUIDs])

  return onClick
}
