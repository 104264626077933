import { Link, Text } from '@loadsmart/miranda-react'
import { generatePath } from 'react-router-dom'

import type { FulfillmentTypes } from 'fulfillments/domain/Fulfillment'
import { appRoutes } from 'router/app-routes'

export function FulfillmentLink({
  fulfillment,
}: Readonly<{
  fulfillment: FulfillmentTypes
}>) {
  return (
    <Link
      href={generatePath(appRoutes.fulfillmentDetails, {
        fulfillmentUuid: fulfillment.uuid,
      })}
      size="small"
      target="_blank"
    >
      <Text color="color-primary-100" variant="body-sm-bold">
        {fulfillment.ref_number}
      </Text>
    </Link>
  )
}
