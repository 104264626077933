import { IconClose } from '@loadsmart/icons'
import { useEffect, useMemo, useRef } from 'react'
import { useQueryClient } from 'react-query'
import type { QueryClient } from 'react-query'

import { useSettings } from '_shared_/settings/useSettings'
import {
  CloseSidebarWrapper,
  Title,
  TitleContainer,
  TitleLoaderWrapper,
} from 'components/SideBar/styles'
import { usePreventPageScrollOver } from 'hooks/usePreventPageScrollOver'
import { useLaneProposalsRetrieve } from 'hooks/useQuery'
import Benchmark from 'rfp/components/benchmark'
import LaneInfoV1 from 'rfp/components/lane-info'
import type { RequestForProposal } from 'rfp/rfp.types'

import LaneBidsV1 from '../lane-bids'
import LaneBidsRemovedV1 from '../lane-bids-removed'
import { RfpBidsInProgress } from './RfpBidsInProgress'
import { Container, InnerContainerScroll } from './styles'

export function setLaneAsViewed(laneId: number, queryClient: QueryClient) {
  /**
   * queryClient.setQueryData() would be suffice here, but since we don't have all data that created the queryKey
   * and since setQueryData doesn't accept filters (like exact: false) it was needed to use a more
   * "generic" function, in order to achieve what we want.
   */
  queryClient.setQueriesData<PaginatedResult | undefined>(
    { queryKey: ['deallocatedLanes'], exact: false },
    (oldData) => {
      if (!oldData) {
        return oldData
      }

      const oldDataCopy = structuredClone(oldData)

      oldDataCopy.results.forEach((lane: Lane) => {
        if (laneId === lane.id) {
          lane.has_new_bids = false
        }
      })

      return oldDataCopy
    }
  )
}

interface Props {
  readonly carrierInvites: NewCarrierInvite[]
  readonly lane: Lane
  readonly rfp: RequestForProposal
  readonly onClose: () => void
  readonly awardScenariosCallback?: () => void
}

function RoutingGuide({
  carrierInvites,
  lane,
  rfp,
  awardScenariosCallback,
  onClose,
}: Props) {
  const scrollerRef = useRef<HTMLDivElement>(null)
  usePreventPageScrollOver(scrollerRef)

  const {
    data: proposals = [],
    isLoading,
    refetch: refetchProposals,
  } = useLaneProposalsRetrieve(lane.id, {
    enabled: !!lane,
    refetchOnWindowFocus: false,
  })
  const queryClient = useQueryClient()

  const {
    values: [enableAskToHurryUp],
  } = useSettings(['flags.ENABLE_ASK_TO_HURRY_UP_RFP_BIDS'])

  const { bidsReceived, bidsRemoved, bidsResults } = useMemo(() => {
    const proposalsReceived = proposals.filter(
      (proposal) => !proposal.is_removed
    )
    const proposalsRemoved = proposals.filter((proposal) => proposal.is_removed)
    const proposalsResults = proposals.filter(
      (proposal) => proposal.awarded_type !== null
    )

    return {
      bidsReceived: proposalsReceived,
      bidsRemoved: proposalsRemoved,
      bidsResults: proposalsResults,
    }
  }, [proposals])

  useEffect(() => {
    refetchProposals()
  }, [lane, refetchProposals])

  useEffect(() => {
    setLaneAsViewed(lane.id, queryClient)
  }, [lane.id, queryClient])

  return (
    <Container data-testid="proposalsContainer">
      <TitleContainer>
        <Title>
          Routing Guide
          {isLoading && <TitleLoaderWrapper />}
        </Title>
        <CloseSidebarWrapper onClick={onClose}>
          <IconClose width={16} height={16} />
        </CloseSidebarWrapper>
      </TitleContainer>
      <InnerContainerScroll ref={scrollerRef}>
        <LaneInfoV1 lane={lane} rfpDistanceType={rfp.distance_type} />
        {lane && proposals && (
          <Benchmark
            lane={lane}
            proposals={proposals}
            isLoading={isLoading}
            currency={rfp.currency}
          />
        )}
        <LaneBidsV1
          lane={lane}
          laneProposals={bidsReceived}
          isLoadingLaneProposals={isLoading}
          carrierInvites={carrierInvites}
          bidsResults={bidsResults}
          rfpState={rfp.state}
          rfp={rfp}
          awardScenariosCallback={awardScenariosCallback}
        />
        {bidsRemoved.length > 0 && (
          <LaneBidsRemovedV1
            rfpCurrency={rfp.currency}
            lane={lane}
            removedLaneProposals={bidsRemoved}
            isLoadingLaneProposals={isLoading}
            rfpDistanceType={rfp.distance_type}
          />
        )}
        {enableAskToHurryUp && (
          <RfpBidsInProgress
            carrierInvites={carrierInvites}
            onRefetchCarrierInvites={() => {
              // the refetch will be handled in a separate task
            }}
          />
        )}
      </InnerContainerScroll>
    </Container>
  )
}

export default RoutingGuide
