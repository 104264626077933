import { createContext, useContext } from 'react'

import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'

interface OneClickPlanFulfillmentContextValue {
  enabled: boolean
  open: (fulfillments: ListFulfillment[]) => void
}

export const OneClickPlanFulfillmentContext =
  createContext<OneClickPlanFulfillmentContextValue>({
    enabled: false,
    open: () => {},
  })

export function useOneClickPlanFulfillmentContext() {
  return useContext(OneClickPlanFulfillmentContext)
}
