import { isObject } from 'lodash-es'
import { toast } from 'react-toastify'

import type { ShipmentStatus, ShipmentStatusOrString } from 'utils/shipment'

export function isShipmentStatusUpdatable(
  status: ShipmentStatusOrString
): boolean {
  const updatableStatuses = [
    'booked',
    'en_route_pickup',
    'at_pickup',
    'en_route_delivery',
    'at_delivery',
    'accounting_review',
  ]
  return updatableStatuses.includes(status)
}

export const SHIPMENT_STATUSES_OPTIONS: ReadonlyArray<
  Readonly<{
    value: ShipmentStatus
    label: string
  }>
> = [
  { value: 'booked', label: 'Booked' },
  { value: 'en_route_pickup', label: 'En route to pickup' },
  { value: 'at_pickup', label: 'At pickup' },
  { value: 'en_route_delivery', label: 'En route to delivery' },
  { value: 'at_delivery', label: 'At delivery' },
  { value: 'accounting_review', label: 'Accounting review' },
]

export const handleToastError = (value: unknown) => {
  const getErrorMessages = (errorMessage: unknown): string[] => {
    if (typeof errorMessage === 'string') {
      return [errorMessage]
    }

    if (Array.isArray(errorMessage)) {
      return errorMessage.map((message) => getErrorMessages(message)).flat()
    }

    if (isObject(errorMessage)) {
      return Object.values(errorMessage).reduce(
        (acc, message) => [...acc, ...getErrorMessages(message)],
        [] as string[]
      )
    }

    return []
  }

  const errorMessages = getErrorMessages(value)

  errorMessages.forEach((errorMessage) => {
    toast.error(errorMessage)
  })
}
