import { Layout, Link, Table, Text, Tooltip } from '@loadsmart/miranda-react'

import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import {
  formatFulfillmentDeliveryDate,
  formatFulfillmentEquipmentType,
  formatFulfillmentHandlingUnitShippedWeight,
  formatFulfillmentHandlingUnitTitle,
  formatFulfillmentPickupReadyDate,
  formatFulfillmentTotalPieceCount,
  formatFulfillmentWeight,
} from 'fulfillments/fulfillment-utils'

interface FulfillmentTableRowProps {
  readonly fulfillment: ListFulfillment
}

export function HandlingUnitsCell({
  fulfillment,
}: {
  readonly fulfillment: ListFulfillment
}) {
  const handlingUnits = fulfillment.handling_units ?? []

  if (handlingUnits.length === 0) {
    return '-'
  }

  if (handlingUnits.length === 1) {
    return (
      <Layout.Stack gap="none">
        {formatFulfillmentHandlingUnitTitle(handlingUnits[0])}
        <Text variant="body-sm" color="color-text-tertiary">
          {formatFulfillmentHandlingUnitShippedWeight(handlingUnits[0])}
        </Text>
      </Layout.Stack>
    )
  }

  return (
    <Layout.Stack gap="none">
      <Tooltip
        message={
          <Layout.Stack gap="none">
            {handlingUnits.map((item) => (
              <Text key={item.uuid} color="color-text-inverted">
                {formatFulfillmentHandlingUnitTitle(handlingUnits[0])}
              </Text>
            ))}
          </Layout.Stack>
        }
        placement="top"
        trigger="hover"
        data-testid="handling-unit-tooltip"
      >
        <Text color="color-text-link" variant="body-md-bold-underline">
          {formatFulfillmentTotalPieceCount(fulfillment)}
        </Text>
      </Tooltip>
      <Text variant="body-sm" color="color-text-tertiary">
        {formatFulfillmentWeight(fulfillment)}
      </Text>
    </Layout.Stack>
  )
}

const FulfillmentTableRow = ({ fulfillment }: FulfillmentTableRowProps) => {
  const formattedPickupDateWindow =
    formatFulfillmentPickupReadyDate(fulfillment)
  const formattedDeliveryDateWindow = formatFulfillmentDeliveryDate(fulfillment)

  return (
    <Table.Row
      key={fulfillment.uuid}
      data-testid={`selected-fulfillments-table-row-${fulfillment.uuid}`}
    >
      <Table.Cell>
        <Layout.Group align="center" gap="spacing-1" padding="none">
          <Link href={`/fulfillments/${fulfillment.uuid}`} target="_blank">
            {fulfillment.ref_number}
          </Link>
          <Text variant="body-sm" color="color-text-tertiary">
            {formatFulfillmentEquipmentType(fulfillment)}
          </Text>
        </Layout.Group>
      </Table.Cell>

      <Table.Cell>
        <Layout.Stack gap="none">
          {fulfillment.pickup_facility.name}
          <Text variant="body-sm" color="color-text-tertiary">
            {formattedPickupDateWindow}
          </Text>
        </Layout.Stack>
      </Table.Cell>

      <Table.Cell>
        <Layout.Stack gap="none">
          {fulfillment.delivery_facility.name}
          <Text variant="body-sm" color="color-text-tertiary">
            {formattedDeliveryDateWindow}
          </Text>
        </Layout.Stack>
      </Table.Cell>

      <Table.Cell>
        <HandlingUnitsCell fulfillment={fulfillment} />
      </Table.Cell>
    </Table.Row>
  )
}

export function FulfillmentSelection({
  fulfillments,
}: {
  readonly fulfillments: ListFulfillment[]
}) {
  return (
    <Table data-testid="selected-fulfillments-table">
      <Table.Head>
        <Table.Row>
          <Table.Cell>Primary ref</Table.Cell>
          <Table.Cell>Origin</Table.Cell>
          <Table.Cell>Destination</Table.Cell>
          <Table.Cell>Handling units</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {fulfillments.map((fulfillment) => (
          <FulfillmentTableRow
            key={fulfillment.uuid}
            fulfillment={fulfillment}
          />
        ))}
      </Table.Body>
    </Table>
  )
}
