import { Select } from '@loadsmart/loadsmart-ui'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import { Field } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties, ReactNode } from 'react'

import { useSubEquipments } from 'hooks/useMetadata'
import { areEquipmentTypesEqual } from 'utils/equipmentTypeV2'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationFormField } from './useFreightInformationFormField'

export const SUB_EQUIPMENT_TYPE_NAME = 'equipment_subtypes'
const SUB_EQUIPMENT_TYPE_LABEL = 'Flatbed subequipment'

type FreightInformationSubEquipmentFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly disabled?: boolean
  readonly hint?: ReactNode
  readonly error?: string
  readonly style?: CSSProperties
  readonly label?: string
}

export function FreightInformationSubEquipmentFormField({
  required,
  disabled,
  hint,
  error,
  style,
  label = SUB_EQUIPMENT_TYPE_LABEL,
}: FreightInformationSubEquipmentFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()
  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: SUB_EQUIPMENT_TYPE_NAME,
    hint,
    error,
  })
  const showSubEquipments = areEquipmentTypesEqual(
    shipment.equipment_type!,
    'FBE'
  )
  const { data: subEquipments } = useSubEquipments({
    enabled: showSubEquipments,
  })

  if (!showSubEquipments) {
    return null
  }

  const flatBedSubEquipments = subEquipments?.FBE

  const selectedSubEquipment =
    flatBedSubEquipments?.find(
      (subEquipment) =>
        subEquipment.value === shipment[SUB_EQUIPMENT_TYPE_NAME]?.[0]
    ) || null

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${SUB_EQUIPMENT_TYPE_NAME}-field`}
    >
      <Field.Label>{label}</Field.Label>
      <Select
        name={SUB_EQUIPMENT_TYPE_NAME}
        placeholder="Flatbed subequipment"
        options={flatBedSubEquipments}
        value={selectedSubEquipment as Selectable}
        onChange={(event) => {
          const subEquipment = get(event, 'target.value.value')
          const value = subEquipment ? [subEquipment] : []
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [SUB_EQUIPMENT_TYPE_NAME]: value,
            },
          })
        }}
        disabled={disabled}
        hideClear={required}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
