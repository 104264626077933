import { Dialog } from '@loadsmart/miranda-react'
import { isEmpty, noop } from 'lodash-es'
import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { deleteOrder } from 'orders/order-service'
import type { ListOrder, Order } from 'orders/types'

export function isOrderDeletable(order: ListOrder | Order) {
  return !['deleted', 'planned'].includes(order.status.toLowerCase())
}

export interface DeleteOrderContextValue {
  closeDialog: () => void
  confirm: () => void
  isDeleting?: boolean
  openDialog: (id?: string) => void
  selectedOrderId?: string
}

export const DeleteOrderContext = createContext<DeleteOrderContextValue>({
  closeDialog: noop,
  confirm: noop,
  isDeleting: false,
  openDialog: noop,
  selectedOrderId: undefined,
})

export function useDeleteOrderMutation(callback?: () => void) {
  const [selectedOrderId, setSelectedOrderId] = useState<string | undefined>()

  const { isLoading: isDeleting, mutate } = useMutation({
    mutationFn: deleteOrder,
    onSuccess() {
      toast.success('Order successfully deleted.')
      callback?.()
    },
    onError() {
      toast.error('Order could not be deleted.')
    },
    onSettled() {
      setSelectedOrderId(undefined)
    },
  })

  const confirm = useCallback(() => {
    if (!selectedOrderId) {
      return
    }

    mutate(selectedOrderId)
  }, [mutate, selectedOrderId])

  return {
    confirm,
    isDeleting,
    selectedOrderId,
    setSelectedOrderId,
  }
}

export function useDeleteOrderContext() {
  return useContext(DeleteOrderContext)
}

export function DeleteOrderDialog() {
  const { closeDialog, confirm, isDeleting, selectedOrderId } =
    useDeleteOrderContext()

  if (!selectedOrderId || isEmpty(selectedOrderId)) {
    return null
  }

  return (
    <Dialog
      data-testid="delete-order-dialog"
      open
      onClose={closeDialog}
      variant="danger"
    >
      <Dialog.Header>
        Delete Order
        <Dialog.Close />
      </Dialog.Header>
      <Dialog.Body>
        This action cannot be undone. Do you want to continue?
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionTertiary disabled={isDeleting} onClick={closeDialog}>
          Cancel
        </Dialog.ActionTertiary>
        <Dialog.ActionPrimary loading={isDeleting} onClick={confirm}>
          Delete
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </Dialog>
  )
}

interface DeleteOrderProvider extends PropsWithChildren {
  readonly callback?: () => void
}

export function DeleteOrderProvider({
  callback,
  children,
}: DeleteOrderProvider) {
  const { confirm, isDeleting, selectedOrderId, setSelectedOrderId } =
    useDeleteOrderMutation(callback)

  const closeDialog = useCallback(() => {
    setSelectedOrderId(undefined)
  }, [setSelectedOrderId])

  const openDialog = useCallback(
    (id?: string) => {
      setSelectedOrderId(id)
    },
    [setSelectedOrderId]
  )

  const context = useMemo(
    () => ({ closeDialog, confirm, isDeleting, openDialog, selectedOrderId }),
    [closeDialog, confirm, isDeleting, openDialog, selectedOrderId]
  )

  return (
    <DeleteOrderContext.Provider value={context}>
      {children}
      <DeleteOrderDialog />
    </DeleteOrderContext.Provider>
  )
}
