import { createIndexProvider } from 'contexts/indexer'

import { RenderStopFacilityOrAddress } from './RenderStopFacilityOrAddress'
import { StopContact } from './StopContact'
import { StopDate } from './StopDate'
import { StopFacility } from './StopFacility'
import { StopNotes } from './StopNotes'
import { StopTerminal } from './StopTerminal'
import { StopType } from './StopType'

export * from './ContactSummary'
export * from './StopSummary'

export type { StopsFormProps } from './StopsForm'
export { StopsForm } from './StopsForm'

export type * from './Stops.types'
export * from './Stops.utils'
export { FACILITY_ADAPTER } from './StopFacility.helpers'

export const StopForm = Object.assign(createIndexProvider(), {
  Contact: StopContact,
  Facility: StopFacility,
  FacilityOrAddress: RenderStopFacilityOrAddress,
  Terminal: StopTerminal,
  Date: StopDate,
  Notes: StopNotes,
  Type: StopType,
})
