import { Dialog, LoadingDots, Text } from '@loadsmart/loadsmart-ui'

import { SpinnerWrapper } from 'rfp/rfp-details/styles'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

interface Props {
  readonly isOpen: boolean
  readonly isAllocating: boolean
  readonly onClose: () => void
  readonly lanesCount: number
  readonly allocate: () => void
  readonly isRanked?: boolean
  readonly incompleteRankedLanes?: number
  readonly maxRankPositions?: number
}

export default function AllocateDialog({
  isOpen,
  isAllocating,
  lanesCount,
  onClose,
  allocate,
  incompleteRankedLanes = 0,
  isRanked,
  maxRankPositions,
}: Props) {
  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Header>Allocate All</Dialog.Header>
      <Dialog.Body>
        <Text variant="body" color="color-neutral-dark">
          You are about to allocate{' '}
          <b>{`${lanesCount} ${lanesCount === 1 ? 'lane' : 'lanes'}`}</b>
          <br />
          on this RFP.
        </Text>
        <br />
        <br />
        {isRanked && incompleteRankedLanes > 0 && (
          <>
            <Text variant="body" color="color-neutral-dark">
              <b>{incompleteRankedLanes}</b> of your lanes doesn&apos;t have
              enough carriers to do a <b>top {maxRankPositions}</b>, so some may
              have a lower rank number.
            </Text>
            <br />
            <br />
          </>
        )}
        <Text variant="body" color="color-neutral-dark">
          Do you want to continue?
        </Text>
      </Dialog.Body>
      <Dialog.ActionConfirm
        onConfirm={() => {
          allocate()
          analytics.track(
            'Award Scenarios / Allocate All / Confirm',
            AnalyticsEventTrigger.click
          )
        }}
        disabled={isAllocating}
      >
        <SpinnerWrapper>
          {isAllocating ? <LoadingDots variant="light" /> : <>Allocate</>}
        </SpinnerWrapper>
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={onClose} />
    </Dialog>
  )
}
