import { useMemo } from 'react'

import { useSettings } from '_shared_/settings/useSettings'
import { isFBMUser } from '_shared_/user'
import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { fromEquipmentToSelectOption } from 'utils/equipmentTypeV2'
import { areTransportationModesEqual } from 'utils/transportationMode'

type UseEquipmentTypesParams = {
  mode?: string
}

export function useEquipmentTypes(props?: UseEquipmentTypesParams) {
  const { user } = useCurrentUser()
  const {
    values: [enableBulkTanker, enableReeferLTL],
  } = useSettings([
    'flags.ENABLE_BULK_TANKER_OPTION',
    'flags.ENABLE_LTL_REEFER_SHIPMENT',
  ])

  const equipments: SelectOption[] = useMemo(() => {
    if (props?.mode && areTransportationModesEqual(props.mode, 'LTL')) {
      const options = [fromEquipmentToSelectOption('drv')!]
      if (enableReeferLTL) {
        options.push(fromEquipmentToSelectOption('rfr')!)
      }
      return options
    }

    if (props?.mode && areTransportationModesEqual(props.mode, 'IMDL')) {
      return [
        fromEquipmentToSelectOption('drv')!,
        fromEquipmentToSelectOption('rfr')!,
      ]
    }

    if (props?.mode && areTransportationModesEqual(props.mode, 'EXP')) {
      return [
        fromEquipmentToSelectOption('spr')!,
        fromEquipmentToSelectOption('cgv')!,
        fromEquipmentToSelectOption('str')!,
        fromEquipmentToSelectOption('btr')!,
        fromEquipmentToSelectOption('hot')!,
      ]
    }

    if (props?.mode && areTransportationModesEqual(props.mode, 'drayage')) {
      return [
        fromEquipmentToSelectOption('drb')!,
        fromEquipmentToSelectOption('rfr')!,
        fromEquipmentToSelectOption('40hc')!,
        fromEquipmentToSelectOption('fra')!,
        fromEquipmentToSelectOption('tank')!,
      ]
    }

    if (enableBulkTanker && !isFBMUser(user)) {
      return [
        fromEquipmentToSelectOption('drv')!,
        fromEquipmentToSelectOption('rfr')!,
        fromEquipmentToSelectOption('fbe')!,
        fromEquipmentToSelectOption('tank')!,
      ]
    }

    return [
      fromEquipmentToSelectOption('drv')!,
      fromEquipmentToSelectOption('rfr')!,
      fromEquipmentToSelectOption('fbe')!,
    ]
  }, [props, enableBulkTanker, enableReeferLTL, user])

  return { equipments }
}
