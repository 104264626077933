import { IconCopy } from '@loadsmart/icons'
import { DateFormatHelper, DateHelper } from '@loadsmart/loadsmart-ui'
import { Button, Field, Layout, Tag, Text } from '@loadsmart/miranda-react'
import type { ColorToken } from '@loadsmart/miranda-react/dist/tokens'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'

import EquipmentType from 'components/EquipmentType'
import TransportationMode from 'components/TransportationMode'
import type { CanonicalEquipmentTypeName } from 'utils/equipmentTypeV2'
import type { ShipmentStatus } from 'utils/shipment'
import { getShipmentStatus, isShipmentStatus } from 'utils/shipment'
import toArray from 'utils/toArray'
import { resolveTransportationMode } from 'utils/transportationMode'

import type { AdaptedShipment, Stop } from '../../Details/types'
import { getShipmentStatusTagVariant } from './Shipment.utils'

export type ShipmentSummaryContextValue = AdaptedShipment | undefined

const ShipmentSummaryContext =
  createContext<ShipmentSummaryContextValue>(undefined)

ShipmentSummaryContext.displayName = 'ShipmentSummaryContext'

export const formatStopAddress = (stop?: Stop) => {
  let city, state
  if (stop?.facility) {
    city = get(stop, 'facility.city')
    state = get(stop, 'facility.state')
  } else {
    city = get(stop, 'city')
    state = get(stop, 'state')
  }

  return [city, state].filter(Boolean).join(', ')
}

export function ShipmentSummary({
  shipment,
  children,
}: PropsWithChildren<{
  readonly shipment: ShipmentSummaryContextValue
}>) {
  return (
    <ShipmentSummaryContext.Provider value={shipment}>
      {isEmpty(shipment) ? null : children}
    </ShipmentSummaryContext.Provider>
  )
}

const ShipmentSummaryShipmentID = ({
  color = 'color-text-tertiary',
  showCopy = true,
  heading = false,
}: {
  readonly color?: ColorToken
  readonly showCopy?: boolean
  readonly heading?: boolean
}) => {
  const shipment = useContext(ShipmentSummaryContext)

  const shipperGuideId = get(shipment, 'shipper_guide_id', '')
  const status = get(shipment, 'status') as ShipmentStatus
  const isExpired = isShipmentStatus(status).equals('expired')

  if (!shipperGuideId) {
    return null
  }

  if (heading) {
    return (
      <Text
        variant="heading-sm-bold"
        color={isExpired ? 'color-text-error' : 'color-text-tertiary'}
      >
        #{shipperGuideId}
      </Text>
    )
  }

  return (
    <Field size="small">
      <Field.Label>Shipment ID</Field.Label>
      <Layout.Group align="center" gap="spacing-1">
        <Text variant="body-md" color={color}>
          {shipperGuideId}
        </Text>

        {showCopy && (
          <Button
            variant="icon-secondary"
            size="small"
            aria-label="Copy ID"
            onClick={() => navigator.clipboard.writeText(shipperGuideId)}
          >
            <IconCopy height={14} width={14} title={null} />
          </Button>
        )}
      </Layout.Group>
    </Field>
  )
}

const ShipmentSummaryRoute = () => {
  const shipment = useContext(ShipmentSummaryContext)

  const stops = toArray(get(shipment, 'stops'))

  if (stops.length < 2) {
    return null
  }

  const pickup = stops.at(0)
  const delivery = stops.at(-1)

  return (
    <Layout.Group gap="spacing-1" align="center">
      <Text variant="body-lg-bold">{formatStopAddress(pickup)}</Text>
      &nbsp;
      {stops.length > 2 && (
        <Tag variant="neutral" size="small">
          +{stops.length - 2}&nbsp;
        </Tag>
      )}
      <Text variant="body-lg-bold">&rarr;</Text>
      &nbsp;
      <Text variant="body-lg-bold">{formatStopAddress(delivery)}</Text>
    </Layout.Group>
  )
}

const ShipmentSummaryPeriod = ({
  dateFormat,
}: {
  readonly dateFormat?: string
}) => {
  const dateFormatter = DateFormatHelper(dateFormat ?? 'MMM D')
  const shipment = useContext(ShipmentSummaryContext)

  const stops = toArray(get(shipment, 'stops'))
  const pickupDate = get(stops.at(0), 'date')
  const deliveryDate = get(stops.at(-1), 'date')
  const status = get(shipment, 'status') as ShipmentStatus
  const isExpired = isShipmentStatus(status).equals('expired')

  const textColor = isExpired ? 'color-text-error' : 'color-text-tertiary'

  return (
    <Layout.Group gap="spacing-0-5">
      <Text variant="heading-sm-bold" color={textColor}>
        {pickupDate ? dateFormatter.format(DateHelper(pickupDate)) : 'Pending'}
      </Text>
      &nbsp;
      <Text variant="heading-sm-bold" color={textColor}>
        &rarr;
      </Text>
      &nbsp;
      <Text variant="heading-sm-bold" color={textColor}>
        {deliveryDate
          ? dateFormatter.format(DateHelper(deliveryDate))
          : 'Pending'}
      </Text>
    </Layout.Group>
  )
}

const ShipmentSummaryMileage = () => {
  const shipment = useContext(ShipmentSummaryContext)

  const mileage = get(shipment, 'mileage')
  const status = get(shipment, 'status') as ShipmentStatus
  const isExpired = isShipmentStatus(status).equals('expired')

  if (!mileage) {
    return null
  }

  return (
    <Text
      variant="heading-sm-bold"
      color={isExpired ? 'color-text-error' : 'color-text-tertiary'}
    >{`${mileage} miles`}</Text>
  )
}

const ShipmentSummaryMileageAndTransitEstimate = () => {
  const shipment = useContext(ShipmentSummaryContext)

  const mileage = get(shipment, 'mileage', '')
  const transitEstimate = get(shipment, 'transit_estimate', null)

  if (transitEstimate === null) {
    return (
      <Layout.Stack gap="spacing-1">
        <Text variant="body-sm" color="color-text-tertiary">
          Distance
        </Text>
        <Text variant="body-md" color="color-text-primary">
          {mileage} miles
        </Text>
      </Layout.Stack>
    )
  }

  return (
    <Layout.Stack gap="spacing-1">
      <Text variant="body-sm" color="color-text-tertiary">
        Distance & transit estimate
      </Text>
      <Text variant="body-md" color="color-text-primary">
        {mileage} miles <Text variant="heading-sm">•</Text> {transitEstimate}
      </Text>
    </Layout.Stack>
  )
}

const ShipmentSummaryTransportationMode = () => {
  const shipment = useContext(ShipmentSummaryContext)

  const mode = get(shipment, 'mode')

  const code = resolveTransportationMode(mode as string)

  if (!code) {
    return null
  }

  return (
    <TransportationMode
      color="color-text-tertiary"
      iconSize={24}
      variant="abbr"
      textVariant="body-md-bold"
      name={code}
    />
  )
}

const ShipmentSummaryEquipmentType = () => {
  const shipment = useContext(ShipmentSummaryContext)

  const type = get(shipment, 'equipment_type') as CanonicalEquipmentTypeName
  const length = get(shipment, 'equipment_length')

  return (
    <Layout.Group align="center" gap="spacing-1">
      <EquipmentType
        color="color-text-tertiary"
        iconSize={24}
        variant="icon-only"
        textVariant="body-md-bold"
        name={type}
      />
      {Boolean(length) && (
        <Text
          variant="body-md-bold"
          color="color-text-tertiary"
          data-testid="equipment-type-length"
        >
          {`${length}'`}
        </Text>
      )}

      <EquipmentType
        color="color-text-tertiary"
        iconSize={24}
        variant="label-only"
        textVariant="body-md-bold"
        name={type}
      />
    </Layout.Group>
  )
}

const ShipmentSummaryBOLNumber = () => {
  const shipment = useContext(ShipmentSummaryContext)

  return (
    <Field size="small">
      <Field.Label>BOL Number&nbsp;</Field.Label>
      <Text variant="body-md">{`${get(shipment, 'bol_number') || '-'}`}</Text>
    </Field>
  )
}

const ShipmentSummaryPONumber = () => {
  const shipment = useContext(ShipmentSummaryContext)

  return (
    <Field size="small">
      <Field.Label>PO Number(s)&nbsp;</Field.Label>
      <Text variant="body-md">{`${get(shipment, 'po_number') || '-'}`}</Text>
    </Field>
  )
}

const ShipmentSummarySONumber = () => {
  const shipment = useContext(ShipmentSummaryContext)

  return (
    <Field size="small">
      <Field.Label>SO Number&nbsp;</Field.Label>
      <Text variant="body-md">{`${get(shipment, 'so_number') || '-'}`}</Text>
    </Field>
  )
}

const ShipmentSummaryEquipment = () => {
  const shipment = useContext(ShipmentSummaryContext)
  const type = get(shipment, 'equipment_type') as CanonicalEquipmentTypeName
  const length = get(shipment, 'equipment_length')

  return (
    <Field size="small">
      <Field.Label>Equipment&nbsp;</Field.Label>
      <Layout.Group align="center" gap="spacing-1">
        {Boolean(length) && (
          <Text
            variant="body-md"
            data-testid="equipment-length"
          >{`${length}'`}</Text>
        )}
        <EquipmentType variant="label-only" name={type} />
      </Layout.Group>
    </Field>
  )
}

const ShipmentSummaryDivider = () => {
  const shipment = useContext(ShipmentSummaryContext)
  const status = get(shipment, 'status') as ShipmentStatus
  const isExpired = isShipmentStatus(status).equals('expired')

  return (
    <Text
      color={isExpired ? 'color-text-error' : 'color-text-tertiary'}
      variant="heading-sm-bold"
    >
      •
    </Text>
  )
}

const ShipmentSummaryStatus = () => {
  const shipment = useContext(ShipmentSummaryContext)

  const status = get(shipment, 'status') as ShipmentStatus

  return (
    <Tag variant={getShipmentStatusTagVariant(status)} size="small">
      {getShipmentStatus(status)?.label}
    </Tag>
  )
}

/**
 * Render shipment status.
 */
ShipmentSummary.Status = ShipmentSummaryStatus

/**
 * Render a bullet divider.
 */
ShipmentSummary.Divider = ShipmentSummaryDivider

/**
 * Render shipment ID.
 */
ShipmentSummary.ShipmentID = ShipmentSummaryShipmentID

/**
 * Render PU/DEL City and State.
 */
ShipmentSummary.Route = ShipmentSummaryRoute

/**
 * Render PU/DEL dates.
 */
ShipmentSummary.Period = ShipmentSummaryPeriod

/**
 * Render mileage between PU and DEL.
 */
ShipmentSummary.Mileage = ShipmentSummaryMileage

/**
 * Render mileage between PU and DEL and estimated transit time.
 */

ShipmentSummary.MileageAndTransitEstimate =
  ShipmentSummaryMileageAndTransitEstimate

/**
 * Render shipment transportation mode.
 */
ShipmentSummary.TransportationMode = ShipmentSummaryTransportationMode

/**
 * Render shipment equipment type.
 */
ShipmentSummary.EquipmentType = ShipmentSummaryEquipmentType

/**
 * Render shipment BOL number.
 */
ShipmentSummary.BOLNumber = ShipmentSummaryBOLNumber

/**
 * Render shipment PO number.
 */
ShipmentSummary.PONumber = ShipmentSummaryPONumber

/**
 * Render shipment SO number.
 */
ShipmentSummary.SONumber = ShipmentSummarySONumber

/**
 * Render shipment summarized equipment type and length.
 */
ShipmentSummary.Equipment = ShipmentSummaryEquipment
