import { MODES } from 'utils/constants'

import type { ShipmentChargesColumnKey } from './types'

export const LATEST_LOCATION_INDEX = 0
export const SHIPMENT_DETAILS_QUERY_KEY = 'retrieveShipmentDetails'
export const SHIPMENT_CHARGES_QUERY_KEY = 'shipmentCharges'
export const SHIPMENT_UPDATE_WS_EVENT = 'shipment-update:updated'
export const SHIPMENT_ACCOUNTING_SUMMARY_KEY = 'shipmentAccountingSummary'
export const SHIPMENTS_SETTLEMENT_METADATA = 'shipmentSSettlementMetadata'
export const SHIPMENTS_SETTLEMENT = 'shipmentSettlement'
export const SHIPMENTS_SETTLEMENT_REPORTS = 'shipmentsSettlementReports'
export const SHIPMENT_CHARGE_TYPES_QUERY_KEY = 'shipmentChargeTypes'
export const SHIPMENT_INVOICES = 'shipmentInvoices'
export const SHIPMENT_INVOICE_REVIEW = 'shipmentInvoiceReview'
export const SHIPMENT_REQUEST_INVOICE = 'requestCarrierInvoice'
export const SHIPMENT_DISPUTE_INVOICE = 'disputeShipmentInvoice'
export const SHIPMENT_APPROVE_INVOICE = 'approveShipmentInvoice'
export const SHIPMENT_DENY_INVOICE = 'denyShipmentInvoice'
export const SHIPMENT_TIMELINE_QUERY_KEY = 'shipmentTimeline'

export const LTL_MODES = [MODES.LTL, MODES.VLTL, MODES.PTL] as string[]

export const ALLOW_RATE_CHANGE_SHIPMENT_STATUS = [
  'booked',
  'en-route-pickup',
  'at-pickup',
  'en-route-delivery',
  'at-delivery',
  'accounting-review',
]
export const TABS = {
  DETAILS: {
    value: 'details',
    label: 'Details',
  },
  DOCS_AND_CHARGES: {
    value: 'docs-and-charges',
    label: 'Docs and Charges',
    subpath: '/charges',
  },
} as const

export const CHARGES_TABLE_COLUMNS: {
  key: ShipmentChargesColumnKey
  label: string
}[] = [
  { key: 'type', label: 'Type' },
  { key: 'stop', label: 'Stop' },
  { key: 'createdBy', label: 'Submitted by' },
  { key: 'createdAt', label: 'Submitted on' },
  { key: 'status', label: 'Status' },
  { key: 'total', label: 'Amount' },
]

export const LINKED_CHARGES_TABLE_COLUMNS: {
  key: ShipmentChargesColumnKey
  label: string
}[] = [
  { key: 'type', label: 'Type' },
  { key: 'createdBy', label: 'Submitted by' },
  { key: 'expected', label: 'Expected' },
  { key: 'readyToPay', label: 'Ready to Pay' },
  { key: 'status', label: 'Status' },
]

export const CHARGE_DESCRIPTION_MAX_CHARS = 150
export const CHARGE_ACCEPTED_FILE_EXTENSIONS = ['.jpeg', '.jpg', '.png', '.pdf']
export const CHARGE_MAX_FILE_SIZE_IN_MB = 10
