import type { AxiosRequestConfig } from 'axios'

import type { RawUserData } from '_shared_/user'
import { userUtils } from '_shared_/user'
import httpClient from 'utils/httpClient'

export const obtainToken = async (
  username: string,
  password: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.post(
    '/auth/token',
    {
      username,
      password,
    },
    config
  )

  userUtils.setTokens(data)
}

export const obtainOauth2Token = async (
  authCode: string,
  codeVerifier: string,
  redirectUri: string,
  config?: AxiosRequestConfig
) => {
  const { data } = await httpClient.post(
    '/auth/oauth2/token',
    {
      auth_code: authCode,
      code_verifier: codeVerifier,
      redirect_uri: redirectUri,
    },
    config
  )

  userUtils.setTokens(data)
}

export const refreshToken = async () => {
  const { data } = await httpClient.post('/auth/token/refresh', {
    refresh: userUtils.refreshToken,
    username: userUtils.data.user_email,
  })
  userUtils.setTokens(data)
}

export const sendPasswordResetRequest = async (email: string) => {
  const { data } = await httpClient.post('/auth/password_reset', { email })
  return data
}

export const resetPassword = async (password: string, token: string) => {
  const { data } = await httpClient.post('/auth/password_reset/confirm', {
    password,
    token,
  })
  userUtils.setTokens(data)
}

export const changePassword = async (
  current_password: string,
  new_password: string,
  new_password_confirmation: string
) => {
  const { data } = await httpClient.put('/auth/change_password', {
    current_password,
    new_password,
    new_password_confirmation,
  })
  userUtils.setTokens(data)
}

export const completeRegistration = async (password: string, token: string) => {
  const { data } = await httpClient.post('/auth/complete_registration', {
    password,
    token,
  })
  userUtils.setTokens(data)
}

export const emailVerification = async (token: string) => {
  const { data } = await httpClient.post('/auth/email_verification', {
    token,
  })
  return data
}

export const resendEmailVerification = async (email: string) => {
  const { data } = await httpClient.post('/auth/resend_email_verification', {
    email,
  })
  return data
}

/**
 * @todo move to _shared_/user folder
 */
export const fetchUserData = async (
  config?: AxiosRequestConfig
): Promise<RawUserData> => {
  const { data } = await httpClient.get('/auth/user_data', config)

  return data
}
