import type { SelectDatasource } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'

import { getTerminals } from 'facilities/facilities.services'
import type { Terminal } from 'facilities/facitilites.types'
import { mapFiltersToURLSearchParams } from 'screens/Shipper/FacilityManagement/useFacilities'

export function AsyncTerminalDatasource(): SelectDatasource<Terminal> {
  const type = 'terminal'

  return {
    type,
    adapter: {
      getKey: (terminal) => terminal.uuid,
      getLabel: (terminal) => `${terminal.name}: ${terminal.address}`,
    },
    async fetch({ query }) {
      const { results: terminals } = await getTerminals({
        params: mapFiltersToURLSearchParams({
          search: query,
          sort: 'name',
        }),
      })

      return terminals
    },
  }
}
