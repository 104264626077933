import { cloneDeep, isEmpty } from 'lodash-es'

import { getLabel } from 'components/AppliedFilters'
import { formatVolume, formatWeight } from 'components/ShippingItemsManager'
import { formatOrderDateFilters } from 'orders/orders.utils'
import type { Order } from 'orders/types'
import { getLocationDisplayInformation } from 'screens/Quotes/List/utils'
import { numberFormatter } from 'utils/numbers'

import type { FilterValues } from '../hooks/useOrdersFiltersQueryParamPlugin'

export function getLocationOptionKey(
  location: LocationOption & {
    facility_uuid?: string | null
    place_id?: string | null
  }
): string {
  if ('facility_uuid' in location && location.facility_uuid) {
    return String(location.facility_uuid)
  }
  if ('place_id' in location && location.place_id) {
    return location.place_id
  }
  if ('uuid' in location && location.uuid) {
    return location.uuid
  }
  if ('warehouse_uuid' in location && location.warehouse_uuid) {
    return location.warehouse_uuid
  }
  return `${location.city}, ${location.state}, ${location.country}`
}

type OpAndValueFilters = 'package_count' | 'total_volume' | 'total_weight'

export const OP_AND_VALUE_FIELDS = [
  'package_count',
  'total_volume',
  'total_weight',
] as OpAndValueFilters[]

export function removeOpsWithoutValues(filters: FilterValues = {}) {
  const newFilters = cloneDeep(filters)

  OP_AND_VALUE_FIELDS.forEach((field) => {
    const op: keyof FilterValues = `${field}_op`

    if (!isEmpty(filters[op]) && isEmpty(newFilters[field])) {
      newFilters[op] = null
    }
  })

  return newFilters
}

export function getOpAndValueFilter(
  filters: FilterValues = {},
  field: 'package_count' | 'total_volume' | 'total_weight' = 'package_count'
) {
  const value = filters[field]
  const op = filters[`${field}_op`]

  if (!isEmpty(value) && op) {
    if (op === 'eq') {
      return {
        [`${field}`]: value,
      }
    }
    if (['lt', 'lte', 'gt', 'gte'].includes(op)) {
      return {
        [`${field}__${op}`]: value,
      }
    }
  }

  return {}
}

export const OP_LABELS = {
  eq: '=',
  lt: '<',
  lte: '<=',
  gt: '>',
  gte: '>=',
}
export const OP_FULL_LABELS = {
  eq: 'Equal',
  lt: 'Less than',
  lte: 'Less than or equal to',
  gt: 'Greater than',
  gte: 'Greater than or equal to',
}
export const DEFAULT_OP_VALUE: keyof typeof OP_FULL_LABELS = 'lte'

function getOpFilterFormatter(
  field: 'package_count' | 'total_volume' | 'total_weight',
  formatter: (value: number | string) => string
) {
  return function (filters: FilterValues = {}) {
    const value = filters[field]
    const op = filters[`${field}_op`]

    if (!value || !op || !OP_LABELS[op]) {
      return ''
    }

    return `${OP_LABELS[op]} ${formatter(value)}`
  }
}

export const packageCountFormatter = getOpFilterFormatter(
  'package_count',
  numberFormatter
)
export const totalVolumeFormatter = getOpFilterFormatter(
  'total_volume',
  formatVolume
)
export const totalWeightFormatter = getOpFilterFormatter(
  'total_weight',
  formatWeight
)
export const opFormatter = () => {
  return ''
}

function getLocationFormatter(field: 'delivery_location' | 'pickup_location') {
  return function (filters: FilterValues = {}) {
    return getLabel(filters[field], (item) =>
      getLocationDisplayInformation(item)
    )
  }
}

export const deliveryFormatter = getLocationFormatter('delivery_location')
export const pickupFormatter = getLocationFormatter('pickup_location')

function getDateFormatter(
  field:
    | 'delivery_date_after'
    | 'delivery_date_before'
    | 'pickup_date_after'
    | 'pickup_date_before'
) {
  return function (filters: FilterValues = {}) {
    if (!filters[field]) {
      return ''
    }
    return formatOrderDateFilters(filters[field], 'MM/DD/YYYY')
  }
}

export const STATUS_SLUG_TO_LABEL: Record<Order['status'], string> = {
  open: 'Open',
  planned: 'Planned',
  deleted: 'Deleted',
  planning: 'Planning',
  closed: 'Closed',
}

export function statusFormatter(filters: FilterValues = {}) {
  if (!filters.status) {
    return ''
  }

  return getLabel(
    filters.status,
    (item) => STATUS_SLUG_TO_LABEL[item as Order['status']]
  )
}

export const deliveryDateAfterFormatter = getDateFormatter(
  'delivery_date_after'
)
export const deliveryDateBeforeFormatter = getDateFormatter(
  'delivery_date_before'
)
export const pickupDateAfterFormatter = getDateFormatter('pickup_date_after')
export const pickupDateBeforeFormatter = getDateFormatter('pickup_date_before')

const LABELS = {
  delivery_date_after: 'Delivery date after',
  delivery_date_before: 'Delivery date before',
  delivery_location: 'Delivery',
  package_count_op: '',
  package_count: 'Piece count',
  pickup_date_after: 'Pickup date after',
  pickup_date_before: 'Pickup date before',
  pickup_location: 'Pickup',
  status: 'Status',
  total_volume_op: '',
  total_volume: 'Total volume',
  total_weight_op: '',
  total_weight: 'Total weight',
  search: '',
}

const VALUE_FORMATTER = {
  delivery_date_after: deliveryDateAfterFormatter,
  delivery_date_before: deliveryDateBeforeFormatter,
  delivery_location: deliveryFormatter,
  package_count: packageCountFormatter,
  package_count_op: opFormatter,
  pickup_date_after: pickupDateAfterFormatter,
  pickup_date_before: pickupDateBeforeFormatter,
  pickup_location: pickupFormatter,
  status: statusFormatter,
  total_volume: totalVolumeFormatter,
  total_volume_op: opFormatter,
  total_weight: totalWeightFormatter,
  total_weight_op: opFormatter,
  search: (filters: FilterValues) => filters.search ?? '',
}

export function getTagLabel(
  filters: FilterValues,
  filterKey: keyof FilterValues
): string {
  let filterLabel = ''
  let itemsLabel: string | null = ''
  const formatter = VALUE_FORMATTER[filterKey]
  if (formatter && !isEmpty(filters[filterKey])) {
    filterLabel = LABELS[filterKey]
    itemsLabel = formatter(filters)
  }
  return `${filterLabel} ${itemsLabel}`.trim()
}
export function getAppliedFilterTags(
  filters: FilterValues,
  ignoreStatus = false
) {
  let keys = Object.keys(filters) as Array<keyof FilterValues>

  if (ignoreStatus) {
    keys = keys.filter((key) => key !== 'status')
  }

  const mapped = keys
    .map((filterKey) => {
      return {
        label: getTagLabel(filters, filterKey),
        value: filterKey,
      }
    })
    .filter(({ label }) => !isEmpty(label))
  return mapped
}
export function getHasAppliedFilters(
  filters: FilterValues,
  ignoreStatus = false
) {
  if (isEmpty(filters)) {
    return false
  }

  let keys = Object.keys(filters) as Array<keyof FilterValues>

  if (ignoreStatus) {
    keys = keys.filter((key) => key !== 'status')
  }

  return keys.reduce(
    (hasValue, key) => hasValue || !isEmpty(filters[key]),
    false
  )
}
