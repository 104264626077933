import { get } from 'lodash-es'
import type { ReactNode } from 'react'
import { useCallback } from 'react'

import { createTransientCollectionSetup } from 'components/TransientCollectionForm'
import { useIndexerContext } from 'contexts/indexer'
import { useCustomFormField } from 'hooks/useCustomFormField'
import { getMetadata, getTransientError } from 'utils/transient'

import type { TransientHandlingUnitOrderItem } from '../HandlingUnits.types'

const {
  TransientCollectionForm,
  useTransientCollectionFormContext,
  TransientCollectionFormContext,
} = createTransientCollectionSetup<TransientHandlingUnitOrderItem>()

export const OrderItemsTransientCollectionForm = TransientCollectionForm
export const useOrderItemsForm = useTransientCollectionFormContext
export const OrderItemsFormContext = TransientCollectionFormContext

type UseOrderItemFormFieldProps = {
  name: string
  hint?: ReactNode
}

export function useOrderItemFormField(props: UseOrderItemFormFieldProps) {
  const { name, hint } = props

  const [items, dispatch] = useOrderItemsForm()
  const index = useIndexerContext()
  const item = items[index]
  const error = getTransientError(item, name)
  const controlProps = getMetadata(item, ['controls', name])
  const fieldProps = getMetadata(item, ['fields', name])

  const formFieldProps = useCustomFormField({
    index,
    // prevent duplicate ids
    name: `order-item-${name}`,
    hint,
    error,
    controlProps,
    fieldProps,
  })

  const setItem = useCallback(
    (changes: Partial<TransientHandlingUnitOrderItem>) => {
      dispatch({
        type: 'SET_ITEM',
        payload: { index, changes },
      })
    },
    [dispatch, index]
  )

  const value = get(item, name)

  const setValue = useCallback(
    (
      newValue: TransientHandlingUnitOrderItem[keyof TransientHandlingUnitOrderItem]
    ) => {
      setItem({ [name]: newValue })
    },
    [setItem, name]
  )

  return {
    index,
    item,
    dispatch,
    setItem,
    value,
    setValue,
    items,
    ...formFieldProps,
  }
}
