import { CARRIERS_PATH } from './constants'

const idRegex = /^\d+$/
const uuidRegex = /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/i
const matchers = [idRegex, uuidRegex]
export function urlCleaner(url: string, placeholder = '*') {
  if (url.startsWith('http')) {
    url = new URL(url).pathname
  }

  return url
    .split('?')[0]
    .split('/')
    .map((s) => {
      if (!matchers.some((matcher) => matcher.test(s))) {
        return s
      }
      return placeholder
    })
    .join('/')
    .replace(/(.*)\/$/, '$1')
    .toLowerCase()
}

export function parseSearch(url: string): Record<string, unknown> {
  if (!url) {
    return {}
  }

  const { searchParams } = new URL(url)
  return Object.fromEntries(searchParams)
}

export const isCarrierPath = (): boolean =>
  CARRIERS_PATH.some((path) =>
    globalThis.location.pathname.toLowerCase().startsWith(path)
  )
