import { Card, Layout } from '@loadsmart/miranda-react'

import { GridV2 as Grid } from 'components/Grid'
import { CommoditiesForm } from 'components/HandlingUnitsManager/CommoditiesForm/CommoditiesForm'
import type {
  TransientCommodity,
  TransientHandlingUnit,
} from 'components/HandlingUnitsManager/HandlingUnits.types'
import {
  HandlingUnitForm,
  HandlingUnitsForm,
} from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsForm'
import { HandlingUnitsFormAddHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsFormAddHandlingUnit'
import {
  createTransientCommodity,
  createTransientHandlingUnit,
} from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import { HandlingUnitHeader } from 'components/LTLQuoteFormHandlingUnit/HandlingUnitHeader'
import type { TransientStop } from 'components/StopsManager'
import { HandlingUnitsFormHeader } from 'screens/Shipper/Shipments/components/HandlingUnit/HandlingUnitsFormHeader'
import { hasMoreThanOneItem, replaceArrayItem } from 'utils/arrays'
import { hasTransientError } from 'utils/transient'

import { FulfillmentCommodity } from './FulfillmentCommodity'
import { useFulfillmentFormContext } from './FulfillmentFormContext'

type FormHandlingUnitProps = Readonly<{
  handlingUnits: TransientHandlingUnit[]
  stops: TransientStop[]
  hasOneItemRequiredError?: boolean
  onHandlingUnitChange: (value: TransientHandlingUnit[]) => void
}>

export function FulfillmentHandlingUnit({
  hasOneItemRequiredError,
  handlingUnits,
  onHandlingUnitChange,
}: FormHandlingUnitProps) {
  const { action } = useFulfillmentFormContext()
  const handleCommodityChange = (index: number) => {
    return (value: TransientCommodity[]) => {
      onHandlingUnitChange(
        replaceArrayItem(handlingUnits, index, {
          ...handlingUnits[index],
          commodities: value,
        })
      )
    }
  }

  const handleCreateItem = () => {
    return createTransientHandlingUnit({
      order_items: [],
      pickup_stop_index: 0,
      delivery_stop_index: 1,
    })
  }

  return (
    <HandlingUnitsForm
      items={handlingUnits}
      createItem={handleCreateItem}
      onChange={onHandlingUnitChange}
    >
      <HandlingUnitsFormHeader
        handlingUnits={handlingUnits}
        hasOneItemRequiredError={hasOneItemRequiredError}
        actions={<HandlingUnitsFormAddHandlingUnit />}
      />
      {handlingUnits.map((handlingUnit, index) => (
        <HandlingUnitForm key={handlingUnit._metadata?.id} index={index}>
          <CommoditiesForm
            items={handlingUnit.commodities}
            createItem={createTransientCommodity}
            onChange={handleCommodityChange(index)}
          >
            <Card
              aria-labelledby={`title-${handlingUnit._metadata?.id}`}
              data-testid={`handling-unit-${index}`}
              onToggle={(event) => event.stopPropagation()}
              style={{
                '--m-card-border-left': 'none',
                '--m-card-border-right': 'none',
                '--m-card-border-bottom': 'none',
              }}
              collapsible
            >
              <HandlingUnitHeader
                handlingUnit={handlingUnit}
                actions={
                  action === 'create' && hasMoreThanOneItem(handlingUnits) ? (
                    <Layout.Group align="center" justify="flex-end">
                      <HandlingUnitsForm.RemoveHandlingUnit />
                    </Layout.Group>
                  ) : null
                }
              />
              <Card.Divider />
              <Card.Body>
                <Layout.Stack
                  gap={hasTransientError(handlingUnit) ? 'spacing-4' : 'none'}
                >
                  <Layout.Group align="flex-start">
                    <HandlingUnitForm.PackageType required hint={<>&nbsp;</>} />
                    <HandlingUnitForm.PackageCount
                      required
                      hint={<>&nbsp;</>}
                    />
                    <HandlingUnitForm.Stackable hint={<>&nbsp;</>} />
                    <HandlingUnitForm.Turnable hint={<>&nbsp;</>} />
                  </Layout.Group>

                  <Grid columns={3} rowGap="none">
                    <HandlingUnitForm.Dimension
                      dimension="length"
                      required
                      hint={<>&nbsp;</>}
                    />
                    <HandlingUnitForm.Dimension
                      dimension="width"
                      required
                      hint={<>&nbsp;</>}
                    />
                    <HandlingUnitForm.Dimension
                      dimension="height"
                      required
                      hint={<>&nbsp;</>}
                    />
                    <HandlingUnitForm.WeightType required hint={<>&nbsp;</>} />
                  </Grid>

                  {handlingUnit.commodities.map(
                    (commodity: TransientCommodity, commodityIndex: number) => (
                      <FulfillmentCommodity
                        key={commodity._metadata?.id}
                        commodityIndex={commodityIndex}
                        handlingUnit={handlingUnit}
                      />
                    )
                  )}
                </Layout.Stack>
              </Card.Body>
              {action === 'create' && (
                <Card.Footer>
                  <CommoditiesForm.AddCommodity />
                </Card.Footer>
              )}
            </Card>
          </CommoditiesForm>
        </HandlingUnitForm>
      ))}
    </HandlingUnitsForm>
  )
}
