import { datadogRum } from '@datadog/browser-rum'
import type { BrowserOptions } from '@sentry/react'
import * as Sentry from '@sentry/react'
import mixpanel from 'mixpanel-browser'
import { hotjar } from 'react-hotjar'

import type { AnalyticsEvents } from '_shared_/analytics/analytics-events'
import {
  mixpanelReset,
  setMixPanelUser,
  setupMixpanel,
} from '_shared_/analytics/mixpanel'
import { setupZoomWebInsights } from '_shared_/analytics/zoom-webinsights'
import type { UserData } from '_shared_/user'
import { formatUserForAnalytics, userUtils } from '_shared_/user'
import { interceptErrors } from '_shared_/utils/intercept-errors'
import * as constants from 'utils/constants'
import logger from 'utils/logger'

export const shouldTrack = () => {
  return ['production', 'staging'].includes(constants.ENV)
}

export const preventLogging4xxRequestErrors = (
  event: Sentry.Event
): Sentry.Event | null => {
  const responseContext = event.contexts?.response

  // Check if the event contains a response context with a 4XX status code
  if (responseContext?.status_code?.toString().startsWith('4')) {
    return null // Drop all 4XX errors
  }

  // Alternatively, look for 4XX errors in the exception values
  if (
    event.exception?.values?.some((exception) =>
      /Request failed with status code 4\d{2}/.test(exception.value ?? '')
    )
  ) {
    return null // Drop all exceptions with 4XX-related messages
  }

  // Allow other events to be sent
  return event
}

export const getIgnoredErrorsStaging = () =>
  constants.ENV === 'staging' ? ['ChunkLoadError'] : []

const sentryConfig = (): BrowserOptions => ({
  dsn: constants.SENTRY_DSN,
  environment: constants.ENV,
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#traces-sample-rate
  tracesSampleRate: 1.0,
  ignoreErrors: [
    // Ignoring Microsoft SafeLink error
    // Taken from: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:2',
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:1',

    // Resize observer errors can be safely ignored because they don't reflect an actual bug: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
    // We have fixed them for the most part but we still have an error every once in a while that we're unable to reproduce.
    // FWIW, these seem to appear on dropdowns. (like Selects)
    'ResizeObserver loop completed with undelivered notifications.', // Only one user with Firefox on Windows is running into this one on quotes/new
    'ResizeObserver loop limit exceeded',
    constants.SHIPMENT_PROOF_OF_DELIVERY_ERROR,
    // See https://loadsmart.atlassian.net/browse/PNP-236 for more info
    'Failed to init talkjs session',
    // temporary ignore just to reduce the noise in the slack channel while we investigate the issue
    ...getIgnoredErrorsStaging(),
  ],
  // https://sentry.io/organizations/loadsmart/issues/3524986589/events/706141aad0ce432d9b2f905fca144700/?project=5357855
  denyUrls: ['https://app.shipper.guide/en-US.js'],
  release: constants.RELEASE_VERSION
    ? 'rfp-web@' + constants.RELEASE_VERSION
    : undefined,
  normalizeDepth: 6,
  beforeSend: preventLogging4xxRequestErrors,
})
const datadogConfig = () => ({
  applicationId: constants.DATADOG_APPLICATION_ID,
  clientToken: constants.DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: constants.DATADOG_SERVICE_NAME,
  env: constants.ENV,
  version: constants.RELEASE_VERSION,
  sessionSampleRate: 100,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  premiumSampleRate: 0,
})

export enum AnalyticsEventTrigger {
  click = 'click',
  select = 'select',
  redirect = 'redirect',
  success = 'success',
  error = 'error',
  hover = 'hover',
  view = 'view',
}

let isUserSet = false

export const analytics = interceptErrors(
  {
    init: () => {
      if (!shouldTrack()) {
        return
      }

      setupMixpanel()
      Sentry.init(sentryConfig())

      hotjar.initialize({
        id: constants.HOTJAR_ID,
        sv: 6,
      })

      logger.init()
      datadogRum.init(datadogConfig())
      setupZoomWebInsights()
    },
    setUser: (userData: UserData) => {
      if (!shouldTrack() || isUserSet) {
        return
      }

      const formattedUserData = userData.user_uuid
        ? formatUserForAnalytics(userData)
        : userData

      setMixPanelUser(userData)
      Sentry.setUser({ ...userData, ...formattedUserData })
      logger.setUser(formattedUserData)
      datadogRum.setUser({ ...formattedUserData })

      isUserSet = true
    },
    track: <EventName extends keyof AnalyticsEvents>(
      event: EventName,
      trigger: string = 'click',
      eventData: Record<string, unknown> = {}, // use AnalyticsEvents[EventName] after migrating from `AnalyticsEvent`
      specialFormat: string[] = []
    ) => {
      const { co2_emissions, market_rate, contract_rate, preferences } =
        eventData
      if (specialFormat.includes('lane')) {
        eventData.co2_emission_calculated = !!co2_emissions
        eventData.market_rate_calculated = !!market_rate
        eventData.short_term_available = !!contract_rate
      }

      if (specialFormat.includes('rfpLanes')) {
        delete eventData.preferences
      }

      if (
        (specialFormat.includes('lane') ||
          specialFormat.includes('rfpLanes')) &&
        Array.isArray(preferences)
      ) {
        const modes = preferences.reduce(
          (acc: string[], preference: LanePreference) => {
            if (!acc.includes(preference.mode)) {
              return [...acc, preference.mode]
            }
            return acc
          },
          []
        )
        eventData.mode = modes.join(', ')
        eventData.multimode = modes.length > 1
      }

      if (!shouldTrack() || userUtils.user?.user_is_test) {
        // eslint-disable-next-line no-console
        console.log(
          `analytics.track('${event}', ${trigger}, ${JSON.stringify(eventData)})`
        )
        return
      }

      eventData.trigger = trigger
      mixpanel.track(event, eventData)
      logger.info(event, { ...eventData, metric_type: event })
    },
    reset: () => {
      if (!shouldTrack()) {
        return
      }
      mixpanelReset()
    },
  },
  (error) => {
    Sentry.captureException(error)
  }
)

export default analytics
