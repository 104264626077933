import {
  IconArrowLeft,
  IconAttention,
  IconCheck,
  IconClock,
  IconExport,
  IconQuestionCircle,
} from '@loadsmart/icons'
import type { WCRadioGroup } from '@loadsmart/miranda-react'
import {
  Banner,
  Button,
  Dialog,
  Layout,
  Radio,
  RadioGroup,
  Table,
  Text,
  Tooltip,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { defaultTo, isEmpty } from 'lodash-es'
import type { ChangeEvent } from 'react'
import { useCallback } from 'react'
import { generatePath, NavLink } from 'react-router-dom'

import {
  OneClickPlanResultFailedHeader,
  OneClickPlanResultLoadingHeader,
  OneClickPlanResultSuccessHeader,
} from 'fulfillments/1click-plan/OneClickPlanFulfillment.dialogResult'
import type { ListOrder } from 'orders/types'
import { appRoutes } from 'router/app-routes'

import { plural } from '../../../utils/strings'
import type {
  MutationContext,
  OneClickShipmentCreationContextValue,
} from './context'
import { useOneClickShipmentCreationContext } from './context'
import type { OneClickAvailableModes, OrderMapPerMode } from './types'

export interface ConfirmationContentHeaderProps
  extends Pick<
    OneClickShipmentCreationContextValue,
    'mutations' | 'mutationsCompleted'
  > {
  readonly selectedOrders: ListOrder[]
}

function ConfirmationContentHeader({
  mutations,
  mutationsCompleted,
  selectedOrders,
}: ConfirmationContentHeaderProps) {
  if (mutationsCompleted < 1) {
    return (
      <OneClickPlanResultLoadingHeader
        mutationsCompleted={mutationsCompleted}
        shipmentCount={selectedOrders.length}
      />
    )
  }

  const keys = Object.keys(mutations)
  const failedMutations = keys.filter((key) => mutations[key].isError).length

  if (failedMutations) {
    return <OneClickPlanResultFailedHeader failedMutations={failedMutations} />
  }

  return <OneClickPlanResultSuccessHeader shipmentsCreated={keys.length} />
}

export interface StatusCellProps {
  readonly isError?: boolean
  readonly isLoading?: boolean
  readonly isSuccess?: boolean
}

export function StatusCell({ isError, isLoading, isSuccess }: StatusCellProps) {
  if (isError) {
    return (
      <Layout.Group
        data-testid="one-click-status-cell"
        align="center"
        gap="spacing-1"
      >
        <IconAttention
          color={toCSSValue('color-text-error')}
          height={12}
          width={12}
        />
        <Text color="color-text-error">Failed to create shipment</Text>
      </Layout.Group>
    )
  }

  if (isSuccess) {
    return (
      <Layout.Group
        data-testid="one-click-status-cell"
        align="center"
        gap="spacing-1"
      >
        <IconCheck
          color={toCSSValue('color-success-100')}
          height={12}
          width={12}
        />
        <Text color="color-success-100">Shipment created</Text>
      </Layout.Group>
    )
  }

  if (isLoading) {
    return (
      <Layout.Group
        data-testid="one-click-status-cell"
        align="center"
        gap="spacing-1"
      >
        <IconClock
          color={toCSSValue('color-text-highlight')}
          height={12}
          width={12}
        />
        <Text color="color-text-highlight">Creating shipment...</Text>
      </Layout.Group>
    )
  }

  return null
}

export interface LinkCellProps {
  readonly newPage?: boolean
  readonly shipmentUUID?: string
}

export function LinkCell({ newPage = true, shipmentUUID }: LinkCellProps) {
  if (!shipmentUUID || isEmpty(shipmentUUID)) {
    return null
  }

  return (
    <NavLink
      target={newPage && process.env.NODE_ENV != 'test' ? '_blank' : undefined}
      to={{
        pathname: generatePath(appRoutes.shipmentDetails, {
          id: shipmentUUID,
        }),
      }}
      style={{ textDecoration: 'none' }}
    >
      <Layout.Group
        data-testid="one-click-link-cell"
        align="center"
        gap="spacing-1"
        justify="flex-end"
      >
        <IconExport
          color={toCSSValue('color-text-link')}
          height={12}
          width={12}
        />
        <Text color="color-text-link">Go to shipment</Text>
      </Layout.Group>
    </NavLink>
  )
}

export interface ConfirmationContentTableRowProps {
  readonly mutationContext?: MutationContext
  readonly order: ListOrder
}

export function ConfirmationContentTableRow({
  mutationContext,
  order,
}: ConfirmationContentTableRowProps) {
  return (
    <Table.Row>
      <Table.Cell
        style={{
          paddingBottom: toCSSValue('spacing-4'),
          paddingTop: toCSSValue('spacing-4'),
        }}
      >
        {order.primary_ref}
      </Table.Cell>

      <Table.Cell
        style={{
          paddingBottom: toCSSValue('spacing-4'),
          paddingTop: toCSSValue('spacing-4'),
        }}
      >
        <StatusCell
          isError={mutationContext?.isError}
          isLoading={mutationContext?.isLoading}
          isSuccess={mutationContext?.isSuccess}
        />
      </Table.Cell>

      <Table.Cell
        style={{
          paddingBottom: toCSSValue('spacing-4'),
          paddingTop: toCSSValue('spacing-4'),
        }}
      >
        <LinkCell shipmentUUID={mutationContext?.response?.shipment_uuid} />
      </Table.Cell>
    </Table.Row>
  )
}

export interface ConfirmationContentProps
  extends Pick<
    OneClickShipmentCreationContextValue,
    'mutations' | 'mutationsCompleted'
  > {
  readonly selectedOrders: ListOrder[]
}

export function ConfirmationContent({
  mutations,
  mutationsCompleted,
  selectedOrders,
}: ConfirmationContentProps) {
  return (
    <Layout.Stack gap="spacing-6">
      <ConfirmationContentHeader
        mutations={mutations}
        mutationsCompleted={mutationsCompleted}
        selectedOrders={selectedOrders}
      />

      <Table size="small">
        <Table.Head>
          <Table.Row>
            <Table.HeadCell style={{ width: '150px' }}>Order</Table.HeadCell>
            <Table.HeadCell>Status</Table.HeadCell>
            <Table.HeadCell />
          </Table.Row>
        </Table.Head>

        <Table.Body>
          {selectedOrders.map((order) => (
            <ConfirmationContentTableRow
              key={order.uuid}
              mutationContext={mutations[order.uuid]}
              order={order}
            />
          ))}
        </Table.Body>
      </Table>
    </Layout.Stack>
  )
}

export type ConfirmationActionsProps = Pick<
  OneClickShipmentCreationContextValue,
  'closeDialog' | 'mutationsCompleted'
>

export function ConfirmationActions({
  closeDialog,
  mutationsCompleted,
}: ConfirmationActionsProps) {
  return (
    <Dialog.Actions>
      <Dialog.ActionPrimary
        disabled={mutationsCompleted < 1}
        onClick={closeDialog}
        trailing={<IconArrowLeft />}
      >
        Close
      </Dialog.ActionPrimary>
    </Dialog.Actions>
  )
}

export interface SelectedOrdersBannerProps
  extends Pick<OneClickShipmentCreationContextValue, 'mode'> {
  readonly ordersPerMode: OrderMapPerMode
  readonly selectedOrdersCount: number
}

export function SelectedOrdersBanner({
  mode,
  ordersPerMode,
  selectedOrdersCount,
}: SelectedOrdersBannerProps) {
  if (
    ordersPerMode.incompatibleModes.length ||
    ordersPerMode.alreadyPlanned.length ||
    ordersPerMode[mode].unsuitable.length
  ) {
    return (
      <Banner variant="warning">
        <Banner.Title>
          {selectedOrdersCount} {plural('order', selectedOrdersCount)} selected
        </Banner.Title>
        <Banner.Description>
          <div>Separate shipments will be created for each selected order.</div>
          {ordersPerMode.incompatibleModes.length > 0 && (
            <>
              Except for the following, which are incompatible with the
              available shipment modes:
              <ul style={{ marginTop: 0 }}>
                {ordersPerMode.incompatibleModes.map((order) => (
                  <li key={order.uuid}>
                    <Text color="color-text-secondary" variant="body-md-bold">
                      {order.primary_ref}
                    </Text>
                  </li>
                ))}
              </ul>
            </>
          )}
          {ordersPerMode.alreadyPlanned.length > 0 && (
            <>
              Except for the following, which are already planned:
              <ul style={{ marginTop: 0 }}>
                {ordersPerMode.alreadyPlanned.map((order) => (
                  <li key={order.uuid}>
                    <Text color="color-text-secondary" variant="body-md-bold">
                      {order.primary_ref}
                    </Text>
                  </li>
                ))}
              </ul>
            </>
          )}
          {ordersPerMode[mode].unsuitable.length > 0 && (
            <>
              Except for the following, which are incompatible with the chosen
              shipment mode:
              <ul style={{ marginTop: 0 }}>
                {ordersPerMode[mode].unsuitable.map((order) => (
                  <li key={order.uuid}>
                    <Text color="color-text-secondary" variant="body-md-bold">
                      {order.primary_ref}
                    </Text>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Banner.Description>
      </Banner>
    )
  }

  return (
    <Banner>
      <Banner.Title>
        {selectedOrdersCount} {plural('order', selectedOrdersCount)} selected
      </Banner.Title>
      <Banner.Description>
        We will create a separate shipment for each selected order
      </Banner.Description>
    </Banner>
  )
}

function ImcompatibleModeTooltip() {
  return (
    <Tooltip
      placement="top"
      trigger="hover"
      message={
        <>
          The order is incompatible
          <br /> with this shipment mode
        </>
      }
    >
      <IconQuestionCircle height={16} width={16} title="" />
    </Tooltip>
  )
}

export interface PreConfirmationContentProps
  extends Pick<OneClickShipmentCreationContextValue, 'mode' | 'setMode'> {
  readonly isFTLEnabled: boolean
  readonly isLTLEnabled: boolean
  readonly ordersPerMode: OrderMapPerMode
  readonly selectedOrdersCount: number
}

export function PreConfirmationContent({
  isFTLEnabled,
  isLTLEnabled,
  mode,
  ordersPerMode,
  selectedOrdersCount,
  setMode,
}: PreConfirmationContentProps) {
  const handleChange = useCallback(
    (e: ChangeEvent<WCRadioGroup>) => {
      setMode(e.target.value as OneClickAvailableModes)
    },
    [setMode]
  )

  return (
    <Layout.Stack>
      <SelectedOrdersBanner
        mode={mode}
        ordersPerMode={ordersPerMode}
        selectedOrdersCount={selectedOrdersCount}
      />

      <Layout.Stack>
        <Text color="color-text-tertiary">Select the shipment mode</Text>
        <RadioGroup
          data-testid="one-click-mode-selection"
          onChange={handleChange}
          value={mode}
        >
          {isFTLEnabled ? (
            <Radio
              data-testid="one-click-mode-selection-ftl"
              aria-label="FTL"
              value="FTL"
            >
              <Text variant="body-md-bold">FTL</Text> (Full Truckload)
            </Radio>
          ) : (
            <Layout.Group
              data-testid="one-click-mode-selection-ftl"
              align="center"
              gap="none"
            >
              <Radio aria-label="FTL" disabled value="FTL">
                <Text variant="body-md-bold">FTL</Text> (Full Truckload)
              </Radio>
              <ImcompatibleModeTooltip />
            </Layout.Group>
          )}

          {isLTLEnabled ? (
            <Radio
              data-testid="one-click-mode-selection-ltl"
              aria-label="LTL"
              value="LTL"
            >
              <Text variant="body-md-bold">LTL</Text> (Less Than Truckload)
            </Radio>
          ) : (
            <Layout.Group
              data-testid="one-click-mode-selection-ltl"
              align="center"
              gap="none"
            >
              <Radio aria-label="LTL" disabled value="LTL">
                <Text variant="body-md-bold">LTL</Text> (Less Than Truckload)
              </Radio>
              <ImcompatibleModeTooltip />
            </Layout.Group>
          )}
        </RadioGroup>
      </Layout.Stack>
    </Layout.Stack>
  )
}

export interface PreConfirmationActionsProps
  extends Pick<
    OneClickShipmentCreationContextValue,
    'canProceed' | 'closeDialog' | 'onConfirm'
  > {
  readonly validOrdersCount: number
}

export function PreConfirmationActions({
  canProceed,
  closeDialog,
  onConfirm,
  validOrdersCount,
}: PreConfirmationActionsProps) {
  return (
    <Dialog.Actions>
      <Dialog.ActionTertiary onClick={closeDialog}>
        Cancel
      </Dialog.ActionTertiary>
      <Dialog.ActionPrimary
        disabled={!canProceed}
        onClick={onConfirm}
        trailing={<IconArrowLeft />}
      >
        Create {validOrdersCount} {plural('shipment', validOrdersCount)}
      </Dialog.ActionPrimary>
    </Dialog.Actions>
  )
}

export function AutoCreateShipmentsDialog() {
  const {
    canProceed,
    hasConfirmed,
    isFTLEnabled,
    isLTLEnabled,
    isOpen,
    closeDialog,
    mutations,
    mutationsCompleted,
    mode,
    onConfirm,
    ordersPerMode,
    setMode,
    selectedOrders,
    validOrdersCount,
  } = useOneClickShipmentCreationContext()

  if (!isOpen || selectedOrders.length === 0) {
    return null
  }

  return (
    <Dialog
      open={isOpen}
      size="medium"
      data-testid="dialog-auto-create-shipments"
      onClose={closeDialog}
    >
      <Dialog.Header>
        Auto create shipments <Dialog.Close />
      </Dialog.Header>
      <Dialog.Body>
        {hasConfirmed ? (
          <ConfirmationContent
            mutations={mutations}
            mutationsCompleted={mutationsCompleted}
            selectedOrders={selectedOrders}
          />
        ) : (
          <PreConfirmationContent
            isFTLEnabled={isFTLEnabled}
            isLTLEnabled={isLTLEnabled}
            mode={mode}
            ordersPerMode={ordersPerMode}
            selectedOrdersCount={selectedOrders.length}
            setMode={setMode}
          />
        )}
      </Dialog.Body>
      {hasConfirmed ? (
        <ConfirmationActions
          closeDialog={closeDialog}
          mutationsCompleted={mutationsCompleted}
        />
      ) : (
        <PreConfirmationActions
          canProceed={canProceed}
          closeDialog={closeDialog}
          onConfirm={onConfirm}
          validOrdersCount={validOrdersCount}
        />
      )}
    </Dialog>
  )
}

const BULK_ONE_CLICK_LIMIT = 10

interface BulkAutoPlanButtonProps {
  readonly selectedOrders?: ListOrder[]
}

export function BulkAutoPlanButton({
  selectedOrders,
}: BulkAutoPlanButtonProps) {
  const { isOneClickShipmentCreationEnabled, openDialog } =
    useOneClickShipmentCreationContext()

  const onAutoPlanFromSelectionClick = useCallback(() => {
    if (selectedOrders?.length) {
      openDialog(selectedOrders)
    }
  }, [openDialog, selectedOrders])

  if (!selectedOrders?.length) {
    return null
  }

  if (defaultTo(selectedOrders?.length, 0) > BULK_ONE_CLICK_LIMIT) {
    return (
      <Tooltip
        placement="top"
        trigger="hover"
        message={
          <>
            Please select up to {BULK_ONE_CLICK_LIMIT} orders to
            <br /> proceed with auto-planning
          </>
        }
      >
        <Button disabled variant="primary" data-testid="button-bulk-auto-plan">
          Plan each order as a shipment
          <IconQuestionCircle height={16} width={16} title="" />
        </Button>
      </Tooltip>
    )
  }

  return (
    <Button
      hidden={!isOneClickShipmentCreationEnabled || !selectedOrders?.length}
      onClick={onAutoPlanFromSelectionClick}
      variant="primary"
      data-testid="button-bulk-auto-plan"
    >
      Plan each order as a shipment
    </Button>
  )
}
