import { Select, Tag } from '@loadsmart/loadsmart-ui'
import type { FieldProps } from '@loadsmart/miranda-react'
import { Field, Layout } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import type { ListOrder } from 'orders/types'
import type { Transient } from 'utils/transient'

import type { TransientShippingItem } from './ShippingItems.types'
import type { UseShippingItemProps } from './ShippingItemsForm'
import { useShippingItemFormField } from './ShippingItemsForm'

export const ShippingItemOrders = (props: {
  readonly selectableOrders: ListOrder[]
  readonly required?: FieldProps['required']
  readonly hint?: UseShippingItemProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    name,
    dispatch,
  } = useShippingItemFormField({ name: 'orders', hint })

  const options = props.selectableOrders.map((order) => ({
    value: order.uuid,
    label: order.primary_ref,
    uuid: order.uuid,
    primary_ref: order.primary_ref,
    po_number: order.po_number,
    so_number: order.so_number,
    external_id: order.external_id,
  }))

  const value = get<Transient<TransientShippingItem>, string, SelectOption[]>(
    item,
    name,
    []
  )

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid="shipping-item-orders"
    >
      <Field.Label {...labelProps}>Orders primary ref</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={Boolean(controlProps.disabled)}
        name={name}
        placeholder="Orders primary ref"
        options={options}
        multiple
        value={value}
        onChange={(e) => {
          dispatch({
            type: 'SET_ITEM',
            payload: {
              index,
              changes: {
                [name]: e.target.value as SelectOption[],
              },
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />

      <Layout.Group data-testid="selected-orders-filters" paddingT="spacing-1">
        {value?.map((order) => (
          <Tag
            key={order.value}
            variant="default"
            removable
            onRemove={() => {
              const newValue = value.filter(
                (currentItem) => currentItem.value !== order.value
              )
              dispatch({
                type: 'SET_ITEM',
                payload: {
                  index,
                  changes: {
                    [name]: newValue,
                  },
                },
              })
            }}
          >
            {order.label}
          </Tag>
        ))}
      </Layout.Group>
    </Field>
  )
}
