import { Field, Select } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const CONTAINER_SIZE_NAME = 'container_size'
const CONTAINER_SIZE_LABEL = 'Container size'

type FreightInformationContainerSizeProps = {
  readonly disabled?: boolean
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

const CONTAINER_SIZE_OPTIONS = [
  {
    label: '20ft Standard',
    value: '20',
  },
  {
    label: '40ft Standard',
    value: '40',
  },
  {
    label: '40ft High Cube',
    value: '40HC',
  },
  {
    label: '45ft High Cube',
    value: '45HC',
  },
]

type FreightInformationContainerSizeSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name' | 'value'
>

export function FreightInformationContainerSizeSummaryField(
  props: Readonly<FreightInformationContainerSizeSummaryFieldProps>
) {
  const { shipment } = useFreightInformationSummaryContext()

  return (
    <FreightInformationSummaryField
      label={CONTAINER_SIZE_LABEL}
      name={CONTAINER_SIZE_NAME}
      value={
        CONTAINER_SIZE_OPTIONS.find(
          (option) => option.value === shipment[CONTAINER_SIZE_NAME]
        )?.label
      }
      {...props}
    />
  )
}

type SingleSelectOption = string | undefined

export function FreightInformationContainerSizeField({
  disabled = false,
  required,
  hint,
  error,
  style,
}: FreightInformationContainerSizeProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: CONTAINER_SIZE_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${CONTAINER_SIZE_NAME}-field`}
    >
      <Field.Label id={`label-${CONTAINER_SIZE_NAME}`}>
        {CONTAINER_SIZE_LABEL}
      </Field.Label>
      <Select
        aria-labelledby={`label-${CONTAINER_SIZE_NAME}`}
        disabled={disabled}
        name={CONTAINER_SIZE_NAME}
        placeholder="Select"
        options={CONTAINER_SIZE_OPTIONS}
        value={shipment[CONTAINER_SIZE_NAME]}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [CONTAINER_SIZE_NAME]: get(
                event,
                'target.value',
                undefined
              ) as SingleSelectOption,
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
