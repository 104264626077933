import {
  EmptyState,
  Header,
  Layout,
  LogoLoader,
  PageContent,
} from '@loadsmart/miranda-react'
import { useEffect } from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import { Redirect, useHistory } from 'react-router-dom'

import { WindowTitle } from '_shared_/components/WindowTitle'
import GenericErrorIllustration from 'assets/illustrations/generic-error.svg'
import { useFulfillmentDetails } from 'fulfillments/hooks/useFulfillmentDetails'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

import { FulfillmentFormProvider } from '../components/FulfillmentFormContext'
import { EditFulfillmentForm } from './EditFulfillmentForm'

export function EditFulfillmentPage({
  match,
}: Readonly<RouteComponentProps<{ id: string }>>) {
  const history = useHistory()
  const { id } = match.params
  const { data: fulfillment, status, refetch } = useFulfillmentDetails(id)

  function handleBack() {
    history.goBack()
  }

  useEffect(() => {
    analytics.track('Viewed: Edit Fulfillment', AnalyticsEventTrigger.view)
  }, [])

  // we only allow editing fulfillments that are in planning status
  if (fulfillment?.status === 'closed') {
    return <Redirect to={`/fulfillments/${id}`} />
  }

  return (
    <PageContent>
      <WindowTitle title="Edit fulfillment | ShipperGuide" />
      <Header>
        <Header.BackButton onClick={handleBack} />
        <Header.Content>
          <Header.Title>
            Edit fulfillment {fulfillment?.ref_number}
          </Header.Title>
        </Header.Content>
      </Header>
      <PageContent.Body paddingX="none" paddingB="none">
        {status === 'loading' && (
          <Layout.Stack align="center" justify="center">
            <LogoLoader size="48" aria-busy="true" aria-label="Loading" />
          </Layout.Stack>
        )}
        {status === 'success' && (
          <FulfillmentFormProvider action="edit" fulfillment={fulfillment}>
            <EditFulfillmentForm />
          </FulfillmentFormProvider>
        )}
        {status === 'error' && (
          <Layout.Stack align="center" justify="center">
            <EmptyState
              variant="page"
              illustration={GenericErrorIllustration}
              header="Could not load fulfillment"
              message="Please try again"
              action="Retry"
              onAction={() => {
                refetch()
              }}
              data-testid="edit-fulfillment-empty-state"
            />
          </Layout.Stack>
        )}
      </PageContent.Body>
    </PageContent>
  )
}
