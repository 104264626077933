import { IconQuestionCircle } from '@loadsmart/icons'
import { Layout, Tag, Tooltip } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import TableActions from '_shared_/components/TableActions'
import { useSettings } from '_shared_/settings/useSettings'
import loadsmartLogo from 'assets/icons/loadsmart.svg'
import {
  StyledCard,
  StyledBody,
  StyledTitle,
  ActionFooter,
  ShowHideAction,
  TitleLoaderWrapper,
} from 'components/SideBar/styles'
import TableV3 from 'components/TableV3'
import { Text } from 'components/Typography'
import { RateType } from 'features/laneProposals/reducer'
import { laneProposalsRateTypeViewSelector } from 'features/laneProposals/selectors'
import { getPerDistanceRate, isKilometers } from 'rfp/components/distanceType'
import { TableHeaderDropdown } from 'rfp/rfp-details/award-scenarios/TableHeaderDropdown'
import MultiCurrencyValue from 'rfp/rfp-details/components/MultiCurrencyValue'
import type { Currency, RequestForProposal } from 'rfp/rfp.types'
import { partialUpdate } from 'services/laneProposal'
import { MODES_DICT } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'
import { getEquipmentType } from 'utils/equipmentTypeV2'
import { numberFormatter } from 'utils/numbers'

import {
  getFirstLowestBid,
  getFirstHighestBid,
  getBenchmarkColor,
} from '../../utils'
import {
  CarrierCell,
  CarrierHeader,
  LSIcon,
  Capacity,
  RatePercent,
} from './styles'

const tableInitalState = { sortBy: [{ id: 'flat_rate' }] }
const carrierMaxLength = 34

const rateColumnOptions = [
  {
    value: 'flat_rate',
    label: 'Flat Rate',
  },
  {
    value: 'per_mile_rate',
    label: 'RPM',
  },
]

const CarrierName = ({
  dot,
  loadsmartDOT,
  name,
}: {
  readonly dot: unknown
  readonly loadsmartDOT: unknown
  readonly name: string
}) => {
  const carrierName =
    name.length > carrierMaxLength
      ? `${name.substring(0, carrierMaxLength)} ...`
      : name
  return dot !== loadsmartDOT ? (
    carrierName
  ) : (
    <LSIcon type="image/svg+xml" data={loadsmartLogo}>
      {name}
    </LSIcon>
  )
}

const getFlatRateValueField = (displayMultiCurrency: boolean) =>
  displayMultiCurrency ? 'converted_flat_rate' : 'flat_rate'

const getModesAndEquipmentColumn = () => {
  return [
    {
      Header: 'Modes & Equip.',
      id: 'preferences',
      className: 'text-left',
      Cell: function modesAndEquipmentRender({
        row,
      }: {
        row: { original: LaneProposal }
      }) {
        const { preference } = row.original
        const { mode, equipment_type } = preference

        return (
          <Tag variant="neutral" size="default">
            {MODES_DICT[mode]}&nbsp;&rarr;&nbsp;
            {getEquipmentType(equipment_type)?.abbr}
          </Tag>
        )
      },
    },
  ]
}

const getRateColumn = (
  displayMultiCurrency: boolean,
  rfpCurrency: Currency,
  rfpDistanceType: RequestForProposal['distance_type'],
  rateColumn: string,
  setRateColumn: (value: string) => void
) => {
  return [
    {
      Header: (
        <Layout.Group gap="spacing-2" style={{ flexWrap: 'nowrap' }}>
          {
            rateColumnOptions.find((option) => option.value === rateColumn)
              ?.label
          }
          <TableHeaderDropdown
            options={rateColumnOptions}
            defaultValue={rateColumn}
            setValue={setRateColumn}
            disabled={false}
          />
        </Layout.Group>
      ),
      id: rateColumn,
      className: 'text-left',
      Cell: function render({ row }: { row: any }) {
        if (rateColumn === 'flat_rate') {
          const valueField = getFlatRateValueField(displayMultiCurrency)

          return (
            <MultiCurrencyValue
              value={row.original[valueField]}
              rfpCurrency={rfpCurrency}
              nonConvertedValue={row.original['flat_rate']}
              boldStyle={false}
            />
          )
        }

        const value = getPerDistanceRate(
          row.original,
          rfpDistanceType,
          displayMultiCurrency
        )

        const nonConvertedValue = isKilometers(rfpDistanceType)
          ? row.original['per_kilometer_rate']
          : row.original['per_mile_rate']

        return (
          <MultiCurrencyValue
            value={value}
            rfpCurrency={rfpCurrency}
            nonConvertedValue={nonConvertedValue}
            boldStyle={false}
          />
        )
      },
    },
  ]
}

export default function LaneBidsRemovedV1({
  lane,
  rfpCurrency,
  removedLaneProposals,
  isLoadingLaneProposals,
  rfpDistanceType,
}: {
  readonly lane: Lane
  readonly rfpCurrency: Currency
  readonly removedLaneProposals: LaneProposal[]
  readonly isLoadingLaneProposals: boolean
  readonly rfpDistanceType: RequestForProposal['distance_type']
}) {
  const displayMultiCurrency = isMultiCurrency(rfpCurrency)
  const [show, setShow] = useState<boolean>(false)
  const rateType = useSelector(laneProposalsRateTypeViewSelector)
  const [rateColumn, setRateColumn] = useState(rateColumnOptions[0].value)
  const queryClient = useQueryClient()

  const {
    values: [loadsmartDOT],
  } = useSettings(['settings.LOADSMART_DOT'])

  const { mutate: mutateLaneProposal, isLoading: isUpdating } = useMutation({
    mutationFn: partialUpdate,
    onSuccess() {
      queryClient.refetchQueries({ queryKey: ['retrieveLaneProposal'] })
      toast.success('Bid added back.')
    },
    onError() {
      toast.error('Could not apply the requested changes. Please contact us.')
    },
  })

  const handleAddBid = useCallback(
    (laneProposal: LaneProposal) => {
      mutateLaneProposal({
        laneId: lane.id,
        carrierId: laneProposal.id,
        payload: {
          is_removed: false,
        },
      })
    },
    [lane.id, mutateLaneProposal]
  )

  const columns = useMemo(() => {
    return () => [
      {
        Header: <CarrierHeader>Carrier</CarrierHeader>,
        id: 'name',
        accessor: ({ carrier }: { carrier: any }) => carrier.name,
        className: 'text-left',
        Cell: function render({ row }: Readonly<{ row: any }>) {
          const { name, dot } = row.original.carrier
          return (
            <CarrierCell>
              <CarrierName dot={dot} loadsmartDOT={loadsmartDOT} name={name} />
            </CarrierCell>
          )
        },
      },
      ...getModesAndEquipmentColumn(),
      {
        Header: 'Volume',
        id: 'capacity',
        accessor: 'capacity',
        className: 'text-left',
        Cell: function Cell({ value }: { readonly value: number }) {
          return (
            <Capacity highlight={Boolean(lane?.volume > value)}>
              {value}
            </Capacity>
          )
        },
      },
      ...getRateColumn(
        displayMultiCurrency,
        rfpCurrency,
        rfpDistanceType,
        rateColumn,
        setRateColumn
      ),
      ...(displayMultiCurrency
        ? []
        : [
            {
              Header: (
                <Layout.Group gap="spacing-1">
                  Bench.
                  <Tooltip
                    placement="bottom-start"
                    trigger="hover"
                    message={
                      <>
                        Shows the percentage
                        <br />
                        difference between the bid and
                        <br /> the selected benchmark (Target
                        <br /> Rate or Market Benchmark).
                      </>
                    }
                  >
                    <IconQuestionCircle
                      height={16}
                      width={16}
                      fill={toCSSValue('color-text-secondary')}
                      title={null}
                    />
                  </Tooltip>
                </Layout.Group>
              ),
              className: 'text-left',
              accessor:
                rateType === RateType.USER
                  ? 'target_rate_percent_difference'
                  : 'market_rate_percent_difference',
              Cell: function render({
                row,
                value,
                data,
              }: Readonly<{
                row: any
                value: number | null
                data: LaneProposal[]
              }>) {
                if (!value) {
                  return '- %'
                }

                const flatRate = Number(row.values.flat_rate)
                const targetRate = Number(lane?.target_rate || 0)
                const percent = Number(value)
                const firstLowestBid = getFirstLowestBid(data, lane.target_rate)
                const firstHighestBid = getFirstHighestBid(
                  data,
                  lane.target_rate
                )

                const color = getBenchmarkColor(
                  data.length,
                  flatRate,
                  targetRate,
                  firstLowestBid,
                  firstHighestBid
                )

                return (
                  <RatePercent color={color}>
                    {percent > 0 && '+'}
                    {numberFormatter(percent, 1)} %
                  </RatePercent>
                )
              },
            },
          ]),
      {
        Header: '',
        id: 'actions',
        className: 'text-left actions-cell',
        Cell: function render({ row }: Readonly<{ row: any }>) {
          return (
            <TableActions>
              <TableActions.Item onClick={() => handleAddBid(row.original)}>
                <Text size="medium" weight="heavy" color="success">
                  Add Bid
                </Text>
              </TableActions.Item>
            </TableActions>
          )
        },
      },
    ]
  }, [
    displayMultiCurrency,
    rfpCurrency,
    rfpDistanceType,
    rateColumn,
    rateType,
    loadsmartDOT,
    lane?.volume,
    lane.target_rate,
    handleAddBid,
  ])

  return (
    <StyledCard data-testid="header">
      <StyledTitle style={{ marginBottom: '30px' }}>
        Bids Removed
        {isUpdating && <TitleLoaderWrapper />}
      </StyledTitle>
      {show && (
        <StyledBody style={{ marginBottom: '30px' }}>
          <TableV3
            columns={columns}
            entries={removedLaneProposals}
            isLoading={isLoadingLaneProposals}
            initialState={tableInitalState}
            sortable
            pagination
            bordered={false}
          />
        </StyledBody>
      )}

      <ActionFooter>
        {show ? (
          <ShowHideAction onClick={() => setShow(!show)}>
            Show Less
          </ShowHideAction>
        ) : (
          <ShowHideAction onClick={() => setShow(!show)}>
            Show More
          </ShowHideAction>
        )}
      </ActionFooter>
    </StyledCard>
  )
}
