import { noop } from 'lodash-es'
import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import type { FilterValues } from '../hooks/useOrdersFiltersQueryParamPlugin'
import { FiltersDrawer } from './FiltersDrawer'

interface FiltersContextValue {
  closeFilters: () => void
  openFilters: () => void
  view?: 'customer' | 'supplier'
}

const FiltersContext = createContext<FiltersContextValue>({
  closeFilters: noop,
  openFilters: noop,
})

export function useOrdersFiltersContext() {
  const context = useContext(FiltersContext)

  if (!context) {
    throw new Error(
      'useOrdersFiltersContext must be used within a OrdersFiltersProvider'
    )
  }

  return context
}

export interface OrdersFiltersProviderProps extends PropsWithChildren {
  readonly clearFilters: () => void
  readonly filters: FilterValues
  readonly setFilters: (newFilters: FilterValues) => void
  readonly view?: 'customer' | 'supplier'
}

export function OrdersFiltersProvider({
  children,
  clearFilters,
  filters,
  setFilters,
  view = 'customer',
}: OrdersFiltersProviderProps) {
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false)
  const openFilters = useCallback(() => {
    setIsFiltersDrawerOpen(true)
  }, [setIsFiltersDrawerOpen])
  const closeFilters = useCallback(() => {
    setIsFiltersDrawerOpen(false)
  }, [setIsFiltersDrawerOpen])

  const contextValue = useMemo(
    () => ({ closeFilters, openFilters, view }),
    [closeFilters, openFilters, view]
  )

  return (
    <FiltersContext.Provider value={contextValue}>
      {children}

      <FiltersDrawer
        clearFilters={clearFilters}
        closeFiltersDrawer={closeFilters}
        filters={filters}
        isFiltersDrawerOpen={isFiltersDrawerOpen}
        setFilters={setFilters}
      />
    </FiltersContext.Provider>
  )
}
