import { Card, Field, Layout, TextArea } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'

import type { TransientFacilityInstruction } from './Facility.types'
import { createTransientFacilityInstruction } from './InstructionsSection.helpers'

export type InstructionsSectionProps = {
  readonly collapsible?: boolean
  readonly initialCollapsed?: boolean
  readonly instruction?: TransientFacilityInstruction
  readonly onChange?: (instruction: TransientFacilityInstruction) => void
}

export function InstructionsSection(props: InstructionsSectionProps) {
  const {
    collapsible,
    initialCollapsed,
    instruction = createTransientFacilityInstruction(),
    onChange,
  } = props

  return (
    <Card
      collapsible={collapsible}
      initialCollapsed={initialCollapsed}
      data-testid="instructions-section"
    >
      <Card.Title role="heading" aria-level={2}>
        Instructions
      </Card.Title>
      <Card.Subtitle role="heading" aria-level={3}>
        Set default instructions for new shipments handled by this facility
      </Card.Subtitle>

      <Card.Divider />

      <Card.Body>
        <Layout.Container>
          <Layout.Row>
            <Layout.Column>
              <Field>
                <Field.Label id="label-for-facility-generic-instructions">
                  Default instructions
                </Field.Label>
                <TextArea
                  autosize
                  aria-labelledby="label-for-facility-generic-instructions"
                  placeholder="Enter default instructions"
                  cols={20}
                  value={instruction.note ?? ''}
                  onInput={(event) => {
                    onChange?.({
                      ...instruction,
                      note: get(event, 'target.value', ''),
                    })
                  }}
                />
                <Field.Hint>
                  Default instructions apply to new shipments, but you can
                  customize instructions for each shipment.
                </Field.Hint>
              </Field>
            </Layout.Column>
          </Layout.Row>
        </Layout.Container>
      </Card.Body>
    </Card>
  )
}
