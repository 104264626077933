import { capitalize, isEmpty, isNil, isNull } from 'lodash-es'

import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { hasHazmat } from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import type { TransientStop } from 'components/StopsManager'
import { getStopFacilityLabel } from 'components/StopsManager'

import type { TransientShipment } from '../components/Shipment'
import type { ConsolidationContextValue } from './consolidation/consolidation.types'

export function updateItemsStopIndexesOnStopAddition(
  handlingUnits: TransientHandlingUnit[],
  stopAddedAt: number
): TransientHandlingUnit[] {
  return handlingUnits.map((handlingUnit) => {
    const newIndexes: {
      pickup_stop_index?: number
      delivery_stop_index?: number | null
    } = {}
    if (stopAddedAt <= handlingUnit.pickup_stop_index!) {
      newIndexes.pickup_stop_index = Number(handlingUnit.pickup_stop_index) + 1
    }
    if (stopAddedAt <= handlingUnit.delivery_stop_index!) {
      newIndexes.delivery_stop_index =
        Number(handlingUnit.delivery_stop_index) + 1
    }
    return {
      ...handlingUnit,
      ...newIndexes,
    }
  })
}

export function updateItemsStopIndexesOnStopRemoval(
  handlingUnits: TransientHandlingUnit[],
  removedStop: number
): TransientHandlingUnit[] {
  return handlingUnits.map((handlingUnit) => {
    const newIndexes: {
      pickup_stop_index?: number
      delivery_stop_index?: number | null
    } = {}
    if (removedStop < handlingUnit.pickup_stop_index!) {
      newIndexes.pickup_stop_index = Number(handlingUnit.pickup_stop_index) - 1
    }
    if (removedStop < handlingUnit.delivery_stop_index!) {
      newIndexes.delivery_stop_index =
        Number(handlingUnit.delivery_stop_index) - 1
    } else if (removedStop === handlingUnit.delivery_stop_index) {
      newIndexes.delivery_stop_index = null
    }
    return {
      ...handlingUnit,
      ...newIndexes,
    }
  })
}

function stopTypeChanged(oldStops: TransientStop[], newStops: TransientStop[]) {
  return oldStops.some((oldStop, index) => {
    return oldStop.stop_type !== newStops[index].stop_type
  })
}

export function updateItemStopIndexesIfStopTypeChanged(
  shipment: TransientShipment,
  newTransientStops: TransientStop[]
): TransientHandlingUnit[] {
  if (
    shipment.stops.length !== newTransientStops.length ||
    !stopTypeChanged(shipment.stops, newTransientStops)
  ) {
    return shipment.items
  }

  return shipment.items.map((handlingUnit) => {
    const newIndexes: {
      pickup_stop_index?: number
      delivery_stop_index?: number | null
    } = {}
    const pickupStop = handlingUnit.pickup_stop_index
      ? newTransientStops[handlingUnit.pickup_stop_index]
      : null
    const deliveryStop = handlingUnit.delivery_stop_index
      ? newTransientStops[handlingUnit.delivery_stop_index]
      : null
    if (!isNull(pickupStop) && pickupStop.stop_type === 'delivery') {
      newIndexes.pickup_stop_index = 0
    }
    if (!isNull(deliveryStop) && deliveryStop?.stop_type === 'pickup') {
      newIndexes.delivery_stop_index = null
    }
    return {
      ...handlingUnit,
      ...newIndexes,
    }
  })
}

function getStopSectionIndex(index: number, totalStops: number) {
  return {
    id: `stop-${index}`,
    label: `${getStopFacilityLabel(index, totalStops)} details`,
  }
}

function getStopIndexes(
  stops: TransientShipment['stops'],
  withIntermediaryStops: boolean
) {
  const stopsToRender = withIntermediaryStops
    ? stops
    : [stops[0], stops[stops.length - 1]]

  const stopIndexes = stopsToRender
    .filter((stop) => !isNull(stop.facility) || !isNil(stop.terminal))
    .map((stop) => getStopSectionIndex(stop.stop_index!, stops.length))

  return stopIndexes
}

export function getShipmentFormIndexes({
  transientShipment,
  withIntermediaryStops,
  consolidation = null,
  withShippingItems,
  withContainerDetails,
}: {
  transientShipment: TransientShipment
  withIntermediaryStops: boolean
  consolidation?: ConsolidationContextValue['consolidationType'] | null
  withShippingItems?: boolean
  withContainerDetails?: boolean
}) {
  const { stops, shipper_custom_fields } = transientShipment

  const stopIndexes = getStopIndexes(stops, withIntermediaryStops)

  // Review this wording
  const orderSelection = consolidation
    ? [
        {
          id: `${consolidation}-selection-card`,
          label: `${capitalize(consolidation)}s`,
        },
      ]
    : []

  const customFieldsSection = !isEmpty(shipper_custom_fields)
    ? [
        {
          id: 'custom-fields-card',
          label: 'Custom fields',
        },
      ]
    : []

  const containerDetailsSelection = withContainerDetails
    ? [
        {
          id: 'container-details-card',
          label: 'Container Details',
        },
      ]
    : []

  return [
    ...orderSelection,
    { id: 'mode-card', label: 'Mode' },
    { id: 'lane-card', label: 'Lane' },
    ...stopIndexes,
    ...(withShippingItems
      ? [{ id: 'shipping-items-card', label: 'Shipping Items' }]
      : []),
    ...containerDetailsSelection,
    {
      id: 'more-details-card',
      label: 'More details',
    },
    ...customFieldsSection,
  ]
}

export function hasHazmatCommodities(handlingUnits: TransientHandlingUnit[]) {
  const shipmentCommodities = handlingUnits
    .map(({ commodities }) => commodities)
    .flat()

  return hasHazmat(shipmentCommodities)
}

export function mapStopsToOptionsByStopType(
  stops: TransientStop[],
  stopType: StopType
) {
  return stops
    .map((stop, index) => ({
      index,
      type: stop.stop_type,
      value: String(index),
      label: getStopFacilityLabel(index, stops.length),
    }))
    .filter(({ type }) => type === stopType)
}
