import { useCallback, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'

import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import {
  getFulfillmentsAsCustomer,
  getListFulfillmentsParams,
} from 'fulfillments/fulfillment-service'
import { useSearchParams } from 'hooks/useSearchParams'
import { createSearchParams } from 'utils/searchParams'

export const SELECTED_FULFILLMENTS_QUERY_PARAM = 'fulfillments'

export const useLoadFulfillments = (selectedUUIDs: string[]) => {
  const { data, isLoading, isSuccess, isError } = useQuery<
    PaginatedResult<ListFulfillment>
  >({
    enabled: selectedUUIDs.length > 0,
    queryKey: ['loadFulfillments', selectedUUIDs.join(',')],
    queryFn: ({ signal }) =>
      getFulfillmentsAsCustomer(
        getListFulfillmentsParams({ uuid: selectedUUIDs.join(',') }),
        { signal }
      ),
  })

  const fulfillments: ListFulfillment[] = useMemo(
    () =>
      data?.results.filter((order) => selectedUUIDs.includes(order.uuid)) ?? [],
    [data, selectedUUIDs]
  )

  return { fulfillments, isLoading, isSuccess, isError }
}

export const useSelectedFulfillmentsQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const selectedFulfillmentUUIDs = useMemo(
    () => searchParams.getAll(SELECTED_FULFILLMENTS_QUERY_PARAM) || [],
    [searchParams]
  )

  const setSelectedFulfillments = useCallback(
    (newSelected: string[]) => {
      const newInit = new URLSearchParams(searchParams.toString())

      while (newInit.has(SELECTED_FULFILLMENTS_QUERY_PARAM)) {
        newInit.delete(SELECTED_FULFILLMENTS_QUERY_PARAM)
      }

      newSelected.forEach((orderUUID) => {
        newInit.append(SELECTED_FULFILLMENTS_QUERY_PARAM, orderUUID)
      })

      setSearchParams(createSearchParams(newInit))
    },
    [searchParams, setSearchParams]
  )

  const addSelectedFulfillment = useCallback(
    (newSelected: string[]) => {
      const newInit = new URLSearchParams(searchParams.toString())

      newSelected.forEach((fulfillmentUUID) => {
        newInit.append(SELECTED_FULFILLMENTS_QUERY_PARAM, fulfillmentUUID)
      })

      setSearchParams(createSearchParams(newInit))
    },
    [searchParams, setSearchParams]
  )

  const removeSelectedFulfillment = useCallback(
    (toBeRemoved: string | string[]) => {
      const newInit = new URLSearchParams(searchParams.toString())

      const newSelectedFulfillmentsUUIDs = selectedFulfillmentUUIDs.filter(
        (uuid) => {
          if (Array.isArray(toBeRemoved)) {
            return !toBeRemoved.includes(uuid)
          }
          return toBeRemoved !== uuid
        }
      )

      while (newInit.has(SELECTED_FULFILLMENTS_QUERY_PARAM)) {
        newInit.delete(SELECTED_FULFILLMENTS_QUERY_PARAM)
      }

      newSelectedFulfillmentsUUIDs.forEach((fulfillmentUUID) => {
        newInit.append(SELECTED_FULFILLMENTS_QUERY_PARAM, fulfillmentUUID)
      })

      setSearchParams(createSearchParams(newInit))
    },
    [searchParams, selectedFulfillmentUUIDs, setSearchParams]
  )

  return {
    addSelectedFulfillment,
    removeSelectedFulfillment,
    selectedFulfillmentUUIDs,
    setSelectedFulfillments,
  }
}

export function useSelectedFulfillments() {
  const {
    addSelectedFulfillment,
    removeSelectedFulfillment,
    selectedFulfillmentUUIDs,
    setSelectedFulfillments,
  } = useSelectedFulfillmentsQueryParams()
  const { fulfillments, isLoading, isSuccess, isError } = useLoadFulfillments(
    selectedFulfillmentUUIDs
  )

  useEffect(() => {
    if (isSuccess && fulfillments.length < selectedFulfillmentUUIDs.length) {
      toast.error('Unable to load some selected fulfillments.')
    }
  }, [isSuccess, fulfillments, selectedFulfillmentUUIDs])

  useEffect(() => {
    if (isError) {
      toast.error('Error loading fulfillments')
    }
  }, [isError])

  return {
    addSelectedFulfillment,
    selectedFulfillments: fulfillments,
    isLoading,
    isSuccess,
    isError,
    removeSelectedFulfillment,
    selectedFulfillmentUUIDs,
    setSelectedFulfillments,
  }
}
