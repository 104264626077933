import { SideNavigation } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { userUtils } from '_shared_/user'
import { appRoutes } from 'router/app-routes'
import { USER_TYPE } from 'utils/constants'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const ProcurementMenu = () => {
  const history = useHistory()

  if (userUtils.isUserType([USER_TYPE.SG])) {
    return (
      <SideNavigation.Menu label="Procurement">
        <SideNavigation.Menu.Item
          label="RFP Guide"
          active={isActivePath(appRoutes.rfp)}
          url={appRoutes.rfp}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Home', appRoutes.rfp, history)
          }}
        />
        <SideNavigation.Menu.Item
          label="Contracts"
          active={isActivePath(appRoutes.contracts)}
          url={appRoutes.contracts}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Contracts', appRoutes.contracts, history)
          }}
        />
      </SideNavigation.Menu>
    )
  }

  return null
}
