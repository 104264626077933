import { Button, Tooltip } from '@loadsmart/miranda-react'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { FulfillmentsRoutePaths } from 'fulfillments/FulfillmentsRoutes'
import { canCreateFulfillmentFromOrders } from 'orders/domain/Order'
import type { ListOrder, Order } from 'orders/types'

import { useIsFulfillmentsFlowEnabled } from './useIsFulfillmentsFlowEnabled'

export function useGoToNewFulfillment() {
  const history = useHistory()
  const goToNewFulfillmentForm = useCallback(() => {
    history.push(FulfillmentsRoutePaths.New)
  }, [history])

  return goToNewFulfillmentForm
}

export function useGoToNewFulfillmentWithOrders() {
  const history = useHistory()
  const goToNewFulfillmentForm = useCallback(
    (ordersUUIDs: string[]) => {
      history.push(FulfillmentsRoutePaths.New, {
        orders: ordersUUIDs,
      })
    },
    [history]
  )

  return goToNewFulfillmentForm
}

export function NewFulfillmentFromOrdersButton({
  isLoading,
  orders,
}: Readonly<{
  isLoading?: boolean
  orders: (ListOrder | Order | undefined)[]
}>) {
  const isFulfillmentsFlowEnabled = useIsFulfillmentsFlowEnabled()
  const goToNewFulfillment = useGoToNewFulfillmentWithOrders()

  if (!isFulfillmentsFlowEnabled) {
    return null
  }

  const UUIDs = orders.map((order) => order?.uuid).filter(Boolean) as string[]
  const disabled = !canCreateFulfillmentFromOrders(orders)
  const button = (
    <Button
      data-testid="button-create-new-fulfillment"
      disabled={disabled}
      loading={isLoading}
      name="New fulfillment from selection"
      onClick={() => {
        goToNewFulfillment(UUIDs)
      }}
      variant="primary"
    >
      New fulfillment from selection
    </Button>
  )

  if (disabled) {
    return (
      <Tooltip message="Closed orders were selected and cannot be used to create a new fulfillment.">
        {button}
      </Tooltip>
    )
  }

  return button
}

export function useGoToFulfillmentDetails() {
  const history = useHistory()

  const goToFulfillmentDetails = useCallback(
    (uuid: string) => {
      history.push(
        generatePath(FulfillmentsRoutePaths.Details, {
          id: uuid,
        })
      )
    },
    [history]
  )

  return goToFulfillmentDetails
}
