import { defaultTo, isEmpty } from 'lodash-es'

import {
  decodeLocationParam,
  encodeLocationParam,
  formatLocationForURL,
} from 'screens/Quotes/List/utils'
import { generateUseSearchParamsPlugin } from 'utils/filters'
import type { Filters } from 'utils/filters'

import { getOpAndValueFilter } from '../components/Filters.utils'

export type LocationParam =
  | {
      city: string
      country: string
      state: string
      zipcode: string
      place_id: string
    }
  | {
      facility_uuid: string
      facility_name: string
    }

export type FilterOp = 'eq' | 'lt' | 'lte' | 'gt' | 'gte' | null

export type GetOrdersFilters = {
  search: string | null
  delivery_date_after: string | null
  delivery_date_before: string | null
  delivery_location: LocationParam[]
  package_count_op: FilterOp
  package_count: string | null
  pickup_date_after: string | null
  pickup_date_before: string | null
  pickup_location: LocationParam[]
  status: string[] | null
  total_volume_op: FilterOp
  total_volume: string | null
  total_weight_op: FilterOp
  total_weight: string | null
}

export type FilterValues = Filters<GetOrdersFilters>

export const EMPTY_FILTERS: GetOrdersFilters = {
  search: null,
  delivery_date_after: null,
  delivery_date_before: null,
  delivery_location: [],
  package_count: null,
  package_count_op: null,
  pickup_location: [],
  pickup_date_after: null,
  pickup_date_before: null,
  status: null,
  total_volume: null,
  total_volume_op: null,
  total_weight: null,
  total_weight_op: null,
}

export function mapFilters(input: Partial<GetOrdersFilters> = {}) {
  const filters = getDefaultOrdersFilters(input)
  return {
    delivery_date_after: filters.delivery_date_after,
    delivery_date_before: filters.delivery_date_before,
    delivery_location: !isEmpty(filters.delivery_location)
      ? encodeLocationParam(filters.delivery_location)
      : null,
    ...getOpAndValueFilter(filters, 'package_count'),
    pickup_date_after: filters.pickup_date_after,
    pickup_date_before: filters.pickup_date_before,
    pickup_location: !isEmpty(filters.pickup_location)
      ? encodeLocationParam(filters.pickup_location)
      : null,
    status: defaultTo(filters.status, null),
    ...getOpAndValueFilter(filters, 'total_volume'),
    ...getOpAndValueFilter(filters, 'total_weight'),
  }
}

export function getDefaultOrdersFilters(
  overrides?: Partial<GetOrdersFilters>
): GetOrdersFilters {
  return {
    search: defaultTo(overrides?.search, null),
    delivery_date_after: defaultTo(overrides?.delivery_date_after, null),
    delivery_date_before: defaultTo(overrides?.delivery_date_before, null),
    delivery_location: defaultTo(overrides?.delivery_location, []),
    package_count_op: defaultTo(overrides?.package_count_op, null),
    package_count: defaultTo(overrides?.package_count, null),
    pickup_date_after: defaultTo(overrides?.pickup_date_after, null),
    pickup_date_before: defaultTo(overrides?.pickup_date_before, null),
    pickup_location: defaultTo(overrides?.pickup_location, []),
    total_volume_op: defaultTo(overrides?.total_volume_op, null),
    total_volume: defaultTo(overrides?.total_volume, null),
    total_weight_op: defaultTo(overrides?.total_weight_op, null),
    total_weight: defaultTo(overrides?.total_weight, null),
    status: defaultTo(overrides?.status, null),
  }
}

function isLocationOption(value: object): value is LocationOption {
  return '_type' in value
}

export function fromLocationSearchParam(value: string | null) {
  if (!value) {
    return null
  }

  return decodeLocationParam(value)
}

export function toLocationSearchParam(values: LocationOption[] | null) {
  if (!values || isEmpty(values)) {
    return null
  }

  return encodeLocationParam(
    values.map((value) => {
      if (isLocationOption(value)) {
        if (value._type === 'facility') {
          return formatLocationForURL({ ...value, isFacility: true })
        }

        return formatLocationForURL(value)
      }

      return value
    })
  )
}

export const useOrdersFiltersQueryParamsPlugin =
  generateUseSearchParamsPlugin<GetOrdersFilters>({
    search: { type: 'primitive' },
    delivery_date_after: { type: 'primitive' },
    delivery_date_before: { type: 'primitive' },
    delivery_location: {
      type: 'primitive',
      fromSearchParam: fromLocationSearchParam,
      toSearchParam: toLocationSearchParam,
    },
    package_count_op: { type: 'primitive' },
    package_count: { type: 'primitive' },
    pickup_date_after: { type: 'primitive' },
    pickup_date_before: { type: 'primitive' },
    pickup_location: {
      type: 'primitive',
      fromSearchParam: fromLocationSearchParam,
      toSearchParam: toLocationSearchParam,
    },
    status: {
      type: 'primitive-collection',
    },
    total_volume_op: { type: 'primitive' },
    total_volume: { type: 'primitive' },
    total_weight_op: { type: 'primitive' },
    total_weight: { type: 'primitive' },
  })
