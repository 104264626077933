import { Layout, SpinnerWheel, Text } from '@loadsmart/miranda-react'
import { Redirect } from 'react-router-dom'

import { useSettings } from '_shared_/settings/useSettings'
import { userUtils } from '_shared_/user'
import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { USER_TYPE } from 'utils/constants'

/**
 * User will have different home pages based on their userType
 */
export const getHomePage = (enableNewHomePage: boolean) => {
  if (userUtils.isUserType([USER_TYPE.SUPPLIER])) {
    return '/orders'
  }

  if (userUtils.isUserType([USER_TYPE.MTC]) || enableNewHomePage) {
    return '/shipments'
  }

  return '/quotes/new'
}

export function Home() {
  const { user } = useCurrentUser()
  const {
    isLoading,
    values: [enableNewHomePage],
  } = useSettings(['flags.ENABLE_NEW_HOME_PAGE'])

  if (user && !isLoading) {
    return <Redirect to={getHomePage(enableNewHomePage)} />
  }

  return (
    <Layout.Stack
      justify="center"
      align="center"
      style={{ margin: 'auto', height: '100vh' }}
    >
      <Text variant="heading-sm-bold">Loading...</Text>
      <SpinnerWheel size="48" />
    </Layout.Stack>
  )
}
