import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import { GlobalSpinner } from '_shared_/components/GlobalSpinner'
import { useFeatureFlag } from '_shared_/feature-flag'

import { PayablesBasePage } from './list/PayablesBase'

export function PayablesRoutes({ match }: Readonly<RouteComponentProps>) {
  const { path } = match

  const payablesPageEnabled = useFeatureFlag('ENABLE_SETTLEMENT_TAB')

  if (!payablesPageEnabled) {
    return <GlobalSpinner />
  }

  return (
    <Switch>
      <Route path={path} exact component={PayablesBasePage} />
    </Switch>
  )
}
