import { defaultTo } from 'lodash-es'
import { useCallback } from 'react'
import type { Column } from 'react-table'

import { Table } from 'components/TableV4'
import type { TableSelectedRowsIDs } from 'components/TableV4/Table.utils'
import { NewFulfillmentFromOrdersButton } from 'fulfillments/hooks/navigation'
import {
  OrdersFiltersProvider,
  useOrdersFiltersContext,
} from 'orders/components/Filters'
import { AppliedFiltersBar } from 'orders/components/Filters.components'
import { useGoToOrderDetails } from 'orders/hooks/navigation'
import { useOrderSelection } from 'orders/hooks/useOrderSelection'
import { usePaginatedOrders } from 'orders/hooks/usePaginatedOrders'
import type { ListOrder, Order } from 'orders/types'

import {
  CommodityCell,
  CustomerCell,
  DeliveryLocationCell,
  FulfillmentsCell,
  IdentifierCell,
  IdentifierHeaderCell,
  OrdersTableEmptyState,
  PickupLocationCell,
  StatusCell,
  SupplierActionsCell,
} from './ListOrders.components'
import { OrdersListProvider } from './ListOrders.context'

export function ListOrdersAsSupplierBulkActions({
  isLoading,
  selectedOrders,
}: Readonly<{ isLoading?: boolean; selectedOrders: (Order | undefined)[] }>) {
  return (
    <NewFulfillmentFromOrdersButton
      isLoading={isLoading}
      orders={selectedOrders}
    />
  )
}

const COLUMNS: ReadonlyArray<Column<ListOrder>> = [
  {
    Header: 'Customer',
    id: 'customer',
    Cell: CustomerCell,
  },
  {
    id: 'ref_number',
    Header: IdentifierHeaderCell,
    Cell: IdentifierCell,
    width: '200px',
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: StatusCell,
  },
  {
    Header: 'Fulfillments',
    id: 'fulfillments',
    Cell: FulfillmentsCell,
  },
  {
    Header: 'Origin',
    id: 'pickup_address',
    Cell: PickupLocationCell,
  },
  {
    Header: 'Destination',
    id: 'delivery_address',
    Cell: DeliveryLocationCell,
  },
  {
    Header: 'Commodity',
    id: 'commodity',
    Cell: CommodityCell,
  },
  {
    Header: '',
    id: 'row_actions',
    Cell: SupplierActionsCell,
    width: '35px',
  },
]

type OrdersListTableProps = ReturnType<typeof usePaginatedOrders> &
  Readonly<{
    isLoadingSelectedOrdersData?: boolean
    selectedOrderUUIDs: string[]
    selectedOrders: (Order | undefined)[]
    setSelectedOrders: (newSelected: string[]) => void
  }>

function OrdersListTable({
  currentPage,
  data,
  filters,
  isLoading,
  isLoadingSelectedOrdersData,
  onPageChange,
  pageSize,
  resultsCount,
  selectedOrders,
  selectedOrderUUIDs,
  setSelectedOrders,
  clearFilters,
  setFilters,
  onSearchChange,
}: OrdersListTableProps) {
  const goToDetails = useGoToOrderDetails()
  const { openFilters } = useOrdersFiltersContext()

  const onRowClick = useCallback(
    (order: ListOrder) => {
      goToDetails(order.uuid)
    },
    [goToDetails]
  )

  const onSelectionChange = useCallback(
    (selection: TableSelectedRowsIDs) => {
      if (selection !== 'all') {
        setSelectedOrders(selection)
      }
    },
    [setSelectedOrders]
  )

  return (
    <OrdersListProvider>
      <Table<ListOrder>
        tableStyle={{ height: '100%' }}
        appliedFiltersBar={
          <AppliedFiltersBar
            clearFilters={clearFilters}
            filters={filters}
            setFilters={setFilters}
          />
        }
        bulkActions={
          <ListOrdersAsSupplierBulkActions
            isLoading={isLoadingSelectedOrdersData}
            selectedOrders={selectedOrders}
          />
        }
        columns={COLUMNS}
        count={data?.results?.length}
        data={defaultTo(data?.results, [])}
        emptyState={<OrdersTableEmptyState />}
        isLoading={isLoading}
        onPageChange={onPageChange}
        onRowClick={onRowClick}
        onSelectionChange={onSelectionChange}
        onSearchInputChange={onSearchChange}
        onOpenFilters={openFilters}
        page={currentPage}
        pageSize={pageSize}
        searchFilter={defaultTo(filters.search, '')}
        selectable
        selectedRows={selectedOrderUUIDs}
        showResultsCount={false}
        totalCount={resultsCount}
        uniqueIDfield="uuid"
      />
    </OrdersListProvider>
  )
}

export function ListOrdersAsSupplier() {
  const paginatedOrdersProps = usePaginatedOrders('supplier')
  const { selectedOrderUUIDs, setSelectedOrders, selectedOrdersData } =
    useOrderSelection({
      loadDetails: true,
    })

  return (
    <OrdersFiltersProvider
      clearFilters={paginatedOrdersProps.clearFilters}
      filters={paginatedOrdersProps.filters}
      setFilters={paginatedOrdersProps.setFilters}
      view="supplier"
    >
      <OrdersListTable
        {...paginatedOrdersProps}
        isLoadingSelectedOrdersData={selectedOrdersData.isLoading}
        selectedOrders={selectedOrdersData.orders}
        selectedOrderUUIDs={selectedOrderUUIDs}
        setSelectedOrders={setSelectedOrders}
      />
    </OrdersFiltersProvider>
  )
}
