import { Button, Dialog, Icon } from '@loadsmart/miranda-react'

import { useModal } from 'hooks/useModal'

export const HandlingUnitsFormRemoveAll = ({
  stopIndex,
  onRemove,
}: {
  readonly stopIndex: number
  readonly onRemove: (stopIndex: number) => void
}) => {
  const dialog = useModal()

  const handleRemove = () => {
    onRemove(stopIndex)
    dialog.closeModal()
  }

  return (
    <>
      <Button
        variant="tertiary"
        leading={<Icon name="close" size="16" />}
        onClick={dialog.openModal}
      >
        Remove shipping item
      </Button>
      <Dialog
        open={dialog.modalState}
        data-testid={`remove-dialog-${stopIndex}`}
      >
        <Dialog.Header>Remove shipping item</Dialog.Header>
        <Dialog.Body>
          <p>
            Removing the shipping item will also remove the handling units and
            commodities added for this stop.
          </p>
          <p>This action cannot be undone.</p>
        </Dialog.Body>
        <Dialog.Actions>
          <Button variant="tertiary" onClick={dialog.closeModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleRemove}>
            Remove
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  )
}
