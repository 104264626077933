import { useContext } from 'react'

import { parseSettings } from './settings'
import { settingsContext } from './settings.context'

type SettingsReturn = { values: any[]; isLoading: boolean }

export function useSettings(keys: string[]): SettingsReturn {
  const { isLoading, ...settings } = useContext(settingsContext)
  const values = parseSettings(keys, settings)

  return { values, isLoading }
}
