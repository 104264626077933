import { SideNavigation } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { useFeatureFlag } from '_shared_/feature-flag'
import { userUtils } from '_shared_/user'
import { FulfillmentsRoutePaths } from 'fulfillments/FulfillmentsRoutes'
import { appRoutes } from 'router/app-routes'
import { USER_TYPE } from 'utils/constants'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const PlanMenu = () => {
  const history = useHistory()
  const enableFulfillments = useFeatureFlag('ENABLE_FULFILLMENTS')

  if (userUtils.isUserType([USER_TYPE.SG, USER_TYPE.SUPPLIER])) {
    return (
      <SideNavigation.Menu label="Plan">
        <SideNavigation.Menu.Item
          active={isActivePath(appRoutes.orders)}
          label="Orders"
          url={appRoutes.orders}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Orders', appRoutes.orders, history)
          }}
        />
        {enableFulfillments && (
          <SideNavigation.Menu.Item
            active={isActivePath(appRoutes.fulfillments)}
            label="Fulfillments"
            url={FulfillmentsRoutePaths.List}
            onClick={(event) => {
              event.preventDefault()
              navbarLinkClicked(
                'Fulfillments',
                FulfillmentsRoutePaths.List,
                history
              )
            }}
          />
        )}
      </SideNavigation.Menu>
    )
  }

  return null
}
