import { loadScript } from '_shared_/utils/load-script'

const getGlobalVersion = () => {
  return ((globalThis as any).delighted = [])
}

const getDelighted = (cast = false) => {
  const delightedEntry = Object.entries(globalThis).find(
    ([key, _]) => key === 'delighted'
  )
  if (delightedEntry) {
    return delightedEntry[1]
  }
  return cast ? getGlobalVersion() : undefined
}

class DelightedLoader {
  private api: Delighted | undefined
  private loader: Promise<Delighted> | undefined
  private resolve: (api: Delighted) => void
  private reject: (err: Error) => void
  private apiKey: string

  constructor(apiKey = '') {
    if (typeof globalThis.window === 'undefined') {
      throw new Error('Delighted is supported only in browser environment')
    }
    this.apiKey = apiKey
    this.resolve = () => null
    this.reject = () => null
  }

  public load(): Promise<Delighted> {
    if (typeof this.api !== 'undefined') {
      return Promise.resolve(this.api)
    }

    if (typeof this.loader !== 'undefined') {
      return this.loader
    }

    return (this.loader = new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject

      if (getDelighted()) {
        resolve(getDelighted() as unknown as Delighted)
        return
      }

      const functions = [
        'survey',
        'reset',
        'config',
        'init',
        'set',
        'get',
        'event',
        'identify',
        'track',
        'page',
        'screen',
        'group',
        'alias',
      ]

      const delighted = getDelighted(true) as unknown as Delighted
      for (let index = 0; index < functions.length; index++) {
        const func = functions[index]
        delighted[func] =
          delighted[func] ||
          (function (functionName) {
            return function (...args: any[]) {
              const functionImplementation = Array.prototype.slice.call(args)
              delighted.push([functionName, functionImplementation])
            }
          })(func)
      }
      delighted['SNIPPET_VERSION'] = '1.0.1'
      const script = loadScript({
        src: `https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/${this.apiKey}/delighted.js`,
        onLoad: () => {
          resolve(delighted as unknown as Delighted)
        },
      })
      /* istanbul ignore next -- ignoring for coverage as we currently don't have a fake server for mocking this call  */
      script.onerror = reject
    }))
  }
}

export default DelightedLoader
