import { Card, LabeledValue, Layout } from '@loadsmart/miranda-react'

import { getEquipmentLabelByAlias } from 'utils/equipmentType'
import { defaultEmptyString } from 'utils/strings'

import { useViewFulfillmentContext } from '../ViewFulfillmentContext'

export function FulfillmentOverviewCard() {
  const { fulfillment } = useViewFulfillmentContext()

  if (!fulfillment) {
    return null
  }

  return (
    <Card data-testid="fulfillment-overview-card">
      <Card.Title>Overview</Card.Title>
      <Card.Divider />
      <Card.Body>
        <Layout.Grid>
          <LabeledValue
            data-testid="fulfillment-owner"
            label="Owner"
            value={defaultEmptyString(fulfillment.customer?.name)}
          />
          <LabeledValue
            data-testid="fulfillment-supplier"
            label="Supplier"
            value={defaultEmptyString(fulfillment.supplier?.name)}
          />
          <LabeledValue
            data-testid="fulfillment-equipment-type"
            label="Equipment"
            value={defaultEmptyString(
              fulfillment.equipment_type
                ? getEquipmentLabelByAlias(fulfillment.equipment_type)
                : ''
            )}
          />
          <LabeledValue
            data-testid="fulfillment-pickup-number"
            label="Pickup number"
            value={defaultEmptyString(fulfillment.pickup_number)}
          />
        </Layout.Grid>
      </Card.Body>
    </Card>
  )
}
