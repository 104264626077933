import { Tabs } from '@loadsmart/loadsmart-ui'
import { useState } from 'react'
import { toast } from 'react-toastify'

import { useCurrentUser } from '_shared_/user/useCurrentUser'
import { useFilteredRankedLanes } from 'hooks/useQuery'
import RFPLimitBanner from 'rfp/components/rfp-limit-banner'
import type { RequestForProposal } from 'rfp/rfp.types'
import { USER_CATEGORIES } from 'utils/constants'

import InvitedCarriers from '../tabs/InvitedCarriers'
import Lanes from '../tabs/Lanes'

const TABS = {
  LANES: 'lanes',
  INVITED_CARRIERS: 'invited-carriers',
}

interface QueryParams {
  page: number
  offset: number
  limit: number
  sort: {
    column: string
    direction: string
  }
  filterByRankedLanes: boolean | undefined
}

interface RankFinalizedProps {
  readonly rfp: RequestForProposal
  readonly invites: NewCarrierInvite[]
  readonly carrierInviteStatus: string
  readonly refetchCarrierInvites: () => Promise<unknown>
  readonly isLoadingCarrierInvites: boolean
  readonly showLaneDetailsOrRoutingGuide: (lane: Lane) => void
  readonly showRecommendedCarriersSidebar: (lane: Lane) => void
  readonly remainingRfpQuota: number
  readonly carrierInvitesMaxQuota: number
}

const LIMIT = 50

const defaultParams: QueryParams = {
  page: 0,
  offset: 0,
  limit: LIMIT,
  sort: {
    column: 'lane_id',
    direction: 'asc',
  },
  filterByRankedLanes: undefined,
}

const RankFinalized = ({
  rfp,
  invites,
  carrierInviteStatus,
  refetchCarrierInvites,
  isLoadingCarrierInvites,
  showLaneDetailsOrRoutingGuide,
  showRecommendedCarriersSidebar,
  remainingRfpQuota,
  carrierInvitesMaxQuota,
}: RankFinalizedProps) => {
  const [params, setParams] = useState(defaultParams)

  const { user } = useCurrentUser()
  const isPaidUser = user?.user_category === USER_CATEGORIES.PAID_SHIPPER

  const [tab, setTab] = useState(TABS.LANES)

  const filteredLanesQuery = useFilteredRankedLanes(
    rfp.id,
    params.limit,
    params.offset,
    params.sort,
    params.filterByRankedLanes,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onError() {
        toast.error('Could not retrieve the lanes, please contact an admin')
      },
    }
  )

  return (
    <Tabs
      className="miranda-tabs"
      activeTab={tab}
      onTabChange={(newTab: string) => setTab(newTab)}
    >
      <Tabs.Items>
        <Tabs.Item name={TABS.LANES}>Lanes</Tabs.Item>
        <Tabs.Item name={TABS.INVITED_CARRIERS}>Invited Carriers</Tabs.Item>
      </Tabs.Items>
      {!isPaidUser && (
        <RFPLimitBanner
          remainingRfpQuota={remainingRfpQuota}
          carrierInvitesMaxQuota={carrierInvitesMaxQuota}
        />
      )}
      <Tabs.Panels>
        <Tabs.Panel name={TABS.LANES}>
          <Lanes
            params={params}
            query={filteredLanesQuery}
            rfp={rfp}
            setParams={setParams}
            showLaneDetailsOrRoutingGuide={showLaneDetailsOrRoutingGuide}
            showRecommendedCarriersSidebar={showRecommendedCarriersSidebar}
          />
        </Tabs.Panel>
        <Tabs.Panel name={TABS.INVITED_CARRIERS}>
          <InvitedCarriers
            carrierInviteStatus={carrierInviteStatus}
            invites={invites}
            isLoadingCarrierInvites={isLoadingCarrierInvites}
            refetchCarrierInvites={refetchCarrierInvites}
            rfp={rfp}
          />
        </Tabs.Panel>
      </Tabs.Panels>
    </Tabs>
  )
}

export default RankFinalized
