import type { SettingsProviderValue } from './settings.context'

export function parseSettings(
  keys: string[],
  settings: Pick<SettingsProviderValue, 'settings' | 'flags'>
) {
  return keys.map((key) =>
    key.split('.').reduce((acc, curr) => {
      const obj = acc === null ? settings : (acc as any)

      if (!obj) {
        return acc
      }

      return curr in obj ? obj[curr] : null
    }, null)
  )
}
