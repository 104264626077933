import { Select } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import {
  fromModeToSelectOption,
  getTransportationMode,
} from 'utils/transportationMode'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationSummaryContext } from './FreightInformationSummary.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const MODE_NAME = 'mode'
const MODE_LABEL = 'Mode'

type FreightInformationModeSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name' | 'value'
>

export function FreightInformationModeSummaryField(
  props: FreightInformationModeSummaryFieldProps
) {
  const { shipment } = useFreightInformationSummaryContext()

  return (
    <FreightInformationSummaryField
      label={MODE_LABEL}
      name={MODE_NAME}
      value={getTransportationMode(shipment[MODE_NAME] ?? '')?.abbr}
      {...props}
    />
  )
}

type FreightInformationModeProps = {
  readonly disabled?: boolean
  readonly modes: SelectOption[]
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
}

export function FreightInformationModeFormField({
  disabled = false,
  modes,
  required,
  hint,
  error,
  style,
}: FreightInformationModeProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: MODE_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${MODE_NAME}-field`}
    >
      <Field.Label>{MODE_LABEL}</Field.Label>
      <Select
        disabled={disabled}
        name={MODE_NAME}
        placeholder="Mode"
        options={modes}
        value={
          shipment[MODE_NAME]
            ? fromModeToSelectOption(shipment[MODE_NAME])
            : null
        }
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: { [MODE_NAME]: get(event, 'target.value.value') },
          })
        }}
        hideClear={required}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
