import { isEmpty, isNull, map, uniq, uniqBy } from 'lodash-es'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useFeatureFlag } from '_shared_/feature-flag'
import type { FreightInformation } from 'components/FreightInformation'
import { EQUIPMENT_REQUIREMENTS_NAME } from 'components/FreightInformation/FreightInformationTemperature'
import type {
  Commodity,
  HandlingUnit,
} from 'components/HandlingUnitsManager/HandlingUnits.types'
import {
  createCommodity,
  createHandlingUnit,
  createTransientCommodity,
  createTransientHandlingUnit,
} from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import type { CommodityPackageType } from 'components/ShippingItemsManager'
import { COMMODITY_PACKAGE_TYPES_MAP } from 'components/ShippingItemsManager'
import type { Stop } from 'components/StopsManager'
import { endOfDay } from 'components/StopsManager/StopDate'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { getFulfillmentsEquipmentType } from 'fulfillments/fulfillment-utils'
import { useSelectedFulfillments } from 'fulfillments/hooks/useSelectedFulfillments'
import { getOrderItemsLowestTemperature } from 'screens/Orders/common/utils'
import type { TransientShipment } from 'screens/Shipper/Shipments/components/Shipment'
import type { FacilityDetailsV2 } from 'services/facilities'
import { EQUIPMENT_TYPES } from 'utils/equipmentTypeV2'
import { joinValidStrings } from 'utils/strings'
import { resolveTransportationMode } from 'utils/transportationMode'

import { presetStopToFormTransientStop, usePreSetStops } from './usePreSetStops'

function mapFulfillmentsToHandlingUnits(
  fulfillments: ListFulfillment[],
  stops: Stop[] | null
): HandlingUnit[] {
  return fulfillments.flatMap((fulfillment): HandlingUnit[] => {
    return fulfillment.handling_units.map((handling_unit): HandlingUnit => {
      const pickup = stops?.find(
        (stop) => stop.facility?.uuid == fulfillment.pickup_facility.uuid
      )?.stop_index
      const delivery = stops?.find(
        (stop) => stop.facility?.uuid == fulfillment.delivery_facility.uuid
      )?.stop_index

      return createHandlingUnit({
        pickup_stop_index: pickup || 0,
        delivery_stop_index: delivery || null,
        package_count: handling_unit.package_count,
        package_type: handling_unit.package_type,
        length: handling_unit.package_length || '',
        width: handling_unit.package_width || '',
        height: handling_unit.package_height || '',
        stackable: handling_unit.stackable,
        turnable: handling_unit.turnable,
        fulfillment: {
          uuid: fulfillment.uuid,
          ref_number: fulfillment.ref_number,
          pickup_number: fulfillment.pickup_number,
        },
        fulfillment_handling_unit: {
          uuid: handling_unit.uuid,
        },
        commodities: handling_unit.order_items.map((item): Commodity => {
          return createCommodity({
            package_count: item.shipped_package_count,
            package_type: COMMODITY_PACKAGE_TYPES_MAP.has(
              item.package_type as CommodityPackageType
            )
              ? (item.package_type as CommodityPackageType)
              : null,
            weight: item.shipped_package_weight ?? '',
            description: item.commodity,
            nmfc_code: item.shipped_nmfc_code ?? undefined,
            freight_class: item.shipped_freight_class ?? null,
          })
        }),
      })
    })
  })
}

function getTemperatureRequirements(
  lowestTemperature: string | undefined,
  previousShipment: TransientShipment
) {
  return isEmpty(lowestTemperature)
    ? {}
    : {
        equipment_type: EQUIPMENT_TYPES.rfr.abbr,
        [EQUIPMENT_REQUIREMENTS_NAME]: {
          ...previousShipment[EQUIPMENT_REQUIREMENTS_NAME],
          temperature: Number(lowestTemperature),
        },
      }
}

function getStopNotesFromFulfillments(
  stop: Stop,
  fulfillments: ListFulfillment[]
) {
  const stop_type = stop.stop_type!
  const facilityNote = stop.facility?.notes[0]?.note

  const fulfillmentInStop = fulfillments.filter(
    (fulfillment) =>
      fulfillment[`${stop_type}_facility`].uuid === stop.facility?.uuid
  )

  const instructionsAggregate = fulfillmentInStop.reduce(
    (acc, fulfillment) => {
      const instructions =
        stop_type == 'pickup'
          ? fulfillment.pickup_instructions
          : fulfillment.delivery_instructions

      if (isEmpty(instructions)) {
        return acc
      }
      return `${acc}Fulfillment ${fulfillment.ref_number} stop instructions:\n${instructions}\n`
    },
    facilityNote ? `${facilityNote}\n` : ''
  )

  if (!isEmpty(instructionsAggregate)) {
    return instructionsAggregate
  }

  return stop.notes
}

function getStopDateFromFulfillments(
  stop: Stop,
  fulfillments: ListFulfillment[]
) {
  const stop_type = stop.stop_type!

  const fulfillmentInStop = fulfillments.filter(
    (fulfillment) =>
      fulfillment[`${stop_type}_facility`].uuid === stop.facility?.uuid
  )

  const dates = uniq(
    stop_type == 'pickup'
      ? fulfillmentInStop
          .map((fulfillment) => fulfillment.pickup_ready_date)
          .filter((date) => !isNull(date))
      : fulfillmentInStop
          .map((fulfillment) => fulfillment.delivery_date)
          .filter((date) => !isNull(date))
  )

  if (dates.length == 1) {
    return endOfDay(dates[0])
  }

  return stop.date
}

function getPreSelectedContact(
  facility: FacilityDetailsV2,
  selectedContact: ListFulfillment['pickup_contact']
) {
  return (
    facility.contacts.find(
      (contact) => contact.uuid === selectedContact?.uuid
    ) ?? null
  )
}

function getStopContactFromFulfillments(
  stop: Stop,
  fulfillments: ListFulfillment[]
) {
  const stop_type = stop.stop_type!

  const fulfillmentInStop = fulfillments.filter(
    (fulfillment) =>
      fulfillment[`${stop_type}_facility`].uuid === stop.facility?.uuid
  )

  const contacts = uniqBy(
    fulfillmentInStop
      .map((fulfillment) =>
        getPreSelectedContact(
          stop.facility!,
          fulfillment[`${stop_type}_contact`]
        )
      )
      .filter((contact) => !isNull(contact)),
    'uuid'
  )

  if (contacts.length == 1) {
    return { ...contacts[0], _type: 'contact' }
  }

  return stop.contact
}

export function useFulfillmentConsolidation({
  setShipment,
}: {
  setShipment: Dispatch<SetStateAction<TransientShipment>>
}) {
  const enableFulfillments = useFeatureFlag('ENABLE_FULFILLMENTS')

  const { selectedFulfillmentUUIDs, selectedFulfillments } =
    useSelectedFulfillments()
  const { stops, loadingFacilities } = usePreSetStops({
    enabled: Boolean(enableFulfillments) && selectedFulfillmentUUIDs.length > 0,
  })

  const [stopsLoaded, setStopsLoaded] = useState(false)
  const [itemsLoaded, setItemsLoaded] = useState(false)
  const [freightInfoLoaded, setFreightInfoLoaded] = useState(false)
  const location = useLocation()

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const mode = useMemo(() => {
    return resolveTransportationMode(searchParams.get('mode') || '')
  }, [searchParams])

  const orderItems = useMemo(() => {
    const allHandlingUnits = selectedFulfillments.flatMap(
      (fulfillment) => fulfillment.handling_units
    )

    const allOrderItems = allHandlingUnits.flatMap(
      (handlingUnit) => handlingUnit.order_items
    )

    return uniqBy(allOrderItems, 'uuid')
  }, [selectedFulfillments])

  const orders = useMemo(() => {
    const allOrders = orderItems.map((item) => item.order)

    return uniqBy(allOrders, 'uuid')
  }, [orderItems])

  const handlingUnits = useMemo(() => {
    if (selectedFulfillments.length > 0 && !loadingFacilities) {
      return mapFulfillmentsToHandlingUnits(selectedFulfillments, stops)
    }
    return []
  }, [selectedFulfillments, stops, loadingFacilities])

  const lowestTemperature = useMemo(() => {
    if (orderItems.length > 0) {
      const { max_temperature } = getOrderItemsLowestTemperature(orderItems)

      return max_temperature
    }

    return undefined
  }, [orderItems])

  const freightInformation: Partial<FreightInformation> = useMemo(() => {
    if (!isEmpty(orders)) {
      return {
        po_number: joinValidStrings(map(orders, 'po_number'), ', '),
        so_number: joinValidStrings(map(orders, 'so_number'), ', '),
        shipper_ref_number: joinValidStrings(map(orders, 'primary_ref'), ', '),
        equipment_type: getFulfillmentsEquipmentType(selectedFulfillments),
        ...(mode ? { mode } : {}),
      }
    }
    return {}
  }, [orders, mode, selectedFulfillments])

  //set pre selected stops
  useEffect(() => {
    if (
      !stopsLoaded &&
      !isEmpty(stops) &&
      !loadingFacilities &&
      !isEmpty(selectedFulfillments)
    ) {
      setShipment((previous) => {
        return {
          ...previous,
          stops: stops!.map((stop) => {
            const presetStop = presetStopToFormTransientStop(stop)
            return {
              ...presetStop,
              notes: getStopNotesFromFulfillments(
                presetStop,
                selectedFulfillments
              ),
              date: getStopDateFromFulfillments(
                presetStop,
                selectedFulfillments
              ),
              contact: getStopContactFromFulfillments(
                presetStop,
                selectedFulfillments
              ),
            }
          }),
        }
      })
      setStopsLoaded(true)
    }
  }, [loadingFacilities, stopsLoaded, selectedFulfillments, stops, setShipment])

  // Set freight information
  useEffect(() => {
    if (!freightInfoLoaded && !isEmpty(freightInformation)) {
      setShipment((previous) => ({
        ...previous,
        ...freightInformation,
      }))
      setFreightInfoLoaded(true)
    }
  }, [freightInfoLoaded, freightInformation, setShipment, setFreightInfoLoaded])

  //Set handling units from selected Orders' items
  useEffect(() => {
    if (!itemsLoaded && !isEmpty(handlingUnits)) {
      const items = handlingUnits.map((handlingUnit) => ({
        ...createTransientHandlingUnit(handlingUnit),
        commodities: handlingUnit.commodities.map((commodity) =>
          createTransientCommodity(commodity)
        ),
      }))
      setShipment((previousShipment) => ({
        ...previousShipment,
        ...getTemperatureRequirements(lowestTemperature, previousShipment),
        items,
      }))
      setItemsLoaded(true)
    }
  }, [itemsLoaded, lowestTemperature, setShipment, handlingUnits])

  return {
    fulfillmentsInConsolidation: selectedFulfillments,
    inConsolidation: selectedFulfillmentUUIDs.length > 0,
  }
}
