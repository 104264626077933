import { useFlag } from '@unleash/proxy-client-react'
import type { ReactNode } from 'react'
import { useCallback, useMemo, useState } from 'react'

import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'

import { OneClickPlanFulfillmentContext } from './OneClickPlanFulfillment.context'
import OneClickPlanFulfillmentDialog from './OneClickPlanFulfillment.dialog'

export default function OneClickPlanFulfillmentProvider({
  children,
}: {
  readonly children: ReactNode
}) {
  const enabled = useFlag('ENABLE_FULFILLMENT_1_CLICK')
  const [fulfillments, setFulfillments] = useState<ListFulfillment[]>([])
  const [isOpen, setIsOpen] = useState(false)

  const open = useCallback((selectedFulfillments: ListFulfillment[]) => {
    setIsOpen(true)
    setFulfillments(selectedFulfillments)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const value = useMemo(() => ({ enabled, open }), [enabled, open])

  return (
    <OneClickPlanFulfillmentContext.Provider value={value}>
      {children}
      {enabled && isOpen ? (
        <OneClickPlanFulfillmentDialog
          fulfillments={fulfillments}
          isOpen={isOpen}
          onClose={onClose}
        />
      ) : null}
    </OneClickPlanFulfillmentContext.Provider>
  )
}
