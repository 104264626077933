import { Field, ToggleGroup } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const DIRECTION_NAME = 'direction'
const DIRECTION_LABEL = 'Direction'

type FreightInformationDirectionFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
  readonly disabled?: boolean
}

export function FreightInformationDirection({
  required,
  hint,
  error,
  style,
}: FreightInformationDirectionFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: DIRECTION_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${DIRECTION_NAME}-field`}
    >
      <Field.Label id={`label-${DIRECTION_NAME}`}>
        {DIRECTION_LABEL}
      </Field.Label>
      <ToggleGroup
        type="single-strict"
        options={[
          { label: 'Import', value: 'load_import' },
          { label: 'Export', value: 'load_export' },
        ]}
        value={get(shipment, DIRECTION_NAME)}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: {
              [DIRECTION_NAME]: get(event, 'target.value'),
            },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
