import type { AxiosError } from 'axios'
import { useEffect } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

import { useMultipleFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { useFulfillmentsDetails } from 'fulfillments/hooks/useFulfillmentsDetails'
import { useLoadOrderData } from 'screens/Orders/common/useLoadOrderData'
import type { NewShipmentPayload } from 'shipments/shipments.services'
import { createNewShipment } from 'shipments/shipments.services'

export function useAutoPlanFulfillment() {
  return useMutation<
    { uuid: string },
    AxiosError<Record<string, string>>,
    NewShipmentPayload & { fulfillment_uuid: string }
  >({
    mutationFn: createNewShipment,
  })
}

export function useAutoPlanFulfillmentAuxiliaryData(
  selectedFulfillments: ListFulfillment[]
) {
  const fulfillmentsUUIDs = selectedFulfillments.map(
    (fulfillment) => fulfillment.uuid
  )
  const {
    isLoading: isLoadingFulfillments,
    isError: isErrorLoadingFulfillments,
    fulfillments,
  } = useFulfillmentsDetails(fulfillmentsUUIDs)

  const ordersUUIDs = selectedFulfillments
    .map((fulfillment) => fulfillment.order_uuids)
    .flat()
  const {
    isError: isErrorLoadingOrders,
    isLoading: isLoadingOrders,
    orders,
  } = useLoadOrderData(ordersUUIDs)

  const facilitiesUUIDs = selectedFulfillments
    .map((fulfillment) => [
      fulfillment.pickup_facility.uuid,
      fulfillment.delivery_facility.uuid,
    ])
    .flat()

  const {
    isError: isErrorLoadingFacilities,
    isLoading: isLoadingFacilities,
    facilities,
  } = useMultipleFacilityDetailsV2(facilitiesUUIDs)

  useEffect(() => {
    if (
      isErrorLoadingFulfillments ||
      isErrorLoadingFacilities ||
      isErrorLoadingOrders
    ) {
      toast.error('Failed to load related data')
    }
  }, [
    isErrorLoadingFacilities,
    isErrorLoadingFulfillments,
    isErrorLoadingOrders,
  ])

  return {
    facilities,
    fulfillments,
    isError:
      isErrorLoadingFulfillments ||
      isErrorLoadingOrders ||
      isErrorLoadingFacilities,
    isLoading: isLoadingFulfillments || isLoadingOrders || isLoadingFacilities,
    orders,
  }
}
