import { get, set } from 'lodash-es'
import { createContext, useRef, useCallback, useContext, useMemo } from 'react'
import type { Dispatch, PropsWithChildren } from 'react'

import type { Transient } from 'utils/transient'

export type Action<T> =
  | {
      type: 'ADD_ITEM'
      payload?: { addAt: number }
    }
  | {
      type: 'REMOVE_ITEM'
      payload: { index: number }
    }
  | {
      type: 'MOVE_ITEM'
      payload: { index: number; direction: 'up' | 'down' }
    }
  | {
      type: 'SET_ITEM'
      payload: {
        index: number
        changes: Partial<Transient<T>>
      }
    }

type TransientCollectionFormContextValue<T> = [
  Transient<T>[],
  Dispatch<Action<T>>,
]

export type TransientCollectionFormProps<T> = PropsWithChildren<{
  readonly items?: Transient<T>[]
  readonly createItem: () => Transient<T>
  readonly onChange: (items: Transient<T>[]) => void
  readonly onAddItem?: (addedAt: number) => void
  readonly onDeleteItem?: (stopIndex: number) => void
}>

export function createReducer<T>({
  createItem,
  onAddItem,
  onDeleteItem,
}: {
  createItem: () => Transient<T>
  onAddItem?: (addedAt: number) => void
  onDeleteItem?: (index: number) => void
}) {
  return function reducer(state: Transient<T>[], action: Action<T>) {
    switch (action.type) {
      case 'ADD_ITEM': {
        const addAt = get(action, 'payload.addAt') ?? state.length

        const newItems = [...state]
        newItems.splice(addAt, 0, createItem())

        onAddItem?.(addAt)

        return newItems
      }
      case 'REMOVE_ITEM': {
        const { index } = action.payload
        onDeleteItem?.(index)

        return state.filter((_, i) => i !== index)
      }
      case 'MOVE_ITEM': {
        const { index, direction } = action.payload

        const increment = direction === 'up' ? -1 : 1
        const newState: typeof state = [...state]

        ;[newState[index], newState[index + increment]] = [
          newState[index + increment],
          newState[index],
        ]

        return newState
      }
      case 'SET_ITEM': {
        const { index, changes } = action.payload

        const changedItem = Object.keys(changes).reduce(
          (item, name) => {
            return set(item, name, get(changes, name))
          },
          { ...state[index] }
        )

        const changedItems = [
          ...state.slice(0, index),
          changedItem,
          ...state.slice(index + 1),
        ]

        return changedItems
      }
      default:
        return state
    }
  }
}

export function createTransientCollectionSetup<T>() {
  // we do this so we can have a dynamically typed context
  const TransientCollectionFormContext = createContext<
    TransientCollectionFormContextValue<T>
  >([[], () => ({}) as Transient<T>])

  function TransientCollectionFormInnerContent({
    items,
    createItem,
    onChange,
    onAddItem,
    onDeleteItem,
    children,
  }: TransientCollectionFormProps<T> & { readonly items: Transient<T>[] }) {
    const reducerRef = useRef(
      createReducer({ createItem, onAddItem, onDeleteItem })
    )

    const dispatch = useCallback(
      (action: Action<T>) => {
        const newItems = reducerRef.current(items, action)
        onChange?.(newItems)
      },
      [items, onChange]
    )

    const contextValue: TransientCollectionFormContextValue<T> = useMemo(() => {
      return [items, dispatch]
    }, [dispatch, items])

    return (
      <TransientCollectionFormContext.Provider value={contextValue}>
        {children}
      </TransientCollectionFormContext.Provider>
    )
  }

  function TransientCollectionForm({
    items,
    ...otherProps
  }: TransientCollectionFormProps<T>) {
    if (!items) {
      return null
    }

    return <TransientCollectionFormInnerContent items={items} {...otherProps} />
  }

  function useTransientCollectionFormContext() {
    const context = useContext(TransientCollectionFormContext)

    return context
  }

  return {
    TransientCollectionForm,
    TransientCollectionFormContext,
    useTransientCollectionFormContext,
  }
}
