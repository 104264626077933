import { Banner, Button, Layout } from '@loadsmart/miranda-react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import type { UserData } from '_shared_/user'
import { useCurrentUser } from '_shared_/user/useCurrentUser'
import type { PageIndices } from 'components/PageIndex'
import PageIndex from 'components/PageIndex'
import { StickyFormFooter } from 'components/StickyFormFooter'
import { FulfillmentLane } from 'fulfillments/components/FulfillmentLane'
import { FulfillmentNotes } from 'fulfillments/components/FulfillmentNotes'
import { FulfillmentOrdersReviewCard } from 'fulfillments/components/FulfillmentOrdersReviewCard'
import { getFulfillmentFormIndex } from 'fulfillments/getFulfillmentFormIndex'
import { useGoToFulfillmentDetails } from 'fulfillments/hooks/navigation'

import { FulfillmentFreightInfo } from '../components/FulfillmentFreightInfo'
import { useCreateFulfillmentForm } from './hooks/useCreateFulfillmentForm'

export const getUserType = (shipper?: UserData['shipper']) => {
  if (shipper?.is_supplier) {
    return 'supplier'
  }
  return 'shipper'
}

export function CreateFulfillmentForm({
  selectedOrdersUUIDs = [],
}: Readonly<{
  selectedOrdersUUIDs?: string[]
}>) {
  const history = useHistory()
  const { user } = useCurrentUser()
  const goToFulfillmentDetails = useGoToFulfillmentDetails()
  const {
    uuid,
    fulfillment,
    status,
    hasErrors,
    selectedOrders,
    submitForm,
    setPartialFulfillment,
  } = useCreateFulfillmentForm()

  const userType = getUserType(user?.shipper)
  const pageIndex: PageIndices = getFulfillmentFormIndex(
    fulfillment,
    Boolean(selectedOrders?.length)
  )

  // setting uuids on transient fulfillment
  useEffect(() => {
    if (selectedOrdersUUIDs.length) {
      setPartialFulfillment({ order_uuids: selectedOrdersUUIDs })
    }
  }, [selectedOrdersUUIDs, setPartialFulfillment])

  useEffect(() => {
    if (status === 'success' && uuid) {
      goToFulfillmentDetails(uuid)
    }
  }, [goToFulfillmentDetails, status, uuid])

  return (
    <form>
      <Layout.Container>
        <Layout.Row columnGap="none">
          <Layout.Column offsetLg="3" sm="12" md="8" lg="6">
            <Layout.Stack gap="spacing-6" paddingB="spacing-8">
              {hasErrors && (
                <Banner variant="danger">
                  <Banner.Title>
                    Please fix the errors below to proceed
                  </Banner.Title>
                </Banner>
              )}
              <FulfillmentFreightInfo />
              <FulfillmentOrdersReviewCard />
              <FulfillmentLane userType={userType} />
              <FulfillmentNotes />
            </Layout.Stack>
          </Layout.Column>
          <Layout.Column sm="12" md="4" lg="3">
            <Layout.Box>
              <PageIndex values={pageIndex} />
            </Layout.Box>
          </Layout.Column>
        </Layout.Row>
      </Layout.Container>
      <StickyFormFooter>
        <Layout.Container>
          <Layout.Row columnGap="none">
            <Layout.Column offsetLg="3" sm="12" md="8" lg="6">
              <Layout.Group justify="flex-end" paddingR="none">
                <Layout.Group justify="space-between">
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      history.goBack()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="button" variant="primary" onClick={submitForm}>
                    Create fulfillment
                  </Button>
                </Layout.Group>
              </Layout.Group>
            </Layout.Column>
          </Layout.Row>
        </Layout.Container>
      </StickyFormFooter>
    </form>
  )
}
