import { useUnleashContext } from '@unleash/proxy-client-react'
import type { ReactNode } from 'react'
import { useState, useEffect, useMemo } from 'react'

import { GlobalSpinner } from '_shared_/components/GlobalSpinner'
import { getFeatureFlagContext } from '_shared_/feature-flag'
import { userUtils, TOKEN_KEY, USER_KEY } from '_shared_/user'
import { useUserData } from 'hooks/useQuery'
import {
  LOCATION_MANAGEMENT_STORAGE_EVENT,
  LOCATION_MANAGEMENT_STORAGE_KEY,
} from 'utils/constants'
import { EVENT as STORAGE_EVENT } from 'utils/localStorage'
import { redirectOrReload } from 'utils/shipperLocationManagement'

import { userContext } from './useCurrentUser'

export function UserProvider({ children }: { readonly children?: ReactNode }) {
  const updateContext = useUnleashContext()
  const context = getFeatureFlagContext()
  const [user, setUser] = useState(userUtils.user)
  const [locationUUID, setLocationUUID] = useState(userUtils.locationUUID)

  const { refetch, isLoading } = useUserData({
    enabled: userUtils.isLoggedIn,
  })

  useEffect(() => {
    updateContext(context)
  }, [context, updateContext])

  useEffect(() => {
    /**
     * We are using two separate events to listen to storage changes
     * because the default storage event cannot be listened from the
     * page that emitted it
     * (https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event),
     * so instead we listen to a custom event
     * for changes from this tab, and use the default event to handle
     * changes from other tabs, this way we can keep user data synchronized.
     */

    function onCustomStorage(event: CustomEvent) {
      if (event.detail?.key === USER_KEY && event.detail?.value !== user) {
        setUser(event.detail?.value)
      }

      if (event.detail?.key === TOKEN_KEY && event.detail?.value != null) {
        /**
         * We need to refetch the user data when the token is set
         * to make sure we have the latest user data.
         *
         * We should not refetch the user data when the token is removed.
         */
        refetch()
      }
    }

    function onStorage(event: StorageEvent) {
      if (event.storageArea === localStorage) {
        const newUser = userUtils.user

        if (event.key === USER_KEY && newUser !== user) {
          setUser(newUser)
        }

        if (event.key === LOCATION_MANAGEMENT_STORAGE_KEY) {
          onLocationChange()
        }
      }
    }

    function onLocationChange() {
      setLocationUUID(userUtils.locationUUID)
      redirectOrReload(globalThis.location.pathname)
    }

    globalThis.addEventListener(STORAGE_EVENT as any, onCustomStorage)
    globalThis.addEventListener('storage', onStorage)
    globalThis.addEventListener(
      LOCATION_MANAGEMENT_STORAGE_EVENT,
      onLocationChange
    )

    return () => {
      globalThis.removeEventListener(STORAGE_EVENT as any, onCustomStorage)
      globalThis.removeEventListener('storage', onStorage)
      globalThis.removeEventListener(
        LOCATION_MANAGEMENT_STORAGE_EVENT,
        onLocationChange
      )
    }
  }, [user, refetch])

  const contextValue = useMemo(
    () => ({
      locationUUID,
      user,
    }),
    [user, locationUUID]
  )

  if (isLoading) {
    return <GlobalSpinner />
  }

  const canRender = !userUtils.isLoggedIn || Boolean(user)

  return (
    <userContext.Provider value={contextValue}>
      {canRender && children}
    </userContext.Provider>
  )
}
