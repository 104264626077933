import {
  Card,
  Divider,
  Icon,
  Layout,
  Tag,
  Text,
  Tooltip,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-tokens'
import { get, isEmpty } from 'lodash-es'
import { useCallback, useEffect } from 'react'

import { FacilitySummary } from 'components/FacilityDetails'
import type { TransientFreightInformation } from 'components/FreightInformation'
import { FreightInformationForm } from 'components/FreightInformation'
import { GridV2 } from 'components/Grid'
import { HandlingUnitsDimensionsSummary } from 'components/HandlingUnitsManager/ContextBasedHandlingUnitDimensionsSummary'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { HandlingUnitsForm } from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsForm'
import { HandlingUnitsFormAddHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsFormAddHandlingUnit'
import { createTransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import { StopHandlingUnitsSummary } from 'components/HandlingUnitsManager/StopHandlingUnitsSummary'
import type { TransientHazmatContact } from 'components/HazmatInformation'
import { HazmatContactForm } from 'components/HazmatInformation/HazmatContactForm'
import { HazmatContactName } from 'components/HazmatInformation/HazmatContactName'
import { HazmatContactPhone } from 'components/HazmatInformation/HazmatContactPhone'
import { hasHazmat } from 'components/ShippingItemsManager'
import type { TransientStop } from 'components/StopsManager'
import {
  createTransientStop,
  StopForm,
  StopsForm,
} from 'components/StopsManager'
import { StopFacilitySummary } from 'components/StopsManager/StopFacilitySummary'
import { REQUIRED_FIELD_MESSAGE } from 'constants/errors'
import { FormLayout } from 'screens/Shipper/Shipments/components/FormLayout'
import { StopSection } from 'screens/Shipper/Shipments/components/StopSection/StopSection'
import { ConsolidationReviewCard } from 'screens/Shipper/Shipments/create/consolidation/components/ConsolidationReviewCard'
import { useConsolidationContext } from 'screens/Shipper/Shipments/create/consolidation/consolidation.context'
import { useShipmentFormContext } from 'screens/Shipper/Shipments/create/create.context'
import {
  FORM_MAX_WIDTH,
  FormWrapper,
} from 'screens/Shipper/Shipments/create/create.styles'
import { getShipmentFormIndexes } from 'screens/Shipper/Shipments/create/create.utils'
import { useEquipmentTypes } from 'shipments/hooks/useEquipmentTypes'
import { useTransportationModes } from 'shipments/hooks/useTransportationModes'
import { EQUIPMENT_TYPES, isEquipmentTypeOneOf } from 'utils/equipmentTypeV2'
import { getTransientError } from 'utils/transient'

import { LTLFormHandlingUnitV2 } from './LTLFormHandlingUnitV2'

export function LessThanTruckloadFormV2() {
  const [shipment, setPartialShipment] = useShipmentFormContext()
  const { consolidationType, modeLocked } = useConsolidationContext()

  const { modes } = useTransportationModes()
  const { equipments } = useEquipmentTypes({ mode: shipment.mode })

  useEffect(() => {
    const equipmentOptions = equipments.map(
      (equipmentOption) => equipmentOption.value as string
    )
    if (
      shipment.equipment_type &&
      !isEquipmentTypeOneOf(shipment.equipment_type, equipmentOptions)
    ) {
      setPartialShipment({ equipment_type: EQUIPMENT_TYPES.drv.value })
    }
  }, [shipment.equipment_type, setPartialShipment, equipments])

  const handleFreightInformationChange = useCallback(
    (newFreightInformation: TransientFreightInformation) => {
      setPartialShipment(newFreightInformation)
    },
    [setPartialShipment]
  )

  const handleStopsChange = useCallback(
    (newStops: TransientStop[]) => {
      setPartialShipment({ stops: newStops })
    },
    [setPartialShipment]
  )

  const handleHandlingUnitChange = (
    newHandlingUnits: TransientHandlingUnit[]
  ) => {
    setPartialShipment({
      items: newHandlingUnits,
    })
  }

  const [pickup, delivery] = get(shipment, 'stops', [])
  const pickupIndex = 0
  const deliveryIndex = shipment.stops.length - 1

  const oneItemRequiredError =
    getTransientError(shipment, 'items') == REQUIRED_FIELD_MESSAGE

  const handleHazmatContactChange = useCallback(
    (newHazmatContact: TransientHazmatContact) => {
      setPartialShipment({ ...newHazmatContact })
    },
    [setPartialShipment]
  )

  const shipmentHasHazmatCommodity = hasHazmat(
    shipment.items.map(({ commodities }) => commodities).flat()
  )
  const isReefer = shipment.equipment_type === EQUIPMENT_TYPES.rfr.abbr

  const handleCreateItem = () => {
    return createTransientHandlingUnit({
      order_items: [],
      pickup_stop_index: 0,
      delivery_stop_index: shipment.stops.at(-1)?.stop_index,
    })
  }

  return (
    <FormLayout
      indexValues={getShipmentFormIndexes({
        transientShipment: shipment,
        consolidation: consolidationType,
        withIntermediaryStops: false,
        withShippingItems: true,
      })}
    >
      <FormWrapper
        data-testid="less-than-truckload-form"
        style={{ maxWidth: FORM_MAX_WIDTH, width: '100%' }}
      >
        <FreightInformationForm
          shipment={shipment}
          onChange={handleFreightInformationChange}
        >
          {Boolean(consolidationType) && <ConsolidationReviewCard />}
          <Card id="mode-card">
            <Card.Title>Mode and Equipment</Card.Title>
            <Card.Divider />
            <Card.Body>
              <Layout.Switcher>
                <FreightInformationForm.Mode
                  modes={modes}
                  required
                  disabled={modeLocked}
                />
                <FreightInformationForm.Equipment
                  equipments={equipments}
                  disabled={equipments.length === 1}
                  required
                />
              </Layout.Switcher>
            </Card.Body>
          </Card>
          <StopsForm
            stops={shipment.stops}
            createStop={createTransientStop}
            onChange={handleStopsChange}
          >
            <Card id="lane-card">
              <Card.Title>Lane</Card.Title>
              <Card.Divider />
              <Card.Body>
                <Layout.Stack>
                  <StopForm key={pickup._metadata.id} index={pickupIndex}>
                    <Layout.Group data-testid="pickup-stop">
                      <StopForm.Facility
                        label="Pickup facility"
                        required
                        style={{ flex: 1 }}
                        stopType="pickup"
                      />

                      <StopForm.Date label="Pickup date" required />
                    </Layout.Group>
                  </StopForm>
                  <StopForm key={delivery._metadata.id} index={deliveryIndex}>
                    <Layout.Box padding="none" data-testid="delivery-stop">
                      <StopForm.Facility
                        label="Delivery facility"
                        required
                        stopType="delivery"
                      />
                    </Layout.Box>
                  </StopForm>
                </Layout.Stack>
              </Card.Body>
            </Card>
            {Boolean(pickup.facility) && (
              <StopSection
                key={`${pickup._metadata.id}-${0}`}
                id={`stop-${pickupIndex}`}
                stop={pickup}
                index={pickupIndex}
                totalStops={2}
                initialCollapsedState={true}
              >
                <StopFacilitySummary
                  stop={pickup}
                  style={{
                    margin: 0,
                    borderTopWidth: 0,
                    borderBottomColor: toCSSValue('color-divider'),
                    borderBottomWidth: toCSSValue('border-thin'),
                  }}
                />
                <StopForm key={pickup._metadata.id} index={pickupIndex}>
                  <Layout.Stack gap="spacing-4" padding="spacing-6">
                    <StopForm.Contact required />
                    <StopForm.Notes />
                  </Layout.Stack>
                </StopForm>
                <Divider />
                <Layout.Group padding="spacing-6">
                  <FacilitySummary facility={pickup.facility}>
                    <FacilitySummary.EditButton />
                  </FacilitySummary>
                </Layout.Group>
              </StopSection>
            )}
            {Boolean(delivery.facility) && (
              <StopSection
                key={`${delivery._metadata.id}-${deliveryIndex}`}
                id={`stop-${deliveryIndex}`}
                stop={delivery}
                index={deliveryIndex}
                totalStops={shipment.stops.length}
              >
                <StopFacilitySummary
                  stop={delivery}
                  style={{
                    margin: 0,
                    borderTopWidth: 0,
                    borderBottomColor: toCSSValue('color-divider'),
                    borderBottomWidth: toCSSValue('border-thin'),
                  }}
                />
                <StopForm key={delivery._metadata.id} index={deliveryIndex}>
                  <Layout.Stack gap="spacing-4" padding="spacing-6">
                    <StopForm.Contact required />
                    <StopForm.Notes />
                  </Layout.Stack>
                </StopForm>
                <Divider />
                <Layout.Box padding="spacing-6">
                  <StopHandlingUnitsSummary
                    variant="delivery"
                    handlingUnits={shipment.items}
                  />
                </Layout.Box>
                <Divider />
                <Layout.Group padding="spacing-6">
                  <FacilitySummary facility={delivery.facility}>
                    <FacilitySummary.EditButton />
                  </FacilitySummary>
                </Layout.Group>
              </StopSection>
            )}
          </StopsForm>
          <Card id="shipping-items-card" collapsible>
            <HandlingUnitsForm
              items={shipment.items}
              createItem={handleCreateItem}
              onChange={handleHandlingUnitChange}
            >
              <Card.Title>
                <Layout.Group align="center">
                  <Text variant="heading-sm-bold" color="color-text-secondary">
                    Shipping items
                  </Text>
                  {oneItemRequiredError && (
                    <Tag size="small" variant="danger">
                      Requires at least one item
                    </Tag>
                  )}
                </Layout.Group>
              </Card.Title>
              <Card.Subtitle>
                <HandlingUnitsDimensionsSummary
                  handlingUnits={shipment.items}
                />
              </Card.Subtitle>
              {shipment.items.length > 0 && <Card.Divider />}
              <Card.Body style={{ '--m-card-body-padding': 'none' }}>
                <LTLFormHandlingUnitV2
                  required={!isReefer}
                  handlingUnits={shipment.items || []}
                  onHandlingUnitChange={handleHandlingUnitChange}
                />
              </Card.Body>
              <Card.Footer>
                <HandlingUnitsFormAddHandlingUnit />
              </Card.Footer>
            </HandlingUnitsForm>
          </Card>
          {shipmentHasHazmatCommodity && (
            <Card
              aria-labelledby="hazmat-card-title"
              data-testid="hazmat-emergency-contact"
            >
              <Card.Title id="hazmat-card-title">Emergency contact</Card.Title>
              <Card.Divider />
              <Card.Body>
                <Layout.Stack gap="spacing-6">
                  <HazmatContactForm
                    onChange={handleHazmatContactChange}
                    contact={shipment}
                  >
                    <GridV2 columns={2} align="flex-end">
                      <HazmatContactName hint={<>&nbsp;</>} required />
                      <HazmatContactPhone hint={<>&nbsp;</>} required />
                    </GridV2>
                  </HazmatContactForm>
                </Layout.Stack>
              </Card.Body>
            </Card>
          )}
          <Card id="more-settings-card" collapsible>
            <Card.Title>More details</Card.Title>
            <Card.Divider />
            <Card.Body>
              <Layout.Stack>
                <Layout.Group justify="space-between">
                  <FreightInformationForm.PONumber />
                  <FreightInformationForm.SONumber />
                  <FreightInformationForm.BOLNumber />
                  {isReefer && <FreightInformationForm.Temperature />}
                </Layout.Group>
                <FreightInformationForm.Accessorials
                  mode="less_than_truckload"
                  stops={shipment.stops}
                />
              </Layout.Stack>
            </Card.Body>
          </Card>
          {!isEmpty(shipment.shipper_custom_fields) && (
            <Card id="custom-fields-card" collapsible>
              <Card.Title>
                <Layout.Group gap="spacing-1">
                  Custom fields
                  <Tooltip message="Custom fields information is for your internal use only and are not shared with carriers.">
                    <Icon name="question-dot" color="color-text-tertiary" />
                  </Tooltip>
                </Layout.Group>
              </Card.Title>
              <Card.Divider />
              <Card.Body>
                <FreightInformationForm.CustomFields />
              </Card.Body>
            </Card>
          )}
        </FreightInformationForm>
      </FormWrapper>
    </FormLayout>
  )
}
