import { get } from 'lodash-es'

import type { TransientFulfillment } from './domain/Fulfillment'

export function getFulfillmentFormIndex(
  fulfillment: TransientFulfillment,
  hasSelectedOrders: boolean = false
) {
  const [pickup, delivery] = get(fulfillment, 'stops', [])

  const index = [{ id: 'fulfillment-freight-info', label: 'Freight info' }]

  if (fulfillment.order_identifier_type === 'orders' && hasSelectedOrders) {
    index.push({ id: 'fulfillment-orders', label: 'Orders' })
  }

  index.push({ id: 'fulfillment-lane', label: 'Lane' })

  if (pickup.facility) {
    index.push({ id: 'pickup', label: 'Pickup details' })
  }

  if (delivery.facility) {
    index.push({ id: 'delivery', label: 'Delivery details' })
  }

  index.push({ id: 'fulfillment-notes', label: 'Notes' })

  return index
}
