import { Dropdown, Layout, Text } from '@loadsmart/miranda-react'
import { useCallback, useContext } from 'react'

import TableActions from '_shared_/components/TableActions'
import type { FulfillmentDetails } from 'fulfillments/details/ViewFulfillmentPage.data'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { canDeleteFulfillment } from 'fulfillments/domain/Fulfillment'

import { DeleteFulfillmentContext } from './DeleteFulfillment'

export function DeleteFulfillmentAction({
  fulfillment,
}: {
  readonly fulfillment: ListFulfillment | FulfillmentDetails
}) {
  const { openDialog } = useContext(DeleteFulfillmentContext)
  const deletable = canDeleteFulfillment(fulfillment)

  const onClick = useCallback(() => {
    openDialog(fulfillment.uuid)
  }, [openDialog, fulfillment.uuid])

  if (!deletable) {
    return null
  }

  return (
    <TableActions.Item
      onClick={onClick}
      caption="Remove all data about this fulfillment"
    >
      <Text color="color-text-error">Delete fulfillment</Text>
    </TableActions.Item>
  )
}

export function DeleteFulfillmentDropdownItem({
  fulfillment,
}: {
  readonly fulfillment: ListFulfillment | FulfillmentDetails
}) {
  const { openDialog } = useContext(DeleteFulfillmentContext)
  const deletable = canDeleteFulfillment(fulfillment)

  const onClick = useCallback(() => {
    openDialog(fulfillment.uuid)
  }, [openDialog, fulfillment.uuid])

  if (!deletable) {
    return null
  }

  return (
    <Dropdown.Item onClick={onClick} disabled={!deletable}>
      <Layout.Stack gap="spacing-0-5">
        <Text color={!deletable ? 'color-text-disabled' : 'color-text-error'}>
          Delete fulfillment
        </Text>
        <Text variant="body-sm" color="color-text-tertiary">
          Remove all data about this fulfillment
        </Text>
      </Layout.Stack>
    </Dropdown.Item>
  )
}
