import { useQuery } from 'react-query'

import { getSettings } from '_shared_/settings/settings.services'
import { useCurrentUser } from '_shared_/user/useCurrentUser'

import type { SettingsProviderValue } from './settings.context'

export const useSettingsRetrieve = () => {
  const { user } = useCurrentUser()

  return useQuery<Omit<SettingsProviderValue, 'isLoading'>>({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    queryKey: ['front-end-settings', user?.user_uuid],
    placeholderData: {
      settings: {},
      flags: {},
    },
    queryFn: getSettings,
  })
}
