import type { AxiosError } from 'axios'
import { useCallback, useRef, useState } from 'react'

import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import {
  createTransientShipmentFromFulfillment,
  getTransientShipmentFromFulfillmentValidationErrors,
} from 'fulfillments/fulfillment-service.mappers'
import { useShipmentModePageConfig } from 'screens/Shipper/Shipments/create/create.common'
import { validate } from 'screens/Shipper/Shipments/create/create.validation'
import type { NewShipmentPayload } from 'shipments/shipments.services'
import type { MODES } from 'utils/constants'
import { capitalize } from 'utils/strings'
import toArray from 'utils/toArray'

import { DEFAULT_ONE_CLICK_MODE } from './OneClickPlanFulfillment.constants'
import {
  useAutoPlanFulfillment,
  useAutoPlanFulfillmentAuxiliaryData,
} from './OneClickPlanFulfillment.hooks'
import type {
  MutationContext,
  OneClickAvailableModes,
} from './OneClickPlanFulfillment.types'

export function prettyErrorMessage(
  errors: Record<string, string | string[]> = {}
) {
  return Object.keys(errors).map(
    (field) =>
      `${capitalize(field.replaceAll('_', ' '))}: ${toArray(errors[field]).join(' ')}`
  )
}

export function useAutoCreateShipmentsDialogState(
  selectedFulfillments: ListFulfillment[],
  onSuccessCallback?: () => void
) {
  const [hasConfirmed, setHasConfirmed] = useState(false)
  const [mode, setMode] = useState<OneClickAvailableModes>(
    DEFAULT_ONE_CLICK_MODE
  )

  const { facilities, fulfillments, isError, isLoading, orders } =
    useAutoPlanFulfillmentAuxiliaryData(selectedFulfillments)

  const { mutateAsync } = useAutoPlanFulfillment()
  const mutations = useRef<Record<string, MutationContext>>({})

  const completedMutations = Object.keys(mutations.current).filter(
    (key) =>
      mutations.current[key]?.isSuccess || mutations.current[key]?.isError
  ).length
  const mutationsCompleted = hasConfirmed
    ? completedMutations / selectedFulfillments.length
    : 0

  const onError = useCallback(
    (
      e: AxiosError<Record<string, string>>,
      request: NewShipmentPayload & { fulfillment_uuid: string }
    ) => {
      const key = request.fulfillment_uuid

      mutations.current[key] = {
        errors: prettyErrorMessage(e.response?.data),
        isError: true,
        isLoading: false,
        isSuccess: false,
        response: undefined,
      }
    },
    [mutations]
  )

  const onSuccess = useCallback(
    (
      response: {
        uuid: string
      },
      request: NewShipmentPayload & { fulfillment_uuid: string }
    ) => {
      const key = request.fulfillment_uuid
      mutations.current[key] = {
        isError: false,
        isLoading: false,
        isSuccess: true,
        response,
      }
    },
    [mutations]
  )

  const shipmentAdapter = useShipmentModePageConfig(mode).adapter

  const onConfirm = useCallback(async () => {
    fulfillments.forEach((fulfillment) => {
      const pickupFacility = facilities.find(
        (facility) => facility?.uuid === fulfillment.pickup_facility.uuid
      )
      const deliveryFacility = facilities.find(
        (facility) => facility?.uuid === fulfillment.delivery_facility.uuid
      )
      if (pickupFacility && deliveryFacility) {
        const shipment = createTransientShipmentFromFulfillment(
          fulfillment,
          orders.filter((order) =>
            fulfillment.order_uuids.includes(order.uuid)
          ),
          pickupFacility,
          deliveryFacility,
          mode as MODES
        )
        const [validatedShipment, isValid] = validate(shipment)

        mutations.current[fulfillment.uuid] = {
          errors:
            getTransientShipmentFromFulfillmentValidationErrors(
              validatedShipment
            ),
          isError: !isValid,
          isLoading: isValid,
          shipment: validatedShipment,
          isValid,
        }
      } else {
        mutations.current[fulfillment.uuid] = {
          errors: ['Failed to load facility data'],
          isError: true,
          isLoading: false,
          shipment: undefined,
          isValid: false,
        }
      }
    })

    setHasConfirmed(true)

    for (const fulfillment of selectedFulfillments) {
      const { isValid, shipment } = mutations.current[fulfillment.uuid]

      if (isValid && shipment) {
        const payload = shipmentAdapter(shipment)

        try {
          await mutateAsync(
            { ...payload, fulfillment_uuid: fulfillment.uuid },
            {
              onError,
              onSuccess,
            }
          )
        } catch (e) {
          mutations.current[fulfillment.uuid].isError = !!e
        }
      }
    }

    onSuccessCallback?.()
  }, [
    facilities,
    fulfillments,
    mode,
    mutateAsync,
    mutations,
    onError,
    onSuccess,
    onSuccessCallback,
    orders,
    selectedFulfillments,
    setHasConfirmed,
    shipmentAdapter,
  ])

  return {
    canProceed: !isLoading && !isError,
    hasConfirmed,
    mode,
    mutations,
    mutationsCompleted,
    onConfirm,
    setMode,
  }
}
