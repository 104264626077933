import { Card, Divider, Layout, Text } from '@loadsmart/miranda-react'

import { Column, GridV2 as Grid, GridV2 } from 'components/Grid'
import {
  CommoditiesForm,
  CommodityForm,
} from 'components/HandlingUnitsManager/CommoditiesForm/CommoditiesForm'
import type {
  TransientCommodity,
  TransientHandlingUnit,
  TransientHandlingUnitOrderItem,
} from 'components/HandlingUnitsManager/HandlingUnits.types'
import {
  HandlingUnitForm,
  HandlingUnitsForm,
} from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsForm'
import { HandlingUnitsFormAddHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsFormAddHandlingUnit'
import {
  createTransientCommodity,
  createTransientHandlingUnit,
  createTransientHandlingUnitOrderItem,
} from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import { OrderItemsForm } from 'components/HandlingUnitsManager/OrderItemForm/OrderItemForm'
import { HazmatItemForm } from 'components/HazmatInformation'
import { HandlingUnitHeader } from 'components/LTLQuoteFormHandlingUnit/HandlingUnitHeader'
import type { TransientStop } from 'components/StopsManager'
import { HandlingUnitsFormHeader } from 'screens/Shipper/Shipments/components/HandlingUnit/HandlingUnitsFormHeader'
import { hasMoreThanOneItem, replaceArrayItem } from 'utils/arrays'
import { hasTransientError } from 'utils/transient'

import { HandlingUnitConsolidationSection } from '../../consolidation/components/HandlingUnitConsolidationSection'
import { useConsolidationContext } from '../../consolidation/consolidation.context'

type LTLFormHandlingUnitProps = {
  readonly handlingUnits: TransientHandlingUnit[]
  readonly stops: TransientStop[]
  readonly hasOneItemRequiredError?: boolean
  readonly onHandlingUnitChange: (value: TransientHandlingUnit[]) => void
  readonly required?: boolean
}

function FormCommodity({
  commodityIndex,
  handlingUnit,
  required,
}: {
  readonly commodityIndex: number
  readonly handlingUnit: TransientHandlingUnit
  readonly required: boolean
}) {
  const { canBeRemoved } = useConsolidationContext()
  const isHazmat = handlingUnit.commodities[commodityIndex].hazmat
  return (
    <CommodityForm index={commodityIndex}>
      <Layout.Stack aria-label={`Commodity #${commodityIndex + 1}`}>
        <Layout.Stack gap="spacing-1">
          <Layout.Group align="center" justify="space-between">
            <Text variant="body-md-bold" aria-hidden="true">
              {`Commodity #${commodityIndex + 1}`}
            </Text>

            {canBeRemoved(handlingUnit) &&
              hasMoreThanOneItem(handlingUnit.commodities) && (
                <CommoditiesForm.RemoveCommodity />
              )}
          </Layout.Group>
          <Divider />
        </Layout.Stack>

        <Layout.Stack>
          <Layout.Stack gap="none">
            <CommodityForm.Description hint={<>&nbsp;</>} required={required} />

            <GridV2 columns={4} align="flex-end">
              <CommodityForm.Weight hint={<>&nbsp;</>} required={required} />
              <CommodityForm.PackageCount hint={<>&nbsp;</>} />
              <CommodityForm.PackageType hint={<>&nbsp;</>} />
              <CommodityForm.Hazmat hint={<>&nbsp;</>} />
            </GridV2>

            <GridV2 columns={4} align="flex-end">
              <CommodityForm.Nmfc hint={<>&nbsp;</>} />
              <CommodityForm.FreightClass
                hint={<>&nbsp;</>}
                required={required}
              />
              <Column columns={2}>
                <CommodityForm.NmfcSearch hint={<>&nbsp;</>} />
              </Column>
            </GridV2>

            {isHazmat && (
              <GridV2 columns={4} align="flex-end" data-testid="hazmatfields">
                <HazmatItemForm.ShippingName
                  hint={<>&nbsp;</>}
                  required={required}
                />
                <HazmatItemForm.Class hint={<>&nbsp;</>} required={required} />
                <HazmatItemForm.UNNumber
                  hint={<>&nbsp;</>}
                  required={required}
                />
                <HazmatItemForm.PackingGroup
                  hint={<>&nbsp;</>}
                  required={required}
                />
              </GridV2>
            )}
          </Layout.Stack>
        </Layout.Stack>
      </Layout.Stack>
    </CommodityForm>
  )
}

export function LTLFormHandlingUnit({
  hasOneItemRequiredError,
  handlingUnits,
  stops,
  onHandlingUnitChange,
  required = true,
}: LTLFormHandlingUnitProps) {
  const { canBeRemoved } = useConsolidationContext()

  const handleCommodityChange = (index: number) => {
    return (value: TransientCommodity[]) => {
      onHandlingUnitChange(
        replaceArrayItem(handlingUnits, index, {
          ...handlingUnits[index],
          commodities: value,
        })
      )
    }
  }

  const handleOrderItemChange = (index: number) => {
    return (newTransientOrderItems: TransientHandlingUnitOrderItem[]) => {
      onHandlingUnitChange(
        replaceArrayItem(handlingUnits, index, {
          ...handlingUnits[index],
          order_items: newTransientOrderItems,
        })
      )
    }
  }

  const handleCreateItem = () => {
    return createTransientHandlingUnit({
      order_items: [],
      pickup_stop_index: 0,
      delivery_stop_index: stops.at(-1)?.stop_index,
    })
  }

  return (
    <HandlingUnitsForm
      items={handlingUnits}
      createItem={handleCreateItem}
      onChange={onHandlingUnitChange}
    >
      <HandlingUnitsFormHeader
        handlingUnits={handlingUnits}
        hasOneItemRequiredError={hasOneItemRequiredError}
        actions={<HandlingUnitsFormAddHandlingUnit />}
      />
      {handlingUnits.map((handlingUnit, index) => (
        <HandlingUnitForm key={handlingUnit._metadata?.id} index={index}>
          <CommoditiesForm
            items={handlingUnit.commodities}
            createItem={createTransientCommodity}
            onChange={handleCommodityChange(index)}
          >
            {/* How should this form definition change? */}
            <OrderItemsForm
              items={handlingUnit.order_items || []}
              createItem={createTransientHandlingUnitOrderItem}
              onChange={handleOrderItemChange(index)}
            >
              <Card
                aria-labelledby={`title-${handlingUnit._metadata?.id}`}
                data-testid={`handling-unit-${index}`}
                onToggle={(event) => event.stopPropagation()}
                style={{
                  '--m-card-border-left': 'none',
                  '--m-card-border-right': 'none',
                  '--m-card-border-bottom': 'none',
                }}
                collapsible
              >
                <HandlingUnitHeader
                  handlingUnit={handlingUnit}
                  actions={
                    canBeRemoved(handlingUnit) &&
                    hasMoreThanOneItem(handlingUnits) && (
                      <Layout.Group align="center" justify="flex-end">
                        <HandlingUnitsForm.RemoveHandlingUnit />
                      </Layout.Group>
                    )
                  }
                />
                <Card.Divider />
                <Card.Body>
                  <Layout.Stack
                    gap={hasTransientError(handlingUnit) ? 'spacing-4' : 'none'}
                  >
                    <HandlingUnitConsolidationSection
                      handlingUnit={handlingUnit}
                    />
                    <Layout.Group align="flex-start">
                      <HandlingUnitForm.PackageType
                        required={required}
                        hint={<>&nbsp;</>}
                      />
                      <HandlingUnitForm.PackageCount
                        required={required}
                        hint={<>&nbsp;</>}
                      />
                      <HandlingUnitForm.Stackable hint={<>&nbsp;</>} />
                      <HandlingUnitForm.Turnable hint={<>&nbsp;</>} />
                    </Layout.Group>

                    <Grid columns={3} rowGap="none">
                      <HandlingUnitForm.Dimension
                        dimension="length"
                        required={required}
                        hint={<>&nbsp;</>}
                      />
                      <HandlingUnitForm.Dimension
                        dimension="width"
                        required={required}
                        hint={<>&nbsp;</>}
                      />
                      <HandlingUnitForm.Dimension
                        dimension="height"
                        required={required}
                        hint={<>&nbsp;</>}
                      />
                      <HandlingUnitForm.WeightType
                        required={required}
                        hint={<>&nbsp;</>}
                      />
                    </Grid>

                    {handlingUnit.commodities.map(
                      (
                        commodity: TransientCommodity,
                        commodityIndex: number
                      ) => (
                        <FormCommodity
                          key={commodity._metadata?.id}
                          commodityIndex={commodityIndex}
                          handlingUnit={handlingUnit}
                          required={required}
                        />
                      )
                    )}
                  </Layout.Stack>
                </Card.Body>
                {canBeRemoved(handlingUnit) && (
                  <Card.Footer>
                    <CommoditiesForm.AddCommodity />
                  </Card.Footer>
                )}
              </Card>
            </OrderItemsForm>
          </CommoditiesForm>
        </HandlingUnitForm>
      ))}
    </HandlingUnitsForm>
  )
}
