import type { FieldProps } from '@loadsmart/miranda-react'
import {
  Banner,
  Card,
  Divider,
  Icon,
  Layout,
  Text,
  Tooltip,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-tokens'
import { get, isEmpty } from 'lodash'
import type { ReactNode, CSSProperties } from 'react'
import { useCallback, useEffect, useRef } from 'react'

import { FacilitySummary } from 'components/FacilityDetails'
import type { TransientFreightInformation } from 'components/FreightInformation'
import { FreightInformationForm } from 'components/FreightInformation'
import { HandlingUnitsDimensionsSummary } from 'components/HandlingUnitsManager/ContextBasedHandlingUnitDimensionsSummary'
import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { HandlingUnitsForm } from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsForm'
import { HandlingUnitsFormAddHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsFormAddHandlingUnit'
import {
  createTransientHandlingUnit,
  hasHazmat,
} from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import {
  StopForm,
  StopsForm,
  createTransientStop,
  getIsDateRequired,
} from 'components/StopsManager'
import { StopFacilitySummary } from 'components/StopsManager/StopFacilitySummary'
import type { UseStopFormFieldProps } from 'components/StopsManager/useStopFormField'
import { FormLayout } from 'screens/Shipper/Shipments/components/FormLayout'
import { StopSection } from 'screens/Shipper/Shipments/components/StopSection/StopSection'
import { ConsolidationReviewCard } from 'screens/Shipper/Shipments/create/consolidation/components/ConsolidationReviewCard'
import { useConsolidationContext } from 'screens/Shipper/Shipments/create/consolidation/consolidation.context'
import { useShipmentFormContext } from 'screens/Shipper/Shipments/create/create.context'
import { useMultiStopWithHandlingUnitsShipmentFormHandlers } from 'screens/Shipper/Shipments/create/create.hooks'
import {
  FORM_MAX_WIDTH,
  FormWrapper,
} from 'screens/Shipper/Shipments/create/create.styles'
import { getShipmentFormIndexes } from 'screens/Shipper/Shipments/create/create.utils'
import { HandlingUnitsToDeliver } from 'screens/Shipper/Shipments/create/forms/HandlingUnitsToDelivery'
import { useEquipmentTypes } from 'shipments/hooks/useEquipmentTypes'
import { useTransportationModes } from 'shipments/hooks/useTransportationModes'
import {
  areEquipmentTypesEqual,
  EQUIPMENT_TYPES,
  isEquipmentTypeOneOf,
} from 'utils/equipmentTypeV2'
import { resolveTransportationMode } from 'utils/transportationMode'

import { TLFormHandlingUnitV2 } from './TLFormHandlingUnitV2'

const FacilityOrTerminal = (
  props: Readonly<{
    label?: ReactNode
    required?: FieldProps['required']
    hint?: UseStopFormFieldProps['hint']
    style?: CSSProperties
    disabled?: boolean
    stopType?: StopType
  }>
) => {
  const [shipment] = useShipmentFormContext()

  if (shipment.direction === 'load_import' && props.stopType === 'pickup') {
    return <StopForm.Terminal {...props} />
  }

  if (shipment.direction === 'load_export' && props.stopType === 'delivery') {
    return <StopForm.Terminal {...props} />
  }

  return <StopForm.Facility {...props} />
}

export function DrayageForm() {
  const [shipment, setPartialShipment, setShipment] = useShipmentFormContext()
  const { consolidationType } = useConsolidationContext()

  const { handleRemoveStop, handleStopAdded, handleStopsChange } =
    useMultiStopWithHandlingUnitsShipmentFormHandlers({ setShipment })

  const { modes } = useTransportationModes()
  const { equipments } = useEquipmentTypes({ mode: shipment.mode })
  const lastDirectionRef = useRef<string | undefined>(undefined)

  useEffect(() => {
    const equipmentOptions = equipments.map((equipmentOption) =>
      String(equipmentOption.value)
    )

    if (
      shipment.equipment_type &&
      !isEquipmentTypeOneOf(shipment.equipment_type, equipmentOptions)
    ) {
      setPartialShipment({
        equipment_type: EQUIPMENT_TYPES.drb.value,
        direction: 'load_import',
      })

      lastDirectionRef.current = 'load_import'
    }
  }, [shipment.equipment_type, setPartialShipment, equipments])

  useEffect(() => {
    if (shipment.direction === lastDirectionRef.current) {
      return
    }

    const pickup = shipment.stops[0]
    const delivery = shipment.stops[shipment.stops.length - 1]

    const newStops = [...shipment.stops]

    newStops[0] = {
      ...delivery,
      stop_type: 'pickup',
      stop_index: 0,
    }

    newStops[shipment.stops.length - 1] = {
      ...pickup,
      stop_type: 'delivery',
      stop_index: shipment.stops.length - 1,
    }

    setPartialShipment({
      stops: newStops,
    })

    lastDirectionRef.current = shipment.direction
  }, [setPartialShipment, shipment.direction, shipment.stops])

  const handleFreightInformationChange = useCallback(
    (newFreightInformation: TransientFreightInformation) => {
      setPartialShipment(newFreightInformation)
    },
    [setPartialShipment]
  )

  const handleHandlingUnitChange = (
    newHandlingUnits: TransientHandlingUnit[]
  ) => {
    const hasHazmatCommodity = hasHazmat(
      newHandlingUnits.map((item) => item.commodities).flat()
    )

    setPartialShipment({
      items: newHandlingUnits,
      hazmat: hasHazmatCommodity,
    })
  }

  const stops = get(shipment, 'stops', [])
  const [pickup, delivery] = stops
  const intermediaryStops = stops.slice(1, stops.length - 1)
  const isReefer = areEquipmentTypesEqual(
    shipment.equipment_type ?? '',
    EQUIPMENT_TYPES.rfr.value
  )

  const handleCreateItem = () => {
    return createTransientHandlingUnit({
      order_items: [],
      pickup_stop_index: 0,
      delivery_stop_index: null,
    })
  }

  return (
    <FormLayout
      indexValues={getShipmentFormIndexes({
        transientShipment: shipment,
        consolidation: consolidationType,
        withIntermediaryStops: true,
        withShippingItems: true,
        withContainerDetails: true,
      })}
    >
      <FormWrapper
        data-testid="drayage-form"
        style={{ maxWidth: FORM_MAX_WIDTH, width: '100%' }}
      >
        <FreightInformationForm
          shipment={shipment}
          onChange={handleFreightInformationChange}
        >
          {Boolean(consolidationType) && <ConsolidationReviewCard />}
          <Card id="mode-card">
            <Card.Title>Mode and equipment</Card.Title>
            <Card.Divider />
            <Card.Body>
              <Layout.Stack>
                <Layout.Switcher>
                  <FreightInformationForm.Mode modes={modes} required />
                  <FreightInformationForm.Equipment
                    equipments={equipments}
                    required
                  />
                </Layout.Switcher>
                <FreightInformationForm.Direction />
              </Layout.Stack>
            </Card.Body>
          </Card>
          <StopsForm
            stops={shipment.stops}
            createStop={createTransientStop}
            onChange={handleStopsChange}
            onAddStop={handleStopAdded}
            onDeleteStop={handleRemoveStop}
          >
            <Card id="lane-card">
              <Card.Title>Lane</Card.Title>
              <Card.Divider />
              <Card.Body>
                <Layout.Stack>
                  <StopForm key={pickup._metadata.id} index={0}>
                    <Layout.Group data-testid="pickup-stop">
                      <FacilityOrTerminal
                        label="Pickup"
                        required
                        style={{ flex: 1 }}
                        stopType="pickup"
                      />

                      <StopForm.Date label="Pickup date" required />
                    </Layout.Group>
                  </StopForm>

                  {intermediaryStops.map((stop, index: number) => (
                    <StopForm key={stop._metadata.id} index={index + 1}>
                      <Layout.Group
                        data-testid={`stop-${index + 1}`}
                        align="flex-end"
                      >
                        <StopForm.Facility
                          label={`Stop #${index + 1}`}
                          required
                          style={{ flex: 1 }}
                          stopType={stop.stop_type as StopType}
                        />
                        <StopForm.Type />
                        <Layout.Box padding="none" paddingY="spacing-2">
                          <StopsForm.RemoveStop />
                        </Layout.Box>
                        <StopForm.Date label={`Stop #${index + 1} date`} />
                      </Layout.Group>
                    </StopForm>
                  ))}

                  <StopForm
                    key={delivery._metadata.id}
                    index={shipment.stops.length - 1}
                  >
                    <Layout.Group data-testid="delivery-stop">
                      <FacilityOrTerminal
                        label="Delivery"
                        required
                        style={{ flex: 1 }}
                        stopType="delivery"
                      />

                      <StopForm.Date
                        label="Delivery date"
                        required={getIsDateRequired(
                          shipment.stops.length - 1,
                          shipment.stops.length
                        )}
                      />
                    </Layout.Group>
                  </StopForm>
                  <Banner variant="neutral">
                    <Banner.Description>
                      For import drayage, the container returns to the pickup
                      port. For export drayage, it&apos;s picked up at the
                      destination port.
                    </Banner.Description>
                  </Banner>
                </Layout.Stack>
              </Card.Body>
            </Card>
            {stops.map(
              (stop, index) =>
                (Boolean(stop.facility) ||
                  Boolean(stop.location) ||
                  Boolean(stop.terminal)) && (
                  <StopSection
                    key={`${stop._metadata.id}`}
                    id={`stop-${index}`}
                    stop={stop}
                    index={index}
                    totalStops={shipment.stops.length}
                    initialCollapsedState
                  >
                    <StopFacilitySummary
                      stop={stop}
                      style={{
                        margin: 0,
                        borderTopWidth: 0,
                        borderBottomColor: toCSSValue('color-divider'),
                        borderBottomWidth: toCSSValue('border-thin'),
                      }}
                    />
                    {stop.facility && (
                      <StopForm key={stop._metadata.id} index={index}>
                        <Layout.Stack gap="spacing-4" padding="spacing-6">
                          <StopForm.Contact />
                          <StopForm.Notes />
                        </Layout.Stack>
                      </StopForm>
                    )}

                    <HandlingUnitsToDeliver
                      stopIndex={stop.stop_index}
                      allItems={shipment.items}
                    />

                    {stop.facility && (
                      <>
                        <Divider />
                        <Layout.Group padding="spacing-6">
                          <FacilitySummary facility={stop.facility}>
                            <FacilitySummary.EditButton />
                          </FacilitySummary>
                        </Layout.Group>
                      </>
                    )}
                  </StopSection>
                )
            )}
          </StopsForm>
          <Card
            id="shipping-items-card"
            data-testid="shipping-items-card"
            collapsible
          >
            <HandlingUnitsForm
              items={shipment.items}
              createItem={handleCreateItem}
              onChange={handleHandlingUnitChange}
            >
              <Card.Title>
                <Layout.Group align="center">
                  <Text variant="heading-sm-bold" color="color-text-secondary">
                    Shipping items
                  </Text>
                </Layout.Group>
              </Card.Title>
              <Card.Subtitle>
                <HandlingUnitsDimensionsSummary
                  handlingUnits={shipment.items}
                />
              </Card.Subtitle>
              {!isEmpty(shipment.items) && <Card.Divider />}
              <Card.Body style={{ '--m-card-body-padding': 'none' }}>
                <TLFormHandlingUnitV2
                  handlingUnits={shipment.items}
                  onHandlingUnitChange={handleHandlingUnitChange}
                  stops={shipment.stops}
                />
              </Card.Body>
              <Card.Footer>
                <HandlingUnitsFormAddHandlingUnit />
              </Card.Footer>
            </HandlingUnitsForm>
          </Card>
          <Card
            id="container-details-card"
            data-testid="container-details-card"
            collapsible
          >
            <Card.Title>Container Details</Card.Title>
            <Card.Divider />
            <Card.Body>
              <Layout.Grid
                style={{
                  gridTemplateColumns: 'repeat(3, 1fr)',
                }}
                gap="spacing-4"
              >
                <FreightInformationForm.ContainerSize required />
                <FreightInformationForm.ContainerNumber required />
                <FreightInformationForm.MasterBOLNumber />
                <FreightInformationForm.OceanLine />
                <FreightInformationForm.SealNumber />
                <FreightInformationForm.VesselETA />
                <FreightInformationForm.VesselName />
                <FreightInformationForm.VoyageNumber />
                <FreightInformationForm.IMOCode />
              </Layout.Grid>
            </Card.Body>
          </Card>
          <Card id="more-details-card" collapsible>
            <Card.Title>More details</Card.Title>
            <Card.Divider />
            <Card.Body>
              <Layout.Stack>
                <Layout.Group>
                  <FreightInformationForm.PONumber />
                  <FreightInformationForm.SONumber />
                  <FreightInformationForm.BOLNumber />
                  {isReefer && <FreightInformationForm.Temperature />}
                </Layout.Group>
                <FreightInformationForm.Accessorials
                  mode={resolveTransportationMode(shipment.mode!)!}
                  stops={shipment.stops}
                />
              </Layout.Stack>
            </Card.Body>
          </Card>
          {shipment.shipper_custom_fields && (
            <Card id="custom-fields-card" collapsible>
              <Card.Title>
                <Layout.Group gap="spacing-1">
                  Custom fields
                  <Tooltip message="Custom fields information is for your internal use only and are not shared with carriers.">
                    <Icon name="question-dot" color="color-text-tertiary" />
                  </Tooltip>
                </Layout.Group>
              </Card.Title>
              <Card.Divider />
              <Card.Body>
                <FreightInformationForm.CustomFields />
              </Card.Body>
            </Card>
          )}
        </FreightInformationForm>
      </FormWrapper>
    </FormLayout>
  )
}
