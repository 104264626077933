import { PageContent } from '@loadsmart/miranda-react'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { WindowTitle } from '_shared_/components/WindowTitle'
import { FulfillmentHistoryProvider } from 'fulfillments/components/FulfillmentHistory'
import { DeleteFulfillmentProvider } from 'fulfillments/delete/DeleteFulfillment'
import { appRoutes } from 'router/app-routes'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

import { ViewFulfillmentPageHeader } from './components/ViewFulfillmentPageHeader'
import {
  useViewFulfillmentContextData,
  ViewFulfillmentContextProvider,
} from './ViewFulfillmentContext'
import { ViewFulfillmentDetails } from './ViewFulfillmentDetails'

export function ViewFulfillmentPage() {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const context = useViewFulfillmentContextData(id)

  useEffect(() => {
    analytics.track('Viewed: Fulfillment', AnalyticsEventTrigger.view)
  }, [])

  const redirectBackToList = () => {
    history.push(appRoutes.fulfillments)
  }

  return (
    <ViewFulfillmentContextProvider {...context}>
      <WindowTitle title="Fulfillment Details" />
      <FulfillmentHistoryProvider>
        <DeleteFulfillmentProvider callback={redirectBackToList}>
          <PageContent>
            <ViewFulfillmentPageHeader />
            <PageContent.Body>
              <ViewFulfillmentDetails />
            </PageContent.Body>
          </PageContent>
        </DeleteFulfillmentProvider>
      </FulfillmentHistoryProvider>
    </ViewFulfillmentContextProvider>
  )
}
