import { DateHelper } from '@loadsmart/loadsmart-ui'
import * as Yup from 'yup'

import type { HandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { hasHazmat } from 'components/ShippingItemsManager'
import { getStopDateFacilityLabel } from 'components/StopsManager'
import type { TransientStop } from 'components/StopsManager'
import {
  INVALID_NUMBER_MESSAGE,
  MAX_LENGTH_MESSAGE,
  POSITIVE_NUMBER_MESSAGE,
  REQUIRED_FIELD_MESSAGE,
} from 'constants/errors'

import type { TransientShipment } from './Shipment.utils'

type TestContextExtended = {
  from: {
    value: TransientShipment
  }[]
}

function requiredStopByTypeTest(
  stopType: string,
  value: number | null | undefined,
  context: Yup.TestContext<any>
) {
  if (value == null) {
    return false
  }

  const { from } = context as Yup.TestContext & TestContextExtended
  const stop = from.at(-1)?.value.stops[value]
  return stop?.stop_type === stopType
}

const stringRequired = Yup.string().nullable().required(REQUIRED_FIELD_MESSAGE)
const numberAsString = Yup.string()
  .nullable()
  .required(REQUIRED_FIELD_MESSAGE)
  .matches(/^[0-9.]+$/, INVALID_NUMBER_MESSAGE)
const numberRequired = Yup.number()
  .nullable()
  .required(REQUIRED_FIELD_MESSAGE)
  .min(1, POSITIVE_NUMBER_MESSAGE)

const numberNullable = Yup.number().nullable()

const hazmatRequired = Yup.string()
  .nullable()
  .when('items', (items: HandlingUnit[], schema) => {
    const handlingUnits = items
    if (hasHazmat(handlingUnits.map(({ commodities }) => commodities).flat())) {
      return stringRequired
    }
    return schema
  })

const facilitySchema = Yup.object().shape({
  facility: Yup.object().nullable().required(REQUIRED_FIELD_MESSAGE),
})

const facilityOrTerminalSchema = Yup.object().shape(
  {
    facility: Yup.object().when('terminal', {
      is: null,
      then: Yup.object().nullable().required(REQUIRED_FIELD_MESSAGE),
      otherwise: Yup.object().nullable(),
    }),
    terminal: Yup.object().when('facility', {
      is: null,
      then: Yup.object().nullable().required(REQUIRED_FIELD_MESSAGE),
      otherwise: Yup.object().nullable(),
    }),
  },
  [['facility', 'terminal']]
)

const stopContactSchema = Yup.object().shape({
  contact: Yup.object().nullable().required(REQUIRED_FIELD_MESSAGE).shape({
    uuid: stringRequired,
  }),
})

const stopSchemaShape = {
  date: Yup.date()
    .nullable()
    .test('date', REQUIRED_FIELD_MESSAGE, (value, context) => {
      // Should always require pickup stop date
      if (context.parent.stop_index == 0 && !value) {
        return false
      }

      const { from } = context as Yup.TestContext & TestContextExtended
      const stops = from.at(-1)?.value.stops ?? []
      const lastStopIndex = stops.at(-1)?.stop_index
      const hasAdditionalStops = stops.length > 2
      // Should require last stop date for multiple stops shipments
      if (
        context.parent.stop_index == lastStopIndex &&
        hasAdditionalStops &&
        !value
      ) {
        return false
      }

      return true
    })
    .test(
      'future-date',
      'The date cannot be before the previous stop',
      (value, context) => {
        const { stop_index } = context.parent

        if (stop_index === 0 || !value) {
          return true
        }

        const { from } = context as Yup.TestContext & TestContextExtended
        const stops = from.at(-1)?.value.stops ?? []

        for (
          let previousStopIndex = stop_index - 1;
          previousStopIndex >= 0;
          previousStopIndex--
        ) {
          const date = stops.at(previousStopIndex)?.date

          if (date && DateHelper(value).is('<', DateHelper(date))) {
            return context.createError({
              message: `The ${getStopDateFacilityLabel(stop_index, stops.length)} cannot be before the ${getStopDateFacilityLabel(previousStopIndex, stops.length)}`,
            })
          }
        }

        return true
      }
    ),
}

const stopSchema = Yup.object().shape(stopSchemaShape).concat(facilitySchema)

const stopSchemaWithTerminal = Yup.object()
  .shape(stopSchemaShape)
  .concat(facilityOrTerminalSchema)

const deliveryStopSchema = Yup.number()
  .nullable()
  .required(REQUIRED_FIELD_MESSAGE)
  .test(
    'deliveryStopIndex',
    'A delivery address is required',
    function test(value, context) {
      return requiredStopByTypeTest('delivery', value, context)
    }
  )

const pickupStopSchema = Yup.number()
  .nullable()
  .required(REQUIRED_FIELD_MESSAGE)
  .test(
    'pickupStopIndex',
    'A pickup address is required',
    function test(value, context) {
      return requiredStopByTypeTest('pickup', value, context)
    }
  )

const equipmentTypeSchema = Yup.object().shape({
  equipment_type: stringRequired,
  equipment_subtypes: Yup.array()
    .nullable()
    .when('equipment_type', {
      is: 'FBE',
      then: (schema) => schema.min(1, REQUIRED_FIELD_MESSAGE),
      otherwise: (schema) => schema.min(0),
    }),
  with_tarp: Yup.boolean().nullable(),
  tarp_type: Yup.string().nullable().when('with_tarp', {
    is: true,
    then: stringRequired,
  }),
  tarp_size: Yup.number().nullable().when('with_tarp', {
    is: true,
    then: numberRequired,
  }),
})

const freightInformationSchema = Yup.object()
  .shape({
    po_number: Yup.string()
      .nullable()
      .max(255, MAX_LENGTH_MESSAGE`255`),
    so_number: Yup.string()
      .nullable()
      .max(255, MAX_LENGTH_MESSAGE`255`),
    bol_number: Yup.string()
      .nullable()
      .max(255, MAX_LENGTH_MESSAGE`100`),
    shipper_ref_number: Yup.string()
      .nullable()
      .max(255, MAX_LENGTH_MESSAGE`255`),
  })
  .concat(equipmentTypeSchema)

const containerDetailsSchema = Yup.object().shape({
  container_size: Yup.string().when('mode', {
    is: 'drayage',
    then: (f) => f.required(REQUIRED_FIELD_MESSAGE),
    otherwise: (f) => f.nullable(),
  }),
  container_number: Yup.string()
    .matches(/^[a-z]{4}\d{7}$/i, {
      message: 'Enter 4 letters followed by 7 numbers, no special characters.',
      excludeEmptyString: true,
    })
    .when('mode', {
      is: 'drayage',
      then: (f) => f.required(REQUIRED_FIELD_MESSAGE),
      otherwise: (f) => f.nullable(),
    }),
  master_bol_number: Yup.string().nullable(),
  ocean_line_scac: Yup.string().nullable(),
  seal_number: Yup.string().nullable(),
  vessel_eta_date: Yup.string().nullable(),
  vessel_eta_time: Yup.string()
    .when('vessel_eta_date', {
      is: (value?: string | null) => typeof value === 'string',
      then: Yup.string().required('Required when date is provided'),
      otherwise: Yup.string().nullable(),
    })
    .matches(/\d{4}/i, {
      message: 'Please use the format HHMM',
      excludeEmptyString: true,
    }),
  vessel_name: Yup.string().nullable(),
  voyage_number: Yup.string().nullable(),
  imo_code: Yup.string().nullable(),
})

export class ItemSchemaBuilder {
  private baseItem: Record<string, Yup.AnySchema>
  private baseCommodity: Record<string, Yup.AnySchema>
  private baseOrderItem: Record<string, Yup.AnySchema>

  constructor() {
    this.baseItem = {
      package_type: Yup.string().nullable(),
      package_count: Yup.number().nullable(),
      length: Yup.string().nullable(),
      width: Yup.string().nullable(),
      height: Yup.string().nullable(),
      weight_type: Yup.string().nullable(),
      delivery_stop_index: deliveryStopSchema,
      pickup_stop_index: pickupStopSchema,
    }

    this.baseCommodity = {
      description: Yup.string()
        .nullable()
        .max(255, MAX_LENGTH_MESSAGE`255`),
      package_type: Yup.string().nullable(),
      package_count: Yup.number().nullable(),
      weight: Yup.string().nullable(),
      hazmat: Yup.boolean().nullable(),
      nmfc_code: Yup.string()
        .nullable()
        .max(255, MAX_LENGTH_MESSAGE`255`),
      freight_class: Yup.string().nullable(),
      hazmat_packing_group: Yup.string().when('hazmat', {
        is: true,
        then: (f) => f.required(REQUIRED_FIELD_MESSAGE),
        otherwise: (f) => f.nullable(),
      }),
      hazmat_proper_shipping_name: Yup.string().when('hazmat', {
        is: true,
        then: (f) =>
          f.required(REQUIRED_FIELD_MESSAGE).max(255, MAX_LENGTH_MESSAGE`255`),
        otherwise: (f) => f.nullable(),
      }),
      hazmat_un_number: Yup.string().when('hazmat', {
        is: true,
        then: (f) => f.required(REQUIRED_FIELD_MESSAGE),
        otherwise: (f) => f.nullable(),
      }),
      hazmat_class: Yup.string().when('hazmat', {
        is: true,
        then: (f) => f.required(REQUIRED_FIELD_MESSAGE),
        otherwise: (f) => f.nullable(),
      }),
    }

    this.baseOrderItem = {
      uuid: Yup.string().required(REQUIRED_FIELD_MESSAGE),
      shipped_package_count: Yup.number()
        .nullable()
        .min(0, POSITIVE_NUMBER_MESSAGE),
    }
  }

  optionalDeliveryStop() {
    this.baseItem.delivery_stop_index = numberNullable

    return this
  }

  requireFullItem() {
    const { package_type, package_count, length, width, height, weight_type } =
      this.baseItem

    this.baseItem = {
      package_type: package_type.concat(stringRequired),
      package_count: package_count.concat(numberRequired),
      length: length.concat(numberAsString),
      width: width.concat(numberAsString),
      height: height.concat(numberAsString),
      weight_type: weight_type.concat(stringRequired),
      delivery_stop_index: deliveryStopSchema,
      pickup_stop_index: pickupStopSchema,
    }

    return this
  }

  requireFullCommodityWithoutPackaging() {
    const { description, weight, freight_class, ...rest } = this.baseCommodity
    this.baseCommodity = {
      ...rest,
      description: description.concat(stringRequired),
      weight: weight.concat(numberAsString),
      freight_class: freight_class.concat(stringRequired),
    }
    return this
  }

  requireFullCommodity() {
    const {
      description,
      package_type,
      package_count,
      weight,
      freight_class,
      ...rest
    } = this.baseCommodity
    this.baseCommodity = {
      ...rest,
      description: description.concat(stringRequired),
      package_type: package_type.concat(stringRequired),
      package_count: package_count.concat(numberRequired),
      weight: weight.concat(numberAsString),
      freight_class: freight_class.concat(stringRequired),
    }
    return this
  }

  requireMinimalCommodity() {
    const { description, weight, ...rest } = this.baseCommodity
    this.baseCommodity = {
      ...rest,
      description: description.concat(stringRequired),
      weight: weight.concat(numberAsString),
    }
    return this
  }

  optionalFreightClass() {
    this.baseCommodity.freight_class = Yup.string().nullable()
    return this
  }

  build() {
    return Yup.array().of(
      Yup.object().shape({
        ...this.baseItem,
        commodities: Yup.array().of(Yup.object().shape(this.baseCommodity)),
        order_items: Yup.array().of(Yup.object().shape(this.baseOrderItem)),
      })
    )
  }
}

const PTLItemsSchema = new ItemSchemaBuilder()
  .requireFullItem()
  .requireMinimalCommodity()
  .optionalFreightClass()
  .build()

const LTLItemsSchema = new ItemSchemaBuilder()
  .requireFullItem()
  .optionalDeliveryStop()
  .requireFullCommodityWithoutPackaging()
  .build()

const multimodalItemsSchema = new ItemSchemaBuilder().build()

const EXPItemsSchema = new ItemSchemaBuilder()
  .requireFullItem()
  .requireFullCommodityWithoutPackaging()
  .optionalFreightClass()
  .build()

const hazmatSchema = Yup.object().shape({
  hazmat_contact_name: hazmatRequired,
  hazmat_phone_number: hazmatRequired,
})

const LTLStopsSchema = Yup.array().test(
  'stops',
  'validate stops',
  (stops: TransientStop[] | undefined) => {
    const [pickup, delivery] = stops ?? []
    const errors: Yup.ValidationError[] = []

    try {
      stopSchema
        .concat(stopContactSchema)
        .validateSync(pickup, { abortEarly: false })
    } catch (error) {
      const yupErrors = (error as Yup.ValidationError).inner
      yupErrors.forEach((yupError) => {
        yupError.path = `stops[0].${yupError.path}`
      })
      errors.push(...yupErrors)
    }

    try {
      facilitySchema
        .concat(stopContactSchema)
        .validateSync(delivery, { abortEarly: false })
    } catch (error) {
      const yupErrors = (error as Yup.ValidationError).inner
      yupErrors.forEach((yupError) => {
        yupError.path = `stops[1].${yupError.path}`
      })
      errors.push(...yupErrors)
    }

    if (errors.length > 0) {
      return new Yup.ValidationError(errors)
    }

    return true
  }
)

export const ltlValidationSchema = Yup.object()
  .shape({
    stops: LTLStopsSchema,
    items: Yup.array().when('equipment_type', {
      is: 'RFR',
      then: () => multimodalItemsSchema.min(1, REQUIRED_FIELD_MESSAGE),
      otherwise: () => LTLItemsSchema.min(1, REQUIRED_FIELD_MESSAGE),
    }),
  })
  .concat(equipmentTypeSchema)
  .concat(hazmatSchema)

export const multimodalValidationSchema = Yup.object()
  .shape({
    stops: Yup.array().of(stopSchema),
    items: multimodalItemsSchema,
  })
  .concat(freightInformationSchema)

export const ptlValidationSchema = Yup.object()
  .shape({
    stops: Yup.array().of(stopSchema),
    items: PTLItemsSchema.min(1, REQUIRED_FIELD_MESSAGE),
  })
  .concat(freightInformationSchema)

export const expValidationSchema = Yup.object()
  .shape({
    stops: Yup.array().of(stopSchema),
    items: EXPItemsSchema.min(1, REQUIRED_FIELD_MESSAGE),
  })
  .concat(freightInformationSchema)

export const drayageValidationSchema = Yup.object()
  .shape({
    stops: Yup.array().of(stopSchemaWithTerminal),
    items: multimodalItemsSchema,
  })
  .concat(freightInformationSchema)
  .concat(containerDetailsSchema)
