import { intersection } from 'lodash-es'

import { ALLOWED_STATUSES_FOR_CONSOLIDATION } from 'orders/constants'
import type { ListOrder, Order } from 'orders/types'

import { ONE_CLICK_AVAILABLE_MODES } from './types'

export function isOrderAutoPlannable(order?: ListOrder | Order) {
  return (
    !!order &&
    ALLOWED_STATUSES_FOR_CONSOLIDATION.includes(order.status) &&
    !!order?.suitable_modes?.length &&
    !!intersection(order.suitable_modes, ONE_CLICK_AVAILABLE_MODES).length
  )
}
