import { Spinner } from '@loadsmart/loadsmart-ui'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useSettings } from '_shared_/settings/useSettings'
import type { AppDispatch } from 'app/store'
import NavigationHeader from 'components/NavigationHeader'
import { addMessage } from 'features/message/actions'
import { useRfpRetrieve } from 'rfp/hooks/rfp'
import RFPForm from 'rfp/rfp-form'
import type { RequestForProposalDetail } from 'rfp/rfp.types'
import { SUPPORT_EMAIL } from 'utils/constants'

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export default function RFPEdit() {
  const dispatch = useDispatch<AppDispatch>()
  const { rfpId } = useParams<{ rfpId: string }>()

  const {
    values: [newRFPFormEnabled],
  } = useSettings(['flags.ENABLE_NEW_RFP_FORM'])

  const { data, isLoading, isFetching } = useRfpRetrieve(rfpId, {
    refetchOnWindowFocus: false,
    onError: () =>
      dispatch(
        addMessage({
          id: 'loadRFPFailed',
          message: 'Failed to the Request For Proposal.',
          description: `Please refresh the page or contact support at ${SUPPORT_EMAIL}.`,
          closeable: false,
          type: 'danger',
        })
      ),
  })
  const rfp = data as RequestForProposalDetail

  const crumbs = [
    {
      title: `RFP - ${rfp?.name}`,
      path: `/shipper/rfp/${rfpId}`,
    },
    {
      title: 'Edit Details',
      path: '',
    },
  ]

  const shouldRenderLoading = isLoading || isFetching || !rfp

  return (
    <>
      <NavigationHeader shouldRender={!newRFPFormEnabled} crumbs={crumbs} />
      {shouldRenderLoading ? (
        <SpinnerWrapper>
          <Spinner size={50} />
        </SpinnerWrapper>
      ) : (
        <RFPForm rfp={rfp} />
      )}
    </>
  )
}
