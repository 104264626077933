import { matchPath } from 'react-router-dom'

import { appRoutes } from 'router/app-routes'

import {
  LOCATION_MANAGEMENT_STORAGE_EVENT,
  LOCATION_MANAGEMENT_STORAGE_KEY,
} from './constants'

/**
 * Paths that are not a route per se but have slash "/" in the URL.
 *
 * We are adding this because we match the route using exact, and this can lead to issues
 * Later we can just use exact:false and add specific routes to be matched first
 */
const CUSTOM_PATHS = { ShipmentCharges: '/shipments/:shipmentUUID/charges' }

const RELOAD_PATHS = [
  appRoutes.newQuote,
  appRoutes.quotes,
  appRoutes.newShipment,
  appRoutes.shipments,
  appRoutes.contracts,
  appRoutes.newContract,
  appRoutes.rfp,
  appRoutes.newRFP,
  appRoutes.carriers,
  appRoutes.lanes,
  appRoutes.facilityManagement,
  appRoutes.shipperSettings,
]

const NO_RELOAD_REQUIRED_PATHS: string[] = [
  appRoutes.priceMonitor,
  appRoutes.shipperReports,
]

/**
 * After changing the shipper locations we should redirect the user
 * from this specific (detail) screens to the related list page
 */
const REDIRECT_PATHS_MAP: Record<string, string> = {
  [appRoutes.quoteDetails]: appRoutes.quotes,
  [appRoutes.shipmentDetails]: appRoutes.shipments,
  [CUSTOM_PATHS.ShipmentCharges]: appRoutes.shipments,
  [appRoutes.editContract]: appRoutes.contracts,
  [appRoutes.contractDetails]: appRoutes.contracts,
  [appRoutes.rfpDetails]: appRoutes.rfp,
  [appRoutes.editRFP]: appRoutes.rfp,
  [appRoutes.checkout]: appRoutes.quotes,
}

const REDIRECT_PATHS_LIST = Object.keys(REDIRECT_PATHS_MAP)

const API_PATHS_WITHOUT_LOCATION_UUID: Record<string, string[]> = {
  '/shippers/users/:userId': ['get', 'patch'],
}

export function shouldNotAppendLocationUUIDToThisPath(
  apiPath: string | undefined,
  apiMethod: string | undefined
) {
  if (typeof apiPath !== 'string' || typeof apiMethod !== 'string') {
    return false
  }

  const matchedPath = matchPath(apiPath, {
    path: Object.keys(API_PATHS_WITHOUT_LOCATION_UUID),
    exact: true,
  })

  return (
    matchedPath &&
    API_PATHS_WITHOUT_LOCATION_UUID[matchedPath.path].includes(apiMethod)
  )
}

function getShipperUUIDFromURL() {
  const urlData = new URL(globalThis.location.href)
  return urlData.searchParams.get('toLocation')
}

function clearURLSearchParams() {
  const url = new URL(globalThis.location.href)
  url.searchParams.delete('toLocation')

  globalThis.history.replaceState(null, '', url.toString())
}

export function getShipperUUIDFromLocalStorage() {
  const currentShipperUUID = globalThis.localStorage.getItem(
    LOCATION_MANAGEMENT_STORAGE_KEY
  )
  const overrideShipperUUID = getShipperUUIDFromURL()

  if (overrideShipperUUID && overrideShipperUUID !== currentShipperUUID) {
    setShipperUUIDToLocalStorage(overrideShipperUUID)
    clearURLSearchParams()
  }

  if (overrideShipperUUID && overrideShipperUUID === currentShipperUUID) {
    clearURLSearchParams()
  }

  //Using getItem here instead of currentShipperUUID to get updated value
  return globalThis.localStorage.getItem(LOCATION_MANAGEMENT_STORAGE_KEY)
}

export function setShipperUUIDToLocalStorage(locationUUID: string) {
  /**
   * We need both the localStorage.setItem and the CustomEvent
   * because we want to notify both UserContext about this change and
   * another tabs as well. The only way to notify others are with a "storage event",
   * fired in ever localStorage change, and the way to notify the current one are using a CustomEvent.
   *
   * Per spec, "storage" events cannot be listened in the same tab that fired it.
   *
   * So the "storage" event will only notify other tabs and the CustomEvent
   * only the current tab.
   */
  globalThis.localStorage.setItem(LOCATION_MANAGEMENT_STORAGE_KEY, locationUUID)
  globalThis.dispatchEvent(new CustomEvent(LOCATION_MANAGEMENT_STORAGE_EVENT))
}

export function removeShipperUUIDFromLocalStorage() {
  globalThis.localStorage.removeItem(LOCATION_MANAGEMENT_STORAGE_KEY)
}

function getCurrentMatchPath(currentPath: string, paths: string[]) {
  return matchPath(currentPath, {
    path: paths,
    exact: true,
  })
}

export function shouldRedirectToList(currentPath: string) {
  if (getCurrentMatchPath(currentPath, RELOAD_PATHS)) {
    return false
  }

  const match = getCurrentMatchPath(currentPath, REDIRECT_PATHS_LIST)

  return match !== null
}

export function redirectOrReload(currentPath: string) {
  const isAPathThatDoesNotRequireAReload = Boolean(
    getCurrentMatchPath(currentPath, NO_RELOAD_REQUIRED_PATHS)
  )
  if (isAPathThatDoesNotRequireAReload) {
    return
  }

  if (shouldRedirectToList(currentPath)) {
    const match = getCurrentMatchPath(currentPath, REDIRECT_PATHS_LIST)
    if (match !== null) {
      globalThis.location.href = REDIRECT_PATHS_MAP[match.path]
    }
  } else {
    globalThis.location.reload()
  }
}

export function changeLocation(currentPath: string, locationUUID: string) {
  setShipperUUIDToLocalStorage(locationUUID)
}

export const getDefaultLocationUUID = (locationDict: ShipperLocationDict) => {
  for (const uuid in locationDict) {
    if (locationDict[uuid]?.default) {
      return uuid
    }
  }

  return null
}

export const getDefaultLocation = (
  locationDict: ShipperLocationDict
): ShipperLocation | null => {
  const uuid = getDefaultLocationUUID(locationDict)

  if (!uuid || !locationDict[uuid]) {
    return null
  }

  return {
    uuid,
    locationName: locationDict[uuid].location_name,
  }
}

export const parseLocationList = (
  locationDict: ShipperLocationDict
): ShipperLocation[] => {
  const result = []

  for (const uuid in locationDict) {
    const location = {
      uuid,
      locationName: locationDict[uuid]?.location_name ?? '',
    }

    result.push(location)
  }

  return result
}
