import type { FieldProps } from '@loadsmart/miranda-react'
import type { ReactNode } from 'react'

export type useCustomFormFieldProps = {
  name: string
  hint?: ReactNode
  error?: string
  index?: number
  controlProps?: Record<string, unknown>
  fieldProps?: Record<string, unknown>
}

export type useCustomFormFieldResult = {
  name: string
  controlProps: {
    [key: string]: unknown
    disabled?: boolean
    'aria-labelledby': string
    name: string
  }
  fieldProps: Pick<FieldProps, 'status' | 'required'>
  hintProps: {
    hidden: boolean
    children: ReactNode | undefined
    'data-testid': string
  }
  labelProps: {
    id: string
  }
}

export function useCustomFormField(
  props: useCustomFormFieldProps
): useCustomFormFieldResult {
  const { name, hint, error, index, controlProps, fieldProps } = props

  const labelId =
    index == null ? `label-for-${name}` : `label-for-${name}-${index}`
  const hintDataTestId =
    index == null ? `hint-for-${name}` : `hint-for-${name}-${index}`
  const status: FieldProps['status'] = error ? 'danger' : 'default'

  return {
    name,
    fieldProps: { ...fieldProps, status },
    hintProps: {
      hidden: !error && !hint,
      children: error ?? hint,
      'data-testid': hintDataTestId,
    },
    labelProps: {
      id: labelId,
    },
    controlProps: {
      ...controlProps,
      'aria-labelledby': labelId,
      name,
    },
  }
}
