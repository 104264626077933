import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { appRoutes } from 'router/app-routes'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

export function useCreateShipmentLink(fulfillment: ListFulfillment) {
  const history = useHistory()

  const params = {
    fulfillments: fulfillment.uuid,
    stop_type_0: 'pickup',
    stop_facility_0: fulfillment.pickup_facility.uuid,
    stop_type_1: 'delivery',
    stop_facility_1: fulfillment.delivery_facility.uuid,
  }

  const link = generatePath(
    `${appRoutes.newShipment}?${new URLSearchParams(params).toString()}`
  )

  const onClick = useCallback(() => {
    analytics.track('Action: Create Shipment', AnalyticsEventTrigger.click, {
      entry_point: 'Fulfillments',
    })
    history.push(link)
  }, [history, link])

  return { link, onClick }
}
