import { Select } from '@loadsmart/loadsmart-ui'
import type { GenericOption } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import {
  Checkbox,
  Drawer,
  Field,
  Icon,
  Layout,
  ToggleGroup,
  Tooltip,
} from '@loadsmart/miranda-react'
import { defaultTo, noop } from 'lodash-es'
import type { PropsWithChildren } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { SelectedItemsTags } from 'fulfillments/components/Filters.components'
import type {
  Fulfillment,
  FulfillmentCreator,
} from 'fulfillments/domain/Fulfillment'
import { FULFILLMENT_STATUS_LABEL } from 'fulfillments/domain/Fulfillment'
import type {
  GetFulfillmentsFilters,
  FilterValues,
} from 'fulfillments/hooks/useFulfillmentList'
import { getDefaultFulfillmentsFiltersParams } from 'fulfillments/hooks/useFulfillmentList'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'

const STATUSES = Object.entries(FULFILLMENT_STATUS_LABEL).map(
  ([value, label]) => ({
    value: value as Fulfillment['status'],
    label,
  })
)

type FiltersDrawerContentProps = Readonly<{
  clearFilters: () => void
  closeFiltersDrawer: () => void
  filters: FilterValues
  setFilters: (newFilters: FilterValues) => void
  view: FulfillmentCreator
}>

export function FiltersDrawerContent({
  clearFilters,
  closeFiltersDrawer,
  filters: currentFilters,
  setFilters: applyFilters,
  view,
}: FiltersDrawerContentProps) {
  const [filters, setFilters] = useState<GetFulfillmentsFilters>(
    getDefaultFulfillmentsFiltersParams(currentFilters)
  )

  const unselectItem = useCallback(
    (field: keyof GetFulfillmentsFilters, itemIndex: number) => {
      if (!Array.isArray(filters[field])) {
        return
      }

      const updatedField = filters[field].filter(
        (_: any, valIndex: number) => valIndex !== itemIndex
      )
      setFilters((prevFormData: any) => ({
        ...prevFormData,
        [field]: updatedField,
      }))
    },
    [filters, setFilters]
  )

  const handleStatusChange = useCallback(
    (
      event:
        | EventLike<Selectable | Selectable[] | null>
        | EventLike<[string | null, string | null] | null>
    ) => {
      const { value } = event.target

      if (Array.isArray(value)) {
        const values = value.map((item) =>
          String((item as GenericOption).value)
        )

        setFilters((prevFormData) => ({
          ...prevFormData,
          status: defaultTo(values, null),
        }))
      }
    },
    [setFilters]
  )

  const handleApplyClick = useCallback(() => {
    applyFilters(filters)
    analytics.track('Action: Apply Filters', AnalyticsEventTrigger.click, {
      status: filters.status,
      source: filters.source,
      created_by: filters.created_by,
    })

    closeFiltersDrawer()
  }, [applyFilters, closeFiltersDrawer, filters])

  const handleClearFiltersClick = useCallback(() => {
    setFilters(getDefaultFulfillmentsFiltersParams())
    clearFilters()
  }, [clearFilters, setFilters])

  return (
    <Drawer
      onClose={closeFiltersDrawer}
      open
      size="medium"
      data-testid="filters"
    >
      <Drawer.Header>Apply Filters</Drawer.Header>
      <Drawer.Body>
        <Layout.Stack>
          <Layout.Stack gap="spacing-2">
            <Field>
              <Field.Label id="status">Status</Field.Label>
              <Select
                multiple
                options={STATUSES}
                name="statuses"
                id="statuses"
                placeholder="Select status"
                onChange={handleStatusChange}
                value={
                  filters.status?.map((value) => ({
                    lable:
                      FULFILLMENT_STATUS_LABEL[value as Fulfillment['status']],
                    value,
                  })) as Selectable[]
                }
              />
              <SelectedItemsTags
                items={defaultTo(filters.status, [])}
                getItemKey={(item) => item}
                onRemoveItem={(index) => unselectItem('status', index)}
                getLabel={(item) =>
                  FULFILLMENT_STATUS_LABEL[item as Fulfillment['status']]
                }
              />
            </Field>

            {view === 'customer' && (
              <Layout.Group align="center">
                <Field>
                  <Field.Label>
                    Creation method
                    <Tooltip
                      placement="top"
                      message={
                        <p style={{ margin: 0 }}>
                          Filter fulfillments by creation method:
                          <ul
                            style={{ listStylePosition: 'inside', padding: 0 }}
                          >
                            <li>All - show all fulfillments</li>
                            <li>Manual - created by a user</li>
                            <li>Automatic - generated from orders</li>
                          </ul>
                        </p>
                      }
                    >
                      <Icon name="question" />
                    </Tooltip>
                  </Field.Label>
                  <ToggleGroup
                    type="single-strict"
                    id="source"
                    name="source"
                    value={filters.source}
                    options={[
                      { label: 'All', value: 'all' },
                      { label: 'Manual', value: 'manual' },
                      { label: 'Automatic', value: 'auto-fulfilled' },
                    ]}
                    onChange={(event) => {
                      const { value } = event.target
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        source: value === 'all' ? undefined : value,
                        created_by: undefined,
                      }))
                    }}
                  />
                </Field>

                <Field>
                  <Field.Label role="presentation" />
                  <Checkbox
                    name="created_by_supplier"
                    id="created_by_supplier"
                    disabled={filters.source === 'auto-fulfilled'}
                    checked={filters.created_by === 'supplier'}
                    onChange={(event) => {
                      setFilters((prevFilters) => ({
                        ...prevFilters,
                        created_by: event.target.checked
                          ? 'supplier'
                          : undefined,
                      }))
                    }}
                  >
                    Created by supplier
                  </Checkbox>
                </Field>
              </Layout.Group>
            )}
          </Layout.Stack>
        </Layout.Stack>
      </Drawer.Body>
      <Drawer.Actions>
        <Layout.Group justify="space-between" style={{ width: '100%' }}>
          <Layout.Group gap="spacing-4">
            <Drawer.ActionPrimary
              data-testid="apply-filters"
              onClick={handleApplyClick}
              variant="primary"
              type="button"
            >
              Apply Filters
            </Drawer.ActionPrimary>
            <Drawer.ActionSecondary type="button" onClick={closeFiltersDrawer}>
              Close
            </Drawer.ActionSecondary>
          </Layout.Group>
          <Drawer.ActionSecondary
            data-testid="clear-filters"
            onClick={handleClearFiltersClick}
            type="button"
            variant="tertiary"
          >
            Clear Filters
          </Drawer.ActionSecondary>
        </Layout.Group>
      </Drawer.Actions>
    </Drawer>
  )
}

export type FiltersDrawerProps = Readonly<{
  clearFilters: () => void
  closeFiltersDrawer: () => void
  filters: FilterValues
  isFiltersDrawerOpen: boolean
  setFilters: (filters: FilterValues) => void
  view: FulfillmentCreator
}>

export function FiltersDrawer({
  clearFilters,
  closeFiltersDrawer,
  filters,
  isFiltersDrawerOpen,
  setFilters,
  view,
}: FiltersDrawerProps) {
  if (!isFiltersDrawerOpen) {
    return null
  }
  return (
    <FiltersDrawerContent
      clearFilters={clearFilters}
      closeFiltersDrawer={closeFiltersDrawer}
      filters={filters}
      setFilters={setFilters}
      view={view}
    />
  )
}

interface ContextValue {
  closeFilters: () => void
  openFilters: () => void
  view?: FulfillmentCreator
}

const Context = createContext<ContextValue>({
  closeFilters: noop,
  openFilters: noop,
  view: undefined,
})

export function useFulfillmentsFiltersContext() {
  return useContext(Context)
}

export interface FulfillmentsFiltersProviderProps extends PropsWithChildren {
  readonly clearFilters: () => void
  readonly filters: FilterValues
  readonly setFilters: (newFilters: FilterValues) => void
  readonly view: FulfillmentCreator
}

export function FulfillmentsFiltersProvider({
  children,
  clearFilters,
  filters,
  setFilters,
  view,
}: FulfillmentsFiltersProviderProps) {
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false)
  const openFilters = useCallback(() => {
    setIsFiltersDrawerOpen(true)
  }, [setIsFiltersDrawerOpen])
  const closeFilters = useCallback(() => {
    setIsFiltersDrawerOpen(false)
  }, [setIsFiltersDrawerOpen])

  const contextValue = useMemo(
    () => ({ closeFilters, openFilters, view }),
    [closeFilters, openFilters, view]
  )

  return (
    <Context.Provider value={contextValue}>
      {children}

      <FiltersDrawer
        clearFilters={clearFilters}
        closeFiltersDrawer={closeFilters}
        filters={filters}
        isFiltersDrawerOpen={isFiltersDrawerOpen}
        setFilters={setFilters}
        view={view}
      />
    </Context.Provider>
  )
}
