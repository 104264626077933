import { useSuppliersOptions } from 'fulfillments/hooks/useSuppliersOptions'

import { FulfillmentSelect } from './FulfillmentSelect'

export function FulfillmentSupplier({
  required,
}: {
  readonly required?: boolean
}) {
  const { data, isLoading } = useSuppliersOptions()

  return (
    <FulfillmentSelect
      name="supplier_uuid"
      label="Supplier"
      required={required}
      placeholder="Select supplier"
      loading={isLoading}
      options={data}
    />
  )
}
