import { Button, Layout, Tooltip } from '@loadsmart/miranda-react'
import { defaultTo } from 'lodash-es'
import { useCallback } from 'react'
import type { Column } from 'react-table'

import { Table } from 'components/TableV4'
import type { TableSelectedRowsIDs } from 'components/TableV4/Table.utils'
import { OneClickPlanAsShipmentButton } from 'fulfillments/1click-plan/OneClickPlanFulfillment.components'
import OneClickPlanFulfillmentProvider from 'fulfillments/1click-plan/OneClickPlanFulfillment.provider'
import {
  FulfillmentsFiltersProvider,
  useFulfillmentsFiltersContext,
} from 'fulfillments/components/Filters'
import { AppliedFiltersBar } from 'fulfillments/components/Filters.components'
import { getHasAppliedFilters } from 'fulfillments/components/Filters.utils'
import { DeleteFulfillmentProvider } from 'fulfillments/delete/DeleteFulfillment'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { canCreateShipment } from 'fulfillments/domain/Fulfillment'
import { useGoToFulfillmentDetails } from 'fulfillments/hooks/navigation'
import { useFulfillmentList } from 'fulfillments/hooks/useFulfillmentList'
import { useSelectedFulfillments } from 'fulfillments/hooks/useSelectedFulfillments'
import useLinkToFulfillmentsPlan from 'fulfillments/plan/hooks/useLinkToFulfillmentsPlan'

import { COMMON_COLUMNS } from './components/ListFulfillmentsColumns'
import {
  CustomerActionsCell,
  NotesCell,
  SupplierCell,
} from './components/ListFulfillmentsTableCells'
import {
  ListFulfillmentsTableEmptyState,
  ListFulfillmentsTableEmptyStateWithFilters,
} from './components/ListFulfillmentsTableEmptyState'

export function PlanShipmentButton({
  selectedFulfillments,
}: Readonly<{ selectedFulfillments: ListFulfillment[] }>) {
  const disabled = !canCreateShipment(selectedFulfillments)
  const UUIDs = selectedFulfillments.map((fulfillment) => fulfillment.uuid)

  const onClick = useLinkToFulfillmentsPlan(UUIDs)

  if (!UUIDs.length) {
    return null
  }

  const button = (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="secondary"
      data-testid="button-create-new-shipment"
    >
      Plan shipment
    </Button>
  )

  if (disabled) {
    return (
      <Tooltip message="Planned fulfillments were selected and cannot be used to create a new shipment.">
        {button}
      </Tooltip>
    )
  }

  return button
}

const CUSTOMER_COLUMNS: ReadonlyArray<Column<ListFulfillment>> = [
  ...COMMON_COLUMNS,
  {
    Header: 'Supplier',
    id: 'supplier',
    Cell: SupplierCell,
  },
  {
    Header: 'Notes',
    id: 'notes',
    Cell: NotesCell,
  },
  {
    Header: '',
    id: 'row_actions',
    Cell: CustomerActionsCell,
    width: '50px',
  },
]

type FulfillmentsTableProps = ReturnType<typeof useFulfillmentList> & {
  columns: ReadonlyArray<Column<ListFulfillment>>
  selectedFulfillmentUUIDs: string[]
  selectedFulfillments: ListFulfillment[]
  setSelectedFulfillments: (newSelected: string[]) => void
}

function FulfillmentsTable({
  columns,
  currentPage,
  data,
  filters,
  setFilters,
  clearFilters,
  isLoading,
  onPageChange,
  onSearchChange,
  pageSize,
  selectedFulfillmentUUIDs,
  selectedFulfillments,
  setSelectedFulfillments,
}: Readonly<FulfillmentsTableProps>) {
  const goToFulfillmentDetails = useGoToFulfillmentDetails()
  const { openFilters } = useFulfillmentsFiltersContext()

  const onRowClick = useCallback(
    (fulfillment: ListFulfillment) => {
      goToFulfillmentDetails(fulfillment.uuid)
    },
    [goToFulfillmentDetails]
  )

  const onSelectionChange = useCallback(
    (selection: TableSelectedRowsIDs) => {
      if (selection !== 'all') {
        setSelectedFulfillments(selection)
      }
    },
    [setSelectedFulfillments]
  )

  const hasAppliedFilters = getHasAppliedFilters(filters)

  if (!isLoading && !hasAppliedFilters && !data?.results?.length) {
    return <ListFulfillmentsTableEmptyState />
  }

  return (
    <Table<ListFulfillment>
      tableStyle={{ height: '100%' }}
      appliedFiltersBar={
        <AppliedFiltersBar
          clearFilters={clearFilters}
          setFilters={setFilters}
          filters={filters}
        />
      }
      bulkActions={
        <Layout.Group>
          <PlanShipmentButton selectedFulfillments={selectedFulfillments} />
          <OneClickPlanAsShipmentButton
            selectedFulfillments={selectedFulfillments}
          />
        </Layout.Group>
      }
      columns={columns}
      count={data?.results?.length}
      data={defaultTo(data?.results, [])}
      emptyState={<ListFulfillmentsTableEmptyStateWithFilters />}
      isLoading={!!isLoading}
      onOpenFilters={openFilters}
      onPageChange={onPageChange}
      onRowClick={onRowClick}
      onSearchInputChange={onSearchChange}
      onSelectionChange={onSelectionChange}
      page={currentPage}
      pageSize={pageSize}
      searchFilter={defaultTo(filters.search, '')}
      selectable
      selectedRows={selectedFulfillmentUUIDs}
      showResultsCount={false}
      totalCount={data?.count}
      uniqueIDfield="uuid"
    />
  )
}

export function ListFulfillmentsAsCustomer() {
  const asCustomerList = useFulfillmentList()
  const { filters, clearFilters, setFilters } = asCustomerList
  const {
    selectedFulfillmentUUIDs,
    selectedFulfillments,
    setSelectedFulfillments,
  } = useSelectedFulfillments()

  return (
    <DeleteFulfillmentProvider callback={asCustomerList.refetch}>
      <OneClickPlanFulfillmentProvider>
        <FulfillmentsFiltersProvider
          view="customer"
          clearFilters={clearFilters}
          filters={filters}
          setFilters={setFilters}
        >
          <FulfillmentsTable
            {...asCustomerList}
            columns={CUSTOMER_COLUMNS}
            selectedFulfillmentUUIDs={selectedFulfillmentUUIDs}
            selectedFulfillments={selectedFulfillments}
            setSelectedFulfillments={setSelectedFulfillments}
          />
        </FulfillmentsFiltersProvider>
      </OneClickPlanFulfillmentProvider>
    </DeleteFulfillmentProvider>
  )
}
