import { defaultTo } from 'lodash-es'

import { DEFAULT_LIMIT, DEFAULT_OFFSET } from './order-service'
import type { ListOrdersParams } from './order-service'

export function getListOrdersParams(
  params: Partial<ListOrdersParams> = {}
): ListOrdersParams {
  return {
    view: defaultTo(params.view, 'customer'),
    limit: defaultTo(params.limit, DEFAULT_LIMIT),
    offset: defaultTo(params.offset, DEFAULT_OFFSET),
    search_term: defaultTo(params.search_term, null),
    delivery_date_after: defaultTo(params.delivery_date_after, null),
    delivery_date_before: defaultTo(params.delivery_date_before, null),
    delivery_location: defaultTo(params.delivery_location, null),
    pickup_date_after: defaultTo(params.pickup_date_after, null),
    pickup_date_before: defaultTo(params.pickup_date_before, null),
    pickup_location: defaultTo(params.pickup_location, null),
    package_count: defaultTo(params.package_count, null),
    package_count__lt: defaultTo(params.package_count__lt, null),
    package_count__lte: defaultTo(params.package_count__lte, null),
    package_count__gt: defaultTo(params.package_count__gt, null),
    package_count__gte: defaultTo(params.package_count__gte, null),
    status: defaultTo(params.status, null),
    total_volume: defaultTo(params.total_volume, null),
    total_volume__lt: defaultTo(params.total_volume__lt, null),
    total_volume__lte: defaultTo(params.total_volume__lte, null),
    total_volume__gt: defaultTo(params.total_volume__gt, null),
    total_volume__gte: defaultTo(params.total_volume__gte, null),
    total_weight: defaultTo(params.total_weight, null),
    total_weight__lt: defaultTo(params.total_weight__lt, null),
    total_weight__lte: defaultTo(params.total_weight__lte, null),
    total_weight__gt: defaultTo(params.total_weight__gt, null),
    total_weight__gte: defaultTo(params.total_weight__gte, null),
    uuid: defaultTo(params.uuid, null),
  }
}
