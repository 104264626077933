import { Card, LabeledValue, Layout, Tag, Text } from '@loadsmart/miranda-react'
import { defaultTo } from 'lodash-es'
import styled from 'styled-components'

import { OrderItemCountryOfOrigin } from 'orders/components/OrderItemCountryOfOrigin'
import { OrderItemTags } from 'orders/components/OrderItemTags'
import {
  formatOrderDensity,
  formatOrderItemDescription,
  formatOrderItemDimensions,
  formatOrderItemMaxTemperature,
  formatOrderItemWeight,
  formatOrderVolume,
  formatOrderWeight,
} from 'orders/orders.utils'
import { defaultEmptyString } from 'utils/strings'

import { useViewOrderContext } from '../ViewOrderContext'

const OuterCard = styled(Card)`
  --m-card-body-padding: 0;
`

const InnerCard = styled(Card)`
  --m-card-border-left: none;
  --m-card-border-right: none;
  --m-card-border-bottom: none;
  width: 100%;
`

export function OrderItemsCard() {
  const { order } = useViewOrderContext()
  const items = defaultTo(order?.items, [])

  if (!order || !items) {
    return null
  }

  const shouldCollapseItems = items.length > 5

  return (
    <OuterCard data-testid="order-items-card">
      <Card.Title>
        <Layout.Group align="center" gap="spacing-2">
          Order items
          <Tag variant="neutral" size="small">
            {items.length}
          </Tag>
        </Layout.Group>
      </Card.Title>
      <Card.Subtitle>
        <Layout.Group align="center" gap="spacing-2">
          <Layout.Group
            data-testid="order-total-weight"
            align="center"
            gap="spacing-1"
          >
            <Text color="color-text-tertiary">Total weight</Text>
            <Text>{formatOrderWeight(order)}</Text>
          </Layout.Group>

          <Layout.Group
            data-testid="order-total-volume"
            align="center"
            gap="spacing-1"
          >
            <Text color="color-text-tertiary">Cube</Text>
            <Text>{formatOrderVolume(order)}</Text>
          </Layout.Group>

          <Layout.Group
            data-testid="order-total-density"
            align="center"
            gap="spacing-1"
          >
            <Text color="color-text-tertiary">Density</Text>
            <Text>{formatOrderDensity(order)}</Text>
          </Layout.Group>
        </Layout.Group>
      </Card.Subtitle>
      <Card.Divider />
      <Card.Body>
        {items.map((item) => (
          <InnerCard
            collapsible
            collapsed={shouldCollapseItems}
            key={item.uuid}
          >
            <InnerCard.Title>
              <Layout.Stack>
                <Layout.Group>
                  <OrderItemTags item={item} />
                </Layout.Group>
                {formatOrderItemDescription(item)}
              </Layout.Stack>
            </InnerCard.Title>
            <InnerCard.Divider />
            <InnerCard.Body>
              <Layout.Stack>
                <Layout.Grid>
                  <LabeledValue
                    data-testid="order-item-item-number"
                    label="Item number"
                    value={defaultEmptyString(item.item_number)}
                  />
                  <LabeledValue
                    data-testid="order-item-item-description"
                    label="Item description"
                    value={defaultEmptyString(item.item_description)}
                  />
                  <LabeledValue
                    data-testid="order-item-line-number"
                    label="Line number"
                    value={defaultTo(item.line_number, '-')}
                  />
                  <LabeledValue
                    data-testid="order-item-country-of-origin"
                    label="Country of origin"
                    value={<OrderItemCountryOfOrigin item={item} />}
                  />
                  <LabeledValue
                    data-testid="order-item-total-weight"
                    label="Total weight"
                    value={formatOrderItemWeight(item)}
                  />
                  <LabeledValue
                    data-testid="order-item-dimensions"
                    label="Dimensions"
                    value={formatOrderItemDimensions(item, true)}
                  />
                  <LabeledValue
                    data-testid="order-item-upc"
                    label="UPC"
                    value={defaultEmptyString(item.upc)}
                  />
                  <LabeledValue
                    data-testid="order-item-max-temperature"
                    label="Max temperature"
                    value={formatOrderItemMaxTemperature(item)}
                  />
                  <LabeledValue
                    data-testid="order-item-nmfc"
                    label="NMFC"
                    value={defaultEmptyString(item.nmfc_code)}
                  />
                  <LabeledValue
                    data-testid="order-item-freight-class"
                    label="Freight class"
                    value={defaultEmptyString(item.freight_class)}
                  />
                </Layout.Grid>
              </Layout.Stack>
            </InnerCard.Body>
          </InnerCard>
        ))}
      </Card.Body>
    </OuterCard>
  )
}
