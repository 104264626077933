import type { AxiosRequestConfig } from 'axios'
import { defaultTo } from 'lodash-es'

import type { Commodity } from 'components/HandlingUnitsManager/HandlingUnits.types'
import type { Supplier } from 'suppliers/types'
import httpClient from 'utils/httpClient'
import { createSearchParams } from 'utils/searchParams'

import type { Customer } from './domain/Customer'
import type {
  Fulfillment,
  FulfillmentCreator,
  FulfillmentPayload,
  FulfillmentStatus,
  FulfillmentWithoutOrderPayload,
  ListFulfillment,
} from './domain/Fulfillment'

export async function getFulfillmentDetails(
  id: string,
  config?: AxiosRequestConfig
): Promise<Fulfillment> {
  const { data } = await httpClient.get(`/orders/fulfillments/${id}`, config)
  return data
}

export async function getSuppliers(
  config?: AxiosRequestConfig
): Promise<Supplier[]> {
  const { data } = await httpClient.get(`/shippers/suppliers/list`, config)
  return data
}

export async function getCustomers(
  config?: AxiosRequestConfig
): Promise<Customer[]> {
  const { data } = await httpClient.get(`/shippers/customers/list`, config)
  return data
}

export type CreateFulfillmentParams =
  | {
      payload: FulfillmentWithoutOrderPayload
      withOrders: false
      config?: AxiosRequestConfig
    }
  | {
      payload: FulfillmentPayload
      withOrders: true
      config?: AxiosRequestConfig
    }
export function createFulfillment({
  payload,
  withOrders,
  config,
}: CreateFulfillmentParams) {
  if (withOrders) {
    return httpClient.post<{ uuid: string }>(
      '/orders/fulfillments',
      payload,
      config
    )
  }

  return httpClient.post<{ uuid: string }>(
    '/orders/fulfillments/without-order',
    payload,
    config
  )
}

export const DEFAULT_PAGE_SIZE = 10
export const MAX_LIMIT = 500
export const DEFAULT_PAGE = 1

type ListFulfillmentsParams = {
  page_size: number
  page: number
  source?: 'manual' | 'auto-fulfilled' | null
  uuid?: string | null
  status?: FulfillmentStatus[] | null
  created_by?: FulfillmentCreator | null
}

export function getListFulfillmentsParams(
  params: Partial<ListFulfillmentsParams> = {}
): ListFulfillmentsParams {
  return {
    page_size: defaultTo(params.page_size, DEFAULT_PAGE_SIZE),
    page: defaultTo(params.page, DEFAULT_PAGE),
    source: defaultTo(params.source, null),
    uuid: defaultTo(params.uuid, null),
    status: defaultTo(params.status, null),
    created_by: defaultTo(params.created_by, null),
  }
}

export async function getFulfillmentsAsCustomer(
  params: ListFulfillmentsParams,
  config?: Omit<AxiosRequestConfig, 'params'>
): Promise<Omit<PaginatedResult<ListFulfillment>, 'total_count'>> {
  const { data } = await httpClient.get('/orders/fulfillments-as-customer', {
    ...config,
    params: createSearchParams(params),
  })
  return data
}

export async function getFulfillmentsAsSupplier(
  params: ListFulfillmentsParams,
  config?: Omit<AxiosRequestConfig, 'params'>
): Promise<Omit<PaginatedResult<ListFulfillment>, 'total_count'>> {
  const { data } = await httpClient.get('/orders/fulfillments-as-supplier', {
    ...config,
    params: createSearchParams(params),
  })
  return data
}

export type PutFulfillmentPayload = {
  delivery_contact_uuid?: string | null
  delivery_date?: string | null
  delivery_facility_uuid?: string
  delivery_instructions?: string
  equipment_type?: string | null
  handling_units: {
    uuid: string
    commodities: {
      order_item_uuid: string
      shipped_count: number
      shipped_weight: number
      shipped_nmfc_code: Commodity['nmfc_code']
      shipped_freight_class: Commodity['freight_class']
    }[]
    package_count?: number
    package_type?: string
    package_length?: number | null
    package_width?: number | null
    package_height?: number | null
    stackable?: boolean
    turnable?: boolean
  }[]
  notes?: string
  pickup_contact_uuid?: string | null
  pickup_facility_uuid?: string
  pickup_instructions?: string
  pickup_number?: string
  pickup_ready_date?: string | null
}

export type PutFulfillmentParams = {
  uuid: string
  payload: PutFulfillmentPayload
}

export function putFulfillment(
  { uuid, payload }: PutFulfillmentParams,
  config?: AxiosRequestConfig
) {
  return httpClient.put<Fulfillment>(
    `/orders/fulfillments/${uuid}`,
    payload,
    config
  )
}

export async function deleteFulfillment(UUID: string) {
  return await httpClient.delete(`orders/fulfillments/${UUID}`)
}
