import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import type { FreightInformationSummaryFieldProps } from './FreightInformationSummaryField'
import { FreightInformationSummaryField } from './FreightInformationSummaryField'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const BOL_NUMBER_NAME = 'bol_number'
const BOL_NUMBER_LABEL = 'BOL number'

type FreightInformationBOLNumberSummaryFieldProps = Omit<
  FreightInformationSummaryFieldProps,
  'label' | 'name'
>

export function FreightInformationBOLNumberSummaryField(
  props: FreightInformationBOLNumberSummaryFieldProps
) {
  return (
    <FreightInformationSummaryField
      label={BOL_NUMBER_LABEL}
      name={BOL_NUMBER_NAME}
      {...props}
    />
  )
}

type FreightInformationBOLNumberFormFieldProps = {
  readonly required?: FieldProps['required']
  readonly hint?: string
  readonly error?: string
  readonly style?: CSSProperties
  readonly disabled?: boolean
}

export function FreightInformationBOLNumberFormField({
  required,
  hint,
  error,
  style,
  disabled,
}: FreightInformationBOLNumberFormFieldProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()

  const { fieldProps, hintProps } = useFreightInformationFormField({
    name: BOL_NUMBER_NAME,
    hint,
    error,
  })

  return (
    <Field
      {...fieldProps}
      required={required}
      style={style}
      data-testid={`${BOL_NUMBER_NAME}-field`}
    >
      <Field.Label id={`label-${BOL_NUMBER_NAME}`}>
        {BOL_NUMBER_LABEL}
      </Field.Label>
      <TextField
        aria-labelledby={`label-${BOL_NUMBER_NAME}`}
        placeholder="Enter BOL number"
        value={get(shipment, BOL_NUMBER_NAME, '')}
        disabled={disabled}
        onChange={(event) => {
          dispatch({
            type: 'HANDLE_CHANGE',
            payload: { [BOL_NUMBER_NAME]: get(event, 'target.value') },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
