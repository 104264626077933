import { Icon, Layout } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash-es'

import { getCountryMetadataByAbbreviation } from 'components/FacilityDetails'
import type { OrderItem } from 'orders/types'

export function OrderItemCountryOfOrigin({
  item,
}: Readonly<{ item: OrderItem }>) {
  if (isEmpty(item.country_of_origin)) {
    return '-'
  }

  const countryMetadata = getCountryMetadataByAbbreviation(
    item.country_of_origin
  )

  if (countryMetadata) {
    return (
      <Layout.Group align="center" gap="spacing-1" padding="none">
        <Icon name={countryMetadata.flagIconName} size="16" />
        {countryMetadata.name}
      </Layout.Group>
    )
  }

  return item.country_of_origin
}
