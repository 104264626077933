import type { FunctionComponent } from 'react'
import type * as Yup from 'yup'

import { useFeatureFlag } from '_shared_/feature-flag'
import { DrayageForm } from 'shipments/components/create/DrayageForm'
import { ExpediteFormV2 } from 'shipments/components/create/ExpediteFormV2'
import { LessThanTruckloadFormV2 } from 'shipments/components/create/LessThanTruckloadFormV2'
import { PartialTruckloadFormV2 } from 'shipments/components/create/PartialTruckloadFormV2'
import { TruckloadFormV2 } from 'shipments/components/create/TruckloadFormV2'
import type { NewShipmentPayload } from 'shipments/shipments.services'
import { MODES } from 'utils/constants'
import {
  areTransportationModesEqual,
  isTransportationModeOneOf,
} from 'utils/transportationMode'

import type { TransientShipment } from '../components/Shipment/Shipment.utils'
import {
  drayageValidationSchema,
  expValidationSchema,
  ltlValidationSchema,
  multimodalValidationSchema,
  ptlValidationSchema,
} from '../components/Shipment/Shipment.validationSchemas'
import {
  drayAdapter,
  expAdapter,
  ftlAdapter,
  imdlAdapter,
  ltlAdapter,
  ptlAdapter,
} from './create.adapters'
import ExpediteForm from './forms/Expedite'
import LessThanTruckloadForm from './forms/LessThanTruckload'
import PartialTruckloadForm from './forms/PartialTruckload'
import TruckloadForm from './forms/Truckload'

export interface CreateShipmentPageConfig {
  form: FunctionComponent
  validationSchema: Yup.ObjectSchema<any, any, any, any>
  adapter: (shipment: TransientShipment) => NewShipmentPayload
}

export const shipmentModeToPageConfigMap: Record<
  string,
  CreateShipmentPageConfig
> = {
  [MODES.FTL]: {
    form: TruckloadForm,
    validationSchema: multimodalValidationSchema,
    adapter: ftlAdapter,
  },
  [MODES.IMDL]: {
    form: TruckloadForm,
    validationSchema: multimodalValidationSchema,
    adapter: imdlAdapter,
  },
  [MODES.DRAY]: {
    form: DrayageForm,
    validationSchema: drayageValidationSchema,
    adapter: ftlAdapter,
  },
  [MODES.PTL]: {
    form: PartialTruckloadForm,
    validationSchema: ptlValidationSchema,
    adapter: ptlAdapter,
  },
  [MODES.LTL]: {
    form: LessThanTruckloadForm,
    validationSchema: ltlValidationSchema,
    adapter: ltlAdapter,
  },
  [MODES.VLTL]: {
    form: LessThanTruckloadForm,
    validationSchema: ltlValidationSchema,
    adapter: ltlAdapter,
  },
  [MODES.EXP]: {
    form: ExpediteForm,
    validationSchema: expValidationSchema,
    adapter: expAdapter,
  },
}

export const TLshipmentModeToPageConfigMapV2: Record<
  string,
  CreateShipmentPageConfig
> = {
  [MODES.FTL]: {
    form: TruckloadFormV2,
    validationSchema: multimodalValidationSchema,
    adapter: ftlAdapter,
  },
  [MODES.IMDL]: {
    form: TruckloadFormV2,
    validationSchema: multimodalValidationSchema,
    adapter: imdlAdapter,
  },
  [MODES.DRAY]: {
    form: DrayageForm,
    validationSchema: multimodalValidationSchema,
    adapter: drayAdapter,
  },
}

export function useShipmentModePageConfig(
  shipmentMode: string | undefined
): CreateShipmentPageConfig {
  const enableLTLStandaloneShippingItemsOnCreate = useFeatureFlag(
    'ENABLE_LTL_STANDALONE_SHIPPING_ITEMS_ON_CREATE'
  )
  const enablePTLStandaloneShippingItemsOnCreate = useFeatureFlag(
    'ENABLE_PTL_STANDALONE_SHIPPING_ITEMS_ON_CREATE'
  )
  const enableFTLStandaloneShippingItemsOnCreate = useFeatureFlag(
    'ENABLE_FTL_STANDALONE_SHIPPING_ITEMS_ON_CREATE'
  )
  const enableEXPStandaloneShippingItemsOnCreate = useFeatureFlag(
    'ENABLE_EXP_STANDALONE_SHIPPING_ITEMS_ON_CREATE'
  )

  if (!shipmentMode) {
    return shipmentModeToPageConfigMap[MODES.FTL]
  }

  const isLTLOrVLTL = isTransportationModeOneOf(shipmentMode, [
    MODES.LTL,
    MODES.VLTL,
  ])

  const isPTL = areTransportationModesEqual(shipmentMode, MODES.PTL)
  const isEXP = areTransportationModesEqual(shipmentMode, MODES.EXP)

  if (enableLTLStandaloneShippingItemsOnCreate && isLTLOrVLTL) {
    return {
      form: LessThanTruckloadFormV2,
      validationSchema: ltlValidationSchema,
      adapter: ltlAdapter,
    }
  }

  if (enablePTLStandaloneShippingItemsOnCreate && isPTL) {
    return {
      form: PartialTruckloadFormV2,
      validationSchema: ptlValidationSchema,
      adapter: ptlAdapter,
    }
  }

  if (enableEXPStandaloneShippingItemsOnCreate && isEXP) {
    return {
      form: ExpediteFormV2,
      validationSchema: expValidationSchema,
      adapter: expAdapter,
    }
  }

  if (enableFTLStandaloneShippingItemsOnCreate) {
    const config = TLshipmentModeToPageConfigMapV2[shipmentMode]
    if (config) {
      return config
    }
  }

  return shipmentModeToPageConfigMap[shipmentMode]
}
