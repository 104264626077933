import type { Order } from './types'

export const ALLOWED_STATUSES_FOR_CONSOLIDATION: Order['status'][] = [
  'open',
  'planning',
]

type StatusOptions = 'open' | 'planned' | 'planning' | 'closed' | 'deleted'
export const STATUS_SLUG_TO_LABEL: Record<StatusOptions, string> = {
  open: 'Open',
  planned: 'Planned',
  deleted: 'Deleted',
  planning: 'Planning',
  closed: 'Closed',
}

export const SEARCH_TERM_CHANGE_DEBOUNCE_MS =
  process.env.NODE_ENV === 'test' ? 1 : 1250
