import { Formik } from 'formik'
import type { FormikConfig, FormikState } from 'formik'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { REQUIRED_FIELD_MESSAGE } from 'constants/errors'
import { useModeOptions } from 'hooks/useModeOptions'
import { useCarriersOperationRegions } from 'hooks/useQuery'
import { useRfpEquipmentTypes } from 'rfp/hooks/rfp'
import {
  aliceCarrierDetails,
  create,
  partialUpdateCarrierDetails,
} from 'services/carriers'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { getAxiosErrorResponseData } from 'utils/errors'

import type { CarrierFormValue } from '../types'
import {
  getInitialValues,
  getInitialValuesFromAlice,
  formatCarrierPayload,
} from '../utils'
import Form from './CarrierForm'

const alternativeEmailsSchema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email address'),
})

const Schema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  dot: Yup.string().required(REQUIRED_FIELD_MESSAGE),
  mc: Yup.string().nullable(true),
  scac: Yup.string().nullable(true),
  entity: Yup.object().nullable(true),
  regions: Yup.string().nullable(true),
  email: Yup.string()
    .email('Enter a valid email address')
    .required(REQUIRED_FIELD_MESSAGE),
  phone: Yup.string().nullable(true),
  hq_address: Yup.string().nullable(true),
  req_safety_passed: Yup.string().nullable(true),
  cargo_on_file: Yup.string().nullable(true),
  bipd_on_file: Yup.string().nullable(true),
  notes: Yup.string().nullable(true),
  alternative_emails: Yup.array().of(alternativeEmailsSchema),
})

export type CarrierFormV1Props = {
  readonly cancelCallback: () => void
  readonly carrier?: Carrier | null
  readonly closeSideBar: () => void
  readonly onSuccess?: () => void
  readonly addNewCarrierSuccessTrackData?: Record<string, any>
}

export default function CarrierFormV1({
  carrier,
  cancelCallback,
  closeSideBar,
  onSuccess,
  addNewCarrierSuccessTrackData = {},
}: CarrierFormV1Props) {
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null)
  const [selectedModes, setSelectedModes] = useState<string[]>([])
  const [isLoadingAliceCarrier, setIsLoadingAliceCarrier] =
    useState<boolean>(false)
  const [hasErrorAliceCarrier, setHasErrorAliceCarrier] =
    useState<boolean>(false)
  const { mutateAsync: fetchAliceCarrier } = useMutation({
    mutationFn: aliceCarrierDetails,
  })
  const { mutateAsync: createCarrier } = useMutation({ mutationFn: create })
  const { mutateAsync: updateCarrier } = useMutation({
    mutationFn: partialUpdateCarrierDetails,
  })

  const { modeOptions } = useModeOptions({ includePTL: true, includeEXP: true })
  const { data: equipmentTypeOptions, isLoading: isLoadingEquipmentTypes } =
    useRfpEquipmentTypes(selectedModes?.length ? selectedModes : '')

  const {
    data: operationRegionsOptions,
    isLoading: isLoadingOperationRegions,
  } = useCarriersOperationRegions(false)

  const memoizedEquipmentTypes = useMemo(() => {
    if (isLoadingEquipmentTypes || !equipmentTypeOptions?.length) {
      return []
    }
    return equipmentTypeOptions.map(
      (item: { value: string; label: string }) => ({
        value: item.value,
        label: item.value,
      })
    )
  }, [isLoadingEquipmentTypes, equipmentTypeOptions])

  const memoizedOperationRegions = useMemo(() => {
    if (isLoadingOperationRegions || !operationRegionsOptions?.length) {
      return []
    }
    return operationRegionsOptions.map(
      (item: { value: string; label: string }) => ({
        value: item.value,
        label: item.label,
      })
    )
  }, [isLoadingOperationRegions, operationRegionsOptions])
  const queryClient = useQueryClient()

  const onModesChange = useCallback((modes: string[]) => {
    setSelectedModes(modes)
  }, [])

  const isLoading = isLoadingAliceCarrier || isLoadingOperationRegions

  const handleSubmit: FormikConfig<CarrierFormValue>['onSubmit'] = useCallback(
    async (values, actions) => {
      actions.setSubmitting(true)
      try {
        const payload = formatCarrierPayload(values, {
          selectedUuid,
          modeOptions,
          equipmentTypeOptions: memoizedEquipmentTypes,
          operationRegionOptions: memoizedOperationRegions,
        })

        if (carrier) {
          await updateCarrier({ id: carrier.id, carrier: payload })
          analytics.track(
            'Carrier Management / Edit Carrier Panel / Save & Close',
            AnalyticsEventTrigger.success,
            { carrier: payload }
          )
        } else {
          await createCarrier(payload)
          analytics.track(
            'Carrier Management / Add New Carrier Success',
            AnalyticsEventTrigger.success,
            {
              carrier: payload,
              ...addNewCarrierSuccessTrackData,
            }
          )
        }

        await queryClient.refetchQueries({ queryKey: ['retrieveCarriers'] })

        onSuccess?.()

        toast.success('Carrier successfully saved')
      } catch (error: unknown) {
        const errorData = getAxiosErrorResponseData<{
          alternative_emails: string[]
        }>(error)
        const fieldErrors: Record<string, string> = {}
        const fieldsTouched: Record<string, boolean> = {}

        toast.error(
          errorData?.alternative_emails[0] ?? 'Could not save carrier'
        )

        if (errorData) {
          Object.entries(errorData).forEach(([field, msg]) => {
            fieldErrors[field] = Array.isArray(msg) ? msg[0] : msg
            fieldsTouched[field] = true
          })
        }

        if (carrier) {
          analytics.track(
            'Carrier Management / Edit Carrier Panel / Save & Close',
            AnalyticsEventTrigger.error,
            { error: errorData }
          )
        } else {
          analytics.track(
            'Carrier Management / Add New Carrier Error',
            AnalyticsEventTrigger.error,
            { error: errorData }
          )
        }
        actions.setErrors(fieldErrors)
      } finally {
        actions.setSubmitting(false)
        setSelectedUuid(null)
        cancelCallback()
      }
    },
    [
      selectedUuid,
      modeOptions,
      memoizedEquipmentTypes,
      memoizedOperationRegions,
      carrier,
      onSuccess,
      updateCarrier,
      createCarrier,
      addNewCarrierSuccessTrackData,
      cancelCallback,
      queryClient,
    ]
  )

  const handleAliceCarrierSelection = useCallback(
    async (
      resetFn: (nextState?: Partial<FormikState<CarrierFormValue>>) => void,
      aliceCarrier: Carrier
    ) => {
      setHasErrorAliceCarrier(false)
      try {
        if (aliceCarrier.uuid) {
          setSelectedUuid(aliceCarrier.uuid)
          setIsLoadingAliceCarrier(true)
          const data = await fetchAliceCarrier(aliceCarrier.uuid)
          const newValues = getInitialValuesFromAlice(data)

          newValues.alternative_emails = [{ email: '', active: false }]

          resetFn({
            values: newValues,
            isSubmitting: false,
            isValidating: false,
          })
        }
      } catch {
        setHasErrorAliceCarrier(true)
      } finally {
        setIsLoadingAliceCarrier(false)
      }
    },
    [fetchAliceCarrier]
  )
  useEffect(() => {
    setSelectedUuid(null)
  }, [])

  return (
    <Formik<CarrierFormValue>
      initialValues={getInitialValues(carrier, {
        modeOptions,
        equipmentTypeOptions: memoizedEquipmentTypes,
        operationRegionOptions: memoizedOperationRegions,
      })}
      validationSchema={Schema}
      validate={async (values) => {
        const errors: Record<string, string> = {}

        if (Object.keys(errors).length > 0) {
          return errors
        }

        try {
          await Schema.validate(values, { abortEarly: false })
        } catch (err: any) {
          err.inner.forEach((error: Yup.ValidationError) => {
            errors[error.path!] = error.message
          })
        }
        return errors
      }}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        return (
          <Form
            {...formikProps}
            onModesChange={onModesChange}
            modeOptions={modeOptions}
            equipmentTypeOptions={memoizedEquipmentTypes}
            isLoadingEquipmentTypes={isLoadingEquipmentTypes}
            operationRegionOptions={memoizedOperationRegions}
            carrier={carrier}
            cancelCallback={cancelCallback}
            closeSideBar={closeSideBar}
            isLoading={isLoading}
            hasErrorAliceCarrier={hasErrorAliceCarrier}
            aliceCarrierSelectionCallback={handleAliceCarrierSelection}
          />
        )
      }}
    </Formik>
  )
}
