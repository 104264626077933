import { useMemo } from 'react'

import { useFeatureFlag } from '_shared_/feature-flag'
import { useSettings } from '_shared_/settings/useSettings'
import { fromModeToSelectOption } from 'utils/transportationMode'

export function useTransportationModes() {
  const {
    values: [isPTLShipmentEnabled],
  } = useSettings(['flags.ENABLE_PTL_SHIPMENT_CREATION'])

  const enableDrayageShipments = useFeatureFlag('ENABLE_DRAYAGE_SHIPMENTS')

  const modeOptions: SelectOption[] = useMemo(() => {
    return [
      fromModeToSelectOption('ftl')!,
      fromModeToSelectOption('ltl')!,
      ...(isPTLShipmentEnabled ? [fromModeToSelectOption('ptl')!] : []),
      fromModeToSelectOption('imdl')!,
      fromModeToSelectOption('exp')!,
      ...(enableDrayageShipments ? [fromModeToSelectOption('drayage')!] : []),
    ]
  }, [isPTLShipmentEnabled, enableDrayageShipments])

  return { modes: modeOptions }
}
