import type { ReactNode } from 'react'
import { createContext } from 'react'

import type { FrontendSettingsResponse } from './settings.services'
import { useSettingsRetrieve } from './useSettingsRetrieve'

export type SettingsProviderValue = {
  isLoading: boolean
} & FrontendSettingsResponse

const settingsContextDefaultValue: SettingsProviderValue = {
  settings: {},
  flags: {},
  isLoading: false,
}

export const settingsContext = createContext(settingsContextDefaultValue)

export function SettingsProvider({
  children,
}: {
  readonly children: ReactNode
}) {
  const { data, isLoading } = useSettingsRetrieve()
  const value = data
    ? {
        ...data,
        isLoading,
      }
    : {
        settings: {},
        flags: {},
        isLoading,
      }

  return (
    <settingsContext.Provider value={value}>
      {children}
    </settingsContext.Provider>
  )
}
