import { Button, Icon, Layout, Text, Tooltip } from '@loadsmart/miranda-react'
import { defaultTo, isEmpty } from 'lodash-es'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import type { CellProps } from 'react-table'

import TableActions from '_shared_/components/TableActions'
import { OneClickPlanAsShipmentAction } from 'fulfillments/1click-plan/OneClickPlanFulfillment.components'
import { useFulfillmentHistoryContext } from 'fulfillments/components/FulfillmentHistory'
import { FulfillmentStatusTag } from 'fulfillments/components/FulfillmentStatusTag'
import { DeleteFulfillmentAction } from 'fulfillments/delete/DeleteFulfillment.actions'
import type { ListFulfillment } from 'fulfillments/domain/Fulfillment'
import { canCreateShipment } from 'fulfillments/domain/Fulfillment'
import {
  formatFulfillmentDeliveryDate,
  formatFulfillmentHandlingUnitShippedWeight,
  formatFulfillmentHandlingUnitTitle,
  formatFulfillmentPickupReadyDate,
  formatFulfillmentStop,
  formatFulfillmentTotalPieceCount,
  formatFulfillmentWeight,
} from 'fulfillments/fulfillment-utils'
import { useCreateShipmentLink } from 'fulfillments/hooks/useCreateShipmentLink'
import useLinkToFulfillmentsPlan from 'fulfillments/plan/hooks/useLinkToFulfillmentsPlan'
import { appRoutes } from 'router/app-routes'
import { useIsCurrentShipperASupplier } from 'suppliers/common/useIsCurrentShipperASupplier'
import { defaultEmptyString } from 'utils/strings'

export function CustomerCell({ row }: CellProps<ListFulfillment>) {
  const customer = defaultEmptyString(row.original.customer?.name, '-')

  return <Text>{customer}</Text>
}

export function StatusCell({ row }: CellProps<ListFulfillment>) {
  const isSupplier = useIsCurrentShipperASupplier()
  const shipment = row.original['shipment_uuid']
  const fulfillment = row.original

  return (
    <Layout.Group align="center" gap="spacing-1">
      <FulfillmentStatusTag fulfillment={fulfillment} />

      {!isSupplier && !!shipment && !isEmpty(shipment) && (
        <Button
          href={generatePath(appRoutes.shipmentDetails, {
            id: shipment,
          })}
          size="small"
          title="Go to shipment"
          variant="icon-secondary"
        >
          <Icon name="arrow-corner-up" color="color-text-link" size="12px" />
        </Button>
      )}
    </Layout.Group>
  )
}

export function OrdersCell({ row }: CellProps<ListFulfillment>) {
  const orders = defaultTo(row.original.order_uuids, [])

  return <Text>{orders.length}</Text>
}

export function OriginCell({ row }: CellProps<ListFulfillment>) {
  return (
    <Layout.Stack gap="none">
      {formatFulfillmentStop(row.original.pickup_facility, false)}
      <Text variant="body-sm" color="color-text-tertiary">
        {formatFulfillmentPickupReadyDate(row.original)}
      </Text>
    </Layout.Stack>
  )
}

export function DestinationCell({ row }: CellProps<ListFulfillment>) {
  return (
    <Layout.Stack gap="none">
      {formatFulfillmentStop(row.original.delivery_facility, false)}
      <Text variant="body-sm" color="color-text-tertiary">
        {formatFulfillmentDeliveryDate(row.original)}
      </Text>
    </Layout.Stack>
  )
}

export function HandlingUnitsCell({ row }: CellProps<ListFulfillment>) {
  const handlingUnits = defaultTo(row.original.handling_units, [])

  if (handlingUnits.length === 0) {
    return '-'
  }

  if (handlingUnits.length === 1) {
    return (
      <Layout.Stack gap="none">
        {formatFulfillmentHandlingUnitTitle(handlingUnits[0])}
        <Text variant="body-sm" color="color-text-tertiary">
          {formatFulfillmentHandlingUnitShippedWeight(handlingUnits[0])}
        </Text>
      </Layout.Stack>
    )
  }

  return (
    <Layout.Stack gap="none">
      <Tooltip
        message={
          <Layout.Stack gap="none">
            {handlingUnits.map((item) => (
              <Text key={item.uuid} color="color-text-inverted">
                {formatFulfillmentHandlingUnitTitle(handlingUnits[0])}
              </Text>
            ))}
          </Layout.Stack>
        }
        placement="top"
        trigger="hover"
        data-testid="handling-unit-tooltip"
      >
        <Text color="color-text-link" variant="body-md-bold-underline">
          {formatFulfillmentTotalPieceCount(row.original)}
        </Text>
      </Tooltip>
      <Text variant="body-sm" color="color-text-tertiary">
        {formatFulfillmentWeight(row.original)}
      </Text>
    </Layout.Stack>
  )
}

export function SupplierCell({ row }: CellProps<ListFulfillment>) {
  const supplier = defaultEmptyString(row.original.supplier?.name, '-')

  return <Text>{supplier}</Text>
}

export function NotesCell({ row }: CellProps<ListFulfillment>) {
  const notes = defaultEmptyString(row.original.notes, '')

  if (isEmpty(notes)) {
    return null
  }

  return (
    <Tooltip
      data-testid="fulfillment-notes-tooltip"
      message={notes}
      trigger="hover"
      placement="top-start"
    >
      <Icon name="comment" />
    </Tooltip>
  )
}

export function OpenHistoryAction({
  fulfillment,
}: {
  readonly fulfillment: ListFulfillment
}) {
  const { openDrawer } = useFulfillmentHistoryContext()

  const onClick = useCallback(() => {
    openDrawer(fulfillment.uuid)
  }, [openDrawer, fulfillment.uuid])

  return <TableActions.Item onClick={onClick}>View history</TableActions.Item>
}

export function CreateShipmentAction({
  fulfillment,
}: {
  readonly fulfillment: ListFulfillment
}) {
  const { onClick } = useCreateShipmentLink(fulfillment)

  return (
    <TableActions.Item
      disabled={!canCreateShipment(fulfillment)}
      onClick={onClick}
      caption="Create a shipment for this fulfillment"
    >
      Create shipment...
    </TableActions.Item>
  )
}

export function PlanShipmentAction({
  fulfillment,
}: {
  readonly fulfillment: ListFulfillment
}) {
  const onClick = useLinkToFulfillmentsPlan(fulfillment.uuid)

  return (
    <TableActions.Item
      disabled={!canCreateShipment(fulfillment)}
      onClick={onClick}
      caption="Create a shipment including this fulfillment"
    >
      Plan as shipment...
    </TableActions.Item>
  )
}

export function EditFulfillmentAction({
  fulfillment,
}: Readonly<{ fulfillment: ListFulfillment }>) {
  const history = useHistory()

  const handleClick = useCallback(() => {
    history.push(
      generatePath(appRoutes.fulfillmentEdit, { id: fulfillment.uuid })
    )
  }, [history, fulfillment.uuid])

  if (fulfillment.status === 'closed') {
    return (
      <TableActions.Item
        caption="Planned fulfillments cannot be edited"
        disabled
        onClick={handleClick}
      >
        Edit fulfillment...
      </TableActions.Item>
    )
  }

  return (
    <TableActions.Item onClick={handleClick}>
      Edit fulfillment...
    </TableActions.Item>
  )
}

export function CustomerActionsCell({ row }: CellProps<ListFulfillment>) {
  return (
    <TableActions
      title="Fulfillment actions"
      data-testid={`fulfillment-actions-${row.index}`}
    >
      <EditFulfillmentAction fulfillment={row.original} />
      <CreateShipmentAction fulfillment={row.original} />
      <PlanShipmentAction fulfillment={row.original} />
      <OneClickPlanAsShipmentAction fulfillment={row.original} />
      <OpenHistoryAction fulfillment={row.original} />
      <DeleteFulfillmentAction fulfillment={row.original} />
    </TableActions>
  )
}

export function SupplierActionsCell({ row }: CellProps<ListFulfillment>) {
  return (
    <TableActions
      title="Fulfillment actions"
      data-testid={`fulfillment-actions-${row.index}`}
    >
      <EditFulfillmentAction fulfillment={row.original} />
      <DeleteFulfillmentAction fulfillment={row.original} />
    </TableActions>
  )
}
