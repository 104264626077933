import { useMutation } from 'react-query'

import { createFulfillment } from '../../fulfillment-service'

export function useCreateFulfillment({
  onSuccess,
}: { onSuccess?: () => void } = {}) {
  return useMutation({
    mutationKey: ['createFulfillment'],
    mutationFn: createFulfillment,
    onSuccess,
  })
}
