import { Dialog, Text } from '@loadsmart/miranda-react'

type DeleteFulfillmentDialogProps = Readonly<{
  closeDialog: () => void
  confirm: () => void
  isDeleting?: boolean
}>

export function DeleteFulfillmentDialog({
  closeDialog,
  confirm,
  isDeleting,
}: DeleteFulfillmentDialogProps) {
  return (
    <Dialog
      data-testid="delete-fulfillment-dialog"
      open
      onClose={closeDialog}
      variant="danger"
    >
      <Dialog.Header>
        Delete fulfillment
        <Dialog.Close />
      </Dialog.Header>
      <Dialog.Body>
        <Text variant="body-md-bold">This action cannot be undone.</Text>
        <Text>Are you sure you want to delete this fulfillment?</Text>
      </Dialog.Body>
      <Dialog.Actions>
        <Dialog.ActionTertiary disabled={isDeleting} onClick={closeDialog}>
          Cancel
        </Dialog.ActionTertiary>
        <Dialog.ActionPrimary loading={isDeleting} onClick={confirm}>
          Delete
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </Dialog>
  )
}
