import { Button, Header, Icon } from '@loadsmart/miranda-react'

import { HeaderBackLink } from '_shared_/components/HeaderBackLink'
import { appRoutes } from 'router/app-routes'

import { usePlanFulfillmentsContext } from '../PlanFulfillmentsContext'

export function PlanFulfillmentsPageHeader() {
  const { canStartShipment, clearQPState, onStartShipmentClick } =
    usePlanFulfillmentsContext()
  return (
    <Header>
      <HeaderBackLink href={appRoutes.fulfillments} />
      <Header.Content>
        <Header.Title>Plan shipment</Header.Title>
      </Header.Content>

      <Header.Actions>
        <Button onClick={clearQPState} variant="secondary">
          Reset plan
        </Button>
        <Button
          disabled={!canStartShipment}
          onClick={onStartShipmentClick}
          variant="primary"
        >
          Review shipment
          <Icon name="arrow-right" color="color-text-inverted" />
        </Button>
      </Header.Actions>
    </Header>
  )
}
