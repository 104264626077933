import type { FreightClass } from '_shared_/strangercodes'
import { toSelectOptions } from 'utils/selectOption'

import type { CommodityPackageType, PackageType } from './ShippingItems.types'

// based on: src/screens/Shipper/Shipments/NewShipment/Step1/constants.ts
export const FREIGHT_CLASSES_MAP = new Map<FreightClass, string>([
  ['50', '50'] as const,
  ['55', '55'] as const,
  ['60', '60'] as const,
  ['65', '65'] as const,
  ['70', '70'] as const,
  ['77.5', '77.5'] as const,
  ['85', '85'] as const,
  ['92.5', '92.5'] as const,
  ['100', '100'] as const,
  ['110', '110'] as const,
  ['125', '125'] as const,
  ['150', '150'] as const,
  ['175', '175'] as const,
  ['200', '200'] as const,
  ['250', '250'] as const,
  ['300', '300'] as const,
  ['400', '400'] as const,
  ['500', '500'] as const,
])

export const FREIGHT_CLASSES_OPTIONS = toSelectOptions(FREIGHT_CLASSES_MAP)

export const FREIGHT_CLASSES_OPTIONS_MAP = new Map(
  FREIGHT_CLASSES_OPTIONS.map((option) => [option.value, option] as const)
)

// source: src/screens/NewQuote/LTLQuote/components/ShippingItemItem/options.ts
// for handling unit
export const PACKAGE_TYPES_MAP = new Map<PackageType, string>([
  ['std_pallets', 'Standard Pallets (48x40)'] as const,
  ['pallets_non_std', 'Pallets Non Standard'] as const,
  ['bags', 'Bags'] as const,
  ['bales', 'Bales'] as const,
  ['boxes', 'Boxes'] as const,
  ['bunches', 'Bunches'] as const,
  ['carpets', 'Carpets'] as const,
  ['coils', 'Coils'] as const,
  ['crates', 'Crates'] as const,
  ['cylinders', 'Cylinders'] as const,
  ['drums', 'Drums'] as const,
  ['pails', 'Pails'] as const,
  ['reels', 'Reels'] as const,
  ['rolls', 'Rolls'] as const,
  ['tubes_pipes', 'Tubes and Pipes'] as const,
  ['loose', 'Loose'] as const,
])

export const PACKAGE_TYPES_OPTIONS = toSelectOptions(PACKAGE_TYPES_MAP)

export const PACKAGE_TYPES_OPTIONS_MAP = new Map(
  PACKAGE_TYPES_OPTIONS.map((option) => [option.value, option] as const)
)

// for each commodity
export const COMMODITY_PACKAGE_TYPES_MAP = new Map<
  CommodityPackageType,
  string
>([
  ['bags', 'Bags'] as const,
  ['boxes', 'Boxes'] as const,
  ['bulk_containers', 'Bulk Containers'] as const,
  ['bundles_and_rolls', 'Bundles and Rolls'] as const,
  ['cartons', 'Cartons'] as const,
  ['drums', 'Drums'] as const,
  ['gas_cylinders', 'Gas Cylinders'] as const,
  ['jerricans', 'Jerricans'] as const,
  ['pails_or_cans', 'Pails or Cans'] as const,
  ['pieces', 'Pieces'] as const,
  ['totes_or_tanks', 'Totes or Tanks'] as const,
])

export const COMMODITY_PACKAGE_TYPES_OPTIONS = toSelectOptions(
  COMMODITY_PACKAGE_TYPES_MAP
)

export const COMMODITY_PACKAGE_TYPES_OPTIONS_MAP = new Map(
  COMMODITY_PACKAGE_TYPES_OPTIONS.map(
    (option) => [option.value, option] as const
  )
)
