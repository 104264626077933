import { Select } from '@loadsmart/loadsmart-ui'
import { Field } from '@loadsmart/miranda-react'
import { useMemo } from 'react'

import { fromSelectOption, toSelectOption } from 'utils/selectOption'

import type { CustomFormFieldProps, StopAtOption } from '../HandlingUnits.types'
import { getHandlingUnitStopAtOption } from '../HandlingUnitsUtils'
import { useHandlingUnitFormField } from './HandlingUnitsForm.hooks'

type HandlingUnitPickupAtProps = {
  readonly options: StopAtOption[]
} & CustomFormFieldProps

export const HandlingUnitPickupAt = (props: HandlingUnitPickupAtProps) => {
  const { required, hint, style, options } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    labelProps,
    value,
    name,
    setValue,
  } = useHandlingUnitFormField({ name: 'pickup_stop_index', hint })

  const selectOptions = useMemo(() => {
    return options.map((option) =>
      toSelectOption(option.label, option.value.toString())
    )
  }, [options])

  const fieldValue =
    value != null
      ? getHandlingUnitStopAtOption(String(value), selectOptions)
      : null

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Pickup at</Field.Label>
      <Select
        getInputProps={() => controlProps}
        disabled={Boolean(controlProps.disabled)}
        name={name}
        placeholder="Select"
        hideClear={required}
        options={selectOptions}
        value={fieldValue}
        onChange={(event) => {
          const {
            target: { value: option },
          } = event

          const newValue = fromSelectOption(option as SelectOption)

          if (newValue === null) {
            setValue(null)
          } else {
            setValue(Number(newValue))
          }
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
