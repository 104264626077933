import { SideNavigation } from '@loadsmart/loadsmart-ui'
import { useHistory } from 'react-router-dom'

import { useFeatureFlag } from '_shared_/feature-flag'
import { userUtils } from '_shared_/user'
import { appRoutes } from 'router/app-routes'
import { USER_TYPE } from 'utils/constants'

import { isActivePath, navbarLinkClicked } from './sidenav.utils'

export const SettlementMenu = () => {
  const history = useHistory()

  const isSettlementTabEnabled = useFeatureFlag('ENABLE_SETTLEMENT_TAB')

  if (
    isSettlementTabEnabled &&
    userUtils.isUserType([USER_TYPE.SG, USER_TYPE.SUPPLIER])
  ) {
    return (
      <SideNavigation.Menu label="Settlement">
        <SideNavigation.Menu.Item
          active={isActivePath(appRoutes.payables)}
          label="Payables"
          url={appRoutes.payables}
          onClick={(event) => {
            event.preventDefault()
            navbarLinkClicked('Payables', appRoutes.payables, history)
          }}
        />
      </SideNavigation.Menu>
    )
  }

  return null
}
