import { IconPaper, IconClose, IconUpload } from '@loadsmart/icons'
import { Spinner } from '@loadsmart/loadsmart-ui'
import { Button as MirandaButton, Text, Layout } from '@loadsmart/miranda-react'
import { useRef, useCallback, useState, useMemo } from 'react'
import type { HTMLAttributes, ChangeEvent, MouseEvent } from 'react'
import styled from 'styled-components'

import { UnstyledButton } from 'components/Button'
import analytics from 'utils/analytics'

const Input = styled.input`
  display: none;
`
const RemoveButton = styled(UnstyledButton)`
  align-items: center;
  color: ${({ theme }) => theme.colors.danger};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  line-height: 12px;
  padding: 0;
  width: auto;
`

const StyledIconUpload = styled(IconUpload)`
  margin-right: 4px;
`
const FileList = styled.ul`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;

  li {
    align-items: center;
    display: inline-flex;

    :not(:last-child) {
      margin-right: 20px;
    }

    *:not(:last-child) {
      margin-right: 10px;
    }
  }
`

interface Props extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
  readonly value: File[]
  readonly accept?: string[]
  readonly error?: boolean
  readonly isLoading?: boolean
  readonly fileCount?: number
  readonly sizeLimitInMb?: number
  readonly onChange: (file: File[]) => void
}

export default function FileInputV2({
  onChange,
  value,
  accept,
  isLoading,
  fileCount = 1,
  sizeLimitInMb,
  ...props
}: Props) {
  const fileInput = useRef<HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState<string[]>([])
  const onClick = useCallback(() => {
    analytics.track('Upload Files / Upload File Button')
    if (fileInput.current !== null) {
      fileInput.current.value = ''
      fileInput.current.click()
    }
  }, [fileInput])
  const onChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      event.stopPropagation()
      setInputValue([...inputValue, event.currentTarget.value])
      const uploaded = Array.from(event?.currentTarget?.files || [])
      const files = [...value, ...uploaded].slice(0, fileCount)
      onChange(files)
    },
    [onChange, value, fileCount, inputValue]
  )
  const removeFile = useCallback(
    (index: number) => (event: MouseEvent<HTMLButtonElement>) => {
      analytics.track('Upload Files / Remove File Button')
      event.stopPropagation()
      event.preventDefault()
      const newFiles = [...value]
      const removed = newFiles.splice(index, 1)
      onChange(newFiles)
      setInputValue(
        inputValue.filter((fileName) => !fileName.endsWith(removed?.[0]?.name))
      )
    },
    [onChange, value, inputValue]
  )
  const getBody = useCallback(() => {
    if (isLoading) {
      return <Spinner size={80} />
    }
    return (
      sizeLimitInMb && (
        <Text variant="body-sm" color="color-text-tertiary">
          The maximum file size is {sizeLimitInMb}MB each.
        </Text>
      )
    )
  }, [isLoading, sizeLimitInMb])

  const memoizedInput = useMemo(() => {
    return (
      <Input
        {...props}
        onInput={onChangeInput}
        type="file"
        ref={fileInput}
        accept={accept ? accept.join(',') : undefined}
        disabled={isLoading}
        multiple={fileCount > 1}
      />
    )
  }, [accept, fileCount, isLoading, onChangeInput, props])

  return (
    <>
      {memoizedInput}
      <Layout.Stack>
        <Layout.Group>
          <Layout.Stack gap="spacing-1">
            <MirandaButton
              tabIndex={0}
              onClick={onClick}
              disabled={isLoading || value.length >= fileCount}
              type="button"
              leading={<IconUpload />}
            >
              <StyledIconUpload height={12} width={12} /> Upload documents
            </MirandaButton>
            {getBody()}
          </Layout.Stack>
        </Layout.Group>
        {(value?.length || 0) > 0 && (
          <Layout.Group paddingT="spacing-6">
            <FileList>
              {Array.from(value || []).map((file: File, index: number) => (
                <li key={file.name}>
                  <IconPaper height={18} width={18} />
                  <span>{file.name}</span>
                  <RemoveButton
                    onClick={removeFile(index)}
                    data-testid={`remove-${file.name}`}
                  >
                    <IconClose height={10} width={10} />
                  </RemoveButton>
                </li>
              ))}
            </FileList>
          </Layout.Group>
        )}
      </Layout.Stack>
    </>
  )
}
