import { PageContent } from '@loadsmart/miranda-react'

import { WindowTitle } from '_shared_/components/WindowTitle'
import { FulfillmentHistoryProvider } from 'fulfillments/components/FulfillmentHistory'

import { ListFulfillmentsPageHeader } from './components/ListFulfillmentsPageHeader'
import { ListFulfillments } from './ListFulfillments'

export function ListFulfillmentsPage() {
  return (
    <PageContent>
      <WindowTitle title="Fulfillments" />
      <ListFulfillmentsPageHeader />

      <PageContent.Body>
        <FulfillmentHistoryProvider>
          <ListFulfillments />
        </FulfillmentHistoryProvider>
      </PageContent.Body>
    </PageContent>
  )
}
