import { Field, Layout, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useFreightInformationFormContext } from './FreightInformationForm.context'
import { useFreightInformationFormField } from './useFreightInformationFormField'

const CUSTOM_FIELDS_NAME = 'shipper_custom_fields'

type FreightInformationCustomFieldsProps = {
  readonly required?: FieldProps['required']
  readonly error?: string
  readonly style?: CSSProperties
}

export function FreightInformationCustomFields({
  required,
  error,
  style,
}: FreightInformationCustomFieldsProps) {
  const [shipment, dispatch] = useFreightInformationFormContext()
  const settingsCustomFields = get(shipment, CUSTOM_FIELDS_NAME) ?? []

  const { fieldProps } = useFreightInformationFormField({
    name: CUSTOM_FIELDS_NAME,
    error,
  })

  return (
    <Layout.Container>
      <Layout.Row>
        {Object.keys(settingsCustomFields).map((key, index) => (
          <Layout.Column sm="4" key={key}>
            <Field
              key={key}
              {...fieldProps}
              required={required}
              style={style}
              data-testid={`${CUSTOM_FIELDS_NAME}-field-${index}`}
            >
              <Field.Label id={`label-custom-field-${index}`}>
                {key}
              </Field.Label>
              <TextField
                aria-labelledby={`label-custom-field-${key}`}
                placeholder={`Add ${key.toLowerCase()}`}
                value={get(settingsCustomFields, key) ?? ''}
                onChange={(event) => {
                  dispatch({
                    type: 'HANDLE_CHANGE',
                    payload: {
                      [CUSTOM_FIELDS_NAME]: {
                        ...get(shipment, CUSTOM_FIELDS_NAME),
                        [key]: get(event, 'target.value'),
                      },
                    },
                  })
                }}
              />
            </Field>
          </Layout.Column>
        ))}
      </Layout.Row>
    </Layout.Container>
  )
}
