import {
  IconDocumentDetail,
  IconTrophyFilled,
  IconQuestionCircle,
} from '@loadsmart/icons'
import { Dialog, IconButton, LoadingDots } from '@loadsmart/loadsmart-ui'
import { Layout, Tag, Tooltip } from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import type { ChangeEvent } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { InputGroup } from '_shared_/components/form/styles'
import Button from 'components/Button'
import { Input, Label } from 'components/Input'
import {
  StyledBody,
  StyledCard,
  StyledTitle,
  TitleLoaderWrapper,
} from 'components/SideBar/styles'
import TableV3 from 'components/TableV3'
import { RateType } from 'features/laneProposals/reducer'
import { laneProposalsRateTypeViewSelector } from 'features/laneProposals/selectors'
import { useModal } from 'hooks/useModal'
import EmptyState from 'rfp/components/empty-state'
import { TableHeaderDropdown } from 'rfp/rfp-details/award-scenarios/TableHeaderDropdown'
import type { RequestForProposal } from 'rfp/rfp.types'
import { partialUpdate, rankedAwards } from 'services/laneProposal'
import { theme } from 'styles/theme'
import analytics, { AnalyticsEventTrigger } from 'utils/analytics'
import { BID_TYPE, MODES_DICT, RFP_STATE } from 'utils/constants'
import { isMultiCurrency } from 'utils/currency'
import { getEquipmentType } from 'utils/equipmentTypeV2'

import AccessorialsDialog from '../accessorials-dialog'
import {
  AwardCarrierDialog,
  SendAwardConfirmationDialog,
} from '../award-carrier-dialog'
import CounterOfferModal from '../counter-offer-modal/CounterOfferModal'
import LaneResultsV1 from '../lane-results'
import RankedAwards from '../ranked-awards'
import {
  ActionsCell,
  BenchmarkCell,
  BidCarrierCell,
  FlatRateCell,
  RatePerMileCell,
} from './Cells'
import {
  Capacity,
  CarrierHeader,
  EmptyStateWrapper,
  PrimaryBackupButtonsWrapper,
} from './styles'

const tableInitalState = { sortBy: [{ id: 'flat_rate' }] }
const defaultAwardModalState = {
  title: 'Award Carrier',
  isPrimary: true,
  isBackup: false,
  volumeGiven: '',
  volumeOrigin: 0,
}

interface AwardModalState {
  title?: string
  isPrimary: boolean
  isBackup: boolean
  volumeGiven: string
  volumeOrigin: number
}

const AwardDialog = ({
  awardModalVisibility,
  handleAwardModalClose,
  selected,
  awardModalState,
  handlePrimaryBackupChange,
  handleGivenVolumeChange,
  handleAwardModalSave,
  isUpdating,
  lane,
}: {
  readonly awardModalVisibility: boolean
  readonly handleAwardModalClose: () => void
  readonly selected: any
  readonly awardModalState: AwardModalState
  readonly handlePrimaryBackupChange: () => void
  readonly handleGivenVolumeChange: ({ target: { value } }: any) => void
  readonly handleAwardModalSave: () => void
  readonly isUpdating: boolean
  readonly lane: Lane
}) => {
  return (
    <Dialog
      open={awardModalVisibility}
      onOverlayClick={handleAwardModalClose}
      data-testid="award-modal"
    >
      <Dialog.Header>
        {(selected !== null && selected.carrier?.name) ?? ''}
      </Dialog.Header>
      <Dialog.Body>
        {selected && (
          <div
            style={{
              textAlign: 'left',
            }}
          >
            Select carrier as
            <PrimaryBackupButtonsWrapper>
              <Button
                variant={awardModalState.isPrimary ? 'dark' : 'secondary'}
                onClick={handlePrimaryBackupChange}
                style={{
                  marginRight: '8px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  fontSize: '16px',
                  marginTop: '10px',
                }}
              >
                <IconTrophyFilled
                  style={{ marginRight: '4px', verticalAlign: 'top' }}
                  width={16}
                  height={16}
                  fill={
                    awardModalState.isPrimary
                      ? theme.colors.white
                      : theme.colors.text
                  }
                />
                PRIMARY CARRIER
              </Button>
              <Button
                variant={awardModalState.isBackup ? 'dark' : 'secondary'}
                onClick={handlePrimaryBackupChange}
                style={{
                  padding: '10px 8px',
                  fontSize: '16px',
                  marginTop: '10px',
                }}
              >
                BACKUP CARRIER
              </Button>
            </PrimaryBackupButtonsWrapper>
            <InputGroup required={awardModalState.isPrimary}>
              <Label name="volume">
                VOLUME GIVEN
                <Input
                  name="volume"
                  type="number"
                  value={awardModalState.volumeGiven}
                  onChange={handleGivenVolumeChange}
                  min={0}
                  max={selected.capacity}
                  style={{
                    display: 'inline-flex',
                    width: '165px',
                    marginRight: '8px',
                  }}
                  disabled={awardModalState.isBackup}
                />
                &nbsp;of{' '}
                {Math.min(lane.remaining_award_volume, selected.capacity)}
              </Label>
            </InputGroup>
          </div>
        )}
      </Dialog.Body>
      <Dialog.ActionConfirm onConfirm={handleAwardModalSave}>
        {!isUpdating ? 'SAVE' : <LoadingDots variant="light" />}
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel onCancel={handleAwardModalClose} />
    </Dialog>
  )
}

const BidsReceived = ({
  laneProposals,
  columns,
  isUpdating,
  isLoadingLaneProposals,
}: {
  readonly laneProposals: LaneProposal[]
  readonly columns: unknown
  readonly isUpdating: boolean
  readonly isLoadingLaneProposals: boolean
}) => {
  const shouldShowBidsReceived = laneProposals && laneProposals.length > 0

  if (!shouldShowBidsReceived) {
    return (
      <EmptyStateWrapper>
        <EmptyState
          title="Waiting for Bids"
          subTitle="As soon as you have the first rate you will see a list here."
        />
      </EmptyStateWrapper>
    )
  }

  return (
    <>
      <StyledTitle style={{ marginBottom: '30px' }}>
        Bids Received
        {isUpdating && <TitleLoaderWrapper />}
      </StyledTitle>
      <StyledBody>
        <TableV3
          columns={columns}
          entries={laneProposals}
          isLoading={isLoadingLaneProposals}
          initialState={tableInitalState}
          sortable
          pagination
          bordered={false}
          data-testid="bids-received-table"
        />
      </StyledBody>
    </>
  )
}

const checkShowLaneResultsAndRankedAwards = (
  isRankedAward: boolean,
  bidsResultsCount: number,
  laneProposalsCount: number
) => {
  return {
    shouldShowLaneResults: !isRankedAward && bidsResultsCount > 0,
    shouldShowRankedAwards: isRankedAward && laneProposalsCount > 0,
  }
}

function getVolumeGiven(
  { awarded_volume, capacity }: LaneProposal,
  lane: Lane
) {
  const minVolume = String(Math.min(lane.remaining_award_volume, capacity))
  return awarded_volume ? String(awarded_volume) : minVolume
}

const rateColumnOptions = [
  {
    value: 'flat_rate',
    label: 'Flat Rate',
  },
  {
    value: 'per_mile_rate',
    label: 'RPM',
  },
]
const getModesAndEquipmentColumn = () => {
  return [
    {
      Header: 'Modes & Equip.',
      id: 'preferences',
      className: 'text-left',
      Cell: function modesAndEquipmentRender({
        row,
      }: {
        row: { original: LaneProposal }
      }) {
        const { preference } = row.original
        const { mode, equipment_type } = preference

        return (
          <Tag variant="neutral" size="default">
            {MODES_DICT[mode]}&nbsp;&rarr;&nbsp;
            {getEquipmentType(equipment_type)?.abbr}
          </Tag>
        )
      },
    },
  ]
}

const getVolumeColumn = (isRankedAward: boolean, lane: Lane) => {
  let headerName = 'Volume'

  if (isRankedAward) {
    headerName = 'Est. Vol'
  }

  return [
    {
      Header: headerName,
      id: 'capacity',
      accessor: 'capacity',
      className: 'text-left',
      Cell: function render({ row, value }: { row: any; value: number }) {
        return (
          <Capacity
            className={`${
              row.original.awarded_type !== null ? 'attenuated' : ''
            }`}
            highlight={!!(lane && lane.volume > value)}
          >
            {value}
          </Capacity>
        )
      },
    },
  ]
}

const getRateColumn = (
  displayMultiCurrency: boolean,
  rfp: RequestForProposal,
  rateColumn: string,
  setRateColumn: (value: string) => void
) => {
  return [
    {
      Header: (
        <Layout.Group gap="spacing-2" style={{ flexWrap: 'nowrap' }}>
          {
            rateColumnOptions.find((option) => option.value === rateColumn)
              ?.label
          }
          <TableHeaderDropdown
            options={rateColumnOptions}
            defaultValue={rateColumn}
            setValue={setRateColumn}
            disabled={false}
          />
        </Layout.Group>
      ),
      id: rateColumn,
      className: 'text-left',
      Cell: function render({ row }: { row: any }) {
        if (rateColumn === 'flat_rate') {
          return (
            <FlatRateCell
              displayMultiCurrency={displayMultiCurrency}
              rfp={rfp}
              row={row}
            />
          )
        }

        return (
          <RatePerMileCell
            displayMultiCurrency={displayMultiCurrency}
            rfp={rfp}
            row={row}
          />
        )
      },
    },
  ]
}

const getBenchmarkColumn = (
  displayMultiCurrency: boolean,
  rateTypeDataKey: string,
  lane: Lane,
  rateType: string
) => {
  if (displayMultiCurrency) {
    // we dont have benchmark for multicurrency RPFs
    return []
  }

  return [
    {
      Header: (
        <Layout.Group gap="spacing-1">
          Bench.
          <Tooltip
            placement="bottom-start"
            trigger="hover"
            message={
              <>
                Shows the percentage
                <br />
                difference between the bid and
                <br /> the selected benchmark (Target
                <br /> Rate or Market Benchmark).
              </>
            }
          >
            <IconQuestionCircle
              height={16}
              width={16}
              fill={toCSSValue('color-text-secondary')}
              title={null}
            />
          </Tooltip>
        </Layout.Group>
      ),
      className: 'text-left',
      accessor: rateTypeDataKey,
      Cell: function renderBenchmarkCell({
        row,
        value,
        data,
      }: {
        row: { original: LaneProposal }
        value: number | null
        data: LaneProposal[]
      }) {
        return (
          <BenchmarkCell
            data={data}
            lane={lane}
            rateType={rateType}
            row={row}
            value={value}
          />
        )
      },
    },
  ]
}

export default function LaneBidsV1({
  lane,
  laneProposals,
  isLoadingLaneProposals,
  carrierInvites,
  bidsResults,
  rfpState,
  rfp,
  awardScenariosCallback,
}: {
  readonly lane: Lane
  readonly laneProposals: LaneProposal[]
  readonly isLoadingLaneProposals: boolean
  readonly carrierInvites: NewCarrierInvite[]
  readonly bidsResults: LaneProposal[]
  readonly rfpState: string
  readonly rfp: RequestForProposal
  readonly awardScenariosCallback?: () => void
}) {
  const isRankedAward = rfp.bid_type === BID_TYPE.RANK
  const displayMultiCurrency = isMultiCurrency(rfp.currency)
  const [selected, setSelected] = useState<LaneProposal | null>(null)
  const [laneOnCounterOfferModal, setLaneOnCounterOfferModal] =
    useState<LaneProposal | null>(null)
  const [awardModalState, setAwardModalState] = useState<AwardModalState>(
    defaultAwardModalState
  )
  const [showSendAwardModal, setShowSendAwardModal] = useState(true)
  const [awardAcceptanceDeadline, setAwardAcceptanceDeadline] = useState(3)
  const rateType = useSelector(laneProposalsRateTypeViewSelector)
  const [rateColumn, setRateColumn] = useState(rateColumnOptions[0].value)

  const {
    modalState: awardModalVisibility,
    closeModal: awardCloseModal,
    openModal: awardOpenModal,
  } = useModal()
  const {
    modalState: sendAwardModalVisibility,
    closeModal: sendAwardCloseModal,
    openModal: sendAwardOpenModal,
  } = useModal()
  const {
    modalState: counterOfferModalState,
    closeModal: counterOfferCloseModal,
    openModal: counterOfferOpenModal,
  } = useModal()
  const {
    modalState: carrierAccessorialsModalState,
    closeModal: carrierAccessorialsCloseModal,
    openModal: carrierAccessorialsOpenModal,
  } = useModal()

  const awardingStateFlow = rfpState === RFP_STATE.AWARDING

  const canAwardOrUnaward = useMemo(
    () =>
      [
        RFP_STATE.DRAFT,
        RFP_STATE.PUBLISHED,
        RFP_STATE.CLOSED,
        RFP_STATE.AWARDING,
      ].includes(rfpState),
    [rfpState]
  )

  const canUpdateRankedProposals = useMemo(
    () => canAwardOrUnaward && rfpState !== RFP_STATE.AWARDING,
    [canAwardOrUnaward, rfpState]
  )

  const carrierInvitesLinksMap = useMemo(
    () =>
      carrierInvites.reduce(
        (acc, invite) => ({ ...acc, [invite.carrier.id]: invite.link }),
        {} as Record<number, string>
      ),
    [carrierInvites]
  )

  const handleAwardModalClose = useCallback(() => {
    awardCloseModal()
    setSelected(null)
    setAwardModalState(defaultAwardModalState)
  }, [awardCloseModal])

  const handleAccessorialsDialogClose = useCallback(() => {
    carrierAccessorialsCloseModal()
    setSelected(null)
  }, [carrierAccessorialsCloseModal])

  const queryClient = useQueryClient()

  const { mutate: mutateLaneProposal, isLoading: isUpdating } = useMutation({
    mutationFn: partialUpdate,
    onSuccess({ successMessage }) {
      handleAwardModalClose()
      queryClient.refetchQueries({ queryKey: ['retrieveLaneProposal'] })
      queryClient.refetchQueries({
        queryKey: ['rfpTotalizers', rfp.id.toString()],
      })

      awardScenariosCallback?.()

      if (successMessage) {
        toast.info(successMessage)
      } else {
        toast.success('Changes saved.')
      }
    },
    onError() {
      toast.error('Could not apply the requested changes. Please contact us.')
    },
  })

  const { mutate: mutateRankedAwards, isLoading: isUpdatingRankedAwards } =
    useMutation({
      mutationFn: rankedAwards,
      onSuccess() {
        awardScenariosCallback?.()
        queryClient.refetchQueries({ queryKey: ['retrieveLaneProposal'] })
        queryClient.refetchQueries({
          queryKey: ['rfpTotalizers', rfp.id.toString()],
        })
        queryClient.refetchQueries({ queryKey: ['deallocatedLanes'] })
        queryClient.refetchQueries({ queryKey: ['allocatedLanes'] })
        queryClient.refetchQueries({ queryKey: ['rankScenarios'] })
      },
      onError() {
        toast.error('Could not apply the requested changes. Please contact us.')
      },
    })

  const handleAddToRank = useCallback(
    (proposal: LaneProposal) => {
      mutateRankedAwards({
        laneId: lane.id,
        payload: [
          ...bidsResults.map((laneProposal) => ({
            id: laneProposal.id,
            awarded_rank: laneProposal.awarded_rank,
          })),
          { id: proposal.id, awarded_rank: bidsResults.length + 1 },
        ],
      })
    },
    [mutateRankedAwards, bidsResults, lane.id]
  )

  const handleRemoveFromRank = useCallback(
    (proposal: LaneProposal) => {
      mutateRankedAwards({
        laneId: lane.id,
        payload: [
          ...bidsResults
            .filter((ranked_proposal) => ranked_proposal.id !== proposal.id)
            .sort((a, b) => a.awarded_rank! - b.awarded_rank!)
            .map((laneProposal, index) => ({
              id: laneProposal.id,
              awarded_rank: index + 1,
            })),
        ],
      })
    },
    [mutateRankedAwards, bidsResults, lane.id]
  )

  const handleMoveProposalInRank = useCallback(
    (proposal: LaneProposal, direction: 'up' | 'down') => {
      const currentRankPosition = proposal.awarded_rank!

      const newRankPosition =
        direction === 'up' ? currentRankPosition - 1 : currentRankPosition + 1

      mutateRankedAwards({
        laneId: lane.id,
        payload: [
          ...bidsResults
            .filter((ranked_proposal) => ranked_proposal.id !== proposal.id)
            .map((laneProposal) => ({
              id: laneProposal.id,
              awarded_rank:
                laneProposal.awarded_rank === newRankPosition
                  ? currentRankPosition
                  : laneProposal.awarded_rank,
            })),
          { id: proposal.id, awarded_rank: newRankPosition },
        ],
      })
    },
    [mutateRankedAwards, bidsResults, lane.id]
  )

  const handleMoveToEdgesInRank = useCallback(
    (proposal: LaneProposal, edge: 'top' | 'bottom') => {
      mutateRankedAwards({
        laneId: lane.id,
        payload: [
          ...bidsResults
            .filter((ranked_proposal) => ranked_proposal.id !== proposal.id)
            .sort((a, b) => a.awarded_rank! - b.awarded_rank!)
            .map((laneProposal, index) => ({
              id: laneProposal.id,
              awarded_rank: index + (edge === 'top' ? 2 : 1),
            })),
          {
            id: proposal.id,
            awarded_rank: edge === 'top' ? 1 : bidsResults.length,
          },
        ],
      })
    },
    [mutateRankedAwards, bidsResults, lane.id]
  )

  const handleRequestNewRate = useCallback(
    (proposal: LaneProposal, note: string) => {
      if (proposal.under_negotiation) {
        return
      }
      mutateLaneProposal({
        laneId: lane.id,
        carrierId: proposal.id,
        payload: {
          under_negotiation: true,
          negotiation_note: note,
        },
        successMessage: 'You have sent your counter offer.',
      })
    },
    [mutateLaneProposal, lane.id]
  )

  const handleCheckCarrier = useCallback(
    (laneProposal: LaneProposal) => {
      if (laneProposal.awarded_type !== null) {
        mutateLaneProposal({
          laneId: lane.id,
          carrierId: laneProposal.id,
          payload: {
            awarded_type: null,
            awarded_volume: 0,
          },
        })
      } else {
        setSelected(selected === null ? laneProposal : null)

        setAwardModalState({
          title: 'Award Carrier',
          volumeOrigin: laneProposal.capacity,
          volumeGiven: getVolumeGiven(laneProposal, lane),
          isPrimary: laneProposal.awarded_type !== 'backup',
          isBackup: laneProposal.awarded_type === 'backup',
        })
        awardOpenModal()
      }
    },
    [mutateLaneProposal, lane, selected, awardOpenModal]
  )

  const handleSelectCarrier = useCallback(
    (laneProposal: LaneProposal) => {
      setSelected(selected === null ? laneProposal : null)
      setAwardModalState({
        title: 'Award Carrier',
        volumeOrigin: laneProposal.capacity,
        volumeGiven:
          laneProposal.awarded_type === 'backup'
            ? ''
            : getVolumeGiven(laneProposal, lane),
        isPrimary: laneProposal.awarded_type !== 'backup',
        isBackup: laneProposal.awarded_type === 'backup',
      })
      awardOpenModal()
    },
    [selected, lane, awardOpenModal]
  )

  const handlePromoteToPrimary = useCallback(
    (laneProposal: LaneProposal) => {
      setSelected(selected === null ? laneProposal : null)
      setAwardModalState({
        title: 'Promote to primary',
        volumeOrigin: laneProposal.capacity,
        volumeGiven: getVolumeGiven(laneProposal, lane),
        isPrimary: true,
        isBackup: false,
      })
      awardOpenModal()
    },
    [selected, lane, awardOpenModal]
  )

  const handleAwardModalSave = useCallback(() => {
    if (selected !== null) {
      if (awardModalState.isPrimary) {
        mutateLaneProposal({
          laneId: lane.id,
          carrierId: selected.id,
          payload: {
            awarded_type: 'primary',
            awarded_volume: parseInt(awardModalState.volumeGiven, 10),
            award_acceptance_deadline: awardAcceptanceDeadline,
          },
        })
      } else {
        mutateLaneProposal({
          laneId: lane.id,
          carrierId: selected.id,
          payload: {
            awarded_type: 'backup',
          },
        })
      }
    }
  }, [
    awardModalState.isPrimary,
    awardModalState.volumeGiven,
    lane.id,
    mutateLaneProposal,
    selected,
    awardAcceptanceDeadline,
  ])

  const handlePrimaryBackupChange = useCallback(() => {
    setAwardModalState({
      ...awardModalState,
      isPrimary: awardModalState.isBackup,
      isBackup: awardModalState.isPrimary,
    })
  }, [awardModalState])

  const handleGivenVolumeChange = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const volumeGiven = lane.remaining_award_volume

      setAwardModalState({
        ...awardModalState,
        volumeGiven: Number(value) > volumeGiven ? String(volumeGiven) : value,
      })
    },
    [awardModalState, lane.remaining_award_volume]
  )

  const handleRemoveBid = useCallback(
    (laneProposal: LaneProposal) => {
      mutateLaneProposal({
        laneId: lane.id,
        carrierId: laneProposal.id,
        payload: {
          is_removed: true,
        },
      })
    },
    [lane.id, mutateLaneProposal]
  )

  const checkIfCanRemoveBid = useCallback(
    (awardedType: string | null) =>
      !isRankedAward &&
      canAwardOrUnaward &&
      awardedType === null &&
      rfpState !== RFP_STATE.AWARDING,
    [isRankedAward, canAwardOrUnaward, rfpState]
  )

  const getRateTypeDataKey = (selectedRateType: string) => {
    let rateTypeDataKey = 'target_rate_percent_difference'

    if (selectedRateType === RateType.LIVE) {
      rateTypeDataKey = 'market_rate_percent_difference'
    } else if (selectedRateType === RateType.SHORT_TERM) {
      rateTypeDataKey = 'contract_rate_percent_difference'
    }

    return rateTypeDataKey
  }

  const checkIfSelectedCarrierIsDisabled = useCallback(
    (rowAwardedType: 'primary' | 'backup' | 'rank' | null) =>
      !isRankedAward &&
      rfpState === RFP_STATE.AWARDING &&
      (lane.remaining_award_volume === 0 || rowAwardedType !== null),
    [isRankedAward, rfpState, lane]
  )

  const columns = useMemo(() => {
    const rateTypeDataKey = getRateTypeDataKey(rateType)

    const getAccessorialsColumn = () => {
      return [
        {
          Header: '',
          id: 'carrier_accessorials',
          className: 'no-padding',
          Cell: function render({ row }: Readonly<{ row: any }>) {
            return (
              <IconButton
                onClick={() => {
                  setSelected(row.original)
                  // setTimeout needed to avoid concurrency
                  // between setSelected and openModal
                  setTimeout(carrierAccessorialsOpenModal, 0)
                }}
                scale="small"
                variant="icon"
                disabled={row.original.accessorials.length === 0}
              >
                <IconDocumentDetail height={16} width={16} />
              </IconButton>
            )
          },
        },
      ]
    }

    return () => [
      {
        Header: <CarrierHeader>Carrier</CarrierHeader>,
        id: 'name',
        accessor: ({ carrier }: { carrier: any }) => carrier.name,
        className: 'text-left',
        Cell: function bidCarrierCellRender({
          row,
        }: Readonly<{
          row: { original: LaneProposal }
        }>) {
          return (
            <BidCarrierCell
              canAwardOrUnaward={canAwardOrUnaward}
              canUpdateRankedProposals={canUpdateRankedProposals}
              handleAddToRank={handleAddToRank}
              handleCheckCarrier={handleCheckCarrier}
              lane={lane}
              rfp={rfp}
              row={row}
            />
          )
        },
      },
      ...getModesAndEquipmentColumn(),
      ...getVolumeColumn(isRankedAward, lane),
      ...getRateColumn(displayMultiCurrency, rfp, rateColumn, setRateColumn),
      ...getBenchmarkColumn(
        displayMultiCurrency,
        rateTypeDataKey,
        lane,
        rateType
      ),
      ...getAccessorialsColumn(),
      {
        Header: '',
        id: 'actions',
        className: 'text-left actions-cell',
        Cell: function render({
          row,
        }: Readonly<{
          row: { original: LaneProposal }
        }>) {
          const inviteLink = carrierInvitesLinksMap[row.original.carrier.id]

          const isSelectCarrierDisabled = checkIfSelectedCarrierIsDisabled(
            row.original.awarded_type
          )

          return (
            <ActionsCell
              canAwardOrUnaward={canAwardOrUnaward}
              canRemoveBid={checkIfCanRemoveBid(row.original.awarded_type)}
              counterOfferOpenModal={counterOfferOpenModal}
              handleAddToRank={handleAddToRank}
              handleRemoveBid={handleRemoveBid}
              handleSelectCarrier={handleSelectCarrier}
              inviteLink={inviteLink}
              isRankedAward={isRankedAward}
              isSelectCarrierDisabled={isSelectCarrierDisabled}
              lane={lane}
              rfp={rfp}
              row={row}
              setLaneOnCounterOfferModal={setLaneOnCounterOfferModal}
            />
          )
        },
      },
    ]
  }, [
    rateType,
    displayMultiCurrency,
    lane,
    carrierAccessorialsOpenModal,
    isRankedAward,
    rfp,
    rateColumn,
    canAwardOrUnaward,
    canUpdateRankedProposals,
    handleAddToRank,
    handleCheckCarrier,
    carrierInvitesLinksMap,
    checkIfSelectedCarrierIsDisabled,
    checkIfCanRemoveBid,
    counterOfferOpenModal,
    handleRemoveBid,
    handleSelectCarrier,
  ])

  const { shouldShowLaneResults, shouldShowRankedAwards } =
    checkShowLaneResultsAndRankedAwards(
      isRankedAward,
      bidsResults.length,
      laneProposals.length
    )

  const onSendAward = showSendAwardModal
    ? sendAwardOpenModal
    : handleAwardModalSave

  return (
    <StyledCard data-testid="header">
      {shouldShowLaneResults && (
        <LaneResultsV1
          lane={lane}
          laneProposals={bidsResults}
          isLoadingLaneProposals={isLoadingLaneProposals}
          mutateLaneProposal={mutateLaneProposal}
          isUpdating={isUpdating}
          handleRequestNewRate={handleRequestNewRate}
          promoteCarrierToPrimary={handlePromoteToPrimary}
          canPromoteCarrierToPrimary={laneProposals.some(
            (e) => e.award_confirmation === 'denied'
          )}
          canAwardOrUnaward={canAwardOrUnaward}
          remainingVolumeGiven={lane.remaining_award_volume}
          rfpCurrency={rfp.currency}
          rfpState={rfpState as RfpState}
          rfpDistanceType={rfp.distance_type}
        />
      )}
      {shouldShowRankedAwards && (
        <RankedAwards
          laneProposals={bidsResults}
          isLoadingLaneProposals={isLoadingLaneProposals}
          isUpdatingRankedAwards={isUpdatingRankedAwards}
          handleRemoveFromRank={handleRemoveFromRank}
          handleMoveProposalInRank={handleMoveProposalInRank}
          handleMoveToEdgesInRank={handleMoveToEdgesInRank}
          rfpCurrency={rfp.currency}
          rfpState={rfpState as RfpState}
          rfpDistanceType={rfp.distance_type}
        />
      )}
      <BidsReceived
        columns={columns}
        isLoadingLaneProposals={isLoadingLaneProposals}
        isUpdating={isUpdating}
        laneProposals={laneProposals}
      />

      {awardingStateFlow ? (
        <>
          <AwardCarrierDialog
            title={awardModalState.title}
            isOpen={awardModalVisibility}
            onClose={handleAwardModalClose}
            onSendAward={onSendAward}
            remainingVolume={lane.remaining_award_volume ?? 0}
            inputValue={awardModalState.volumeGiven}
            inputOnChange={handleGivenVolumeChange}
            rfpId={rfp.id}
            setAwardAcceptanceDeadline={setAwardAcceptanceDeadline}
          />
          <SendAwardConfirmationDialog
            isOpen={sendAwardModalVisibility}
            onConfirm={() => {
              handleAwardModalSave()
              sendAwardCloseModal()
            }}
            onCancel={() => {
              setShowSendAwardModal(false)
              sendAwardCloseModal()
            }}
            onOverlayClick={sendAwardCloseModal}
          />
        </>
      ) : (
        <AwardDialog
          awardModalState={awardModalState}
          awardModalVisibility={awardModalVisibility}
          handleAwardModalClose={handleAwardModalClose}
          handleAwardModalSave={handleAwardModalSave}
          handleGivenVolumeChange={handleGivenVolumeChange}
          handlePrimaryBackupChange={handlePrimaryBackupChange}
          isUpdating={isUpdating}
          lane={lane}
          selected={selected}
        />
      )}
      {laneOnCounterOfferModal && (
        <CounterOfferModal
          close={() => {
            analytics.track(
              'RFP / Lane / Counter Offer / Cancel',
              AnalyticsEventTrigger.click,
              {
                ...lane,
                ...rfp,
              },
              ['lane']
            )
            setLaneOnCounterOfferModal(null)
            counterOfferCloseModal()
          }}
          isOpen={counterOfferModalState}
          save={(note: string) => {
            analytics.track(
              'RFP / Lane / Counter Offer / Send',
              AnalyticsEventTrigger.click,
              {
                ...lane,
                ...rfp,
              },
              ['lane']
            )
            handleRequestNewRate(laneOnCounterOfferModal, note)
            counterOfferCloseModal()
          }}
        />
      )}
      {!!carrierAccessorialsModalState && selected?.accessorials && (
        <AccessorialsDialog
          carrierName={selected?.carrier?.name ?? ''}
          carrierAccessorials={selected?.accessorials ?? []}
          volume={selected?.capacity ?? '-'}
          isOpen={carrierAccessorialsModalState}
          onClose={handleAccessorialsDialogClose}
          rfpCurrency={rfp.currency}
        />
      )}
    </StyledCard>
  )
}
