import { Dialog, LoadingDots, Text } from '@loadsmart/loadsmart-ui'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import type { RequestForProposal } from 'rfp/rfp.types'
import { partialUpdate } from 'rfp/services/rfp'
import { RFP_STATE } from 'utils/constants'

import FinalizedDialog from '../finalized-dialog'

interface Props {
  readonly rfp: RequestForProposal
  readonly isOpen: boolean
  readonly onClose: () => void
  readonly totalizers: RFPTotalizers | undefined
}

const SpinnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  svg {
    margin-right: 6px;
  }
`

export default function NoAwardDialog({
  rfp,
  isOpen,
  totalizers,
  onClose,
}: Props) {
  const history = useHistory()
  const queryClient = useQueryClient()

  const { mutate: finalizeRFP, isLoading } = useMutation({
    mutationFn: partialUpdate,
    onSuccess() {
      history.push({ hash: 'award' })
      queryClient.refetchQueries({ queryKey: ['deallocatedLanes'] })
      queryClient.refetchQueries({ queryKey: ['allocatedLanes'] })
    },
    onError() {
      toast.error('Failed to award RFP.')
    },
  })

  if (rfp.state === RFP_STATE.FINALIZED) {
    return <FinalizedDialog rfp={rfp} isOpen={isOpen} onClose={onClose} />
  }

  return (
    <Dialog open={isOpen} scale="small" onOverlayClick={onClose}>
      <Dialog.Header>No Allocation</Dialog.Header>
      <Dialog.Body>
        <Text variant="body" color="color-neutral-dark">
          You are about to finalize this RFP{' '}
          <b>without primary carriers selected.</b>
        </Text>
        <br />
        <br />
        <Text variant="body" color="color-neutral-dark">
          Lanes Allocated: <b>{`${totalizers?.lane_count_allocated || 0}%`}</b>
          <br />
        </Text>
        <Text variant="body" color="color-neutral-dark">
          Volume Allocated:{' '}
          <b>{`${totalizers?.total_volume_allocated || 0}%`}</b>
          <br />
        </Text>
        <br />
        <Text variant="body" color="color-neutral-dark">
          Do you want to continue?
        </Text>
      </Dialog.Body>
      <Dialog.ActionConfirm onConfirm={onClose}>
        keep it published
      </Dialog.ActionConfirm>
      <Dialog.ActionCancel
        onCancel={() => {
          finalizeRFP({
            id: rfp.id,
            payload: { state: RFP_STATE.FINALIZED },
            queryClient,
          })
        }}
        disabled={isLoading}
      >
        <SpinnerWrapper>
          {isLoading ? <LoadingDots /> : 'finalize without awards'}
        </SpinnerWrapper>
      </Dialog.ActionCancel>
    </Dialog>
  )
}
