import { merge, omit } from 'lodash-es'

import type { FreightClass } from '_shared_/strangercodes'
import type { HandlingUnitOrderItem } from 'components/CommodityDetails/Commodity'
import { createHazmatItem } from 'components/HazmatInformation'
import { PACKAGE_TYPE_LABELS } from 'screens/NewQuote/LTLQuote/LTLQuote.constants'
import {
  STANDARD_PALLETS_LENGTH,
  STANDARD_PALLETS_WIDTH,
} from 'utils/constants'
import toArray from 'utils/toArray'
import { createTransient, undoTransient } from 'utils/transient'

import type { CommodityPackageType, PackageType } from '../ShippingItemsManager'
import {
  COMMODITY_PACKAGE_TYPES_MAP,
  calculateTotalDensity,
  calculateTotalVolume,
  calculateTotalWeight as calculateItemsTotalWeight,
} from '../ShippingItemsManager'
import type {
  Commodity,
  CommodityMetadata,
  HandlingUnit,
  HandlingUnitMetadata,
  HandlingUnitsDetails,
  TransientCommodity,
  TransientHandlingUnit,
  TransientHandlingUnitOrderItem,
  WeightType,
} from './HandlingUnits.types'

export const getHandlingUnitStopAtOption = (
  value: string,
  options: SelectOption[]
) => {
  return options.find((option) => option.value === value)
}

type HasHazmat = Pick<Commodity, 'hazmat'>

export function hasHazmat(commodities: HasHazmat | HasHazmat[]): boolean {
  return toArray(commodities).some((commodity) => commodity.hazmat)
}

type HasStackable = Pick<HandlingUnit, 'stackable'>

export function hasStackable(units?: HasStackable | HasStackable[]): boolean {
  return toArray(units).some((unit) => unit.stackable)
}

type HasTurnable = Pick<HandlingUnit, 'turnable'>

export function hasTurnable(units?: HasTurnable | HasTurnable[]): boolean {
  return toArray(units).some((unit) => unit.turnable)
}

export function getHighestFreightClassCommodity(
  commodities?: Array<{ freight_class: FreightClass | null }>
): FreightClass | null {
  if (!Array.isArray(commodities) || commodities.length === 0) {
    return null
  }

  const maxFreightClassCommodity = commodities.reduce<FreightClass | null>(
    (highestFreightClass, commodity) => {
      if (!commodity.freight_class) {
        return highestFreightClass
      }

      const currentFreightClass = Number(commodity.freight_class)

      if (
        !highestFreightClass ||
        currentFreightClass > Number(highestFreightClass)
      ) {
        return commodity.freight_class
      }

      return highestFreightClass
    },
    null
  )

  if (maxFreightClassCommodity === null) {
    return null
  }

  return maxFreightClassCommodity
}

export function createCommodity(overrides: Partial<Commodity> = {}): Commodity {
  return merge(
    {
      description: '',
      package_type: '',
      package_count: 0,
      weight: '',
      hazmat: false,
      freight_class: null,
      nmfc_code: '',
      ...createHazmatItem(),
    },
    overrides
  )
}

export function createTransientCommodity(
  overrides: Partial<Commodity> = {},
  metadata?: CommodityMetadata
): TransientCommodity {
  return createTransient(createCommodity(overrides), metadata)
}

export function createHandlingUnitOrderItem(
  overrides?: Partial<HandlingUnitOrderItem>
): HandlingUnitOrderItem {
  return {
    uuid: '',
    line_number: 1,
    item_number: '',
    item_description: '',
    shipped_package_count: 0,
    order: {
      uuid: '',
      primary_ref: '',
      external_id: '',
      po_number: '',
      so_number: '',
    },
    ...overrides,
  }
}

export function createTransientHandlingUnitOrderItem(
  handlingUnitOrderItem?: Partial<HandlingUnitOrderItem>,
  metadata?: any
): TransientHandlingUnitOrderItem {
  return createTransient(
    createHandlingUnitOrderItem(handlingUnitOrderItem),
    metadata
  )
}

export const DEFAULT_WEIGHT_TYPE: WeightType = 'all_packages'

export function createHandlingUnit(
  overrides?: Partial<HandlingUnit>
): HandlingUnit {
  return merge(
    {
      package_type: 'std_pallets',
      package_count: null,
      length: STANDARD_PALLETS_LENGTH,
      width: STANDARD_PALLETS_WIDTH,
      height: '',
      stackable: false,
      turnable: false,
      commodities: [createCommodity()],
      weight_type: DEFAULT_WEIGHT_TYPE,
    },
    overrides
  )
}

export function createTransientHandlingUnit(
  overrides?: Partial<HandlingUnit>,
  metadata?: HandlingUnitMetadata
): TransientHandlingUnit {
  return createTransient(
    createHandlingUnit({
      commodities: [createTransientCommodity()],
      ...overrides,
    }),
    metadata
    // we are sure the commodity is transient
  ) as TransientHandlingUnit
}

export const stripNull = <T = unknown>(
  value: T
): NonNullable<T> | undefined => {
  return value === null ? undefined : value
}

// Due to different models in the BE, the commodity is not being added to the
// commodities array in the handling unit. So we need to create a
// commodity object from the handling unit details
export function createCommodityFromHandlingUnit(
  handlingUnitsDetails: HandlingUnitsDetails
): Commodity {
  return {
    description: handlingUnitsDetails.commodity,
    package_type: null, // This does not exist on the flow creation
    package_count: 0, // This does not exist on the flow creation
    weight: handlingUnitsDetails.weight,
    hazmat: handlingUnitsDetails.hazmat,
    freight_class: handlingUnitsDetails.freight_class,
    nmfc_code: handlingUnitsDetails.nmfc_code ?? '',
    hazmat_proper_shipping_name:
      handlingUnitsDetails.hazmat_proper_shipping_name,
    hazmat_un_number: handlingUnitsDetails.hazmat_un_number,
    hazmat_class: handlingUnitsDetails.hazmat_class,
    hazmat_packing_group: stripNull(handlingUnitsDetails.hazmat_packing_group),
  }
}

export const createTransientHandlingUnits = ({
  items,
  handlingUnitMetadata,
  commoditiesMetadata,
  hazmatCommoditiesMetadata,
}: {
  items: HandlingUnitsDetails[]
  handlingUnitMetadata?: HandlingUnitMetadata
  commoditiesMetadata?: CommodityMetadata
  hazmatCommoditiesMetadata?: CommodityMetadata
}): TransientHandlingUnit[] => {
  return items.map((item) => {
    const commodities: TransientCommodity[] = item.commodities.map(
      (commodity) => {
        return {
          uuid: commodity.uuid,
          ...createTransientCommodity(
            {
              ...commodity,
              hazmat_un_number:
                (commodity.un_number == null
                  ? undefined
                  : commodity.un_number) ?? commodity.hazmat_un_number,
            },
            commodity.hazmat ? hazmatCommoditiesMetadata : commoditiesMetadata
          ),
        }
      }
    )

    // If we have commodities, we don't need to create
    // a commodity from the handling unit, since it already exists
    // from the newer API, so we avoid duplicates
    if (commodities.length === 0) {
      const handlingUnitCommodity = createTransient(
        createCommodityFromHandlingUnit(item),
        item.hazmat ? hazmatCommoditiesMetadata : commoditiesMetadata
      )

      commodities.push(handlingUnitCommodity)
    }

    const order_items = item.order_items

    return {
      uuid: item.uuid,
      ...createTransientHandlingUnit(
        {
          id: item.id,
          length: stripNull(item.length),
          width: stripNull(item.width),
          height: stripNull(item.height),
          package_type: item.package_type,
          package_count: stripNull(item.package_count),
          stackable: stripNull(item.stackable),
          turnable: stripNull(item.turnable),
          weight_type: stripNull(item.weight_type),
          pickup_stop_index: stripNull(item.pickup_stop_index),
          delivery_stop_index: stripNull(item.delivery_stop_index),
          orders: stripNull(item.orders),
          order_items,
          commodities,
        },
        handlingUnitMetadata
      ),
    }
  })
}

export const sumHandlingUnitCount = (
  units: Array<Pick<HandlingUnit, 'package_count'>>
): number => {
  return units.reduce((acc, unit): number => {
    return acc + (Number.parseInt(String(unit.package_count), 10) || 0)
  }, 0)
}

type TotalWeightItemProps = (
  | {
      weight_type?: 'all_packages'
      package_count?: HandlingUnit['package_count']
    }
  | {
      weight_type: 'per_package'
      package_count: HandlingUnit['package_count']
    }
) & {
  commodities: Array<Pick<Commodity, 'weight'>>
}

export const calculateTotalWeight = (
  items: TotalWeightItemProps | TotalWeightItemProps[]
): number => {
  return toArray(items).reduce((totalWeight, item) => {
    const { weight_type, commodities } = item

    const partialWeight = commodities.reduce(
      (commoditiesTotalWeight, commodity) => {
        const parsedWeight = Number.parseFloat(String(commodity.weight)) || 0

        if (weight_type === 'per_package') {
          const parsedPackageCount =
            Number.parseInt(String(item.package_count), 10) || 0

          return commoditiesTotalWeight + parsedWeight * parsedPackageCount
        }

        return commoditiesTotalWeight + parsedWeight
      },
      0
    )

    return totalWeight + partialWeight
  }, 0)
}

export const calculateHandlingUnits = (units: HandlingUnit[] = []): number => {
  return units.reduce((total, current) => {
    if (current.package_count == null) {
      return total
    }
    return Number(current.package_count) + total
  }, 0)
}

export type DimensionsProps = Array<
  Pick<HandlingUnit, 'length' | 'width' | 'height' | 'package_count'> &
    TotalWeightItemProps
>

export function calculateTotalDimensions(items?: DimensionsProps): {
  totalVolume: number
  totalDensity: number
  totalWeight: number
} {
  if (!items) {
    return { totalVolume: 0, totalDensity: 0, totalWeight: 0 }
  }

  const { totalVolume, totalWeight } = items.reduce(
    (
      previousValue: { totalVolume: number; totalWeight: number },
      { length, width, height, package_count, weight_type, commodities }
    ) => {
      const volume = calculateTotalVolume({
        length,
        width,
        height,
        package_count,
      })

      return {
        totalVolume: volume + previousValue.totalVolume,
        totalWeight:
          calculateTotalWeight({ weight_type, commodities, package_count }) +
          previousValue.totalWeight,
      }
    },
    { totalVolume: 0, totalWeight: 0 }
  )

  const totalDensity = calculateTotalDensity(totalWeight, totalVolume)

  return {
    totalVolume,
    totalDensity,
    totalWeight,
  }
}

export const formatPackageTypeLabel = (
  packageType: PackageType | CommodityPackageType | '' | null,
  count: number,
  // category is used for differentiate item level and commodity level package types
  category: 'items' | 'commodities',
  description: string | null
): string => {
  if (packageType === null || packageType === '') {
    return description ?? ''
  }

  let label: string | undefined
  if (category === 'commodities') {
    // Ideally if it is commodity package type, it should be within CommodityPackageType
    // However, we still have legacy commodity package types which were from PackageType
    label =
      COMMODITY_PACKAGE_TYPES_MAP.get(packageType as CommodityPackageType) ??
      PACKAGE_TYPE_LABELS.get(packageType as PackageType)
  } else {
    label = PACKAGE_TYPE_LABELS.get(packageType as PackageType)
  }

  if (label) {
    return `${count} ${label.toLowerCase()}${
      description ? ` of ${description}` : ''
    }`
  }
  return description ?? ''
}

export function mapTransientHandlingUnitsToHandlingUnitsDetails(
  transientHandlingUnits: Array<TransientHandlingUnit>
): Array<HandlingUnitsDetails> {
  return transientHandlingUnits.map((handlingUnit) => ({
    ...omit(undoTransient(handlingUnit), ['weight_type', 'order_items', 'id']),
    // This field will not be used, since it is part of legacy API
    commodity: '',
    package_type: handlingUnit.package_type ?? '',
    package_count: String(handlingUnit.package_count),
    weight: calculateTotalWeight(handlingUnit),
    hazmat: hasHazmat(handlingUnit.commodities),
    freight_class: getHighestFreightClassCommodity(handlingUnit.commodities),
  }))
}

export const sumItemsCommoditiesWeight = (
  shippingItems: HandlingUnitsDetails[]
) => {
  return shippingItems.map((item) => ({
    ...item,
    weight: calculateItemsTotalWeight(item.commodities),
  }))
}

export const getHandlingUnitsPickedUpAtStop = (
  handlingUnits?: Array<TransientHandlingUnit>,
  stopIndex?: number
) => {
  return (
    handlingUnits?.filter(
      ({ pickup_stop_index }) => pickup_stop_index === stopIndex
    ) ?? []
  )
}

/*
 * The new item object doesn't have the commodity and weight fields, but the old one does.
 * This function is used to adapt the new item object to the old format.
 *
 * - commodity: a string with the description of the commodities separated by commas
 * - weight: the total weight of the commodities
 */
export const backwardsCompatibilityItemAdapter = (
  item: HandlingUnit | TransientHandlingUnit
) => {
  return {
    commodity: item.commodities
      .map((commodity) => commodity.description)
      .join(', '),
    weight: calculateItemsTotalWeight(item.commodities),
  }
}

export const hasCommodities = (
  items: Array<
    | Pick<HandlingUnit, 'commodities'>
    | Pick<TransientHandlingUnit, 'commodities'>
  >
) => {
  return items.some((item) => item.commodities.length > 0)
}

export const removeCommodities = (items: TransientHandlingUnit[]) => {
  return items.map((item) => ({
    ...item,
    commodities: [],
  }))
}
