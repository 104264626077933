import { Card, Divider, Layout, Text } from '@loadsmart/miranda-react'
import { useEffect, useRef } from 'react'

import { Column, GridV2 as Grid, GridV2 } from 'components/Grid'
import {
  CommoditiesForm,
  CommodityForm,
} from 'components/HandlingUnitsManager/CommoditiesForm/CommoditiesForm'
import type {
  TransientCommodity,
  TransientHandlingUnit,
  TransientHandlingUnitOrderItem,
} from 'components/HandlingUnitsManager/HandlingUnits.types'
import {
  HandlingUnitForm,
  HandlingUnitsForm,
} from 'components/HandlingUnitsManager/HandlingUnitsForm/HandlingUnitsForm'
import {
  createTransientCommodity,
  createTransientHandlingUnitOrderItem,
} from 'components/HandlingUnitsManager/HandlingUnitsUtils'
import { OrderItemsForm } from 'components/HandlingUnitsManager/OrderItemForm/OrderItemForm'
import { HandlingUnitHeader } from 'components/LTLQuoteFormHandlingUnit/HandlingUnitHeader'
import type { TransientStop } from 'components/StopsManager'
import { isMultiStop } from 'components/StopsManager'
import { HandlingUnitConsolidationSection } from 'screens/Shipper/Shipments/create/consolidation/components/HandlingUnitConsolidationSection'
import { useConsolidationContext } from 'screens/Shipper/Shipments/create/consolidation/consolidation.context'
import {
  useDeliveryStopSelectOptionsV2,
  usePickupStopSelectOptions,
} from 'screens/Shipper/Shipments/create/create.hooks'
import { hasMoreThanOneItem, replaceArrayItem } from 'utils/arrays'
import { hasTransientError } from 'utils/transient'

const PICKUP_STOP_INDEX = 0
const DELIVERY_STOP_INDEX = 1

type EXPFormHandlingUnitProps = {
  readonly handlingUnits: TransientHandlingUnit[]
  readonly stops: TransientStop[]
  readonly onHandlingUnitChange: (value: TransientHandlingUnit[]) => void
}

function FormCommodity({
  commodityIndex,
  handlingUnit,
}: {
  readonly commodityIndex: number
  readonly handlingUnit: TransientHandlingUnit
}) {
  const { canBeRemoved } = useConsolidationContext()
  return (
    <CommodityForm index={commodityIndex}>
      <Layout.Stack aria-label={`Commodity #${commodityIndex + 1}`}>
        <Layout.Stack gap="spacing-1">
          <Layout.Group align="center" justify="space-between">
            <Text variant="body-md-bold" aria-hidden="true">
              {`Commodity #${commodityIndex + 1}`}
            </Text>

            {canBeRemoved(handlingUnit) &&
              hasMoreThanOneItem(handlingUnit.commodities) && (
                <CommoditiesForm.RemoveCommodity />
              )}
          </Layout.Group>
          <Divider />
        </Layout.Stack>

        <Layout.Stack>
          <Layout.Stack gap="none">
            <CommodityForm.Description required hint={<>&nbsp;</>} />

            <GridV2 columns={4} align="flex-end">
              <CommodityForm.Weight required hint={<>&nbsp;</>} />
              <CommodityForm.PackageCount hint={<>&nbsp;</>} />
              <CommodityForm.PackageType hint={<>&nbsp;</>} />
            </GridV2>

            <GridV2 columns={4} align="flex-end">
              <CommodityForm.Nmfc hint={<>&nbsp;</>} />
              <CommodityForm.FreightClass hint={<>&nbsp;</>} />
              <Column columns={2}>
                <CommodityForm.NmfcSearch hint={<>&nbsp;</>} />
              </Column>
            </GridV2>
          </Layout.Stack>
        </Layout.Stack>
      </Layout.Stack>
    </CommodityForm>
  )
}

export function EXPFormHandlingUnitV2({
  handlingUnits,
  stops,
  onHandlingUnitChange,
}: EXPFormHandlingUnitProps) {
  const { canBeRemoved } = useConsolidationContext()
  const pickupStops = usePickupStopSelectOptions(stops)
  const deliveryStops = useDeliveryStopSelectOptionsV2(stops)
  const hasMultiStop = isMultiStop(stops.length)
  const fillStopIndexesRef = useRef(handlingUnits.length)

  useEffect(() => {
    if (fillStopIndexesRef.current === handlingUnits.length) {
      return
    }
    const mappedHandlingUnits = handlingUnits.map((unit) => ({
      ...unit,
      pickup_stop_index: PICKUP_STOP_INDEX,
      delivery_stop_index: hasMultiStop
        ? (unit.delivery_stop_index ?? null)
        : DELIVERY_STOP_INDEX,
    }))
    onHandlingUnitChange(mappedHandlingUnits)
    fillStopIndexesRef.current = handlingUnits.length
  }, [handlingUnits, hasMultiStop, onHandlingUnitChange])

  const handleCommodityChange = (index: number) => {
    return (newTransientCommodities: TransientCommodity[]) => {
      onHandlingUnitChange(
        replaceArrayItem(handlingUnits, index, {
          ...handlingUnits[index],
          commodities: newTransientCommodities,
        })
      )
    }
  }

  const handleOrderItemChange = (index: number) => {
    return (newTransientOrderItems: TransientHandlingUnitOrderItem[]) => {
      onHandlingUnitChange(
        replaceArrayItem(handlingUnits, index, {
          ...handlingUnits[index],
          order_items: newTransientOrderItems,
        })
      )
    }
  }

  return handlingUnits.map((handlingUnit, index) => {
    return (
      <HandlingUnitForm key={handlingUnit._metadata?.id} index={index}>
        <CommoditiesForm
          items={handlingUnit.commodities}
          createItem={createTransientCommodity}
          onChange={handleCommodityChange(index)}
        >
          <OrderItemsForm
            items={handlingUnit.order_items || []}
            createItem={createTransientHandlingUnitOrderItem}
            onChange={handleOrderItemChange(index)}
          >
            <Card
              aria-labelledby={`title-${handlingUnit._metadata?.id}`}
              data-testid={`handling-unit-${index}`}
              onToggle={(event) => event.stopPropagation()}
              style={{
                '--m-card-border-left': 'none',
                '--m-card-border-right': 'none',
                '--m-card-border-bottom': 'none',
              }}
              collapsible
            >
              <HandlingUnitHeader
                handlingUnit={handlingUnit}
                actions={
                  canBeRemoved(handlingUnit) &&
                  hasMoreThanOneItem(handlingUnits) && (
                    <Layout.Group align="center" justify="flex-end">
                      <HandlingUnitsForm.RemoveHandlingUnit />
                    </Layout.Group>
                  )
                }
              />
              <Card.Divider />
              <Card.Body>
                <Layout.Stack
                  gap={hasTransientError(handlingUnit) ? 'spacing-4' : 'none'}
                >
                  <HandlingUnitConsolidationSection
                    handlingUnit={handlingUnit}
                  />
                  <Layout.Group align="flex-start">
                    <HandlingUnitForm.PackageType required hint={<>&nbsp;</>} />
                    <HandlingUnitForm.PackageCount
                      required
                      hint={<>&nbsp;</>}
                    />
                    <HandlingUnitForm.Stackable hint={<>&nbsp;</>} />
                    <HandlingUnitForm.Turnable hint={<>&nbsp;</>} />
                  </Layout.Group>

                  <Grid columns={3} rowGap="none">
                    <HandlingUnitForm.Dimension
                      required
                      dimension="length"
                      hint={<>&nbsp;</>}
                    />
                    <HandlingUnitForm.Dimension
                      required
                      dimension="width"
                      hint={<>&nbsp;</>}
                    />
                    <HandlingUnitForm.Dimension
                      required
                      dimension="height"
                      hint={<>&nbsp;</>}
                    />
                    <HandlingUnitForm.WeightType required hint={<>&nbsp;</>} />
                    {hasMultiStop && (
                      <>
                        <HandlingUnitForm.PickupAt
                          options={pickupStops}
                          hint={<>&nbsp;</>}
                          required
                        />

                        <HandlingUnitForm.DeliverAt
                          options={deliveryStops}
                          hint={<>&nbsp;</>}
                          required
                        />
                      </>
                    )}
                  </Grid>

                  {handlingUnit.commodities.map(
                    (commodity: TransientCommodity, commodityIndex: number) => (
                      <FormCommodity
                        key={commodity._metadata?.id}
                        commodityIndex={commodityIndex}
                        handlingUnit={handlingUnit}
                      />
                    )
                  )}
                </Layout.Stack>
              </Card.Body>
              {canBeRemoved(handlingUnit) && (
                <Card.Footer>
                  <CommoditiesForm.AddCommodity />
                </Card.Footer>
              )}
            </Card>
          </OrderItemsForm>
        </CommoditiesForm>
      </HandlingUnitForm>
    )
  })
}
