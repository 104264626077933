import {
  Button,
  Card,
  Drawer,
  Icon,
  Layout,
  SpinnerWheel,
  Table,
  Text,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { useState } from 'react'
import styled from 'styled-components'

import { formatVolume, formatWeight } from 'components/ShippingItemsManager'
import { FulfillmentHandlingUnitOrdersList } from 'fulfillments/components/FulfillmentHandlingUnitOrdersList'
import type { FulfillmentDetails } from 'fulfillments/details/ViewFulfillmentPage.data'
import {
  formatFulfillmentDates,
  formatFulfillmentHandlingUnitTitle,
  formatFulfillmentHandlingUnitVolume,
  formatFulfillmentRoute,
  formatFulfillmentsLowestMaxTemperature,
  formatFulfillmentTotalPieceCount,
  formatHandlingUnitTotalShippedWeight,
  getFulfillmentLinkedOrdersPrimaryRefs,
  getFulfillmentVolume,
  getFulfillmentWeight,
} from 'fulfillments/fulfillment-utils'
import { useFulfillmentsDetails } from 'fulfillments/hooks/useFulfillmentsDetails'

import type { StopMapData } from './PlanFulfillmentsMap.utils'

const CardFooter = styled(Layout.Stack).attrs({
  padding: 'spacing-5',
})`
  border: 1px solid ${toCSSValue('color-border')};
  border-radius: ${toCSSValue('border-medium')};
`

export type FulfillmentCardProps = Readonly<{
  addSelectedFulfillments: (UUIDs: string[]) => void
  fulfillment: FulfillmentDetails
  isCollapsed: boolean
  isSelected: boolean
  onToggleCollapsed: () => void
  removeSelectedFulfillments: (UUIDs: string[]) => void
}>

export function FulfillmentCard({
  addSelectedFulfillments,
  fulfillment,
  isCollapsed,
  isSelected,
  onToggleCollapsed,
  removeSelectedFulfillments,
}: FulfillmentCardProps) {
  const totalWeight = getFulfillmentWeight(fulfillment)
  const totalVolume = getFulfillmentVolume(fulfillment)
  const maxTemperature = formatFulfillmentsLowestMaxTemperature(fulfillment)

  return (
    <Layout.Stack gap="none">
      <Card
        key={fulfillment.uuid}
        collapsible
        collapsed={isCollapsed}
        onToggle={onToggleCollapsed}
        data-testid={`list-drawer-fulfillment-card-${fulfillment.uuid}`}
      >
        <Card.Title>{formatFulfillmentRoute(fulfillment)}</Card.Title>
        <Card.Subtitle data-testid="subtitle">
          <Layout.Stack gap="spacing-1">
            <Layout.Group gap="spacing-2">
              {formatFulfillmentDates(fulfillment)}
              <Text color="color-text-secondary-inverted">&bull;</Text>
              {getFulfillmentLinkedOrdersPrimaryRefs(fulfillment)}
            </Layout.Group>
            <Layout.Group gap="spacing-2">
              {formatFulfillmentTotalPieceCount(fulfillment)}
              {totalWeight != null && (
                <>
                  <Text color="color-text-secondary-inverted">&bull;</Text>
                  {formatWeight(totalWeight)}
                </>
              )}
              {!!totalVolume && (
                <>
                  <Text color="color-text-secondary-inverted">&bull;</Text>
                  {formatVolume(totalVolume)}
                </>
              )}
              {!!maxTemperature && (
                <>
                  <Text color="color-text-secondary-inverted">&bull;</Text>
                  {maxTemperature}
                </>
              )}
            </Layout.Group>
          </Layout.Stack>
        </Card.Subtitle>
        <Card.Divider />
        <Card.Body>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.HeadCell>Units</Table.HeadCell>
                <Table.HeadCell>Weight</Table.HeadCell>
                <Table.HeadCell>Volume</Table.HeadCell>
                <Table.HeadCell>Order</Table.HeadCell>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {fulfillment.handling_units.map((handlingUnit) => (
                <Table.Row key={handlingUnit.uuid}>
                  <Table.Cell>
                    {formatFulfillmentHandlingUnitTitle(handlingUnit)}
                  </Table.Cell>
                  <Table.Cell>
                    {formatHandlingUnitTotalShippedWeight(handlingUnit)}
                  </Table.Cell>
                  <Table.Cell>
                    {formatFulfillmentHandlingUnitVolume(handlingUnit)}
                  </Table.Cell>
                  <Table.Cell>
                    <FulfillmentHandlingUnitOrdersList
                      handlingUnit={handlingUnit}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card.Body>
      </Card>
      <CardFooter>
        <Layout.Group align="center" justify="flex-start">
          {isSelected ? (
            <>
              <Button
                leading={<Icon name="check" size="12px" />}
                onClick={() => {
                  removeSelectedFulfillments([fulfillment.uuid])
                }}
                variant="primary"
              >
                Added
              </Button>
              <Text color="color-text-secondary-inverted">click to remove</Text>
            </>
          ) : (
            <Button
              leading={<Icon name="plus" size="12px" />}
              onClick={() => {
                addSelectedFulfillments([fulfillment.uuid])
              }}
              variant="secondary"
            >
              Add
            </Button>
          )}
        </Layout.Group>
      </CardFooter>
    </Layout.Stack>
  )
}

function PlanFulfillmentsListContent({
  addSelectedFulfillments,
  closeListDrawer,
  removeSelectedFulfillments,
  selectedFulfillmentsUUIDs,
  stopFulfillmentsUUIDs,
}: Readonly<{
  addSelectedFulfillments: (UUIDs: string[]) => void
  closeListDrawer: () => void
  removeSelectedFulfillments: (UUIDs: string[]) => void
  selectedFulfillmentsUUIDs: string[]
  stopFulfillmentsUUIDs: string[]
}>) {
  const { fulfillments, isLoading } = useFulfillmentsDetails(
    stopFulfillmentsUUIDs
  )
  const [expandedMap, setExpandedMap] = useState<Record<string, boolean>>({})

  const onToggle = (fulfillmentUUID: string) => () => {
    setExpandedMap((oldState) => ({
      ...oldState,
      [fulfillmentUUID]: !oldState[fulfillmentUUID],
    }))
  }

  if (isLoading) {
    return (
      <Drawer onClose={closeListDrawer} open size="medium">
        <Drawer.Header>
          Fulfillments <Drawer.Close />
        </Drawer.Header>

        <Drawer.Body>
          <Layout.Group
            align="center"
            justify="center"
            style={{ minHeight: '90vh' }}
          >
            <SpinnerWheel size="30px" />
          </Layout.Group>
        </Drawer.Body>
      </Drawer>
    )
  }

  const sortedFulfillments = fulfillments
    .toSorted((a, b) =>
      formatFulfillmentRoute(a).localeCompare(formatFulfillmentRoute(b))
    )
    .toSorted((a, b) => a.ref_number.localeCompare(b.ref_number))

  return (
    <Drawer onClose={closeListDrawer} open size="medium">
      <Drawer.Header>
        Fulfillments <Drawer.Close />
      </Drawer.Header>

      <Drawer.Body>
        <Layout.Stack>
          {sortedFulfillments.map((fulfillment) => (
            <FulfillmentCard
              key={fulfillment.uuid}
              addSelectedFulfillments={addSelectedFulfillments}
              fulfillment={fulfillment}
              isCollapsed={!expandedMap[fulfillment.uuid]}
              isSelected={selectedFulfillmentsUUIDs.includes(fulfillment.uuid)}
              onToggleCollapsed={onToggle(fulfillment.uuid)}
              removeSelectedFulfillments={removeSelectedFulfillments}
            />
          ))}
        </Layout.Stack>
      </Drawer.Body>
      <Drawer.Actions>
        <Layout.Group justify="space-between" style={{ width: '100%' }}>
          <Layout.Group gap="spacing-4">
            <Drawer.ActionTertiary
              leading={<Icon name="x" size="12px" />}
              onClick={() => {
                removeSelectedFulfillments(stopFulfillmentsUUIDs)
              }}
              type="button"
            >
              Remove all
            </Drawer.ActionTertiary>
            <Drawer.ActionTertiary
              onClick={() => {
                setExpandedMap(
                  stopFulfillmentsUUIDs.reduce(
                    (acc, UUID) => ({ ...acc, [UUID]: true }),
                    {}
                  )
                )
              }}
              type="button"
            >
              Expand all
            </Drawer.ActionTertiary>
            <Drawer.ActionTertiary
              onClick={() => {
                setExpandedMap(
                  stopFulfillmentsUUIDs.reduce(
                    (acc, UUID) => ({ ...acc, [UUID]: false }),
                    {}
                  )
                )
              }}
              type="button"
            >
              Collapse all
            </Drawer.ActionTertiary>
          </Layout.Group>
          <Layout.Group>
            <Drawer.ActionPrimary
              leading={<Icon name="plus" size="12px" />}
              onClick={() => {
                addSelectedFulfillments(stopFulfillmentsUUIDs)
              }}
              type="button"
            >
              Add all
            </Drawer.ActionPrimary>
          </Layout.Group>
        </Layout.Group>
      </Drawer.Actions>
    </Drawer>
  )
}

export type PlanFulfillmentsListProps = Readonly<{
  addSelectedFulfillments: (UUIDs: string[]) => void
  allFulfillmentsUUIDs: string[]
  closeListDrawer: () => void
  isListDrawerOpen: boolean
  removeSelectedFulfillments: (UUIDs: string[]) => void
  selectedFulfillmentsUUIDs: string[]
  selectedStopFacilityUUID: string | null
  stops: Record<string, StopMapData>
}>

export function PlanFulfillmentsList({
  addSelectedFulfillments,
  allFulfillmentsUUIDs,
  closeListDrawer,
  isListDrawerOpen,
  removeSelectedFulfillments,
  selectedFulfillmentsUUIDs,
  selectedStopFacilityUUID,
  stops,
}: PlanFulfillmentsListProps) {
  if (!isListDrawerOpen) {
    return null
  }

  const selectedStop = selectedStopFacilityUUID
    ? stops[selectedStopFacilityUUID]
    : null

  if (!selectedStop) {
    return (
      <PlanFulfillmentsListContent
        addSelectedFulfillments={addSelectedFulfillments}
        closeListDrawer={closeListDrawer}
        removeSelectedFulfillments={removeSelectedFulfillments}
        selectedFulfillmentsUUIDs={selectedFulfillmentsUUIDs}
        stopFulfillmentsUUIDs={allFulfillmentsUUIDs}
      />
    )
  }

  const stopFulfillmentsUUIDs = [
    ...selectedStop.pendingFulfillments,
    ...selectedStop.plannedFulfillments,
  ].map((fulfillment) => fulfillment.uuid)

  return (
    <PlanFulfillmentsListContent
      addSelectedFulfillments={addSelectedFulfillments}
      closeListDrawer={closeListDrawer}
      removeSelectedFulfillments={removeSelectedFulfillments}
      selectedFulfillmentsUUIDs={selectedFulfillmentsUUIDs}
      stopFulfillmentsUUIDs={stopFulfillmentsUUIDs}
    />
  )
}
