import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useShippingItemFormField } from './ShippingItemsForm'
import type { UseShippingItemProps } from './ShippingItemsForm'

export const ShippingItemNMFC = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: UseShippingItemProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    name,
    dispatch,
  } = useShippingItemFormField({ name: 'nmfc_code', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>NMFC</Field.Label>
      <TextField
        {...controlProps}
        placeholder="Enter NMFC"
        value={get(item, name, '')}
        onChange={(event) => {
          const {
            target: { value },
          } = event

          dispatch({
            type: 'SET_ITEM',
            payload: { index, changes: { [name]: value } },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
