import { defaultTo, isEmpty } from 'lodash-es'

import type {
  Fulfillment,
  FulfillmentHandlingUnit,
  FulfillmentHandlingUnitOrderItem,
} from 'fulfillments/domain/Fulfillment'
import {
  getFulfillmentHandlingUnitShippedWeight,
  getFulfillmentHandlingUnitsTotalPackageCount,
  getFulfillmentHandlingUnitUniqueOrders,
} from 'fulfillments/fulfillment-utils'
import type { Order } from 'orders/types'
import { getOrderItemsLowestTemperature } from 'screens/Orders/common/utils'

export type OrderFulfillmentSummary = {
  items: Record<
    string,
    FulfillmentHandlingUnitOrderItem & { totalShippedCount: number }
  >
  totalShippedItemsCount: number
  totalShippedWeight: number
}
export type OrderFulfillmentSummaryMap = {
  [orderUUID: string]: OrderFulfillmentSummary
}

export type FulfillmentDetailsHandlingUnit = FulfillmentHandlingUnit & {
  orders: Pick<
    Order,
    | 'external_id'
    | 'po_number'
    | 'primary_ref'
    | 'so_number'
    | 'status'
    | 'uuid'
  >[]
  poNumbers: string[]
  soNumbers: string[]
  temperatureReqs: {
    max_temperature: string
    max_temperature_uom: string
  }
  totalShippedWeight: number | null
}

export type FulfillmentDetails = Omit<
  Fulfillment,
  'handling_units' | 'delivery_facility_uuid' | 'pickup_facility_uuid'
> & {
  handlingUnitsTotalPackageCount: number
  handling_units: FulfillmentDetailsHandlingUnit[]
  summary: OrderFulfillmentSummaryMap
}

export function getOrderFulfillmentSummary(
  handlingUnits: FulfillmentDetailsHandlingUnit[]
) {
  const summary: OrderFulfillmentSummaryMap = {}

  handlingUnits.forEach((handlingUnit) => {
    handlingUnit.order_items.forEach((orderItem) => {
      if (!summary[orderItem.order.uuid]) {
        summary[orderItem.order.uuid] = {
          items: {},
          totalShippedItemsCount: 0,
          totalShippedWeight: 0,
        }
      }

      // first time seeing this item UUID
      if (!summary[orderItem.order.uuid].items[orderItem.uuid]) {
        summary[orderItem.order.uuid].items[orderItem.uuid] = {
          ...orderItem,
          totalShippedCount: orderItem.shipped_package_count,
        }
        summary[orderItem.order.uuid].totalShippedItemsCount += 1
      } else {
        // if item is multiple times in the same fulfillment
        // just sum the shipped count
        summary[orderItem.order.uuid].items[orderItem.uuid].totalShippedCount +=
          orderItem.shipped_package_count
      }

      const shippedWeight = Number(
        defaultTo(orderItem.shipped_package_weight, '')
      )
      summary[orderItem.order.uuid].totalShippedWeight += defaultTo(
        shippedWeight,
        0
      )
    })
  })

  return summary
}

export function enrichFulfillmentHandlingUnit(
  handlingUnit: FulfillmentHandlingUnit
): FulfillmentDetailsHandlingUnit {
  const orders = getFulfillmentHandlingUnitUniqueOrders(handlingUnit)

  return {
    ...handlingUnit,
    orders,
    poNumbers: orders
      .map((order) => order.po_number)
      .filter((item) => !isEmpty(item)),
    soNumbers: orders
      .map((order) => order.so_number)
      .filter((item) => !isEmpty(item)),
    temperatureReqs: getOrderItemsLowestTemperature(handlingUnit.order_items),
    totalShippedWeight: getFulfillmentHandlingUnitShippedWeight(handlingUnit),
  }
}

export function enrichFulfillment(
  fulfillment?: Fulfillment
): FulfillmentDetails | undefined {
  if (!fulfillment) {
    return fulfillment
  }

  const handling_units = fulfillment.handling_units?.map(
    enrichFulfillmentHandlingUnit
  )
  return {
    ...fulfillment,
    handling_units,
    handlingUnitsTotalPackageCount:
      getFulfillmentHandlingUnitsTotalPackageCount(fulfillment),
    summary: getOrderFulfillmentSummary(handling_units),
  }
}
