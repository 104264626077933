import {
  Button,
  Card,
  Icon,
  Layout,
  Table,
  Tag,
  Text,
  Tooltip,
} from '@loadsmart/miranda-react'
import { toCSSValue } from '@loadsmart/miranda-react/dist/tokens'
import { defaultTo } from 'lodash-es'
import { useCallback } from 'react'
import { generatePath, NavLink } from 'react-router-dom'

import {
  formatFulfillmentFillRate,
  formatFulfillmentHandlingUnitShippedWeight,
  formatFulfillmentHandlingUnitTitle,
  formatFulfillmentPickupReadyDate,
  formatFulfillmentStop,
  formatFulfillmentTotalPieceCount,
  formatFulfillmentWeight,
} from 'fulfillments/fulfillment-utils'
import { FulfillmentsRoutePaths } from 'fulfillments/FulfillmentsRoutes'
import { useGoToNewFulfillmentWithOrders } from 'fulfillments/hooks/navigation'
import { useIsFulfillmentsFlowEnabled } from 'fulfillments/hooks/useIsFulfillmentsFlowEnabled'
import type { OrderFulfillmentsListItem } from 'orders/types'
import { appRoutes } from 'router/app-routes'
import { getTagVariantForStatus } from 'screens/Shipper/Shipments/utils'

import { useViewOrderContext } from '../ViewOrderContext'

export function FulfillmentHandlingUnitsCell({
  fulfillment,
}: Readonly<{
  fulfillment: OrderFulfillmentsListItem
}>) {
  const handlingUnits = defaultTo(fulfillment.handling_units, [])

  if (handlingUnits.length === 0) {
    return '-'
  }

  if (handlingUnits.length === 1) {
    return (
      <Layout.Stack gap="none">
        {formatFulfillmentHandlingUnitTitle(handlingUnits[0])}
        <Text variant="body-sm" color="color-text-tertiary">
          {formatFulfillmentHandlingUnitShippedWeight(handlingUnits[0])}
        </Text>
      </Layout.Stack>
    )
  }

  return (
    <Layout.Stack gap="none">
      <Tooltip
        message={
          <Layout.Stack gap="none">
            {handlingUnits.map((item) => (
              <Text key={item.uuid} color="color-text-inverted">
                {formatFulfillmentHandlingUnitTitle(handlingUnits[0])}
              </Text>
            ))}
          </Layout.Stack>
        }
        placement="top"
        trigger="hover"
        data-testid="handling-unit-tooltip"
      >
        <Text color="color-text-link" variant="body-md-bold-underline">
          {formatFulfillmentTotalPieceCount(fulfillment)}
        </Text>
      </Tooltip>
      <Text variant="body-sm" color="color-text-tertiary">
        {formatFulfillmentWeight(fulfillment)}
      </Text>
    </Layout.Stack>
  )
}

export function FulfillmentsTableRow({
  fulfillment,
  isOrderSupplier,
}: Readonly<{
  fulfillment: OrderFulfillmentsListItem
  isOrderSupplier?: boolean
}>) {
  return (
    <Table.Row>
      <Table.Cell>
        <NavLink
          to={{
            pathname: generatePath(FulfillmentsRoutePaths.Details, {
              id: fulfillment.uuid,
            }),
          }}
          style={{ textDecoration: 'none' }}
        >
          <Text color="color-text-link">{fulfillment.ref_number}</Text>
        </NavLink>
      </Table.Cell>
      <Table.Cell>
        <Layout.Stack>
          <Text>{formatFulfillmentStop(fulfillment.pickup_facility)}</Text>
          <Text color="color-text-secondary" variant="body-sm">
            {formatFulfillmentPickupReadyDate(fulfillment)}
          </Text>
        </Layout.Stack>
      </Table.Cell>
      <Table.Cell>
        <FulfillmentHandlingUnitsCell fulfillment={fulfillment} />
      </Table.Cell>
      <Table.Cell>
        <Layout.Stack>
          <Text>{formatFulfillmentFillRate(fulfillment)}</Text>
        </Layout.Stack>
      </Table.Cell>
      <Table.Cell>
        {fulfillment.shipment ? (
          <Layout.Group align="center" gap="spacing-2">
            <Tag
              size="small"
              variant={getTagVariantForStatus(fulfillment.shipment.status)}
            >
              {fulfillment.shipment.status.toUpperCase()}
            </Tag>
            {!isOrderSupplier && (
              <NavLink
                to={{
                  pathname: generatePath(appRoutes.shipmentDetails, {
                    id: fulfillment.shipment.uuid,
                  }),
                }}
                style={{ textDecoration: 'none' }}
              >
                <Text color="color-text-link">
                  <Icon
                    name="arrow-corner-up"
                    title="Go to shipment"
                    size="12px"
                  />
                </Text>
              </NavLink>
            )}
          </Layout.Group>
        ) : (
          <Text color="color-text-disabled">No shipment</Text>
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export function OrderFulfillmentsCard() {
  const { isOrderSupplier, order } = useViewOrderContext()
  const fulfillments = defaultTo(order?.fulfillments, [])
  const goToNewFulfillment = useGoToNewFulfillmentWithOrders()
  const onNewFulfillmentClick = useCallback(() => {
    if (order?.uuid) {
      goToNewFulfillment([order?.uuid])
    }
  }, [goToNewFulfillment, order?.uuid])

  const isFulfillmentsFlowEnabled = useIsFulfillmentsFlowEnabled()
  if (!isFulfillmentsFlowEnabled) {
    return null
  }

  if (!order) {
    return null
  }

  return (
    <Card data-testid="order-fulfillments-card">
      <Card.Title>Fulfillments</Card.Title>
      <Card.Divider />
      <Card.Body>
        {fulfillments.length ? (
          <Table size="large">
            <Table.Head>
              <Table.Row>
                <Table.HeadCell>Ref number</Table.HeadCell>
                <Table.HeadCell>Pickup facility</Table.HeadCell>
                <Table.HeadCell>Handling units</Table.HeadCell>
                <Table.HeadCell>Fill rate</Table.HeadCell>
                <Table.HeadCell>Shipment</Table.HeadCell>
              </Table.Row>
            </Table.Head>

            <Table.Body>
              {fulfillments.map((fulfillment) => (
                <FulfillmentsTableRow
                  key={fulfillment.uuid}
                  fulfillment={fulfillment}
                  isOrderSupplier={isOrderSupplier}
                />
              ))}
            </Table.Body>
          </Table>
        ) : (
          <Layout.Group
            justify="center"
            data-testid="order-fulfillments-card-empty-state"
          >
            <Layout.Box style={{ padding: `${toCSSValue('spacing-6')} 0` }}>
              <Text variant="body-md" color="color-text-disabled">
                No fulfillments
              </Text>
            </Layout.Box>
          </Layout.Group>
        )}
      </Card.Body>
      <Card.Divider />
      <Card.Footer>
        <Button onClick={onNewFulfillmentClick}>+ Add fulfillment</Button>
      </Card.Footer>
    </Card>
  )
}
