import { useCallback } from 'react'

import { useFilters, generateUseSearchParamsPlugin } from 'utils/filters'

export function moveElement<T>(array: T[], element: T, delta: number) {
  const newArray = [...array]
  const index = newArray.indexOf(element)
  const newIndex = index + delta

  // Element is not present
  if (index < 0) {
    return newArray
  }

  // Already at the top or bottom.
  if (newIndex < 0 || newIndex == newArray.length) {
    return newArray
  }

  // Sort the indices
  const indexes = [index, newIndex].sort((a, b) => a - b)

  // Replace from lowest index, two elements, reverting the order
  newArray.splice(indexes[0], 2, newArray[indexes[1]], newArray[indexes[0]])

  return newArray
}

type PlanFulfillmentsQPState = {
  facilities: string[]
  fulfillments: string[]
}

function getDefaultPlanFulfillmentsQPState(
  overrides?: Partial<PlanFulfillmentsQPState>
): PlanFulfillmentsQPState {
  return {
    facilities: [],
    fulfillments: [],
    ...overrides,
  }
}

const usePlanFulfillmentsQPStatePlugin =
  generateUseSearchParamsPlugin<PlanFulfillmentsQPState>({
    facilities: {
      type: 'primitive-collection',
    },
    fulfillments: {
      type: 'primitive-collection',
    },
  })

export const usePlanFulfillmentsQPState = () => {
  const { setFilter, setFilters, values } = useFilters(
    { initialValues: getDefaultPlanFulfillmentsQPState() },
    usePlanFulfillmentsQPStatePlugin
  )
  const {
    facilities: orderedFacilitiesUUIDs = [],
    fulfillments: selectedFulfillmentsUUIDs = [],
  } = values

  const setFacilities = useCallback(
    (facilityUUIDs: string[]) => {
      setFilter('facilities', facilityUUIDs)
    },
    [setFilter]
  )

  const setSelectedFulfillments = useCallback(
    (newSelected: string[]) => {
      setFilter('fulfillments', newSelected)
    },
    [setFilter]
  )

  const addSelectedFulfillments = useCallback(
    (toBeAdded: string | string[]) => {
      const array = Array.isArray(toBeAdded) ? toBeAdded : [toBeAdded]
      const newFulfillmentsArray = [...selectedFulfillmentsUUIDs, ...array]
      setFilter('fulfillments', newFulfillmentsArray)
    },
    [selectedFulfillmentsUUIDs, setFilter]
  )

  const removeSelectedFulfillments = useCallback(
    (toBeRemoved: string | string[]) => {
      const newFulfillmentsArray = selectedFulfillmentsUUIDs.filter((uuid) => {
        if (Array.isArray(toBeRemoved)) {
          return !toBeRemoved.includes(uuid)
        }
        return toBeRemoved !== uuid
      })

      setFilter('fulfillments', newFulfillmentsArray)
    },
    [selectedFulfillmentsUUIDs, setFilter]
  )

  const reorderFacility = useCallback(
    (facility: string, direction: 'up' | 'down') => {
      let newFacilityUUIDs: string[] = [...orderedFacilitiesUUIDs]

      newFacilityUUIDs = moveElement(
        newFacilityUUIDs,
        facility,
        direction === 'up' ? -1 : 1
      )

      setFacilities(newFacilityUUIDs)
    },
    [orderedFacilitiesUUIDs, setFacilities]
  )

  const clearQPState = useCallback(() => {
    setFilters({ facilities: [], fulfillments: [] })
  }, [setFilters])

  return {
    addSelectedFulfillments,
    clearQPState,
    orderedFacilitiesUUIDs,
    removeSelectedFulfillments,
    reorderFacility,
    selectedFulfillmentsUUIDs,
    setFacilities,
    setSelectedFulfillments,
  }
}
