import { Field, TextField } from '@loadsmart/miranda-react'
import type { FieldProps } from '@loadsmart/miranda-react'
import { get } from 'lodash-es'
import type { CSSProperties } from 'react'

import { useShippingItemFormField } from './ShippingItemsForm'
import type { UseShippingItemProps } from './ShippingItemsForm'
import { hasStandardPalletDimmensions } from './ShippingItemsForm.utils'

export const ShippingItemLength = (props: {
  readonly required?: FieldProps['required']
  readonly hint?: UseShippingItemProps['hint']
  readonly style?: CSSProperties
}) => {
  const { required, hint, style } = props
  const {
    controlProps,
    fieldProps,
    hintProps,
    index,
    item,
    labelProps,
    name,
    dispatch,
  } = useShippingItemFormField({ name: 'length', hint })

  return (
    <Field {...fieldProps} required={required} style={style}>
      <Field.Label {...labelProps}>Length</Field.Label>
      <TextField
        {...controlProps}
        placeholder="inches"
        inputMode="numeric"
        type="number"
        min="1"
        value={get(item, name, '')}
        onChange={(event) => {
          const {
            target: { value },
          } = event

          // source: src/screens/Shipper/Shipments/NewShipment/Step1/PalletFields.tsx

          /**
           * the original code checks: !hasPackageTypeChanged && isPreviousPackageTypeStandardPallets
           * which is the same as "current unit type is std_pallets"
           */

          if (
            item.package_type === 'std_pallets' &&
            !hasStandardPalletDimmensions({ ...item, [name]: value })
          ) {
            dispatch({
              type: 'SET_ITEM',
              payload: {
                index,
                changes: {
                  [name]: value,
                  package_type: 'pallets_non_std',
                },
              },
            })

            return
          }

          dispatch({
            type: 'SET_ITEM',
            payload: { index, changes: { [name]: value } },
          })
        }}
      />
      <Field.Hint {...hintProps} />
    </Field>
  )
}
