import { useState, useEffect } from 'react'

type UseLocalStorageStateOptions<T> = {
  initialValue?: T
  serialize?: (value: T) => string
  deserialize?: (value: string) => T
}

function useLocalStorageState<T>(
  key: string,
  options?: UseLocalStorageStateOptions<T>
) {
  const {
    initialValue,
    serialize = JSON.stringify,
    deserialize = JSON.parse,
  } = options ?? {}

  const [value, setValue] = useState<T>(() => {
    if (typeof globalThis.window === 'undefined') {
      return initialValue
    }
    try {
      const item = globalThis.localStorage.getItem(key)

      if (item != null) {
        return deserialize(item)
      }

      return initialValue
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Could not get value from localStorage', error)

      return initialValue
    }
  })

  useEffect(() => {
    if (typeof globalThis.window === 'undefined') {
      return
    }
    if (value == null) {
      try {
        globalThis.localStorage.removeItem(key)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Could not remove value from localStorage', error)
      }
    } else {
      try {
        globalThis.localStorage.setItem(key, serialize(value))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Could not set value to localStorage', error)
      }
    }
  }, [value, key, serialize])

  return [value, setValue] as const
}

export default useLocalStorageState
