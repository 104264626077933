import { defaultTo, get } from 'lodash-es'

import type { TransientFulfillment } from 'fulfillments/domain/Fulfillment'
import type { PutFulfillmentPayload } from 'fulfillments/fulfillment-service'
import { formatISODate } from 'utils/dateUtils'

export function adaptFulfillmentToApi(
  fulfillment: TransientFulfillment
): PutFulfillmentPayload {
  const [pickup, delivery] = get(fulfillment, 'stops', [])

  return {
    pickup_number: fulfillment.pickup_number,
    pickup_facility_uuid: defaultTo(pickup.facility?.uuid, ''),
    pickup_ready_date: pickup.date ? formatISODate(pickup.date) : '',
    pickup_contact_uuid: defaultTo(pickup.contact?.uuid, undefined),
    pickup_instructions: defaultTo(pickup.notes, ''),

    delivery_facility_uuid: defaultTo(delivery.facility?.uuid, ''),
    delivery_date: delivery.date ? formatISODate(delivery.date) : '',
    delivery_contact_uuid: defaultTo(delivery.contact?.uuid, undefined),
    delivery_instructions: defaultTo(delivery.notes, ''),

    handling_units: fulfillment.handling_units.map((handlingUnit) => ({
      uuid: handlingUnit.id,
      package_count: Number(handlingUnit.package_count),
      package_type: defaultTo(handlingUnit.package_type, ''),
      package_length: Number(handlingUnit.length),
      package_width: Number(handlingUnit.width),
      package_height: Number(handlingUnit.height),
      stackable: handlingUnit.stackable,
      turnable: handlingUnit.turnable,
      commodities: handlingUnit.commodities.map((commodity, index) => {
        const orderItem = handlingUnit.order_items?.[index]
        return {
          order_item_uuid: orderItem?.uuid || '',
          shipped_weight: Number(commodity.weight),
          shipped_count: Number(commodity.package_count),
          shipped_nmfc_code: get(commodity, 'nmfc_code', ''),
          shipped_freight_class: get(commodity, 'freight_class', null),
        }
      }),
    })),

    notes: fulfillment.notes,
  }
}
