import { Divider, Layout } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash-es'

import type { TransientHandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import { StopHandlingUnitsSummary } from 'components/HandlingUnitsManager/StopHandlingUnitsSummary'

export function HandlingUnitsToDeliver({
  stopIndex,
  allItems,
}: {
  readonly stopIndex?: number
  readonly allItems: TransientHandlingUnit[]
}) {
  if (!stopIndex) {
    return null
  }

  const deliveredItems = allItems.filter(
    (item) => item.delivery_stop_index === stopIndex
  )

  if (isEmpty(deliveredItems)) {
    return null
  }

  return (
    <>
      <Divider />
      <Layout.Box padding="spacing-6">
        <StopHandlingUnitsSummary
          variant="delivery"
          handlingUnits={deliveredItems}
        />
      </Layout.Box>
    </>
  )
}
