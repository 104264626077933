import type { TextProps } from '@loadsmart/miranda-react'
import { Layout, Text } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash-es'

import {
  formatFacilityLocation,
  getCountryMetadataByAbbreviation,
} from 'components/FacilityDetails'
import { FacilityCountry } from 'components/FacilityDetails/FacilityCountry'
import type { FulfillmentDetails } from 'fulfillments/details/ViewFulfillmentPage.data'

const FIELD_MAP: Record<
  'pickup' | 'delivery',
  keyof FulfillmentDetails & ('pickup_facility' | 'delivery_facility')
> = {
  pickup: 'pickup_facility',
  delivery: 'delivery_facility',
}

export function FulfillmentFacilityAddress({
  addressLineProps,
  fulfillment,
  nameLineProps = {},
  stop,
}: Readonly<{
  addressLineProps?: TextProps
  fulfillment: FulfillmentDetails
  nameLineProps?: TextProps
  stop: 'pickup' | 'delivery'
}>) {
  const facility = FIELD_MAP[stop]
  const name = fulfillment[facility].name ?? ''
  const country = fulfillment[facility].country ?? ''
  const addressLineOne = fulfillment[facility].address ?? ''
  const addressLineTwo = formatFacilityLocation({
    city: fulfillment[facility].city,
    state: fulfillment[facility].state,
    zipcode: fulfillment[facility].zipcode,
  })

  if ([name, country, addressLineOne, addressLineTwo].every(isEmpty)) {
    return '-'
  }

  return (
    <Layout.Stack gap="spacing-1">
      <Text {...nameLineProps}>{name}</Text>
      <Text {...addressLineProps}>{addressLineOne}</Text>
      <Layout.Group align="center" gap="spacing-2">
        <Text {...addressLineProps}>{addressLineTwo}</Text>

        {country && (
          <Layout.Group align="center" gap="spacing-1">
            <FacilityCountry country={country} labelPosition="tooltip" />
            <Text {...addressLineProps}>
              {
                getCountryMetadataByAbbreviation(fulfillment[facility].country)
                  ?.name
              }
            </Text>
          </Layout.Group>
        )}
      </Layout.Group>
    </Layout.Stack>
  )
}
