import type { AxiosRequestConfig } from 'axios'

import httpClient from 'utils/httpClient'

export type LegacyFlags = Record<string, boolean>

export type FrontendSettingsResponse = {
  flags: LegacyFlags
  settings: Record<string, string | number>
}

export const getSettings = async (
  config?: AxiosRequestConfig
): Promise<FrontendSettingsResponse> => {
  const {
    data = {
      flags: {},
      settings: {},
    },
  } = await httpClient.get<{
    flags: Record<string, boolean>
    settings: Record<string, string | number>
  }>('/frontend_settings', config)
  return data
}
