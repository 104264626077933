import { useDialog } from '@loadsmart/loadsmart-ui'
import { useState } from 'react'

import type {
  AppointmentDateTime,
  StopAppointment,
} from 'screens/Shipper/Shipments/Details/types'
import { formatDate } from 'utils/dateUtils'

const SCHEDULED_AT_FORMAT = 'ddd, MMMM DD'

export function getMilitaryTime(time: string) {
  const hours = time.substring(0, 2)
  const mins = time.substring(2, 4)
  return `${hours}:${mins}`
}

export function getAppointmentDateTime(appointment: StopAppointment) {
  const date = appointment.scheduled_date || appointment.suggested_date
  const time = appointment.scheduled_time || appointment.suggested_time
  return {
    date,
    time,
  }
}

export function getAppointmentTimeByType(timeObject?: AppointmentDateTime) {
  if (!timeObject) {
    return { type: '', start: '', end: '' }
  }

  return {
    type: timeObject.type,
    start: timeObject.start ? getMilitaryTime(timeObject.start) : '',
    end: timeObject.end ? getMilitaryTime(timeObject.end) : '',
  }
}

export function getAppointmentDescription(
  appointment: Partial<StopAppointment>
) {
  if (appointment.scheduled_time) {
    const { type, start, end } = getAppointmentTimeByType(
      appointment.scheduled_time
    )
    const time = type === 'before' ? end : start
    const betweenTime = type === 'between' ? ` until ${end} ` : ''
    return `Arrive ${type} ${time} ${betweenTime}`
  }
  if (appointment.suggested_time) {
    const { type, start, end } = getAppointmentTimeByType(
      appointment.suggested_time
    )
    const time = type === 'before' ? end : start
    const betweenTime = type === 'between' ? ` until ${end} ` : ''
    return `Available ${type} ${time} ${betweenTime}`
  }
  return null
}

export function getMTCAppointmentStatus(appointment: Partial<StopAppointment>) {
  const { status, previous_scheduled_window_start } = appointment

  if (status === 'requested') {
    return null
  }

  if (status === 'confirmed' && previous_scheduled_window_start) {
    return 'Rescheduled'
  }

  if (status === 'pending') {
    return 'Pending schedule'
  }

  return 'Scheduled'
}

export function getMisschedulingReason(reason: string | null) {
  if (!reason) {
    return ''
  }

  return reason.split('-').join(' ')
}

export function getTagVariant(status: string) {
  if (status === 'pending') {
    return 'danger'
  }

  if (status === 'confirmed') {
    return 'success'
  }

  return 'warning'
}

type UseAppointmentDialogStateOptions = {
  warehouseUuid?: string | null
}

export function useAppointmentDialogState({
  warehouseUuid,
}: UseAppointmentDialogStateOptions) {
  const [isCarrierSchedule, setIsCarrierSchedule] = useState(false)

  const dialogState = useDialog({ open: false })
  const { open } = dialogState

  const appointmentDialogIsOpen = Boolean(
    open && (!warehouseUuid || isCarrierSchedule)
  )
  const openDockAppointmentIsOpen = Boolean(
    open && warehouseUuid && !isCarrierSchedule
  )

  return {
    ...dialogState,
    isCarrierSchedule,
    setIsCarrierSchedule,
    appointmentDialogIsOpen,
    openDockAppointmentIsOpen,
  }
}

export function formatPreviousScheduledDate(
  appointment: Partial<StopAppointment>
) {
  const { previous_scheduled_date, scheduled_date, suggested_date } =
    appointment
  const date = scheduled_date?.start || suggested_date?.start
  const previousDate = previous_scheduled_date?.start

  if (!previousDate || previousDate === date) {
    return null
  }

  return formatDate(previousDate, SCHEDULED_AT_FORMAT)
}

export function formatPreviousScheduledTime(
  appointment: Partial<StopAppointment>
) {
  const { start, end, type } = getAppointmentTimeByType(
    appointment?.previous_scheduled_time ?? undefined
  )

  if (type === 'between') {
    return `${start} until ${end}`
  }

  return start
}

export function showMissSchedulingReason(appointment: StopAppointment) {
  const { misscheduling_reason, status, previous_scheduled_window_start } =
    appointment

  const notConfirmed = status !== 'confirmed'
  const isReschedule = status === 'confirmed' && previous_scheduled_window_start

  return (
    (notConfirmed && misscheduling_reason) ||
    (isReschedule && misscheduling_reason)
  )
}

export function formatDateDayString(
  dateStart?: string | null,
  dateEnd?: string | null,
  stopDate?: string | null
) {
  if (dateStart && dateEnd) {
    const startDay = formatDate(dateStart, 'DD')
    const endDay = formatDate(dateEnd, 'DD')

    if (startDay === endDay) {
      return startDay
    }
    return `${startDay} - ${endDay}`
  }
  if (dateStart) {
    return formatDate(dateStart, 'DD')
  }
  if (stopDate) {
    return formatDate(stopDate, 'DD')
  }
  return null
}

export function formatDateMonthString(
  dateStart?: string | null,
  dateEnd?: string | null,
  stopDate?: string | null
) {
  if (dateStart && dateEnd) {
    const startMonth = formatDate(dateStart, 'MMM')
    const endMonth = formatDate(dateEnd, 'MMM')

    if (startMonth === endMonth) {
      return startMonth
    }
    return `${startMonth} - ${endMonth}`
  }
  if (dateStart) {
    return formatDate(dateStart, 'MMM')
  }
  if (stopDate) {
    return formatDate(stopDate, 'MMM')
  }
  return null
}
