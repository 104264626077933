import type { RouteComponentProps } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import { appRoutes } from 'router/app-routes'

import { CreateFulfillmentPage } from './create/CreateFulfillmentPage'
import { ViewFulfillmentPage } from './details/ViewFulfillmentPage'
import { EditFulfillmentPage } from './edit/EditFulfillmentPage'
import { ListFulfillmentsPage } from './list/ListFulfillmentsPage'
import { PlanFulfillmentsPage } from './plan/PlanFulfillmentsPage'

export const FulfillmentsRoutePaths = {
  Details: `${appRoutes.fulfillments}/:id`,
  List: `${appRoutes.fulfillments}/`,
  New: `${appRoutes.fulfillments}/new`,
  Edit: `${appRoutes.fulfillments}/edit/:id`,
}

export function FulfillmentsRoutes({ match }: Readonly<RouteComponentProps>) {
  const { path } = match
  return (
    <Switch>
      <Route
        path={`${path}/plan-shipment`}
        exact
        component={PlanFulfillmentsPage}
      />
      <Route path={`${path}/`} exact component={ListFulfillmentsPage} />
      <Route path={`${path}/new`} exact component={CreateFulfillmentPage} />
      <Route path={`${path}/:id`} exact component={ViewFulfillmentPage} />
      <Route path={`${path}/:id/edit`} exact component={EditFulfillmentPage} />
    </Switch>
  )
}
